// 생산관리 > 외부인력사용현황조회
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import { withTranslation } from "react-i18next"
import { YellowButton} from "../../component/common/Button"
import PageName from "../../component/common/PageName"

import CommonContainer from "../../../containers/CommonContainer"
import ManpowerInquiryContainer from "../../../containers/production/ManpowerInquiryContainer"

import ComboBox from "../../component/common/ComboBox"
import CodeHelpBox from "../../component/common/CodeHelpBox"
import DateBox from "../../component/common/DateBox"
import { TableComponent } from "../../component/common/Table"

import ManpowerInfoPopup from "../../popup/Production/ManpowerInquiryPopup"

const ManpowerInquiry = (props) => {
  const {
    t,
    getComboList,
    cropComboList,
    
    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,
  } = props

  const {
    getManpowerList,
    setManpowerList,
    getManpowerInfo,
    setManpowerInfo,
    manpowerList,
    manpowerInfo,
  } = props

  const [cropSeq, setCropSeq] = useState(0) //createWrite or Edit
  const [cropReportSeq, setCropReportSeq] = useState(0)
  const [dateFr, setDateFr] = useState(moment().format("YYYYMMDD"))
  const [dateTo, setDateTo] = useState(moment().format("YYYYMMDD"))
  const [manpowerInfoPopup, setManpowerInfoPopup] = useState(false)
  const dict = _translationWords[0] // 변수에 사전 담기

  const [cropName, setCropName] = useState('')

  const [minDate, setMinDate] = useState(new Date()) //날짜 최저일
  const [maxDate, setMaxDate] = useState(new Date()) //날짜 최대일

  useEffect(() => {
    getTranslationWords()
    categoryData()
    setManpowerList([])
    setManpowerInfo([])
    }, [])

    // 달력 날짜 제한 관련
    useEffect(() => {

      // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
      const originalDate = new Date(dateFr.substr(0, 4) + '-' + dateFr.substr(4, 2) + '-' + dateFr.substr(6, 2));
      const modifiedDate = originalDate.setDate(originalDate.getDate() + 10000);

      setMinDate(new Date(dateFr.substr(0,4)+'-'+dateFr.substr(4,2)+'-'+dateFr.substr(6,2)));
      setMaxDate(modifiedDate);
      // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
      // console.log('dateFr(1) : ', dateFr)
      // console.log('dateTo(1) : ', dateTo)

    }, [dateFr])
  
  // search method
  const onClickSearch = async () => {
    getData()
  }

  const onClickRow = async (seq) => {
    if ( seq != 0 ) {
      const result = await getManpowerInfo({ cropReportSeq: seq })
      setCropReportSeq(seq)
      if (result) {
        setManpowerInfoPopup(true)
      }
      console.log('manpowerInfo : ', manpowerInfo)
      console.log('manpowerInfoPopup : ', manpowerInfoPopup)
      console.log('cropReportSeq : ', cropReportSeq)
    }
  }

  // lookUp method
  const getData = async () => {
    await getManpowerList({
      dateFr: dateFr,
      dateTo: dateTo,
      cropSeq: typeof cropName == 'object' ? cropName['value'] : cropName, // codeHelp 사용으로 JSON 객체의 label값 사용 - 차건담 2023.08.02
    })

  }
  // category method
  const categoryData = async () => {
    await getComboList({ queryKind: "Crop" })
  }

  const manpowerInfoPopupHandler = async () => {
    setManpowerInfoPopup(false)
    getData()
  }

  return (
    <Container>
      <ManpowerInfoPopup
        open={manpowerInfo.length > 0 && cropReportSeq != 0 && manpowerInfoPopup ? true : false}
        onClose={manpowerInfoPopupHandler}
        infoItem={manpowerInfo.length > 0 && cropReportSeq != 0 && manpowerInfoPopup ? manpowerInfo : false}
      />
      <FlexBox justifyContent={"space-between"}>
        <PageName name={dict['staffUseStatusCheck']} state={false} setState={() => {}} />
      </FlexBox>
      <Box>
        <WhiteBox>
          <SearchBox>
            <div style={{ display: "flex" }}>
              <Text>{dict['cropName']}</Text>
              {/* <ComboBox
                style={{}}
                defaultValue={dict['select']}
                hasDefault={true}
                items={cropComboList.map((i) => ({ value: i.cropSeq, label: i.cropName }))}
                value={cropSeq}
                setValue={setCropSeq}
              /> */}
              <CodeHelpBox // ComboBox에서 CodeHelpBox로 변경 - 차건담 2023.07.13
                style={{}}
                placeholder={dict['cropName']}
                codeHelpSeq={3}
                // codeHelpParam={{}}
                value={cropName}
                setValue={setCropName}
              />
              <Text>{dict['inquiryPeriod']}</Text>
              <DateBox
                style={{
                  width: "120px",
                  height: "40px",
                  marginRight: "5px",
                }}
                stdDate={dateFr}
                setStdDate={setDateFr}
              />
              <p style={{ width: "15px" }}>~</p>
              <DateBox
                style={{
                  width: "120px",
                  height: "40px",
                }}
                stdDate={dateTo}
                setStdDate={setDateTo}
                minDate={minDate} // 날짜 최저일
                maxDate={maxDate} // 날짜 최대일
              />
            </div>
            <FlexBox>
              <YellowButton onClick={onClickSearch}>{dict['search']}</YellowButton>
            </FlexBox>
          </SearchBox>
          <TableComponent
            headList={[
              { title: dict['days'], type: "date" },
              { title: dict['stdCropGrowth'], type: "text" },
              { title: dict['importWork'], type: "text" },
              { title: dict['usageDays'], type: "text" },
              { title: dict['usageTime'], type: "text" },
              { title: dict['cropQty'], type: "text" },
            ]}
            bodyList={((manpowerList && manpowerList) || []).map((i, idx) => ({
              seq: i.cropReportSeq,
              fontWeight: i.sortNo != 1 ? "bold" : "normal",
              textAlign : i.sortNo != 1 ? "right" : "left",
              cropReportDate: i.cropReportDate,
              cropGrowthWork: i.cropGrowthWork, 
              importWork: i.importWork,
              people: i.people == 0 && i.sortNo == 1 ? "" : (i.sortNo == 3 && i.people > 0 ? '+'+ i.people : i.people), //
              useTime: i.useTime == 0 && i.sortNo == 1 ? "" : i.useTime,
              cropReportQty: i.cropReportQty == 0 && i.sortNo == 1 ? "" : (i.sortNo == 3 && i.cropReportQty > 0 ? '+'+ i.cropReportQty : i.cropReportQty), //
              
            }))}
            dict={dict}
            onClick={(data) => { onClickRow(data.seq);}}
          />
        </WhiteBox>
      </Box>
    </Container>
  )
}

export default CommonContainer(ManpowerInquiryContainer(withTranslation()(ManpowerInquiry)))

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  margin-top: 30px;
  padding: 28px 32px 32px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`