// 자동환경설정 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import { AutoEnvTimeKindList } from "../../../utils/data"
import AutoEnvSettingContainer from "../../../containers/envControl/AutoEnvSettingContainer"
import RequiredMark from "../../component/RequiredMark"
import ComboBox from "../../component/common/ComboBox"
import InputBox from "../../component/common/InputBox"
import InputTimeBox from "../../component/common/InputTimeBox"
import AlertPopup from "../AlertPopup"
import moment from "moment"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "388px",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const AutoEnvAddPopup = (props) => {
  const classes = useStyles()
  const { t, _autoEnvEquipTime } = props
  const { open, onClose, type } = props // 1: specified time, 2: sunrise/sunset
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const [timeKind, setTimeKind] = useState(0)
  const [timeFr, setTimeFr] = useState("")
  const [timeTo, setTimeTo] = useState("")
  const [tgValue, setTgValue] = useState("")

  useEffect(() => {
    if (Object.keys(_autoEnvEquipTime).length != 0) {
      setTimeKind(_autoEnvEquipTime.timeKind)
      setTimeFr(_autoEnvEquipTime.timeFr)
      setTimeTo(_autoEnvEquipTime.timeTo)
      setTgValue(_autoEnvEquipTime.tgValue)
    }
    return () => {
      setTimeKind(0)
      setTimeFr("")
      setTimeTo("")
      setTgValue("")
    }
  }, [open])

  const onClickSave = () => {
    let msg = ""
    if (type == 2 && timeKind === 0) {
      msg = t("n.Please select a product.", { n: t("sensorKind") })
    } else if (timeFr == "") {
      msg = t("n.Invalid value. Please try again.", { n: t("timeFr") })
    } else if (timeTo == "") {
      msg = t("n.Invalid value. Please try again.", { n: t("timeTo") })
    } else if (tgValue == "") {
      msg = t("n.Invalid value. Please try again.", { n: t("tgTemp") })
    }
    if (msg != "") {
      return setAlertPopup({ open: true, text: msg })
    }

    let param = { timeKind, timeFr: moment(timeFr, "HHmm").format("HH:mm"), timeTo: moment(timeTo, "HHmm").format("HH:mm"), tgValue }

    if (_autoEnvEquipTime.envConfEquipTimeSeq) {
      param.envConfEquipTimeSeq = _autoEnvEquipTime.envConfEquipTimeSeq
    }

    onClose(t("save"), param)
  }

  const onClickCancel = () => {
    onClose(t("cancel"), null)
  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose(null)}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <Container>
        <Title>{t("autoEnvSetting")}</Title>
        <div style={{ flex: 1 }}>
          {type == 2 && (
            <FlexBox>
              <Label>
                <RequiredMark /> {t("kind")}
              </Label>
              <ComboBox
                style={{ width: "160px", marginRight: 0 }}
                defaultValue={t("select")}
                hasDefault={true}
                items={AutoEnvTimeKindList}
                value={timeKind}
                setValue={setTimeKind}
              />
            </FlexBox>
          )}
          <FlexBox>
            <Label>
              <RequiredMark /> {t("timeFr")}
            </Label>
            <InputTimeBox style={{ width: "160px", marginRight: 0 }} disabled={false} value={timeFr} setValue={setTimeFr} />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {t("timeTo")}
            </Label>
            <InputTimeBox style={{ width: "160px", marginRight: 0 }} disabled={false} value={timeTo} setValue={setTimeTo} />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {t("tgTemp")}
            </Label>
            <InputBox
              style={{ width: "160px", marginRight: 0 }}
              type="number"
              useComma={false}
              value={tgValue}
              onChange={(e) => setTgValue(e.target.value)}
            />
          </FlexBox>
        </div>
        <ButtonBox>
          <Button bgColor={"#fff"} hover={"rgba(174, 174, 174, 0.22)"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={onClickCancel}>
            {t("cancel")}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {t("save")}
          </Button>
        </ButtonBox>
      </Container>
    </Dialog>
  )
}

const Container = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 23px 51px 44px 40px;
`
const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 40px;
  }
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  flex: 1;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

AutoEnvAddPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default AutoEnvSettingContainer(withTranslation()(AutoEnvAddPopup))
