import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment"

import TableCheckBox from "./TableCheckBox"

export const TableComponent = (props) => {
  const { style = {}, headList = [], bodyList = [], onClick = () => {}, onClickEdit = () => {},
          onClickEdit_Comp = () => {}, onClickDelete = () => {}, onClickImg = () => {}, onClickTransLang = () => {}, dict = {} } = props

  const [active, setActive] = useState(-1)

  return (
    <TableBox style={{ ...style }}>
      <Table>
        <Thead>
          <Tr className="thead">
            {headList.map((i, idx) => (
                // 숨기고 싶은 데이터 제목의 Tr태그를 생성하지 않음 - 차건담 2023.02.07
                i.type=="hidden" ? null : <Th key={"th" + idx} >{i.title}</Th> 
            ))}         
          </Tr>
        </Thead>
        <Tbody>
          {bodyList.map((i, idx) => (
            <TableData
              key={idx}
              index={idx}
              item={i}
              headList={headList}
              onClick={onClick}
              onClickEdit={onClickEdit}
              onClickEdit_Comp={onClickEdit_Comp}
              onClickDelete={onClickDelete}
              active={active}
              setActive={setActive}
              onClickImg={onClickImg}
              onClickTransLang={onClickTransLang}
              dict={dict}
            />
          ))}
        </Tbody>
      </Table>
    </TableBox>
  )
}

TableComponent.propTypes = {
  headList: PropTypes.array,
  bodyList: PropTypes.array,
  onClick: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickEdit_Comp: PropTypes.func,
  onClickDelete: PropTypes.func,
  onClickImg: PropTypes.func,
  onClickTransLang: PropTypes.func,
  dict: PropTypes.object,
}

const TableData = ({ item, index, headList, onClick, onClickEdit, onClickEdit_Comp,
                     onClickDelete, onClickTransLang, active, setActive, onClickImg, dict }) => {

  const keys = Object.keys(item)

  const switchType = (type) => {
    switch (type) {
      case "text":
        return "left"
      case "number":
        return "right"
      case "comma":
        return "right"
      case "check":
        return "center"
      default:
        return "center"
    }
  }
  
  return (
    <Tr
      hover={"#edf4eb"}
      className={`${index == active ? "active" : ""}`}
      onClick={() => {
        onClick(item)
        setActive(index)
      }}
    >
      {keys
        .filter((i) => i != "seq")
        .filter((i) => i != "seq1")
        .filter((i) => i != "fontWeight")
        .filter((i) => i != "color")
        .filter((i) => i != "textAlign")
        .filter((i) => i != "dict")
        .map((i, idx) => {
          if (i == "Actions")
            return (
              <Td key={"td" + idx} textAlign={switchType(headList[idx].type)}>
                <ActionButton
                  color={"#038965cc"}
                  onClick={(e) => {
                    e.stopPropagation()
                    onClickEdit(item["seq"])
                  }}
                >
                  {dict['mod']}
                </ActionButton>
                <ActionButton
                  color={"#fa5b59cc"}
                  onClick={(e) => {
                    e.stopPropagation()
                    onClickDelete(item["seq"])
                  }}
                >
                  {dict['delete']}
                </ActionButton>
              </Td>
            )
          else if (i == "Actions_Comp")
            return (
              <Td key={"td" + idx} textAlign={switchType(headList[idx].type)}>
                <ActionButton
                  color={"#038965cc"}
                  onClick={(e) => {
                    e.stopPropagation()
                    onClickEdit(item["seq"])
                  }}
                >
                  {dict['mod']}
                </ActionButton>
                <ActionButton
                  color={"#fa5b59cc"}
                  onClick={(e) => {
                    e.stopPropagation()
                    onClickDelete(item["seq"])
                  }}
                >
                  {dict['delete']}
                </ActionButton>
                
                <ActionButton 
                  style={{ visibility: item["seq1"] == '-' ? "hidden" : "visible"}}
                  color={"#038965cc"}
                  onClick={(e) => {
                    e.stopPropagation()
                    onClickEdit_Comp(item["seq1"])
                  }}
                >
                  {dict['subCondition']}
                </ActionButton>
              </Td>
            )
            else if (i == "Actions_transLang_userMajor") // 사용자코드 대분류 번역 - 차건담 2023.02.06
              return (
                <Td key={"td" + idx} textAlign={switchType(headList[idx].type)}>
                  <ActionButton
                    color={"#fa5b59cc"}
                    onClick={(e) => {
                      e.stopPropagation()
                      onClickTransLang(item)
                    }}
                  >
                    {dict['translate']}
                  </ActionButton>
                </Td>
              )
                  
            else if (i == "Actions_transLang_userMinor") // 사용자코드 소분류 번역 - 차건담 2023.02.06
              return (
                <Td key={"td" + idx} textAlign={switchType(headList[idx].type)}>
                  <ActionButton
                    color={"#038965cc"}
                    onClick={(e) => {
                      e.stopPropagation()
                      onClickEdit(item["seq"])
                    }}
                  >
                    {dict['mod']}
                  </ActionButton>
                  <ActionButton
                    color={"#fa5b59cc"}
                    onClick={(e) => {
                      e.stopPropagation()
                      onClickDelete(item["seq"])
                    }}
                  >
                    {dict['delete']}
                  </ActionButton>

                  <ActionButton
                    color={"#fa5b59cc"}
                    onClick={(e) => {
                      e.stopPropagation()
                      onClickTransLang(item)
                    }}
                  >
                    {dict['translate']}
                  </ActionButton>
                </Td>
              )
                  
            else if (i == "Actions_transLang_sysMajor") // 시스템코드 대분류 번역 - 차건담 2023.02.06
              return (
                <Td key={"td" + idx} textAlign={switchType(headList[idx].type)}>
                  <ActionButton
                    color={"#fa5b59cc"}
                    onClick={(e) => {
                      e.stopPropagation()
                      onClickTransLang(item)
                    }}
                  >
                    {dict['translate']}
                  </ActionButton>
                </Td>
              )
                  
            else if (i == "Actions_transLang_sysMinor") // 시스템코드 소분류 번역 - 차건담 2023.02.06
              return (
                <Td key={"td" + idx} textAlign={switchType(headList[idx].type)}>
                  <ActionButton
                    color={"#fa5b59cc"}
                    onClick={(e) => {
                      e.stopPropagation()
                      onClickTransLang(item)
                    }}
                  >
                    {dict['translate']}
                  </ActionButton>
                </Td>
              )

            else if (i == "Actions_transLangPopup")
              return (
                <Td key={"td" + idx} textAlign={switchType(headList[idx].type)}>
                  <ActionButton
                    color={"#038965cc"}
                    onClick={(e) => {
                      e.stopPropagation()
                      onClickEdit(item)
                    }}
                  >
                    {dict['mod']}
                  </ActionButton>
                </Td>
              )

          else if (headList[idx].type == "check")
            return (
              <Td key={"td" + idx} textAlign={switchType(headList[idx].type)}>
                <TableCheckBox check={item[i] == 1} setCheck={() => {}} />
              </Td>
            )
          else if (headList[idx].type == "img")
            return (
              <Td key={"td" + idx} textAlign={switchType(headList[idx].type)} onClick={(e) => {
                e.stopPropagation()
                onClickImg(item.seq)
              }}>
                <div>{!item[i] || item[i] == "" ? "" : <img style={{ height: "64px", width: "74px" }} src={item[i]} />}</div>
              </Td>
            )
          else if (headList[idx].type == "hidden") // hidden으로 설정한 제목의 데이터 Td를 숨기기 위해 display를 none으로 설정 - 차건담 2023.02.07
            return (
              <Td style={{display:"none"}} key={"td" + idx} textAlign={switchType(headList[idx].type)}>
                <div style={{ width: headList[idx].width || "100%", whiteSpace: "pre", fontWeight: item["fontWeight"] ? item["fontWeight"] : "normal", display: "none"}}
                >
                  {item[i] == null ? null : typeof item[i] == String ? item[i].substr(0, 20) + "..." : item[i]}
                </div>
              </Td>
            )
          else
            return (
              <Td key={"td" + idx} textAlign={switchType(headList[idx].type)}>
                <div
                  style={{ width: headList[idx].width || "100%", whiteSpace: "pre", fontWeight: item["fontWeight"] ? item["fontWeight"] : "normal",
                                                                                    color: item["color"] ? item["color"] : "",
                                                                                    textAlign: item["textAlign"] ? item["textAlign"] : ""
                                                                                  }}
                >
                  {headList[idx].type === "date"
                    ? item[i] && moment(item[i], "YYYYMMDD").format("YYYY-MM-DD")
                    : headList[idx].type === "comma"
                    ? item[i].toLocaleString()
                    : headList[idx].type === "text" && (item[i] + "").length > 20
                    ? item[i].substr(0, 20) + "..."
                    : item[i]}
                </div>
              </Td>
            )
        })}
    </Tr>
  )
}

export const TableBox = styled.div`
  overflow: auto;
  width: 100%;
  margin-top: 23px;
  border: 1px solid #dedede;
  border-radius: 3px;
`
export const Table = styled.table`
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
  white-space: nowrap;
`
export const Tr = styled.tr`
  background-color: #ffffff;

  &:nth-child(even) {
    background-color: #fafafa;
  }
  &.active {
    background-color: #edf4eb;
  }
&:hover {
    background-color: ${(props) => props.hover};
  }

  `
export const Thead = styled.thead``
export const Th = styled.th`
  background-color: #f6f6f6;
  position: sticky;
  top: 0px;
  height: 40px;
  padding: 0 10px;
  border-right: 1px solid #dedede;
  color: #555555b3;
  font-size: 14px;
  text-align: center;
  z-index: 1;

  &:last-child {
    border-right: none;
  }
`
export const Tbody = styled.tbody`
  & > tr {
    cursor: pointer;
  }
`
export const Td = styled.td`
  height: 40px;
  padding: 0 10px;
  border-right: 1px solid #e1e1e1;
  color: #555555b3;
  font-size: 14px;
  text-align: ${(props) => props.textAlign || "center"};

  &:last-child {
    border-right: none;
  }
`
export const ActionButton = styled.button`
  width: 46px;
  height: 20px;
  border: 1px solid #aeaeae33;
  border-radius: 12px;
  color: ${(props) => props.color};
  font-size: 12px;
  &:last-child {
    margin-left: 16px;
  }
  &:hover {
    background-color: #d5d5d533;
  }
`