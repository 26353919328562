// 현황정보 > 판매계획대비실적조회
import React, { useState, useEffect } from "react"
import moment from "moment"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import IncomeInquiryContainer from "../../../containers/IncomeInquiry/IncomeInquiryContainer"
import { TableComponent } from "../../component/common/Table"
import InputBox from "../../component/common/InputBox"
import { YellowButton } from "../../component/common/Button"
import PageName from "../../component/common/PageName"
import BasicPopup from "../../popup/BasicPopup"

import CommonContainer from "../../../containers/CommonContainer"

const PerformanceInquiry = (props) => {
  const { t, _salesCropList, getSalesCropList, setSalesCropList, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  const today = moment()

  const getData = async () => {
    await getSalesCropList({ startDate: startYear, endDate: endYear })
  }
  useEffect(() => {
    getTranslationWords()
    return async () => await setSalesCropList([])
  }, [])

  const [popup, setPopup] = useState({ open: false, text: "" })
  const [startYear, setStartYear] = useState(moment(today).format("YYYY"))
  const [endYear, setEndYear] = useState(moment(today).add(1, "years").format("YYYY"))

  const dict = _translationWords[0] // 변수에 사전 담기

  // 총합
  const amt = (data) => {
    let tempArr = []
    let tempPlan = 0
    let tempSales = 0
    let temp = { dateYM: dict['sum'], salesPlanAmt: 0, salesAmt: 0 }
    if (data.length > 0) {
      for (let i = 0; i < Object.keys(data).length; i++) {
        tempPlan += data[i].salesPlanAmt
        tempSales += data[i].salesAmt
      }
      temp.salesPlanAmt = tempPlan
      temp.salesAmt = tempSales
    }
    tempArr.push(temp, ...data)
    return tempArr
  }
  const onClickSearch = async () => {
    getData()
  }

  return (
    <Container>
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: "" })} content={popup.text} selectedValue={[dict['confirm']]} />
      <FlexBox justifyContent={"space-between"}>
        <PageName name={dict['performanceInquiry']} state={false} setState={() => {}} />
      </FlexBox>
      <Box>
        <WhiteBox>
          <SearchBox>
            <FlexBox>
              <Text>{dict['yearOfInquiry']}</Text>
              <InputBox
                useComma={false}
                style={{ width: "100px", marginRight: "5px" }}
                type="number"
                value={startYear}
                onChange={(e) => setStartYear(e.target.value)}
                mask={"####"}
              />
              <p style={{ width: "15px" }}>~</p>
              <InputBox
                useComma={false}
                style={{ width: "100px" }}
                type="number"
                value={endYear}
                onChange={(e) => setEndYear(e.target.value)}
                mask={"####"}
              />
            </FlexBox>
            <YellowButton onClick={onClickSearch}>{dict['search']}</YellowButton>
          </SearchBox>

          <TableComponent
            headList={[
              { title: dict['monthly'], type: "text" },
              { title: dict['planAmount'], type: "comma" },
              { title: dict['salePrice'], type: "comma" },
              { title: `${dict['achievementRate']}(%)`, type: "comma" },
            ]}
            bodyList={
              _salesCropList.length > 0
                ? amt(_salesCropList).map((i) => ({
                    seq: i,
                    fontWeight: String(i.dateYM).length != 7 ? "bold" : "normal",
                    dateYM: i.dateYM,
                    salesPlanAmt: i.salesPlanAmt,
                    salesAmt: i.salesAmt,
                    resultPer: i.salesPlanAmt == 0 || i.salesAmt == 0 ? 0 : Math.ceil((i.salesAmt / i.salesPlanAmt) * 100),
                  }))
                : []
            }
            dict={dict}
          />
        </WhiteBox>
      </Box>
    </Container>
  )
}

export default IncomeInquiryContainer(CommonContainer(withTranslation()(PerformanceInquiry)))

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  margin-top: 19px;
  padding: 28px 32px 20px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`
