// 기본정보 > 품목
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { downExcel } from "../../../utils/util"
import ItemContainer from "../../../containers/defaultInfo/ItemContainer"
import { SkyButton, YellowButton, GrayButton } from "../../component/common/Button"
import PageName from "../../component/common/PageName"
import InputBox from "../../component/common/InputBox"
import ComboBox from "../../component/common/ComboBox"
import { TableComponent } from "../../component/common/Table"
import ItemAddPopup from "../../popup/DefaultInfo/ItemAddPopup"

import CommonContainer from "../../../containers/CommonContainer" // 다국어 사전 가져오기 위해 import - 차건담 2023.03.02

const ItemInfo = (props) => {
  const { t, _codeList, _itemList, initItemList, getItemList, initItem, getItem, addItem, editItem, delItem, downloadItemListExcel } = props
  const [itemAddPopup, setItemAddPopup] = useState(false)
  const [searchName, setSearchName] = useState("")
  const [searchSeq, setSearchSeq] = useState("")
  const [class1, setClass1] = useState(0)
  const [class2, setClass2] = useState(0)
  const [class3, setClass3] = useState(0)

// 사전 관련 객체 가져오기 - 차건담 2023.03.02
  const { initTranslationWords, getTranslationWords, _translationWords, replaceWord } = props  

  useEffect(() => {
    getTranslationWords() // 사전 가져오는 함수 - 차건담 2023.03.02
    return async () => await initItemList()
  }, [])

  let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.03.02

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  const getData = async () => {
    await getItemList({
      itemName: "",
      itemNo: "",
      itemClass1: 0,
      itemClass2: 0,
      itemClass3: 0,
    })
  }

  const onClickExcelDownload = async () => {
    const result = await downloadItemListExcel()
    downExcel({ result: result, fileName: dictCk("itemList") })
  }

  const onClickSearch = async () => {
    await getItemList({
      itemName: searchName,
      itemNo: searchSeq,
      itemClass1: class1,
      itemClass2: class2,
      itemClass3: class3,
    })
  }
  const onClickAdd = async () => {
    await initItem()
    setItemAddPopup(true)
  }
  const onClickEdit = async (seq) => {
    const result = await getItem({ itemSeq: seq })
    if (result) setItemAddPopup(true)
  }
  
  // const onClickDelete = async (seq) => {
  //   const result = await delItem({ itemSeq: seq })
  //   if (result) getData()
  // }

  const onClickDelete = async (seq) => {
    const confirmDelete = window.confirm('정말로 삭제하시겠습니까?');
    if (confirmDelete) {
      const result = await await delItem({ itemSeq: seq })
      if (result) {
        getData();
      }
    }
  };



  const itemAddPopupHandler = async (type, value) => {
    let finalResult = false
    if (type == dictCk("save") && value.itemSeq == undefined) {
      const result = await addItem(value)
      if (result && !result.code != 0) {
        getData()
        finalResult = true
      }
    } else if (type == dictCk("save") && value.itemSeq) {
      const result = await editItem(value)
      if (result && !result.code != 0) {
        getData()
        finalResult = true
      }
    }

    if ((type == dictCk("save") && finalResult) || type == dictCk("cancel")) {
      setItemAddPopup(false)
    }
  }

  return (
    <Container>
      <ItemAddPopup open={itemAddPopup} onClose={itemAddPopupHandler} />
      <FlexBox justifyContent={"space-between"}>
        <PageName name={dictCk("itemInfo")} state={false} setState={() => {}} />
        <GrayButton onClick={onClickExcelDownload}>{dictCk("downloadExcelFile")}</GrayButton>
      </FlexBox>
      <Box>
        <WhiteBox>
          <SearchBox>
            <div>
              <Text>{dictCk("itemName")} </Text>
              <InputBox style={{ marginRight: "33px" }} type="text" value={searchName} onChange={(e) => setSearchName(e.target.value)} />
              <Text>{dictCk("itemNo")} </Text>
              <InputBox type="text" value={searchSeq} onChange={(e) => setSearchSeq(e.target.value)} />
            </div>
            <FlexBox>
              <YellowButton onClick={onClickSearch}>{dictCk("search")}</YellowButton>
              <SkyButton style={{ marginLeft: "14px" }} onClick={onClickAdd}>
                {dictCk("add")}
              </SkyButton>
            </FlexBox>
          </SearchBox>

          <ClassesBox>
            <div>
              <Text>{dictCk("itemClass1")}</Text>
              <ComboBox
                style={{ width: "220px", marginRight: "33px" }}
                defaultValue={dictCk("select")}
                hasDefault={true}
                items={
                  _codeList.length > 0 &&
                  _codeList.filter((i) => i.majorKind == 2 && i.majorSeq == 1004).length > 0 &&
                  _codeList.filter((i) => i.majorKind == 2 && i.majorSeq == 1004)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                }
                value={class1}
                setValue={setClass1}
              />
              <Text>{dictCk("itemClass2")}</Text>
              <ComboBox
                style={{ width: "220px", marginRight: "33px" }}
                defaultValue={dictCk("select")}
                hasDefault={true}
                items={
                  _codeList.length > 0 &&
                  _codeList.filter((i) => i.majorKind == 2 && i.majorSeq == 1005).length > 0 &&
                  _codeList.filter((i) => i.majorKind == 2 && i.majorSeq == 1005)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                }
                value={class2}
                setValue={setClass2}
              />
              <Text>{dictCk("itemClass3")}</Text>
              <ComboBox
                style={{ width: "220px" }}
                defaultValue={dictCk("select")}
                hasDefault={true}
                items={
                  _codeList.length > 0 &&
                  _codeList.filter((i) => i.majorKind == 2 && i.majorSeq == 1006).length > 0 &&
                  _codeList.filter((i) => i.majorKind == 2 && i.majorSeq == 1006)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                }
                value={class3}
                setValue={setClass3}
              />
            </div>
          </ClassesBox>
          <TableComponent
            headList={[
              { title: `${dictCk("itemName")}`, type: "text" },
              { title: `${dictCk("itemNo")}`, type: "number" },
              { title: `${dictCk("itemKind")}`, type: "text" },
              { title: `${dictCk("unit")}`, type: "text" },
              { title: `${dictCk("itemClass1")}`, type: "text" },
              { title: `${dictCk("itemClass2")}`, type: "text" },
              { title: `${dictCk("itemClass3")}`, type: "text" },
              { title: `${dictCk("stdWeight")}`, type: "number" },
              { title: `${dictCk("stdSugar")}`, type: "number" },
              { title: `${dictCk("isUse")}`, type: "check" },
              { title: `${dictCk("remark")}`, type: "text" },
              { title: `${dictCk("actions")}` },
            ]}
            bodyList={_itemList.map((i) => ({
              seq: i.itemSeq,
              itemName: i.itemName,
              itemNo: i.itemNo,
              itemKindName: i.itemKindName,
              unitName: i.unitName,
              itemClass1Name: i.itemClass1Name,
              itemClass2Name: i.itemClass2Name,
              itemClass3Name: i.itemClass3Name,
              stdWeight: i.stdWeight,
              stdSugar: i.stdSugar,
              isUse: i.isUse,
              remark: i.remark,
              Actions: true,
            }))}
            dict={dict}
            onClickEdit={(seq) => onClickEdit(seq)}
            onClickDelete={(seq) => onClickDelete(seq)}
          />
        </WhiteBox>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  margin-top: 30px;
  padding: 28px 32px 32px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`
const ClassesBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

export default CommonContainer(ItemContainer(withTranslation()(ItemInfo)))
