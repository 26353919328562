// 작업장 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import { timeKindList } from "../../../utils/data"
import WorkPlaceInfoContainer from "../../../containers/defaultInfo/WorkPlaceInfoContainer"
import RequiredMark from "../../component/RequiredMark"
import TableCheckBox from "../../component/common/TableCheckBox"
import CodeHelpBox from "../../component/common/CodeHelpBox"
import ComboBox from "../../component/common/ComboBox"
import InputBox from "../../component/common/InputBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import AlertPopup from "../AlertPopup"

import CommonContainer from "../../../containers/CommonContainer" // 다국어 사전 가져오기 위해 import - 차건담 2023.03.02

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
      maxWidth: "615px",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const WorkCenterAddPopup = (props) => {
  const classes = useStyles()
  const { t, _codeList, _workCenter } = props
  const { onClose, open } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const [workCenterName, setWorkCenterName] = useState("")
  const [workCenterNo, setWorkCenterNo] = useState("")
  const [workCenterKind, setWorkCenterKind] = useState(0)
  const [item, setItem] = useState({ value: 0, label: "" })
  const [area, setArea] = useState("")
  const [areaUnit, setAreaUnit] = useState(0)
  const [cropMethod1, setCropMethod1] = useState(0)
  const [cropMethod2, setCropMethod2] = useState(0)
  const [areaCnt, setAreaCnt] = useState("")
  const [timeKind, setTimeKind] = useState(0)
  const [isUse, setIsUse] = useState(true)
  const [etc, setEtc] = useState("")

  // 사전 관련 객체 가져오기 - 차건담 2023.03.02
  const { initTranslationWords, getTranslationWords, _translationWords, replaceWord } = props  

  useEffect(() => {
    getTranslationWords() // 사전 가져오는 함수 - 차건담 2023.03.02
    if (open && _workCenter && Object.keys(_workCenter).length != 0) {
      setWorkCenterName(_workCenter.workCenterName)
      setWorkCenterNo(_workCenter.workCenterNo)
      setWorkCenterKind(_workCenter.workCenterKind || 0)
      setItem({ label: _workCenter.itemName, value: 0 })
      setArea(_workCenter.area)
      setAreaUnit(_workCenter.areaUnitSeq || 0)
      setCropMethod1(_workCenter.cropMethod1 || 0)
      setCropMethod2(_workCenter.cropMethod2 || 0)
      setAreaCnt(_workCenter.areaCnt)
      setTimeKind(_workCenter.timeKind || 0)
      setIsUse(_workCenter.isUse == 1)
      setEtc(_workCenter.remark)
    }

    return () => {
      setWorkCenterName("")
      setWorkCenterNo("")
      setWorkCenterKind(0)
      setItem({ label: "", value: 0 })
      setArea("")
      setAreaUnit(0)
      setCropMethod1(0)
      setCropMethod2(0)
      setAreaCnt("")
      setTimeKind(0)
      setIsUse(true)
      setEtc("")
    }
  }, [open])

  let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.02.28

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  const onClickSave = async () => {
    if (workCenterName == "") return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("workCenterName")) })
    else if (workCenterNo == "") return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("workCenterNo")) })
    else if (areaCnt == "") return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("areaCnt")) })
    else if (timeKind == 0) return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Please select a product."), dictCk("timeKind")) })
    else if (area != "" && areaUnit == 0) return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Please select a product."), dictCk("areaUnit")) })

    const param = {
      workCenterName: workCenterName,
      workCenterNo: workCenterNo,
      workCenterKind: workCenterKind,
      area: area || 0,
      areaUnitSeq: areaUnit,
      itemSeq: item.value,
      cropMethod1: cropMethod1,
      cropMethod2: cropMethod2,
      areaCnt: areaCnt || 0,
      timeKind: timeKind, // 1(specified time), 2(sunrise/sunset(sensor))
      isUse: isUse ? 1 : 0,
      remark: etc,
    }
    if (_workCenter) param.workCenterSeq = _workCenter.workCenterSeq

    onClose(dictCk("save"), param)
  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose(null)}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <div
        style={{
          backgroundColor: "#fff",
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "800px",
          padding: "23px 51px 44px 40px",
        }}
      >
        <Title>{dictCk("workCenterInfo")}</Title>
        <div style={{ flex: 1 }}>
          <FlexBox>
            <Label>
              <RequiredMark /> {dictCk("workCenterName")}
            </Label>
            <InputBox
              style={{ width: "300px", marginRight: 0 }}
              type="text"
              value={workCenterName}
              onChange={(e) => setWorkCenterName(e.target.value)}
            />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dictCk("workCenterNo")}
            </Label>
            <InputBox style={{ width: "300px", marginRight: 0 }} type="text" value={workCenterNo} onChange={(e) => setWorkCenterNo(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("workCenterKind")}</Label>
            <ComboBox
              style={{ width: "300px" }}
              defaultValue={dictCk("select")}
              hasDefault={true}
              items={
                _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1001).length > 0
                  ? _codeList.filter((i) => i.majorSeq == 1001)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                  : []
              }
              value={workCenterKind}
              setValue={setWorkCenterKind}
            />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("areaUnit")}</Label>
            <InputBox style={{ width: "98px", marginRight: 0 }} type="number" value={area} onChange={(e) => setArea(e.target.value)} />
            <ComboBox
              style={{ width: "96px", marginLeft: "6px" }}
              defaultValue={dictCk("select")}
              hasDefault={true}
              items={
                _codeList.length > 0 && _codeList.filter((i) => i.majorKind == 1 && i.majorSeq == 1007).length > 0
                  ? _codeList
                      .filter((i) => i.majorKind == 1 && i.majorSeq == 1007)[0]
                      .minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                  : []
              }
              value={areaUnit}
              setValue={setAreaUnit}
            />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("cropItem")}</Label>
            <CodeHelpBox
              style={{ width: "300px" }}
              placeholder={dictCk("itemName")}
              codeHelpSeq={1}
              // codeHelpParam={{}}
              value={item}
              setValue={setItem}
            />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("cropMethod1")}</Label>
            <ComboBox
              style={{ width: "300px" }}
              defaultValue={dictCk("select")}
              hasDefault={true}
              items={
                _codeList.length > 0 && _codeList.filter((i) => i.majorKind == 2 && i.majorSeq == 1007).length > 0
                  ? _codeList
                      .filter((i) => i.majorKind == 2 && i.majorSeq == 1007)[0]
                      .minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                  : []
              }
              value={cropMethod1}
              setValue={setCropMethod1}
            />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("cropMethod2")}</Label>
            <ComboBox
              style={{ width: "300px" }}
              defaultValue={dictCk("select")}
              hasDefault={true}
              items={
                _codeList.length > 0 && _codeList.filter((i) => i.majorKind == 2 && i.majorSeq == 1008).length > 0
                  ? _codeList
                      .filter((i) => i.majorKind == 2 && i.majorSeq == 1008)[0]
                      .minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                  : []
              }
              value={cropMethod2}
              setValue={setCropMethod2}
            />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dictCk("areaCnt")}
            </Label>
            <InputBox style={{ width: "300px", marginRight: 0 }} type="number" value={areaCnt} onChange={(e) => setAreaCnt(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dictCk("timeKind")}
            </Label>
            <ComboBox
              style={{ width: "300px" }}
              defaultValue={dictCk("select")}
              hasDefault={true}
              items={timeKindList}
              value={timeKind}
              setValue={setTimeKind}
            />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("isUse")}</Label>
            <TableCheckBox check={isUse} setCheck={setIsUse} />
          </FlexBox>
          <FlexBox alignItems="flex-start">
            <Label>{dictCk("remark")}</Label>
            <TextAreaBox width={0} value={etc} setValue={setEtc} />
          </FlexBox>
        </div>

        <ButtonBox>
          <Button
            bgColor={"#fff"}
            hover={"rgba(174, 174, 174, 0.22)"}
            border={"1px solid #c6c6c6"}
            color={"#555555cc"}
            onClick={() => onClose(dictCk("cancel"), null)}
          >
            {dictCk("cancel")}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {dictCk("save")}
          </Button>
        </ButtonBox>
      </div>
    </Dialog>
  )
}

const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  width: 147px;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

WorkCenterAddPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default CommonContainer(WorkPlaceInfoContainer(withTranslation()(WorkCenterAddPopup)))
