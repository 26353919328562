// 웹 헤더
import React from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import cookie from "react-cookies"
import MainContainer from "../../containers/MainContainer"
import icon_logo from "images/circleLogo.png"
import icon_profile_green from "images/profile_green.png"
import icon_alarm_green from "images/alarm_green.png"
import icon_alarm_gray from "images/alarm_gray.png"
import icon_setting_green from "images/setting_green.png"
import icon_setting_gray from "images/setting_gray.png"
import icon_logout_green from "images/logout_green.png"
import icon_logout_gray from "images/logout_gray.png"

const Header = (props) => {
    const { _userInfo, _moduleList, getMenuList, postSignOut } = props

    const onClickMenu = async (module) => {
        const path = module.moduleId.charAt(0).toLowerCase() + module.moduleId.slice(1)
        cookie.save("moduleId", module.moduleId, { path: "/" })
        navigate(`/app/${path}`)
    }

    return (
        <>
            {_moduleList.length > 0 && (
                <Container>
                    <LeftBox>
                        <Logo
                            isLogo={_userInfo.photoUri}
                            src={(_userInfo.farm && _userInfo.farm.photoUri) || icon_logo}
                            alt={"로고"}
                            onClick={() => navigate("/app/main")}
                        />
                        <TitleView>
                            <Version>ELE-FARM</Version>
                            <FarmName>{(_userInfo.farm && _userInfo.farm.farmName) || "농장이름"}</FarmName>
                        </TitleView>
                        <div style={{ backgroundColor: "#979797", opacity: "16%", width: "1px", height: "71px" }} />
                        {/* 메뉴 파트 */}
                        <MenuView>
                            {_moduleList
                                .filter((i) => i.moduleSeq != 99)
                                .map((menuItem, menuIndex) => (
                                    <MenuItem
                                        key={menuIndex}
                                        onClick={() => onClickMenu(menuItem)}
                                        opacity={window.location.pathname.toLowerCase().includes(menuItem.moduleId.toLowerCase()) ? "100%" : "50%"}
                                        borderBottom={
                                            window.location.pathname.toLowerCase().includes(menuItem.moduleId.toLowerCase())
                                                ? "4px solid #52935d"
                                                : "4px solid #fff"
                                        }
                                    >
                                        {menuItem.moduleName}
                                    </MenuItem>
                                ))}
                        </MenuView>
                    </LeftBox>
                    <RightBox>
                        <ProfileView>
                            <img src={icon_profile_green} alt={""} />
                            <p>{_userInfo.userName || ""}</p>
                        </ProfileView>
                        {/* <AlarmButton onClick={() => navigate("/app/alarm")}>
            <img src={icon_alarm_gray} alt={"알람"} />
          </AlarmButton> */}

                        {_moduleList.find((i) => i.moduleSeq == 99) != undefined && (
                            <SettingButton
                                onClick={async () => {
                                    const result = await getMenuList({ moduleSeq: 99 })
                                    if (result) {
                                        cookie.save("moduleId", _moduleList.find((i) => i.moduleSeq == 99).moduleId, { path: "/" })
                                        navigate("/app/systemSetting")
                                    }
                                }}
                            >
                                <img
                                    src={
                                        window.location.pathname.toLowerCase().includes("/app/systemSetting".toLowerCase())
                                            ? icon_setting_green
                                            : icon_setting_gray
                                    }
                                    alt={"설정"}
                                />
                            </SettingButton>
                        )}

                        <LogoutButton
                            onClick={async () => {
                                const result = await postSignOut()
                                if (result) {
                                    navigate("/app/login")
                                }
                            }}
                        >
                            <img src={icon_logout_gray} alt={"로그아웃"} />
                        </LogoutButton>
                    </RightBox>
                </Container>
            )}
        </>
    )
}

const Container = styled.div`
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 96px;
    padding: 0px 17px;
    border-bottom: 2px solid #e2e2e2;
`
const LeftBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
`
const Logo = styled.img`
    cursor: pointer;
    width: 70px;
    height: 70px;
    border-radius: ${(props) => (props.isLogo ? "50%" : 0)};
`
const TitleView = styled.div`
    width: 206px;
    margin-left: 28px;
    /* 화면 너비가(가로) 1024px 이하일 때 스타일을 변경합니다. */
    @media (max-width: 1024px) {
        width: 130px; /* width 값 조정 */
        margin-left: 10px; /* margin-left 값 조정 */
    }
`
const Version = styled.p`
    opacity: 40%;
    color: #555555;
    font-size: 14px;
    /* 화면 너비가(가로) 1024px 이하일 때 스타일을 변경합니다. */
    @media (max-width: 1024px) {
        font-size: 11px; /* 글씨 크기 조정 */
    }
`
const FarmName = styled.p`
    color: #555555;
    font-size: 16px;
    /* 화면 너비가(가로) 1024px 이하일 때 스타일을 변경합니다. */
    @media (max-width: 1024px) {
        font-size: 13px; /* 글씨 크기 조정 */
    }
`

const MenuView = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 94px;
    margin-left: 25px;
    /* 화면 너비가(가로) 1024px 이하일 때 스타일을 변경합니다. */
    @media (max-width: 1024px) {
        margin-left: 10px; /* margin-left 값 조정 */
    }
`

const MenuItem = styled.button`
    cursor: pointer;
    display: block;
    height: 94px;
    padding: 0 25px;
    border-bottom: ${(props) => props.borderBottom};
    opacity: ${(props) => props.opacity};
    color: #555555;
    font-size: 16px;
    /* 화면 너비가(가로) 1024px 이하일 때 스타일을 변경합니다. */
    @media (max-width: 1024px) {
        font-size: 13px; /* 글씨 크기 조정 */
        padding: 0 10px; /* padding 값 조정 */
    }
`
const RightBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`
const ProfileView = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 61px;
    & > img {
        width: 20px;
        height: 26px;
        margin-right: 14px;
    }
    & > p {
        color: #555555;
        font-size: 18px;
        line-height: 20px;
    }

    /* 화면 너비가(가로) 1024px 이하일 때 스타일을 변경합니다. */
    @media (max-width: 1024px) {
        margin-right: 10px; /* margin-right 값 조정 */
        & > p {
            font-size: 16px; /* font-size 값 조정 */
            line-height: 18px; /* line-height 값 조정 */
        }
    }
`
const AlarmButton = styled.button`
    width: 44px;
    height: 40px;
    & > img {
        width: 22px;
        height: 28px;
    }
    & > img:hover {
        content: url(${icon_alarm_green});
    }
`
const SettingButton = styled.button`
    width: 48px;
    height: 48px;
    & > img {
        width: 24px;
        height: 24px;
    }
    & > img:hover {
        content: url(${icon_setting_green});
    }
`
const LogoutButton = styled.button`
    width: 48px;
    height: 48px;
    & > img {
        width: 24px;
        height: 24px;
    }
    & > img:hover {
        content: url(${icon_logout_green});
    }
`

Header.propTypes = {}

export default MainContainer(Header)
