import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
// import moment from "moment"
// import imageApis from "../../../apis/ImageApis"

import CommonContainer from "../../../containers/CommonContainer"
// import CultivationJournalContainer from "../../../containers/cropManage/CultivationJournalContainer"

import StaffManagementContainer from "../../../containers/production/StaffManagementContainer"
// import BasicPopup from "../BasicPopup"
// import RequiredMark from "../../component/RequiredMark"
// import InputBox from "../../component/common/InputBox"
// import DateBox from "../../component/common/DateBox"
// import ComboBox from "../../component/common/ComboBox"
// import TextAreaBox from "../../component/common/TextAreaBox"
// import AttchImageBox from "../../component/common/AttchImageBox"

import btn_side_left_on from "images/btn_side_left_on.png"
import btn_side_right_on from "images/btn_side_right_on.png"

import btn_side_up_on from "images/btn_side_up_on.png"
import btn_side_down_on from "images/btn_side_down_on.png"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
      maxWidth: "960px",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const imgListPopup = (props) => {
  const classes = useStyles()
  const { open, onClose, infoItem, t } = props
  
  console.log('<<<<<<<<<<<<<<<<<<<<<<<')
  console.log(infoItem)

  const handleClose = (value) => {
    onClose(value)
  }

  const itemLength = Object.keys(infoItem).length 

  let index = 0;
  
  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => handleClose(null)}
      open={open}
      fullWidth={true}
    >
      {/* 이전사진 */}
      <button onClick={() => {
        if ( index != 0 ) {
              index = index-1
              document.getElementById("targetImg").src = itemLength > 0 && infoItem.imageUri.length > 0 ? infoItem.imageUri.map((i) => i.fileUri)[index] : [][0]
              document.getElementById("targetImg").alt = itemLength > 0 && infoItem.imageUri.length > 0 ? infoItem.imageUri.map((i) => i.fileName)[index] : [][0]
              console.log(index)
              console.log(infoItem.imageUri.length)
            }
      }}><img src={btn_side_up_on} alt="" style={{width: '40px', height: '40px', }}></img></button>
     
      {/* 중간사진 */}
      <img src={(itemLength > 0 && infoItem.imageUri.length > 0 ? infoItem.imageUri.map((i) => i.fileUri)[0] : [][0])} 
          alt={(itemLength > 0 && infoItem.imageUri.length > 0 ? infoItem.imageUri.map((i) => i.fileName)[0] : [][0])}
          id = {'targetImg'} />
      
      {/* 다음사진 */}
      <button onClick={() => {  
        if ( index != infoItem.imageUri.length -1 ) {
              index = index +1;
              document.getElementById("targetImg").src = itemLength > 0 && infoItem.imageUri.length > 0 ? infoItem.imageUri.map((i) => i.fileUri)[index] : [][0]
              document.getElementById("targetImg").alt = itemLength > 0 && infoItem.imageUri.length > 0 ? infoItem.imageUri.map((i) => i.fileName)[index] : [][0]
            }
      }}><img src={btn_side_down_on} alt="" style={{width: '40px', height: '40px', }}></img></button>
    </Dialog>
   
  )
}


export default CommonContainer(StaffManagementContainer(withTranslation()(imgListPopup)))

const Container = styled.div`
  padding: 23px 47px 44px;
`

// const ContentView = styled.section`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   margin: 30px 0px;
// `

// const Content = styled.p`
//   color: #555555;
//   font-size: 15px;
//   text-align: center;
//   white-space: pre-line;
// `

// const ButtonView = styled.section`
//   display: flex;
//   justify-content: center;
//   align-items: center;
// `

// const Button = styled.button`
//   background-color: ${(props) => (props.one ? "#fff" : "#52935d")};
//   width: 147px;
//   height: 40px;
//   border: ${(props) => (props.one ? "1px solid #c6c6c6" : "none")};
//   border-radius: 3px;
//   color: ${(props) => (props.one ? "#555555" : "#fff")};
//   font-size: 14px;

//   &:nth-child(2) {
//     margin-left: 14px;
//   }
// `


