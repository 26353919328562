import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  setCropPlanList,
  getCropPlanList,
  setCropWorkCenterPlanList,
  getCropWorkCenterPlanList,
  editCropWorkCenterPlan,
  delCropWorkCenterPlan,
} = actions.ProductionAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _cropPlanList: state.ProductionReducer._cropPlanList,
  _cropWorkCenterPlanList: state.ProductionReducer._cropWorkCenterPlanList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  setCropPlanList: (param) => dispatch(setCropPlanList(param)),
  getCropPlanList: (param) => dispatch(getCropPlanList(param)),
  setCropWorkCenterPlanList: (param) => dispatch(setCropWorkCenterPlanList(param)),
  getCropWorkCenterPlanList: (param) => dispatch(getCropWorkCenterPlanList(param)),
  editCropWorkCenterPlan: (param) => dispatch(editCropWorkCenterPlan(param)),
  delCropWorkCenterPlan: (param) => dispatch(delCropWorkCenterPlan(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
