// 회원가입
import React, { useState } from "react"
import { navigate } from "gatsby"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { onlineTermsOfUse, businessRules, serviceLevelAgreement, privacyPolicy } from "utils/terms"
import SignContainer from "../../containers/SignContainer"
import SEO from "../seo"
import CircleCheckBox from "../component/common/CircleCheckBox"
import Radio from "../component/common/Radio"
import RequiredMark from "../component/RequiredMark"
import { GreenButton } from "../component/common/Button"
// import DateBox from "../component/common/DateBox"
import DateBoxSignUp from "../component/common/DateBoxSignUp"

import InputBox from "../component/common/InputBox"
import InputTelBox from "../component/common/InputTelBox"
import BasicPopup from "../popup/BasicPopup"
import DaumPostCodePopup from "../popup/DaumPostCodePopup"
import icon_search from "images/search.png"
import icon_back_gray from "images/back_gray.png"
import icon_back_green from "images/back_green.png"
import ComboBox from "../component/common/ComboBox"
import i18n from "../../lang/i18n"

const SignUp = (props) => {
    const { t, postSignUp } = props
    const [popup, setPopup] = useState({ open: false, text: "" })
    const [confirmPopup, setConfirmPopup] = useState({ open: false, text: "" })
    const [daumPostCodePopup, setDaumPostCodePopup] = useState(false)
    const [tab, setTab] = useState(0)
    // 약관
    const [check1, setCheck1] = useState(false)
    const [check2, setCheck2] = useState(false)
    const [check3, setCheck3] = useState(false)
    const [check4, setCheck4] = useState(false)
    // 가입정보
    const [applicant, setApplicant] = useState("")
    const [farmName, setFarmName] = useState("")
    const [farmer, setFarmer] = useState("")
    const [buisnessNo, setBuisnessNo] = useState("")
    const [startDate, setStartDate] = useState("")
    const [zipcode, setZipcode] = useState("")
    const [addr, setAddr] = useState("")
    const [sido, setSido] = useState("")
    const [addrDetail, setAddrDetail] = useState("")
    const [xy, setXY] = useState({ x: 0, y: 0 }) // 좌표
    // 세금계산서 수령 정보
    const [radioIndex, setRadioIndex] = useState(0)
    // 마스터 ID 정보
    const [check5, setCheck5] = useState(false) // 신청자정보와 동일
    const [id, setId] = useState("")
    const [password, setPassword] = useState("")
    const [passCheck, setPassCheck] = useState("")
    const [phone, setPhone] = useState("")
    const [email, setEmail] = useState("")
    const [cLanguageSeq, setCLanguageSeq] = useState(0)

    let languageSeq = ""

    if (i18n.language == "ko") {
        languageSeq = 10010002
    } else if (i18n.language == "en") {
        languageSeq = 10010001
    } else if (i18n.language == "vi") {
        languageSeq = 10010003
    } else if (i18n.language == "zh") {
        languageSeq = 10010004
    } else if (i18n.language == "tw") {
        languageSeq = 10010005
    } else if (i18n.language == "ja") {
        languageSeq = 10010006
    }

    const onClickNext = () => {
        if (check1 && check2 && check3 && check4) setTab(1)
        else setPopup({ open: true, text: t("please agree to all terms and conditions") })
    }

    const onClickBack = () => {
        navigate("/app/login")
    }

    const onClickSignUp = async () => {
        // 가입정보
        if (applicant === "") return setPopup({ open: true, text: t("n.Invalid value. Please try again.", { n: t("applicant") }) })
        else if (farmName === "") return setPopup({ open: true, text: t("n.Invalid value. Please try again.", { n: t("farmName") }) })
        else if (farmer === "") return setPopup({ open: true, text: t("n.Invalid value. Please try again.", { n: t("farmer") }) })
        else if (startDate === "") return setPopup({ open: true, text: t("n.Invalid value. Please try again.", { n: t("farmStartDate") }) })
        else if (radioIndex == 0 && buisnessNo === "")
            return setPopup({ open: true, text: t("n.Invalid value. Please try again.", { n: t("bizNo") }) })
        else if (addr === "") return setPopup({ open: true, text: t("n.Invalid value. Please try again.", { n: t("address") }) })
        else if (addrDetail === "") return setPopup({ open: true, text: t("n.Invalid value. Please try again.", { n: t("addrDetail") }) })
        else if (startDate.replace(/-/gi, "").length != 8 && !/^[0-9]+$/.test(startDate.replace(/-/gi, "")))
            return setPopup({ open: true, text: t("n.it was entered incorrectly", { n: t("farmStartDate") }) })
        // 마스터 ID 정보
        else if (id === "") return setPopup({ open: true, text: t("n.Invalid value. Please try again.", { n: t("masterId") }) })
        else if (password === "") return setPopup({ open: true, text: t("n.Invalid value. Please try again.", { n: t("password") }) })
        else if (passCheck === "") return setPopup({ open: true, text: t("n.Invalid value. Please try again.", { n: t("passwordVerification") }) })
        else if (password !== passCheck) return setPopup({ open: true, text: t("Password verification does not match.") })
        else if (phone === "") return setPopup({ open: true, text: t("n.Invalid value. Please try again.", { n: t("cellNo") }) })
        else if (email !== "" && !/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(email))
            return setPopup({ open: true, text: t("n.Invalid format. Please try again.", { n: t("email") }) })
        else if (cLanguageSeq == 0) return setPopup({ open: true, text: t("n.Please select a product.", { n: t("langSetting") }) })

        const result = await postSignUp({
            applyName: applicant,
            farmName: farmName,
            farmOwner: farmer,
            bizNo: buisnessNo,
            farmStartDate: startDate.replace(/-/gi, ""), // 20210404,
            zipCode: zipcode,
            addr: addr, // 주소
            addrZip: sido, // 시/도
            px: xy.x,
            py: xy.y,
            addrDetail: addrDetail,
            userId: id,
            userPw: password,
            cellNo: phone,
            teleNo: "",
            email: email,
            isCfmSmartFarm: "1", //
            isCfmBizRule: "1", //
            isCfmServConv: "1", //
            isCfmPerInfo: "1", //
            billRecvKind: radioIndex + 1, // 1: 회사정보와 동일 2: 수령하지 않음
            languageSeq: languageSeq,
            cLanguageSeq: cLanguageSeq,
        })

        if (result) {
            setConfirmPopup({
                open: true,
                text: `${t("your application for membership has been completed")}\n${t("after registration is approved, you can log in")}`,
            })
        }
    }

    const onConfirmClose = (value) => {
        if (value == t("confirm")) {
            navigate("/app/login")
        }
    }

    return (
        <Container>
            <SEO title={t("signUp")} />
            <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: "" })} content={popup.text} selectedValue={[t("confirm")]} />
            {/* 회원가입완료시 팝업 */}
            <BasicPopup
                open={confirmPopup.open}
                onClose={(value) => onConfirmClose(value)}
                content={confirmPopup.text}
                selectedValue={[t("confirm")]}
            />
            <DaumPostCodePopup
                open={daumPostCodePopup}
                onClose={() => setDaumPostCodePopup(false)}
                onComplete={async (addr) => {
                    setZipcode(addr.zonecode)
                    setAddr(addr.userSelectedType == "R" ? addr.roadAddress : addr.jibunAddress)
                    setSido(addr.sido)
                    setAddrDetail("")
                    setDaumPostCodePopup(false)
                    const geocoder = new daum.maps.services.Geocoder()
                    await geocoder.addressSearch(addr.address, (result, status) => {
                        if (status === daum.maps.services.Status.OK) {
                            const { x, y } = result[0]
                            setXY({ x, y })
                        }
                    })
                }}
            />
            {tab == 0 && (
                <Tab1Container>
                    <Title>{t("membership application")}</Title>

                    <TermsSection>
                        <InfoBox>
                            <p>{`${t("subscriptionNotice")}`}</p>
                        </InfoBox>

                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-end" }}>
                            <CircleCheckBox
                                style={{ marginTop: "9px" }}
                                text={t("totalAgree")}
                                check={check1 && check2}
                                setCheck={() => {
                                    if (check1 && check2) {
                                        setCheck1(false)
                                        setCheck2(false)
                                        setCheck3(false)
                                        setCheck4(false)
                                    } else {
                                        setCheck1(true)
                                        setCheck2(true)
                                        setCheck3(true)
                                        setCheck4(true)
                                    }
                                }}
                            />
                            <TermsBox readOnly value={onlineTermsOfUse}></TermsBox>
                            <CircleCheckBox style={{ marginTop: "4px" }} text={t("I agree to the above")} check={check1} setCheck={setCheck1} />
                            <TermsBox readOnly value={businessRules}></TermsBox>
                            <CircleCheckBox style={{ marginTop: "4px" }} text={t("I agree to the above")} check={check2} setCheck={setCheck2} />
                            <TermsBox readOnly value={serviceLevelAgreement}></TermsBox>
                            <CircleCheckBox style={{ marginTop: "4px" }} text={t("I agree to the above")} check={check3} setCheck={setCheck3} />
                            <SubTitle>{t("collection of personal information")}</SubTitle>
                            <TermsBox readOnly value={privacyPolicy}></TermsBox>
                            <CircleCheckBox style={{ marginTop: "4px" }} text={t("I agree to the above")} check={check4} setCheck={setCheck4} />
                        </div>
                    </TermsSection>

                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "26px" }}>
                        <BackButton onClick={onClickBack}>
                            <div id={"backIcon"} />
                            <p>{t("backToLogin")}</p>
                        </BackButton>
                        <BottomSection>
                            <GreenButton style={{ width: "200px", height: "56px" }} onClick={onClickNext}>
                                {t("toTheNext")}
                            </GreenButton>
                        </BottomSection>
                    </div>
                </Tab1Container>
            )}
            {tab == 1 && (
                <Tab2Container>
                    <InputSection>
                        <p style={{ marginBottom: "10px", color: "#555555", fontSize: "17px", lineHeight: "32px" }}>
                            {t("subscription information")}
                        </p>
                        <div style={{ marginBottom: "34px" }}>
                            <InputComponent label={t("applicant")} value={applicant} setValue={setApplicant} required />
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <InputComponent label={t("farmName")} value={farmName} setValue={setFarmName} required />
                                <InputComponent label={t("farmer")} value={farmer} setValue={setFarmer} required />
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <InputView>
                                    <Label>
                                        <RequiredMark /> {t("farmStartDate")}
                                    </Label>
                                    <DateBoxSignUp
                                        style={{ width: "200px", height: "35px", marginRight: 0 }}
                                        stdDate={startDate}
                                        setStdDate={setStartDate}
                                    />
                                </InputView>
                                <InputComponent
                                    label={t("bizNo")}
                                    placeholder={"000 - 00 - 00000"}
                                    mask="### - ## - #####"
                                    value={buisnessNo}
                                    setValue={setBuisnessNo}
                                    required={radioIndex == 0}
                                />
                            </div>
                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                <InputComponent label={t("zipCode")} value={zipcode} setValue={() => {}} required />
                                <div style={{ flex: 1, marginBottom: "12px" }}>
                                    <SearchButton onClick={() => setDaumPostCodePopup(true)}>
                                        <img src={icon_search} />
                                    </SearchButton>
                                </div>
                            </div>
                            <div>
                                <InputComponent label={t("address")} contentType="2" value={addr} setValue={() => {}} required />
                            </div>
                            <div>
                                <InputComponent label={t("addrDetail")} contentType="2" value={addrDetail} setValue={setAddrDetail} required />
                            </div>
                        </div>

                        <p style={{ marginBottom: "10px", color: "#555555", fontSize: "17px", lineHeight: "32px" }}>
                            {t("tax Invoice Receipt Information")}
                        </p>
                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginBottom: "34px" }}>
                            <p style={{ opacity: "80%", color: "#555555", fontSize: "14px" }}>{t("tax Invoice Receipt Information")}</p>
                            <Radio
                                style={{ marginLeft: "15px" }}
                                text={t("same as company information")}
                                idx={0}
                                check={radioIndex}
                                setCheck={setRadioIndex}
                            />
                            <Radio
                                style={{ marginLeft: "15px" }}
                                text={`${t("notReceived")}(${t("noBizNo")})`}
                                idx={1}
                                check={radioIndex}
                                setCheck={setRadioIndex}
                            />
                        </div>

                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginBottom: "10px" }}>
                            <p style={{ color: "#555555", fontSize: "17px", lineHeight: "32px" }}>{t("masterIdInfo")}</p>
                            {/* <CheckBox style={{ marginLeft: "9px" }} text="신청자정보와 동일" check={check5} setCheck={setCheck5} /> */}
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <InputComponent label={t("id")} value={id} setValue={setId} required />
                            <ComboComponent
                                required
                                label={t("langSetting")}
                                defaultValue={t("select")}
                                hasDefault={true}
                                items={[
                                    { value: "10010001", label: "English" },
                                    { value: "10010002", label: "한국어" },
                                    { value: "10010003", label: "Tiếng Việt" },
                                    { value: "10010004", label: "简体中文" },
                                    { value: "10010005", label: "繁體中文" },
                                    { value: "10010006", label: "日本語" },
                                ]}
                                value={cLanguageSeq}
                                setValue={setCLanguageSeq}
                            />
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <InputComponent type="password" label={t("password")} value={password} setValue={setPassword} required />
                            <InputComponent type="password" label={t("passwordVerification")} value={passCheck} setValue={setPassCheck} required />
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <InputView>
                                <Label>
                                    <RequiredMark /> {t("cellNo")}
                                </Label>
                                <InputTelBox style={{ width: "200px", marginRight: 0 }} value={phone} setValue={setPhone} />
                            </InputView>
                            <InputComponent label={t("email")} value={email} setValue={setEmail} />
                        </div>
                    </InputSection>
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "26px" }}>
                        <BackButton onClick={onClickBack}>
                            <div id={"backIcon"} />
                            <p>{t("backToLogin")}</p>
                        </BackButton>
                        <BottomSection>
                            <GreenButton style={{ width: "200px", height: "56px" }} onClick={onClickSignUp}>
                                {t("signUpConfirm")}
                            </GreenButton>
                        </BottomSection>
                    </div>
                </Tab2Container>
            )}
        </Container>
    )
}

// COMMON
const Container = styled.div`
    background-color: #f6f6f6;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`
const BottomSection = styled.section`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    /* width: 100%;
  margin-top: 26px; */
`

const BackButton = styled.button`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 148px;
    & > div#backIcon {
        background-image: url(${icon_back_gray});
        background-size: 18px 15px;
        background-repeat: no-repeat;
        width: 18px;
        height: 15px;
        margin-right: 10px;
    }
    & > p {
        opacity: 80%;
        color: #555555;
        font-size: 14px;
        line-height: 32px;
    }

    &:hover {
        & > div#backIcon {
            background-image: url(${icon_back_green});
        }
    }
`

// TAB0
const Tab1Container = styled.div`
    width: 660px;
`
const Title = styled.p`
    opacity: 80%;
    margin-bottom: 30px;
    color: #555555;
    font-size: 17px;
    text-align: center;
`
const TermsSection = styled.section`
    background-color: #fff;
    padding: 27px 60px;
    border: 1px solid #fff;
    border-radius: 6px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const InfoBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 69px;
    opacity: 80%;
    & > p {
        opacity: 80%;
        color: #555;
        font-size: 15px;
        line-height: 26px;
        letter-spacing: -0.5px;
        text-align: center;
        white-space: pre;
    }
`
const TermsBox = styled.textarea`
    background-color: #fafafa;
    width: 100%;
    height: 90px;
    margin-top: 9px;
    padding: 9px 12px;
    border: 1px solid #dedede;
    border-radius: 3px;
    resize: none;
    opacity: 70%;
    color: #555;
    font-size: 12px;
`
const SubTitle = styled.p`
    width: 100%;
    opacity: 70%;
    color: #555555;
    font-size: 11px;
    text-align: left;
`

// TAB1
const Tab2Container = styled.div`
    width: 780px;
`
const InputSection = styled.section`
    background-color: #fff;
    padding: 27px 57px 46px;
    border: 1px solid #fff;
    border-radius: 6px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`

const InputComponent = ({ contentType = "", type = "text", label = "", placeholder = "", mask = "", value, setValue, required }) => {
    //contentType (1 or undefined :너비200px ,2:너비100%)
    return (
        <InputView>
            <Label>
                {required ? <RequiredMark /> : ""}
                {label}
            </Label>
            <div style={{ flex: "1" }}>
                <InputBox
                    style={{ width: contentType == "2" ? "100%" : "200px", height: "35px", marginRight: 0 }}
                    type={type}
                    placeholder={placeholder}
                    mask={mask}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                />
            </div>
        </InputView>
    )
}

const ComboComponent = ({
    contentType = "",
    type = "text",
    label = "",
    placeholder = "",
    mask = "",
    value,
    setValue,
    required,
    defaultValue = "",
    hasDefault = true,
    items = {},
}) => {
    //contentType (1 or undefined :너비200px ,2:너비100%)
    return (
        <InputView>
            <Label>
                {required ? <RequiredMark /> : ""}
                {label}
            </Label>
            <div style={{ flex: "1" }}>
                <ComboBox
                    style={{ width: contentType == "2" ? "100%" : "200px", height: "35px", marginRight: 0 }}
                    type={type}
                    placeholder={placeholder}
                    value={value}
                    setValue={setValue}
                    defaultValue={defaultValue}
                    hasDefault={hasDefault}
                    items={items}
                />
            </div>
        </InputView>
    )
}

const InputView = styled.div`
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 12px;
    &:nth-child(2) {
        margin-left: 48px;
    }
    & input {
        background-color: #fafafa;
    }
`
const Label = styled.p`
    opacity: 80%;
    margin-bottom: 3px;
    width: 109px;
    color: #555555;
    font-size: 14px;
    line-height: 32px;
`
const SearchButton = styled.button`
    width: 30px;
    height: 30px;
    margin-left: -15px;
    border: 1px solid #dedede;
    border-radius: 3px;
    & > img {
        width: 17px;
        height: 17px;
    }
    &:hover {
        border: 1px solid #52935d;
    }
`

export default SignContainer(withTranslation()(SignUp))
