// 기본정보 > 농장
import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import moment from "moment"
import imageApis from "../../../apis/ImageApis"

import SignContainer from "../../../containers/SignContainer"
import FarmContainer from "../../../containers/defaultInfo/FarmContainer"

import PageName from "../../component/common/PageName"
import RequiredMark from "../../component/RequiredMark"
import ComboBox from "../../component/common/ComboBox"
import { GreenButton } from "../../component/common/Button"
import DateBox from "../../component/common/DateBox"
import InputBox from "../../component/common/InputBox"
import BasicPopup from "../../popup/BasicPopup"
// import DaumPostCodePopup from "../../popup/DaumPostCodePopup" // 다음 주소검색 api 삭제 - 윤여광 2024.03.12
import GooglePostCodePopup from "../../popup/GooglePostCodePopup" // 주소검색 구글 api - 차건담 2023.08.22
import icon_defaultImg from "images/defaultImg.png"
import icon_search from "images/search.png"
import InputTelBox from "../../component/common/InputTelBox"

import CommonContainer from "../../../containers/CommonContainer" // 다국어 사전 가져오기 위해 import - 차건담 2023.03.02
import CircularIndeterminate from "../../component/common/CircularIndeterminate" //로딩화면

const FarmInfo = (props) => {
    const {
        t,
        setUserInfo,
        _userInfo,

        _farmInfo,
        setFarmInfo,
        getFarmInfo,
        editFarmInfo,
        _codeList,
    } = props

    // 사전 관련 객체 가져오기 - 차건담 2023.03.02
    const { initTranslationWords, getTranslationWords, _translationWords, replaceWord } = props

    const [popup, setPopup] = useState({ open: false, text: "" })
    const [googleAddrPopup, setGoogleAddrPopup] = useState(false) // 다음 주소검색 api 대신 구글 api 를 사용하므로 이름 변경 - 윤여광 2024.03.12
    // leftbox
    const [farmer, setFarmer] = useState("")
    const [farmName, setFarmName] = useState("")
    const [field, setField] = useState(-1)
    const [sectors, setSectors] = useState(-1)
    const [startDate, setStartDate] = useState(moment())
    const [careerYear, setCareerYear] = useState("")
    const [zipcode, setZipcode] = useState("")
    const [addr, setAddr] = useState("")
    const [sido, setSido] = useState("")
    const [addrDetail, setAddrDetail] = useState("")
    const [xy, setXY] = useState({ x: 0, y: 0 }) // 좌표
    const [facilityType, setFacilityType] = useState(-1)
    const [productionArea, setProductionArea] = useState("")
    const [areaUnit, setAreaUnit] = useState(-1)
    const [blogUrl, setBlogUrl] = useState("")
    const [homepageUrl, setHomepageUrl] = useState("")
    const [farmImages, setFarmImages] = useState([])

    // rightbox
    const [farmLogo, setFarmLogo] = useState({ file: null, base64: null, imageUrl: null })
    const [businessNameKo, setBusinessNameKo] = useState("")
    const [businessNameEn, setBusinessNameEn] = useState("")
    const [coType, setCoType] = useState(-1)
    const [businessType, setBusinessType] = useState(-1)
    const [businessNo, setBusinessNo] = useState("")
    const [residentNo, setResidentNo] = useState("")
    const [phoneNo, setPhoneNo] = useState("")
    const [teleNo, setTeleNo] = useState("")
    const [faxNo, setFaxNo] = useState("")
    const [email, setEmail] = useState("")

    const [loading, setLoading] = useState(false) //사진업로드 중에 로딩화면

    useEffect(() => {
        getData()
        getTranslationWords() // 사전 가져오는 함수 - 차건담 2023.03.02
        return async () => await setFarmInfo({})
    }, [])

    let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.02.28

    const dictCk = (word) => {
        if (dict == undefined || dict == null || dict == {} || dict == "") {
            return word
        } else {
            const result = dict[word] != undefined && dict[word] != null && dict[word] != "" ? dict[word] : word
            return result
        }
    }

    useEffect(() => {
        if (Object.keys(_farmInfo).length == 0) return
        setFarmer(_farmInfo.farmOwner)
        setFarmName(_farmInfo.farmName)
        if (_codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1002).length > 0) {
            setField(_farmInfo.bizPart)
            setSectors(_farmInfo.bizType)
            setFacilityType(_farmInfo.facilityType)
            setAreaUnit(_farmInfo.areaUnitSeq)
            setCoType(_farmInfo.corpKind)
            setBusinessType(_farmInfo.bizKind)
        }
        setStartDate(moment(_farmInfo.farmStartDate, "YYYYMMDD").isValid() ? moment(_farmInfo.farmStartDate, "YYYYMMDD") : "")
        setCareerYear(_farmInfo.careerYear)
        setZipcode(_farmInfo.zipCode)
        setAddr(_farmInfo.addr)
        setSido(_farmInfo.addr)
        setAddrDetail(_farmInfo.addrDetail)
        setXY({ x: _farmInfo.px, y: _farmInfo.py })
        setProductionArea(_farmInfo.area)
        setBlogUrl(_farmInfo.blogUrl || "")
        setHomepageUrl(_farmInfo.homepageUrl || "")
        setFarmImages(_farmInfo.imageList.sort((a, b) => a.fileSeq - b.fileSeq).map((i) => ({ file: null, base64: null, imageUrl: i.imageUri })))
        setFarmLogo({ file: null, base64: null, imageUrl: _farmInfo.photoUri })
        setBusinessNameKo(_farmInfo.bizName)
        setBusinessNameEn(_farmInfo.bizNameEn)
        setBusinessNo(_farmInfo.bizNo)
        setResidentNo(_farmInfo.citizenNo)
        setPhoneNo(_farmInfo.cellNo)
        setTeleNo(_farmInfo.teleNo)
        setFaxNo(_farmInfo.faxNo)
        setEmail(_farmInfo.email || "")
    }, [_farmInfo])

    const getData = async () => {
        await getFarmInfo()
    }

    const toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })

    const onClickSave = async () => {
        if (farmer == "") return setPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("farmOwner")) })
        else if (farmName == "") return setPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("farmName")) })
        else if (startDate == "") return setPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("days")) })
        else if (zipcode == "") return setPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("zipcode")) })
        else if (addr == "") return setPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("addr")) })
        else if (addrDetail == "")
            return setPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("addrDetail")) })
        else if (phoneNo == "") return setPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("cellNo")) })
        else if (blogUrl != "" && !/(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi.test(blogUrl))
            return setPopup({ open: true, text: replaceWord(dictCk("n.Invalid format. Please try again."), dictCk("blogUrl")) })
        else if (homepageUrl != "" && !/(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi.test(homepageUrl))
            return setPopup({ open: true, text: replaceWord(dictCk("n.Invalid format. Please try again."), dictCk("homepageUrl")) })
        else if (email != "" && !/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(email))
            return setPopup({ open: true, text: replaceWord(dictCk("n.Invalid format. Please try again."), dictCk("email")) })

        let sDate = typeof startDate == "string" ? startDate : startDate.format("YYYYMMDD") // 날짜를 선택하지 않으면 오브젝트 형태여서 else if 조건이 안먹힘startDate == ""
        setLoading(true) // 업로드중 로딩화면 시작

        let imageUrls = []
        if (farmImages.length > 0) {
            const formData = new FormData()
            for (let i = 0; i < farmImages.length; i++) {
                if (farmImages[i].file) {
                    formData.append("images", farmImages[i].file)
                } else {
                    imageUrls.push(farmImages[i].imageUrl)
                }
            }
            const uploadsResult = await imageApis.uploadImages(formData)
            imageUrls.push(...uploadsResult.data.images)
        }

        let logoUrl = null
        if (farmLogo.file) {
            const formData = new FormData()
            formData.append("image", farmLogo.file)
            const uploadResult = await imageApis.uploadImage(formData)
            logoUrl = uploadResult.data.image
        } else if (farmLogo.imageUrl) {
            logoUrl = farmLogo.imageUrl
        }

        const result = await editFarmInfo({
            farmOwner: farmer,
            farmName: farmName,
            bizPart: field,
            bizType: sectors,
            farmStartDate: sDate.length == 8 ? moment(startDate).format("YYYYMMDD") : "",
            careerYear: careerYear,
            zipCode: zipcode,
            addr: addr,
            addrZip: sido,
            px: xy.x,
            py: xy.y,
            addrDetail: addrDetail,
            facilityType: facilityType,
            area: productionArea,
            areaUnitSeq: areaUnit,
            blogUrl: blogUrl,
            homepageUrl: homepageUrl,
            farmImages: imageUrls,
            photoUri: logoUrl,
            bizName: businessNameKo,
            bizNameEn: businessNameEn,
            corpKind: coType,
            bizKind: businessType,
            bizNo: businessNo,
            citizenNo: residentNo,
            cellNo: phoneNo,
            teleNo: teleNo,
            faxNo: faxNo,
            email: email,
            userName: _farmInfo.userName,
        })

        if (result) {
            setUserInfo({ ..._userInfo, farm: { ..._userInfo.farm, farmName, photoUri: logoUrl } })

            setPopup({ open: true, text: dictCk("the requested modifications have been completed.") })
            setLoading(false) // 업로드중 로딩화면 종료
        }
        setLoading(false) // 업로드중 로딩화면 종료
        getData()
    }

    // 주소검색 팝업 창에서 입력된 주소 FarmInfo의 입력창에 입력되게 하는 함수 - 차건담 2023.08.22
    // 기존 : 다음 api 사용 - xy값을 따로 처리
    // 변경 : 구글 api 사용 - xy값을 api로 직접 받아 처리 - 윤여광 2024.03.12
    const typeAddress = async (mainAddr, xy) => {
        // console.log("mainAddr, detailAddr:", mainAddr)
        const detailAddr = document.getElementById("detailAddr")
        setAddr(mainAddr)
        setAddrDetail(detailAddr.value)
        setXY(xy) // 구글 api로 xy값을 직접 받아 처리, 함수의 인수로 받아오게함
        setGoogleAddrPopup(false) // 이름 변경
        // setDaumPostCodePopup(false)
    }

    return (
        <Container>
            <CircularIndeterminate open={loading} />
            <BasicPopup
                open={popup.open}
                onClose={() => setPopup({ open: false, text: "" })}
                content={popup.text}
                selectedValue={[dictCk("confirm")]}
            />
            <GooglePostCodePopup
                _userInfo={_userInfo}
                typeAddress={typeAddress}
                open={googleAddrPopup}
                onClose={() => setGoogleAddrPopup(false)}
                // 완료버튼 클릭시 addr을 받아올 필요가 없으므로 삭제 - 윤여광 2024.03.12
                // onComplete={async (addr) => {
                onComplete={async () => {
                    // setZipcode(addr.zonecode) // 우편번호 직접 입력으로 변경 - 윤여광 2024.03.12
                    // setAddr(addr.userSelectedType == "R" ? addr.roadAddress : addr.jibunAddress)
                    // setSido(addr.sido)
                    setAddrDetail("")
                    setGoogleAddrPopup(false)
                    // 기존 : 다음 api 사용 - xy값을 따로 처리하는 부분
                    // const geocoder = new daum.maps.services.Geocoder()
                    // await geocoder.addressSearch(addr.address, (result, status) => {
                    //     if (status === daum.maps.services.Status.OK) {
                    //         const { x, y } = result[0]
                    //         setXY({ x, y })
                    //     }
                    // })
                }}
            />
            <PageName name={dictCk("farmInfo")} state={false} setState={() => {}} />
            <WhiteBox>
                <InnerView>
                    <LeftBox>
                        <FlexView>
                            <Label>
                                <RequiredMark /> {dictCk("farmOwner")}
                            </Label>
                            <InputBox style={{ width: "200px" }} type="text" value={farmer} onChange={(e) => setFarmer(e.target.value)} />
                        </FlexView>
                        <FlexView>
                            <Label>
                                <RequiredMark /> {dictCk("farmName")}.
                            </Label>
                            <InputBox style={{ width: "200px" }} type="text" value={farmName} onChange={(e) => setFarmName(e.target.value)} />
                        </FlexView>
                        <FlexView>
                            <Label>{dictCk("bizPart")}</Label>
                            <ComboBox
                                style={{ width: "200px" }}
                                defaultValue={dictCk("select")}
                                hasDefault={true}
                                items={
                                    _codeList.length > 0 &&
                                    _codeList.filter((i) => i.majorKind == 1 && i.majorSeq == 1002).length > 0 &&
                                    _codeList
                                        .filter((i) => i.majorKind == 1 && i.majorSeq == 1002)[0]
                                        .minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                                }
                                value={field}
                                setValue={setField}
                            />
                        </FlexView>
                        <FlexView>
                            <Label>{dictCk("bizType")}</Label>
                            <ComboBox
                                style={{ width: "200px" }}
                                defaultValue={dictCk("select")}
                                hasDefault={true}
                                items={
                                    _codeList.length > 0 &&
                                    _codeList.filter((i) => i.majorKind == 1 && i.majorSeq == 1003).length > 0 &&
                                    _codeList
                                        .filter((i) => i.majorKind == 1 && i.majorSeq == 1003)[0]
                                        .minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                                }
                                value={sectors}
                                setValue={setSectors}
                            />
                        </FlexView>
                        <FlexView>
                            <RequiredMark />
                            <Label style={{ marginRight: "5px" }}>{dictCk("farmStartDate")}</Label>
                            <DateBox style={{ width: "200px" }} stdDate={startDate} setStdDate={setStartDate} />
                        </FlexView>
                        <FlexView>
                            <Label>{dictCk("careerYear")}</Label>
                            <InputBox style={{ width: "200px" }} type="number" value={careerYear} onChange={(e) => setCareerYear(e.target.value)} />
                        </FlexView>
                        <FlexView>
                            <Label>
                                <RequiredMark /> {dictCk("zipCode")}
                            </Label>
                            {/* 우편번호 직접 입력으로 수정 - 윤여광 2024.03.12 */}
                            <InputBox
                                style={{ width: "200px" }}
                                type="text"
                                value={zipcode}
                                onChange={(e) => {
                                    setZipcode(e.target.value)
                                }}
                            />
                        </FlexView>
                        <FlexView>
                            <Label>
                                <RequiredMark /> {dictCk("addr")}
                            </Label>
                            <InputBox style={{ width: "320px", marginRight: 0 }} type="text" value={addr} onChange={() => {}} disabled={true} />
                            <button style={{ width: "40px", height: "40px" }} onClick={() => setGoogleAddrPopup(true)}>
                                <img style={{ width: "16px", height: "16px" }} src={icon_search} alt={""} />
                            </button>
                        </FlexView>
                        <FlexView>
                            <Label>
                                <RequiredMark /> {dictCk("addrDetail")}
                            </Label>
                            <InputBox
                                style={{ width: "320px", marginRight: 0 }}
                                type="text"
                                value={addrDetail}
                                onChange={(e) => setAddrDetail(e.target.value)}
                            />
                        </FlexView>
                        <FlexView>
                            <Label>{dictCk("facilityType")}</Label>
                            <ComboBox
                                style={{ width: "96px" }}
                                defaultValue={dictCk("select")}
                                hasDefault={true}
                                items={
                                    _codeList.length > 0 &&
                                    _codeList.filter((i) => i.majorKind == 2 && i.majorSeq == 1001).length > 0 &&
                                    _codeList
                                        .filter((i) => i.majorKind == 2 && i.majorSeq == 1001)[0]
                                        .minorList.map((i) => ({ value: i.minorSeq, label: i.minorName}))
                                }
                                value={facilityType}
                                setValue={setFacilityType}
                            />
                        </FlexView>
                        <FlexView>
                            <Label>{dictCk("productionArea")}</Label>
                            <InputBox
                                style={{ width: "210px", marginRight: 0 }}
                                type="number"
                                value={productionArea}
                                onChange={(e) => setProductionArea(e.target.value)}
                            />
                            <ComboBox
                                style={{ width: "96px", marginLeft: "14px" }}
                                defaultValue={dictCk("select")}
                                hasDefault={true}
                                items={
                                    _codeList.length > 0 &&
                                    _codeList.filter((i) => i.majorKind == 1 && i.majorSeq == 1007).length > 0 &&
                                    _codeList
                                        .filter((i) => i.majorKind == 1 && i.majorSeq == 1007)[0]
                                        .minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                                }
                                value={areaUnit}
                                setValue={setAreaUnit}
                            />
                        </FlexView>
                        <FlexView>
                            <Label>{dictCk("blogUrl")}</Label>
                            <InputBox
                                style={{ width: "320px", marginRight: 0 }}
                                type="text"
                                placeholder={"https://example.com"}
                                value={blogUrl}
                                onChange={(e) => setBlogUrl(e.target.value)}
                                maxLength={200}
                            />
                        </FlexView>
                        <FlexView>
                            <Label>{dictCk("homepageUrl")}</Label>
                            <InputBox
                                style={{ width: "320px", marginRight: 0 }}
                                type="text"
                                placeholder={"https://example.com"}
                                value={homepageUrl}
                                onChange={(e) => setHomepageUrl(e.target.value)}
                                maxLength={200}
                            />
                        </FlexView>
                    </LeftBox>
                    <RightBox>
                        <FlexView alignItems={"flex-start"}>
                            <Label>{dictCk("photoUri")}</Label>
                            <input
                                id="farmLogoInputRef"
                                style={{ display: "none" }}
                                type="file"
                                accept={"image/*"}
                                value={""}
                                onChange={async (e) => {
                                    if (e.target.files[0]) {
                                        const file = e.target.files[0]
                                        if (file.size > 20 * 1024 * 1024) {
                                            // 파일용량이 20MB 넘을시 반환
                                            alert(dictCk("The file capacity exceeds 20 MB and cannot be saved."))
                                            return
                                        } // end if

                                        const base64 = await toBase64(file)
                                        setFarmLogo({ ...farmLogo, file: file, base64: base64 })
                                    } // end if
                                }}
                            />
                            <button
                                style={{ position: "relative", width: "112px", height: "112px" }}
                                onClick={() => document.getElementById("farmLogoInputRef").click()}
                            >
                                <img
                                    style={{ width: "112px", height: "112px", borderRadius: "3px" }}
                                    src={farmLogo.base64 || farmLogo.imageUrl || icon_defaultImg}
                                    alt={"logo"}
                                />
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        setFarmLogo({ file: null, base64: null, imageUrl: null })
                                    }}
                                    style={{
                                        backgroundColor: "#abbfae",
                                        position: "absolute",
                                        top: "2px",
                                        right: "2px",
                                        display: farmLogo.file || farmLogo.imageUrl ? "flex" : "none",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        width: "20px",
                                        height: "20px",
                                        paddingBottom: "2px",
                                        border: "1px solid #fff",
                                        borderRadius: "50%",
                                        color: "#000",
                                        fontSize: "9px",
                                        fontWeight: 700,
                                    }}
                                >
                                    X
                                </div>
                            </button>
                        </FlexView>
                        <FlexView>
                            <Label>{dictCk("bizName")}</Label>
                            <InputBox
                                style={{ width: "200px", marginRight: 0 }}
                                type="text"
                                value={businessNameKo}
                                onChange={(e) => setBusinessNameKo(e.target.value)}
                                maxLength={20}
                            />
                        </FlexView>
                        <FlexView>
                            <Label>{dictCk("bizNameEn")}</Label>
                            <InputBox
                                style={{ width: "200px", marginRight: 0 }}
                                type="text"
                                value={businessNameEn}
                                onChange={(e) => setBusinessNameEn(e.target.value)}
                                maxLength={20}
                            />
                        </FlexView>
                        <FlexView>
                            <Label>{dictCk("corpKind")}</Label>
                            <ComboBox
                                style={{ width: "200px" }}
                                defaultValue={dictCk("select")}
                                hasDefault={true}
                                items={
                                    _codeList.length > 0 &&
                                    _codeList.filter((i) => i.majorKind == 1 && i.majorSeq == 1004).length > 0 &&
                                    _codeList
                                        .filter((i) => i.majorKind == 1 && i.majorSeq == 1004)[0]
                                        .minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                                }
                                value={coType}
                                setValue={setCoType}
                            />
                        </FlexView>
                        <FlexView>
                            <Label>{dictCk("bizKind")}</Label>
                            <ComboBox
                                style={{ width: "200px" }}
                                defaultValue={dictCk("select")}
                                hasDefault={true}
                                items={
                                    _codeList.length > 0 &&
                                    _codeList.filter((i) => i.majorKind == 1 && i.majorSeq == 1005).length > 0 &&
                                    _codeList
                                        .filter((i) => i.majorKind == 1 && i.majorSeq == 1005)[0]
                                        .minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                                }
                                value={businessType}
                                setValue={setBusinessType}
                            />
                        </FlexView>
                        <FlexView>
                            <Label>{dictCk("bizNo")}</Label>
                            <InputBox
                                style={{ width: "200px", marginRight: 0 }}
                                placeholder={"000 - 00 - 00000"}
                                type="text"
                                mask="### - ## - #####"
                                value={businessNo}
                                onChange={(e) => setBusinessNo(e.target.value)}
                            />
                        </FlexView>
                        <FlexView>
                            <Label>{dictCk("citizenNo")}</Label>
                            <InputBox
                                style={{ width: "200px", marginRight: 0 }}
                                placeholder={""}
                                type="text"
                                mask="######-#######"
                                value={residentNo}
                                onChange={(e) => setResidentNo(e.target.value)}
                            />
                        </FlexView>
                        <FlexView>
                            <Label>
                                <RequiredMark />
                                {dictCk("cellNo")}
                            </Label>
                            {/* <InputBox
                style={{ width: "200px", marginRight: 0 }}
                type="text"
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
                maxLength={13}
              /> */}

                            <InputTelBox style={{ width: "200px", marginRight: 0 }} value={phoneNo} setValue={setPhoneNo} />
                        </FlexView>

                        <FlexView>
                            <Label>{dictCk("teleNo")}</Label>
                            <InputBox
                                style={{ width: "200px", marginRight: 0 }}
                                type="text"
                                value={teleNo}
                                onChange={(e) => setTeleNo(e.target.value)}
                                maxLength={13}
                            />
                        </FlexView>
                        <FlexView>
                            <Label>{dictCk("faxNo")}</Label>
                            <InputBox
                                style={{ width: "200px", marginRight: 0 }}
                                type="text"
                                value={faxNo}
                                onChange={(e) => setFaxNo(e.target.value)}
                                maxLength={13}
                            />
                        </FlexView>
                        <FlexView>
                            <Label>{dictCk("email")}</Label>
                            <InputBox
                                style={{ width: "200px", marginRight: 0 }}
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FlexView>
                    </RightBox>

                    <ButtonBox>
                        <GreenButton style={{ width: "147px", height: "40px" }} onClick={onClickSave}>
                            {dictCk("save")}
                        </GreenButton>
                    </ButtonBox>
                </InnerView>

                <FlexView alignItems={"flex-start"} style={{ paddingLeft: "40px" }}>
                    <Label>{dictCk("farmImage")}</Label>
                    <input
                        id="farmImgaeInputRef"
                        style={{ display: "none" }}
                        type="file"
                        accept={"image/*"}
                        value={""}
                        onChange={async (e) => {
                            if (e.target.files[0]) {
                                const file = e.target.files[0]
                                const base64 = await toBase64(file)
                                const temp = [...farmImages]
                                temp.push({ base64, file, imageUrl: null })
                                setFarmImages(temp)
                            }
                        }}
                    />
                    <button
                        style={{ width: "", height: "" }}
                        onClick={() => {
                            if (farmImages.length < 5) {
                                document.getElementById("farmImgaeInputRef").click()
                            }
                        }}
                    >
                        <img style={{ width: "80px", height: "80px", borderRadius: "3px" }} src={icon_defaultImg} alt={"logo"} />
                    </button>
                    {farmImages.map((image, imageIndex) => (
                        <div
                            key={imageIndex}
                            style={{ position: "relative", width: "112px", height: "80px", marginLeft: "12px", border: "1px solid #dddddd" }}
                        >
                            <img style={{ width: "112px", height: "80px" }} src={image.base64 || image.imageUrl} alt={""} />
                            <button
                                onClick={() => {
                                    const temp = [...farmImages]
                                    temp.splice(imageIndex, 1)
                                    setFarmImages(temp)
                                }}
                                style={{
                                    backgroundColor: "#abbfae",
                                    position: "absolute",
                                    top: "2px",
                                    right: "2px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "20px",
                                    height: "20px",
                                    paddingBottom: "2px",
                                    border: "1px solid #fff",
                                    borderRadius: "50%",
                                    color: "#000",
                                    fontSize: "9px",
                                    fontWeight: 700,
                                }}
                            >
                                X
                            </button>
                        </div>
                    ))}
                </FlexView>
                {/* <GoogleSearchBox onPlaceSelected={handlePlaceSelected}></GoogleSearchBox> */}
            </WhiteBox>
        </Container>
    )
}

const Container = styled.div`
    overflow-y: visible;
    width: 100%;
    height: 100%;
    &:after {
        content: "";
        display: block;
        height: 42px;
        width: 100%;
    }
`
const WhiteBox = styled.div`
    background-color: #fff;
    width: 100%;
    border: 1px solid #fff;
    border-radius: 9px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
    margin-top: 30px;
`
const InnerView = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
`
const LeftBox = styled.div`
    height: 100%;
    padding: 28px 40px 20px;
`
const RightBox = styled.div`
    height: 100%;
    padding: 28px 40px 47px;
`
const FlexView = styled.div`
    display: flex;
    justify-content: ${(props) => props.justifyContent || "flex-start"};
    align-items: ${(props) => props.alignItems || "center"};
    margin-bottom: 14px;
`
const Label = styled.p`
    opacity: 80%;
    width: 100px;
    margin-right: 12px;
    color: #555555;
    font-size: 14px;
    line-height: 32px;
`
const ButtonBox = styled.div`
    height: 100%;
    padding-top: 28px;
    padding-right: 40px;
`

export default SignContainer(CommonContainer(FarmContainer(withTranslation()(FarmInfo))))
