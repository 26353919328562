import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

// import { Line } from "react-chartjs-2"
// import ChartDataLabels from 'chartjs-plugin-datalabels';

import { ResponsiveLine } from '@nivo/line' // https://nivo.rocks/line
import { WidthToDP, isTablet } from "../../../utils/util"

const pastelToneColor = [ // 파스텔톤
  '#ffafb0', '#caa6fe', '#dfd4e4', '#bee9b4', '#c4f4fe',
  '#aee4ff', '#b5c7ed', '#fcffb0', '#f2cfa5', '#fdfa87',
  '#fcc6f7', '#e2ffaf', '#eeb7b4', '#afffba', '#ffafd8',
  '#fcffb0', '#f2cfa5', '#ffe4af', '#83a7a3', '#acb890',
]

const ChartLineBox = (props) => {
  const { style = {}, labels = [], datas = [], } = props

  return (

    <StyledChartBox style={{ ...style, }} >

      {/* <div style={{ position: 'absolute', width: '100%', height: '100%' }}> */}
      <ResponsiveLine 
        // height={`calc(100%-${30}vw)`}
        // style={{height: '100%'}}
        data={datas} //datas.length > 0 ? datas[0].datas : []}
        margin={{ top: 50, right: 10, bottom: 30, left: 35 }}
        xScale={{ type: 'point' }}
        yScale={{ 
          type: 'linear', min: 'auto', max: 'auto', 
          stacked: false, // 누적여부  
          reverse: false 
        }}
        // yFormat=" >-0.0f"
        
        axisTop={null}
        axisRight={null}
        axisBottom={{
            orient: 'bottom',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: -30, // x축 label 50도 기울기 
            // legend: 'transportation',
            legendOffset: 36,
            legendPosition: 'middle'
        }}
        axisLeft={{
            
            format: e => Math.floor(e) === e && e, // y 축 완수부만 보여주기 
            // tickValues: {tickValues}, //data.reduce((set, { y }) => set.add(y), new Set()).size,
            
            orient: 'left',
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            // legend: 'count',
            legendOffset: -40,
            legendPosition: 'middle'
        }}
        colors={pastelToneColor}//{ scheme: 'nivo' }} // line 색상 
        lineWidth={1} // line 굵기 
        pointSize={5} // line point 크기 
        // pointColor={{ theme: 'background' }}
        pointColor={{ from: 'color', modifiers: [] }} // line point 채우기 여부 
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabelYOffset={-8}
        useMesh={true}
        legends={[
            {
                anchor: 'top',
                direction: 'row',
                justify: false,
                translateX: 1,
                translateY: -38,
                itemsSpacing: 20,
                itemDirection: 'left-to-right',
                itemWidth: 80,
                itemHeight: 20,
                itemOpacity: 0.75,
                symbolSize: 12,
                symbolShape: 'circle',
                symbolBorderColor: 'rgba(0, 0, 0, .5)',
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
      />
      {/* </div> */}
    </StyledChartBox>

  )
}

const checkRender = (prevProps, nextProps) => {

  return false;
  
}

const StyledChartBox = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-top: ${WidthToDP(46)}px;
  background-color: #ffffff;
`

ChartLineBox.propTypes = {
  style: PropTypes.object,
  dataColor: PropTypes.string,
  label: PropTypes.string,
  labels: PropTypes.array.isRequired,
  datas: PropTypes.array.isRequired,
}

export default ChartLineBox
// export default React.memo( ChartLineBox, checkRender )
