import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  initCustomerList,
  getCustomerList,
  addCustomer,
  initCustomer,
  getCustomer,
  editCustomer,
  delCustomer,
  downloadCustomerListExcel,
} = actions.DefaultInfoAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _customerList: state.DefaultInfoReducer._customerList,
  _customer: state.DefaultInfoReducer._customer,
  _codeList: state.SystemSettingReducer._codeList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  initCustomerList: (param) => dispatch(initCustomerList(param)),
  getCustomerList: (param) => dispatch(getCustomerList(param)),
  addCustomer: (param) => dispatch(addCustomer(param)),
  initCustomer: (param) => dispatch(initCustomer(param)),
  getCustomer: (param) => dispatch(getCustomer(param)),
  editCustomer: (param) => dispatch(editCustomer(param)),
  delCustomer: (param) => dispatch(delCustomer(param)),
  downloadCustomerListExcel: (param) => dispatch(downloadCustomerListExcel(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
