// 환경제어 > 자동환경설정 정/역
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { AutoEnvTimeKindList } from "../../../utils/data"
import AutoEnvSettingContainer from "../../../containers/envControl/AutoEnvSettingContainer"
import PageName from "../../component/common/PageName"
import { SkyButton } from "../../component/common/Button"
import { TableComponent } from "../../component/common/Table"
import AutoEnvAddPopup from "../../popup/EnvControl/AutoEnvAddPopup"
import InputBox from "../../component/common/InputBox"
import Tab from "../../component/common/Tab"
import BasicPopup from "../../popup/BasicPopup"

const AutoEnvSetting = (props) => {
  const {
    t,
    _autoEnvWorkCenterList,
    _autoEnvEquipList,
    _autoEnvEquip,
    _sensorData,
    initAutoEnvWorkCenterList,
    getAutoEnvWorkCenterList,
    initAutoEnvEquipList,
    getAutoEnvEquipList,
    initAutoEnvEquip,
    getAutoEnvEquip,
    getSensorData,

    addAutoEnvEquipTime,
    initAutoEnvEquipTime,
    getAutoEnvEquipTime,
    editAutoEnvEquipTime,
    delAutoEnvEquipTime,
    editAutoEnvConfEquip,
    editAutoEnvConfEquipTemp,
  } = props
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [autoEnvAddPopup, setAutoEnvAddPopup] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [timeKind, setTimeKind] = useState(0) // workCenter timeKind 1: specified time, 2: sunrise/sunset
  const [equipIndex, setEquipIndex] = useState(0)
  const [settingItemData, setSettingItemData] = useState({ delayTime: "", section: "", stdLight: "", tgTemp: "", totalTime: "" })
  const [stdLightData, setStdLightData] = useState([
    { fr: "", to: "", ondo: "" },
    { fr: "", to: "", ondo: "" },
    { fr: "", to: "", ondo: "" },
    { fr: "", to: "", ondo: "" },
  ])

  useEffect(() => {
    getWorkCenterList()

    return async () => {
      await initAutoEnvWorkCenterList()
      await initAutoEnvEquipList()
      await initAutoEnvEquip()
    }
  }, [])

  useEffect(() => {
    if (_autoEnvWorkCenterList.length > 0) {
      setTabIndex(_autoEnvWorkCenterList[0].workCenterSeq)
      getEquipList(_autoEnvWorkCenterList[0].workCenterSeq)
    }
  }, [_autoEnvWorkCenterList])

  useEffect(() => {
    if (_autoEnvEquipList.length > 0) {
      setEquipIndex(_autoEnvEquipList[0].equipSeq)
      getEquipInfo(_autoEnvEquipList[0].equipSeq)
    }
  }, [_autoEnvEquipList])

  useEffect(() => {
    if (Object.keys(_autoEnvEquip).length > 0) {
      // 설정항목
      if (_autoEnvEquip.envConfEquip && _autoEnvEquip.envConfEquip.length > 0) {
        setSettingItemData(_autoEnvEquip.envConfEquip[0])
      } else {
        setSettingItemData({ delayTime: "", section: "", stdLight: "", tgTemp: "", totalTime: "" })
      }
      // 일사량에 따른 온도 환산 설정
      if (_autoEnvEquip.envConfEquipTemp && _autoEnvEquip.envConfEquipTemp.length > 0) {
        const temp = []
        for (let i = 0; i < 4; i++) {
          if (i >= _autoEnvEquip.envConfEquipTemp.length) temp.push({ fr: "", to: "", ondo: "" })
          else
            temp.push({
              fr: _autoEnvEquip.envConfEquipTemp[i].lightFr,
              to: _autoEnvEquip.envConfEquipTemp[i].lightTo,
              ondo: _autoEnvEquip.envConfEquipTemp[i].tgTemp,
            })
        }
        setStdLightData(temp)
      } else {
        setStdLightData([
          { fr: "", to: "", ondo: "" },
          { fr: "", to: "", ondo: "" },
          { fr: "", to: "", ondo: "" },
          { fr: "", to: "", ondo: "" },
        ])
      }
    }
  }, [_autoEnvEquip])

  const getWorkCenterList = async () => {
    const pro1 = await getAutoEnvWorkCenterList()
    const pro2 = await getSensorData()
    Promise.all([pro1, pro2])
  }

  const getEquipList = async (workCenterSeq) => {
    await getAutoEnvEquipList({ workCenterSeq, type: 1 })
  }

  const getEquipInfo = async (equipSeq) => {
    await getAutoEnvEquip({ equipSeq })
  }

  const onClickAdd = async () => {
    await initAutoEnvEquipTime()
    setAutoEnvAddPopup(true)
  }
  const onClickEdit = async (seq) => {
    const result = await getAutoEnvEquipTime({ envConfEquipTimeSeq: seq })
    if (result) setAutoEnvAddPopup(true)
  }
  const onClickDel = async (seq) => {
    const result = await delAutoEnvEquipTime({ envConfEquipTimeSeq: seq })
    if (result) getEquipInfo(equipIndex)
  }

  const autoEnvAddPopupHandler = async (type, value) => {
    if (type == t("save")) {
      let result = false
      if (value.envConfEquipTimeSeq === undefined) {
        result = await addAutoEnvEquipTime({
          equipSeq: equipIndex,
          ...value,
        })
      } else {
        result = await editAutoEnvEquipTime({
          equipSeq: equipIndex,
          ...value,
        })
      }

      if (result) getEquipInfo(equipIndex)
    }
    setAutoEnvAddPopup(false)
  }

  const onClickSettingSave = async () => {
    const result = await editAutoEnvConfEquip({
      equipSeq: equipIndex,
      totalTime: settingItemData.totalTime,
      tgTemp: settingItemData.tgTemp,
      section: settingItemData.section,
      delayTime: settingItemData.delayTime,
      stdLight: settingItemData.stdLight,
    })
    if (result) setPopup({ open: true, text: t("saved successfully.") })
  }

  const onClickStdLightSave = async () => {
    const result = await editAutoEnvConfEquipTemp({
      equipSeq: equipIndex,
      envConfEquipTemp: stdLightData.map((i) => ({ lightFr: i.fr || 0, lightTo: i.to || 0, tgTemp: i.ondo || 0 })),
    })
    if (result) setPopup({ open: true, text: t("saved successfully.") })
  }

  const onChangeSettingItemData = (value, type) => {
    const temp = { ...settingItemData }
    temp[type] = value
    setSettingItemData(temp)
  }

  return (
    <Container>
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: popup.text })} content={popup.text} selectedValue={[t("confirm")]} />
      <AutoEnvAddPopup open={autoEnvAddPopup} onClose={autoEnvAddPopupHandler} type={timeKind} />
      <PageName name={t("autoEnvSettingForRev")} state={false} setState={() => {}} />

      <Tab
        list={_autoEnvWorkCenterList.map((i) => ({ seq: i.workCenterSeq, name: i.workCenterName, timeKind: i.timeKind }))}
        tabIndex={tabIndex}
        onClick={(i) => {
          getEquipList(i.seq)
          setTabIndex(i.seq)
          setTimeKind(i.timeKind)
          setEquipIndex(0)
        }}
      />
      <Box>
        {_autoEnvEquipList.length > 0 && tabIndex > 0 && (
          <Left>
            {_autoEnvEquipList.map((i, idx) => (
              <button
                key={idx}
                style={{
                  backgroundColor: equipIndex == i.equipSeq ? "#fff" : "#d8d8d81a",
                  display: "block",
                  width: "100%",
                  height: "37px",
                  paddingLeft: "20px",
                  border: equipIndex == i.equipSeq ? "1px solid #52935d" : "none",
                  color: "#555555",
                  fontSize: "13px",
                  fontWeight: equipIndex == i.equipSeq ? 700 : 500,
                  textAlign: "left",
                }}
                onClick={() => {
                  getEquipInfo(i.equipSeq)
                  setEquipIndex(i.equipSeq)
                }}
              >
                {i.equipName}
              </button>
            ))}
          </Left>
        )}
        {_autoEnvEquipList.length > 0 && equipIndex > 0 && (
          <>
            <Center>
              <FlexBox justifyContent={"space-between"}>
                <p style={{ color: "#555555", fontSize: "14px" }}>{t("lightFr")}</p>
                <InputBox
                  style={{ width: "100px", marginRight: 0 }}
                  type="text"
                  placeholder={"00:00"}
                  value={_sensorData.lightFr}
                  onChange={() => {}}
                />
                <p style={{ color: "#555555", fontSize: "14px" }}>{t("lightTo")}</p>
                <InputBox
                  style={{ width: "100px", marginRight: 0 }}
                  type="text"
                  placeholder={"00:00"}
                  value={_sensorData.lightTo}
                  onChange={() => {}}
                />
                <SkyButton onClick={onClickAdd}>{t("add")}</SkyButton>
              </FlexBox>
              <TableComponent
                headList={
                  timeKind == 1
                    ? [
                        { title: `${t("no")}` },
                        { title: `${t("timeFr")}`, type: "text" },
                        { title: `${t("timeTo")}`, type: "text" },
                        { title: `${t("tgTemp")}`, type: "text" },
                        { title: `${t("actions")}` },
                      ]
                    : [
                        { title: `${t("no")}` },
                        { title: `${t("kind")}`, type: "text" },
                        { title: `${t("timeFr")}`, type: "text" },
                        { title: `${t("timeTo")}`, type: "text" },
                        { title: `${t("tgTemp")}`, type: "text" },
                        { title: `${t("actions")}` },
                      ]
                }
                bodyList={(_autoEnvEquip.envConfEquipTime || []).map((i, idx) =>
                  timeKind == 1
                    ? {
                        seq: i.envConfEquipTimeSeq,
                        no: _autoEnvEquip.envConfEquipTime.length - idx,
                        timeFr: i.timeFr,
                        timeTo: i.timeTo,
                        tgValue: i.tgValue,
                        Actions: true,
                      }
                    : {
                        seq: i.envConfEquipTimeSeq,
                        no: _autoEnvEquip.envConfEquipTime.length - idx,
                        timeKind: timeKind != 0 ? t(AutoEnvTimeKindList[i.timeKind-1].label):"",
                        timeFr: i.timeFr,
                        timeTo: i.timeTo,
                        tgValue: i.tgValue,
                        Actions: true,
                      }
                )}
                onClickEdit={(seq) => onClickEdit(seq)}
                onClickDel={(seq) => onClickDel(seq)}
              />
            </Center>
            <Right>
              <div className="rightChild">
                <p style={{ marginBottom: "14px", color: "#3e7347", fontSize: "16px", fontWeight: 400 }}>{t("settingItems")}</p>
                <SettingItem
                  title={t("workTime")}
                  value={settingItemData.totalTime}
                  disabled={true}
                  setValue={(value) => onChangeSettingItemData(value, "totalTime")}
                  unit={t("sec")}
                />
                <SettingItem
                  title={t("fullyOpenSetting")}
                  value={settingItemData.tgTemp}
                  setValue={(value) => onChangeSettingItemData(value, "tgTemp")}
                  unit={t("cel")}
                />
                <SettingItem
                  title={t("sectionSetting")}
                  value={settingItemData.section}
                  setValue={(value) => onChangeSettingItemData(value, "section")}
                  unit={t("times")}
                />
                <SettingItem
                  title={t("delayTime")}
                  value={settingItemData.delayTime}
                  setValue={(value) => onChangeSettingItemData(value, "delayTime")}
                  unit={t("sec")}
                />
                <SettingItem
                  title={t("stdLight")}
                  value={settingItemData.stdLight}
                  setValue={(value) => onChangeSettingItemData(value, "stdLight")}
                  unit={t("solarUnit")}
                />
                <Button onClick={onClickSettingSave}>{t("save")}</Button>
              </div>
              <div className="rightChild">
                <p style={{ marginBottom: "14px", color: "#3e7347", fontSize: "16px", fontWeight: 400 }}>
                  {t("temperature conversion setting according to insolation")}
                </p>
                <FlexBox justifyContent={"space-between"} style={{ height: "32px" }}>
                  <p style={{ width: "60px", color: "#555555cc", fontSize: "14px" }}>{t("from")}</p>
                  <p style={{ width: "60px", color: "#555555cc", fontSize: "14px" }}>{t("to")}</p>
                  <p style={{ width: "60px", color: "#555555cc", fontSize: "14px" }}>{t("convertedTemperature")}</p>
                </FlexBox>
                <StdLightInputItem index={0} value={stdLightData} setValue={setStdLightData} />
                <StdLightInputItem index={1} value={stdLightData} setValue={setStdLightData} />
                <StdLightInputItem index={2} value={stdLightData} setValue={setStdLightData} />
                <StdLightInputItem index={3} value={stdLightData} setValue={setStdLightData} />
                <Button onClick={onClickStdLightSave}>{t("save")}</Button>
              </div>
            </Right>
          </>
        )}
      </Box>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  &:after {
    content: "";
    display: block;
    height: 42px;
    width: 100%;
  }
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  display: flex;
  margin-top: 30px;
`
const Button = styled.button`
  background-color: #52935d;
  width: 100%;
  height: 40px;
  margin-top: 24px;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  &:hover {
    background-color: #3e7347;
  }
`
const Left = styled.div`
  background-color: #fff;
  width: 260px;
  height: fit-content;
  padding: 14px 12px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const Center = styled.div`
  background-color: #fff;
  flex: 1;
  height: fit-content;
  margin: 0 23px;
  padding: 28px 35px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const Right = styled.div`
  background-color: #f1f1f1;
  width: 350px;
  height: fit-content;

  div.rightChild {
    padding: 20px 25px;
    border: 1px solid #c6c6c6;
    border-radius: 9px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
    &:last-child {
      margin-top: 15px;
    }
  }
`
const SettingItem = ({ title, value, setValue, unit, disabled }) => {
  useEffect(() => {}, [])
  return (
    <FlexBox justifyContent={"space-between"}>
      <p style={{ width: "100px", color: "#555555cc", fontSize: "14px" }}>{title}</p>
      <InputBox
        style={{ width: "80px", marginRight: "20px", textAlign: "right" }}
        disabled={disabled ? true : false}
        type="number"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      <p style={{ flex: 1, color: "#555555cc", fontSize: "14px" }}>{unit}</p>
    </FlexBox>
  )
}
const StdLightInputItem = ({ index, value, setValue }) => {
  return (
    <FlexBox justifyContent={"space-between"} style={{ position: "relative" }}>
      <InputBox
        style={{ width: "60px", marginRight: 0, textAlign: "right" }}
        type="number"
        value={value[index].fr}
        onChange={(e) => {
          const temp = [...value]
          temp[index].fr = e.target.value
          setValue(temp)
        }}
      />
      <p style={{ position: "absolute", left: 85, color: "#555555cc", fontSize: "14px" }}>~</p>
      <InputBox
        style={{ width: "60px", marginRight: 0, textAlign: "right" }}
        type="number"
        value={value[index].to}
        onChange={(e) => {
          const temp = [...value]
          temp[index].to = e.target.value
          setValue(temp)
        }}
      />
      <InputBox
        style={{ width: "60px", marginRight: 0, textAlign: "right" }}
        type="number"
        value={value[index].ondo}
        onChange={(e) => {
          const temp = [...value]
          temp[index].ondo = e.target.value
          setValue(temp)
        }}
      />
    </FlexBox>
  )
}

export default AutoEnvSettingContainer(withTranslation()(AutoEnvSetting))
