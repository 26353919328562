import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { downExcel } from "../../../utils/util"
import GateWayInfoContainer from "../../../containers/defaultInfo/GatewayInfoContainer"
import { GrayButton, SkyButton, YellowButton } from "../../component/common/Button"
import PageName from "../../component/common/PageName"
import InputBox from "../../component/common/InputBox"
import { TableComponent } from "../../component/common/Table"
import GatewayAddPopup from "../../popup/DefaultInfo/GatewayAddPopup"

import CommonContainer from "../../../containers/CommonContainer" // 다국어 사전 가져오기 위해 import - 차건담 2023.03.02

const GatewayInfo = (props) => {
  const {
    t,
    _gateWayList,
    initGateWayList,
    getGateWayList,
    addGateWay,
    initGateWay,
    getGateWay,
    editGateWay,
    delGateWay,
    downloadGateWayListExcel,
  } = props

  // 사전 관련 객체 가져오기 - 차건담 2023.03.02
  const { initTranslationWords, getTranslationWords, _translationWords, replaceWord } = props  

  const [gatewayAddPopup, setGatewayAddPopup] = useState(false)
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    getTranslationWords() // 사전 가져오는 함수 - 차건담 2023.03.02
    return async () => await initGateWayList()
  }, [])

  let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.02.28

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }
  
  const getData = async () => {
    await getGateWayList({ gatewayName: "" })
  }

  const onClickExcelDownload = async () => {
    const result = await downloadGateWayListExcel()
    downExcel({ result: result, fileName: dictCk("gatewayList") })
  }

  const onClickSearch = async () => {
    const result = await getGateWayList({ gatewayName: searchText })
    if (result) setSearchText("")
  }

  const onClickAdd = async () => {
    await initGateWay()
    setGatewayAddPopup(true)
  }

  const onClickEdit = async (seq) => {
    const result = await getGateWay({ gatewaySeq: seq })
    if (result) setGatewayAddPopup(true)
  }

  const onClickDelete = async (seq) => {
    const result = await delGateWay({ gatewaySeq: seq })
    if (result) getData()
  }

  const gatewayAddPopupHandler = async (type, value) => {
    let finalResult = false
    if (type == dictCk("save") && value.gatewaySeq == undefined) {
      const result = await addGateWay(value)
      if (result && !result.code != 0) {
        getData()
        finalResult = true
      }
    } else if (type == dictCk("save") && value.gatewaySeq) {
      const result = await editGateWay(value)
      if (result && !result.code != 0) {
        getData()
        finalResult = true
      }
    }

    if ((type == dictCk("save") && finalResult) || type == dictCk("cancel")) {
    setGatewayAddPopup(false)
    }
  }

  return (
    <Container>
      <GatewayAddPopup open={gatewayAddPopup} onClose={gatewayAddPopupHandler} />
      <FlexBox justifyContent="space-between">
        <PageName name={dictCk("gatewayInfo")} state={false} setState={() => {}} />
        <GrayButton onClick={onClickExcelDownload}>{dictCk("downloadExcelFile")}</GrayButton>
      </FlexBox>
      <Box>
        <WhiteBox>
          <FlexBox justifyContent="space-between">
            <FlexBox>
              <Text>{dictCk("gatewayName")}</Text>
              <InputBox style={{ marginRight: "14px" }} type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            </FlexBox>
            <FlexBox justifyContent="flex-end">
              <YellowButton onClick={onClickSearch}>{dictCk("search")}</YellowButton>
              <SkyButton style={{ marginLeft: "14px" }} onClick={onClickAdd}>
                {dictCk("add")}
              </SkyButton>
            </FlexBox>
          </FlexBox>

          <TableComponent
            headList={[
              { title: `${dictCk("no")}` },
              { title: `${dictCk("gatewayName")}`, type: "text" },
              { title: `${dictCk("gatewayNo")}`, type: "text" },
              // { title: "API URL", type: "text" },
              // { title: "Socket URL", type: "text" },
              // { title: "IP", type: "text" },
              // { title: "Post", type: "text" },
              // { title: "Mac주소", type: "text" },
              { title: `${dictCk("dataSendCycle")}`, type: "number" },
              { title: `${dictCk("isUse")}`, type: "check" },
              { title: `${dictCk("remark")}`, type: "text" },
              { title: `${dictCk("actions")}` }, // 수정 2024.03.18 by 강동우
              //{ title: `${dictCk("action")}` },
            ]}
            bodyList={_gateWayList.map((i, idx) => ({
              seq: i.gatewaySeq,
              no: _gateWayList.length - idx,
              gatewayName: i.gatewayName,
              gatewayNo: i.gatewayNo,
              // apiUrl: i.apiUrl,
              // socketUrl: i.socketUrl,
              // ip: i.ip,
              // port: i.port,
              // mac: i.mac,
              dataSendCycle: i.dataSendCycle,
              isUse: i.isUse,
              remark: i.remark,
              Actions: true,
            }))}
            dict={dict}
            onClickEdit={(seq) => onClickEdit(seq)}
            onClickDelete={(seq) => onClickDelete(seq)}
          />
        </WhiteBox>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
  width: 100%;
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
  margin-top: 30px;
  padding: 28px 32px 31px;
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`

export default CommonContainer(GateWayInfoContainer(withTranslation()(GatewayInfo)))
