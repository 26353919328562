import React, { useState, forwardRef, useEffect } from "react"
import styled, { css, createGlobalStyle } from "styled-components"
import DatePicker from "react-datepicker"
// import { ko } from "date-fns/esm/locale"
import "./react-datepicker.css"
import { getYear, getMonth, addDays, addMonths } from "date-fns"
// import "react-datepicker/dist/react-datepicker.css";

import { dateFormat } from "../../../utils/util"
import icon_calendar from "images/calendar.png"

import AlertPopup from "../../popup/AlertPopup"
import { withTranslation } from "react-i18next"

import CommonContainer from "../../../containers/CommonContainer"

const DateBox = (props) => {
    // 컴포넌트의 프로퍼티로부터 필요한 값들을 추출합니다.
    const {
        style = {},
        disabled = false,
        stdDate = "",
        setStdDate,
        t,
        minDate = new Date("1900-01-01"),
        maxDate = new Date("2999-12-31"),
        isYM = 0,
    } = props
    const { initTranslationWords, getTranslationWords, _translationWords, replaceWord } = props

    // isYM 상태값은 'yyyy-MM-dd' 형식과 'yyyy-MM' 형식을 구분합니다.
    // const [isYM, setIsYM] = useState(0);

    // 선택 가능한 최소 날짜와 최대 날짜를 설정합니다.
    // const minDate = new Date('1900-01-01');
    // const maxDate = new Date('2999-12-31');

    // 1900부터 2099년까지의 연도 배열을 생성합니다.
    const years = [...Array(2000).keys()].map((row) => row + 1900)

    // 01부터 12까지의 월 배열을 생성합니다.
    const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"]

    // 로컬 상태값들을 초기화합니다.
    const [localValue, setLocalValue] = useState("") // 선택된 날짜를 저장하는 상태 변수
    const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })

    // 번역 단어 초기화와 로드를 위한 이펙트 훅을 설정합니다.
    useEffect(() => {
        getTranslationWords()
    }, [])

    const dict = _translationWords[0]

    const dictCk = (word) => {
        if (dict == undefined || dict == null || dict == {} || dict == "") {
            return word
        } else {
            const result = dict[word] != undefined && dict[word] != null && dict[word] != "" ? dict[word] : word
            return result
        }
    }

    // "날짜 선택" 버튼을 클릭하면 input 요소를 클릭하는 함수입니다.
    function onClickDateBox() {
        const onClickDate = document.getElementById("dateBox")
        onClickDate.click()
    }

    // --------------- 아래로는 날짜 처리 로직 ---------------

    // 날짜가 선택되었을 때 호출되는 핸들러 함수입니다.
    const handleDateChange = (date) => {
        setLocalValue(date)

        const d = date === null ? "" : dateFormat(date, "YYYY-MM-DD")
        // console.log("ddddd(화면에 보여질값)@@@@@", d)

        const value = d.replaceAll(" ", "").replaceAll("-", "").replaceAll("/", "").replaceAll(":", "").replaceAll(",", "")
        // console.log("value(db에 저장될값)@@@@@", value)
        setStdDate(value)
    }

    // '-'가 없으면 추가하는 로직입니다.
    const addHyphen = (inputText, index) => {
        if (inputText.charAt(index) !== "-") {
            inputText = inputText.slice(0, index) + "-" + inputText.slice(index)
        }
        return inputText
    }

    // 날짜가 유효한지 체크하는 함수입니다.
    const isValidDate = (year, month, day) => {
        const newDate = new Date(year, month, day)
        return newDate.getFullYear() === year && newDate.getMonth() === month && newDate.getDate() === day
    }

    // 입력된 날짜 값을 처리하는 함수입니다.
    const handleInputChange = (e) => {
        let inputText = e.target.value // 입력된 값 가져오기

        if (inputText) {
            // 값이 존재하는 경우에만 처리
            if (inputText.length === 5) {
                // 값의 길이가 5일 때
                inputText = addHyphen(inputText, 4) // 다섯 번째에 '-' 추가
            } else if (inputText.length === 8) {
                // 값의 길이가 8일 때
                inputText = addHyphen(inputText, 7) // 여덟 번째에 '-' 추가
            }

            e.target.value = inputText // 입력 요소의 값을 수정한 값으로 설정

            if (inputText.length === 10) {
                // 값의 길이가 10일 때
                const parts = inputText.split("-") // '-'를 기준으로 값 분할
                const selectedYear = parseInt(parts[0]) // 연도 추출
                const selectedMonth = parseInt(parts[1]) - 1 // 월 추출 (0부터 시작하므로 1을 빼줌)
                const selectedDay = parseInt(parts[2]) // 일 추출

                // 연도, 월, 일이 모두 유효한 숫자인지 체크
                if (!isNaN(selectedYear) && !isNaN(selectedMonth) && !isNaN(selectedDay)) {
                    // 유효한 날짜인지 체크
                    if (isValidDate(selectedYear, selectedMonth, selectedDay)) {
                        const newDate = new Date(selectedYear, selectedMonth, selectedDay) // 새로운 날짜 객체 생성

                        if (newDate >= minDate && newDate <= maxDate) {
                            // 선택한 날짜가 유효한 범위 내에 있는 경우
                            setLocalValue(newDate) // 로컬 상태 업데이트하여 날짜 표시
                        } else {
                            // 선택 가능한 범위를 벗어난 경우
                            setLocalValue(null) // 로컬 상태를 초기화하고
                            alert(dictCk("선택 가능한 범위를 벗어났습니다.")) // 경고 메시지 출력
                        }
                    } else {
                        // 유효하지 않은 날짜인 경우
                        setLocalValue(null) // 로컬 상태를 초기화하고
                        alert(dictCk("유효하지 않은 날짜입니다.")) // 경고 메시지 출력
                    }
                } else {
                    // 연도, 월, 일 중 하나라도 숫자가 아닌 경우
                    setLocalValue(null) // 로컬 상태를 초기화
                }
            } else {
                // 값의 길이가 10이 아닌 경우
                setLocalValue(null) // 로컬 상태를 초기화
            }
        }
    }

    return (
        <>
            <AlertPopup
                bg={false}
                open={alertPopup.open}
                onClose={() => setAlertPopup({ open: false, text: alertPopup.text })}
                content={alertPopup.text}
            />
            <StyledDateBox style={{ ...style }}>
                <SpanBox>
                    <DatePicker
                        autoComplete="off" // 자동완성 기능을 끄기 위한 설정
                        id="dateBox"
                        // selected={localValue === "" ? (stdDate === "" ? "" : new Date(dateFormat(stdDate, "YYYY-MM-DD"))) : new Date(localValue)}
                        selected={
                            localValue === ""
                                ? stdDate === ""
                                    ? ""
                                    : new Date(dateFormat(stdDate, "YYYY-MM-DD"))
                                : stdDate === ""
                                ? new Date(localValue)
                                : new Date(dateFormat(stdDate, "YYYY-MM-DD"))
                        }
                        // 선택한 날짜를 표시하기 위한 설정입니다.
                        // 조건에 따라서 세 가지 경우로 분기하여 값을 설정하고 있습니다:
                        // 1. localValue가 비어있는 경우: stdDate가 비어있는 경우에도 빈 문자열("")로 설정
                        // 2. stdDate가 비어있는 경우: localValue를 사용
                        // 3. stdDate와 localValue 모두 있는 경우: stdDate를 "YYYY-MM-DD" 형식으로 변환하여 설정
                        onChange={handleDateChange} // 선택된 날짜 변경 시 호출되는 함수
                        dateFormat={isYM === 1 ? "yyyy-MM" : "yyyy-MM-dd"} // 날짜 표시 형식
                        // 날짜 표시 형식을 설정합니다. isYM 값에 따라 "yyyy-MM" 또는 "yyyy-MM-dd" 형식으로 표시됩니다.
                        onChangeRaw={handleInputChange} // 입력 값이 변경될 때 호출되는 함수
                        minDate={minDate} // 선택 가능한 최소 날짜
                        maxDate={maxDate} // 선택 가능한 최대 날짜
                        // maxDate={addMonths(new Date(), 1000)}  // 최대 날짜를 1000개월 후로 제한하려면 사용할 수 있습니다.
                        disabled={disabled} // DatePicker를 비활성화하는 경우 true로 설정
                        // locale={ko} // DatePicker에 표시되는 언어 및 형식 설정 (주석 처리된 상태로 남겨둠)

                        renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                            decreaseMonth,
                            increaseMonth,
                            prevMonthButtonDisabled,
                            nextMonthButtonDisabled,
                        }) => (
                            <div
                                style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "space-between",
                                }}
                            >
                                <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                                    {"<"}
                                </button>
                                <div>
                                    <select style={{ padding: "3px" }} value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)}>
                                        {years.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                    &nbsp;&nbsp; - &nbsp;&nbsp;
                                    <select
                                        style={{ padding: "3px" }}
                                        value={months[getMonth(date)]}
                                        onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                                    >
                                        {months.map((option) => (
                                            <option key={option} value={option}>
                                                {option}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                                    {">"}
                                </button>
                            </div>
                        )}
                    />
                    {/* <img src={icon_calendar} onClick={()=>onClickDateBox()} /> */}
                    <img src={icon_calendar} />
                </SpanBox>
            </StyledDateBox>
        </>
    )
}

const StyledDateBox = styled.div`
    position: relative;
    width: 120px;
    margin-right: 30px;
    z-index: 99;
`

const SpanBox = styled.span`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    & > p {
        padding: 0 15px;
        font-size: 14px;
    }
    & > img {
        position: absolute;
        right: 11px;
        width: 16px;
        height: 16px;
    }
    &:focus {
        border: 1px solid #52935d;
    }
`
export default CommonContainer(withTranslation()(DateBox))
