import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  
  GET_CONF_LIST,
  GET_CONF_INFO,
  ADD_CONF_INFO,
  MOD_CONF_INFO,
  DEL_CONF_INFO,

} = actions.EnvControlAction


// redux state > component mapping
const mapStateToProps = (state) => ({
  
  confList: state.CommonReducer.confList || GET_CONF_LIST.initData, GET_CONF_LIST,
  confInfo: state.CommonReducer.confInfo || GET_CONF_INFO.initData, GET_CONF_INFO,
  ADD_CONF_INFO, MOD_CONF_INFO, DEL_CONF_INFO,

})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
