// 사용자 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import RequiredMark from "../../component/RequiredMark"
import TableCheckBox from "../../component/common/TableCheckBox"
import InputBox from "../../component/common/InputBox"
import AlertPopup from "../AlertPopup"
import InputTelBox from "../../component/common/InputTelBox"
import ComboBox from "../../component/common/ComboBox"

import CommonContainer from "../../../containers/CommonContainer" // 다국어 사전 가져오기 위해 import - 차건담 2023.03.02

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "403px",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const UserAddPopup = (props) => {
  const classes = useStyles()
  const { t, onClose, open, data } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const [id, setId] = useState("")
  const [password, setPassword] = useState("")
  const [passCheck, setPassCheck] = useState("")
  const [nameKo, setNameKo] = useState("")
  const [nameEn, setNameEn] = useState("")
  const [phoneNo, setPhoneNo] = useState("")
  const [email, setEmail] = useState("")
  const [check, setCheck] = useState(true)
  const [lang, setLang] = useState(0)

  // 사전 관련 객체 가져오기 - 차건담 2023.03.02
  const { initTranslationWords, getTranslationWords, _translationWords, replaceWord, _codeList } = props

  useEffect(() => {
    getTranslationWords() // 사전 가져오는 함수 - 차건담 2023.03.02
    if (Object.keys(data).length != 0) {
      setId(data.userId || "")
      setNameKo(data.userName || "")
      setNameEn(data.userNameEn || "")
      setPhoneNo(data.teleNo || "")
      setEmail(data.email || "")
      setCheck(data.isUse == 1)
      setLang(data.lang || "")
    }

    return () => {
      setId("")
      setPassword("")
      setPassCheck("")
      setNameKo("")
      setNameEn("")
      setPhoneNo("")
      setEmail("")
      setCheck(true)
      setLang("")
    }
  }, [open])

  let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.02.28

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }
  
  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose(null)}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <div
        style={{
          backgroundColor: "#fff",
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "650px",
          padding: "23px 51px 44px 40px",
        }}
      >
        <Title>{dictCk("userInfo")}</Title>
        <div style={{ flex: 1 }}>
          <FlexBox>
            <Label>
              <RequiredMark /> {dictCk("id")}
            </Label>
            <InputBox style={{ width: "200px", marginRight: 0 }} type="text"  value={id} onChange={(e) => setId(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dictCk("password")}
            </Label>
            {/* autocomplete="new-password" 인풋박스 자동완성제거 */}
            <InputBox style={{ width: "200px", marginRight: 0 }} type="password"  value={password} onChange={(e) => setPassword(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dictCk("passwordVerification")}
            </Label>
            <InputBox style={{ width: "200px", marginRight: 0 }} type="password" value={passCheck} onChange={(e) => setPassCheck(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dictCk("userName")}
            </Label>
            <InputBox style={{ width: "200px", marginRight: 0 }} type="text" value={nameKo} onChange={(e) => setNameKo(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark />{dict['langSetting']}
            </Label>
            <ComboBox
              style={{ width: "200px", marginRight: 0 }}
              defaultValue={dict['select']}
              hasDefault={true}
              items={
                _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1001).length > 0
                  ? _codeList.filter((i) => i.majorSeq == 1001)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                  : []
              }
              value={lang}
              setValue={setLang}
            />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("userNameEn")}</Label>
            <InputBox style={{ width: "200px", marginRight: 0 }} type="text" value={nameEn} onChange={(e) => setNameEn(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("cellNo")}</Label>
            <InputTelBox style={{ width: "200px", marginRight: 0 }} value={phoneNo} setValue={setPhoneNo} />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("email")}</Label>
            <InputBox style={{ width: "200px", marginRight: 0 }} type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("isUse")}</Label>
            <TableCheckBox check={check} setCheck={setCheck} />
          </FlexBox>
        </div>
        <ButtonBox>
          <Button bgColor={"#fff"} hover={"rgba(174, 174, 174, 0.22)"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={() => onClose(null)}>
            {dictCk("cancel")}
          </Button>
          <Button
            bgColor={"#52935d"}
            hover={"#3e7347"}
            border={"none"}
            color={"#fff"}
            onClick={() => {
              if (id === "" || nameKo === "")
                return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("required")) })
              else if (Object.keys(data).length == 0 && (password === "" || passCheck === ""))
                return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("required")) })
              else if (password !== passCheck) 
                return setAlertPopup({ open: true, text: replaceWord(dictCk("Password verification does not match.")) })
              else if (email != "" && !/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(email))
                return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid format. Please try again."), dictCk("email")) })
              else if (lang == 0) 
                return setAlertPopup({ open: true, text: replaceWord(dictCk('n.Please select a product.'), dictCk('langSetting')) })

              onClose({ id, password, nameKo, nameEn, phoneNo, email, check, lang })
            }}
          >
            {dictCk("save")}
          </Button>
        </ButtonBox>
      </div>
    </Dialog>
  )
}

const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 14px;
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  flex: 1;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

UserAddPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default CommonContainer(withTranslation()(UserAddPopup))
