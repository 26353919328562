import React, { useState } from "react"
import styled from "styled-components"
import Layout from "../component/Layout"
import SEO from "../seo"
import { YellowButton, SkyButton, GreenButton, GrayButton } from "../component/common/Button"
import CheckBox from "../component/common/CheckBox"
import CircleCheckBox from "../component/common/CircleCheckBox"
import PageName from "../component/common/PageName"
import Radio from "../component/common/Radio"
import Switch from "../component/common/Switch"
import { TableComponent } from "../component/common/Table"
import { TableAccodionComponent } from "../component/common/AccodionTable"
import { withTranslation } from "react-i18next"

import DateBox from "../component/common/DateBox"
import CodeHelpBox from "../component/common/CodeHelpBox"
import ComboBox from "../component/common/ComboBox"
import MultiComboBox from "../component/common/MultiComboBox"
import InputBox from "../component/common/InputBox"
import InputYMBox from "../component/common/InputYMBox"
import InputTimeBox from "../component/common/InputTimeBox"
import InputTelBox from "../component/common/InputTelBox"

const Sample = (props) => {
  const { t } = props

  const [check, setCheck] = useState(false)
  const [like, setLike] = useState(false)
  const [radioIndex, setRadioIndex] = useState(0)
  const [switchOn, setSwitchOn] = useState(false)

  const [stdDate, setStdDate] = useState("20211019")

  const [value1, setValue1] = useState([]) // 멀티콤보
  const [value2, setValue2] = useState(0) // 콤보
  const [value3, setValue3] = useState({ value: 3, label: "킹스베리" }) // 코드도움 (품목)
  const [value4, setValue4] = useState({ value: 0, label: "" }) // 코드도움 (판매거래처)
  const [value5, setValue5] = useState({ value: 0, label: "" }) // 코드도움 (구매거래처)

  const [inputValue0, setInputValue0] = useState("0103") // 월일
  const [inputValue1, setInputValue1] = useState("1111") // 시분
  const [inputValue2, setInputValue2] = useState("") // 핸드폰 번호
  const [inputValue3, setInputValue3] = useState("") // 핸드폰 번호
  const [inputValue4, setInputValue4] = useState("") // 전화 번호
  const [inputValue5, setInputValue5] = useState("") // 이름
  const [inputValue6, setInputValue6] = useState("") // 수량
  const [inputValue7, setInputValue7] = useState("") // 금액

  const items = [
    { value: 1, label: "a" },
    { value: 2, label: "b선택" },
    { value: 3, label: "c" },
  ]

  return (
    <Layout>
      <SEO title="샘플" />
      <Container>
        <div style={{ padding: "0 10px" }}>
          <Title>1. 버튼</Title>
          <YellowButton style={{ marginRight: "10px" }}>버튼</YellowButton>
          <SkyButton style={{ marginRight: "10px" }}>버튼</SkyButton>
          <GreenButton style={{ marginRight: "10px" }}>버튼</GreenButton>
          <GrayButton style={{}}>버튼</GrayButton>
          <hr />
          <Title>2. 체크박스</Title>
          <CheckBox style={{}} text="체크박스" check={check} setCheck={setCheck} />
          <CircleCheckBox style={{}} text="체크박스" check={check} setCheck={setCheck} />
          <hr />
          <Title>3. 드롭다운</Title>
          <MultiComboBox style={{}} placeholder={"멀티선택"} items={items} value={value1} setValue={setValue1} />
          <ComboBox style={{}} defaultValue={"선택"} hasDefault={true} items={items} value={value2} setValue={setValue2} />
          <CodeHelpBox
            style={{}}
            placeholder={"품명"}
            codeHelpSeq={1}
            // codeHelpParam={{}}
            value={value3}
            setValue={setValue3}
          />
          <CodeHelpBox
            style={{}}
            placeholder={"거래처명"}
            codeHelpSeq={2}
            codeHelpParam={{ custKind: 10060001 }} // 판매거래처
            value={value4}
            setValue={setValue4}
          />
          <CodeHelpBox
            style={{}}
            placeholder={"거래처명"}
            codeHelpSeq={2}
            codeHelpParam={{ custKind: 10060002 }} // 구매거래처
            value={value5}
            setValue={setValue5}
          />
          <DateBox style={{}} stdDate={stdDate} setStdDate={setStdDate} />
          <InputYMBox style={{}} disabled={false} value={inputValue0} setValue={setInputValue0} />
          <InputTimeBox style={{}} disabled={false} value={inputValue1} setValue={setInputValue1} />
          <InputBox
            style={{}}
            placeholder={"000 - 00 - 00000"}
            type="text"
            disabled={false}
            mask="### - ## - #####"
            value={inputValue2}
            onChange={(e) => setInputValue2(e.target.value)}
          />
          <InputBox
            style={{}}
            placeholder={"010 - 0000 - 0000"}
            type="text"
            mask="010 - #### - ####"
            value={inputValue3}
            onChange={(e) => setInputValue3(e.target.value)}
          />
          <InputTelBox style={{}} value={inputValue4} setValue={setInputValue4} />
          <InputBox style={{}} placeholder={"이름"} type="text" value={inputValue5} onChange={(e) => setInputValue5(e.target.value)} />
          <InputBox style={{}} placeholder={"수량"} type="number" value={inputValue6} onChange={(e) => setInputValue6(e.target.value)} />
          <InputBox
            style={{}}
            placeholder={"금액"}
            type="number"
            useComma={true}
            maxLength={5}
            value={inputValue7}
            onChange={(e) => setInputValue7(e.target.value)}
          />
          <hr />
          {/* ={value1}= */}={value2}= ={JSON.stringify(value3)}= ={JSON.stringify(value4)}= ={JSON.stringify(value5)}= ={stdDate}= ={inputValue0}= =
          {inputValue1}= ={inputValue2}= ={inputValue3}= ={inputValue4}= ={inputValue5}= ={inputValue6}= ={inputValue7}=
          <hr />
          <hr />
          <Title>4. 페이지 이름</Title>
          <PageName name="페이지 이름" state={like} setState={() => setLike(!like)} />
          <hr />
          <Title>5. 라디오 버튼</Title>
          <Radio style={{}} text="라디오1" idx={0} check={radioIndex} setCheck={setRadioIndex} />
          <Radio style={{}} text="라디오2" idx={1} check={radioIndex} setCheck={setRadioIndex} />
          <hr />
          <InputColor placeholder={t("name")}></InputColor>
          <hr />
          <Title>6. 스위치</Title>
          <Switch bool={switchOn} setBool={setSwitchOn} />
          <hr />
          <Title>7-1. 테이블</Title>
          <TableComponent
            headList={[
              { title: "작업장명", type: "text" },
              { title: "작업장번호", type: "text" },
              { title: "작업장종류", type: "text" },
              { title: "면적", type: "number" },
              { title: "재배품목", type: "text" },
              { title: "재배방법1", type: "text" },
              { title: "재배방법2", type: "text" },
              { title: "구역(개)", type: "number" },
              { title: "기준시간구분", type: "text" },
              { title: "사용여부", type: "check" },
              { title: "비고" },
              { title: "Actions" },
            ]}
            bodyList={[1, 2, 3].map((i) => ({
              seq: i,
              workCenterName: "테스트",
              workCenterNo: "123123",
              workCenterKindName: "종류이름",
              area: "호호(ㅎㅎ)",
              itemName: "품목",
              cropMethod1Name: "재배방법1",
              cropMethod2Name: "재배방법2",
              areaCnt: "3",
              timeKind: "테스트",
              isUse: 1,
              remark: "비고",
              Actions: true,
            }))}
            onClickEdit={(seq) => onClickEdit(seq)}
            onClickDelete={(seq) => onClickDelete(seq)}
          ></TableComponent>
          <hr />
          <Title>7-2. 테이블 (row의 text, number형식의 fontWeight 지정)</Title>
          <TableComponent
            headList={[
              { title: "작업장명", type: "text" },
              { title: "작업장번호", type: "text" },
              { title: "작업장종류", type: "text" },
              { title: "면적", type: "number" },
              { title: "재배품목", type: "text" },
              { title: "재배방법1", type: "text" },
              { title: "재배방법2", type: "text" },
              { title: "구역(개)", type: "number" },
              { title: "기준시간구분", type: "text" },
              { title: "사용여부", type: "check" },
              { title: "비고" },
              { title: "Actions" },
            ]}
            bodyList={[1, 2, 3].map((i) => ({
              seq: i,
              fontWeight: i == 1 ? "bold" : "normal",
              workCenterName: "테스트",
              workCenterNo: "123123",
              workCenterKindName: "종류이름",
              area: "호호(ㅎㅎ)",
              itemName: "품목",
              cropMethod1Name: "재배방법1",
              cropMethod2Name: "재배방법2",
              areaCnt: "3",
              timeKind: "테스트",
              isUse: 1,
              remark: "비고",
              Actions: true,
            }))}
            onClickEdit={(seq) => onClickEdit(seq)}
            onClickDelete={(seq) => onClickDelete(seq)}
          ></TableComponent>
          <hr />
          <Title>8. 아코디언 테이블 (액션버튼 없음)</Title>
          <TableAccodionComponent
            headList={[
              { title: "작업장명", type: "text" },
              { title: "작업장번호", type: "text" },
              { title: "작업장종류", type: "text" },
              { title: "면적", type: "number" },
              { title: "재배품목", type: "text" },
              { title: "재배방법1", type: "text" },
              { title: "재배방법2", type: "text" },
              { title: "구역(개)", type: "number" },
              { title: "기준시간구분", type: "text" },
              { title: "사용여부", type: "check" },
              { title: "비고" },
              // { title: "Actions" },
            ]}
            bodyMenu={[0, 1, 2, 3].map((i, idx) => ({
              seq: idx,
              menuType: i,
              workCenterName: "menuType == 0 ",
              workCenterNo: "0",
              workCenterKindName: "접기버튼 없음",
              area: "글씨굵게 출력",
              itemName: "품목",
              cropMethod1Name: "bodyList의 menuType과",
              cropMethod2Name: "일치시 접기내용나옴",
              areaCnt: "3",
              timeKind: "테스트",
              isUse: 1,
              remark: "menuType 일치 내용없으면 접기표시만 바뀜",
              // Actions: true,
            }))}
            bodyList={[1, 1, 3].map((i, idx) => ({
              seq: idx,
              menuType: i,
              workCenterName: "테스트",
              workCenterNo: "123123",
              workCenterKindName: "종류이름",
              area: "호호(ㅎㅎ)",
              itemName: "품목",
              cropMethod1Name: "재배방법1",
              cropMethod2Name: "재배방법2",
              areaCnt: "3",
              timeKind: "테스트",
              isUse: 1,
              remark: "비고",
              // Actions: true,
            }))}
            onClickEdit={(seq) => onClickEdit(seq)}
            onClickDelete={(seq) => onClickDelete(seq)}
          ></TableAccodionComponent>
          <hr />
        </div>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
`
const Title = styled.p`
  font-size: 20px;
`
const InputColor = styled.input`
  border: 1px solid black;
  ::placeholder {
    color: red;
  }
`

export default withTranslation()(Sample)
