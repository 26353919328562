// 기본정보 > 사용자
import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { formatPhone } from "utils/util"
import UserContainer from "../../../containers/defaultInfo/UserContainer"
import PageName from "../../component/common/PageName"
import InputBox from "../../component/common/InputBox"
import { YellowButton, SkyButton, GrayButton } from "../../component/common/Button"
import { TableComponent } from "../../component/common/Table"
import BasicPopup from "../../popup/BasicPopup"
import UserAddPopup from "../../popup/DefaultInfo/UserAddPopup"

import CommonContainer from "../../../containers/CommonContainer" // 다국어 사전 가져오기 위해 import - 차건담 2023.03.02

const UserInfo = (props) => {
  const { t, _userList, _user, setUserList, getUserList, addUser, initUser, getUser, editUser, delUser, downloadUserListExcel } = props
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [userAddPopup, setUserAddPopup] = useState(false)
  const [searchName, setSearchName] = useState("")
  const [searchId, setSearchId] = useState("")

  // 사전 관련 객체 가져오기 - 차건담 2023.03.02
  const { initTranslationWords, getTranslationWords, _translationWords, replaceWord } = props

  useEffect(() => {
    getTranslationWords() // 사전 가져오는 함수 - 차건담 2023.03.02
    return async () => await setUserList([])
  }, [])

  let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.02.28

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  const getData = async () => {
    await getUserList({ userName: "", userId: "" })
  }

  const handleUserAddPopup = async (value) => {
    if (value) {
      if (Object.keys(_user).length > 0) {
        // 수정
        const result = await editUser({
          userSeq: _user.userSeq,
          userId: value.id,
          userPw: value.password,
          userName: value.nameKo,
          userNameEn: value.nameEn,
          teleNo: value.phoneNo,
          email: value.email,
          isUse: value.check ? 1 : 0,
          lang : value.lang,
        })
        if (result == true) {
          getData()
          setUserAddPopup(false)
          console.log(_user)
        }
      } else {
        // 추가
        const result = await addUser({
          userId: value.id,
          userPw: value.password,
          userName: value.nameKo,
          userNameEn: value.nameEn,
          teleNo: value.phoneNo,
          email: value.email,
          isUse: value.check ? 1 : 0,
          lang : value.lang,
        })
        if (result == true) {
          getData()
          setUserAddPopup(false)
        }
      }
    } else setUserAddPopup(false)
  }
  const onClickExcelDownload = async () => {
    const result = await downloadUserListExcel()
    const blob = new Blob([result], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    })

    const link = document.createElement("a")
    const href = window.URL.createObjectURL(blob)

    link.href = href
    link.download = `${dictCk("userList")}.xlsx`
    link.click()
  }
  const onClickSearch = async () => {
    await getUserList({ userName: searchName, userId: searchId })
  }
  const onClickAdd = async () => {
    await initUser()
    setUserAddPopup(true)
  }
  const onClickEdit = async (userId) => {
    await getUser({ userSeq: userId })
    setUserAddPopup(true)
  }

  // const onClickDelete = async (userId) => {
  //   const result = await delUser({ userSeq: userId })
  //   if (result) getData()
  // }

  const onClickDelete = async (userId) => {
    const confirmDelete = window.confirm(dictCk('정말로 삭제하시겠습니까?')); //window.confirm 함수호출 확인 누를시 삭제 true/false 반환

    console.log("confirmDelete@@", confirmDelete)
    if (confirmDelete) {
      const result = await delUser({ userSeq: userId });
      if (result) {
        getData();
      }
    }
  };

  return (
    <Container>
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: "" })} content={popup.text} selectedValue={[dictCk("confirm")]} />
      <UserAddPopup open={userAddPopup} onClose={handleUserAddPopup} data={_user} />
      <FlexBox justifyContent={"space-between"}>
        <PageName name={dictCk("user")} state={false} setState={() => {}} />
        <GrayButton onClick={onClickExcelDownload}>{dictCk("downloadExcelFile")}</GrayButton>
      </FlexBox>
      <Box>
        <WhiteBox>
          <SearchBox>
            <FlexBox>
              <Text>{dictCk("userName")}</Text>
              <InputBox type="text" value={searchName} onChange={(e) => setSearchName(e.target.value)} />
              <Text style={{ marginLeft: "33px" }}> {dictCk("id")}</Text>
              <InputBox type="text" value={searchId} onChange={(e) => setSearchId(e.target.value)} />
            </FlexBox>
            <FlexBox>
              <YellowButton onClick={onClickSearch}>{dictCk("search")}</YellowButton>
              <SkyButton style={{ marginLeft: "14px" }} onClick={onClickAdd}>
                {dictCk("add")}
              </SkyButton>
            </FlexBox>
          </SearchBox>
          <TableComponent
            headList={[
              { title: `${dictCk("id")}`, type: "text" },
              { title: `${dictCk("userName")}`, type: "text" },
              { title: `${dictCk("userNameEn")}`, type: "text" },
              { title: `${dictCk("cellNo")}`, type: "text" },
              { title: `${dictCk("email")}`, type: "text" },
              { title: `${dictCk("isUse")}`, type: "check" },
              { title: `${dictCk("actions")}` }, // 수정 2024.03.18 by 강동우
              //{ title: `${dictCk("Actions")}` },
            ]}
            bodyList={_userList.map((i) => ({
              seq: i.userSeq,
              userId: i.userId,
              userName: i.userName,
              userNameEn: i.userNameEn,
              teleNo: i.teleNo ? formatPhone(i.teleNo) : "",
              email: i.email,
              isUse: i.isUse,
              Actions: true,
            }))}
            dict={dict}
            onClickEdit={(seq) => onClickEdit(seq)}
            onClickDelete={(seq) => onClickDelete(seq)}
          />
        </WhiteBox>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  margin-top: 19px;
  padding: 28px 32px 20px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Text = styled.p`
  width: 60px;
  color: #555555;
  font-size: 14px;
`

export default CommonContainer(UserContainer(withTranslation()(UserInfo)))
