import React, { useState, useEffect } from "react"
import styled from "styled-components"

import CodeHelpPopup from "./CodeHelpPopup"
import CodeHelpCustPopup from "./CodeHelpCustPopup"
import CodeHelpItemPopup from "./CodeHelpItemPopup"
import CodeHelpCropPopup from "./CodeHelpCropPopup"

import icon_search from "images/search.png"

const CodeHelpBox = (props) => {

  const { 
    
    style = {}, //defaultValue, hasDefault, items, 
    value, setValue,

    codeHelpSeq, // 1(품목), 2(거래처)
    codeHelpParam = {}, // 변수 
    placeholder = "",
  
  } = props

  const initLocalValue = { label : '', value : 0 }
  const [ queryWord, setQueryWord ] = useState("")

  const [ open, setOpen ] = useState(false)

  const onSetLocalValue = ( data ) => { // 코드도움 값을 선택후 호출 

    setValue( data )

  }
  
  const onChangeLocalValue = ( label ) => { // 코드도움 input 값이 변경될 때 호출 

    setValue( { ...value, label, value : 0 } )

  }

  const onDoubleClickLocalValue = () => { // 코드도움 input 더블클릭시 호출 

    setQueryWord( value.label )
    setOpen(true)

  }

  const onBlurLocalValue = ( label ) => { // 코드도움 input focus 잃을때 호출 

    if ( value.value <= 0 ) {
      
      setQueryWord( label )
      
      if ( label !== '' ) setOpen(true) 

      setValue( initLocalValue )

    } // end if 

  }

  const onKeyDownLocalValue = ( e ) => { // 코드도움 input enter 키 클릭시 호출 

    if( e.keyCode == 13 ){ // enter key 
      
      onBlurLocalValue( e.target.value )

    } // end if 

  }

  return (
    <StyledCodeHelpBox style={{ ...style }} >
      {codeHelpSeq == 1 ? 
        <CodeHelpItemPopup 
          codeHelpSeq={codeHelpSeq} codeHelpParam={codeHelpParam} 
          placeholder={placeholder} value={value} setValue={onSetLocalValue}
          queryWord={queryWord} setQueryWord={setQueryWord}
          open={open} onClose={() => { setOpen(false) }}
        />
      :codeHelpSeq == 2 ? 
        <CodeHelpCustPopup 
          codeHelpSeq={codeHelpSeq} codeHelpParam={codeHelpParam} 
          placeholder={placeholder} value={value} setValue={onSetLocalValue}
          queryWord={queryWord} setQueryWord={setQueryWord}
          open={open} onClose={() => { setOpen(false) }}
        />
      :codeHelpSeq == 3 ? 
        <CodeHelpCropPopup 
          codeHelpSeq={codeHelpSeq} codeHelpParam={codeHelpParam} 
          placeholder={placeholder} value={value} setValue={onSetLocalValue}
          queryWord={queryWord} setQueryWord={setQueryWord}
          open={open} onClose={() => { setOpen(false) }}
        />
      :''}

      <SpanBox onDoubleClick={() => onDoubleClickLocalValue()} >
        <input type="text" style={{width:'100%',flex: 1, height: "40px"}} 
          
          value={value.label} 
          onChange={(e)=>onChangeLocalValue( e.target.value )}
          onBlur={(e)=>onBlurLocalValue( e.target.value )}
          onKeyDown={(e)=>onKeyDownLocalValue( e )}
          
        />
        <img src={icon_search} alt={""} onClick={() => onDoubleClickLocalValue()} />
      </SpanBox>
    </StyledCodeHelpBox>
  )
}

const StyledCodeHelpBox = styled.div`
  position: relative;
  width: 220px;
  margin-right: 30px;
`
const SpanBox = styled.span`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  & > input {
    padding: 0 15px;
    font-size: 14px;
  }
  & > img {
    position: absolute;
    right: 11px;
    width: 16px;
    height: 16px;
  }
  &:focus {
    border: 1px solid #52935d;
  }
`

export default CodeHelpBox
