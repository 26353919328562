import React from "react"
import styled from "styled-components"
import cookie from "react-cookies"
import MainContainer from "../../../containers/MainContainer"
import icon_star_yellow from "images/star_yellow.png"
import icon_star_empty from "images/star_empty.png"

const PageName = (props) => {
  const { _bookmarkList, getBookmarkList, editBookmark } = props
  const { name } = props
  const pgmSeq = cookie.load("pgmSeq", { path: "/" })

  const onClick = async () => {
    const result = await editBookmark({ pgmSeq: pgmSeq, workKind: _bookmarkList.filter((i) => i.pgmSeq == pgmSeq).length > 0 ? 1 : 0 }) //0: 추가, 1: 삭제
    if (result) getBookmarkList()
  }

  return (
    <TitleView>
      <Title>{name}</Title>
      <StarButton onClick={onClick}>
        <img src={_bookmarkList.filter((i) => i.pgmSeq == pgmSeq).length > 0 ? icon_star_yellow : icon_star_empty} alt={"스타"} />
      </StarButton>
    </TitleView>
  )
}

const TitleView = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
`
const Title = styled.p`
  color: #555555;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`
const StarButton = styled.button`
  width: 20px;
  height: 20px;
  margin-left: 16px;
  & > img {
    width: 20px;
    height: 20px;
  }
`

export default MainContainer(PageName)
