// 기본 팝업
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Dialog } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
    container: {
        "& .MuiDialog-paperFullWidth": {
            width: "403px",
            margin: "25px",
            borderRadius: "9px",
        },
    },
}))

const BasicPopup = (props) => {
    const classes = useStyles()
    const { open, onClose, content, selectedValue } = props

    const handleClose = (value) => {
        onClose(value)
    }

    return (
        <Dialog className={classes.container} open={open} onClose={() => handleClose()} fullWidth={true}>
            <Container>
                <ContentView>
                    <Content>{content}</Content>
                </ContentView>

                <ButtonView>
                    {selectedValue.map((item, idx) => {
                        return (
                            <Button key={idx} one={selectedValue.length === 2 && idx === 0} onClick={() => handleClose(item)}>
                                {item}
                            </Button>
                        )
                    })}
                </ButtonView>
            </Container>
        </Dialog>
    )
}

BasicPopup.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    content: PropTypes.string.isRequired,
    selectedValue: PropTypes.array.isRequired,
}

export default BasicPopup

const Container = styled.div`
    padding: 23px 47px 44px;
`

const ContentView = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 30px 0px;
`

const Content = styled.p`
    color: #555555;
    font-size: 15px;
    text-align: center;
    white-space: pre-line;
`

const ButtonView = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
`
const Button = styled.button`
    background-color: ${(props) => (props.one ? "#fff" : "#52935d")};
    width: 147px;
    height: 40px;
    border: ${(props) => (props.one ? "1px solid #c6c6c6" : "none")};
    border-radius: 3px;
    color: ${(props) => (props.one ? "#555555" : "#fff")};
    font-size: 14px;

    &:nth-child(2) {
        margin-left: 14px;
    }
`
