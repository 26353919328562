import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const { 
  getModuleList, 
  getMenuList, 
  getCodeList, 
  getBookmarkList, 
  editBookmark,
  // 추가 2024.03.18 by 강동우
  initTranslationWords,
  getTranslationWords,
   
  MAIN_DATA_LIST 
} = actions.SystemSettingAction

const { postSignOut } = actions.SignAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _isLogin: state.SignReducer._isLogin,
  _moduleList: state.SystemSettingReducer._moduleList,
  _menuList: state.SystemSettingReducer._menuList,
  _codeList: state.SystemSettingReducer._codeList,
  _bookmarkList: state.SystemSettingReducer._bookmarkList,

  _userInfo: state.SignReducer._userInfo,

  mainDataList: state.CommonReducer.mainDataList || MAIN_DATA_LIST.initData, MAIN_DATA_LIST,

  // 추가 2024.03.18 by 강동우
  _translationWords: state.CommonReducer._translationWords || [],
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getModuleList: (param) => dispatch(getModuleList(param)),
  getMenuList: (param) => dispatch(getMenuList(param)),
  getCodeList: (param) => dispatch(getCodeList(param)),
  getBookmarkList: (param) => dispatch(getBookmarkList(param)),
  editBookmark: (param) => dispatch(editBookmark(param)),

  postSignOut: (param) => dispatch(postSignOut(param)),

  // 추가 2024.03.18 by 강동우
  initTranslationWords: (param) => dispatch(initTranslationWords(param)),
  getTranslationWords: (param) => dispatch(getTranslationWords(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
