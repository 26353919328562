// 시스템설정 > 환경설정
import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import i18n from "../../../lang/i18n"
import { LANGUAGE_CODE } from "../../../config"

import SignContainer from "../../../containers/SignContainer"
import SystemSettingContainer from "../../../containers/SystemSettingContainer"

import PageName from "../../component/common/PageName"
import ComboBox from "../../component/common/ComboBox"
import Switch from "../../component/common/Switch"
import InputBox from "../../component/common/InputBox"
import InputTimeBox from "../../component/common/InputTimeBox"
import BasicPopup from "../../popup/BasicPopup"
import icon_checkBasic from "images/checkBasic.png"
import icon_checkBasic_select from "images/checkBasic_select.png"

import CommonContainer from "../../../containers/CommonContainer"

const EnvSetting = (props) => {
    const {
        t,

        getModuleList,
        setUserInfo,
        _userInfo,

        _codeList,
        _envSettingInfo,
        getEnvSettingInfo,
        editEnvSettingInfo,

        initTranslationWords,
        getTranslationWords,
        _translationWords,
        replaceWord,
    } = props

    const [popup, setPopup] = useState({ open: false, text: "" })
    const [lang, setLang] = useState(0)
    const [currency, setCurrency] = useState(0)
    const [exRate, setExRate] = useState("")
    const [sunrise, setSunrise] = useState(0) //일출값
    const [sunset, setSunset] = useState(0) //일몰값
    const [alarmTime, setAlarmTime] = useState("")
    const [pushStatus, setPushStatus] = useState(false)
    const [smsStatus, setSmsStatus] = useState(false)
    const [userList, setUserList] = useState([])

    const dict = _translationWords[0] // 변수에 사전 담기

    useEffect(() => {
        getTranslationWords()
        getData()
    }, [])

    useEffect(() => {
        if (Object.keys(_envSettingInfo).length == 0) return

        setLang(_envSettingInfo.languageSeq)
        setCurrency(_envSettingInfo.currSeq)
        setExRate(_envSettingInfo.exRate)
        setSunrise(_envSettingInfo.sunrise) //일출값
        setSunset(_envSettingInfo.sunset) //일몰값
        setAlarmTime(_envSettingInfo.alarmTime)
        setPushStatus(_envSettingInfo.isMobileAlarm == 1)
        setSmsStatus(_envSettingInfo.isKakaoAlarm == 1)
        setUserList(_envSettingInfo.userList)
    }, [_envSettingInfo])

    const onSetLang = (languageSeq) => {
        setLang(languageSeq)
    }

    const getData = async () => {
        // 서버 버그땜에 6/2 작업 가능
        await getEnvSettingInfo()
    }

    const onClickSave = async () => {
        if (lang == 0) return setPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict["langSetting"]) })
        else if (currency == 0) return setPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict["currency"]) })
        else if (exRate == "") return setPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["exchangeRate"]) })
        else if (sunrise === "")
            return setPopup({
                open: true,
                text: replaceWord(dict["n.Invalid value. Please try again."], dict["sunrise"] + dict["stdLight"] + dict["value"]),
            })
        else if (sunset === "")
            return setPopup({
                open: true,
                text: replaceWord(dict["n.Invalid value. Please try again."], dict["sunset"] + dict["stdLight"] + dict["value"]),
            })
        else if (isNaN(Number(exRate)))
            return setPopup({ open: true, text: replaceWord(dict["n.Invalid format. Please try again."], dict["exchangeRate"]) })
        else if (alarmTime == "") return setPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["alarmTime"]) })

        const result = await editEnvSettingInfo({
            settingSeq: _envSettingInfo.settingSeq,
            languageSeq: lang,
            currSeq: currency,
            exRate: exRate,
            sunrise: sunrise == null ? 0 : sunrise,
            sunset: sunset == null ? 0 : sunset,
            alarmTime: alarmTime,
            isMobileAlarm: pushStatus ? 1 : 0,
            isKakaoAlarm: smsStatus ? 1 : 0,
            userSeqs: userList.filter((i) => i.isCheck == 1).map((i) => i.userSeq),
        })
        if (result) setPopup({ open: true, text: dict["saved successfully."] })
    }

    const onClose = async () => {
        setPopup({ open: false, text: "" })

        await getModuleList()

        const languageSeq = lang
        const languageCode = LANGUAGE_CODE[languageSeq]

        i18n.changeLanguage(languageCode)

        _userInfo.languageSeq = languageSeq

        const data = { ..._userInfo, setting: { ..._userInfo.setting, languageSeq } }

        setUserInfo(data)

        location.reload()
    }

    return (
        <Container>
            <BasicPopup open={popup.open} onClose={() => onClose()} content={popup.text} selectedValue={[dict["confirm"]]} />
            <PageName name={dict["envSetting"]} state={false} setState={() => {}} />
            <WhiteBox>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "100%", height: "100%" }}>
                    <div>
                        <FlexView>
                            <Label>{dict["langSetting"]}</Label>
                            <ComboBox
                                style={{ width: "200px", marginRight: 0 }}
                                defaultValue={dict["select"]}
                                hasDefault={true}
                                items={
                                    _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1001).length > 0
                                        ? // ? _codeList.filter((i) => i.majorSeq == 1001)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName })) - 원본
                                          _codeList
                                              .filter((i) => i.majorSeq == 1001)[0]
                                              .minorList.map((i) => ({
                                                  value: i.minorSeq,
                                                  label:
                                                      i.minorSeq == 10010001
                                                          ? "English"
                                                          : i.minorSeq == 10010002
                                                          ? "한국어"
                                                          : i.minorSeq == 10010003
                                                          ? "Tiếng Việt"
                                                          : i.minorSeq == 10010004
                                                          ? "简体中文"
                                                          : i.minorSeq == 10010005
                                                          ? "繁體中文"
                                                          : i.minorSeq == 10010006
                                                          ? "日本語"
                                                          : "기타언어",
                                              })) // 환경설정의 언어는 해당언어로 표시되게 수정 - 차건담 2023.03.14
                                        : []
                                }
                                value={lang}
                                setValue={onSetLang}
                            />
                        </FlexView>
                        <FlexView>
                            <Label>{dict["currency"]}</Label>
                            <ComboBox
                                style={{ width: "200px", marginRight: 0 }}
                                defaultValue={dict["select"]}
                                hasDefault={true}
                                items={
                                    _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1013).length > 0
                                        ? _codeList
                                              .filter((i) => i.majorSeq == 1013)[0]
                                              .minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                                        : []
                                }
                                value={currency}
                                setValue={setCurrency}
                            />
                        </FlexView>
                        <FlexView>
                            <Label>{dict["exchangeRate"]}</Label>
                            <InputBox
                                style={{ width: "200px", marginRight: 0 }}
                                type="number"
                                value={exRate}
                                onChange={(e) => setExRate(e.target.value)}
                            />
                        </FlexView>

                        <FlexView>
                            <Label>{dict["sunrise"] + dict["stdLight"] + dict["value"]}</Label>
                            <InputBox
                                style={{ width: "200px", marginRight: 0 }}
                                type="number"
                                value={sunrise}
                                onChange={(e) => setSunrise(e.target.value)}
                            />
                        </FlexView>
                        <FlexView>
                            <Label>{dict["sunset"] + dict["stdLight"] + dict["value"]}</Label>
                            <InputBox
                                style={{ width: "200px", marginRight: 0 }}
                                type="number"
                                value={sunset}
                                onChange={(e) => setSunset(e.target.value)}
                            />
                        </FlexView>
                    </div>

                    <div>
                        <BigLabel>{dict["alarmSetting"]}</BigLabel>
                        <FlexView>
                            <Label>{dict["alarmTime"]}</Label>
                            <InputTimeBox value={alarmTime} setValue={setAlarmTime} />
                        </FlexView>
                        <FlexView>
                            <Label>{dict["pushAlarm"]}</Label>
                            <Switch bool={pushStatus} setBool={setPushStatus} />
                        </FlexView>
                        <FlexView>
                            <Label>{dict["kakao"]}</Label>
                            <Switch bool={smsStatus} setBool={setSmsStatus} />
                        </FlexView>
                        <FlexView alignItems="flex-start">
                            <Label>{dict["recipient"]}</Label>
                            <div style={{ overflowY: "auto", flex: 1, maxHeight: 35 * 3 }}>
                                {userList.map((i, idx) => (
                                    <CheckComponent
                                        key={idx}
                                        text={i.userName}
                                        check={i.isCheck == 1}
                                        setCheck={(bool) => {
                                            const temp = [...userList]
                                            temp[idx].isCheck = bool ? 1 : 0
                                            setUserList(temp)
                                        }}
                                    />
                                ))}
                            </div>
                        </FlexView>

                        <SaveButton onClick={onClickSave}>{dict["save"]}</SaveButton>
                    </div>
                </div>
            </WhiteBox>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

//     overflow-y: hidden;
const WhiteBox = styled.div`
    background-color: #fff;
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 403px;
    margin-top: 28px;
    padding: 28px 40px 44px;
    border: 1px solid #fff;
    border-radius: 9px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const FlexView = styled.div`
    display: flex;
    justify-content: ${(props) => props.justifyContent || "flex-start"};
    align-items: ${(props) => props.alignItems || "center"};
    margin-bottom: 14px;
`
const BigLabel = styled.p`
    margin-bottom: 28px;
    color: #555555;
    font-size: 17px;
    line-height: 32px;
`
const Label = styled.p`
    opacity: 80%;
    width: 100px;
    margin-right: 12px;
    color: #555555;
    font-size: 14px;
    line-height: 32px;
`
const SaveButton = styled.button`
    background-color: #52935d;
    width: 100%;
    height: 56px;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    line-height: 32px;
`
const CheckComponent = ({ text, check, setCheck, style = {} }) => {
    return (
        <StyledCheckBox style={{ ...style }} onClick={() => setCheck(!check)}>
            <CheckIcon src={check ? icon_checkBasic_select : icon_checkBasic} />
            <CheckLabel>{text}</CheckLabel>
        </StyledCheckBox>
    )
}
const StyledCheckBox = styled.button`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 35px;
`
const CheckIcon = styled.img`
    width: 22px;
    height: 22px;
    margin-right: 9px;
`
const CheckLabel = styled.p`
    opacity: 60%;
    margin-bottom: 2px;
    color: #555555;
    font-size: 14px;
`

export default SignContainer(SystemSettingContainer(CommonContainer(withTranslation()(EnvSetting))))
