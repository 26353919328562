// 센서데이터 그래프 조회 화면 || 최종 수정 날짜 - 2023.06.16
import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import moment from "moment"

import CommonContainer from "../../../containers/CommonContainer"
import ChartContainer from "../../../containers/statusInfo/ChartContainer"
import { SkyButton, YellowButton, GrayButton } from "../../component/common/Button"
import PageName from "../../component/common/PageName"
import DateBox from "../../component/common/DateBox"
import MultiComboBox from "../../component/common/MultiComboBox"
import ComboBox from "../../component/common/ComboBox"
import MyChart from "../../popup/AddDev/Mychart"
import CompareChart from "../../popup/AddDev/CompareChart"
import Tab from "../../component/common/Tab"
import SensorTargetValuePopup from "../../popup/AddDev/SensorTargetValuePopup"

import RequiredMark from "../../component/RequiredMark"
import AlertPopup from "../../popup/AlertPopup"

import { Oval } from "react-loader-spinner"
import { BallTriangle } from "react-loader-spinner"

import CircularIndeterminate from "../../component/common/CircularIndeterminate" //로딩화면
import Pagination from "../../component/Pagination" //페이징
//import GoogleChartSample from "../../popup/AddDev/GoogleChartSample"
//import HighChartSample from "../../popup/AddDev/HighChartSample"
//import ToastChartSample from "../../popup/AddDev/ToastChartSample"
//import DygraphChartSample from "../../popup/AddDev/DygraphChartSample"

// import { downExcel, dateFormat } from "../../../utils/util"
// import { setTargetValueList } from "../../../actions/ChartAction"
// import AddDevItemContainer from "../../../containers/AddDevItemContainer"
// import SalesInfoPopup from "../../popup/SalesManage/SalesInfoPopup"
// import { TableComponent } from "../../component/common/Table"
// import InputBox from "../../component/common/InputBox"
// import CodeHelpBox from "../../component/common/CodeHelpBox"
// import ChartPopup from "../../popup/AddDev/ChartPopup"
// import { setSensor } from "../../../actions/DefaultInfoAction"
//import '../../popup/AddDev/chart.css'
// import icon_search from "images/search.png"
// import CodeHelpItemPopup from "../../popup/CodeHelpItemPopup"

/* 
  구현 방식
    1. 이벤트 발생 시 특정 함수 호출
    2. 1번의 함수 호출로 인한 변수의 데이터 값 변경
    3. 2번의 변수 값 변경으로 인한 useEffect 실행
    4. 3번으로 인해 렌더링이 되며 차트 내용 변경 
*/

const SensorDataInquiryComp = (props) => {
    const { t, initDataList, getDataList, saveDataList, setDataList, getComboList } = props

    const {
        itemList: dataList,
        ITEM_LIST: actionType,

        // 다국어
        initTranslationWords,
        getTranslationWords,
        _translationWords,
        replaceWord,

        // 차트
        getWorkCenterComboList, // 워크센터 콤보박스 리스트 가져오는 함수
        getSensorNodeComboList, // 센서노드 콤보박스 리스트 가져오는 함수
        getSensorKindComboList, // 센서노드 콤보박스 리스트 가져오는 함수
        getSensorMinMax, // 기간 내 센서의 최소값 최대값 가져오는 함수
        initSensorMinMax,

        initChartDataList, // 차트데이터 초기화 함수
        getChartDataList, // 차트데이터 가져오는 함수

        initCompareChartDataList, // 비교 차트데이터 초기화 함수
        getCompareChartDataList, // 비교 차트데이터 가져오는 함수

        _workCenterComboList, // 워크센터 리스트 담기는 변수
        _sensorNodeComboList, // 센서노드 리스트 담기는 변수
        _sensorKindComboList,
        _chartDataList, // 차트데이터 담기는 변수
        _compareChartDataList, // 비교 차트데이터 담기는 변수
        _sensorMinMax, // 센서 데이터 최소값 최대값 담기는 변수

        getTargetValueList, // 센서 목표값 리스트 가져오기
        initTargetValueList, // 센서 목표값 리스트 초기화
        _targetValueList, // 센서 목표값 리스트 담기는 변수
    } = props

    const [itemNo, setItemNo] = useState("")

    // 다국어 관련 ==================================================
    const dict = _translationWords[0] // 변수에 사전 담기
    const dictCk = (word) => {
        if (dict == undefined || dict == null || dict == {} || dict == "") {
            return word
        } else {
            const result = dict[word] != undefined && dict[word] != null && dict[word] != "" ? dict[word] : word
            return result
        }
    }
    // ==============================================================

    const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })

    const [myChartIsChanged, setMyChartIsChanged] = useState([0]) // MyChart가 변경되었는지 확인하는 키값

    // const [stdDate, setStdDate] = useState(moment().format("YYYY0101")) // 조회조건 시작 일시
    const [stdDate, setStdDate] = useState(moment().subtract(30, "day").format("YYYYMMDD")) // 조회조건 시작 일시
    // const [endDate, setEndDate] = useState(moment().format("YYYYMMDD")) // 조회조건 종료 일시
    const [endDate, setEndDate] = useState(moment().subtract(0, "day").format("YYYYMMDD")) // 조회조건 종료 일시

    const [workCenterComboList, setWorkCenterComboList] = useState([]) // 작업장 리스트 담는 변수
    const [sensorNodeComboList, setSensorNodeComboList] = useState([]) // 센서노드 리스트 담는 변수
    const [sensorKindComboList, setSensorKindComboList] = useState([]) // 센서종류 리스트 담는 변수
    const unitInquiryComboList = [
        // 조회단위 리스트
        { unitInquirySeq: "1m", unitInquiryName: "1" + dictCk("minutes") },
        { unitInquirySeq: "10m", unitInquiryName: "10" + dictCk("minutes") },
        { unitInquirySeq: "1h", unitInquiryName: "1" + dictCk("hour") },
        { unitInquirySeq: "1day", unitInquiryName: dictCk("1day") },
        { unitInquirySeq: "1week", unitInquiryName: dictCk("1week") },
        { unitInquirySeq: "1month", unitInquiryName: dictCk("1month") },
    ]
    const [classifyKey, setClassifyKey] = useState(0) // 특정 명령을 실행할 때 일반차트 그래프인지, 비교차트 그래프인지 구분하는 키값
    const [isOpenChartPopup, setIsOpenChartPopup] = useState(false) // 차트 팝업 시 사용됨

    const [tabIndex, setTabIndex] = useState(0) // tab 라이브러리의 tab 순서 담는 변수
    const [isOpenSensorTargetValuePopup, setIsOpenSensorTargetValuePopup] = useState(false) // 일반 차트의 목표값 설정 팝업화면 on/off 변수
    const [targetValues, setTargetValues] = useState([]) // 목표 최대값 및 최소값 리스트 담는 변수
    const [isTargetValuePopup, setIsTargetValuePopup] = useState(0) // 목표값 설정 팝업화면이 on인지 off인지 구분하는 키값

    // 일반 차트 관련 useState =======================================================================================================
    const [sensorKind, setSensorKind] = useState("") // 센서종류 담는 변수
    const [workCenterDropIndex, setWorkCenterDropIndex] = useState("") // 선택된 작업장 데이터 담는 변수
    const [sensorNodeDropIndex, setSensorNodeDropIndex] = useState("") // 선택된 센서노드 리스트 담는 변수
    const [sensorKindDropIndex, setSensorKindDropIndex] = useState("") // 선택된 센서종류 담는 변수
    const [unitInquiryDropIndex, setUnitInquiryDropIndex] = useState("") // 선택된 조회단위 담는 변수

    const [chartDataList, setChartDataList] = useState([]) // 그래프의 데이터 리스트 담는 변수
    const [categoryList, setCategoryList] = useState([]) // 카테고리 리스트 담는 변수
    const [seriesList, setSeriesList] = useState([]) // 시리즈 리스트 담는 변수 > series는 'ApexCharts'에서 그래프 데이터로 사용되는 변수명임
    const [tooltipList, setTooltipList] = useState([]) // toolTip 리스트 담는 변수 > toolTip은 그래프에 커서가 위치했을 때 나타나는 상자
    const [sensorMinMax, setSensorMinMax] = useState([]) // 센서의 최대값 및 최소값 담는 변수
    const [chartLoading, setChartLoading] = useState([]) // 차트의 조회버튼 클릭 시 로딩 관련
    const [loading, setLoading] = useState(false) // 로딩 변수

    const [currentPage, setCurrentPage] = useState(1) // 현재페이지
    const [totalData, setTotalData] = useState(0) // 총데이터 숫자
    const [dataCount, setDataCount] = useState(0) // 조회단위에 따른 카운팅갯수
    // ==============================================================================================================================

    // 비교 차트 관련 useState =======================================================================================================
    const [compareSensorKind, setCompareSensorKind] = useState("") // 센서종류 담는 변수
    const [compareWorkCenterDropIndex, setCompareWorkCenterDropIndex] = useState("") // 비교차트의 선택된 작업장 데이터 담는 변수
    const [compareSensorNodeDropIndex, setCompareSensorNodeDropIndex] = useState("") // 비교차트의 선택된 센서노드 리스트 담는 변수
    const [compareUnitInquiryDropIndex, setCompareUnitInquiryDropIndex] = useState("") // 비교차트의 선택된 조회단위 담는 변수

    const [compareChartDataList, setCompareChartDataList] = useState([]) // 비교차트 그래프의 데이터 리스트 담는 변수
    const [compareCategoryList, setCompareCategoryList] = useState([]) // 비교차트의 카테고리 리스트 담는 변수
    const [compareSeriesList, setCompareSeriesList] = useState([]) // 비교차트의 시리즈 리스트 담는 변수
    const [compareTooltipList, setCompareTooltipList] = useState([]) // 비교차트의 toolTip 리스트 담는 변수
    const [compareYaxisList, setCompareYaxisList] = useState([]) // 비교차트의 y축 리스트 담는 변수
    const [compareSensorMinMax, setCompareSensorMinMax] = useState([]) // 비교차트 센서의 최대값 및 최소값 담는 변수
    const [compareChartLoading, setCompareChartLoading] = useState([]) // 비교차트의 조회버튼 클릭 시 로딩 관련
    // ==============================================================================================================================

    // 목표값 관련 useState =======================================================================================================
    const [targetValueSensorKind, setTargetValueSensorKind] = useState("")

    // 날짜 최대 최저
    const [minDate, setMinDate] = useState(new Date()) //날짜 최소일
    const [maxDate, setMaxDate] = useState(new Date()) //날짜 최대일

    // const [journalAddPopup, setJournalAddPopup] = useState(false)
    // const [imgListPopup, setImgListPopup] = useState(false)
    // const [reportSeq, setReportSeq] = useState(-1) //createWrite or Edit
    // const [dropIndex, setDropIndex] = useState("")
    // const [value, setValue] = useState([]) //multiComboBox

    useEffect(() => {
        getTranslationWords()
        getWorkCenterComboList()
        getSensorNodeComboList()
        getSensorKindComboList()
        initChartDataList()
        initCompareChartDataList()
        initSensorMinMax()

        function handleLoad() {
            // 페이지 로드 완료 시 실행할 코드를 여기에 작성합니다.
            console.log("Page has finished loading")
            setChartLoading(true) // 예를 들어, 로딩 상태를 변경하는 등의 작업을 수행할 수 있습니다.
        }

        window.addEventListener("DOMContentLoaded", handleLoad)

        return () => {
            // 페이지가 언마운트될 때 load 이벤트 리스너를 제거합니다.
            window.removeEventListener("DOMContentLoaded", handleLoad)
        }
    }, [])

    useEffect(() => {
        //tabIndex 할때 차트 & 페이징 리셋
        if (categoryList != [] && seriesList != [] && tooltipList != []) {
            // 일반차트 탭 바꿀시 데이터 담고있는 변수들 리셋

            setChartDataList([])
            setCategoryList([])
            setSeriesList([])
            setTooltipList([])
            setSensorMinMax([])
            //탭 바꿀시 페이징 리셋
            setCurrentPage(1) // 현재페이지
            setTotalData(0) // 총데이터 숫자
            setDataCount(0) // 조회단위에 따른 카운팅갯수
        }

        if (compareCategoryList != [] && compareSeriesList != [] && compareTooltipList != [] && compareYaxisList != []) {
            // 비교차트 탭바꿀시 데이터 담고있는 변수들 리셋
            setCompareChartDataList([])
            setCompareCategoryList([])
            setCompareSeriesList([])
            setCompareTooltipList([])
            setCompareYaxisList([])
            //탭 바꿀시 페이징 리셋
            setCurrentPage(1) // 현재페이지
            setTotalData(0) // 총데이터 숫자
            setDataCount(0) // 조회단위에 따른 카운팅갯수
        }
    }, [tabIndex])

    // 만약 props의 각 콤보리스트 변수가 변경되면 다시 useState에 값 할당
    useEffect(() => {
        if (_workCenterComboList != null && _workCenterComboList != undefined && _workCenterComboList.length != 0) {
            setWorkCenterComboList(_workCenterComboList)
            setWorkCenterDropIndex(_workCenterComboList[0]["workCenterSeq"])
            setCompareWorkCenterDropIndex(_workCenterComboList[0]["workCenterSeq"])
        }
        if (_sensorNodeComboList != null && _sensorNodeComboList != undefined && _sensorNodeComboList.length != 0) {
            setSensorNodeComboList(_sensorNodeComboList)
        }
        if (_sensorKindComboList != null && _sensorKindComboList != undefined && _sensorKindComboList.length != 0) {
            setSensorKindComboList(_sensorKindComboList)
            console.log("sensorKindComboList >>>>>>>>>>>>>>>>>>>>", _sensorKindComboList)
            setSensorKindDropIndex(_sensorKindComboList[0]["sensorKind"])
        }
        if (unitInquiryComboList != null && unitInquiryComboList != undefined && unitInquiryComboList.length != 0) {
            setUnitInquiryDropIndex(unitInquiryComboList[3]["unitInquirySeq"])
            setCompareUnitInquiryDropIndex(unitInquiryComboList[3]["unitInquirySeq"])
        }
    }, [_workCenterComboList, _sensorNodeComboList, _sensorKindComboList])

    // _chartDataList 값이 변경되면 다시 chartDataList에 값 할당
    useEffect(() => {
        if (_chartDataList == null || _chartDataList == [] || _chartDataList == undefined || _chartDataList.length == 0) {
            // console.log("_chartDataList@@@@@@@@@@@@@@@", _chartDataList)
        } else {
            // console.log("_chartDataList@@@@@2222222222", _chartDataList)
            // console.log("_chartDataList@@@@@3333333333", _chartDataList[0]["dataList"][0]["series"].length)
            // console.log("_chartDataList@@@@@3333333333", _chartDataList[0]["series"].length)
            if (_chartDataList != [] && _chartDataList[0]["series"].length != 0) {
                setChartDataList(_chartDataList[0]["dataList"])
                Promise.all(chartDataList)
            } else {
                // 조회를 했지만 센서 데이터가 비어있는경우 그래프 리셋
                setChartDataList(_chartDataList)

                setCategoryList([])
                setSeriesList([])
                setTooltipList([])
                setSensorMinMax([])
                //탭 바꿀시 페이징 리셋
                setCurrentPage(1) // 현재페이지
                setTotalData(0) // 총데이터 숫자
                setDataCount(0) // 조회단위에 따른 카운팅갯수
            }
        }
    }, [_chartDataList])

    // _compareChartDataList 값이 변경되면 다시 compareChartDataList에 값 할당
    useEffect(() => {
        if (_compareChartDataList == null || _compareChartDataList == [] || _compareChartDataList == undefined || _compareChartDataList.length == 0) {
            // 없음
        } else {
            setCompareChartDataList(_compareChartDataList)
        }
    }, [_compareChartDataList])

    // _sensorMinMax 값이 변경되면 차트 구분 키값에 따라 값 다시 할당
    useEffect(() => {
        if (_sensorMinMax == null || _sensorMinMax == [] || _sensorMinMax == undefined || _sensorMinMax.length == 0) {
            // 없음
        } else {
            if (classifyKey == 1) {
                setSensorMinMax(_sensorMinMax)
                Promise.all(sensorMinMax)
            } else if (classifyKey == 2) {
                setCompareSensorMinMax(_sensorMinMax)
                Promise.all(compareSensorMinMax)
            }
        }
    }, [_sensorMinMax])

    // _targetValueList 값이 변경되면 다시 finalTargetValues에 값 할당
    useEffect(() => {
        setTargetValues([])
        const fetchData = async () => {
            try {
                if (_targetValueList != []) {
                    let finalTargetValues = []
                    let sensorKindName = ""
                    for (let sensorKind of sensorKindComboList) {
                        if (sensorKind.sensorKind == sensorKindDropIndex) {
                            sensorKindName = sensorKind.sensorName
                        }
                    }
                    for (let i in _targetValueList) {
                        if (sensorNodeDropIndex.length > 0) {
                            for (let sensorNode of sensorNodeDropIndex) {
                                if (_targetValueList[i].sensorNodeSeq == sensorNode.value) {
                                    let targetValue = _targetValueList[i]
                                    targetValue["sensorNodeName"] = sensorNode.label
                                    targetValue["sensorKindName"] = sensorKindName
                                    finalTargetValues.push(_targetValueList[i])
                                }
                            }
                        }
                    }
                    setTargetValues(finalTargetValues)
                    Promise.all(targetValues)
                }
            } catch (error) {
                console.error(error)
            }
        }
        // Call the async function
        fetchData()
    }, [_targetValueList])

    // useEffect(() => {
    // }, [targetValues])

    // chartDataList 값이 변경되면 카테고리, 시리즈, 툴팁의 값을 가져옴
    useEffect(() => {
        if (chartDataList == null || chartDataList == [] || chartDataList == undefined || chartDataList.length == 0) {
            // setChartLoading(true)
            setTimeout(() => {
                setLoading(false) // 조회버튼 로딩종료
            }, 1000)
        } else {
            const fetchData = async () => {
                try {
                    if (_chartDataList != [] && _chartDataList.length > 0) {
                        // setCategoryList(_chartDataList[0]['category'])
                        // setSeriesList(_chartDataList[0]['series'])
                        // setTooltipList(_chartDataList[0]['tooltip'])

                        // 23.0905 - 일반 차트 데이터 페이징 추가 START - 김한중 ==========================================================
                        const seriesListtotal = _chartDataList[0]["series"].map((item) => item.data) //조회한 데이터의 총 갯수
                        setTotalData(seriesListtotal[0].length) // 데이터 총 갯수 set
                        // console.log("@@@@@@@@@@@@@@@@@@@@@@@seriesListtotal[0].length::", seriesListtotal[0].length)
                        const startIdx = (currentPage - 1) * dataCount // 시작 인덱스 계산
                        const endIdx = currentPage * dataCount // 끝 인덱스 계산

                        const categoryListSplit = _chartDataList[0]["category"].slice(startIdx, endIdx) // setCategoryList(_chartDataList[0]['category'])
                        const seriesListSplit = _chartDataList[0]["series"].map((item) => {
                            // setSeriesList(_chartDataList[0]['series'])
                            return { name: item.name, data: item.data.slice(startIdx, endIdx) }
                        })
                        const tooltipListMax = _chartDataList[0]["tooltip"]["max"].map((item) => {
                            // setTooltipList(_chartDataList[0]['tooltip'])
                            return { name: item.name, data: item.data.slice(startIdx, endIdx) }
                        })
                        const tooltipListMin = _chartDataList[0]["tooltip"]["min"].map((item) => {
                            // setTooltipList(_chartDataList[0]['tooltip'])
                            return { name: item.name, data: item.data.slice(startIdx, endIdx) }
                        })

                        const tooltipListSplit = { max: tooltipListMax, min: tooltipListMin }

                        setCategoryList(categoryListSplit)
                        setSeriesList(seriesListSplit)
                        setTooltipList(tooltipListSplit)
                        // 23.0905 - 일반 차트 데이터 페이징 추가 END - 김한중 ==========================================================
                    } else {
                        setCategoryList([])
                        setSeriesList([])
                        setTooltipList([])
                    }

                    if (chartDataList[0] != 0) {
                        setMyChartIsChanged([1])
                        Promise.all(myChartIsChanged)
                    }
                } catch (error) {
                    console.error(error)
                }
            }
            // Call the async function
            fetchData()
        }
    }, [chartDataList, currentPage])

    // compareChartDataList 값이 변경되면 비교차트의 카테고리, 시리즈, 툴팁, Y축 리스트의 값을 가져옴
    useEffect(() => {
        if (compareChartDataList == null || compareChartDataList == [] || compareChartDataList == undefined || compareChartDataList.length == 0) {
            setCompareChartLoading(true)
            setTimeout(() => {
                setLoading(false) // 조회버튼 로딩종료
            }, 1000)
        } else {
            const fetchData = async () => {
                try {
                    if (_compareChartDataList != [] && _compareChartDataList.length > 0) {
                        // setCompareCategoryList(_compareChartDataList[0]['category'])
                        // setCompareSeriesList(_compareChartDataList[0]['series'])
                        // setCompareTooltipList(_compareChartDataList[0]['series'])
                        // setCompareYaxisList(_compareChartDataList[0]['yaxisList'])

                        // 23.0905 - 비교 차트 데이터 페이징 추가 START - 김한중 ==========================================================
                        if (_compareChartDataList[0]["series"].length == 0) {
                            setCompareCategoryList([])
                            setCompareSeriesList([])
                            setCompareTooltipList([])
                            setCompareYaxisList([])
                        } else {
                            const compareChartDataListtotal = _compareChartDataList[0]["series"].map((item) => item.data) //조회한 데이터의 총 갯수
                            setTotalData(compareChartDataListtotal[0].length) // 데이터 총 갯수 set

                            const startIdx = (currentPage - 1) * dataCount // 시작 인덱스 계산
                            const endIdx = currentPage * dataCount // 끝 인덱스 계산

                            const categoryListSplit = _compareChartDataList[0]["category"].slice(startIdx, endIdx) // setCompareCategoryList(_compareChartDataList[0]['category'])
                            const seriesListSplit = _compareChartDataList[0]["series"].map((item) => {
                                // setCompareSeriesList(_compareChartDataList[0]['series']) // setCompareTooltipList(_compareChartDataList[0]['series'])
                                return { name: item.name, data: item.data.slice(startIdx, endIdx) }
                            })

                            setCompareCategoryList(categoryListSplit)
                            setCompareSeriesList(seriesListSplit)
                            setCompareTooltipList(seriesListSplit)
                            setCompareYaxisList(_compareChartDataList[0]["yaxisList"])
                        }
                        // 23.0905 - 비교 차트 데이터 페이징 추가 END - 김한중 ==========================================================
                    } else {
                        setCompareCategoryList([])
                        setCompareSeriesList([])
                        setCompareTooltipList([])
                        setCompareYaxisList([])
                        setCompareChartLoading(true)
                        setLoading(false) // 조회버튼 로딩종료
                        return
                    }

                    if (chartDataList[0] != 0) {
                        setMyChartIsChanged([1])
                        Promise.all(myChartIsChanged)
                    }
                    setCompareChartLoading(true)
                    setLoading(false) // 조회버튼 로딩종료
                } catch (error) {
                    console.error(error)
                }
            }

            // Call the async function
            fetchData()
        }
    }, [compareChartDataList, currentPage])

    useEffect(() => {
        if (categoryList != [] && seriesList != [] && tooltipList != []) {
            // setChartLoading(true)
            setTimeout(() => {
                setLoading(false) // 조회버튼 로딩종료
            }, 1000)
        }
    }, [categoryList, seriesList, tooltipList])

    useEffect(() => {
        if (compareCategoryList != [] && compareSeriesList != [] && compareTooltipList != [] && compareYaxisList != []) {
            setCompareChartLoading(true)
            setTimeout(() => {
                setLoading(false) // 조회버튼 로딩종료
            }, 1000)
        }
    }, [compareCategoryList, compareSeriesList, compareTooltipList, compareYaxisList])

    // 23.0905 - 날짜제한 추가 - 김한중 ==========================================================
    // ======================================================================날짜 제한 큰버전START
    // 날짜 변경시 달력 날짜 제한
    useEffect(() => {
        // setStdDate(stdDate);//시작일
        // setEndDate(stdDate);//종료일 동일하게 변경
        const differenceInDays = calculateDateDifference(stdDate, endDate) //두 날짜 차이값

        let dayNum = 0
        const plusMinus = "-"
        if ((tabIndex == 0 && unitInquiryDropIndex == "1m") || (tabIndex == 1 && compareUnitInquiryDropIndex == "1m")) {
            setDataCount(180) // 화면에서 보여줄 데이터 갯수 1분단위 3시간
            dayNum = 2
            if (differenceInDays >= 3) {
                const dateChange = dateConversion(endDate, 1, plusMinus) // 바꿀날짜
                // 날짜 차이가 조건값 넘을시 시작일 종료일 리셋
                setStdDate(dateChange) //시작일
                setEndDate(endDate) //종료일 동일하게 변경
            }
        } else if ((tabIndex == 0 && unitInquiryDropIndex == "10m") || (tabIndex == 1 && compareUnitInquiryDropIndex == "10m")) {
            setDataCount(144) // 화면에서 보여줄 데이터 갯수 10분단위 1일
            dayNum = 30
            if (differenceInDays >= 31) {
                const dateChange = dateConversion(endDate, 10, plusMinus) // 바꿀날짜
                // 날짜 차이가 조건값 넘을시 시작일 종료일 리셋
                setStdDate(dateChange) //시작일
                setEndDate(endDate) //종료일 동일하게 변경
            }
        } else if ((tabIndex == 0 && unitInquiryDropIndex == "1h") || (tabIndex == 1 && compareUnitInquiryDropIndex == "1h")) {
            setDataCount(168) // 화면에서 보여줄 데이터 갯수 1시간단위 7일
            dayNum = 91
            if (differenceInDays >= 92) {
                const dateChange = dateConversion(endDate, 31, plusMinus) // 바꿀날짜
                // 날짜 차이가 조건값 넘을시 시작일 종료일 리셋
                setStdDate(dateChange) //시작일
                setEndDate(endDate) //종료일 동일하게 변경
            }
        } else if ((tabIndex == 0 && unitInquiryDropIndex == "1day") || (tabIndex == 1 && compareUnitInquiryDropIndex == "1day")) {
            setDataCount(180) // 화면에서 보여줄 데이터 갯수 하루단위 3개월
            dayNum = 729
            if (differenceInDays >= 730) {
                const dateChange = dateConversion(endDate, 180, plusMinus) // 바꿀날짜
                // 날짜 차이가 조건값 넘을시 시작일 종료일 리셋
                setStdDate(dateChange) //시작일
                setEndDate(endDate) //종료일 동일하게 변경
            }
        } else {
            setDataCount(200) // 화면에서 보여줄 데이터 갯수 나머지
            dayNum = 10000
        }
        // console.log("@@@@@@@@dayNum 조회날짜", dayNum)
        // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
        const originalDate = new Date(stdDate.substr(0, 4) + "-" + stdDate.substr(4, 2) + "-" + stdDate.substr(6, 2))
        const modifiedDate = originalDate.setDate(originalDate.getDate() + parseInt(dayNum))

        setMinDate(new Date(stdDate.substr(0, 4) + "-" + stdDate.substr(4, 2) + "-" + stdDate.substr(6, 2)))
        setMaxDate(modifiedDate)
        // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
        // console.log('dateFr(1) : ', dateFr)
        // console.log('dateTo(1) : ', dateTo)
    }, [unitInquiryDropIndex, compareUnitInquiryDropIndex, tabIndex])
    // ======================================================================날짜 제한 큰버전END
    // ======================================================================날짜 제한 큰버전START
    // 날짜 변경시 달력 날짜 제한
    useEffect(() => {
        // setStdDate(stdDate);//시작일
        // setEndDate(stdDate);//종료일 동일하게 변경
        const differenceInDays = calculateDateDifference(stdDate, endDate) //두 날짜 차이값

        let dayNum = 0
        const plusMinus = "+"
        if ((tabIndex == 0 && unitInquiryDropIndex == "1m") || (tabIndex == 1 && compareUnitInquiryDropIndex == "1m")) {
            setDataCount(180) // 화면에서 보여줄 데이터 갯수 1분단위 3시간
            dayNum = 2
            if (differenceInDays >= 3 || parseInt(stdDate) > parseInt(endDate)) {
                const dateChange = dateConversion(stdDate, 1, plusMinus) // 바꿀날짜
                // 날짜 차이가 조건값 넘을시 시작일 종료일 리셋
                setStdDate(stdDate) //시작일
                setEndDate(dateChange) //종료일 동일하게 변경
            }
        } else if ((tabIndex == 0 && unitInquiryDropIndex == "10m") || (tabIndex == 1 && compareUnitInquiryDropIndex == "10m")) {
            setDataCount(144) // 화면에서 보여줄 데이터 갯수 10분단위 1일
            dayNum = 30
            if (differenceInDays >= 31 || parseInt(stdDate) > parseInt(endDate)) {
                const dateChange = dateConversion(stdDate, 10, plusMinus) // 바꿀날짜
                // 날짜 차이가 조건값 넘을시 시작일 종료일 리셋
                setStdDate(stdDate) //시작일
                setEndDate(dateChange) //종료일 동일하게 변경
            }
        } else if ((tabIndex == 0 && unitInquiryDropIndex == "1h") || (tabIndex == 1 && compareUnitInquiryDropIndex == "1h")) {
            setDataCount(168) // 화면에서 보여줄 데이터 갯수 1시간단위 7일
            dayNum = 91
            if (differenceInDays >= 92 || parseInt(stdDate) > parseInt(endDate)) {
                const dateChange = dateConversion(stdDate, 31, plusMinus) // 바꿀날짜
                // 날짜 차이가 조건값 넘을시 시작일 종료일 리셋
                setStdDate(stdDate) //시작일
                setEndDate(dateChange) //종료일 동일하게 변경
            }
        } else if ((tabIndex == 0 && unitInquiryDropIndex == "1day") || (tabIndex == 1 && compareUnitInquiryDropIndex == "1day")) {
            setDataCount(180) // 화면에서 보여줄 데이터 갯수 하루단위 3개월
            dayNum = 729
            if (differenceInDays >= 730 || parseInt(stdDate) > parseInt(endDate)) {
                const dateChange = dateConversion(stdDate, 180, plusMinus) // 바꿀날짜
                // 날짜 차이가 조건값 넘을시 시작일 종료일 리셋
                setStdDate(stdDate) //시작일
                setEndDate(dateChange) //종료일 동일하게 변경
            }
        } else {
            setDataCount(200) // 화면에서 보여줄 데이터 갯수 나머지
            dayNum = 10000
        }
        // console.log("@@@@@@@@dayNum 조회날짜", dayNum)
        // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
        const originalDate = new Date(stdDate.substr(0, 4) + "-" + stdDate.substr(4, 2) + "-" + stdDate.substr(6, 2))
        const modifiedDate = originalDate.setDate(originalDate.getDate() + parseInt(dayNum))

        setMinDate(new Date(stdDate.substr(0, 4) + "-" + stdDate.substr(4, 2) + "-" + stdDate.substr(6, 2)))
        setMaxDate(modifiedDate)
        // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
        // console.log('dateFr(1) : ', dateFr)
        // console.log('dateTo(1) : ', dateTo)
    }, [stdDate, endDate])
    // ======================================================================날짜 제한 큰버전END

    // 조회 단위에 따른 조회 기간 제한을 주기 위해 두 날짜 사이의 일 수 계산하는 함수 - 차건담 2023.08.08
    function calculateDateDifference(startDate, endDate) {
        startDate = startDate.substr(0, 4) + "-" + startDate.substr(4, 2) + "-" + startDate.substr(6, 2)
        endDate = endDate.substr(0, 4) + "-" + endDate.substr(4, 2) + "-" + endDate.substr(6, 2)
        const oneDay = 24 * 60 * 60 * 1000 // 1일을 밀리초로 표현
        console.log("startDate/ endDate:", startDate, " / ", endDate)
        // 날짜를 밀리초로 변환하여 차이 계산
        const startTime = new Date(startDate).getTime()
        const endTime = new Date(endDate).getTime()
        console.log("startTime:", startTime)
        console.log("endTime:", endTime)
        // 날짜 차이를 일 단위로 계산
        const differenceInDays = Math.round(Math.abs((endTime - startTime) / oneDay))

        return differenceInDays
    }

    // 날짜를 더해주는 함수(dateChange=변할날짜, changNum=변할날짜수, plusMinus=더할지뺼지)
    function dateConversion(dateChange, changNum, plusMinus) {
        // 입력받은 stdDate를 "YYYY-MM-DD" 형식의 날짜로 변환합니다.
        const originalDateS = new Date(dateChange.substr(0, 4) + "-" + dateChange.substr(4, 2) + "-" + dateChange.substr(6, 2))
        // 날짜를 3일 뒤로 변경합니다.
        if (plusMinus == "+") {
            originalDateS.setDate(originalDateS.getDate() + changNum)
        } else if (plusMinus == "-") {
            originalDateS.setDate(originalDateS.getDate() - changNum)
        }
        // 변경된 날짜의 연도를 가져옵니다.
        const year = originalDateS.getFullYear()
        // 변경된 날짜의 월을 가져오고, 문자열로 변환하며 한 자리 숫자인 경우 앞에 0을 추가합니다.
        const month = String(originalDateS.getMonth() + 1).padStart(2, "0")
        // 변경된 날짜의 일을 가져오고, 문자열로 변환하며 한 자리 숫자인 경우 앞에 0을 추가합니다.
        const day = String(originalDateS.getDate()).padStart(2, "0")
        // 변경된 날짜를 "YYYYMMDD" 형식으로 만듭니다.
        const modifiedDate = `${year}${month}${day}`
        // 결과값으로 변경된 날짜를 반환합니다.
        return modifiedDate
    }
    // 예시: dateConversion("20230305") 호출 시 "20230308" 반환

    // 클릭 시 조회 조건에 따른 차트데이터 가져오는 함수 - 차건담 2023.05.22
    const onClickSearch = async () => {
        console.log("onClickSearch > sensorNodeDropIndex", sensorNodeDropIndex) // 김한중

        if (workCenterDropIndex === undefined || workCenterDropIndex === "" || workCenterDropIndex === 0)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("workCenterName")) })
        //작업장명
        else if (sensorNodeDropIndex === undefined || sensorNodeDropIndex === "" || sensorNodeDropIndex.length == 0)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("sensorNodeName")) })
        //센서노드명
        else if (unitInquiryDropIndex === undefined || unitInquiryDropIndex === "" || unitInquiryDropIndex === 0)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("inquiry") + dictCk("unit")) })
        //조회단위
        else if (sensorKindDropIndex === undefined || sensorKindDropIndex === "" || sensorKindDropIndex === 0)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("sensorKind")) })
        //센서종류
        else if (stdDate === undefined || stdDate.length < 8)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("dateFr")) })
        //등록일 시작
        else if (endDate === undefined || endDate.length < 8)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("dateTo")) }) //등록일 종료

        const differenceInDays = calculateDateDifference(stdDate, endDate) // 두 날짜사이 일수 계산

        console.log("@@@@@@@@@@@@differenceInDays:", differenceInDays)

        // ======================================================================날짜 제한 큰버전START
        if (unitInquiryDropIndex == "1m") {
            // 1분일 경우 최대 3일로 제한
            if (differenceInDays >= 3)
                return setAlertPopup({
                    open: true,
                    text:
                        replaceWord(dictCk("query in n.minutes"), "1" + dictCk("minutes")) +
                        " " +
                        replaceWord(dictCk("limit the query period to a maximum of n.days"), "3"),
                })
        } else if (unitInquiryDropIndex == "10m") {
            // 10분일 경우 최대 31일로 제한
            console.log(differenceInDays)
            if (differenceInDays >= 31)
                return setAlertPopup({
                    open: true,
                    text:
                        replaceWord(dictCk("query in n.minutes"), "10" + dictCk("minutes")) +
                        " " +
                        replaceWord(dictCk("limit the query period to a maximum of n.days"), "31"),
                })
        } else if (unitInquiryDropIndex == "1h") {
            // 1시간일 경우 최대 92일로 제한
            if (differenceInDays >= 92)
                return setAlertPopup({
                    open: true,
                    text:
                        replaceWord(dictCk("query in n.minutes"), "1" + dictCk("hour")) +
                        " " +
                        replaceWord(dictCk("limit the query period to a maximum of n.days"), "92"),
                })
        } else if (unitInquiryDropIndex == "1day") {
            // 하루일 경우 최대 365일로 제한
            if (differenceInDays >= 730)
                return setAlertPopup({
                    open: true,
                    text:
                        replaceWord(dictCk("query in n.minutes"), "1" + dictCk("day")) +
                        " " +
                        replaceWord(dictCk("limit the query period to a maximum of n.days"), "730"),
                })
        }
        // ======================================================================날짜 제한 큰버전END

        // setChartLoading(false) // 조회버튼 로딩시작
        setLoading(true) // 조회버튼 로딩시작

        setClassifyKey(1)
        if (workCenterDropIndex == "") {
            // 워크센터 선택되지 않으면 차트데이터 가져오지 않음
            await initChartDataList()
            await initSensorMinMax()
            setChartDataList([0])
        } else {
            let allSensorNode = []
            for (let i in sensorNodeComboList) {
                allSensorNode.push(sensorNodeComboList[i]["sensorNodeSeq"])
            }
            const data = {
                workCenterSeq: workCenterDropIndex == "" ? "" : workCenterDropIndex,
                sensorNodeSeq: sensorNodeDropIndex == [] || sensorNodeDropIndex.length == 0 ? allSensorNode : sensorNodeDropIndex.map((i) => i.value),
                sensorKind: sensorKindDropIndex == "" ? "" : sensorKindDropIndex,
                unitInquiry: unitInquiryDropIndex == "" ? "" : unitInquiryDropIndex,
                dateFr: stdDate,
                dateTo: endDate,
                tab: "tab1",
            }
            setSensorKind(data["sensorKind"])

            await getChartDataList(data)
            await getSensorMinMax(data)
            await getTargetValueList({ sensorKind: data["sensorKind"] })
        }
    }

    // 클릭 시 조회 조건에 따른 비교차트 데이터 가져오는 함수
    const onClickButton = async (sensorKind) => {
        if (compareWorkCenterDropIndex === undefined || compareWorkCenterDropIndex === "" || compareWorkCenterDropIndex === 0)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("workCenterName")) })
        //작업장명
        else if (compareSensorNodeDropIndex === undefined || compareSensorNodeDropIndex === "" || compareSensorNodeDropIndex.length == 0)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("sensorNodeName")) })
        //센서노드명
        else if (compareUnitInquiryDropIndex === undefined || compareUnitInquiryDropIndex === "" || compareUnitInquiryDropIndex === 0)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("inquiry") + dictCk("unit")) })
        //조회단위
        else if (stdDate === undefined || stdDate.length < 8)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("dateFr")) })
        //등록일 시작
        else if (endDate === undefined || endDate.length < 8)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("dateTo")) }) //등록일 종료

        const differenceInDays = calculateDateDifference(stdDate, endDate)

        // ======================================================================날짜 제한 큰버전START
        if (compareUnitInquiryDropIndex == "1m") {
            // 1분일 경우 최대 3일로 제한
            if (differenceInDays >= 3)
                return setAlertPopup({
                    open: true,
                    text:
                        replaceWord(dictCk("query in n.minutes"), "1" + dictCk("minutes")) +
                        " " +
                        replaceWord(dictCk("limit the query period to a maximum of n.days"), "3"),
                })
        } else if (compareUnitInquiryDropIndex == "10m") {
            // 10분일 경우 최대 31일로 제한
            console.log(differenceInDays)
            if (differenceInDays >= 31)
                return setAlertPopup({
                    open: true,
                    text:
                        replaceWord(dictCk("query in n.minutes"), "10" + dictCk("minutes")) +
                        " " +
                        replaceWord(dictCk("limit the query period to a maximum of n.days"), "31"),
                })
        } else if (compareUnitInquiryDropIndex == "1h") {
            // 1시간일 경우 최대 92일로 제한
            if (differenceInDays >= 92)
                return setAlertPopup({
                    open: true,
                    text:
                        replaceWord(dictCk("query in n.minutes"), "1" + dictCk("hour")) +
                        " " +
                        replaceWord(dictCk("limit the query period to a maximum of n.days"), "92"),
                })
        } else if (compareUnitInquiryDropIndex == "1day") {
            // 하루일 경우 최대 730일로 제한
            if (differenceInDays >= 730)
                return setAlertPopup({
                    open: true,
                    text:
                        replaceWord(dictCk("query in n.minutes"), "1" + dictCk("day")) +
                        " " +
                        replaceWord(dictCk("limit the query period to a maximum of n.days"), "730"),
                })
        }
        // ======================================================================날짜 제한 큰버전END

        setCompareChartLoading(false)
        setLoading(true) // 조회버튼 로딩시작
        setClassifyKey(2)
        if (compareWorkCenterDropIndex.length == 0 || compareSensorNodeDropIndex.length == 0 || compareUnitInquiryDropIndex.length == 0) {
            // 워크센터 선택되지 않으면 차트데이터 가져오지 않음
            await initCompareChartDataList()
            await initSensorMinMax()
            setChartDataList([0])
            setCompareChartLoading(true)
            setLoading(false) // 조회버튼 로딩종료
        } else {
            let allSensorNode = []
            for (let i in sensorNodeComboList) {
                allSensorNode.push(sensorNodeComboList[i]["sensorNodeSeq"])
            }
            const data = {
                workCenterSeq: compareWorkCenterDropIndex == "" ? "" : compareWorkCenterDropIndex,
                sensorNodeSeq:
                    compareSensorNodeDropIndex == [] || compareSensorNodeDropIndex.length == 0
                        ? allSensorNode
                        : compareSensorNodeDropIndex.map((i) => i.value),
                sensorKind: sensorKind,
                unitInquiry: compareUnitInquiryDropIndex == "" ? "" : compareUnitInquiryDropIndex,
                dateFr: stdDate,
                dateTo: endDate,
                tab: "tab2",
            }
            setCompareSensorKind(data["sensorKind"])

            await getCompareChartDataList(data)
            await getSensorMinMax(data)
        }
    }

    const closeChartPopup = async () => {
        setIsOpenChartPopup(false)
    }
    // 목표값 설정 버튼 클릭 시 실행되는 함수
    const openSensorTargetValuePopup = async () => {
        console.log("sensorNodeComboList:", sensorNodeComboList)
        console.log("_sensorNodeList", _sensorNodeComboList)
        setTargetValueSensorKind(sensorKindDropIndex)
        initTargetValueList()
        setIsTargetValuePopup(1)
        setIsOpenSensorTargetValuePopup(true)
        console.log("센서타겟 openSensorTarget > sensorKindDropIndex >>>>>>> ", sensorKindDropIndex)
    }

    // 목표값 설정 팝업 창 닫을 때 사용되는 함수
    const closeSensorTargetValuePopup = async () => {
        setIsOpenSensorTargetValuePopup(false)
        setIsTargetValuePopup(0)
        initTargetValueList()
        if (chartDataList.length > 0) {
            onClickSearch()
        }
        console.log("센서타겟 closeSensorTarget > sensorKindDropIndex >>>>>>> ", sensorKindDropIndex)
    }

    const setZindexOfDatePicker = () => {
        // 화면에서 달력 보여질 때 zIndex 설정 함수
        // const datePicker = document.getElementsByClassName('react-datepicker__tab-loop')
        // console.log("datePicker ??????????????", datePicker)
        // if(datePicker.length > 0){
        //   console.log("datePicker[0] ??????????????", datePicker[0])
        //   datePicker[0].style.zIndex = "1000"
        // }

        const dateBoxFrom = document.getElementById("dateBoxFrom")
        const dateBoxTo = document.getElementById("dateBoxTo")

        if (dateBoxFrom) {
            //console.log("dateBoxFrom ???????????? ",dateBoxFrom)
            dateBoxFrom.style.zIndex = "12"
        }
        if (dateBoxTo) {
            //console.log("dateBoxTo ???????????? ",dateBoxTo)
            dateBoxTo.style.zIndex = "12"
        }
    }

    const initZindexOfDatePicker = () => {
        // 달력 화면에서 보여질 때 zIndex 초기화 함수
        // const datePicker = document.getElementsByClassName('react-datepicker__tab-loop')
        // console.log("datePicker ??????????????", datePicker)
        // if(datePicker.length > 0){
        //   console.log("datePicker[0] ??????????????", datePicker[0])
        //   datePicker[0].style.zIndex = "1000"
        // }

        const dateBoxFrom = document.getElementById("dateBoxFrom")
        const dateBoxTo = document.getElementById("dateBoxTo")

        if (dateBoxFrom) {
            console.log("dateBoxFrom ???????????? ", dateBoxFrom)
            dateBoxFrom.style.zIndex = "10"
        }
        if (dateBoxTo) {
            console.log("dateBoxTo ???????????? ", dateBoxTo)
            dateBoxTo.style.zIndex = "10"
        }
    }

    const setZindexOfMultiCombo = () => {
        const multiComboDropDown = document.getElementById("sensorNodeMultiComboBox")
        console.log("multiComboDropDown ??????????????????", multiComboDropDown)
        if (multiComboDropDown) {
            console.log("multiComboDropDown ??????????????????", multiComboDropDown)
            multiComboDropDown.style.zIndex = "120"
        }
    }

    const initZindexOfMultiCombo = () => {
        const multiComboDropDown = document.getElementById("sensorNodeMultiComboBox")
        console.log("multiComboDropDown ??????????????????", multiComboDropDown)
        if (multiComboDropDown) {
            console.log("multiComboDropDown ??????????????????", multiComboDropDown)
            multiComboDropDown.style.zIndex = "100"
        }
    }

    return (
        <Container>
            <CircularIndeterminate open={loading} />
            <AlertPopup
                bg={false}
                open={alertPopup.open}
                onClose={() => setAlertPopup({ open: false, text: alertPopup.text })}
                content={alertPopup.text}
            />

            <SensorTargetValuePopup
                open={isOpenSensorTargetValuePopup}
                onClose={() => {
                    closeSensorTargetValuePopup()
                }}
                sensorNodeList={sensorNodeComboList}
                sensorKindComboList={sensorKindComboList}
                isPopup={isTargetValuePopup}
                targetValueSensorKind={targetValueSensorKind}
                setTargetValueSensorKind={setTargetValueSensorKind}
            />
            <FlexBox justifyContent={"space-between"}>
                <PageName name={dictCk("sensorDataInquiry")} state={false} setState={() => {}} />
            </FlexBox>
            <Tab
                style={{ marginTop: "23px" }}
                list={[
                    { seq: 0, name: dictCk("sensorDataGraph") },
                    { seq: 1, name: dictCk("comparisonGraphBySensor") },
                ]}
                tabIndex={tabIndex}
                onClick={(i) => {
                    setTabIndex(i.seq)
                }}
            />

            {tabIndex == 0 && (
                <Box>
                    <WhiteBox>
                        <SearchBox>
                            <div style={{ display: "flex" }}>
                                <Text>
                                    <RequiredMark />
                                    {dictCk("workCenterName")}
                                </Text>
                                <ComboBox
                                    style={{}}
                                    defaultValue={dictCk("select")}
                                    hasDefault={true}
                                    items={workCenterComboList.map((i) => ({ value: i.workCenterSeq, label: i.workCenterName }))}
                                    //items={ {value: 1, label: 1} }
                                    value={workCenterDropIndex}
                                    setValue={setWorkCenterDropIndex}
                                />

                                <Text>
                                    <RequiredMark />
                                    {dictCk("sensorNodeName")}
                                </Text>
                                <MultiComboBox
                                    setZindexOfMultiCombo={setZindexOfMultiCombo}
                                    initZindexOfMultiCombo={initZindexOfMultiCombo}
                                    multiComboBoxId={"sensorNodeMultiComboBox"}
                                    style={{ width: "300px" }}
                                    placeholder={""}
                                    items={sensorNodeComboList.map((i) => ({ value: i.sensorNodeSeq, label: i.sensorNodeName }))}
                                    //items={ {value: 1, label: 1} }
                                    value={sensorNodeDropIndex}
                                    setValue={setSensorNodeDropIndex}
                                />
                            </div>
                            <FlexBox>
                                {/* {chartLoading ? <YellowButton onClick={async () => {initChartDataList(), await onClickSearch()}}>{dictCk('search')}</YellowButton> : <Oval height={50} width={50}/>} */}
                                <YellowButton
                                    style={{ width: "flex", minWidth: "147px" }}
                                    onClick={async () => {
                                        initChartDataList(), setCurrentPage(1), await onClickSearch()
                                    }}
                                >
                                    {dictCk("inquiry")}
                                </YellowButton>
                                <SkyButton
                                    style={{ marginLeft: "14px", width: "flex", minWidth: "147px" }}
                                    onClick={async () => {
                                        await openSensorTargetValuePopup()
                                    }}
                                >
                                    {dictCk("target") + dictCk("value") + "" + dictCk("settings")}
                                </SkyButton>
                            </FlexBox>
                        </SearchBox>

                        <SearchBox style={{ marginTop: "15px" }}>
                            <div style={{ display: "flex" }}>
                                <Text>
                                    <RequiredMark />
                                    {dictCk("inquiry") + " " + dictCk("unit")}
                                </Text>
                                <ComboBox
                                    style={{}}
                                    defaultValue={dictCk("select")}
                                    hasDefault={true}
                                    items={unitInquiryComboList.map((i) => ({ value: i.unitInquirySeq, label: i.unitInquiryName }))} //1분 데이터 조회 잠시 막아둠
                                    // items={unitInquiryComboList.filter((i) => i.unitInquirySeq != "1m").map((i) => ({ value: i.unitInquirySeq, label: i.unitInquiryName }))}
                                    //items={ {value: 1, label: 1} }
                                    value={unitInquiryDropIndex}
                                    setValue={setUnitInquiryDropIndex}
                                    OnClick={console.log(unitInquiryDropIndex)}
                                />
                                <Text>
                                    <RequiredMark />
                                    {dictCk("sensorKind")}
                                </Text>
                                <ComboBox
                                    style={{}}
                                    defaultValue={dictCk("select")}
                                    hasDefault={true}
                                    items={sensorKindComboList.map((i) => ({
                                        value: i.sensorKind,
                                        label:
                                            i.sensorName == "온도"
                                                ? dictCk("temperature")
                                                : i.sensorName == "습도"
                                                ? dictCk("humidity")
                                                : i.sensorName == "CO2"
                                                ? dictCk("carbonDioxide")
                                                : i.sensorName == "PH"
                                                ? dictCk("PH")
                                                : i.sensorName == "EC"
                                                ? dictCk("EC")
                                                : i.sensorName == "풍향"
                                                ? dictCk("windDirection")
                                                : i.sensorName == "풍속"
                                                ? dictCk("weedSpeed")
                                                : i.sensorName == "일사"
                                                ? dictCk("solarRad")
                                                : i.sensorName == "감우"
                                                ? dictCk("rainyWeather")
                                                : i.sensorName == "수분부족량"
                                                ? dictCk("humidityDeficit")
                                                : "",
                                    }))}
                                    //items={ {value: 1, label: 1} }
                                    value={sensorKindDropIndex}
                                    setValue={setSensorKindDropIndex}
                                />
                                <Text>
                                    <RequiredMark />
                                    {dictCk("regDate")}
                                </Text>
                                <DateBox
                                    // setZindexOfDatePicker={setZindexOfDatePicker}
                                    // initZindexOfDatePicker={initZindexOfDatePicker}
                                    dateBoxId={"dateBoxFrom"}
                                    style={{
                                        width: "120px",
                                        height: "40px",
                                        marginRight: "5px",
                                        zIndex: "11",
                                    }}
                                    stdDate={stdDate}
                                    setStdDate={setStdDate}
                                    // minDate={minDate}
                                    // maxDate={maxDate}
                                />
                                <p style={{ width: "15px" }}>~</p>
                                <DateBox
                                    // setZindexOfDatePicker={setZindexOfDatePicker}
                                    // initZindexOfDatePicker={initZindexOfDatePicker}
                                    dateBoxId={"dateBoxTo"}
                                    style={{
                                        width: "120px",
                                        height: "40px",
                                        zIndex: "11",
                                    }}
                                    stdDate={endDate}
                                    setStdDate={setEndDate}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                />
                            </div>
                        </SearchBox>

                        <div style={{ width: "1200px", height: "800px", maxWidth: "1200px", marginTop: "30px", marginLeft: "30px", zIndex: "10" }}>
                            <MyChart
                                chartDataList={Promise.all(chartDataList)}
                                categoryList={categoryList}
                                seriesList={seriesList}
                                tooltipList={tooltipList}
                                sensorKind={sensorKind}
                                sensorMinMax={sensorMinMax}
                                targetValues={targetValues}
                                isChanged={myChartIsChanged}
                                setIsChanged={setMyChartIsChanged}
                            />

                            <div>
                                {/* 페이징 파트 */}
                                <Pagination
                                    current={currentPage} // 현재페이지
                                    setCurrent={setCurrentPage}
                                    total={totalData} // 총데이터 숫자
                                    count={dataCount} // 조회단위에 따른 카운팅갯수
                                    setLoading={setLoading} // 페이지 변경시 로딩
                                />
                            </div>
                        </div>

                        <div style={{ width: "1000px", height: "800px", maxWidth: "1200px", marginTop: "3%" }}>
                            {/* <GoogleChartSample/> */}
                            {/* <HighChartSample/> */}
                            {/* <ToastChartSample></ToastChartSample> */}
                            {/* <DygraphChartSample></DygraphChartSample> */}
                        </div>
                    </WhiteBox>
                </Box>
            )}

            {tabIndex == 1 && (
                <Box>
                    <WhiteBox>
                        <SearchBox>
                            <div style={{ display: "flex" }}>
                                <Text>
                                    <RequiredMark />
                                    {dictCk("workCenterName")}
                                </Text>
                                <ComboBox
                                    style={{}}
                                    defaultValue={dictCk("select")}
                                    hasDefault={true}
                                    items={workCenterComboList.map((i) => ({ value: i.workCenterSeq, label: i.workCenterName }))}
                                    value={compareWorkCenterDropIndex}
                                    setValue={setCompareWorkCenterDropIndex}
                                />

                                <Text>
                                    <RequiredMark />
                                    {dictCk("sensorNodeName")}
                                </Text>
                                <MultiComboBox
                                    setZindexOfMultiCombo={setZindexOfMultiCombo}
                                    initZindexOfMultiCombo={initZindexOfMultiCombo}
                                    multiComboBoxId={"sensorNodeMultiComboBox"}
                                    style={{}}
                                    placeholder={""}
                                    items={sensorNodeComboList.map((i) => ({ value: i.sensorNodeSeq, label: i.sensorNodeName }))}
                                    value={compareSensorNodeDropIndex}
                                    setValue={setCompareSensorNodeDropIndex}
                                />
                            </div>
                        </SearchBox>

                        <SearchBox style={{ marginTop: "15px" }}>
                            <div style={{ display: "flex" }}>
                                <Text>
                                    <RequiredMark />
                                    {dictCk("inquiry") + " " + dictCk("unit")}
                                </Text>
                                <ComboBox
                                    style={{}}
                                    defaultValue={dictCk("select")}
                                    hasDefault={true}
                                    items={unitInquiryComboList.map((i) => ({ value: i.unitInquirySeq, label: i.unitInquiryName }))} //1분 데이터 조회 잠시 막아둠
                                    // items={unitInquiryComboList.filter((i) => i.unitInquirySeq != "1m").map((i) => ({ value: i.unitInquirySeq, label: i.unitInquiryName }))}

                                    value={compareUnitInquiryDropIndex}
                                    setValue={setCompareUnitInquiryDropIndex}
                                />
                                <Text>
                                    <RequiredMark />
                                    {dictCk("regDate")}
                                </Text>
                                <DateBox
                                    // setZindexOfDatePicker={setZindexOfDatePicker}
                                    // initZindexOfDatePicker={initZindexOfDatePicker}
                                    dateBoxId="dateBoxFrom"
                                    style={{
                                        width: "120px",
                                        height: "40px",
                                        marginRight: "5px",
                                        zindex: "0",
                                        position: "relative",
                                    }}
                                    stdDate={stdDate}
                                    setStdDate={setStdDate}
                                    // minDate={minDate}
                                    // maxDate={maxDate}
                                />
                                <p style={{ width: "15px", zindex: "0", position: "relative" }}>~</p>
                                <DateBox
                                    // setZindexOfDatePicker={setZindexOfDatePicker}
                                    // initZindexOfDatePicker={initZindexOfDatePicker}
                                    dateBoxId="dateBoxTo"
                                    style={{
                                        width: "120px",
                                        height: "40px",
                                        zindex: "0",
                                        position: "relative",
                                    }}
                                    stdDate={endDate}
                                    setStdDate={setEndDate}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                />
                            </div>
                        </SearchBox>
                        <FlexBox>
                            <SkyButton
                                style={{
                                    marginTop: "15px",
                                    marginRight: "10px",
                                    width: "flex",
                                    minWidth: "147px",
                                    pointerEvents: compareChartLoading ? "auto" : "none",
                                }}
                                onClick={async () => {
                                    initCompareChartDataList(), setCurrentPage(1), await onClickButton([10150001, 10150002, 10150004])
                                }}
                            >
                                {dictCk("temperature") + "/" + dictCk("humidity") + "/" + dictCk("carbonDioxide")}
                            </SkyButton>
                            <SkyButton
                                style={{
                                    marginTop: "15px",
                                    marginRight: "10px",
                                    width: "flex",
                                    minWidth: "147px",
                                    pointerEvents: compareChartLoading ? "auto" : "none",
                                }}
                                onClick={async () => {
                                    initCompareChartDataList(), setCurrentPage(1), await onClickButton([10150005, 10150006])
                                }}
                            >
                                {dictCk("PH") + "/" + dictCk("EC")}
                            </SkyButton>
                            <div style={{ display: compareChartLoading ? "none" : "flex", marginTop: "15px" }}>
                                {" "}
                                <BallTriangle height={40} width={50} />{" "}
                            </div>
                        </FlexBox>
                        <div style={{ width: "1200px", height: "800px", maxWidth: "1200px", marginTop: "30px", marginLeft: "30px", zIndex: "10" }}>
                            <CompareChart
                                chartDataList={Promise.all(compareChartDataList)}
                                categoryList={compareCategoryList}
                                seriesList={compareSeriesList}
                                tooltipList={compareTooltipList}
                                sensorKind={compareSensorKind}
                                sensorMinMax={compareSensorMinMax}
                                yaxisList={compareYaxisList}
                            />

                            <div>
                                {/* 페이징 파트 */}
                                <Pagination
                                    current={currentPage} // 현재페이지
                                    setCurrent={setCurrentPage}
                                    total={totalData} // 총데이터 숫자
                                    count={dataCount} // 조회단위에 따른 카운팅갯수
                                    setLoading={setLoading} // 페이지 변경시 로딩
                                />
                            </div>
                        </div>
                    </WhiteBox>
                </Box>
            )}
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`
const FlexBox = styled.div`
    display: flex;
    justify-content: ${(props) => props.justifyContent || "flex-start"};
    align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
    overflow: hidden;
    display: flex;
    height: 100%;
`
// overflow-y: hidden;
const WhiteBox = styled.div`
    background-color: #fff;
    display: flex;
    overflow: auto;
    flex-direction: column;
    flex: 1;
    width: 0px;
    margin-top: 30px;
    padding: 28px 32px 32px;
    border: 1px solid #fff;
    border-radius: 9px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
`
const Text = styled.p`
    width: flex;
    min-width: 80px;
    color: #555555;
    font-size: 14px;
    margin-right: 5px;
`
const ClassesBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 17px;
    & > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
`

export default CommonContainer(ChartContainer(withTranslation()(SensorDataInquiryComp)))
