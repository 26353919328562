import React, { useState, useEffect } from "react"
import styled, { css, createGlobalStyle } from "styled-components"
// import InputMask from 'react-input-mask';
import NumberFormat from "react-number-format"

const defaultStyle = {
  position: "relative",
  width: "220px",
  height: "40px",
  marginRight: "30px",
  minWidth: "40px"
}

const InputBox = (props) => {
  const {
    style = {},
    placeholder,
    type = "text",
    autoComplete="new-password",
    id,
    name,
    value = "", //setValue,
    mask = "",
    disabled,
    onChange,
    useComma = true,
    maxLength = 0,
    useNegativeNum,
    className = "",

  } = props

  const [localValue, setLocalValue] = useState("")

  useEffect(() => {
    setLocalValue("")
  }, [value])

  const onChangeLocalValue = (e) => {
    const localValue = e.target.value
    // const value = localValue.replaceAll(" ", "").replaceAll("-", "").replaceAll("/", "").replaceAll(":", "").replaceAll(",", "")

    let value = localValue.replaceAll(" ", "").replaceAll("/", "").replaceAll(":", "").replaceAll(",", "")
    if (useNegativeNum && !(mask > "")) value
    else value = value.replaceAll("-", "")

    if (useNegativeNum && value.length == 1 && value == "-") value = ""

    setLocalValue(localValue)
    onChange({ ...e, target: { ...e.target, value } })
  }

  const onChangeValue = (e) => {
    onChange(e)
  }

  return (
    <>
      {useComma && type === "number" ? (
        <StyledInputBox
          style={{ ...defaultStyle, ...style }}
          placeholder={placeholder}
          id={id}
          name={className.length > 0 ? className : name}
          className={className == "" ? undefined : className}
          value={localValue == "" ? value : localValue}
          disabled={disabled}
          autoComplete={autoComplete}
          onChange={onChangeLocalValue}
          thousandSeparator={true}
          // isAllowed={({ floatValue }) => floatValue < 10 ** (maxLength === 0 ? 9 : maxLength) - 1}
          maxLength={(maxLength === 0 ? 9 : maxLength) + 2}
        />
      ) : useNegativeNum && type === "number" ? (
        <NumberFormat
          style={{ ...defaultStyle, ...style }}
          placeholder={placeholder}
          id={id}
          name={className.length > 0 ? className : name}
          className={className == "" ? undefined : className}
          value={localValue == "" ? value : localValue}
          disabled={disabled}
          autoComplete={autoComplete}
          onChange={onChangeLocalValue}
        />
      ) : mask > "" || type === "number" ? (
        <NumberFormat
          style={{ ...defaultStyle, ...style }}
          placeholder={placeholder}
          id={id}
          name={className.length > 0 ? className : name}
          className={className == "" ? undefined : className}
          value={localValue == "" ? value : localValue}
          disabled={disabled}
          autoComplete={autoComplete}
          onChange={onChangeLocalValue}
          format={mask === "" ? "#########" : mask} //"## : ##"
        />
      ) : (
        <input
          style={{ ...defaultStyle, ...style }}
          placeholder={placeholder}
          type={type}
          autoComplete={autoComplete}
          id={id}
          name={className.length > 0 ? className : name}
          className={className == "" ? undefined : className}
          value={value}
          disabled={disabled}
          onChange={onChangeValue}
          maxLength={maxLength === 0 ? 20 : maxLength}
        />
      )}
    </>
  )
}

// const StyledInputBox = styled(NumberFormat)`
//   text-align: right;
// `

const StyledInputBox = styled(NumberFormat)`
  text-align: right;
  :-ms-input-placeholder {
    text-align: left; /* for IE 10-11 */
  }
  ::-webkit-input-placeholder {
    text-align: left; /* for IE Edge */
  }
`

export default InputBox
