// 구매/지출 추가/수정 //내부 추가 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import PurchaseSpendRegisterContainer from "../../../containers/purchaseSpend/PurchaseSpendRegisterContainer"
import RequiredMark from "../../component/RequiredMark"
import ComboBox from "../../component/common/ComboBox"
import InputBox from "../../component/common/InputBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import AlertPopup from "../AlertPopup"

import CommonContainer from "../../../containers/CommonContainer"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "521px",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const PurchaseSpendItemAddPopup = (props) => {
  const classes = useStyles()
  const { t, _codeList, _purchaseCostDetail, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  const { open, onClose, index, details, setDetails, selectCostKind } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const [costClass, setCostClass] = useState(0)
  const [unitSeq, setUnitSeq] = useState(0)
  const [qty, setQty] = useState("")
  const [price, setPrice] = useState("")
  const [remark, setRemark] = useState("")

  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {
    getTranslationWords()
    if (_purchaseCostDetail.costDetailSeq) {
      setCostClass(_purchaseCostDetail.costClass)
      setUnitSeq(_purchaseCostDetail.unitSeq)
      setQty(_purchaseCostDetail.qty)
      setPrice(_purchaseCostDetail.price)
      setRemark(_purchaseCostDetail.remark)
    } else if (index > -1) {
      setCostClass(details[index].costClass)
      setUnitSeq(details[index].unitSeq)
      setQty(details[index].qty)
      setPrice(details[index].price)
      setRemark(details[index].remark)
    }

    return () => {
      setCostClass(0)
      setUnitSeq(0)
      setQty("")
      setPrice("")
      setRemark("")
    }
  }, [open])

  const onClickSave = (kind) => {
    if (costClass == 0) return setAlertPopup({ open: true, text:replaceWord(dict['n.Please select a product.'], dict['expenditureItems']) })
    else if (unitSeq == 0) return setAlertPopup({ open: true, text:replaceWord(dict['n.Please select a product.'], dict['unit']) })
    else if (qty == "" || qty == 0) return setAlertPopup({ open: true, text:replaceWord(dict['n.Invalid value. Please try again.'], dict['quantity']) })
    else if (price == "" || qty == 0) return setAlertPopup({ open: true, text:replaceWord(dict['n.Invalid value. Please try again.'], dict['price']) })

    let param = {
      costClass,
      costClassName: _codeList.filter((i) => i.majorSeq == kind)[0].minorList.find((i) => i.minorSeq == costClass).minorName,
      unitSeq,
      unitName: _codeList.filter((i) => i.majorSeq == 1003)[0].minorList.find((i) => i.minorSeq == unitSeq).minorName,
      qty: parseInt(qty, 10) || 0, // parseInt(price, 10) 에서 10은 10진수 진법을 정하는 값임
      price: parseInt(price, 10) || 0,
      amt: qty > 0 && price > 0 ? qty * price : 0,
      remark,
    }

    if (index > -1) {
      if (_purchaseCostDetail.costDetailSeq) {
        param.costDetailSeq = _purchaseCostDetail.costDetailSeq
      }
      const temp = [...details]
      temp.splice(index, 1, param)
      setDetails(temp)
    } else {
      setDetails([...details, param])
    }

    onClose()
  }

  //지출,구매 항목 구분코드선택
  const selectCostSeq = (majorKind) => {
    if (majorKind == 10140001) return { seq: 1017, kind: dict['purchase'] }
    else if (majorKind == 10140002) return { seq: 1013, kind: dict['expenditure'] }
    else return { seq: 0, kind: dict['unselected']}
  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={onClose}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <Container>
        <Title>{`${dict['purchaseSpendItems']}(${t(selectCostSeq(selectCostKind).kind)})`}</Title>
        <div style={{ flex: 1 }}>
          <FlexBox>
            <Label>
              <RequiredMark />
              {dict['spendItems']}
            </Label>
            <ComboBox
              style={{ marginRight: 0 }}
              defaultValue={dict['select']}
              hasDefault={true}
              items={
                _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == selectCostSeq(selectCostKind).seq).length > 0
                  ? _codeList
                      .filter((i) => i.majorSeq == selectCostSeq(selectCostKind).seq)[0]
                      .minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                  : []
              }
              value={costClass}
              setValue={setCostClass}
            />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark />
              {dict['unit']}
            </Label>
            <ComboBox
              style={{ marginRight: 0 }}
              defaultValue={dict['select']}
              hasDefault={true}
              items={
                _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1003).length > 0
                  ? _codeList.filter((i) => i.majorSeq == 1003)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                  : []
              }
              value={unitSeq}
              setValue={setUnitSeq}
            />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark />
              {dict['quantity']}
            </Label>
            <InputBox style={{ marginRight: 0, textAlign: "right" }} type="number" value={qty} onChange={(e) => setQty(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark />
              {dict['price']}
            </Label>
            <InputBox style={{ marginRight: 0, textAlign: "right" }} type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>{dict['amt']}</Label>
            <InputBox
              style={{ marginRight: 0, textAlign: "right" }}
              type="number"
              value={qty > 0 && price > 0 ? qty * price : ""}
              onChange={() => {}}
              disabled
            />
          </FlexBox>
          <FlexBox alignItems="flex-start">
            <Label>{dict['remark']}</Label>
            <TextAreaBox width={300} value={remark} setValue={setRemark}></TextAreaBox>
          </FlexBox>
        </div>
        <ButtonBox>
          <Button bgColor={"#fff"} hover={"rgba(174, 174, 174, 0.22)"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={onClose}>
            {dict['cancel']}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={() => onClickSave(selectCostSeq(selectCostKind).seq)}>
            {dict['tempSave']}
          </Button>
        </ButtonBox>
      </Container>
    </Dialog>
  )
}

const Container = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 23px 59px 40px 40px;
`
const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 34px;
  }
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  flex: 1;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

PurchaseSpendItemAddPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default PurchaseSpendRegisterContainer(CommonContainer(withTranslation()(PurchaseSpendItemAddPopup)))
