// 구글 주소검색 api (Search Box) - 차건담 2023.08.22
import React, { useRef, useEffect } from "react"
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api"
import CommonContainer from "../../../containers/CommonContainer"

const PlaceComponent = (props) => {
    const { mainAddr, setMainAddr, xy, setXY, getTranslationWords, _translationWords, _userInfo } = props
    const inputRef = useRef()

    const handlePlaceChanged = () => {
        const [place] = inputRef.current.getPlaces()
        if (place) {
            setMainAddr(place.formatted_address)
            const lng = place.geometry.location.lng()
            const lat = place.geometry.location.lat()
            setXY({ x: lng, y: lat })
            // console.log(place.formatted_address)
            // console.log(place.geometry.location.lat()) // py
            // console.log(place.geometry.location.lng()) // px
        }
    }

    useEffect(() => {
        getTranslationWords()
    }, [])

    let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.04.04

    const dictCk = (word) => {
        if (dict == undefined || dict == null || dict == {} || dict == "") {
            return word
        } else {
            const result = dict[word] != undefined && dict[word] != null && dict[word] != "" ? dict[word] : word
            return result
        }
    }

    const languageCode = (_userInfo) => {
        let languageCode = _userInfo.languageSeq
        if (languageCode == 10010001) {
            return "en"
        } else if (languageCode == 10010003) {
            return "vi"
        } else if (languageCode == 10010004) {
            return "zh"
        } else if (languageCode == 10010005) {
            return "tw"
        } else if (languageCode == 10010006) {
            return "ja"
        } else {
            return "ko"
        }
    }

    return (
        <LoadScript googleMapsApiKey={"AIzaSyAfKY9if3fDt2XSEuMZX3JTm7uBuHX_iMs"} libraries={["places"]} language={languageCode(_userInfo)}>
            <StandaloneSearchBox onLoad={(ref) => (inputRef.current = ref)} onPlacesChanged={handlePlaceChanged}>
                <input
                    type="text"
                    className="form-control"
                    placeholder={dictCk("InputAddressMessage")}
                    style={{
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `400px`,
                        height: `40px`,
                        marginTop: `0px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                    }}
                />
            </StandaloneSearchBox>
        </LoadScript>
    )
}

export default CommonContainer(PlaceComponent)
