// 표준작기정보 병해충정보 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import StandardCropInfoContainer from "../../../containers/cropManage/StandardCropInfoContainer"
import RequiredMark from "../../component/RequiredMark"
import ComboBox from "../../component/common/ComboBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import AlertPopup from "../AlertPopup"

import CommonContainer from "../../../containers/CommonContainer"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "600px",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const StdCropPestAddPopup = (props) => {
  const classes = useStyles()
  const { t, _codeList, _stdCropPest, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  const { open, onClose } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const [diseaseKind, setDiseaseKind] = useState(0)
  const [diseaseClass, setDiseaseClass] = useState(0)
  const [content, setContent] = useState("")
  const [remark, setRemark] = useState("")
  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {
    getTranslationWords()
    if (Object.keys(_stdCropPest).length != 0) {
      setDiseaseKind(_stdCropPest.diseaseKind)
      setDiseaseClass(_stdCropPest.diseaseClass)
      setContent(_stdCropPest.content)
      setRemark(_stdCropPest.remark)
    }

    return () => {
      setDiseaseKind(0)
      setDiseaseClass(0)
      setContent("")
      setRemark("")
    }
  }, [open])

  const onClickSave = () => {
    if (diseaseClass == 0) return setAlertPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['diseaseKind']) })
    else if (diseaseKind == 0) return setAlertPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['diseaseClass']) })
    else if (content == 0) return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict['content']) })

    const param = {
      diseaseClass: diseaseClass || 0,
      diseaseKind: diseaseKind || 0,
      content,
      remark,
    }
    if (Object.keys(_stdCropPest).length != 0) {
      param.stdCropPestSeq = _stdCropPest.stdCropPestSeq
    }
    onClose(dict['save'], param)
  }

  const onClickCancel = () => {
    onClose(dict['cancel'], null)
  }


  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose(null)}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <Container>
        <Title>{dict['cropPest']}</Title>
        <InputBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['diseaseKind']}
            </Label>
            <ComboBox
              style={{ width: "400px" }}
              defaultValue={dict['select']}
              hasDefault={true}
              items={
                _codeList.length > 0 &&
                _codeList.filter((i) => i.majorKind == 1 && i.majorSeq == 1011).length > 0 &&
                _codeList.filter((i) => i.majorKind == 1 && i.majorSeq == 1011)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
              }
              value={diseaseKind}
              setValue={setDiseaseKind}
            />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['diseaseClass']}
            </Label>
            <ComboBox
              style={{ width: "400px" }}
              defaultValue={dict['select']}
              hasDefault={true}
              items={
                _codeList.length > 0 &&
                _codeList.filter((i) => i.majorKind == 2 && i.majorSeq == 1010).length > 0 &&
                _codeList.filter((i) => i.majorKind == 2 && i.majorSeq == 1010)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
              }
              value={diseaseClass}
              setValue={setDiseaseClass}
            />
          </FlexBox>
          <FlexBox alignItems="flex-start">
            <Label><RequiredMark /> {dict['content']}</Label>
            <TextAreaBox width={400} height={150} value={content} setValue={setContent}></TextAreaBox>
          </FlexBox>
          <FlexBox alignItems="flex-start">
            <Label>{dict['remark']}</Label>
            <TextAreaBox width={400} height={150} value={remark} setValue={setRemark}></TextAreaBox>
          </FlexBox>
        </InputBox>
        <ButtonBox>
          <Button bgColor={"#fff"} hover={"rgba(174, 174, 174, 0.22)"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={onClickCancel}>
            {dict['cancel']}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {dict['save']}
          </Button>
        </ButtonBox>
      </Container>
    </Dialog>
  )
}

const Container = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 23px 26px 40px;
`
const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const InputBox = styled.div`
  flex: 1;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 34px;
  }
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  flex: 1;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

StdCropPestAddPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default StandardCropInfoContainer(CommonContainer(withTranslation()(StdCropPestAddPopup)))
