// 현황정보 > 센서데이터조회(표)
import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { downExcel, dateFormat, downExcelWeb } from "../../../utils/util"

import CommonContainer from "../../../containers/CommonContainer"
import InfoContainer from "../../../containers/InfoContainer"

import PageName from "../../component/common/PageName"
import { TableComponent } from "../../component/common/TableNew"

import RequiredMark from "../../component/RequiredMark"
import DateBox from "../../component/common/DateBox"
import ComboBox from "../../component/common/ComboBox"
import MultiComboBox from "../../component/common/MultiComboBox"
import { YellowButton, GrayButton } from "../../component/common/Button"

import { Oval } from "react-loader-spinner"
import CircularIndeterminate from "../../component/common/CircularIndeterminate" //로딩화면
import Pagination from "../../component/Pagination" //페이징
import { setSensorDataListExcel } from "../../../actions/StatusInfoAction"

const SensorDataListInquiry = (props) => {
    const {
        t,
        initDataList,
        getDataList,
        saveDataList,
        setDataList,
        getComboList,
        initTranslationWords,
        getTranslationWords,
        _translationWords,
        replaceWord,
        initSensorDataListExcel,
        getSensorDataListExcel,
        sensorDataListExcel,
    } = props

    const { workCenterComboList, sensorNodeComboList, sensorComboList } = props

    const { sensorDataGridList: dataList, actionSensorDataGridList: actionType, actionSensorDataGridExcelDownload: actionTypeExcelDownload } = props

    const temp1 = dateFormat(new Date(), "YYYYMMDD")
    const temp2 = dateFormat(new Date(), "YYYYMMDD")

    const [dateFr, setDateFr] = useState(temp1)
    const [dateTo, setDateTo] = useState(temp2)

    const [minDate, setMinDate] = useState(new Date()) //날짜 최저일
    const [maxDate, setMaxDate] = useState(new Date()) //날짜 최대일
    // const [ sensorSeq, setSensorSeq ] = useState(0)

    const [workCenterSeq, setWorkCenterSeq] = useState(0)
    const [sensorNodeSeq, setSensorNodeSeq] = useState(0)
    const [sensorOptions, setSensorOptions] = useState([])

    // const [asyncDataListFinal, setAsyncDataListFinal] = useState([])

    const [loading, setLoading] = useState(false) // 로딩중

    const [currentPage, setCurrentPage] = useState(1) // 현재페이지
    const [totalData, setTotalData] = useState(0) // 총데이터 숫자
    const [dataCount, setDataCount] = useState(144) // 조회단위에 따른 카운팅갯수 (10분 하루 단위)

    const [dataListSplit, setDataListSplit] = useState([]) // dataList 다시 담을 변수

    const dict = _translationWords[0] // 변수에 사전 담기

    useEffect(() => {
        getTranslationWords()
        getData()

        return async () => {
            await initDataList(actionType)
            await initSensorDataListExcel()
        }
    }, [])

    /****************************************************************************************************
     * 2023.09.12 by 김한중
     * 조회 날짜의 제한을 dateFr의 한달 후로 수정
     * 날짜 변경시 제한밖이면 dateTo 자동 수정
     ****************************************************************************************************/
    useEffect(() => {
        const differenceInDays = calculateDateDifference(dateFr, dateTo) //두 날짜 차이값
        const plusMinus = "+"
        if (differenceInDays >= 31 || parseInt(dateFr) > parseInt(dateTo)) {
            const dateChange = dateConversion(dateFr, 1, plusMinus) // 바꿀날짜
            // 날짜 차이가 조건값 넘을시 시작일 종료일 리셋
            setDateFr(dateFr) //시작일
            setDateTo(dateChange) //종료일 동일하게 변경
        }

        const originalDate = new Date(dateFr.substr(0, 4) + "-" + dateFr.substr(4, 2) + "-" + dateFr.substr(6, 2))
        const modifiedDate = new Date(originalDate)
        modifiedDate.setMonth(modifiedDate.getMonth() + 1) // 한 달을 더함
        modifiedDate.setDate(modifiedDate.getDate() - 1) // 하루를 뺌

        setMinDate(originalDate)
        setMaxDate(modifiedDate)
    }, [dateFr, dateTo])

    // 페이징 관련 코드 === 23.0905 수정 김한중
    useEffect(() => {
        let dataDistinction = dataList // 데이터 존재여부 판별 변수

        if (dataDistinction.length == 0) {
            // console.log('dataList== []@@@@@@@@ : ', dataList)
        } else {
            /****************************************************************************************************
             * 2023.09.11 by 윤여광
             * 조회시 backend에서 서버 cpu 할당 작업이 많아 병렬처리 되지 않는 문제 수정(해당 조회시 모든 조회가 멈춰버리는 문제 수정)
             * 데이터 가공 작업을 frontend에서 하도록 수정
             ****************************************************************************************************/
            const dataListFinal = generateDataList(dataList.labelsData, dataList.sensorListData, dataList.dataListData) // bodyList에 맞는 데이터 가공

            // 23.0905 - 데이터 페이징 추가 START - 김한중 ==========================================================
            const listTotal = dataListFinal.length //조회한 데이터의 총 갯수
            setTotalData(listTotal) // 데이터 총 갯수 set

            const startIdx = (currentPage - 1) * dataCount // 시작 인덱스 계산
            const endIdx = currentPage * dataCount // 끝 인덱스 계산

            let headListS = dataList.headList //헤드데이터 추출
            let bodyListS = dataListFinal.slice(startIdx, endIdx) //바디 데이터 갯수 제한

            const dataListSplit = { headList: headListS, bodyList: bodyListS } //dataList 갯수 제한둬서 다시 담음
            // console.log('dataList== @@@@@@@@ : ', dataList)
            // console.log('dataList@@@@@@@dataListSplit : ', dataListSplit)

            setDataListSplit(dataListSplit) // 보여줄것만 다시 set1
            setLoading(false) // 조회끝난후 다시 조회버튼으로 바꿔줌 로딩종료
        }
    }, [dataList, currentPage])

    /****************************************************************************************************
     * 2023.09.11 by 윤여광
     * Excel 파일 생성을 fronend에서 하도록 추가
     ****************************************************************************************************/
    useEffect(() => {
        if (sensorDataListExcel && sensorDataListExcel.length != 0) {
            const dataListFinal = generateDataList(
                sensorDataListExcel.labelsData,
                sensorDataListExcel.sensorListData,
                sensorDataListExcel.dataListData
            )
            downExcelWeb({ result: dataListFinal, fileName: dict["sensorData"] })
        }
        setSensorDataListExcel([])
    }, [sensorDataListExcel])

    /****************************************************************************************************
     * 2023.09.11 by 윤여광
     * 조회시 데이터 가공
     * 수정 : 객체를 map으로 담을시 숫자가 최우선으로 배열되는 문제 수정 2023.09.12 by 김한중
     ****************************************************************************************************/
    const generateDataList = (labels, sensorList, dataList) => {
        const dataListFinal = labels.map((row1) => {
            let temp = { 일시: row1 }
            sensorList.forEach((row2) => {
                // 객체를 map으로 담을시 숫자가 최우선으로 배열되어서 기존 들어가느는 순서를 지키기위한 IF조건
                let typeDistinction = parseInt(row2.sensorName) //센서명 숫자타입으로 변환되는거 있는지 확인을 위한 변수
                if (isNaN(typeDistinction)) {
                    // 숫자로 변환 안될시 기존 센서명사용
                    typeDistinction = row2.sensorName
                } else {
                    // 숫자로 변환될시 끝에 .붙여 문자열로 변환
                    typeDistinction = `"` + typeDistinction + `"`
                }
                const data = dataList.filter((row3) => row3.stdDateTime === row1 && row3.sensorSeq === row2.sensorSeq)
                temp[typeDistinction] =
                    data.length > 0 ? (row2.sensorKind === 10150011 || row2.sensorKind === 10150013 ? data[0].rainfall : data[0].sensorData) : ""
            })
            return temp
        })
        return dataListFinal
    }

    const getData = async () => {
        await getComboList({ queryKind: "WorkCenter" })
        await getComboList({ queryKind: "SensorNode" })
        await getComboList({ queryKind: "Sensor" })
    }

    const onClickSearch = async () => {
        // 조회

        setDataListSplit([]) //데이터 담은 변수 리셋
        setCurrentPage(1) //페이지 리셋
        setLoading(true) // 조회시 스피너로 바꿔줌 로딩시작

        const data = {
            dateFr,
            dateTo,
            workCenterSeq,
            sensorNodeSeq,
            sensorSeqs: sensorOptions.map((row) => row.value),
        }

        await getDataList(actionType, data)

        setLoading(false) // 조회끝난후 다시 조회버튼으로 바꿔줌 로딩종료
    }

    /****************************************************************************************************
     * 2023.09.11 by 윤여광
     * 조회시 backend에서 서버 cpu 할당 작업이 많아 병렬처리 되지 않는 문제 수정(해당 조회시 모든 조회가 멈춰버리는 문제 수정)
     * 데이터 가공 작업을 frontend에서 하도록 수정
     ****************************************************************************************************/
    const onClickExcelDownload = async () => {
        // 엑셀 다운로드

        const data = {
            dateFr,
            dateTo,
            workCenterSeq,
            sensorNodeSeq,
            sensorSeqs: sensorOptions.map((row) => row.value),
        }

        setLoading(true) // 조회시 스피너로 바꿔줌 로딩시작
        // const result = await getDataList(actionTypeExcelDownload, data, { responseType: "blob" })
        // await getDataList(data)
        await getSensorDataListExcel(data)

        // downExcel({ result: result, fileName: dict["sensorData"] })

        setLoading(false) // 조회끝난후 다시 조회버튼으로 바꿔줌 로딩종료
    }

    const onSelectWorkCenter = async (value) => {
        setWorkCenterSeq(value)

        setSensorNodeSeq(0)
        setSensorOptions([])

        await getComboList({ queryKind: "SensorNode", workCenterSeq: value })
        await getComboList({ queryKind: "Sensor", workCenterSeq: value })
    }

    const onSelectSensorNode = async (value) => {
        setSensorNodeSeq(value)

        setSensorOptions([])
    }

    // 조회 단위에 따른 조회 기간 제한을 주기 위해 두 날짜 사이의 일 수 계산하는 함수 - 차건담 2023.08.08
    function calculateDateDifference(startDate, endDate) {
        startDate = startDate.substr(0, 4) + "-" + startDate.substr(4, 2) + "-" + startDate.substr(6, 2)
        endDate = endDate.substr(0, 4) + "-" + endDate.substr(4, 2) + "-" + endDate.substr(6, 2)
        const oneDay = 24 * 60 * 60 * 1000 // 1일을 밀리초로 표현
        // console.log("startDate/ endDate:", startDate, " / ", endDate)
        // 날짜를 밀리초로 변환하여 차이 계산
        const startTime = new Date(startDate).getTime()
        const endTime = new Date(endDate).getTime()
        // console.log("startTime:", startTime)
        // console.log("endTime:", endTime)
        // 날짜 차이를 일 단위로 계산
        const differenceInDays = Math.round(Math.abs((endTime - startTime) / oneDay))

        return differenceInDays
    }

    // 날짜를 더해주는 함수(dateChange=변할날짜, changNum=변할날짜수, plusMinus=더할지뺼지)(한달단위)
    function dateConversion(dateChange, changNum, plusMinus) {
        // 입력받은 stdDate를 "YYYY-MM-DD" 형식의 날짜로 변환합니다.
        const originalDateS = new Date(dateChange.substr(0, 4) + "-" + dateChange.substr(4, 2) + "-" + dateChange.substr(6, 2))
        const modifiedDate = new Date(originalDateS)
        // 날짜를 3일 뒤로 변경합니다.
        if (plusMinus == "+") {
            modifiedDate.setMonth(modifiedDate.getMonth() + changNum) // 한 달을 더함
            modifiedDate.setDate(modifiedDate.getDate() - 1) // 하루를 뺌
        } else if (plusMinus == "-") {
            modifiedDate.setMonth(modifiedDate.getMonth() - changNum) // 한 달을 뺌함
            modifiedDate.setDate(modifiedDate.getDate() + 1) // 하루를 더함
        }

        // 변경된 날짜의 연도를 가져옵니다.
        const year = modifiedDate.getFullYear()
        // 변경된 날짜의 월을 가져오고, 문자열로 변환하며 한 자리 숫자인 경우 앞에 0을 추가합니다.
        const month = String(modifiedDate.getMonth() + 1).padStart(2, "0")
        // 변경된 날짜의 일을 가져오고, 문자열로 변환하며 한 자리 숫자인 경우 앞에 0을 추가합니다.
        const day = String(modifiedDate.getDate()).padStart(2, "0")
        // 변경된 날짜를 "YYYYMMDD" 형식으로 만듭니다.
        const modifiedDateS = `${year}${month}${day}`
        // 결과값으로 변경된 날짜를 반환합니다.
        return modifiedDateS
    }
    // 예시: dateConversion("20230305") 호출 시 "20230308" 반환

    return (
        <Container>
            <CircularIndeterminate open={loading} />
            <FlexBox justifyContent={"space-between"}>
                <PageName name={dict["sensorDataInquiry"]} state={false} setState={() => {}} />
                <GrayButton onClick={onClickExcelDownload}>{dict["downloadExcelFile"]}</GrayButton>
            </FlexBox>

            <Box>
                <WhiteBox>
                    <SearchBox>
                        <FlexBox>
                            <Text>{dict["workCenterName"]}</Text>
                            <ComboBox
                                style={{ width: "260px", marginRight: "28px" }}
                                defaultValue={dict["select"]}
                                hasDefault={true}
                                items={workCenterComboList.map((row) => ({ value: row.workCenterSeq, label: row.workCenterName }))}
                                value={workCenterSeq}
                                setValue={onSelectWorkCenter}
                            />

                            <Text>{dict["sensorNodeName"]}</Text>
                            <ComboBox
                                style={{ width: "260px", marginRight: "28px" }}
                                defaultValue={dict["select"]}
                                hasDefault={true}
                                items={sensorNodeComboList.map((row) => ({ value: row.sensorNodeSeq, label: row.sensorNodeName }))}
                                value={sensorNodeSeq}
                                setValue={onSelectSensorNode}
                            />
                        </FlexBox>

                        {/* {loading ? <YellowButton onClick={onClickSearch}>{dict['search']}</YellowButton> : <Oval height={50} width={50}/>  } */}
                        <YellowButton onClick={onClickSearch}>{dict["search"]}</YellowButton>
                        {/* <YellowButton >{'긴급점검중 사용불가'}</YellowButton> */}
                    </SearchBox>
                    <ClassesBox>
                        <FlexBox>
                            <Text>{dict["inquiryPeriod"]}</Text>
                            <DateBox
                                style={{
                                    width: "120px",
                                    height: "40px",
                                    marginRight: "5px",
                                }}
                                stdDate={dateFr}
                                setStdDate={setDateFr}
                            />
                            <p style={{ width: "15px" }}>~</p>
                            <DateBox
                                style={{
                                    width: "120px",
                                    height: "40px",
                                    marginRight: "30px",
                                }}
                                stdDate={dateTo}
                                setStdDate={setDateTo}
                                minDate={minDate}
                                maxDate={maxDate}
                            />

                            <Text>
                                {/* <RequiredMark />  */}
                                {dict["sensorName"]}
                            </Text>
                            <MultiComboBox
                                style={{ width: "548px", zIndex: 100 }}
                                placeholder={dict["multiSelect"]}
                                items={sensorComboList
                                    .filter((row) => sensorNodeSeq == 0 || row.sensorNodeSeq == sensorNodeSeq)
                                    .map((row) => ({ value: row.sensorSeq, label: row.sensorNodeName + " - " + row.sensorName }))}
                                value={sensorOptions}
                                setValue={setSensorOptions}
                            />
                        </FlexBox>
                    </ClassesBox>

                    {/* 원본 */}
                    {/* <TableComponent
            headList={dataList.headList && 
              [{ title : dict['days'], type : 'text', width : '110px' }, 
              ...dataList.headList.map(row=>({ title : row.sensorName, type : '', width : '10%' })),
              { title : '', type : '', }, 
            ]}

            bodyList={dataList.bodyList && dataList.bodyList.map((i) => (i))}
            dict={dict}
          /> */}

                    {/* 페이징 */}
                    <TableComponent
                        headList={
                            dataList.headList && [
                                { title: dict["days"], type: "text", width: "110px" },
                                ...dataList.headList.map((row) => ({ title: row.sensorName, type: "", width: "10%" })),
                                { title: "", type: "" },
                            ]
                        }
                        // 하단 행 데이터수만 페이징 상단은 그대로
                        bodyList={dataListSplit.bodyList && dataListSplit.bodyList.map((i) => i)}
                        dict={dict}
                    />

                    {/* 조회하기전에는 페이징 버튼 안보이는 조건 */}
                    <div style={{ display: dataListSplit.length != 0 ? "flex" : "none", marginTop: "28px" }}>
                        <Pagination
                            current={currentPage} // 현재페이지
                            setCurrent={setCurrentPage}
                            total={totalData} // 총데이터 숫자
                            count={dataCount} // 조회단위에 따른 카운팅갯수
                            setLoading={setLoading} // 페이지 변경시 로딩
                        />
                    </div>
                </WhiteBox>
            </Box>
        </Container>
    )
}

export default InfoContainer(CommonContainer(withTranslation()(SensorDataListInquiry)))

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`
const FlexBox = styled.div`
    display: flex;
    justify-content: ${(props) => props.justifyContent || "flex-start"};
    align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
    overflow: hidden;
    display: flex;
    height: 100%;
`
const WhiteBox = styled.div`
    background-color: #fff;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 0px;
    margin-top: 19px;
    padding: 28px 32px 20px;
    border: 1px solid #fff;
    border-radius: 9px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
const Text = styled.p`
    width: 80px;
    color: #555555;
    font-size: 14px;
`

const ClassesBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 17px;
    & > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
`
