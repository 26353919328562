import React, { useState, useEffect } from "react"
import styled, { css, createGlobalStyle } from "styled-components"

// import InputBox from "./InputBox"
// import InputMask from 'react-input-mask';
import NumberFormat from "react-number-format"

const InputYMBox = (props) => {
  const {
    // placeholder,
    // type,
    id,
    name,
    value = "",
    setValue,
    // mask, maskChar=" ",
    disabled,
    style,
  } = props

  const [time, setTime] = useState("")

  useEffect(() => {
    setTime("")
  }, [value])

  const onChangeLocalValue = (e) => {
    const value = e.target.value

    setTime(value)
    setValue(value.replaceAll(" - ", "").replaceAll(" ", ""))
  }

  const limit = (val, max) => {
    if (val.length === 1 && val[0] > max[0]) {
      val = "0" + val
    }

    if (val.length === 2) {
      if (Number(val) === 0) val = "01"
      else if (val > max) val = max
    }

    return val
  }

  const dateLimit = (value) => {
    let month = limit(value.substring(0, 2), "12")

    let day = value.substring(2, 4)

    return (
      month + (day.length ? " - " + limit(day, month === "02" ? "29" : ["01", "03", "05", "07", "08", "10", "12"].includes(month) ? "31" : "30") : "")
    )
  }

  return (
    <>
      <NumberFormat
        placeholder="00 - 00"
        id={id}
        name={name}
        value={time == "" ? value : time}
        disabled={disabled}
        onChange={onChangeLocalValue}
        style={{ position: "relative", width: "65px", height: "40px", marginRight: "30px", ...style }}
        format={dateLimit} //"## : ##"
      />
    </>
  )
}

export default InputYMBox
