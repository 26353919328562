// 자동환경설정 예외 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import { AutoEnvKindList } from "../../../utils/data"
import AutoEnvSettingOnOffContainer from "../../../containers/envControl/AutoEnvSettingOnOffContainer"
import RequiredMark from "../../component/RequiredMark"
import InputBox from "../../component/common/InputBox"
import ComboBox from "../../component/common/ComboBox"
import TableCheckBox from "../../component/common/TableCheckBox"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "388px",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const AutoEnvOnOffExAddPopup = (props) => {
  const classes = useStyles()
  const { t, _codeList, _comboEquipList, getComboList, _autoEnvConfEquipOnOffEx } = props
  const { onClose, open } = props
  const [equipKind, setEquipKind] = useState(0)
  const [status, setStatus] = useState(0)
  const [kind, setKind] = useState(0)
  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")
  const [isAlarm, setIsAlarm] = useState(false)

  useEffect(() => {
    if (Object.keys(_autoEnvConfEquipOnOffEx).length == 0) return
    setEquipKind(_autoEnvConfEquipOnOffEx.equipSeqEx)
    setStatus(_autoEnvConfEquipOnOffEx.workStatus)
    setKind(_autoEnvConfEquipOnOffEx.valueKind)
    setFrom(_autoEnvConfEquipOnOffEx.tgValueFr || 0)
    setTo(_autoEnvConfEquipOnOffEx.tgValueTo || 0)
    setIsAlarm(_autoEnvConfEquipOnOffEx.isAlarm == 1)

    return () => {
      setEquipKind(0)
      setStatus(0)
      setKind(0)
      setFrom("")
      setTo("")
      setIsAlarm(false)
    }
  }, [open])

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    await getComboList({ queryKind: "Equip" })
  }

  const onClickSave = () => {
    if (equipKind === 0 || status == 0 || kind == 0) return
    else if (kind != 3 && from == "") return
    else if (kind != 2 && to == "") return

    let param = {
      equipKind,
      status,
      kind,
      from: kind == 3 ? 0 : from || 0,
      to: kind == 2 ? 0 : to || 0,
      isAlarm: isAlarm ? 1 : 0,
    }
    if (_autoEnvConfEquipOnOffEx.envConfEquipOnOffExSeq) {
      param.envConfEquipOnOffExSeq = _autoEnvConfEquipOnOffEx.envConfEquipOnOffExSeq
    }

    onClose(t("save"), param)
  }

  const onClickCancel = () => {
    onClose(t("cancel"), null)
  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose(null)}
      open={open}
      fullWidth={true}
    >
      <Container>
        <Title>{t("autoEnvSetting")}</Title>
        <div style={{ flex: 1 }}>
          <FlexBox>
            <Label>
              <RequiredMark /> {t("equipKind")}
            </Label>
            <ComboBox
              style={{ width: "160px" }}
              defaultValue={t("select")}
              hasDefault={true}
              items={_comboEquipList.map((i) => ({ value: i.equipSeq, label: i.equipName }))}
              value={equipKind}
              setValue={setEquipKind}
            />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {t("status")}
            </Label>
            <ComboBox
              style={{ width: "160px" }}
              defaultValue={t("select")}
              hasDefault={true}
              items={((_codeList || []).filter((i) => i.majorSeq == 1019)[0] || { minorList: [] }).minorList.map((i) => ({
                value: i.minorSeq,
                label: i.minorName,
              }))}
              value={status}
              setValue={setStatus}
            />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {t("kind")}
            </Label>
            <ComboBox
              style={{ width: "160px" }}
              defaultValue={t("select")}
              hasDefault={true}
              items={AutoEnvKindList}
              value={kind}
              setValue={setKind}
            />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {t("from")}
            </Label>
            <InputBox
              style={{ width: "160px", marginRight: 0 }}
              type="comma"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              disabled={kind == 3}
            />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {t("to")}
            </Label>
            <InputBox
              style={{ width: "160px", marginRight: 0 }}
              type="comma"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              disabled={kind == 2}
            />
          </FlexBox>
          <FlexBox>
            <Label>{t("isAlarmSetting")}</Label>
            <TableCheckBox check={isAlarm} setCheck={() => setIsAlarm((prev) => !prev)} />
          </FlexBox>
        </div>
        <ButtonBox>
          <Button bgColor={"#fff"} hover={"rgba(174, 174, 174, 0.22)"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={onClickCancel}>
            {t("cancel")}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {t("save")}
          </Button>
        </ButtonBox>
      </Container>
    </Dialog>
  )
}

const Container = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 23px 51px 44px 40px;
`
const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 40px;
  }
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  flex: 1;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

AutoEnvOnOffExAddPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default AutoEnvSettingOnOffContainer(withTranslation()(AutoEnvOnOffExAddPopup))
