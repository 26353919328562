import React from "react"
import styled from "styled-components"

const Tab = ({ style = {}, list, tabIndex, onClick }) => {
  return (
    <TabContainer style={{ ...style }}>
      {list.map((i, idx) => (
        <TabItem
          key={idx}
          bgColor={tabIndex == i.seq ? "#474747" : "#cfcfcf4d"}
          color={tabIndex == i.seq ? "#fff" : "#55555566"}
          onClick={() => onClick(i)}
        >
          {i.name}
        </TabItem>
      ))}
    </TabContainer>
  )
}

const TabContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 28px;
`
const TabItem = styled.button`
  background-color: ${(props) => props.bgColor || "#fff"};
  min-width: 86px;
  height: 37px;
  margin-right: 3px;
  padding: 0 19.5px;
  border-top-left-radius: 18.5px;
  color: ${(props) => props.color || "#fff"};
  font-size: 14px;
`

export default Tab
