//  포장바코드 발행<품목 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"

import { dateFormat } from "../../../utils/util"

import AlertPopup from "../AlertPopup"
import CommonContainer from "../../../containers/CommonContainer"
import SalesContainer from "../../../containers/SalesContainer"
import { withTranslation } from "react-i18next"

// import TableComponent from "../component/common/TableComponent"
// import { SkyButton, YellowButton, GrayButton } from "../component/common/Button"
import RequiredMark from "../../component/RequiredMark"
import DateBox from "../../component/common/DateBox"
import InputBox from "../../component/common/InputBox"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
      maxWidth: "521px",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const OutBarcodeInfoPopup = (props) => {
  const classes = useStyles()

  const { t, initDataList, getDataList, saveDataList, setDataList, outBarcodeSalesList: dataList, ADD_OUT_BARCODE_INFO, salesOutSeq, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props

  const { onClose, open } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })

  const [dataInfo, setDataInfo] = useState({})

  const temp1 = dateFormat(new Date(), "YYYYMMDD")

  const dict = _translationWords[0] // 변수에 사전 담기

  const setDate = (date) => {
    setDataInfo({ ...dataInfo, salesOutDate: date })
  }
  const setQty = (qty) => {
    setDataInfo({ ...dataInfo, qty: qty })
  }

  useEffect(() => {
    getTranslationWords()
    if (open) getData()

    return () => setDataInfo({})
  }, [open])

  const getData = async () => {
    let data = dataList.filter((row) => row.salesOutSeq == salesOutSeq)
    // let subData = dataSubList.filter( row => row.salesOutSeq == salesOutSeq );

    data =
      data.length > 0
        ? {
            salesOutNoDis: data[0].salesOutNo,
            salesOutQtyDis: data[0].salesOutQty,
            barcodeQtyDis: data[0].barcodeQty,
            restQtyDis: data[0].restQty,
            unitNameDis: data[0].unitName,

            salesOutSeq,
            qty: 0,
          }
        : {}

    setDataInfo(data)
  }

  const onClickSave = async () => {
    if (dataInfo.qty === undefined || dataInfo.qty == 0)
      return setAlertPopup({ open: true, text:replaceWord(dict['n.Invalid value. Please try again.'], dict['packingQty']) })
    else if (dataInfo.qty > dataInfo.restQtyDis)
      return setAlertPopup({ open: true, text:replaceWord(dict['n.Value quantity cannot be entered larger than the remaining quantity'], dict['packingQty']) })

    let data = { ...dataInfo }

    // 추가
    if (await saveDataList(ADD_OUT_BARCODE_INFO, data)) onClose()
  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose()}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <div
        style={{
          backgroundColor: "#fff",
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "700px",
          padding: "23px 51px 44px 40px",
        }}
      >
        <Title>{dict['IssuePackingBarcode']}</Title>
        <div style={{ flex: 1 }}>
          <FlexBox>
            <Label>{dict['shipmentNo']}</Label>
            {dataInfo.salesOutNoDis}
          </FlexBox>
          <FlexBox>
            <Label>{dict['shipmentQty']}</Label>
            {dataInfo.salesOutQtyDis}&nbsp;{dataInfo.unitNameDis}
          </FlexBox>
          <FlexBox>
            <Label>{dict['issueQty']}</Label>
            {dataInfo.barcodeQtyDis}&nbsp;{dataInfo.unitNameDis}
          </FlexBox>
          <FlexBox>
            <Label>{dict['amtRemain']}</Label>
            {dataInfo.restQtyDis}&nbsp;{dataInfo.unitNameDis}
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['packingQty']}
            </Label>
            <InputBox type="number" value={dataInfo.qty} onChange={(e) => setQty(e.target.value)} />
          </FlexBox>
        </div>
        <ButtonBox>
          <Button bgColor={"#fff"} hover={"rgba(174, 174, 174, 0.22)"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={() => onClose()}>
            {dict['cancel']}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {dict['issue']}
          </Button>
        </ButtonBox>
      </div>
    </Dialog>
  )
}

const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const TextArea = styled.textarea`
  flex: 1;
  height: 80px;
  resize: none;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  width: 147px;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

OutBarcodeInfoPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default SalesContainer(CommonContainer(withTranslation()(OutBarcodeInfoPopup)))
