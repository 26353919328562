// 시스템설정 > 코드정보
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import SystemSettingContainer from "../../../containers/SystemSettingContainer"
import PageName from "../../component/common/PageName"
import InputBox from "../../component/common/InputBox"
import { YellowButton, SkyButton } from "../../component/common/Button"
import { TableComponent } from "../../component/common/Table"

// 차건담
import TransLang from "../../popup/SystemSetting/TransLang"

import CommonContainer from "../../../containers/CommonContainer"

/*
  +필요한 부분+

  변수
  _systemMajorCode,
  _systemMinorCode,

*/


const CodeInfoSystem = (props) => {
  const {
    t,
    _sysMajorCode,
    _sysMinorCode,
    initSysMajorCode,
    getSysMajorCode,
    initSysMinorCode,
    getSysMinorCode,

    // 다국어 공통 - 차건담 2023.01.27
    initLanguageKind,
    getLanguageKind,
    _languageKind,
    
    // 다국어 대분류
    initUMajorSMajorSMinor,
    getUMajorSMajorSMinor, // 다국어 대분류 값 가져오는 함수 - 차건담 2023.01.30
    _userUMajorSMajorSMinor, // _userUDLanguageCodeMajor

    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,

  } = props

  const [currentMajor, setCurrentMajor] = useState(null)
  const [majorName, setMajorName] = useState("")
  const [minorName, setMinorName] = useState("")
  const [minorList, setMinorList] = useState([])
  //유용준
  const [currentMinor, setCurrentMinor] = useState(null)

  const [isOpenPopupMajor, setisOpenPopupMajor] = useState(false)
  const [isOpenPopupMinor, setisOpenPopupMinor] = useState(false)
  const [LanguageKind, setLanguageKind] = useState([])  // 언어종류 가져오기 - 차건담 2023.01.30

  const [checkSysUser, setCheckSysUser] = useState(1)       // 사용자/시스템 구분코드 - 차건담
  const [checkMajorMinor, setCheckMajorMinor] = useState([]) // 대분류/소분류 구분코드 - 차건담

  // 시스템코드 다국어 대분류 번역기능을 위한 useState - 차건담 2023.01.31
    const [currentMajorForTrans, setCurrentMajorForTrans] = useState(null)
    const [majorListForTran, setMajorListForTran] = useState([])

  // 시스템코드 다국어 대분류 번역기능을 위한 useState - 차건담 2023.01.31  
    const [currentMinorForTrans, setCurrentMinorForTrans] = useState(null)
    const [minorListForTran, setMinorListForTran] = useState([])

    const [finalTransWords, setFinalTransWords] = useState([]) // 최종 번역 리스트

    const dict = _translationWords[0] // 변수에 사전 담기

  // 선택된 시스템 대분류코드가 9996 ~ 9999에 포함되는지 확인하는 키값 - 차건담 2023.02.16
  let checkMajorInOrOut = 0
    
  useEffect(() => {
    getTranslationWords()
    return async () => {
      await initSysMajorCode()
      await initSysMinorCode()
      await initUMajorSMajorSMinor()
      setisOpenPopupMinor(false)
      setisOpenPopupMajor(false)
    }
  }, [])

  useEffect(() => {
    if (currentMajor == null) return
    getMinorData()
  }, [currentMajor])

  useEffect(() => {
    setMinorList(_sysMinorCode)
  }, [_sysMinorCode])

  // 시스템코드 다국어 대분류 번역 - 차건담 2023.01.31 ============================================================================
    useEffect(() => {
      if (currentMajorForTrans == null || currentMajorForTrans == [] || currentMajorForTrans.length == 0) return
      else {
        setCheckMajorMinor([1, currentMajorForTrans.seq, 1])
      }
    }, [currentMajorForTrans])

    useEffect(() => {
      if (checkMajorMinor[0] != 1 || checkMajorMinor[1] == null || checkMajorMinor[1] == [] || checkMajorMinor[1].length == 0 ||
          checkMajorMinor[2] != 1) return
      else {
        getWordsForTranslate()

      }
    }, [checkMajorMinor[1]])

    useEffect(() => {
      if (_userUMajorSMajorSMinor == null || _userUMajorSMajorSMinor == [] || _userUMajorSMajorSMinor.length == 0 ||
          checkMajorMinor[2] != 1) return
      else {
        setMajorListForTran(_userUMajorSMajorSMinor)
        setFinalTransWords([])
      }
    }, [_userUMajorSMajorSMinor])

    useEffect(() => {
      if ( majorListForTran == null || majorListForTran == [] || majorListForTran.length == 0 ||
           checkMajorMinor[2] != 1) return
      else {
        if (checkMajorMinor[0] == 1) {     
          onClickTransLang_major()
        }
      }
    }, [majorListForTran])

    useEffect(() => {
      if (finalTransWords == null || finalTransWords == [] || finalTransWords.length == 0 ||
          checkMajorMinor[2] != 1) return
      else {
        console.log("CodeInfoSystem > 대분류 > finalTransWords:", finalTransWords)
      }
    }, [finalTransWords])
//============================================================================================================================




// 시스템코드 다국어 소분류 번역 - 차건담 2023.01.31 ============================================================================
   useEffect(() => {
      console.log("currentMinorForTrans: ", currentMinorForTrans)
    if (currentMinorForTrans == null || currentMinorForTrans == [] || currentMinorForTrans.length == 0) return
    else {
      console.log("currentMinorForTrans.seq: ", currentMinorForTrans.seq)
      setCheckMajorMinor([1, currentMinorForTrans.seq, 2])
      // if (currentMajorForTrans >= 9996 || currentMajorForTrans <= 9999) {
      //   checkMajorInOrOut = currentMajorForTrans
      // }
      // else {checkMajorInOrOut = 0}       
    }
  }, [currentMinorForTrans])

  useEffect(() => {
    console.log("checkMajorMinor[1]=======================================")
    if (checkMajorMinor[0] != 1 || checkMajorMinor[1] == null || checkMajorMinor[1] == [] || checkMajorMinor[1].length == 0 ||
        checkMajorMinor[2] != 2) return
    else {
      getWordsForTranslate()
      
    }
  }, [checkMajorMinor[1]])

  useEffect(() => {
    console.log("_userUMajorSMajorSMinor====================================")
    if (_userUMajorSMajorSMinor == null || _userUMajorSMajorSMinor == [] || _userUMajorSMajorSMinor.length == 0 ||
        checkMajorMinor[2] != 2 ) return
    else {
      setMinorListForTran(_userUMajorSMajorSMinor)
      setFinalTransWords([])
    }
  }, [_userUMajorSMajorSMinor])

  useEffect(() => {
    console.log("minorListForTran============================================")
    if ( minorListForTran == null || minorListForTran == [] || minorListForTran.length == 0 ||
         checkMajorMinor[2] != 2) return
    else {
      if (checkMajorMinor[2] == 2) {     
        onClickTransLang_minor()
      }
    }
  }, [minorListForTran])

  useEffect(() => {
    console.log("finalTransWords=============================================")
    if (finalTransWords == null || finalTransWords == [] || finalTransWords.length == 0) return
    else {
      console.log("CodeInfoSystem > 소분류 > finalTransWords:", finalTransWords)
    }
  }, [finalTransWords])
//============================================================================================================================


//언어종류 가져오기 - 차건담 2023.01.30
    useEffect(()=>{
      getLanguageList()
    }, [])

    const getLanguageList = async () => {
        await getLanguageKind()
        setLanguageKind(_languageKind)
    }

  const getMinorData = async () => {
    await getSysMinorCode({ majorSeq: currentMajor.seq })
  }

  const onClickMajorSearch = async () => {
    setCurrentMajor(null)
    await initSysMajorCode()
    await initSysMinorCode()
    const result = await getSysMajorCode({ majorName: majorName })
    if (result) setMajorName("")
    
    console.log(props._sysMajorCode)
    console.log(props._sysMinorCode)
  }

// 다국어 대분류 값 가져오기
  const getWordsForTranslate = async () => {
    if (checkMajorMinor[2] == 1) {
      await getUMajorSMajorSMinor({ majorSeq: currentMajorForTrans.seq,
                                    minorSeq: '',
                                    checkSysUser: checkSysUser,
                                  })
      console.log("_userUMajorSmajorSMinor: ",_userUMajorSMajorSMinor)
    }
    else if (checkMajorMinor[2] == 2) {
      console.log("line 225 currentMajor: ", currentMajor)
      if (currentMajor.seq > 0){
        await getUMajorSMajorSMinor({ majorSeq: currentMajor.seq,
          minorSeq: currentMinorForTrans.seq,
          checkSysUser: checkSysUser,
        })   
      }
      else {
        await getUMajorSMajorSMinor({ majorSeq: '',
          minorSeq: currentMinorForTrans.seq,
          checkSysUser: checkSysUser,
        })     
      }
    }  
  }

// 차건담 - 2023.01.30
const closePopupMajor = async () => {
  setisOpenPopupMajor(false)
  await getSysMajorCode({ majorName: majorName })
}

const closePopupMinor = async () => {
  setisOpenPopupMinor(false)
}

const onClickTransLang_major = async () => {
  setFinalTransWords(await getTransLangList(majorListForTran))
  if (isOpenPopupMajor != true) {
    setisOpenPopupMajor(true)
  } 
  // else {
  //   setisOpenPopupMajor(false)
  //   setisOpenPopupMajor(true)
  // }
}
const onClickTransLang_minor = async () => {
  setFinalTransWords(await getTransLangList(minorListForTran))
  if (isOpenPopupMinor != true) {
    setisOpenPopupMinor(true)
  }
}

// 언어종류 배열과 언어종류별 데이터 배열 합치는 함수 - 차건담 2023.01.30
/*
   언어종류 배열: 번역 가능한 언어들(languageSeq)을 가지고 있다. 
   언어종류별 데이터 배열: 해당되는 단어의 언어별 단어를 가지고 있다. 번역되어있는 데이터만 존재
*/
  const getTransLangList = async (transLangList) => {
    let listFinal = []

      for(let i=0; i<_languageKind.length; i++){
        let isTranslated = 0;
        for(let k=0; k<transLangList.length; k++){
            if(_languageKind[i].languageSeq == transLangList[k].languageSeq) {
                isTranslated = 1;
                listFinal.push(Object.assign(_languageKind[i], transLangList[k]))
            }
        }
        if (isTranslated == 0) {
            listFinal.push(_languageKind[i])
        }
      }

    return listFinal
  }

// 값 초기화 함수 - 차건담 2023.02.01
const initForMajor = async () => {
  setCurrentMajorForTrans(1)
  setMajorListForTran([])
  setCheckMajorMinor([])
  await initLanguageKind()
}

const initForMinor = async () => {
  getMinorData()
  setCurrentMinorForTrans(1)
  setMinorListForTran([])
  setCheckMajorMinor([])
  await initLanguageKind()
}
  return (
    <Container>
      {/* 다국어 기능 팝업 화면 - 차건담 */}
      <TransLang open={isOpenPopupMajor} checkSysUser={checkSysUser}
                                         checkMajorMinor={checkMajorMinor[0]}
                                         finalTransWords={finalTransWords}
                                         setFinalTransWords={setFinalTransWords}
                                         currentMajorForTrans={currentMajorForTrans}
                                         onClose={() => {
                                                         closePopupMajor()
                                                         initForMajor()
                                                        }}/>
      <TransLang open={isOpenPopupMinor} checkSysUser={checkSysUser}
                                         checkMajorMinor={checkMajorMinor[0]} // 소분류 번역창이 열리지만 대분류와 같은 로직이기 때문에 대분류에 해당하는 1을 값으로 넘겨줌
                                         finalTransWords={finalTransWords}
                                         setFinalTransWords={setFinalTransWords}
                                         currentMinorForTrans={currentMinorForTrans}
                                         onClose={() => {
                                                         closePopupMinor()
                                                         initForMinor()
                                                        }}/>

      <PageName name={dict['codeInfo']} state={false} setState={() => {}} />
      <FlexBox>
        <WhiteBox width={520}>
          <InputView>
            <Title>{dict['mainCategory']}</Title>
            <InputBox style={{ flex: 1 }} type="text" value={majorName} onChange={(e) => setMajorName(e.target.value)} />
            <YellowButton onClick={() => onClickMajorSearch()}>{dict['search']}</YellowButton>
          </InputView>
          <TableComponent
            headList={[
              { title: dict['codeNo'], type: "number" },
              { title: dict['mainCategoryName'] , type: "text" },
              { title: dict['function'] },
            ]}
            bodyList={_sysMajorCode.map((i, idx) => ({
              seq: i.majorSeq,
              majorSeq: i.majorSeq,
              majorName: i.majorName,
              Actions_transLang_sysMajor: true,
            }))}
            dict={dict}
            onClick={(i) => setCurrentMajor(i)}
            onClickTransLang={(i) => {setCurrentMajorForTrans(i), console.log(i)
                                      , getLanguageKind() // 언어종류 가져오기 - 차건담 2023.02.09
                                     }}
          />
        </WhiteBox>
        <Box>
          <WhiteBox width={520} marginLeft={10}>
            <InputView>
              <Title style={{marginTop: 10, marginBottom: 10}}>{dict['subCategory']}</Title>
            </InputView>
            <TableComponent
              headList={[{ title: dict['subCategoryCode'], type: "number" }, { title: dict['subCategoryName'], type: "text" }, { title: dict['function'] }]}
              bodyList={minorList.map((i, idx) => ({
                seq: i.minorSeq,
                minorSeq: i.minorSeq,
                minorName: i.minorName,
                Actions_transLang_sysMinor: true,
                }))}
              dict={dict}       
              onClick = {(i) => {setCurrentMinor(i)}}        
              onClickTransLang={(i) => {setCurrentMinorForTrans(i), console.log(i)
                , getLanguageKind() // 언어종류 가져오기 - 차건담 2023.02.09
              }}
            />
          </WhiteBox>
        </Box>
      </FlexBox>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  overflow-y: hidden;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 28px;
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width}px;
  height: 100%;
  margin-left: ${(props) => props.marginLeft}px;
  padding: 28px 35px 20px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const InputView = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Title = styled.p`
  width: 72px;
  color: #555555;
  font-size: 14px;
`
const TableInput = styled.input`
  width: 100%;
  height: 35px;
  padding: 0px 15px;
`

export default SystemSettingContainer(CommonContainer(withTranslation()(CodeInfoSystem)))
