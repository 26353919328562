// 생산관리 > 외부인력사용현황조회(팝업)
import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import { TableComponent } from "../../component/common/Table"

import CommonContainer from "../../../containers/CommonContainer"
import ManpowerInquiryContainer from "../../../containers/production/ManpowerInquiryContainer"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
      maxWidth: "960px",
    },
    "& .MuiDialog-paper": {
    },
  },
}))

const ManpowerInquiryPopup = (props) => {
  const classes = useStyles()
  const { open, onClose, infoItem, t, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  const dict = _translationWords[0] // 변수에 사전 담기
  
  useEffect(() => {
    getTranslationWords()
  }, [open])

  const handleClose = (value) => {
    onClose(value)
  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => handleClose(null)}
      open={open}
      fullWidth={true}
    >
      <TableComponent style={{margin : "23px 23px 23px 23px"}}
            headList={[
              { title: dict['ranking'], type: "number" },
              { title: dict['userName'], type: "text" },
              { title: dict['employeeNumber'], type: "number" },
              { title: dict['cellNo'], type: "text" },
              { title: dict['address'], type: "text" },
              { title: dict['gender'], type: "text" },
              { title: dict['remark'], type: "text" },
            ]}
            bodyList={((infoItem && infoItem) || []).map((i, idx) => ({
              no: idx + 1,
              seq: i.staffSeq,
              staffName: i.staffName,
              staffUniqueNumber: i.staffUniqueNumber,
              staffNumber: i.staffNumber,
              staffAddress: i.staffAddress,
              gender: i.gender == 1 ? "male" : (i.gender == 2 ? "female" : ""),
              remark: i.remark,
            }))}
            dict={dict}
          />
    
    </Dialog>
   
  )
}


export default CommonContainer(ManpowerInquiryContainer(withTranslation()(ManpowerInquiryPopup)))

