// 시스템설정 > 모듈권한
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import SystemSettingContainer from "../../../containers/SystemSettingContainer"
import PageName from "../../component/common/PageName"
import InputBox from "../../component/common/InputBox"
import { YellowButton } from "../../component/common/Button"
import TableCheckBox from "../../component/common/TableCheckBox"
import { TableComponent } from "../../component/common/Table"

import CommonContainer from "../../../containers/CommonContainer"

const ModulePermission = (props) => {
  const { t, _moduleAuthList, setModuleAuthList, getModuleAuthList, editModuleAuth, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  const [searchText, setSearchText] = useState("")
  const [authList, setAuthList] = useState([])

  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {
    getTranslationWords()
    return async () => await setModuleAuthList([])
  }, [])

  useEffect(() => {
    setAuthList(_moduleAuthList)
  }, [_moduleAuthList])

  const getData = async () => {
    await getModuleAuthList({ word: "" })
  }

  const onClickSearch = async () => {
    await getModuleAuthList({ word: searchText })
  }

  const onClickCheckBox = async (data, index) => {
    const result = await editModuleAuth({
      userSeq: data.userSeq,
      moduleSeq: data.moduleList[index].moduleSeq,
      isAuth: data.moduleList[index].isAuth == 1 ? 0 : 1,
    })

    if (result) getData()
  }

  const toObject = (arr, num) => { // i, idx
    // check1: <TableCheckBox check={i.moduleList[0].isAuth == 1} setCheck={() => onClickCheckBox(i, 0)} />,
    let obj = {}
    for (let i = 0; i < arr.moduleList.length; ++i) {
      obj["check" + i] = (
        <TableCheckBox
          check={arr.moduleList[i].isAuth == 1}
          setCheck={() => {
            onClickCheckBox(arr, i)
          }}
        />
      )
    }
    return obj
  }

  return (
    <Container>
      <PageName name={dict['modulePermission']} state={false} setState={() => {}} />

      <Box>
        <WhiteBox>
          <SearchBox>
            <Text>{dict['idOrName']}</Text>
            <InputBox style={{ marginRight: "14px" }} type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            <YellowButton bgColor={"yellow"} onClick={onClickSearch}>
              {dict['search']}
            </YellowButton>
          </SearchBox>
          <TableComponent
            headList={
              authList.length == 0 ? [] : 
                [
                  { title: dict['id'], type: "text" },
                  { title: dict['name'], type: "text" },
                ].concat(authList[0].moduleList.map(row => ({ title: row.moduleName, type: "text" })))
            //   [
            //   { title: t("id"), type: "text" },
            //   { title: t("name"), type: "text" },
            //   { title: t("defaultInfo"), type: "text" },
            //   { title: t("envControll"), type: "text" },
            //   { title: t("cropInfo"), type: "text" },
            //   { title: t("production"), type: "text" },
            //   { title: t("salesManage"), type: "text" },
            //   { title: t("purchaseSpend"), type: "text" },
            //   { title: t("statusInfo"), type: "text" },
            //   { title: t("systemSetting"), type: "text" },
            // ]
          
          }
            bodyList={authList.map((i, idx) => ({
              userId: i.moduleList[0].userId,
              userName: i.userName,
              ...toObject(i, idx),
              // check1: <TableCheckBox check={i.moduleList[0].isAuth == 1} setCheck={() => onClickCheckBox(i, 0)} />,
              // check2: <TableCheckBox check={i.moduleList[1].isAuth == 1} setCheck={() => onClickCheckBox(i, 1)} />,
              // check3: <TableCheckBox check={i.moduleList[2].isAuth == 1} setCheck={() => onClickCheckBox(i, 2)} />,
              // check4: <TableCheckBox check={i.moduleList[3].isAuth == 1} setCheck={() => onClickCheckBox(i, 3)} />,
              // check5: <TableCheckBox check={i.moduleList[4].isAuth == 1} setCheck={() => onClickCheckBox(i, 4)} />,
              // check6: <TableCheckBox check={i.moduleList[5].isAuth == 1} setCheck={() => onClickCheckBox(i, 5)} />,
              // check7: <TableCheckBox check={i.moduleList[6].isAuth == 1} setCheck={() => onClickCheckBox(i, 6)} />,
              // check8: <TableCheckBox check={i.moduleList[7].isAuth == 1} setCheck={() => onClickCheckBox(i, 7)} />,
              // check9: <TableCheckBox check={i.moduleList[8].isAuth == 1} setCheck={() => onClickCheckBox(i, 8)} />,
            }))}
            dict={dict}
          />
        </WhiteBox>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  margin-top: 30px;
  padding: 29px 40px 20px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Text = styled.p`
  width: 117px;
  color: #555555;
  font-size: 14px;
`

export default SystemSettingContainer(CommonContainer(withTranslation()(ModulePermission)))
