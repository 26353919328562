import React from "react"
import styled from "styled-components"
import Select, { components } from "react-select"
import icon_selector from "images/selector.png"
// import makeAnimated from 'react-select/animated';

// const animatedComponents = makeAnimated();

const MultiComboBox = (props) => {
  const { style = {}, placeholder, items, value, setValue, disabled,
          multiComboBoxId=null,
          setZindexOfMultiCombo=null, initZindexOfMultiCombo=null } = props

  const onChangeValue = (v) => {
    // 행 클릭 후 뜬 팝업차이 아닌 경우에만 값 변경 가능하게 - 차건담 2023.04.13
    if(disabled==false || disabled==undefined || disabled==null){
      setValue(v)
    }
  }

  const styles = {
    control: (base, state) => ({
      ...base,
      boxShadow: "none",
      border: state.menuIsOpen ? "1px solid #52935d" : "1px solid #dedede",
      "&:hover": {
        border: "1px solid #52935d",
      },
    }),

    option: (base) => ({
      ...base,
      width: "100%",
      height: "40px",
      textAlign: "left",
      borderBottom: "1px solid #dedede",
      fontSize: "14px",
    }),

    menu: (provided, state) => ({
      ...provided,
      padding: 0,
      margin: 0,
    }),

    menuList: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
  }

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props} >
        <img src={icon_selector} alt={""} style={{ width: "24px", height: "24px"}} />
      </components.DropdownIndicator>
    )
  }

  return (
    <StyledDropdown style={{ ...style }}>
      <Select
        id={multiComboBoxId}
        placeholder={<div
                      style={{ fontSize: "14px", color: "#55555566"}}
                      disabled = {disabled == undefined || disabled == null ? false : disabled} // 화면에서 행 클릭 시 수정 안되게 하기 위한 값 - 차건담 2023.04.13
                    >
                      {placeholder}
                    </div>}
        defaultValue={value}
        value={value}
        options={items}
        isMulti
        // components={animatedComponents}
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        onChange={onChangeValue}
        noOptionsMessage={() => null}
        onMenuOpen={() => setZindexOfMultiCombo == null ? console.log(setZindexOfMultiCombo) : setZindexOfMultiCombo()}
        onMenuClose={() => initZindexOfMultiCombo == null ? console.log(initZindexOfMultiCombo) : initZindexOfMultiCombo()}
        styles={styles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary50: "", // 항목 선택시
            primary25: "", // 항목 mouse over
            primary: "", // 선택된 항목 배경
          },
        })}
      />
    </StyledDropdown>
  )
}

const StyledDropdown = styled.div`
  position: relative;
  width: 500px;
  margin-right: 30px;
`
export default MultiComboBox
