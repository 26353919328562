// 현황정보 > 간편손익조회
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import { withTranslation } from "react-i18next"
import IncomeInquiryContainer from "../../../containers/IncomeInquiry/IncomeInquiryContainer"
import PageName from "../../component/common/PageName"
import { TableComponent } from "../../component/common/Table"
import { TableAccodionComponent } from "../../component/common/AccodionTable"
import DateBox from "../../component/common/DateBox"
import InputBox from "../../component/common/InputBox"
import { YellowButton } from "../../component/common/Button"
import BasicPopup from "../../popup/BasicPopup"

import CommonContainer from "../../../containers/CommonContainer"

const IncomeInquiry = (props) => {
  const { t, getInfoProfitAndLoss, setInfoProfitAndLoss, _infoProfitAndLoss, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props

  const getData = async () => {
    await getInfoProfitAndLoss({
      startDate: stdDate,
      endDate: endDate,
      itemName: division,
    })
  }
  useEffect(() => {
    getTranslationWords()
    return async () => setInfoProfitAndLoss([])
  }, [])

  const [popup, setPopup] = useState({ open: false, text: "" })
  const [stdDate, setStdDate] = useState(moment().format("YYYYMM01"))
  const [endDate, setEndDate] = useState(moment().format("YYYYMMDD"))
  const [division, setDivision] = useState("")

  const dict = _translationWords[0] // 변수에 사전 담기

  const onClickSearch = async () => {
    getData()
  }

  const sortType = (sortNum) => {
    let findSortNum = 0
    if (sortNum.sortNo == 1) findSortNum = 0
    else if (sortNum.sortNo >= 2000 && sortNum.sortNo < 3000) findSortNum = 1
    else if (sortNum.sortNo >= 1000 && sortNum.sortNo < 2000) findSortNum = 2
    else if (sortNum.sortNo >= 3000 && sortNum.sortNo < 4000) findSortNum = 3
    else if (sortNum.sortNo >= 4000 && sortNum.sortNo < 5000) findSortNum = 4
    return findSortNum
  }

  return (
    <Container>
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: "" })} content={popup.text} selectedValue={[dict['confirm']]} />
      <FlexBox justifyContent={"space-between"}>
        <PageName name={dict['incomeInquiry']} state={false} setState={() => {}} />
      </FlexBox>
      <Box>
        <WhiteBox>
          <SearchBox>
            <FlexBox>
              <Text>{dict['inquiryPeriod']}</Text>
              <DateBox
                style={{
                  width: "120px",
                  height: "40px",
                  marginRight: "5px",
                }}
                stdDate={stdDate}
                setStdDate={setStdDate}
              />
              <p style={{ width: "15px" }}>~</p>
              <DateBox
                style={{
                  width: "120px",
                  height: "40px",
                  marginRight: "30px",
                }}
                stdDate={endDate}
                setStdDate={setEndDate}
              />

              <Text>{dict['division']}</Text>
              <InputBox style={{ width: "200px" }} type="text" value={division} onChange={(e) => setDivision(e.target.value)} />
            </FlexBox>
            <YellowButton onClick={onClickSearch}>{dict['search']}</YellowButton>
          </SearchBox>
          <TableAccodionComponent
            headList={[
              { title: dict['division'], type: "text" },
              { title: dict['saleAmt'], type: "comma" },
              { title: dict['purchaseAmount'], type: "comma" },
              { title: dict['amt'], type: "comma" },
            ]}
            bodyMenu={
              _infoProfitAndLoss
                ? _infoProfitAndLoss
                    .filter((row) => row.sortNo == 1 || row.sortNo == 1000 || row.sortNo == 2000 || row.sortNo == 3000 || row.sortNo == 4000)
                    .map((i) => ({
                      menuType: sortType(i),
                      seq: i,
                      itemName: `${i.itemName}`,
                      amt:
                        i.sortNo > 0 &&
                        i.sortNo < 2000 &&
                        i.sortNo != 1 &&
                        i.sortNo != 1000 &&
                        i.sortNo != 2000 &&
                        i.sortNo != 3000 &&
                        i.sortNo != 4000
                          ? i.amt
                          : "",
                      exp: i.sortNo >= 2000 && i.sortNo < 3000 && i.sortNo != 2000 ? i.amt : "",
                      sum: i.sortNo == 1 || i.sortNo == 1000 || i.sortNo == 2000 || i.sortNo == 3000 || i.sortNo == 4000 ? i.amt : "",
                    }))
                : []
            }
            bodyList={
              _infoProfitAndLoss
                ? _infoProfitAndLoss
                    .filter((row) => !(row.sortNo == 1 || row.sortNo == 1000 || row.sortNo == 2000 || row.sortNo == 3000 || row.sortNo == 4000))
                    .map((i) => ({
                      menuType: sortType(i),
                      seq: i,
                      itemName: i.isSalesSum == 1 || i.isCostSum == 1 ? `  ${i.itemName}` : i.isTotalSum == 1 ? i.itemName : `    ${i.itemName}`,
                      amt:
                        i.sortNo > 0 &&
                        i.sortNo < 2000 &&
                        i.sortNo != 1 &&
                        i.sortNo != 1000 &&
                        i.sortNo != 2000 &&
                        i.sortNo != 3000 &&
                        i.sortNo != 4000
                          ? i.amt
                          : "",
                      exp: i.sortNo >= 3000 && i.sortNo < 5000 && !(i.sortNo == 2000 || i.sortNo == 3000 || i.sortNo == 4000) ? i.amt : "",
                      sum: i.sortNo == 1 || i.sortNo == 1000 || i.sortNo == 2000 || i.sortNo == 3000 || i.sortNo == 4000 ? i.amt : "",
                    }))
                : []
            }
            dict={dict}
          />
        </WhiteBox>
      </Box>
    </Container>
  )
}

export default IncomeInquiryContainer(CommonContainer(withTranslation()(IncomeInquiry)))

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  margin-top: 19px;
  padding: 28px 32px 20px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`
