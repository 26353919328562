// 온라인 이용약관
export const onlineTermsOfUse = `스마트팜 이용 약관과 업무처리규칙 및 서비스수준협약(이하 “약관” )은 ㈜아페스(이하 “아페스” )와 아페스가 제공하는 제품, 소프트웨어, 인적 서비스, 기능 등(이하 “서비스” )을 아페스의 스마트팜 웹사이트(이하 “엘리팜”) 상에서 이용하고자 본 약관에 동의하는 주체(“고객”) 간 의무와 권리 및 책임사항을 정하고 있습니다.

본 약관에 동의하는 경우 하단의 ‘위의 사항에 동의합니다’ 에 체크하십시오.
제1조(약관의 효력)
1. 본 약관은 엘리팜 이용약관과 업무처리규칙 및 서비스수준협약으로 구성되며 고객이 아페스에 가입신청을 하고, 아페스가 가입을 승낙한 때부터 효력을 발생합니다.
2. 고객이 대리인(가족, 고용인을 포함하되, 이에 한하지 않음)을 통하여 약관에 동의하고 가입신청 하는 경우에도 대리인은 계약상, 법률상 고객을 대리할 정당한 권한을 보유하였음을 보증하고, 고객은 약관에 동의하고 준수할 것임을 보증합니다.
3. 고객이 약관에 동의하는 것은 고객의 고용인들과 관계사, 협력사, 거래처 등 고객으로 인해 본 서비스를 이용하는 모든 사용자로 하여금 본 약관을 준수하게 할 책임이 있다는 것을 의미합니다.

제2조(약관의 명시와 개정)
1. 아페스는 약관의 내용과 상호, 영업소재지, 사업자등록번호, 연락처 등을 이용자가 알 수 있도록 초기 화면에 게시하거나 기타의 방법으로 사용자에게 공지합니다.
2. 아페스는 약관의 내용을 수시로 변경할 수 있습니다. 다만, 고객에게 현저하게 불리하거나 고객의 권리와 의무의 중요한 변경의 경우에는 문자 또는 기타 전자쪽지 등 전자적 수단으로 개별 통지하며, 통지한 날로부터 3일 이내에 고객이 이의를 제기하지 않거나 통지한 이후에도 서비스를 계속 이용하면 상기 기간이 도과한 때 또는 계속 이용한 때부터 변경된 약관에 동의한 것으로 간주합니다.

제3조(서비스)
1. 서비스는 아페스가 운용하는 인터넷 상의 엘리팜을 기반으로 제공되며, 또한 공중망(인터넷을 통한 네트워크 서비스)을 통해서만 제공합니다.
2. 서비스는 특정 인터넷 브라우저와 버전을 기준으로 제공되며 브라우저와 버전은 기술 상 필요에 따라 변경될 수 있습니다. 기준 외의 브라우저나 버전에서는 서비스의 일부 또는 전체가 구동하지 않을 수 있습니다.
3. 아페스는 기술적 정책적 판단에 의해 언제든지 서비스를 수정(신규 기능과 절차의 추가, 기존 기능과 절차의 변경과 삭제를 포함하되, 이에 한하지 않음)할 수 있습니다. 다만, 아페스가 서비스의 중요한 수정이라고 판단할 경우, 수정 내역을 고객에게 문자 또는 기타 전자쪽지 등으로 공지할 수 있습니다.
4. 서비스의 수정은 정기적 또는 비정기적으로 발생할 수 있습니다.
5. 고객은 고객에게 필요한 기능과 절차에 대해 언제라도 아페스에 제안하거나 아이디어를 제공할 수 있으나, 아페스는 고객들의 해당 기능 개발 요청 빈도, 개발의 가능성, 방향성, 그리고 적합성 등을 기준으로 개발여부를 재량으로 판단합니다.

제4조(모바일 기기에서의 서비스 사용)
1. 서비스는 인터넷을 통해 사용하는 호환성 있는 모바일 기기에서 사용이 가능합니다. 고객은 고객의 모바일 기기 및 통신사의 약관 조항과 일반 사용법, 서비스의 업데이트 등 모든 변경 사항에 대해 스스로 책임을 집니다.
2. 아페스는 아페스의 귀책 사유가 없는 서비스 장애 상황 등(다음 사항을 포함하되, 이에 한하지 않음)에 대해 어떠한 책임도 지지 않습니다.
• 고객 통신서비스 제공사의 서비스 제공 가능 여부
• 시간 및 장소를 불문한 서비스 접속 가능 여부
• 통신서비스 관련 서비스 일체의 누락, 손상 또는 보안 침해
• 데이터 정보 전송 실패 또는 서비스 연결 설정 실패

제5조(데이터 보호 및 관리)
1. 데이터란 고객이 직접 입력하였거나 센서에 의해 자동으로 수집된 값이 물리적인 서버 내에 저장된 자료 및 그 자료들의 결합, 변형 또는 재연산을 통해 생성되는 부가 자료 일체를 말하며, 고객이 제공, 노출하였거나 아페스에 의해 파악된 고객의 자료(고객 자신, 서비스 사용 경험, 고객의 비즈니스, 기타)를 포함합니다.
2. 고객의 데이터는 아페스 또는 아페스 대리인이 시설을 유지 관리하는 지역 또는 국가에 저장하고 연산 처리할 수 있습니다.
4. 아페스는 해당 국가 수사기관의 영장, 법원의 판결 또는 결정 또는 정당한 법률에 의거한 경우에는 고객의 데이터를 요청기관에 고객에 대한 사전 통지 없이 제공하거나 공개할 수 있습니다.
5. 아페스가 제공하는 데이터 삭제 기능을 통해 고객이 데이터를 삭제하면 일반적으로 데이터는 모두 삭제되지만, 아페스가 백업 서버 등에 보관한 백업본에는 데이터가 남아있을 수 있습니다. 이 백업본 데이터는 아페스 자체의 백업본 데이터 운용정책에 따라 일정 기간(통상 영업일 기준 7일 정도이나, 항상 그렇지는 않음)이 경과한 후 자동적으로 소멸됩니다.
6. 데이터가 유실된 경우
• 저장된 전체 고객의 데이터 중 어느 고객의 데이터가 덜 중요한지, 또는 특정 고객의 데이터 중에서도 어떤 데이터가 더 중요한지 아페스는 알 수 없습니다.
아페스는 저장된 데이터의 중요성 또는 우선순위에 관계없이 모든 고객 데이터에 대해 동일한 수준의 주의와 절차로써 관리합니다.
• 통상적인 서비스 유지 활동의 일환으로 기능 업그레이드, 시스템 점검 및 교체, 데이터 마이그레이션, 기타 일상적인 서비스 제공과 관련한 행위 도중에, 비록 아페스의 귀책사유로 데이터의 유실 또는 변형이 발생하였다 하더라도 아페스는 고객 입장에서의 데이터의 중요성 또는 우선순위에 관계없이 최근 3개월 동안 고객이 아페스에 지불한 금액보다 더 많은 배상금액을 지불하지 않습니다.
• 고객의 실수나 관리소홀로 인해 분실되거나 복구 불가능한 데이터 일체에 대한 책임은 고객에게 있습니다.
7. 서비스의 오류 가능성
• 컴퓨터 소프트웨어는 사람의 손으로 이루어지는 프로그램 코딩의 결과물이라는 특성으로 인하여 오류 가능성이 존재하고, 아페스가 제공하는 서비스도 입력, 저장, 계산처리, 출력 등에서 오류가 발생할 가능성을 포함하고 있습니다.
• 고객이 상기와 같은 입력, 저장, 계산처리, 출력 등에서 발생한 오류을 인지하게 된 경우, 아페스에게 이를 즉시 통지하고, 오류를 인지하거나 통지 받은 아페스는 오류를 시정하도록 최선을 다할 것입니다.
• 만에 하나, 상기와 같은 입력, 저장, 계산처리, 출력 등에서 발생한 오류를 아페스가 인지하거나 통지 받지 못 한 상황에서, 또는 인지하거나 통지 받았다고 하더라도 오류의 시정 절차 중에 있거나 시정 우선순위에 밀려서 미처 시정되지 않은 상황에서, 해당 오류로 인해 고객에 손해가 발생했을 때, 아페스는 최근 3개월 동안 고객이 아페스에 지불한 금액 한도 내에서 고객에게 발생한 손해를 배상 할 수 있습니다. 또한 고객이 오류를 인지하였음에도 이를 즉시 아페스에게 통지 하지 않은 경우, 아페스는 손해배상 책임을 부담하지 않습니다.
8. 고객은 다음 사항에 포함되는 데이터를 업로드, 게시, 배포, 링크, 출판, 재생산 또는 전송하지 않을 것이며, 제 3자에게 이를 허용하지 않을 것임을 동의하며, 다음 사항의 데이터가 게재될 경우 아페스는 판단에 따라 해당 데이터를 즉시 삭제할 수 있습니다.
• 불법, 사기, 명예 훼손, 외설, 포르노, 비속어, 협박, 증오, 희롱, 모욕 및 부적절하거나 불쾌한 정보 또는 통신 일체를 포함하며, 꼭 언급한 내용이 아니더라도 타인을 격분하게 만들 수 있는 내용 일체 및 법적 형사 또는 민사책임이 적용되는 내용 일체
• 타인을 사칭하거나 고객의 신분 또는 자격을 거짓 증명하는 내용 및 개인 사생활 일체를 침해할 수 있는 내용
• 호객 행위, 투자 기회, 다단계 판매, 단체 문자(SMS), 스팸메일 등 제 3자가 원치 않는 상업적 자료
• 바이러스, 트로이 목마, 웜 또는 기타 파괴성 있는 악성 소프트웨어와 자료
• 고객이 법적인 권리를 지니지 않거나 저작권자 또는 지적재산권자의 허가를 받지 않은 정보 및 소프트웨어 일체
• 기타 데이터 작성의 취지와 내용 등에 비추어 삭제하는 것이 객관적으로 적절한 데이터
9. 아페스는 아페스와 고객을 보호하거나 서비스를 정상적으로 운영하기 위해 데이터 일체를 감시 또는 수집할 수 있습니다.
10. 아페스는 자사 재량에 의해 허용되지 않거나 바람직하지 않은 데이터, 또는 부적절하거나 본 약관을 위반하는 데이터 일체의 게시 및 저장을 거부하거나 삭제, 또는 삭제를 거부할 수 있습니다.
제6조(아페스의 데이터 활용)
1. 아페스가 서비스 홍보나 서비스 개선을 위하여 데이터를 활용하는 것에 대해 고객은 동의합니다.
2. 아페스는 고객에게 아페스의 다른 서비스, 제품 또는 광고를 제공할 수 있으며, 이때 다른 서비스의 종류에 따라서 추가적인 약관 및 요금이 적용될 수 있습니다.
3. 아페스는 서비스 사용에 도움을 줄 수 있는 부가 서비스 또는 제 3자 제품에 관한 내용을 고객에게 전달할 수 있습니다. 고객은 아페스가 상기 내용들을 이메일로 전송하거나 엘리팜에 게시하는 것에 동의합니다.
4. 데이터 중에서도 고객이 작성한 아페스에 대한 제안사항, 상담문의내역 및 상담요청 시 수집된 고객정보, 업그레이드 아이디어 등은, 아페스가 서비스 개선을 위해 어떠한 형태로든 자유롭게 사용(서비스 개선 아이디어 및 광고 마케팅 자료 활용 등을 포함하며, 이에 한하지 않음)할 수 있으며, 아페스는 고객이 제공한 제안사항, 상담문의내역 및 상담요청 시 수집된 고객정보, 업그레이드 아이디어 등에 관해 영구적이고 전세계적으로 사용할 수 있고, 전면 양도 및 2차 인가가 가능하며, 고객은 아페스에 제공한 제안사항, 상담문의 및 내역, 업그레이드 아이디어 등에 대하여 로열티 등의 일체 비용을 요구할 수 없습니다.
제7조(개인정보의 보호)
1. 개인정보란, 아페스를 사용하는 고객을 구분할 수 있는 주민번호, 계좌번호 등 기타 아페스가 판단하기에 민감한 정보를 말합니다.
2. 고객이 아페스에 입력한 민감한 개인정보에 대해 아페스는 제3자에게 배포, 누설되지 않도록 보호하기 위해 노력합니다.
3. 고객이 자신의 거래처나 기타 제 3자에 대한 개인정보(예: 거래처 담당자의 핸드폰이나 이메일주소)를 아페스에 저장할 때, 아페스로서는 저마다의 고객이 어떤 형식으로, 어떤 위치 또는 어떤 컬럼을 생성하여 저장할 지 예측할 수 없으므로 기본적으로 개인정보가 아닌 일반 데이터로 인식합니다.
제8조(결제 및 지불)
1. 결제: 고객은 매월 또는 연 단위로 아페스 서비스를 결제해야 합니다.
2. 사용기간의 적용: 서비스 사용기간은 최초 가입일을 기준으로 고객이 납부한 결제방식에 따라 서비스만료일이 산정되며, 결제일에 관계없이 직전 서비스만료일을 기준으로 다음 서비스만료일이 설정됩니다.
3. 환불: 연 선납 고객이 중도 해지하는 경우, 선납 결제한 금액 중 서비스 해지 이후의 기간에 해당하는 미경과 금액을 비율로 환산하여 환불하며. 환불과정에서 발생하는 제반 수수료, 비용 등은 고객이 부담합니다. 매월 납부 고객의 경우, 서비스 시작 익월부터 비용을 납부하여야 하고 서비스 시작 월의 잔여기간에 대해 비용납부 의무가 없으며, 해지 월의 잔여기간에 대해서도 환불을 요청하지 않는다는데 동의합니다.
4. 결제방법: 별도로 합의하지 않는 한 고객은 아페스가 지정한 결제수단과 화폐단위로 결제합니다.
5. 세금포함: 고객이 서비스 이용과 관련된 세금(부가가치세를 포함하되, 이에 한하지 않음)에 대해 책임을 지므로, 고객은 서비스에 대해 세금까지 포함하여 아페스에 지불해야 합니다.
6. 원천징수: 결제금액은 은행, 카드사, 결제대행사(Payment Gateway), 페이팔 등 아페스가 지정한 방법을 통해 아페스에 최종 도달한 금액을 기준으로 하며 해당 결제를 위해 고객 국가의 세무 당국에 지급하여야 할 금액은 고객이 부담합니다.
제9조(해지 및 종료)
1. 종료: 고객이 매월 또는 선납 기간의 해당 사용료를 납부하는 행위를 통해 계약을 연장하지 않으면 서비스 이용 만료일에 자동으로 고객의 서비스 이용이 종료됩니다.
2. 고객의 의사로 인한 해지: 고객은 서비스 이용 중 언제든지 서비스를 해지할 수 있습니다.
3. 아페스의 의사로 인한 해지: 아페스는 언제든지 어떠한 이유에서든 서비스를 해지할 수 있습니다. 이 경우 최소 30일 전에 고객에게 안내합니다. 안내는 이메일 또는 문자 기타 전자쪽지를 이용하여 안내합니다. 다만, 고객이 약관을 위반한 경우에 즉시 해지할 수 있습니다.
4. 고객 데이터 백업: 고객은 종료나 해지 전에 데이터 백업을 요청 할 수 있습니다. 아페스가 제공하는 백업 수단은 기술 상 혹은 정책 상 고객의 모든 데이터에 대한 완전한 백업을 의미하지는 않습니다. 고객은 종료나 해지가 아니라면 아페스에 어떠한 데이터 백업 요청도 할 수 없습니다.
5. 해지 이후 데이터: 아페스는 해지한 고객이나 이용이 종료된 고객의 데이터를 보관할 의무가 없으며, 계약 해지나 이용을 종료하면서도 고객이 삭제하지 않은 데이터는 통상적으로 해지나 종료한 날로부터 3개월이 지나면 삭제하는 것을 내부방침으로 합니다.
6. 재가입한 고객의 데이터 : 계약 해지한 후에도 고객은 언제든 서비스 재가입을 할 수 있습니다. 다만 재가입시 해지이전 데이터의 복원 및 완전성, 무결성에 대해 아페스는 보장할 의무가 없습니다.

제10조(일시 중지)
1. 고객이 약관을 위반하거나, 자료의 소유권을 두고 고객 내부의 분쟁이 있을 경우 아페스는 고객에게 문자 또는 전자쪽지 등 기타 전자적 수단으로 개별 통지한 후 서비스의 제공을 일시 중지할 수 있습니다.
2. 아페스는 정기적 혹은 비정기적 시스템의 점검, 긴급한 보안 상의 필요, 기타 서비스 제공에 필요하다고 생각할 경우 언제든지 서비스를 일시 중지할 수 있습니다. 일시 중지로 인해 발생한 고객의 어떠한 피해에 대해서도 아페스는 배상의무가 없습니다. 다만, 아페스의 귀책사유로 서비스가 사전 공지 없이 영업일 기준 3시간 이상 중지되는 서비스 정전이 발생한 경우에는 서비스수준협약에 의해 배상합니다.

제11조(면책 및 제한)
1. 불가항력: 고객과 아페스 양 당사자는 자연재해, 전쟁 또는 테러, 폭동, 노사문제, 정부 조치, 인터넷 장애 및 서비스 거부(DDoS) 공격 등과 같은 당사자의 통제력을 벗어난 조건으로 인해 발생한 약관 불이행에 대해서는 책임을 지지 않습니다.
2. 배상금액의 제한: 본 약관 제11조를 제외하고, 아페스의 귀책사유로 고객에게 별도 손해가 발생한 경우에도 아페스는 고객이 아페스의 책임을 묻는 사건이 발생하기 전 3개월 동안 고객이 지불한 금액보다 많은 금액을 배상하지 않습니다.
3. 서비스 이용을 위한 가입 신청은 고객 스스로 서비스의 적합성과 유용성을 판단하여 스스로 결정한 진정한 의사 표시로서, 아페스는 서비스가 고객에게 적합하다거나 고객이 거주하는 모든 지역 및 국가의 상황에도 유용하다고 선언한 사실이 없음을 확인합니다.

제12조(기타)
1. 적용법 및 분쟁해결방법:
   ① 본 계약은 [대한민국] 법의 적용 • 해석을 받습니다.
   ② 본 계약 및 그 불이행과 관련하여 발생되는 모든 분쟁은 대한민국 서울에서 대한상사중재원의 중재규칙에 따라 중재로 해결합니다. 중재인이 내린 판정은 최종적인 것으로 관련 당사자 쌍방을 구속합니다. 중재인의 수는 3인, 중재에 사용할 언어는 한국어로 합니다.
2. 동의: 고객은 가입 신청함으로써, 온라인 이용약관과 업무처리규칙, 서비스수준협약 등 본 약관을 구성하는 모든 문서에 완전히 동의한 것입니다.
3. 상충하는 조건에 대한 해석: 엘리팜과 본 약관을 구성하는 문서 간에 상충하는 부분이 있을 경우, 우선순위는 ① 업무처리규칙 ③ 서비스수준협약, ④ 온라인 이용약관 순으로 합니다.
`
// 업무처리규칙
export const businessRules = `2021년 4월 7일 업데이트됨
서비스를 이용하는 고객은 본 아페스 업무처리 규칙(이하 “규칙”)을 준수해야 합니다. 고객이 규칙을 위반한 것으로 판단될 경우, 아페스는 언제라도 고객의 서비스 이용을 중지하거나 계약을 해지할 수 있습니다. 아페스는 언제든지 어떤 이유에서든 규칙을 변경할 수 있으며 규칙에 게재된 최신 내용을 숙지하고 준수할 책임은 고객에게 있습니다.
용어의 정의
• 서비스: 아페스가 고객에게 제공하는 제품, 소프트웨어, 인적 서비스, 기능 등을 의미합니다.
• Code: 고객에게 부여되는 식별번호로서, 아페스가 작성하여 고지하는 영문자와 숫자의 조합을 의미합니다.
• ID: 고객이 서비스 이용 시 서비스의 이용권한 있는 자를 제한지정하기 위하여 고객이 신청하고 아페스가 승인하는 한글, 영문자, 숫자 등의 조합으로, ID는 권한에 따라 다음의 2가지 종류로 구분합니다.
• Master ID : 최초 가입시 입력한 사용자 정보로써 아페스에서 가입승인과 동시에 스마트 팜 시스템의 모든 권한을 가진다.
• 대표자: 사업자등록증, 법인등기부등본 등의 공적 증명서에 고객의 대표자로 등재된 사람을 의미합니다.

[지적재산권]
서비스는 저작권, 영업 비밀 및 기타 지적재산권의 보호를 받습니다. 서비스 관련 모든 지적 재산권은 아페스에 귀속되고, 고객은 서비스를 사용할 권한만 부여 받으며, 아페스가 명시한 목적에 국한되어서만 서비스를 사용할 수 있습니다. 고객은 계약기간 동안 서비스 사용료의 지불 의무 일체를 이행해야 합니다.

[서비스 이용 중 금지된 행위]
고객은 서비스 이용 중 다음의 금지된 행위를 하는 경우 아페스는 즉시 서비스 중지 및 계약해지를 할 수 있습니다.
• 아페스가 허용한 경우를 제외하고 서비스의 전부 또는 일부를 제3자에게 판매, 재판매, 임대 또는 그와 유사한 시도를 하는 경우
• 서비스의 대체품이나 그와 유사한 서비스를 만들려고 시도하는 경우
• 불법, 명예 훼손, 희롱, 남용, 사기, 권리 침해, 외설 등의 소지가 있거나 용인하기 어려운 콘텐츠를 전송, 업로드, 배포하거나 이와 같은 행동을 권하는 경우
• 바이러스, 웜, 결함, 트로이 목마, 손상된 파일, 거짓 바이러스 정보 또는 기타 유해하거나 사기성이 있는 항목을 배포하는 경우
• 코드, 사용자 ID, 이메일 주소 등 기타 수단을 통해 다른 사람인 것처럼 가장하거나 자신의 신원 또는 이메일의 출처를 속이는 경우
• 본 서비스를 사용하여 다른 사람의 법적 권리를 침해하는 경우
• 불법활동을 조장 또는 장려하는 경우
• 다른 서비스 이용자의 원활한 서비스 이용에 방해가 되는 경우
• 본 서비스의 일부를 수정, 개조, 변환하거나 리버스 엔지니어링 하는 경우
• 본 서비스에 포함된 저작권, 상표 또는 기타 독점권 표시를 제거하는 경우
• 본 서비스에 속하는 웹 페이지의 일부 형식을 바꾸거나 조작하는 경우
• 아페스 로고나 기타 아페스 상표 표시를 수정하는 경우
• 제공 서비스를 이용하여 얻은 정보를 아페스의 사전 서면승낙 없이 복제, 유통시키거나 상업적으로 사용하는 경우
• 이 계약의 체결 또는 이행과정에 취득한 아페스의 정보를 누설, 배포하거나 상업적 목적으로 사용하는 경우
• 이용요금을 미납하는 경우
• 고객이 자발적으로 요청한 서비스 중지 기간 3개월을 초과한 경우
• 아페스 또는 서비스와 경쟁관계에 있는 이용자가 이용하는 경우
• 아페스 또는 서비스를 모니터링 하거나 벤치마킹 하거나 기타 경쟁의 목적으로 이용하는 경우
• 전화, 게시판, 이메일, 인터넷 등 기타 수단을 통해 아페스에 욕설, 비속어, 고성, 반말, 허위주장, 기타 모욕적인 언사 등을 하는 경우
• 서비스 이용 신청 시 허위 내용을 등록한 경우
• 기타 이용약관 등 서비스 관련 제반 규정을 위반하여 사용하는 경우

[과도한 사용에 대한 제한]
• 고객은 서비스 이용 시 일일 입력 300 Line 이상을 초과하여 사용할 수 없습니다. (1 Line은 판매 또는 구매 화면의 1행을 의미)
• 고객은 서비스 이용 시 서버에 등록한 품목코드와 거래처코드 어느 하나라도 10,000건 이상을 초과하여 사용할 수 없습니다.
• 초과 사용하는 고객에게 발생하는 속도 저하나 오류 발생에 대해 아페스는 책임지지 않으며 관련 기능 사용을 제한할 수 있습니다.
• 아페스가 명시적으로 허용하지 않은 자동화된 도구(아래의 예시를 포함하되 이에 한하지는 않음)를 사용하는 경우 아페스는 해당 고객의 사용을 제한하거나 시스템 접근을 차단할 수 있습니다.
   - 프로그래밍화된 엑셀을 이용한 데이터 수발신
   - 스크래핑 기술을 이용한 데이터 수발신 등.
• 특정 고객이 시스템 자원(네트워크 트래픽, CPU, 메모리, DB 등)을 과도하게 사용해서 나머지 다수 고객의 사용에 불편을 초래하거나 초래할 것이 예상될 경우 아페스는 해당 고객의 사용을 제한할 수 있습니다.

[비밀번호의 관리]
고객 비밀번호에 관한 관리 책임은 고객에게 있으며, 이를 제 3자가 이용하도록 하여서는 안 됩니다. 고객 비밀번호가 도용되거나 제 3자가 사용하고 있음을 인지하였을 경우 아페스에 제보할 책임이 있으며, 아페스의 안내에 따라야 합니다. 아페스에 위 사실을 제보하지 않거나, 제보한 경우에도 아페스의 안내에 따르지 않아 발생한 불이익에 대하여 아페스는 책임지지 않습니다.

[Code와 자료의 권리 귀속에 관한 분쟁 시]
• Code와 그에 입력한 자료의 소유 및 권리에 관한 모든 분쟁에서 아페스는 분쟁 발생시 최초 등록된 ID를 유일한 권리자로 간주합니다.
• 예외적으로, 아페스와 거래한 고객의 법인등기부나 사업자등록증 상의 대표자와 Master ID를 가진 자간의 분쟁이 발생한 경우, 아페스는 법인등기부나 사업자등록증 상의 대표 자를 우선적인 권리자로 간주할 수 있습니다. 법인등기부나 사업자등록증 상의 대표자는 자신이 대표자 본인임을 증명하기 위하여 아페스 주사무실로 내방하는 등 아페스가 지정하는 방법에 따라야 합니다. 대표자의 사정으로 아페스가 지정한 방법으로 대표자 본인임을 증명하지 못하여 손해나 문제가 발생한 경우, 아페스는 이에 대한 책임을 부담하지 않습니다.
• 기존 자료권리자나 대표자의 사망 등 여러 가지 이유로 아페스가 정당한 권리자를 특정할 수 없는 상황에서의 분쟁 시, 법원 등 관계기관의 최종 결정 또는 법적 효력이 있는 이해당사자 간의 합의가 있을 때까지 아페스는 해당 Code를 통한 서비스 접속을 임의로 차단할 수 있습니다.
• 위의 최종결정이나 합의가 법적으로 정당한 효력을 갖고 있는지에 대해, 아페스가 확인하는 과정에서 전문가에게 위임하면서 수수료 등이 발생하면, 발생한 수수료 등 제 반 비용은 고객이 부담합니다.`

// 서비스수준협약
export const serviceLevelAgreement = `서비스 제공
서비스 사용 기간 동안 아페스의 스마트팜 웹서비스는 무중단의 서비스를 제공함을 원칙으로 합니다. 만에 하나 아페스의 귀책사유로 서비스 정전이 발생할 경우, 고객은 본 서비스수준협약에 명시된 배상을 받을 수 있습니다. 본 서비스수준협약는 서비스 정전이 발생했을 때 고객이 취할 수 있는 단독적이며 배타적인 구제조치에 대해 설명합니다.

본 서비스수준협약은 아페스의 업무상황, 경영환경, 마케팅 전략, 고객정책 등의 변화에 따라 임의로 변경할 수 있으며, 여기에 게재된 서비스수준협약의 최신 내용을 숙지하고 준수할 책임은 귀하에게 있습니다.
정의
• 서비스 정전: 아페스의 귀책사유로 서비스 중단에 대한 사전 공지 없이 고객이 서비스에 접속 자체가 불능인 상태이거나, 접속 후 서비스 기능의 50% 이상이 불능인 상태가, 영업일 기준 최소 3시간 이상 지속되는 경우를 의미합니다.
• 서비스 정전 기간 측정: 아페스 서버 측 오류율을 기준으로 하여 측정합니다.
• 서비스수준협약 적용 서비스: 아페스가 자체로 개발하여 서비스하는 기본정보, 환경제어, 작기,생산, 판매, 구매에 적용합니다. 스마트팜 시스템과 연계하여 제공하는 제어노드, 센서노드 등 하드웨어 장치들의 장애에는 적용하지 않습니다.
• 배상: 서비스 정전이 발생한 경우 아페스는 무상 서비스 기간으로 배상합니다. 정전 기간 대비 제공되는 배상은 다음과 같습니다.
서비스 정전 기간	무상 서비스 기간
3시간 ~ 6시간 미만	1일
6시간 ~ 12시간 미만	2일
12시간 ~ 1일 미만	3일
1일 ~ 2일 미만	5일
2일 이상	7일
고객의 배상청구권 행사
위에 명시된 배상을 받으려면 고객에게 배상을 청구할 수 있는 자격이 발생한 지 30일 내에 고객이 아페스에 관련 사실을 서면(전자메일 포함)으로 통지해야 합니다. 이 요구사항을 이행하지 못할 경우 고객은 배상청구권을 상실하게 됩니다.
최대 배상
상기 무상 서비스 기간 표에 따라 1달 동안 발생한 서비스 정전 기간에 대하여 아페스가 고객에게 제공할 수 있는 무상 서비스 시간은 최장 7일으로서 고객은 1달 기준으로 무상 서비스 기간 7일을 초과하여 배상 청구할 수 없습니다.
서비스수준협약 예외사항
서비스 정전이 ① 약관의 '불가항력' 조항에 설명된 요인에 의해 발생하거나 ② 고객 또는 제3자의 장비 중 어느 한쪽이나 양쪽 모두의 장애(아페스의 통제 범위에 속하지 않는 문제)로 인해 발생할 경우 등 아페스의 귀책사유가 아닌 다른 사유로 발생한 서비스 정전에는 본 서비스수준협약이 적용되지 않습니다.
`

// 개인정보보호정책
export const privacyPolicy = `'(주)아페스는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법, 통신비밀보호법, 전기통신사업법에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다.

* 수집 항목 - 신청자 이름, 농가명, 농가주, 사업자등록번호, 농장시작일, 농가주소, 마스터 사용자의 ID, 이름, 비밀번호, 농가전화번호, 모바일, 이메일
* 수집목적 - 서비스 사용을 위한 신규코드 발급, 서비스 이용안내 전화 연락, 가입신청 확인 안내메일/SMS 발송
* 보유 및 이용기간 - 가입 약관에 명시한 기간까지 보유

위 사항에 대한 동의가 없는 경우 회사에서 제공하는 서비스를 신청하거나 가입하는 것이 불가능 할 수 있습니다.
`
