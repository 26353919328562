// 비밀번호 재설정
import React, { useState } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import SignContainer from "../../containers/SignContainer"
import SEO from "../seo"
import InputBox from "../component/common/InputBox"
import InputTelBox from "../component/common/InputTelBox"
import { GreenButton } from "../component/common/Button"
import BasicPopup from "../popup/BasicPopup"
import icon_back_gray from "images/back_gray.png"
import icon_back_green from "images/back_green.png"
import {Oval} from "react-loader-spinner";

const ChangePw = (props) => {
  const { t, findPw } = props
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [id, setId] = useState("")
  const [phone, setPhone] = useState("")
  const [ovalStatus, setOvalStatus] = useState(false)

  const onClickBack = () => {
    navigate("/app/login")
  }

  const onClickPassChange = async () => {
    if (id === "" || phone === "") return setPopup({ open: true, text: t("Please fill in the blanks") })
    const result = await findPw({ userId: id, teleNo: phone })

    if (result) {
      navigate("/app/login")
    }

    setOvalStatus(false)
  }



  return (
    <Container>
      <SEO title={t("resetPassword")} />
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: "" })} content={popup.text} selectedValue={[t("confirm")]} />
      <Title>{t("resetPassword")}</Title>
      {/* <form
        onSubmit={(e) => {
          e.preventDefault()
          onClickPassChange(e)
        }}> */}
      <InputSection>
        <InfoView>
          <p>{`${t("resetPasswordMsg1")}\n${t("resetPasswordMsg2")}`}</p>
        </InfoView>
        <InputView>
          <InputLabel>{t("id")}</InputLabel>
          <InputBox
            style={{ width: "300px", marginRight: 0 }}
            type={"text"}
            placeholder={t("n.Please select a product.", { n: t("id") })}
            value={id}
            onChange={(e) => setId(e.target.value)}
          />
        </InputView>

        <InputView>
          <InputLabel>{t("cellNo")}</InputLabel>
          <InputTelBox style={{ width: "300px", marginRight: 0 }} value={phone} setValue={setPhone} />
        </InputView>
      </InputSection>
      <BottomSection>
        <BackButton type="button" onClick={onClickBack}>
          <div id={"backIcon"} />
          <p>{t("backToLogin")}</p>
        </BackButton>
        {ovalStatus == false ?
          <GreenButton
            type="submit"
            style={{ width: "200px", height: "56px" }}
            onClick={(e) => {
              e.preventDefault()
              setOvalStatus(true)
              onClickPassChange(e)
            }}
          > {t("resetPassword")}
          </GreenButton> : <Oval height={50} width={50}></Oval>
        }
      </BottomSection>
      {/* </form> */}
    </Container>
  )
}

const Container = styled.div`
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
const Title = styled.p`
  opacity: 80%;
  margin-bottom: 30px;
  color: #555555;
  font-size: 17px;
  line-height: 32px;
`
const InputSection = styled.section`
  background-color: #fff;
  width: 528px;
  height: auto;
  padding: 26px 60px 38px 56px;
  border: 1px solid #ffffff;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const InfoView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 69px;
  margin-bottom: 27px;
  opacity: 80%;
  & > p {
    opacity: 80%;
    color: #555;
    font-size: 15px;
    line-height: 26px;
    letter-spacing: -0.5px;
    text-align: center;
    white-space: pre;
  }
`
const InputView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  &:last-child {
    margin-top: 20px;
  }
`
const InputLabel = styled.p`
  opacity: 80%;
  width: 90px;
  margin-bottom: 5px;
  margin-right: 22px;
  color: #555555;
  font-size: 16px;
  line-height: -0.45px;
`
const BottomSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 528px;
  margin-top: 26px;
`
const BackButton = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > div#backIcon {
    background-image: url(${icon_back_gray});
    background-size: 18px 15px;
    background-repeat: no-repeat;
    width: 18px;
    height: 15px;
    margin-right: 10px;
  }
  & > p {
    opacity: 80%;
    color: #555555;
    font-size: 14px;
    line-height: 32px;
  }

  &:hover {
    & > div#backIcon {
      background-image: url(${icon_back_green});
    }
  }
`

export default SignContainer(withTranslation()(ChangePw))
