// 자동환경설정(복합제어) 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import { AutoEnvKindList } from "../../../utils/data"

import AutoEnvSettingConfContainerComp from "../../../containers/envControl/AutoEnvSettingConfContainerComp"
import CommonContainer from "../../../containers/CommonContainer"

import RequiredMark from "../../component/RequiredMark"
import ComboBox from "../../component/common/ComboBox"
import InputBox from "../../component/common/InputBox"
import AlertPopup from "../AlertPopup"
import DateBox from "../../component/common/DateBox"
import InputTimeBox from "../../component/common/InputTimeBox"
import MultiComboBox from "../../component/common/MultiComboBox"
import TableCheckBox from "../../component/common/TableCheckBox"
import TextAreaBox from "../../component/common/TextAreaBox"

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        maxWidth: "600px",
        margin: "0 auto",
        "& .MuiDialog-paperFullWidth": {
            width: "100%",
        },
        "& .MuiPaper-root": {
            backgroundColor: "#fff",
        },
        "& .MuiDialog-paper": {
            margin: "0px",
        },
    },
}))

const AutoEnvConfAddPopupComp = (props) => {
    const classes = useStyles()

    const {
        t,
        initDataList,
        getDataList,
        saveDataList,
        setDataList,
        _codeList,
        _comboEquipList,
        getComboList,
        initTranslationWords,
        getTranslationWords,
        _translationWords,
        replaceWord,
    } = props

    const { sensorComboList } = props

    const { confInfoComp: dataInfo, GET_CONF_INFOComp: actionType, ADD_CONF_INFOComp, MOD_CONF_INFOComp, equipConfCompSeq, equipSeq } = props

    const { onClose, open } = props
    const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
    const [sensorOptions, setSensorOptions] = useState([]) //센서명 멀티
    const dict = _translationWords[0] // 변수에 사전 담기

    const [minDate, setMinDate] = useState(new Date()) // 날짜 최소일
    const [maxDate, setMaxDate] = useState(new Date()) // 날짜 최대일

    const setDataInfo = (data) => {
        setDataList({ confInfoComp: data })
    }

    // -김한중
    const setPrioritySerl = (value) => {
        setDataInfo({ ...dataInfo, prioritySerl: value })
    } // 기본조건우선순위
    const setPrioritySubSerl = (value) => {
        setDataInfo({ ...dataInfo, prioritySubSerl: value })
    } // 보조조건우선순위
    const setStandardKind = (value) => {
        setDataInfo({ ...dataInfo, standardKind: value })
    } // 조건기준
    const setSensorType = (value) => {
        setDataInfo({ ...dataInfo, sensorType: value })
    } // 센서타입
    const setDateFr = (value) => {
        setDataInfo({ ...dataInfo, dateFr: value })
    } // 시작날짜
    const setDateTo = (value) => {
        setDataInfo({ ...dataInfo, dateTo: value })
    } // 종료날짜
    const setTimeFr = (value) => {
        setDataInfo({ ...dataInfo, timeFr: value })
    } // 시작시간
    const setTimeTo = (value) => {
        setDataInfo({ ...dataInfo, timeTo: value })
    } // 종료시간
    const setActionKindSensor = (value) => {
        setDataInfo({ ...dataInfo, actionKindSensor: value })
    } // 센서작동조건
    const setValueFr = (value) => {
        setDataInfo({ ...dataInfo, valueFr: value })
    } // 시작값
    const setValueTo = (value) => {
        setDataInfo({ ...dataInfo, valueTo: value })
    } // 종료값
    const setActionKindEquip = (value) => {
        setDataInfo({ ...dataInfo, actionKindEquip: value })
    } // 설비작동조건
    const setActionStatus = (value) => {
        setDataInfo({ ...dataInfo, actionStatus: value })
    } // 설비상태
    const setDEquipOpenPer = (value) => {
        setDataInfo({ ...dataInfo, dEquipOpenPer: value })
    } // 작동조건 설비의 열림정도 조건값
    const setActionKindOpenPer = (value) => {
        setDataInfo({ ...dataInfo, actionKindOpenPer: value })
    } // 열림정도이상이하조건
    const setOpenPerSet = (value) => {
        setDataInfo({ ...dataInfo, openPerSet: value })
    } // 작동정도
    const setOnOff = (value) => {
        setDataInfo({ ...dataInfo, onOff: value })
    } // 작동방식
    const setDelayTime = (value) => {
        setDataInfo({ ...dataInfo, delayTime: value })
    } // 대기시간
    const setDelayCycle = (value) => {
        setDataInfo({ ...dataInfo, delayCycle: value })
    } // 대기주기
    const setRemark = (value) => {
        setDataInfo({ ...dataInfo, remark: value })
    } // 비고
    const setUseYN = (value) => {
        setDataInfo({ ...dataInfo, useYN: value })
    } // 사용여부
    const setIsTest = (value) => {
        setDataInfo({ ...dataInfo, isTest: value })
    } // 테스트여부

    const setConditionEquipSeq = (value) => {
        setDataInfo({ ...dataInfo, conditionEquipSeq: value })
    } // 조건설비코드
    const setConditionSensorSeq = (value) => {
        setDataInfo({ ...dataInfo, conditionSensorSeq: value })
    } // 조건센서코드

    useEffect(() => {
        if (open) {
            getTranslationWords()
            getData() //팝업 오픈시 데이터 불러오기
            // setSun(0)
        }

        return async () => {
            await initDataList(actionType)
        }
    }, [open])

    const getData = async () => {
        const data = { equipConfCompSeq, equipSeq: equipSeq } // 상세 정보 불러오기(파라미터)

        await getDataList(actionType, data) //데이터 불러오기

        //  await getComboList({ queryKind: "Equip" })
        await getComboList({ queryKind: "Sensor" }) //센서명 가져오는거

        // 신규추가시 해당 조건 데이터 기본 set값
        if (equipConfCompSeq === 0) {
            //   setDataList({ confInfoComp: {
            //   prioritySerl      : 0,
            //   prioritySubSerl   : 0,
            //   //standardKind      : 0,
            //   //sensorType        : 0,
            //   dateFr            : "",
            //   dateTo            : "",
            //   //timeFr            : 0,
            //   //timeTo            : 0,
            //   //actionKindSensor  : 0,
            //   //valueFr           : 0,
            //   //valueTo           : 0,
            //   //actionKindEquip   : 0,
            //   //actionStatus      : 0,
            //   //dEquipOpenPer     : 0,
            //   //actionKindOpenPer : 0,
            //   //openPerSet        : 0,
            //   //onOff             : 0,
            //   //delayTime         : 0,
            //   //delayCycle        : 0,
            //    useYN              : 1,  //사용여부
            //    isTest             : 0,  //테스트여부
            // } })

            setSensorOptions([]) //센서명 멀티 조건 비우기
            // setSun(0) //일출,일몰 체크박스
        }
    }

    //수정시 센서명 불러오는 구문(멀티)
    useEffect(() => {
        if (
            dataInfo.conditionSensorSeq != undefined &&
            dataInfo.conditionSensorSeq != null &&
            dataInfo.standardKind != 10150011 &&
            dataInfo.standardKind != 10150013
        ) {
            let sensorComboListtest = sensorComboList.map((i) => ({ value: i.sensorSeq, label: i.sensorNodeName + " - " + i.sensorName }))

            let SensorSeqlist = []
            const conditionSensorSeqlist = dataInfo.conditionSensorSeq
            const conditionSensorSeqlistcopy = conditionSensorSeqlist.split(",")

            for (let j = 0; j < conditionSensorSeqlistcopy.length; j++) {
                if (sensorComboListtest.filter((i) => conditionSensorSeqlistcopy[j] == i.value).length > 0) {
                    SensorSeqlist.push(sensorComboListtest.filter((i) => conditionSensorSeqlistcopy[j] == i.value)[0])
                }
            }
            setSensorOptions(SensorSeqlist)
        }

        // console.log("김한중@@@@")
        // console.log(dataInfo.conditionSensorSeq, "dataInfo.conditionSensorSeq")
        // console.log(sensorOptions, "sensorOptions")
        // console.log(sensorComboList, "sensorComboList")

        return () => {
            setSensorOptions([])
        }
    }, [dataInfo.conditionSensorSeq && sensorComboList])

    // 시작일 선택시 종료일이 시작일보다 앞으로 달력선택 막음
    useEffect(() => {
        if (dataInfo.dateFr != undefined && dataInfo.dateFr != "" && dataInfo.dateFr != null) {
            // dataInfo.dateFr에 값이 있는경우 조건시작

            if (parseInt(dataInfo.dateFr) > parseInt(dataInfo.dateTo)) {
                // 시작일보다 종료일이 빠를시 시작일로 셋팅
                setDateFr(dataInfo.dateFr) // 시작일 기준으로 통일
                setDateTo(dataInfo.dateFr) // 시작일 기준으로 통일
            }

            // 날짜를 10000일 더하기 위해 날짜 객체를 복사합니다
            const originalDate = new Date(dataInfo.dateFr.substr(0, 4) + "-" + dataInfo.dateFr.substr(4, 2) + "-" + dataInfo.dateFr.substr(6, 2))
            const modifiedDate = originalDate.setDate(originalDate.getDate() + 365000)

            setMinDate(new Date(dataInfo.dateFr.substr(0, 4) + "-" + dataInfo.dateFr.substr(4, 2) + "-" + dataInfo.dateFr.substr(6, 2))) //날짜 최저일
            setMaxDate(modifiedDate) // 날짜 최대일
            // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
            // console.log('dateFr(1) : ', dateFr)
            // console.log('dateTo(1) : ', dateTo)
        }
    }, [dataInfo.dateFr])

    //-김한중 시간(timeFr)
    const onSelectactionTimeFr = async (value) => {
        let data = { ...dataInfo, timeFr: value }

        if (value == "0000") data = { ...data, timeTo: "2359" } // 0000 으로 timeFr 값 넣을시 자동 timeTo 2359

        setDataInfo(data)
    }

    //-김한중 작동조건(센서)
    const onSelectactionKindSensor = async (value) => {
        let data = { ...dataInfo, actionKindSensor: value }

        if (value == 0) data = { ...data, valueFr: undefined, valueTo: undefined }
        else if (value == 2) data = { ...data, valueFr: undefined }
        else if (value == 3) data = { ...data, valueTo: undefined }
        else if (value == 4) data = { ...data, openPerSet: undefined, onOff: undefined }

        setDataInfo(data)
    }

    //-김한중 작동조건(설비)
    const onSelectactionKindEquip = async (value) => {
        let data = { ...dataInfo, actionKindEquip: value }

        if (value == 1) data = { ...data, dEquipOpenPer: undefined, actionKindOpenPer: undefined }
        else if (value == 2) data = { ...data, actionStatus: undefined }

        setDataInfo(data)
    }

    //-김한중 작동조건(설비)
    const onSelectconditionEquipSeq = async (value) => {
        let data = { ...dataInfo, conditionEquipSeq: value }

        if (_comboEquipList.filter((i) => i.equipSeq == value).map((i) => i.workKind) == 10180001) data = { ...data, actionKindEquip: 1 }
        else if (_comboEquipList.filter((i) => i.equipSeq == value).map((i) => i.workKind) == 10180002) data = { ...data, actionKindEquip: 2 }
        else data = { ...data, actionKindEquip: undefined }

        setDataInfo(data)
    }

    //-김한중  조건기준(바뀔시 밑에 항목들 리셋)
    const onSelectstandardKind = async (value) => {
        let data = { ...dataInfo, standardKind: value }

        if (String(value).substr(0, 4) == 1024) {
            ;(data = { ...data, actionKindSensor: undefined, valueFr: undefined, valueTo: undefined, sensorType: undefined }), setSensorOptions([])
        } //설비
        else if (String(value).substr(0, 4) == 1015) {
            ;(data = {
                ...data,
                actionKindSensor: undefined,
                valueFr: undefined,
                valueTo: undefined,
                conditionEquipSeq: undefined,
                actionKindEquip: undefined,
                actionStatus: undefined,
                dEquipOpenPer: undefined,
                actionKindOpenPer: undefined,
                sensorType: undefined,
            }),
                setSensorOptions([])
        } //온도
        else if (String(value).substr(0, 4) == 1025) {
            ;(data = {
                ...data,
                actionKindSensor: undefined,
                valueFr: undefined,
                valueTo: undefined,
                sensorType: undefined,
                timeFr: undefined,
                timeTo: undefined,
                conditionEquipSeq: undefined,
                actionKindEquip: undefined,
                actionStatus: undefined,
                dEquipOpenPer: undefined,
                actionKindOpenPer: undefined,
            }),
                setSensorOptions([])
        } //시간,일출,일몰
        //else if ( value == 10250002 || value == 10250003) {data = { ...data, actionKindSensor : undefined, valueFr : undefined, valueTo : undefined, sensorType : undefined, timeFr : undefined,
        //                                                conditionEquipSeq : undefined, actionKindEquip : undefined, actionStatus : undefined, dEquipOpenPer : undefined, actionKindOpenPer : undefined, sensorType : undefined,} ,setSensorOptions([])} //일출,일몰
        setDataInfo(data)
    }

    const onClickSave = async () => {
        // - 필수입력값 안할시 팝업 START
        if (dataInfo.prioritySerl === undefined || dataInfo.prioritySerl === 0 || dataInfo.prioritySerl == null)
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["basicRanking"]) })
        //기본순위
        else if (dataInfo.standardKind === undefined || dataInfo.standardKind === 0 || dataInfo.standardKind == null)
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["standardKind"]) })
        //조건기준
        else if (dataInfo.dateFr === undefined || dataInfo.dateFr.length < 8)
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["days"]) })
        //날짜
        else if (dataInfo.dateTo === undefined || dataInfo.dateTo.length < 8)
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["days"]) })
        //날짜
        else if (
            dataInfo.prioritySubSerl === 0 &&
            (dataInfo.timeFr === undefined || dataInfo.timeFr == null || dataInfo.timeFr.length < 4) &&
            (String(dataInfo.standardKind).substr(0, 4) == 1015 ||
                String(dataInfo.standardKind).substr(0, 4) == 1024 ||
                dataInfo.standardKind == 10250001)
        )
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["time"]) })
        //시간
        else if (
            dataInfo.prioritySubSerl === 0 &&
            (dataInfo.timeTo === undefined || dataInfo.timeTo == null || dataInfo.timeTo.length < 4) &&
            (String(dataInfo.standardKind).substr(0, 4) == 1015 ||
                String(dataInfo.standardKind).substr(0, 4) == 1024 ||
                dataInfo.standardKind == 10250001)
        )
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["time"]) })
        //시간
        else if (
            dataInfo.prioritySubSerl === 0 &&
            (dataInfo.timeTo === undefined || dataInfo.timeTo == null || dataInfo.timeTo == 0) &&
            (dataInfo.standardKind == 10250002 || dataInfo.standardKind == 10250003)
        )
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["time"] + dict["settings"]) })
        //일출,일몰경우에만 시간
        // else if ((String(dataInfo.standardKind).substr(0,4) == 1015 || dataInfo.standardKind == 10250002 || dataInfo.standardKind == 10250003) && (dataInfo.sensorType === undefined || dataInfo.sensorType === 0 || dataInfo.sensorType == null))
        // return setAlertPopup({ open: true, text: replaceWord(dict['n.Invalid value. Please try again.'], dict['sensorKind']) })  //센서종류
        // else if ((String(dataInfo.standardKind).substr(0,4) == 1015 || dataInfo.standardKind == 10250002 || dataInfo.standardKind == 10250003) && (sensorOptions.length == 0 || sensorOptions.length == undefined || sensorOptions.length == null))
        // return setAlertPopup({ open: true, text: replaceWord(dict['n.Invalid value. Please try again.'], dict['sensorName']) })  //센서명
        else if (
            String(dataInfo.standardKind).substr(0, 4) == 1015 &&
            (dataInfo.sensorType === undefined || dataInfo.sensorType === 0 || dataInfo.sensorType == null)
        )
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["sensorKind"]) })
        //센서종류
        else if (
            String(dataInfo.standardKind).substr(0, 4) == 1015 &&
            dataInfo.standardKind !== 10150011 &&
            dataInfo.standardKind !== 10150013 &&
            (sensorOptions.length == 0 || sensorOptions.length == undefined || sensorOptions.length == null)
        )
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["sensorName"]) })
        //센서명(다중)
        else if (
            (dataInfo.standardKind == 10150011 || dataInfo.standardKind == 10150013) &&
            (dataInfo.conditionSensorSeq === undefined || dataInfo.conditionSensorSeq === 0 || dataInfo.conditionSensorSeq == null)
        )
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["sensorName"]) })
        //센서명(단일)
        else if (
            String(dataInfo.standardKind).substr(0, 4) == 1024 &&
            (dataInfo.conditionEquipSeq === undefined || dataInfo.conditionEquipSeq === 0 || dataInfo.conditionEquipSeq == null)
        )
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["facilities"] + dict["type"]) })
        //설비종류
        else if (
            String(dataInfo.standardKind).substr(0, 4) == 1015 &&
            dataInfo.standardKind !== 10150011 &&
            dataInfo.standardKind !== 10150013 &&
            (dataInfo.actionKindSensor == undefined || dataInfo.actionKindSensor == 0)
        )
            return setAlertPopup({
                open: true,
                text: replaceWord(dict["n.Invalid value. Please try again."], dict["operatingConditions"] + "(" + dict["sensor"] + ")"),
            })
        //작동조건(센서)
        else if (
            String(dataInfo.standardKind).substr(0, 4) == 1015 &&
            dataInfo.standardKind !== 10150011 &&
            dataInfo.standardKind !== 10150013 &&
            (dataInfo.actionKindSensor == 1 || dataInfo.actionKindSensor == 3 || dataInfo.actionKindSensor == 4) &&
            (dataInfo.valueFr == undefined || dataInfo.valueFr === "")
        )
            return setAlertPopup({
                open: true,
                text: replaceWord(
                    dict["n.Invalid value. Please try again."],
                    dict["operatingConditions"] + "(" + dict["sensor"] + ")" + dict["value"]
                ),
            })
        //작동조건(센서)
        else if (
            String(dataInfo.standardKind).substr(0, 4) == 1015 &&
            dataInfo.standardKind !== 10150011 &&
            dataInfo.standardKind !== 10150013 &&
            (dataInfo.actionKindSensor == 1 || dataInfo.actionKindSensor == 2 || dataInfo.actionKindSensor == 4) &&
            (dataInfo.valueTo == undefined || dataInfo.valueTo === "")
        )
            return setAlertPopup({
                open: true,
                text: replaceWord(
                    dict["n.Invalid value. Please try again."],
                    dict["operatingConditions"] + "(" + dict["sensor"] + ")" + dict["value"]
                ),
            })
        //작동조건(센서)
        else if (
            (dataInfo.standardKind == 10150011 || dataInfo.standardKind == 10150013) &&
            (dataInfo.valueFr == undefined || dataInfo.valueFr === "" || dataInfo.valueFr == 0)
        )
            return setAlertPopup({
                open: true,
                text: replaceWord(
                    dict["n.Invalid value. Please try again."],
                    dict["operatingConditions"] + "(" + dict["sensor"] + ")" + dict["value"]
                ),
            })
        //작동조건(센서) 감우,수위센서(접점)
        else if (String(dataInfo.standardKind).substr(0, 4) == 1024 && (dataInfo.actionKindEquip == undefined || dataInfo.actionKindEquip == 0))
            return setAlertPopup({
                open: true,
                text: replaceWord(dict["n.Invalid value. Please try again."], dict["operatingConditions"] + "(" + dict["facilities"] + ")"),
            })
        //작동조건(설비)
        else if (
            String(dataInfo.standardKind).substr(0, 4) == 1024 &&
            dataInfo.actionKindEquip == 1 &&
            (dataInfo.actionStatus == undefined || dataInfo.actionStatus == null || dataInfo.actionStatus == 0)
        )
            return setAlertPopup({
                open: true,
                text: replaceWord(dict["n.Invalid value. Please try again."], dict["operatingConditions"] + "(" + dict["facilities"] + ")"),
            })
        //작동조건(설비)
        else if (
            String(dataInfo.standardKind).substr(0, 4) == 1024 &&
            dataInfo.actionKindEquip == 2 &&
            (dataInfo.dEquipOpenPer == undefined || dataInfo.dEquipOpenPer == null)
        )
            return setAlertPopup({
                open: true,
                text: replaceWord(dict["n.Invalid value. Please try again."], dict["operatingConditions"] + "(" + dict["facilities"] + ")"),
            })
        //작동조건(설비)
        else if (
            String(dataInfo.standardKind).substr(0, 4) == 1024 &&
            dataInfo.actionKindEquip == 2 &&
            (dataInfo.actionKindOpenPer == undefined || dataInfo.actionKindOpenPer == null || dataInfo.actionKindOpenPer == 0)
        )
            return setAlertPopup({
                open: true,
                text: replaceWord(dict["n.Invalid value. Please try again."], dict["operatingConditions"] + "(" + dict["facilities"] + ")"),
            })
        //작동조건(설비)
        else if (
            _comboEquipList.filter((i) => i.equipSeq == equipSeq).map((i) => i.workKind) == 10180002 &&
            (dataInfo.openPerSet === undefined ||
                dataInfo.openPerSet === null ||
                dataInfo.openPerSet === "" ||
                (_comboEquipList.filter((i) => i.equipSeq == equipSeq).map((i) => i.isNotUseOpenPer) == 1 && dataInfo.openPerSet === 0)) &&
            dataInfo.actionKindSensor != 4
        )
            return setAlertPopup({
                open: true,
                text: replaceWord(
                    dict["n.Invalid value. Please try again."],
                    _comboEquipList.filter((i) => i.equipSeq == equipSeq).map((i) => i.isNotUseOpenPer) == 1 ? "냉방/난방" : dict["operationDegree"]
                ),
            })
        //작동정도
        else if (
            _comboEquipList.filter((i) => i.equipSeq == equipSeq).map((i) => i.workKind) == 10180001 &&
            (dataInfo.onOff === undefined || dataInfo.onOff === null || dataInfo.onOff === 0) &&
            dataInfo.actionKindSensor != 4
        )
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["workKind"]) }) //작동방식
        // - 필수입력값 안할시 팝업 END

        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        // console.log((_codeList))
        // console.log('<<<<<<<<<<<<<<<<<<<<<<<<<김한중 테스트')
        // console.log(_comboEquipList.filter((i) => i.equipSeq == dataInfo.conditionEquipSeq).map( i => i.workKind) == 10180002 ? 1 : 2)

        let data = {
            ...dataInfo,
            //equipConfCompSeq,
            equipConfCompSeq: String(equipConfCompSeq).substr(0, 1) == "-" ? 0 : equipConfCompSeq,
            equipSeq,
            dateFr: dataInfo.dateFr,
            dateTo: dataInfo.dateTo,
            valueFr: dataInfo.valueFr,
            valueTo: dataInfo.valueTo,
            timeFr: dataInfo.timeFr == "" ? null : dataInfo.timeFr,
            timeTo: dataInfo.timeTo == "" ? null : dataInfo.timeTo,
            conditionSensorSeq:
                String(dataInfo.standardKind).substr(0, 4) == 1024
                    ? []
                    : dataInfo.standardKind == 10250001
                    ? []
                    : dataInfo.standardKind == 10150011 || dataInfo.standardKind == 10150013
                    ? [dataInfo.conditionSensorSeq]
                    : sensorOptions.map((i) => i.value),

            dEquipOpenPer: dataInfo.dEquipOpenPer === "" ? null : dataInfo.dEquipOpenPer,
            openPerSet: dataInfo.openPerSet === "" ? null : dataInfo.openPerSet,
            delayTime: dataInfo.delayTime === "" ? null : dataInfo.delayTime,
            delayCycle: dataInfo.delayCycle === "" ? null : dataInfo.delayCycle,
            remark: dataInfo.remark === "" ? null : dataInfo.remark,
        }

        if (data.equipConfCompSeq > 0) {
            // 수정

            if (await saveDataList(MOD_CONF_INFOComp, data)) onClose()
        } else {
            // 추가

            if (await saveDataList(ADD_CONF_INFOComp, data)) onClose()
        } // end if
    }

    return (
        <Dialog
            className={classes.container}
            style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
            onClose={() => onClose(null)}
            open={open}
            fullWidth={true}
        >
            <AlertPopup
                bg={false}
                open={alertPopup.open}
                onClose={() => setAlertPopup({ open: false, text: alertPopup.text })}
                content={alertPopup.text}
            />

            <Container>
                <Title>{dict["conditionControl"] + "(" + dict["complex"] + ")"}</Title>
                {/* <div style={{ flex: 1 }}>      */}

                {/* 기본순위 */}
                {/* eslint-disable-next-line no-constant-condition */}
                <FlexBox2 display={1 == 2 ? "none" : "flex"}>
                    <Label>
                        <RequiredMark /> {dict["basicRanking"]}
                    </Label>
                    <InputBox
                        style={{ width: "50px", marginRight: 0 }}
                        type="number"
                        value={dataInfo.prioritySerl}
                        onChange={(e) => setPrioritySerl(e.target.value)}
                        disabled={dataInfo.prioritySubSerl !== 0}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {/* 보조순위 */}
                    <Label2>
                        <RequiredMark /> {dict["subRanking"]}
                    </Label2>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <InputBox
                        style={{ width: "50px", marginRight: 0 }}
                        type="number"
                        value={dataInfo.prioritySubSerl}
                        onChange={(e) => setPrioritySubSerl(e.target.value)}
                        disabled={dataInfo.prioritySubSerl === 0}
                    />
                    {/* visibility: 1==1 ? "hidden" : "visible" */}
                </FlexBox2>

                {/* 조건기준 */}
                <FlexBox>
                    <Label>
                        <RequiredMark /> {dict["standardKind"]}
                    </Label>
                    <ComboBox
                        style={{ width: "200px" }}
                        defaultValue={dict["select"]}
                        hasDefault={true}
                        items={
                            _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1015).length > 0
                                ? (
                                      (_codeList || []).filter((i) => i.majorSeq == 1025 && dataInfo.prioritySubSerl == 0)[0] || { minorList: [] }
                                  ).minorList
                                      .map((i) => ({ value: i.minorSeq, label: i.minorName }))
                                      .concat(
                                          ((_codeList || []).filter((i) => i.majorSeq == 1024)[0] || { minorList: [] }).minorList.map((i) => ({
                                              value: i.minorSeq,
                                              label: i.minorName,
                                          }))
                                      )
                                      // .concat(((_codeList || []).filter((i) => i.majorSeq == 1015)[0] || { minorList: [] }).minorList.filter((i) => i.minorSeq != 10150011).map((i) => ({value: i.minorSeq, label: i.minorName,})))
                                      .concat(
                                          ((_codeList || []).filter((i) => i.majorSeq == 1015)[0] || { minorList: [] }).minorList.map((i) => ({
                                              value: i.minorSeq,
                                              label: i.minorName,
                                          }))
                                      )
                                : []
                        }
                        value={dataInfo.standardKind}
                        setValue={onSelectstandardKind}
                    />
                </FlexBox>

                {/* 날짜 */}
                <FlexBox>
                    <Label>
                        <RequiredMark /> {dict["days"]}
                    </Label>
                    <DateBox
                        style={{ width: "120px", marginRight: 0, zIndex: 99 }}
                        stdDate={dataInfo.dateFr}
                        setStdDate={setDateFr}
                        disabled={dataInfo.prioritySubSerl !== 0}
                    />
                    &nbsp;&nbsp;<Label2>~</Label2>&nbsp;&nbsp;
                    <DateBox
                        style={{ width: "120px", marginRight: 0, zIndex: 99 }}
                        stdDate={dataInfo.dateTo}
                        setStdDate={setDateTo}
                        disabled={dataInfo.prioritySubSerl !== 0}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                </FlexBox>

                {/* 시간 */}
                <FlexBox>
                    <Label>
                        <RequiredMark /> {dict["time"]}
                    </Label>
                    <InputTimeBox
                        style={{ width: "120px", marginRight: 0 }}
                        disabled={
                            dataInfo.prioritySubSerl !== 0 || String(dataInfo.standardKind) == 10250002 || String(dataInfo.standardKind) == 10250003
                        }
                        value={dataInfo.timeFr}
                        setValue={onSelectactionTimeFr}
                    />
                    &nbsp;&nbsp;<Label2>~</Label2>&nbsp;&nbsp;
                    <InputTimeBox
                        style={{ width: "120px", marginRight: 0 }}
                        disabled={
                            dataInfo.prioritySubSerl !== 0 || String(dataInfo.standardKind) == 10250002 || String(dataInfo.standardKind) == 10250003
                        }
                        value={dataInfo.timeTo}
                        setValue={setTimeTo}
                    />
                </FlexBox>

                {/* 일출일몰시 시간 */}
                <FlexBox2
                    display={
                        (dataInfo.standardKind == 10250002 && dataInfo.prioritySubSerl === 0) ||
                        (dataInfo.standardKind == 10250003 && dataInfo.prioritySubSerl === 0)
                            ? "flex"
                            : "none"
                    }
                >
                    <Label>
                        <RequiredMark /> {dict["time"] + dict["settings"]}
                    </Label>
                    <ComboBox
                        style={{ width: "130px", marginRight: 0 }}
                        defaultValue={dict["select"]}
                        hasDefault={true}
                        items={[
                            { value: "1", label: "+1" + dict["time"] },
                            { value: "2", label: "+2" + dict["time"] },
                            { value: "3", label: "+3" + dict["time"] },
                            { value: "10", label: dataInfo.standardKind == 10250002 ? dict["lightTo"] : dict["lightFr"] },
                        ]}
                        value={dataInfo.timeTo}
                        setValue={setTimeTo}
                    />
                </FlexBox2>

                {/* 센서종류 */}
                {/* <FlexBox2 display={String(dataInfo.standardKind).substr(0,4) == 1024 ? 'none' : dataInfo.standardKind == 10250001 ? 'none': 'flex'}> */}
                <FlexBox2
                    display={
                        String(dataInfo.standardKind).substr(0, 4) == 1024
                            ? "none"
                            : String(dataInfo.standardKind).substr(0, 4) == 1025
                            ? "none"
                            : "flex"
                    }
                >
                    <Label>
                        <RequiredMark /> {dict["sensorKind"]}
                    </Label>
                    <ComboBox
                        style={{ width: "200px", marginRight: 0 }}
                        defaultValue={dict["select"]}
                        hasDefault={true}
                        items={
                            String(dataInfo.standardKind).substr(0, 4) == 1015 ||
                            dataInfo.standardKind == 10250002 ||
                            dataInfo.standardKind == 10250003
                                ? ((_codeList || []).filter((i) => i.majorSeq == 1023)[0] || { minorList: [] }).minorList.map((i) => ({
                                      value: i.minorSeq,
                                      label: i.minorName,
                                  }))
                                : []
                        }
                        value={dataInfo.sensorType}
                        setValue={setSensorType}
                    />
                </FlexBox2>

                {/* 센서명 */}
                {/* <FlexBox2 display={String(dataInfo.standardKind).substr(0,4) == 1024 ? 'none' : String(dataInfo.standardKind).substr(0,4) == 1025 ? 'none': 'flex'}> */}
                <FlexBox2
                    display={
                        String(dataInfo.standardKind).substr(0, 4) == 1024
                            ? "none"
                            : String(dataInfo.standardKind).substr(0, 4) == 1025
                            ? "none"
                            : dataInfo.standardKind == 10150011
                            ? "none"
                            : dataInfo.standardKind == 10150013
                            ? "none"
                            : "flex"
                    }
                >
                    <Label>
                        <RequiredMark /> {dict["sensorName"]}
                    </Label>
                    <MultiComboBox
                        style={{ width: "350px", marginRight: 0, zIndex: 90 }}
                        // placeholder={dict['multiSelect']}
                        items={sensorComboList
                            .filter(
                                (i) =>
                                    i.sensorKind ==
                                        (dataInfo.standardKind == 10250002 || dataInfo.standardKind == 10250003 ? 10150010 : dataInfo.standardKind) &&
                                    i.sensorType == dataInfo.sensorType
                            )
                            .map((i) => ({ value: i.sensorSeq, label: i.sensorNodeName + " - " + i.sensorName }))}
                        value={sensorOptions}
                        setValue={setSensorOptions}
                    />
                </FlexBox2>

                {/* 센서명 단일선택*/}
                {/* <FlexBox2 display={1==1 ? "flex" : "none"}> */}
                <FlexBox2 display={dataInfo.standardKind == 10150011 ? "flex" : dataInfo.standardKind == 10150013 ? "flex" : "none"}>
                    <Label>
                        <RequiredMark /> {dict["sensorName"]}
                    </Label>
                    <ComboBox
                        style={{ width: "350px", marginRight: 0, zIndex: 90 }}
                        // placeholder={dict['multiSelect']}
                        items={sensorComboList
                            .filter(
                                (i) =>
                                    i.sensorKind ==
                                        (dataInfo.standardKind == 10250002 || dataInfo.standardKind == 10250003 ? 10150010 : dataInfo.standardKind) &&
                                    i.sensorType == dataInfo.sensorType
                            )
                            .map((i) => ({ value: i.sensorSeq, label: i.sensorNodeName + " - " + i.sensorName }))}
                        value={dataInfo.conditionSensorSeq}
                        setValue={setConditionSensorSeq}
                    />
                </FlexBox2>

                {/* 설비종류 */}
                <FlexBox2
                    display={
                        String(dataInfo.standardKind).substr(0, 4) == 1015
                            ? "none"
                            : String(dataInfo.standardKind).substr(0, 4) == 1025
                            ? "none"
                            : "flex"
                    }
                >
                    <Label
                        style={{
                            visibility:
                                String(dataInfo.standardKind).substr(0, 4) == 1015
                                    ? "hidden"
                                    : String(dataInfo.standardKind).substr(0, 4) == 1025
                                    ? "hidden"
                                    : "visible",
                        }}
                    >
                        <RequiredMark /> {dict["facilities"] + dict["type"]}
                    </Label>
                    <ComboBox
                        style={{
                            width: "200px",
                            marginRight: 0,
                            visibility:
                                String(dataInfo.standardKind).substr(0, 4) == 1015
                                    ? "hidden"
                                    : String(dataInfo.standardKind).substr(0, 4) == 1025
                                    ? "hidden"
                                    : "visible",
                        }}
                        defaultValue={dict["select"]}
                        hasDefault={true}
                        items={_comboEquipList.filter((i) => i.isNotUseOpenPer != 1).map((i) => ({ value: i.equipSeq, label: i.equipName }))}
                        value={dataInfo.conditionEquipSeq}
                        setValue={onSelectconditionEquipSeq}
                    />
                </FlexBox2>

                {/* 작동조건(센서) */}
                <FlexBox2
                    display={
                        String(dataInfo.standardKind).substr(0, 4) == 1024
                            ? "none"
                            : String(dataInfo.standardKind).substr(0, 4) == 1025
                            ? "none"
                            : dataInfo.standardKind == 10150011
                            ? "none"
                            : dataInfo.standardKind == 10150013
                            ? "none"
                            : "flex"
                    }
                >
                    <Label
                        style={{
                            visibility:
                                String(dataInfo.standardKind).substr(0, 4) == 1024
                                    ? "hidden"
                                    : String(dataInfo.standardKind).substr(0, 4) == 1025
                                    ? "hidden"
                                    : "visible",
                        }}
                    >
                        <RequiredMark /> {dict["operatingConditions"] + "(" + dict["sensor"] + ")"}
                    </Label>
                    <ComboBox
                        style={{
                            width: "130px",
                            marginRight: 0,
                            visibility:
                                String(dataInfo.standardKind).substr(0, 4) == 1024
                                    ? "hidden"
                                    : String(dataInfo.standardKind).substr(0, 4) == 1025
                                    ? "hidden"
                                    : "visible",
                        }}
                        defaultValue={dict["select"]}
                        hasDefault={true}
                        items={[
                            { value: 1, label: dict["between"] }, // 사이
                            { value: 2, label: dict["below"] }, // 이하
                            { value: 3, label: dict["moreThan"] }, // 이상
                            { value: 4, label: dict["maintenance"] }, // 유지
                        ]}
                        value={dataInfo.actionKindSensor}
                        setValue={onSelectactionKindSensor}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {/* 작동조건(센서) 입력값 1 */}
                    <InputBox
                        style={{
                            width: "85px",
                            marginRight: 0,
                            visibility:
                                String(dataInfo.standardKind).substr(0, 4) == 1024
                                    ? "hidden"
                                    : String(dataInfo.standardKind).substr(0, 4) == 1025
                                    ? "hidden"
                                    : "visible",
                        }}
                        type="number"
                        value={dataInfo.valueFr}
                        onChange={(e) => setValueFr(e.target.value)}
                        disabled={dataInfo.actionKindSensor === undefined || dataInfo.actionKindSensor == 0 || dataInfo.actionKindSensor == 2}
                    />
                    &nbsp;&nbsp;
                    <Label2
                        style={{
                            visibility:
                                String(dataInfo.standardKind).substr(0, 4) == 1024
                                    ? "hidden"
                                    : String(dataInfo.standardKind).substr(0, 4) == 1025
                                    ? "hidden"
                                    : "visible",
                        }}
                    >
                        ~
                    </Label2>
                    &nbsp;&nbsp;
                    {/* 작동조건(센서) 입력값 2 */}
                    <InputBox
                        style={{
                            width: "85px",
                            marginRight: 0,
                            visibility:
                                String(dataInfo.standardKind).substr(0, 4) == 1024
                                    ? "hidden"
                                    : String(dataInfo.standardKind).substr(0, 4) == 1025
                                    ? "hidden"
                                    : "visible",
                        }}
                        type="number"
                        value={dataInfo.valueTo}
                        onChange={(e) => setValueTo(e.target.value)}
                        disabled={dataInfo.actionKindSensor === undefined || dataInfo.actionKindSensor == 0 || dataInfo.actionKindSensor == 3}
                    />
                </FlexBox2>

                {/* 작동조건(센서) 입력값 감우, 수위센서(접점) 일시 */}
                <FlexBox2 display={dataInfo.standardKind == 10150011 ? "flex" : dataInfo.standardKind == 10150013 ? "flex" : "none"}>
                    <Label style={{ visibility: dataInfo.standardKind == 10150011 ? "flex" : dataInfo.standardKind == 10150013 ? "flex" : "none" }}>
                        <RequiredMark /> {dict["operatingConditions"] + "(" + dict["sensor"] + ")"}
                    </Label>
                    <ComboBox
                        style={{
                            width: "130px",
                            marginRight: 0,
                            visibility:
                                String(dataInfo.standardKind).substr(0, 4) == 1024
                                    ? "hidden"
                                    : String(dataInfo.standardKind).substr(0, 4) == 1025
                                    ? "hidden"
                                    : "visible",
                        }}
                        defaultValue={dict["select"]}
                        hasDefault={true}
                        items={[
                            { value: 1, label: "N" },
                            { value: 2, label: "Y" },
                        ]}
                        value={dataInfo.valueFr}
                        setValue={setValueFr}
                    />
                </FlexBox2>

                {/* 작동조건(설비) */}
                <FlexBox2
                    display={
                        String(dataInfo.standardKind).substr(0, 4) == 1015
                            ? "none"
                            : String(dataInfo.standardKind).substr(0, 4) == 1025
                            ? "none"
                            : "flex"
                    }
                >
                    <Label
                        style={{
                            visibility:
                                String(dataInfo.standardKind).substr(0, 4) == 1015
                                    ? "hidden"
                                    : String(dataInfo.standardKind).substr(0, 4) == 1025
                                    ? "hidden"
                                    : "visible",
                        }}
                    >
                        <RequiredMark /> {dict["operatingConditions"] + "(" + dict["facilities"] + ")"}
                    </Label>
                    <ComboBox
                        style={{
                            width: "130px",
                            marginRight: 0,
                            visibility:
                                String(dataInfo.standardKind).substr(0, 4) == 1015
                                    ? "hidden"
                                    : String(dataInfo.standardKind).substr(0, 4) == 1025
                                    ? "hidden"
                                    : "visible",
                        }}
                        defaultValue={dict["select"]}
                        hasDefault={true}
                        items={
                            _comboEquipList.filter((i) => i.equipSeq == dataInfo.conditionEquipSeq).map((i) => i.workKind) == 10180001
                                ? [{ value: 1, label: dict["operationalStatus"] }] // 가동상태
                                : [{ value: 2, label: dict["openPer"] }] // 열림정도
                        }
                        value={dataInfo.actionKindEquip}
                        setValue={onSelectactionKindEquip}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {/* 작동조건(설비) 가동상태 */}
                    <ComboBox
                        style={{
                            width: "85px",
                            marginRight: 0,
                            visibility:
                                String(dataInfo.standardKind).substr(0, 4) == 1015 || dataInfo.actionKindEquip == 2
                                    ? "hidden"
                                    : String(dataInfo.standardKind).substr(0, 4) == 1025
                                    ? "hidden"
                                    : _comboEquipList.filter((i) => i.equipSeq == dataInfo.conditionEquipSeq).map((i) => i.workKind) == 10180002
                                    ? "hidden"
                                    : "visible",
                        }}
                        defaultValue={dict["select"]}
                        hasDefault={true}
                        items={[
                            { value: 1, label: dict["operation"] }, // 가동
                            { value: 2, label: dict["nonOperating"] }, // 비가동
                        ]}
                        value={dataInfo.actionStatus}
                        setValue={setActionStatus}
                    />
                    &nbsp;&nbsp;<Label2></Label2>&nbsp;&nbsp; &nbsp;
                    {/* 작동조건(설비) 열림정도 1*/}
                    <InputBox
                        style={{
                            width: "50px",
                            marginRight: 0,
                            visibility:
                                String(dataInfo.standardKind).substr(0, 4) == 1015 || dataInfo.actionKindEquip == 1
                                    ? "hidden"
                                    : String(dataInfo.standardKind).substr(0, 4) == 1025
                                    ? "hidden"
                                    : _comboEquipList.filter((i) => i.equipSeq == dataInfo.conditionEquipSeq).map((i) => i.workKind) == 10180001
                                    ? "hidden"
                                    : "visible",
                        }}
                        type="number"
                        value={dataInfo.dEquipOpenPer}
                        onChange={(e) => setDEquipOpenPer(e.target.value)}
                        // disabled={dataInfo.actionKindEquip != 2}
                    />
                    &nbsp;&nbsp;
                    <Label2
                        style={{
                            visibility:
                                String(dataInfo.standardKind).substr(0, 4) == 1015 || dataInfo.actionKindEquip == 1
                                    ? "hidden"
                                    : String(dataInfo.standardKind).substr(0, 4) == 1025
                                    ? "hidden"
                                    : _comboEquipList.filter((i) => i.equipSeq == dataInfo.conditionEquipSeq).map((i) => i.workKind) == 10180001
                                    ? "hidden"
                                    : "visible",
                        }}
                    >
                        %
                    </Label2>
                    &nbsp;&nbsp;
                    {/* 작동조건(설비) 열림정도 2*/}
                    <ComboBox
                        style={{
                            width: "80px",
                            marginRight: 0,
                            visibility:
                                String(dataInfo.standardKind).substr(0, 4) == 1015 || dataInfo.actionKindEquip == 1
                                    ? "hidden"
                                    : String(dataInfo.standardKind).substr(0, 4) == 1025
                                    ? "hidden"
                                    : _comboEquipList.filter((i) => i.equipSeq == dataInfo.conditionEquipSeq).map((i) => i.workKind) == 10180001
                                    ? "hidden"
                                    : "visible",
                        }}
                        defaultValue={dict["select"]}
                        hasDefault={true}
                        items={[
                            { value: 2, label: dict["below"] }, // 이하
                            { value: 3, label: dict["moreThan"] }, // 이상
                        ]}
                        value={dataInfo.actionKindOpenPer}
                        setValue={setActionKindOpenPer}
                    />
                </FlexBox2>

                {/* 열림정도 */}
                <FlexBox2
                    display={
                        _comboEquipList.filter((i) => i.equipSeq == equipSeq).map((i) => i.workKind) == 10180001
                            ? "none"
                            : _comboEquipList.filter((i) => i.equipSeq == equipSeq).map((i) => i.isNotUseOpenPer) == 1
                            ? "none"
                            : dataInfo.actionKindSensor == 4
                            ? "none"
                            : "flex"
                    }
                >
                    <Label
                        style={{
                            visibility:
                                _comboEquipList.filter((i) => i.equipSeq == equipSeq).map((i) => i.workKind) == 10180001 ? "hidden" : "visible",
                        }}
                    >
                        <RequiredMark /> {dict["openPer"]}
                    </Label>
                    <InputBox
                        style={{
                            width: "100px",
                            marginRight: 0,
                            visibility:
                                _comboEquipList.filter((i) => i.equipSeq == equipSeq).map((i) => i.workKind) == 10180001 ? "hidden" : "visible",
                        }}
                        type="number"
                        value={dataInfo.openPerSet}
                        onChange={(e) => setOpenPerSet(e.target.value)}
                        disabled={
                            dataInfo.prioritySubSerl !== 0 || _comboEquipList.filter((i) => i.equipSeq == equipSeq).map((i) => i.workKind) == 10180001
                        }
                    />
                    &nbsp;&nbsp;
                    <Label2
                        style={{
                            visibility:
                                _comboEquipList.filter((i) => i.equipSeq == equipSeq).map((i) => i.workKind) == 10180001 ? "hidden" : "visible",
                        }}
                    >
                        %
                    </Label2>
                </FlexBox2>

                {/* 열림정도 정역모드전환체크시 정 or 역 콤보박스로 변경 */}
                <FlexBox2
                    display={
                        _comboEquipList.filter((i) => i.equipSeq == equipSeq).map((i) => i.workKind) == 10180001
                            ? "none"
                            : _comboEquipList.filter((i) => i.equipSeq == equipSeq).map((i) => i.isNotUseOpenPer) != 1
                            ? "none"
                            : dataInfo.actionKindSensor == 4
                            ? "none"
                            : "flex"
                    }
                >
                    <Label>
                        <RequiredMark /> {"냉방/난방"}
                    </Label>
                    <ComboBox
                        style={{ width: "100px" }}
                        defaultValue={dict["select"]}
                        hasDefault={true}
                        items={[
                            { value: 10190001, label: "정(냉방)" }, //
                            { value: 10190002, label: "역(난방)" }, //
                            { value: 10190003, label: "정지" }, //
                        ]}
                        disabled={dataInfo.prioritySubSerl !== 0}
                        value={dataInfo.openPerSet}
                        setValue={setOpenPerSet}
                    />
                </FlexBox2>

                {/* 작동방식 */}
                <FlexBox2
                    display={
                        _comboEquipList.filter((i) => i.equipSeq == equipSeq).map((i) => i.workKind) == 10180002
                            ? "none"
                            : dataInfo.actionKindSensor == 4
                            ? "none"
                            : "flex"
                    }
                >
                    <Label
                        style={{
                            visibility:
                                _comboEquipList.filter((i) => i.equipSeq == equipSeq).map((i) => i.workKind) == 10180002 ? "hidden" : "visible",
                        }}
                    >
                        <RequiredMark /> {dict["workKind"]}
                    </Label>

                    <ComboBox
                        style={{
                            width: "100px",
                            marginRight: 0,
                            visibility:
                                _comboEquipList.filter((i) => i.equipSeq == equipSeq).map((i) => i.workKind) == 10180002 ? "hidden" : "visible",
                        }}
                        defaultValue={dict["select"]}
                        hasDefault={true}
                        items={((_codeList || []).filter((i) => i.majorSeq == 1019)[0] || { minorList: [] }).minorList.map((i) => ({
                            value: i.minorSeq,
                            label: i.minorName,
                        }))}
                        disabled={
                            dataInfo.prioritySubSerl !== 0 || _comboEquipList.filter((i) => i.equipSeq == equipSeq).map((i) => i.workKind) == 10180002
                        }
                        value={dataInfo.onOff}
                        setValue={setOnOff}
                    />
                </FlexBox2>

                {/* 대기시간 */}
                <FlexBox>
                    <Label>{dict["delayTime"]}</Label>
                    <InputBox
                        style={{ width: "100px", marginRight: 0 }}
                        type="number"
                        value={dataInfo.delayTime}
                        onChange={(e) => setDelayTime(e.target.value)}
                        disabled={dataInfo.prioritySubSerl !== 0}
                        // disabled={kind == 2}
                    />
                    &nbsp;&nbsp;<Label2>{dict["sec"]}</Label2>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {/* style={{ visibility: dataInfo.delayTime == 0 ? "hidden" : "visible" }} 조건아닐시 숨기는 css*/}
                    {/* 반복작동 */}
                    <Label2>{dict["repeatOperation"]}</Label2>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputBox
                        style={{ width: "100px", marginRight: 0 }}
                        type="number"
                        value={dataInfo.delayCycle}
                        onChange={(e) => setDelayCycle(e.target.value)}
                        disabled={
                            dataInfo.delayTime === 0 ||
                            dataInfo.delayTime === undefined ||
                            dataInfo.prioritySubSerl !== 0 ||
                            dataInfo.delayTime === null
                        }
                    />
                    &nbsp;&nbsp;<Label2>{dict["sec"]}</Label2>
                </FlexBox>

                {/* 비고 */}
                <FlexBox alignItems="flex-start">
                    <Label>{dict["remark"]}</Label>
                    <TextAreaBox width={0} value={dataInfo.remark} setValue={setRemark}></TextAreaBox>
                </FlexBox>

                {/* 사용여부 */}
                <FlexBox>
                    <Label>
                        <RequiredMark />
                        {dict["isUse"]}
                    </Label>
                    <TableCheckBox
                        style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}
                        check={dataInfo.useYN}
                        setCheck={setUseYN}
                    />
                </FlexBox>

                {/* 테스트 체크박스 */}
                <FlexBox>
                    <Label style={{ visibility: dataInfo.prioritySubSerl !== 0 ? "hidden" : "visible" }}>{t("(Test)")}</Label>
                    <TableCheckBox
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "40px",
                            visibility: dataInfo.prioritySubSerl !== 0 ? "hidden" : "visible",
                        }}
                        check={dataInfo.isTest}
                        setCheck={setIsTest}
                    />
                </FlexBox>

                {/* </div> */}
                <ButtonBox>
                    <Button
                        bgColor={"#fff"}
                        hover={"rgba(174, 174, 174, 0.22)"}
                        border={"1px solid #c6c6c6"}
                        color={"#555555cc"}
                        onClick={() => onClose()}
                    >
                        {dict["cancel"]}
                    </Button>
                    <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
                        {dict["save"]}
                    </Button>
                </ButtonBox>
            </Container>
        </Dialog>
    )
}

const Container = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 23px 51px 44px 40px;
`
const Title = styled.p`
    opacity: 80%;
    margin-bottom: 23px;
    color: #555555;
    font-size: 15px;
    font-weight: 700;
    line-height: 32px;
`
const FlexBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 14px;
    &:last-child {
        margin-bottom: 40px;
    }
`
const FlexBox2 = styled.div`
    display: ${(props) => props.display};
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 14px;
    &:last-child {
        margin-bottom: 40px;
    }
`

const Label = styled.p`
    opacity: 80%;
    width: 102px;
    color: #555555;
    font-size: 14px;
    line-height: 34px;
`

const Label2 = styled.p`
    opacity: 80%;
    color: #555555;
    font-size: 14px;
    line-height: 34px;
`

const ButtonBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`
const Button = styled.button`
    background-color: ${(props) => props.bgColor};
    flex: 1;
    height: 40px;
    border: ${(props) => props.border};
    border-radius: 3px;
    color: ${(props) => props.color};
    font-size: 14px;

    &:last-child {
        margin-left: 14px;
    }

    &:hover {
        background-color: ${(props) => props.hover};
    }
`

AutoEnvConfAddPopupComp.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

export default AutoEnvSettingConfContainerComp(CommonContainer(withTranslation()(AutoEnvConfAddPopupComp)))
