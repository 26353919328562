// 자동환경설정 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import SystemSettingContainer from "../../../containers/SystemSettingContainer"

import CommonContainer from "../../../containers/CommonContainer"


// 건담
import { TableComponent } from "../../component/common/Table"

const useStyles = makeStyles((theme) => ({
    container: {
      width: "100%",
      maxWidth: "600px",
      margin: "0 auto",
      "& .MuiDialog-paperFullWidth": {
        width: "100%",
      },
      "& .MuiPaper-root": {
        backgroundColor: "#fff",
      },
      "& .MuiDialog-paper": {
        margin: "0px",
      },
    },
  }))

const TransLang = (props) => {

    // 변수 선언부
        // 예제  =================================================
        const classes = useStyles()

        const { 
          confInfo: dataInfo,
        } = props
    
        const { onClose, open } = props
        const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
    
        const setDataInfo = (data) => {
          setDataList({ confInfo: data })
        }
    
        const setTimeFr = (value) => { setDataInfo({ ...dataInfo, timeFr: value }) }       
        // =======================================================

        
        const {
            t, setDataList, initTranslationWords, getTranslationWords, _translationWords, replaceWord,
            
            currentMajor, // 해당되는 대분류 데이터
            currentMinorForTrans, // 해당되는 소분류 데이터

            // 사용자코드 화면 관련 - 유용준
                _userUDLanguageCode,
                getUserDefLanguageCode,
                editUserDefLanguageCode, // 사용자 소분류 수정 함수 - 차건담 2023.02.07
                insertUserDefLanguageCode, // 사용자 소분류 추가 함수 - 차건담 2023.02.09
                                
            // 다국어 공통 - 차건담 2023.01.27
                finalTransWords, // 최종 다국어 언어 리스트 2023.01.31
                setFinalTransWords,
                editUMajorSMajorSMinor,
                insertUMajorSMajorSMinor,

            // 사용자/시스템 구분, 대분류/소분류 구분
                checkMajorMinor,
          } = props

          const [newestFinalTransWords, setNewestFinalTransWords] = useState(finalTransWords) // insert 된 이후 checkTrans 값이 업데이트 된 finalTransWords를 담기 위해 사용 - 차건담 2023.03.13
          const dict = _translationWords[0] // 변수에 사전 담기

          useEffect(() => {
            getTranslationWords()
          }, [])

          useEffect(() => { // insert 된 이후 checkTrans 값이 업데이트 된 finalTransWords를 담기 위해 사용 - 차건담 2023.03.13
            setNewestFinalTransWords(finalTransWords)
          }, [finalTransWords])

    // 함수 선언부
        const getUserDefLanguageData = async () => {
            if (checkMajorMinor == 1) {
                const result = await getUserDefLanguageCode({ majorSeq: currentMajor.seq})
            }
            else if (checkMajorMinor == 2) {
                const result = await getUserDefLanguageCode({ minorSeq: currentMinorForTrans.seq})
            }
        }
        
        // 사용자 소분류 번역 수정 함수 - 차건담 2023.02.08
        const onClickUDLanguageEdit = async (minorSeq, languageSeq, word) => {
        console.log("minorSeq:",minorSeq,"   languageSeq: ",languageSeq,"    word: ",word)
          const result = await editUserDefLanguageCode({ 
            minorSeq: minorSeq,
            languageSeq: languageSeq,
            word: word, 
          })
          if (result) getUserDefLanguageData()
        }

        // 사용자 소분류 번역 추가 함수 - 차건담 2023.02.09
        const onClickUDLanguageInsert = async (minorSeq, languageSeq, word) => {
            const result = await insertUserDefLanguageCode({
                minorSeq: minorSeq,
                languageSeq: languageSeq,
                word: word,
            })
            // 인서트 후 해당 로우의 checkTrans 값에 word 값 넣기
            if (result) {
                console.log("수정요망")  
                for(let i=0; i<finalTransWords.length; i++) {
                    if(finalTransWords[i].languageSeq == languageSeq){
                        finalTransWords[i].checkTrans = word;
                    }
                }
            }
            setNewestFinalTransWords(finalTransWords)
        }

        // 사용자 대분류, 시스템 대분류/소분류 번역 수정 함수 - 차건담 2023.02.06
        const onClickSDLanguageEdit = async (wordSeq, languageSeq, word) => {
            console.log("wordSeq:",wordSeq,"   languageSeq: ",languageSeq,"    word: ",word)
            const result = await editUMajorSMajorSMinor({
                wordSeq: wordSeq,
                languageSeq: languageSeq,
                word: word,
            })
            //if (result) getWordsForTranslate()
            //if (result) setNewestFinalTransWords(finalTransWords)
        }

        // 사용자 대분류, 시스템 대분류/소분류 번역 추가 함수 - 차건담 2023.02.07
        const onClickSDLanguageInsert = async (wordSeq, languageSeq, word) => {
            const result = await insertUMajorSMajorSMinor({
                wordSeq: wordSeq,
                languageSeq: languageSeq,
                word: word,
            })
            // 인서트 후 해당 로우의 checkTrans 값에 word 값 넣기
            if (result) {
                console.log("수정요망")            
                for(let i=0; i<finalTransWords.length; i++) {
                    if(finalTransWords[i].languageSeq == languageSeq){
                        finalTransWords[i].checkTrans = word;
                    }
                }            
            }

            setNewestFinalTransWords(finalTransWords)
        }
        
        // 테이블 타이틀 반환 함수 - 차건담 2023.01.27
        const getHeadList = () => {
            let result = []

            if (checkMajorMinor == 1) {
                result = [
                    {title: dict['languageSeq'] , type: "hidden"},
                    {title: dict['checkTrans'] , type: "hidden"}, // 번역 테이블에 languageSeq에 해당되는 데이터 존재유무 확인하는 키값
                    {title: dict['wordCode'] , type: "number"},
                    {title: dict['language'] , type: "default"},
                    {title: dict['mainCategoryName'] , type: "text"},
                    {title: dict['function'] }
                ]
            }
            else if(checkMajorMinor == 2) {
                result = [
                    {title: dict['languageSeq'] , type: "hidden"},
                    {title: dict['checkTrans'] , type: "hidden"},
                    {title: dict['subCategoryCode'] , type: "number"},
                    {title: dict['language'], type: "default"},
                    {title: dict['subCategoryName'] , type: "text"},
                    {title: dict['function'] }
                ]
            }
            return result
        }

        // 테이블 반환 함수 - 차건담 2023.01.27
        const getTable = () => {
            let result = []

            if (checkMajorMinor == 1) {
                result =
                <TableComponent 
                    headList={getHeadList()}
                    bodyList={finalTransWords.map((i, idx) => ({
                            languageSeq: i.languageSeq,
                            checkTrans: [i.checkTrans, idx],
                            wordSeq: i.wordSeq,
                            language: i.language,
                            word: (
                                <TableInput
                                    id={`word${i.languageSeq}`}
                                    type="text"
                                    value={i.word == null? "" : i.word}
                                    onChange={(i) => {
                                        const temp1 = [...finalTransWords]
                                        temp1[idx].word = i.target.value
                                        setFinalTransWords(temp1)
                                    }}   
                                />
                            ),                 
                            Actions_transLangPopup: true,
                        }))}
                dict={dict}

                    onClickEdit={(i) => {
                        console.log("newestFinalTransWords[idx].checkTrans:",newestFinalTransWords[i.checkTrans[1]].checkTrans, "  idx: ", i.checkTrans[1])
                        console.log(newestFinalTransWords)
                        if (newestFinalTransWords[i.checkTrans[1]].checkTrans == 1) {
                            onClickSDLanguageInsert(i.wordSeq, i.languageSeq, document.getElementById(`word${i.languageSeq}`).value)                          
                        } else {
                            onClickSDLanguageEdit(i.wordSeq, i.languageSeq, document.getElementById(`word${i.languageSeq}`).value)
                        }
                    }}
                >
                </TableComponent>
            }

            else if(checkMajorMinor == 2) {
                result =
                <TableComponent 
                headList={getHeadList()}
                bodyList={finalTransWords.map((i, idx) => ({
                        languageSeq: i.languageSeq,
                        checkTrans: [String(i.checkTrans), idx],
                        minorSeq: i.minorSeq,
                        language: i.language,
                        word: (
                            <TableInput
                                id={`minorWord${i.languageSeq}`}
                                type="text"
                                value={i.word == null? "" : i.word}
                                onChange={(i) => {
                                    const temp1 = [...finalTransWords]
                                    temp1[idx].word = i.target.value
                                    setFinalTransWords(temp1)
                                }}   
                            />
                        ),                 
                        Actions_transLangPopup: true,
                    }))}
                dict={dict}                            
                onClickEdit={(i) => {
                    console.log("TransLang line 268 :",i.checkTrans[0], "   _userUDLanguageCode", _userUDLanguageCode)
                    if (newestFinalTransWords[i.checkTrans[1]].checkTrans == 1) {
                        onClickUDLanguageInsert(i.minorSeq, i.languageSeq, document.getElementById(`minorWord${i.languageSeq}`).value)
                    } else {
                        console.log("TransLang line 269 i.languageSeq: ", i.languageSeq, "    word id: ", document.getElementById(`minor${i.languageSeq}`))
                        onClickUDLanguageEdit(i.minorSeq, i.languageSeq, document.getElementById(`minorWord${i.languageSeq}`).value)
                    }
                }}
                >
                </TableComponent>
            }
            return result
        }

    // 리턴
        return (
            <Dialog
            className={classes.container}
            style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
            onClose={() => onClose(null)}
            open={open}
            fullWidth={true}
            >
                <Container>
                    <Box>
                        <WhiteBox width={520} marginLeft={10}>
                            <InputView>                                       
                              <Title>{dict['multilingualSettings']}</Title>
                            </InputView>
                            {getTable()}                    
                        </WhiteBox>
                    </Box>
                </Container>
            </Dialog>
        )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  overflow-y: hidden;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 28px;
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width}px;
  height: 100%;
  margin-left: 35px;
  padding: 35px 40px 50px 40px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const InputView = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Title = styled.p`
  width: 72px;
  color: #555555;
  font-size: 14px;
`
const TableInput = styled.input`
  width: 100%;
  height: 35px;
  padding: 0px 15px;
`


TransLang.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
  }
  
  export default SystemSettingContainer(CommonContainer(withTranslation()(TransLang)));
  