// 구매지출관리 > 구매지출관리
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import { withTranslation } from "react-i18next"
import { downExcel } from "../../../utils/util"
import PurchaseSpendRegisterContainer from "../../../containers/purchaseSpend/PurchaseSpendRegisterContainer"
import PageName from "../../component/common/PageName"
import DateBox from "../../component/common/DateBox"
import InputBox from "../../component/common/InputBox"
import ComboBox from "../../component/common/ComboBox"
import MultiComboBox from "../../component/common/MultiComboBox"
import { YellowButton, SkyButton, GrayButton } from "../../component/common/Button"
import CodeHelpBox from "../../component/common/CodeHelpBox"
import { TableComponent } from "../../component/common/Table"
import PurchaseSpendAddPopup from "../../popup/PurchaseSpend/PurchaseSpendAddPopup"

import CommonContainer from "../../../containers/CommonContainer"

const purchaseSpendRegister = (props) => {
  const {
    t,

    _codeList,
    getComboList,

    _purchaseCostList,
    setPurchaseCostList,
    getPurchaseCostList,
    setPurchaseCost,
    getPurchaseCost,
    delPurchaseCost,
    downloadPurchaseExpendListExcel,

    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,
  } = props
  const [purchaseSpendAddPopup, setPurchaseSpendAddPopup] = useState(false)
  const [startCostDate, setStartCostDate] = useState(moment().format("YYYYMM01"))
  const [endCostDate, setEndCostDate] = useState(moment().format("YYYYMMDD"))
  const [costNo, setCostNo] = useState("")
  const [custSeq, setCustSeq] = useState({ value: 0, label: "" })
  const [costKind, setCostKind] = useState(0)
  const [costClass, setCostClass] = useState([])
  const [cropName, setCropName] = useState("")
  const [cropNameCode, setCropNameCode] = useState('')

  const [minDate, setMinDate] = useState(new Date()) //날짜 최저일
  const [maxDate, setMaxDate] = useState(new Date()) //날짜 최대일

  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {
    getTranslationWords()
    getData()

    return async () => await setPurchaseCostList([])
  }, [])

  useEffect(() => {
    if(cropNameCode != ''){
      setCropName(cropNameCode['label'])
    }
  }, [cropNameCode])

  // 달력 날짜 제한 관련
  useEffect(() => {

    // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
    const originalDate = new Date(startCostDate.substr(0, 4) + '-' + startCostDate.substr(4, 2) + '-' + startCostDate.substr(6, 2));
    const modifiedDate = originalDate.setDate(originalDate.getDate() + 10000);

    setMinDate(new Date(startCostDate.substr(0,4)+'-'+startCostDate.substr(4,2)+'-'+startCostDate.substr(6,2)));
    setMaxDate(modifiedDate);
    // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
    // console.log('dateFr(1) : ', dateFr)
    // console.log('dateTo(1) : ', dateTo)

  }, [startCostDate])

  const getData = async () => {
    await getComboList({ queryKind: "Crop" })
  }

  const onClickSearch = async () => {
    await getPurchaseCostList({
      startCostDate: startCostDate ? moment(startCostDate).format("YYYYMMDD") : "",
      endCostDate: endCostDate ? moment(endCostDate).format("YYYYMMDD") : "",
      costNo,
      custSeq: custSeq.value,
      costClass: costClass.map((i) => i.value),
      costKind,
      cropName,
    })
  }
  const onClickAdd = async () => {
    await setPurchaseCost({})
    setPurchaseSpendAddPopup(true)
  }
  const onClickEdit = async (costSeq) => {
    const result = await getPurchaseCost({ costSeq })
    if (result) setPurchaseSpendAddPopup(true)
  }
  const onClickDelete = async (costSeq) => {
    const result = await delPurchaseCost({ costSeq })
    if (result) onClickSearch()
  }
  const onClickExcelDownload = async () => {
    const data = {
      startCostDate,
      endCostDate,
      costNo,
      custSeq: custSeq.value,
      costClass: costClass.map((i) => i.value),
      costKind,
      cropName,
    }
    const result = await downloadPurchaseExpendListExcel(data)
    downExcel({ result: result, fileName:dict['purchaseExpendList'] })
  }

  //지출,구매 항목 구분코드선택
  const selectCostSeq = (majorKind) => {
    if (majorKind == 10140001) return { seq: 1017, kind:dict['purchase'] }
    else if (majorKind == 10140002) return { seq: 1013, kind:dict['expenditure'] }
    else return { seq: 0, kind:dict['unselected'] }
  }

  //멀티박스 구매/지출 코드 가져오기
  const selectMultiList = () => {
    const ex = _codeList && _codeList.filter((i) => i.majorSeq == 1013).length > 0 ? _codeList.filter((i) => i.majorSeq == 1013)[0].minorList : []
    const pur = _codeList && _codeList.filter((i) => i.majorSeq == 1017).length > 0 ? _codeList.filter((i) => i.majorSeq == 1017)[0].minorList : []
    const mergeList = [...pur, ...ex]
    return mergeList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
  }

  return (
    <Container>
      <PurchaseSpendAddPopup
        open={purchaseSpendAddPopup}
        onClose={(bool) => {
          if (bool == true) onClickSearch()
          setPurchaseSpendAddPopup(false)
        }}
      />
      <FlexBox justifyContent="space-between">
        <PageName name={dict['purchaseSpendRegister']} state={false} setState={() => {}} />
        <GrayButton onClick={onClickExcelDownload}>{dict['downloadExcelFile']}</GrayButton>
      </FlexBox>
      <Box>
        <WhiteBox>
          <FlexBox justifyContent="space-between" style={{ marginBottom: "18px" }}>
            <FlexBox>
              <Text>{dict['dateOfOccurrence']}</Text>
              <DateBox style={{ width: "120px", marginRight: 0, zIndex: 99 }} stdDate={startCostDate} setStdDate={setStartCostDate} />
              <p style={{ margin: "0 25px", color: "#555555", fontSize: "14px" }}>~</p>
              <DateBox style={{ width: "120px", marginRight: 0, zIndex: 99 }} stdDate={endCostDate} setStdDate={setEndCostDate} minDate={minDate} maxDate={maxDate} />
              <Text style={{ marginLeft: "50px" }}>{dict['purchaseExpenditureNumber']}</Text>
              <InputBox style={{ width: "300px", marginRight: 0 }} type="text" value={costNo} onChange={(e) => setCostNo(e.target.value)} />
            </FlexBox>
            <FlexBox>
              <YellowButton onClick={onClickSearch}>{dict['search']}</YellowButton>
              <SkyButton style={{ marginLeft: "14px" }} onClick={onClickAdd}>
                {dict['new']}
              </SkyButton>
            </FlexBox>
          </FlexBox>

          <FlexBox justifyContent="space-between" style={{ marginBottom: "18px" }}>
            <FlexBox>
              <Text>{dict['purchaser']}</Text>
              <CodeHelpBox
                style={{ width: "300px", marginRight: 0 }}
                codeHelpSeq={2}
                codeHelpParam={{ custKind: 10060002 }} // 구매거래처
                value={custSeq}
                setValue={setCustSeq}
              />
              <Text style={{ marginLeft: "50px" }}>{dict['expenseClassification']}</Text>
              <ComboBox
                style={{ width: "300px" }}
                defaultValue={dict['select']}
                hasDefault={true}
                items={
                  _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1014).length > 0
                    ? _codeList.filter((i) => i.majorSeq == 1014)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                    : []
                }
                value={costKind}
                setValue={setCostKind}
              />
            </FlexBox>
          </FlexBox>

          <FlexBox>
            <Text>{dict['purchaseSpendItems']}</Text>
            <MultiComboBox
              style={{ width: "300px", marginRight: 0, zIndex: 90 }}
              placeholder={dict['multiSelect']}
              items={
                // _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == selectCostSeq(costKind).seq).length > 0
                // ? _codeList.filter((i) => i.majorSeq  == selectCostSeq(costKind).seq)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                // : []
                selectMultiList()
              }
              value={costClass}
              setValue={setCostClass}
            />
            <Text style={{ marginLeft: "50px" }}>{dict['cropName']}</Text>
            {/* <InputBox style={{ width: "300px" }} type="text" value={cropName} onChange={(e) => setCropName(e.target.value)} /> */}
            <CodeHelpBox // inputBox에서 CodeHelpBox로 변경 - 차건담 2023.07.13
              style={{width: "200px", marginLeft: "20px"}}
              placeholder={dict['cropName']}
              codeHelpSeq={3}
              // codeHelpParam={{}}
              value={cropNameCode}
              setValue={setCropNameCode}
            />
          </FlexBox>

          <TableComponent
            headList={[
              { title: dict['purchaseExpenditureNumber'] , type: "text" },
              { title: dict['dateOfOccurrence'] , type: "date" },
              { title: dict['cropName'] , type: "text" },
              { title: dict['purchaser'] , type: "text" },
              { title: dict['itemName'] , type: "text" },
              { title: dict['expenseClassification'] , type: "text" },
              { title: dict['expenditureItems'] , type: "text" },
              { title: dict['unit'] , type: "text" },
              { title: dict['quantity'] , type: "comma" },
              { title: dict['price'] , type: "comma" },
              { title: dict['sum'] , type: "comma" },
              { title: dict['remark'] , type: "text" },
              { title: "Actions" },
            ]}
            bodyList={_purchaseCostList.map((i) => ({
              seq: i.costSeq,
              costNo: i.costNo,
              costDate: i.costDate,
              cropName: i.cropName,
              custName: i.custName,
              costClassName: i.costClassName,
              costKindName: i.costKindName,
              costClassName2: i.costClassName,
              unitName: i.unitName,
              qty: i.qty || 0,
              price: i.price || 0,
              amt: i.amt || 0,
              remark: i.remark,
              Actions: true,
            }))}
            dict={dict}
            onClickEdit={(seq) => onClickEdit(seq)}
            onClickDelete={(seq) => onClickDelete(seq)}
          />
        </WhiteBox>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  margin-top: 19px;
  padding: 28px 32px 20px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const Text = styled.p`
  width: 102px;
  color: #555555;
  font-size: 14px;
`

export default PurchaseSpendRegisterContainer(CommonContainer(withTranslation()(purchaseSpendRegister)))
