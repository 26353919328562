import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  //방문자관리
  initProductionVisitorList,
  initProductionVisitor,
  getProductionVisitorMonthList,
  getProductionVisitorDayList,
  addProductionVisitor,
  getProductionVisitor,
  editProductionVisitor,
  delProductionVisitor,
} = actions.ProductionAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  //방문자관리
  _productionVisitorList: state.ProductionReducer._productionVisitorList,
  _productionVisitor: state.ProductionReducer._productionVisitor,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  //방문자관리
  initProductionVisitorList: (param) => dispatch(initProductionVisitorList(param)),
  initProductionVisitor: (param) => dispatch(initProductionVisitor(param)),

  getProductionVisitorMonthList: (param) => dispatch(getProductionVisitorMonthList(param)),
  getProductionVisitorDayList: (param) => dispatch(getProductionVisitorDayList(param)),
  
  addProductionVisitor: (param) => dispatch(addProductionVisitor(param)),
  getProductionVisitor: (param) => dispatch(getProductionVisitor(param)),
  editProductionVisitor: (param) => dispatch(editProductionVisitor(param)),
  delProductionVisitor: (param) => dispatch(delProductionVisitor(param)),
  
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
