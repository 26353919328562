// 구매/지출 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import PurchaseSpendRegisterContainer from "../../../containers/purchaseSpend/PurchaseSpendRegisterContainer"
import RequiredMark from "../../component/RequiredMark"
import DateBox from "../../component/common/DateBox"
import InputBox from "../../component/common/InputBox"
import ComboBox from "../../component/common/ComboBox"
import { SkyButton } from "../../component/common/Button"
import { TableComponent } from "../../component/common/Table"
import CodeHelpBox from "../../component/common/CodeHelpBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import AlertPopup from "../AlertPopup"
import PurchaseSpendItemAddPopup from "./PurchaseSpendItemAddPopup"

import CommonContainer from "../../../containers/CommonContainer"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
      maxWidth: "1124px",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const PurchaseSpendAddPopup = (props) => {
  const classes = useStyles()
  const {
    t,
    _codeList,
    _comboCropList,
    _purchaseCost,
    addPurchaseCost,
    editPurchaseCost,
    setPurchaseCostDetail,
    getPurchaseCostDetail,
    delPurchaseCostDetail,

    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,
    cropComboList,

  } = props
  const { open, onClose } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const [purchaseSpendItemAddPopup, setPurchaseSpendItemAddPopup] = useState({ open: false, index: -1 })
  const [costDate, setCostDate] = useState(moment().format("YYYYMMDD"))
  const [costNo, setCostNo] = useState("")
  const [custSeq, setCustSeq] = useState({ label: "", value: 0 })
  const [cropSeq, setCropSeq] = useState(0)
  const [cropName, setCropName] = useState('')
  const [costKind, setCostKind] = useState(0)
  const [remark, setRemark] = useState("")
  const [details, setDetails] = useState([])

  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {
    
    if (Object.keys(_purchaseCost).length != 0) {
      setCostDate(_purchaseCost.costDate)
      setCostNo(_purchaseCost.costNo)
      setCustSeq({ label: _purchaseCost.custName || "", value: _purchaseCost.custSeq })
      if (cropComboList.find((i) => i.cropSeq == _purchaseCost.cropSeq) != undefined) setCropSeq(_purchaseCost.cropSeq)
      if (_codeList.filter((i) => i.majorSeq == 1014)[0].minorList.find((i) => i.minorSeq == _purchaseCost.costKind) != undefined)
        setCostKind(_purchaseCost.costKind)
      setRemark(_purchaseCost.remark)
      setDetails(_purchaseCost.details || [])
    }
    getTranslationWords()

    // console.log('김한중테스트중!!@')
    // console.log(_purchaseCost)
    // console.log('김한중테스트중2222')
    // console.log(cropComboList)

    return () => {
      setCostDate(moment().format("YYYYMMDD"))
      setCostNo("")
      setCustSeq({ label: "", value: 0 })
      setCropSeq(0)
      setCostKind(0)
      setRemark("")
      setDetails([])
    }
  }, [open])

  const onClickSave = async () => {
    if (custSeq.value == 0) return setAlertPopup({ open: true, text:replaceWord(dict["n.Please select a product."], dict['purchaser']) })
    else if (cropSeq == 0) return setAlertPopup({ open: true, text:replaceWord(dict["n.Please select a product."], dict['cropName']) })
    else if (costKind == 0) return setAlertPopup({ open: true, text:replaceWord(dict["n.Please select a product."], dict['expenseClassification']) })

    let result = false

    if (_purchaseCost.costSeq == undefined) {
      result = await addPurchaseCost({
        costDate: costDate ? moment(costDate).format("YYYYMMDD") : "",
        custSeq: custSeq.value,
        cropSeq,
        costKind,
        remark,
        details: details.map((i) => {
          if (i.costDetailSeq == undefined)
            return {
              costClass: i.costClass,
              unitSeq: i.unitSeq,
              qty: i.qty || 0,
              price: i.price || 0,
              amt: i.amt || 0,
              remark: i.remark,
            }
          else
            return {
              costDetailSeq: i.costDetailSeq,
              costSeq: _purchaseCost.costSeq,
              costClass: i.costClass,
              unitSeq: i.unitSeq,
              qty: i.qty || 0,
              price: i.price || 0,
              amt: i.amt || 0,
              remark: i.remark,
            }
        }),
      })
    } else {
      result = await editPurchaseCost({
        costSeq: _purchaseCost.costSeq,
        costDate: costDate ? moment(costDate).format("YYYYMMDD") : "",
        costNo,
        custSeq: custSeq.value,
        cropSeq,
        costKind,
        remark,
        details: details.map((i) => {
          if (i.costDetailSeq == undefined)
            return {
              costClass: i.costClass,
              unitSeq: i.unitSeq,
              qty: i.qty || 0,
              price: i.price || 0,
              amt: i.amt || 0,
              remark: i.remark,
            }
          else
            return {
              costDetailSeq: i.costDetailSeq,
              costSeq: _purchaseCost.costSeq,
              costClass: i.costClass,
              unitSeq: i.unitSeq,
              qty: i.qty || 0,
              price: i.price || 0,
              amt: i.amt || 0,
              remark: i.remark,
            }
        }),
      })
    }

    if (result) onClose(true)
  }
  const onClickAdd = async () => {
    await setPurchaseCostDetail({})
    setPurchaseSpendItemAddPopup({ open: true, index: -1 })
  }
  const onClickEdit = async (idx) => {
    const result = await getPurchaseCostDetail({ costDetailSeq: details[idx].costDetailSeq })
    if (result) setPurchaseSpendItemAddPopup({ open: true, index: idx })
  }
  const onClickDelete = async (idx) => {
    if (details[idx].costDetailSeq) {
      const result = await delPurchaseCostDetail({ costDetailSeq: details[idx].costDetailSeq })
      if (result) {
        const temp = [...details]
        temp.splice(idx, 1)
        setDetails(temp)
      }
    } else {
      const temp = [...details]
      temp.splice(idx, 1)
      setDetails(temp)
    }
  }

  useEffect(() => {
    if(cropName != ''){
      setCropSeq(cropName['value'])
    }
  }, [cropName])

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={onClose}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <PurchaseSpendItemAddPopup
        open={purchaseSpendItemAddPopup.open}
        onClose={() => setPurchaseSpendItemAddPopup({ open: false, index: -1 })}
        index={purchaseSpendItemAddPopup.index}
        details={details}
        setDetails={setDetails}
        selectCostKind={costKind}
      />
      <Container>
        <Title>{dict['purchaseSpend']}</Title>
        <div style={{ flex: 1 }}>
          <FlexBox style={{ marginBottom: "14px" }}>
            <Label>
              <RequiredMark /> {dict['dateOfOccurrence']}
            </Label>
            <DateBox stdDate={costDate} setStdDate={setCostDate} />
            <Label>{dict['purchaseExpenditureNumber']}</Label>
            <InputBox value={costNo} onChange={(e) => setCostNo(e.target.value)} disabled />
          </FlexBox>

          <FlexBox style={{ marginBottom: "14px" }}>
            <Label>
              <RequiredMark /> {dict['purchaser']}
            </Label>
            <CodeHelpBox
              style={{}}
              codeHelpSeq={2}
              codeHelpParam={{ custKind: 10060002 }} // 구매거래처
              value={custSeq}
              setValue={setCustSeq}
            />
            <Label>
              <RequiredMark /> {dict['cropName']}
            </Label>
            {/* <ComboBox
              defaultValue={dict['select']}
              hasDefault={true}
              items={(cropComboList || []).map((i) => ({ value: i.cropSeq, label: i.cropName }))}
              value={cropSeq}
              setValue={setCropSeq}
            /> */}
            <CodeHelpBox // inputBox에서 CodeHelpBox로 변경 - 차건담 2023.07.14
              style={{width: "200px", marginLeft: "20px"}}
              placeholder={dict['cropName']}
              codeHelpSeq={3}
              // codeHelpParam={{}}
              value={cropName}
              setValue={setCropName}
            />
            <Label>
              <RequiredMark /> {dict['expenseClassification']}
            </Label>
            <ComboBox
              style={{ marginRight: 0 }}
              defaultValue={dict['select']}
              hasDefault={true}
              items={
                _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1014).length > 0
                  ? _codeList.filter((i) => i.majorSeq == 1014)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                  : []
              }
              value={costKind}
              setValue={setCostKind}
            />
          </FlexBox>

          <FlexBox alignItems="flex-start">
            <Label>{dict['remark']}</Label>
            <TextAreaBox width={0} value={remark} setValue={setRemark}></TextAreaBox>
            <FlexBox alignItems="flex-end" style={{ height: "80px", marginLeft: "14px" }}>
              <SkyButton onClick={onClickAdd}>{dict['add']}</SkyButton>
            </FlexBox>
          </FlexBox>

          <div style={{ overflow: "hidden", height: "227px" }}>
            <TableComponent
              headList={[
                { title: dict['expenditureItems'] , type: "text" },
                { title: dict['unit'] , type: "text" },
                { title: dict['quantity'] , type: "comma" },
                { title: dict['price'] , type: "comma" },
                { title: dict['amt'] , type: "comma" },
                { title: dict['remark'] , type: "text" },
                { title: "Actions" },
              ]}
              bodyList={details.map((i, idx) => ({
                seq: idx,
                costClassName: i.costClassName,
                unitName: i.unitName,
                qty: i.qty || 0,
                price: i.price || 0,
                amt: i.amt || 0,
                remark: i.remark,
                Actions: true,
              }))}
              dict={dict}
              onClickEdit={onClickEdit}
              onClickDelete={onClickDelete}
            />
          </div>
        </div>
        <ButtonBox>
          <Button bgColor={"#fff"} hover={"rgba(174, 174, 174, 0.22)"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={onClose}>
            {dict['cancel']}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {dict['save']}
          </Button>
        </ButtonBox>
      </Container>
    </Dialog>
  )
}

const Container = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 23px 41px 33px;
`
const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 45px;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  width: 147px;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

PurchaseSpendAddPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default PurchaseSpendRegisterContainer(CommonContainer(withTranslation()(PurchaseSpendAddPopup)))
