// 환경>인터락 경보알람 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment"
import { withTranslation } from "react-i18next"
import { Dialog } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import RequiredMark from "../../component/RequiredMark"
import DateBox from "../../component/common/DateBox"
import TableCheckBox from "../../component/common/TableCheckBox"
import ComboBox from "../../component/common/ComboBox"
import InputBox from "../../component/common/InputBox"
import InputTimeBox from "../../component/common/InputTimeBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import AlertPopup from "../AlertPopup"
import MultiComboBox from "../../component/common/MultiComboBox"
import InterLockSettingContainer from "../../../containers/envControl/InterLockSettingContainer"
import CommonContainer from "../../../containers/CommonContainer"

import icon_checkBasic from "images/checkBasic.png"
import icon_checkBasic_select from "images/checkBasic_select.png"

const useStyles = makeStyles((theme) => ({
  container: {
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
      margin: "25px",
      borderRadius: "9px",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
      maxWidth: "960px",
    },
  },
}))

const EmergencyAlarmAddPopup = (props) => {
  const classes = useStyles()
  const { sensorComboList } = props
  const { _codeList } = props
  const { t, initTranslationWords, getTranslationWords, _translationWords, replaceWord, _emergencyAlarm} = props
  // const { _emergencyAlarm } = props
  const { open, onClose } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const [sensorKind, setSensorKind] = useState(0) // 센서종류
  const [sensorOptions, setSensorOptions ] = useState([]) //센서명 멀티
  const [tempMax, setTempMax] = useState("") // 최고온도
  const [tempMin, setTempMin] = useState("") // 최저온도
  const [isUse, setIsUse] = useState(true)   // 알람사용여부
  const [alarmCycleTime, setAlarmCycleTime] = useState("") // 알람주기시간
  const [remark, setRemark] = useState("") //비고
  const [isManager, setIsManager] = useState(true)   // 관리자전송여부

  const [sensorSeq, setSensorSeq] = useState("")   //센서명 멀티
  const [userList, setUserList] = useState([]) // 유저 멀티티
  
  const dict = _translationWords[0] // 변수에 사전 담기

//   const getData = async () => {
  
//     await getComboList({ queryKind: "Sensor" }) //센서명 가져오는거
  
//    setSensorOptions([]) //센서명 멀티 조건 비우기
  
//  }

  useEffect(() => {
    getTranslationWords()
    setSensorOptions([]) //센서명 멀티 조건 비우기
    setUserList([]) //유저 멀티 조건 비우기
    if (Object.keys(_emergencyAlarm).length != 0) {
      setSensorKind(_emergencyAlarm.sensorKind || 0)
      setTempMax(_emergencyAlarm.tempMax || 0)
      setTempMin(_emergencyAlarm.tempMin || 0)
      setIsUse(_emergencyAlarm.isUse == 1)
      setAlarmCycleTime(_emergencyAlarm.alarmCycleTime || "")
      setRemark(_emergencyAlarm.remark || "")
      setIsManager(_emergencyAlarm.isManager == 1)

      // setSensorSeq(_emergencyAlarm.sensorSeq || "") //센서멀티
      if (_emergencyAlarm.sensorSeq != undefined && _emergencyAlarm.sensorSeq != null){ //센서멀티 부르는 구문
        
        let sensorComboListtest = sensorComboList.map((i) => ({ value: i.sensorSeq, label: i.sensorNodeName +' - '+ i.sensorName })) 
        
        let SensorSeqlist = []
        const conditionSensorSeqlist     = _emergencyAlarm.sensorSeq
        const conditionSensorSeqlistcopy = conditionSensorSeqlist.split(',')

        for(let j=0; j<conditionSensorSeqlistcopy.length; j++){
          // console.log("conditionSensorSeqlistcopy[j]",conditionSensorSeqlistcopy[j])
          // console.log("sensorComboListtest.filter((i) => conditionSensorSeqlistcopy[j]==i.value)",sensorComboListtest.filter((i) => conditionSensorSeqlistcopy[j]==i.value))

          if(sensorComboListtest.filter((i) => conditionSensorSeqlistcopy[j]==i.value).length > 0){
            SensorSeqlist.push(sensorComboListtest.filter((i) => conditionSensorSeqlistcopy[j]==i.value)[0]) 

          };

        } // END for 문

        // console.log("_emergencyAlarm.sensorSeq",_emergencyAlarm.sensorSeq)
        // console.log("SensorSeqlist",SensorSeqlist)
        // console.log("sensorComboListtest",sensorComboListtest)

        setSensorOptions(SensorSeqlist)
      } // END if 문

      setUserList(_emergencyAlarm.userList) //유저멀티

    }
    
    return () => {
      setSensorKind(0)
      setTempMax(0)
      setTempMin(0)
      setIsUse(true)
      setAlarmCycleTime("")
      setRemark("")
      setIsManager(false)

      setSensorSeq("")
    }
  
  }, [open])

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }


  const onSelectsensorKind = async (value) => {
    setSensorKind(value)
    setSensorOptions([])
  
    if ( value == 10150011){ setTempMax(""), setTempMin("")}

  }

  // console.log("_emergencyAlarm@@@@@@")
  // console.log(_emergencyAlarm)

  // console.log("sensorComboList@@@@@@")
  // console.log(sensorComboList)

  // console.log("_codeList@@@@@@")
  // console.log(_codeList)

  const onClickSave = () => {
    if (sensorKind == 0 || sensorKind == undefined) return setAlertPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['sensorKind']) })
    else if (sensorOptions.length == 0 || sensorOptions.length == undefined || sensorOptions.length == null)
    return setAlertPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['sensorName']) })
    else if ( (sensorKind == 10150001 || sensorKind == 10150014 )  && (tempMax === "" || tempMax === null || tempMax === undefined))
    return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict['maxValue']) })
    else if ( (sensorKind == 10150001 || sensorKind == 10150014 ) && (tempMin === "" || tempMin === null || tempMin === undefined))
    return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict['minValue']) })
    else if (alarmCycleTime == "" || alarmCycleTime == null || alarmCycleTime == undefined)
    return setAlertPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['time']) })
    else if (userList.filter((i) => i.isCheck == 1).map((i) => i.userSeq).length == 0 || userList.filter((i) => i.isCheck == 1).map((i) => i.userSeq).length == undefined)
    return setAlertPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['recipient']) })

    // console.log("김한중짱@")
    // console.log(userList)
    // console.log(userList.filter((i) => i.isCheck == 1).map((i) => i.userSeq).length)
    
    let param = {
      sensorKind: sensorKind || 0,              //센서종류
      tempMax: tempMax ? tempMax : 0 ,          //최고온도
      tempMin: tempMin ? tempMin : 0 ,          //최저온도
      isUse: isUse ? 1 : 0,                     //사용여부
      alarmCycleTime: alarmCycleTime || "",     //알람주기시간
      remark: remark || "",                     //비고
      isManager: isManager ? 1 : 0,             //관리자전송여부

      sensorOptions : sensorOptions.map( i => i.value),  //센서명(멀티)
      userSeqs: userList.filter((i) => i.isCheck == 1).map((i) => i.userSeq), // 받는사람(멀티)
    }
    if (_emergencyAlarm.emergencyAlarmSeq) param.emergencyAlarmSeq = _emergencyAlarm.emergencyAlarmSeq
    onClose(dict['save'], param)
  }

  return (
    <Dialog className={classes.container} open={open} onClose={onClose} fullWidth={true}>
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <Container>
        <Title>{dictCk('warningAlarm')}</Title>
        <div style={{ flex: 1 }}>
        
        <FlexBox >
          <Label>
          <RequiredMark /> {dict['sensorKind']} 
          </Label>
            <ComboBox
              style={{ width: "200px", marginRight: 0 ,}}
              defaultValue={dict['select']}
              hasDefault={true}
              items={
                ((_codeList || []).filter((i) => i.majorSeq == 1015)[0] || { minorList: [] }).minorList.filter((i) => i.minorSeq == 10150001 || i.minorSeq == 10150011 || i.minorSeq == 10150013 || i.minorSeq == 10150014).map((i) => ({value: i.minorSeq, label: i.minorName,}))
              }
              value={sensorKind}
              setValue={onSelectsensorKind}
            />
          </FlexBox>
          
          <FlexBox >
            <Label><RequiredMark />{dict['sensorName']}</Label>
            <MultiComboBox
              style={{ width: "350px", marginRight: 0,}}
              placeholder={dict['multiSelect']}
              items={sensorComboList.filter((i) => i.sensorKind == sensorKind ).map((i) => ({ value: i.sensorSeq, label: i.sensorNodeName +' - '+ i.sensorName }))}
              value={sensorOptions}
              setValue={setSensorOptions}
            />
          </FlexBox>

          <FlexBox2 display={sensorKind == 10150011 || sensorKind == 10150013 ? 'none' : 'flex'}>
            <Label><RequiredMark />{dict['maxValue']}</Label>
            <InputBox style={{ width: "100px" }} type="number" value={tempMax} onChange={(e) => setTempMax(e.target.value)} />
          </FlexBox2>

          <FlexBox2 display={sensorKind == 10150011 || sensorKind == 10150013 ? 'none' : 'flex'}>
            <Label><RequiredMark />{dict['minValue']}</Label>
            <InputBox style={{ width: "100px" }} type="number" value={tempMin} onChange={(e) => setTempMin(e.target.value)} />
          </FlexBox2>

          <FlexBox>
            <Label>
              <RequiredMark /> {dict['notice']+dict['isUse']}
            </Label>
            <TableCheckBox check={isUse} setCheck={setIsUse} />
          </FlexBox>
          
          <FlexBox >
            <Label><RequiredMark />{dictCk('alarmRepeatTime')}</Label>
            <ComboBox
              style={{ width: "130px", marginRight: 0,}}
              defaultValue={dict['select']}
              hasDefault={true}
              items={[
                { value:'1',  label: "+1"+dict['time'], },
                { value:'3',  label: "+3"+dict['time'], },
                { value:'6',  label: "+6"+dict['time'], },
                { value:'12', label: "+12"+dict['time'], },
                { value:'24', label: "+24"+dict['time'], },
              ]}
              value={alarmCycleTime}
              setValue={setAlarmCycleTime}
            />
          </FlexBox>

          <FlexBox alignItems="flex-start">
            <Label>
            {dict['remark']}
            </Label>
            <TextAreaBox width={0} value={remark} setValue={setRemark}></TextAreaBox>
          </FlexBox>    

          <FlexBox>
            <Label>
            {dictCk('sendManager')}
            </Label>
            <TableCheckBox check={isManager} setCheck={setIsManager} />
          </FlexBox>    

          <FlexView alignItems="flex-start">
          <RequiredMark />
              <Label>{dict['recipient']}</Label>
              <div style={{ overflowY: "auto", flex: 1, maxHeight: 35 * 3 }}>
                {userList.map((i, idx) => (
                  <CheckComponent
                    key={idx}
                    text={i.userName}
                    check={i.isCheck == 1}
                    setCheck={(bool) => {
                      const temp = [...userList]
                      temp[idx].isCheck = bool ? 1 : 0
                      setUserList(temp)
                    }}
                  />
                ))}
              </div>
            </FlexView>
        
        </div>

        <ButtonBox>
          <Button
            bgColor={"#fff"}
            hover={"rgba(174, 174, 174, 0.22)"}
            border={"1px solid #c6c6c6"}
            color={"#555555cc"}
            onClick={() => onClose(dict['cancel'], null)}
          >
            {dict['cancel']}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {dict['save']}
          </Button>
        </ButtonBox>
      </Container>
    </Dialog>
  )
}

EmergencyAlarmAddPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

const Container = styled.div`
  padding: 23px 40px 27px;
`
const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
`

const FlexBox2 = styled.div`
  display: ${(props) => props.display};
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
`

const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  width: 147px;
  height: 40px;
  margin-left: 14px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`
const FlexView = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
`

const CheckComponent = ({ text, check, setCheck, style = {} }) => {
  return (
    <StyledCheckBox style={{ ...style }} onClick={() => setCheck(!check)}>
      <CheckIcon src={check ? icon_checkBasic_select : icon_checkBasic} />
      <CheckLabel>{text}</CheckLabel>
    </StyledCheckBox>
  )
}
const StyledCheckBox = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 35px;
`
const CheckIcon = styled.img`
  width: 22px;
  height: 22px;
  margin-right: 9px;
`
const CheckLabel = styled.p`
  opacity: 60%;
  margin-bottom: 2px;
  color: #555555;
  font-size: 14px;
`

export default CommonContainer(InterLockSettingContainer(withTranslation()(EmergencyAlarmAddPopup)))
