// 판매관리 > 판매관리(찐)
import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { downExcel, dateFormat } from "../../../utils/util"

import CommonContainer from "../../../containers/CommonContainer"
import SalesContainer from "../../../containers/SalesContainer"

import SalesInfoPopup from "../../popup/SalesManage/SalesInfoPopup"

import { SkyButton, YellowButton, GrayButton } from "../../component/common/Button"
import PageName from "../../component/common/PageName"
// import ComboBox from "../../component/common/ComboBox"
import { TableComponent } from "../../component/common/Table"
import DateBox from "../../component/common/DateBox"
import InputBox from "../../component/common/InputBox"
import CodeHelpBox from "../../component/common/CodeHelpBox"

// import icon_search from "images/search.png"
// import CodeHelpItemPopup from "../../popup/CodeHelpItemPopup"

const SalesRegister = (props) => {
  
  const { t, initDataList, getDataList, saveDataList, setDataList, getComboList, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  
  const { 
    salesList: dataList, 
    SALES_LIST: actionType, 
    DEL_SALES_INFO 
  } = props
  const dict = _translationWords[0] // 변수에 사전 담기

  const salesOutStatusList = [
    { value: 1, label: dict['incomplete'] },
    { value: 2, label: dict['complete'] },
  ]

  const [isOpenPopup, setIsOpenPopup] = useState(false)

  const [salesSeq, setSalesSeq] = useState(0)

  const temp1 = dateFormat(new Date(), "YYYYMM01")
  const temp2 = dateFormat(new Date(), "YYYYMMDD")

  const [dateFr, setDateFr] = useState(temp1)
  const [dateTo, setDateTo] = useState(temp2)

  const [salesNo, setSalesNo] = useState("")
  // const [codeHelpItemPopup, setCodeHelpItemPopup] = useState(false)
  // const [item, setItem] = useState({ name: "", seq: 0 })
  // const [custSeq, setCustSeq] = useState( 0 )
  const [cust, setCust] = useState({ value: 0, label: "" }) // 코드도움 (판매거래처)
  const [itemName, setItemName] = useState("")
  const [itemNameCode, setItemNameCode] = useState("")

  const [minDate, setMinDate] = useState(new Date()) //날짜 최저일
  const [maxDate, setMaxDate] = useState(new Date()) //날짜 최대일

  useEffect(() => {
    getTranslationWords()
    getData()

    return async () => await initDataList(actionType)
  }, [])

  useEffect(() => {
    if(itemNameCode != ''){
      setItemName(itemNameCode['label'])
    }
  }, [itemNameCode])

  // 달력 날짜 제한 관련
  useEffect(() => {

    // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
    const originalDate = new Date(dateFr.substr(0, 4) + '-' + dateFr.substr(4, 2) + '-' + dateFr.substr(6, 2));
    const modifiedDate = originalDate.setDate(originalDate.getDate() + 10000);

    setMinDate(new Date(dateFr.substr(0,4)+'-'+dateFr.substr(4,2)+'-'+dateFr.substr(6,2)));
    setMaxDate(modifiedDate);
    // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
    // console.log('dateFr(1) : ', dateFr)
    // console.log('dateTo(1) : ', dateTo)

  }, [dateFr])

  const getData = async () => {}

  const onClickSearch = async () => {
    const data = {
      dateFr,
      dateTo,
      salesNo,
      custSeq: cust.value,
      itemName,
    }

    await getDataList(actionType, data)
  }

  const closePopup = async () => {
    setIsOpenPopup(false)

    // getData()
    onClickSearch()
  }

  const onClickInfo = async (salesSeq) => {
    // 정보 (추가/수정)

    setSalesSeq(salesSeq)

    setIsOpenPopup(true)
  }

  const onClickDelete = async (salesSeq) => {
    // 정보 (삭제)

    let data = { salesSeq }

    if (await saveDataList(DEL_SALES_INFO, data)) onClickSearch()
  }

  return (
    <Container>
      <SalesInfoPopup open={isOpenPopup} onClose={closePopup} salesSeq={salesSeq} />
      <FlexBox justifyContent={"space-between"}>
        <PageName name={dict['salesManage']} state={false} setState={() => {}} />
        {/* <GrayButton onClick={onClickExcelDownload}>엑셀 다운로드</GrayButton> */}
      </FlexBox>
      <Box>
        <WhiteBox>
          <SearchBox>
            <div>
              <Text>{dict['saleDate']}</Text>
              <DateBox stdDate={dateFr} setStdDate={setDateFr} style={{ marginRight: "5px", width: "120px" }} />
              <Text style={{ width: "15px" }}>~</Text>
              <DateBox stdDate={dateTo} setStdDate={setDateTo} style={{ width: "120px" }} minDate={minDate} maxDate={maxDate} />
              <Text>{dict['saleNo']}</Text>
              <InputBox type="text" value={salesNo} onChange={(e) => setSalesNo(e.target.value)} />
            </div>
            <FlexBox>
              <YellowButton onClick={onClickSearch}>{dict['search']}</YellowButton>
              <SkyButton style={{ marginLeft: "14px" }} onClick={() => onClickInfo(0)}>
                {dict['new']}
              </SkyButton>
            </FlexBox>
          </SearchBox>
          <ClassesBox>
            <div>
              <Text>{dict['salesCustomer']}</Text>
              {/* <InputBox type="text" value={custSeq} onChange={(e) => setCustSeq(e.target.value)} style={{width:'260px'}} /> */}
              <CodeHelpBox
                style={{ width: "260px" }}
                placeholder={dict['select']}
                codeHelpSeq={2}
                codeHelpParam={{ custKind: 10060001 }} // 판매거래처
                value={cust}
                setValue={setCust}
              />
              <Text>{dict['itemName']}</Text>
              {/* <InputBox type="text" value={itemName} onChange={(e) => setItemName(e.target.value)} /> */}
              <CodeHelpBox // inputBox에서 CodeHelpBox로 변경 - 차건담 2023.07.13
                style={{}}
                placeholder={dict['itemName']}
                codeHelpSeq={1}
                // codeHelpParam={{}}
                value={itemNameCode}
                setValue={setItemNameCode}
               />
            </div>
          </ClassesBox>
          <TableComponent
            headList={[
              { title: dict['saleNo'] , type: "text" },
              { title: dict['saleDate'] , type: "date" },
              { title: dict['shipStatus'] , type: "" },
              { title: dict['cropName'] , type: "text" },
              { title: dict['salesCustomer'] , type: "text" },
              { title: dict['itemName'] , type: "text" },
              { title: dict['unit'] , type: "text" },
              { title: dict['quantity'] , type: "comma" },
              { title: dict['price'] , type: "comma" },
              { title: dict['amt'] , type: "comma" },
              { title: dict['remark'] , type: "text" },
              { title: "Actions" },
            ]}
            bodyList={dataList.map((i) => ({
              seq: i.salesSeq,
              salesNo: i.salesNo,
              salesDate: i.salesDate,
              salesOutStatus: i.salesOutStatus > 0 ? salesOutStatusList.filter((row) => row.value == i.salesOutStatus)[0].label : "",
              cropName: i.cropName,
              custName: i.custName,
              itemName: i.itemName,
              unitName: i.unitName,
              qty: i.salesQty,
              price: i.price,
              amt: i.amt,
              remark: i.remark,
              Actions: true,
            }))}
            dict={dict}
            onClickEdit={(seq) => onClickInfo(seq)}
            onClickDelete={(seq) => onClickDelete(seq)}
          />
        </WhiteBox>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  margin-top: 30px;
  padding: 28px 32px 32px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`
const ClassesBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

export default CommonContainer(SalesContainer(withTranslation()(SalesRegister)))
