import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  initAutoEnvWorkCenterList,
  getAutoEnvWorkCenterList,
  initAutoEnvEquipList,
  getAutoEnvEquipList,
  initAutoEnvEquip,
  getAutoEnvEquip,
  getSensorData,

  addAutoEnvEquipTime,
  initAutoEnvEquipTime,
  getAutoEnvEquipTime,
  editAutoEnvEquipTime,
  delAutoEnvEquipTime,
  editAutoEnvConfEquip,
  editAutoEnvConfEquipTemp,
} = actions.EnvControlAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _autoEnvWorkCenterList: state.EnvControlReducer._autoEnvWorkCenterList,
  _autoEnvEquipList: state.EnvControlReducer._autoEnvEquipList,
  _autoEnvEquip: state.EnvControlReducer._autoEnvEquip,
  _sensorData: state.EnvControlReducer._sensorData,

  _autoEnvEquipTime: state.EnvControlReducer._autoEnvEquipTime,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  initAutoEnvWorkCenterList: (param) => dispatch(initAutoEnvWorkCenterList(param)),
  getAutoEnvWorkCenterList: (param) => dispatch(getAutoEnvWorkCenterList(param)),
  initAutoEnvEquipList: (param) => dispatch(initAutoEnvEquipList(param)),
  getAutoEnvEquipList: (param) => dispatch(getAutoEnvEquipList(param)),
  initAutoEnvEquip: (param) => dispatch(initAutoEnvEquip(param)),
  getAutoEnvEquip: (param) => dispatch(getAutoEnvEquip(param)),
  getSensorData: (param) => dispatch(getSensorData(param)),

  addAutoEnvEquipTime: (param) => dispatch(addAutoEnvEquipTime(param)),
  initAutoEnvEquipTime: (param) => dispatch(initAutoEnvEquipTime(param)),
  getAutoEnvEquipTime: (param) => dispatch(getAutoEnvEquipTime(param)),
  editAutoEnvEquipTime: (param) => dispatch(editAutoEnvEquipTime(param)),
  delAutoEnvEquipTime: (param) => dispatch(delAutoEnvEquipTime(param)),
  editAutoEnvConfEquip: (param) => dispatch(editAutoEnvConfEquip(param)),
  editAutoEnvConfEquipTemp: (param) => dispatch(editAutoEnvConfEquipTemp(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
