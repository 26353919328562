// 생산관리 > 생산수확조회
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import { withTranslation } from "react-i18next"
import ProductionInquiryContainer from "../../../containers/production/ProductionInquiryContainer"
import PageName from "../../component/common/PageName"
import InputBox from "../../component/common/InputBox"
import { YellowButton } from "../../component/common/Button"
import { TableComponent } from "../../component/common/Table"
import Tab from "../../component/common/Tab"

import CommonContainer from "../../../containers/CommonContainer"

import CodeHelpBox from "../../component/common/CodeHelpBox"

const ProductionInquiry = (props) => {
  const {
    t,
    getProductionHarvList,
    getProductionHarvWorkCenterList,
    getProductionDayList,
    setProductionHarvList,
    setProductionHarvWorkCenterList,
    setProductionDayList,
    _productionHarvList,
    _productionHarvWorkCenterList,
    _productionDayList,

    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,
  } = props
  const [startYear, setStartYear] = useState(moment().format("YYYY"))
  const [cropItemName, setCropItemName] = useState("")
  const [tabIndex, setTabIndex] = useState(0)
  const [clickCenterList, setClickCenterList] = useState(null)
  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {
    getTranslationWords()
    return async () => {
      await setProductionHarvList([])
      await setProductionHarvWorkCenterList([])
      await setProductionDayList([])
    }
  }, [])

  const getData = async () => {
    await getProductionHarvList({ startYear: startYear, itemName: typeof cropItemName == 'object' ? cropItemName['label'] : cropItemName}) // codeHelp 사용으로 JSON 객체의 label값 사용 - 차건담 2023.08.02
  }
  const onClickSearch = async () => {
    getData()
  }

  const onClickCrop = async (item) => {
    await getProductionHarvWorkCenterList({ cropSeq: item.seq })
    await getProductionDayList({ cropSeq: item.seq })
    setClickCenterList(item)
  }

  // 작업장별 총합
  const centerAmt = (data) => {
    let tempArr = []
    let tempCropPlan = 0
    let tempHarvPlan = 0
    let tempHarvQty = 0
    let temp = { workCenterName: dict['sum'], tempCropPlan: 0, tempHarvPlan: 0, tempHarvQty: 0 }
    if (data.length > 0) {
      for (let i = 0; i < Object.keys(data).length; i++) {
        tempCropPlan += data[i].cropPlanQty
        tempHarvPlan += data[i].harvPlanQty
        tempHarvQty += data[i].harvQty
      }
      temp.cropPlanQty = tempCropPlan
      temp.harvPlanQty = tempHarvPlan
      temp.harvQty = tempHarvQty
    }
    tempArr.push(temp, ...data)
    return tempArr
  }
  // 일별 합계
  const dayAmt = (data) => {
    let tempArr = []
    let tempHarvPlan = 0
    let tempHarvQty = 0
    let temp = { date: dict['sum'], tempHarvPlan: 0, tempHarvQty: 0 }
    if (data.length > 0) {
      for (let i = 0; i < Object.keys(data).length; i++) {
        tempHarvPlan += data[i].harvPlanQty
        tempHarvQty += data[i].harvQty
      }
      temp.harvPlanQty = tempHarvPlan
      temp.harvQty = tempHarvQty
    }
    tempArr.push(temp, ...data)
    return tempArr
  }

  return (
    <Container>
      <PageName name={dict['productionInquiry']} state={false} setState={() => {}} />
      <TopWhiteBox>
        <FlexBox justifyContent="space-between">
          <FlexBox>
            <Text>{dict['cropYearFr']}</Text>
            <InputBox
              style={{ width: "100px", marginLeft: "20px" }}
              useComma={false}
              type="text"
              value={startYear}
              onChange={(e) => setStartYear(e.target.value)}
              maxLength={4}
            />
            <Text>{dict['itemName']}</Text>
            {/* <InputBox
              style={{ width: "200px", marginLeft: "20px" }}
              type="text"
              value={cropItemName}
              onChange={(e) => setCropItemName(e.target.value)}
            /> */}
            <CodeHelpBox // inputBox에서 CodeHelpBox로 변경 - 차건담 2023.07.13
              style={{width: "200px", marginLeft: "20px"}}
              placeholder={dict['itemName']}
              codeHelpSeq={1}
              // codeHelpParam={{}}
              value={cropItemName}
              setValue={setCropItemName}
            />
          </FlexBox>

          <YellowButton onClick={onClickSearch}>{dict['search']}</YellowButton>
        </FlexBox>

        <TableComponent
          headList={[
            { title: dict['cropName'], type: "text" },
            { title: dict['cropDateFr'], type: "date" },
            { title: dict['cropDateTo'], type: "date" },
            { title: dict['itemName'], type: "text" },
            { title: dict['productionUnit'], type: "text" },
            { title: dict['productionPlanQuantity'], type: "comma" },
            { title: dict['harvestPlanQuantity'], type: "comma" },
            { title: dict['cropQty'], type: "comma" },
            { title: dict['remark'], type: "text" },
          ]}
          bodyList={(_productionHarvList || []).map((i) => ({
            seq: i.cropSeq,
            cropName: i.cropName,
            cropDateFr: i.cropDateFr,
            cropDateTo: i.cropDateTo,
            itemName: i.itemName,
            cropUnitNamee: i.cropUnitNamee,
            harvPlanQty: i.cropPlanQty,
            harvQty: i.harvPlanQty,
            cropPlanQty: i.harvQty,
            remark: i.remark,
          }))}
          dict={dict}
          onClick={onClickCrop}
        />
      </TopWhiteBox>
      <BottomWhiteBox>
        <FlexBox>
          <Text>{dict['cropName']}</Text>
          <InputBox
            style={{ width: "200px", marginLeft: "20px" }}
            type="text"
            value={clickCenterList ? clickCenterList.cropName : ""}
            onChange={() => {}}
            disabled
          />
        </FlexBox>
        <FlexBox>
          <Tab
            list={[
              { workCenterSeq: 0, workCenterName: dict['byWorkplace'] },
              { workCenterSeq: 1, workCenterName: dict['byDay'] },
            ].map((i) => ({ seq: i.workCenterSeq, name: i.workCenterName }))}
            tabIndex={tabIndex}
            onClick={(i) => {
              setTabIndex(i.seq)
            }}
          />
        </FlexBox>
        {tabIndex == 0 ? (
          <TableComponent
            style={{ marginTop: "3px" }}
            headList={[
              { title: dict['workCenter'], type: "text" },
              { title: dict['productionPlanQuantity'], type: "comma" },
              { title: dict['harvestPlanQuantity'], type: "comma" },
              { title: dict['cropQty'], type: "comma" },
            ]}
            bodyList={
              _productionHarvWorkCenterList.length > 0
                ? centerAmt(_productionHarvWorkCenterList || []).map((i, idx) => ({
                    seq: i.workCenterSeq,
                    workCenterName: i.workCenterName,
                    cropPlanQty: i.cropPlanQty ? i.cropPlanQty : 0,
                    harvPlanQty: i.harvPlanQty ? i.harvPlanQty : 0,
                    harvQty: i.harvQty ? i.harvQty : 0,
                  }))
                : []
            }
            dict={dict}
          />
        ) : (
          <TableComponent
            style={{ marginTop: "3px" }}
            headList={[
              { title: dict['date'], type: "text" },
              { title: dict['harvestPlanQuantity'], type: "comma" },
              { title: dict['cropQty'], type: "comma" },
            ]}
            bodyList={
              _productionDayList.length > 0
                ? dayAmt(_productionDayList || []).map((i, idx) => ({
                    seq: idx,
                    date: i.date,
                    harvPlanQty: i.harvPlanQty ? i.harvPlanQty : 0,
                    harvQty: i.harvQty ? i.harvQty : 0,
                  }))
                : []
            }
            dict={dict}
          />
        )}
      </BottomWhiteBox>
    </Container>
  )
}

export default ProductionInquiryContainer(CommonContainer(withTranslation()(ProductionInquiry)))

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  &:after {
    content: "";
    display: block;
    height: 42px;
    width: 100%;
  }
`
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const TopWhiteBox = styled.div`
  background-color: #fff;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: 350px;
  margin-top: 30px;
  padding: 28px 35px 31px;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const BottomWhiteBox = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 551px;
  height: 322px;
  margin-top: 30px;
  padding: 28px 35px 31px;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const Text = styled.p`
  color: #555555;
  font-size: 14px;
`
