import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  initGateWayList,
  getGateWayList,
  addGateWay,
  initGateWay,
  getGateWay,
  editGateWay,
  delGateWay,
  downloadGateWayListExcel,
} = actions.DefaultInfoAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _gateWayList: state.DefaultInfoReducer._gateWayList,
  _gateWay: state.DefaultInfoReducer._gateWay,
  _codeList: state.SystemSettingReducer._codeList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  initGateWayList: (param) => dispatch(initGateWayList(param)),
  getGateWayList: (param) => dispatch(getGateWayList(param)),
  addGateWay: (param) => dispatch(addGateWay(param)),
  initGateWay: (param) => dispatch(initGateWay(param)),
  getGateWay: (param) => dispatch(getGateWay(param)),
  editGateWay: (param) => dispatch(editGateWay(param)),
  delGateWay: (param) => dispatch(delGateWay(param)),
  downloadGateWayListExcel: (param) => dispatch(downloadGateWayListExcel(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
