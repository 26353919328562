// 일반 차트 컴포넌트 || 최종 수정일자 - 차건담 2023.06.16
import React, { useRef, useEffect, useState, Component } from 'react';
import ApexCharts from 'apexcharts';
import ChartContainer from '../../../containers/statusInfo/ChartContainer';
import PropTypes from "prop-types"
import './chart.css'

const MyChart = (props) => {
  const { chartDataList, // 차트 데이터 리스트
          seriesList,    // 시리즈 데이터 리스트 (그래프의 값)
          categoryList,  // x축 값 리스트
          isChanged,     // 차트 데이터의 값 변경 유무 키값
          tooltipList,   // tooltip 데이터 리스트 (tooltip은 그래프에 커서가 올라가면 나타나는 박스)
          sensorKind,    // 센서종류 seq 번호
          sensorMinMax,  // 센서 데이터의 최소값과 최대값
          targetValues,   // 목표 최대값 및 최소값 리스트

        } = props
        
  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);

  const [seriesListFinal, setSeriesListFinal] = useState([]);
  const [tooltipListFinal, setTooltipListFinal] = useState({max:[], min:[]});
  const [valueUnit, setValueUnit] = useState('')
  const [sensorName, setSensorName] = useState('')
  const [minMax, setMinMax] = useState([0,100])

  // 센서 데이터의 최소값과 최대값으로 y축의 최소값 및 최대값을 계산하여 return하는 함수
  const calMinMaxFinal = (x,y) => {
    const range = Math.abs(x - y);
    //스케일 값의 간격을 결정하고 값이 5 또는 10의 배수인지 확인
    let interval;
    let scaleNum;
    if (range > 60) {
      //interval = Math.ceil(range / 10 / 10) * 10; // Interval as a multiple of 10
      interval = 10
    } else {
      //interval = Math.ceil(range / 10 / 5) * 5; // Interval as a multiple of 5
      interval = 5
    }

    if (range <= 5) {
      //interval = Math.ceil(range / 10 / 10) * 10; // Interval as a multiple of 10
      interval = 1
    } else if (range <= 60){
      //interval = Math.ceil(range / 10 / 5) * 5; // Interval as a multiple of 5
      interval = 5
    } else if (range <= 200) {
      interval = 10
    } else {
      interval = 100
    } 

    //x 및 y 기호를 고려하여 Y축 최소값과 최대값을 결정
    let yAxisMin = Math.floor(y / interval) * interval;
    let yAxisMax = Math.ceil(x / interval) * interval;

    scaleNum = (yAxisMax - yAxisMin)/interval

    return [yAxisMin, yAxisMax, scaleNum]
  }

  //
  useEffect(() => {
    // let minMax = [0,100] // y축의 최소값 및 최대값 기본값
    if(sensorMinMax != undefined && sensorMinMax != null && sensorMinMax.length>0){
      let maxData
      let minData
      for(let i in sensorMinMax){
        if(i==0){ // 최초의 반복문 실행에서 maxData와 minData에 초기값 설정
          maxData = sensorMinMax[i]['maxData']
          minData = sensorMinMax[i]['minData']
        } else { // 이후에는 이전의 최대값 및 최소값과 비교하며 전체 리스트의 최대값 및 최소값 설정
          if(maxData < sensorMinMax[i]['maxData']){
            maxData = sensorMinMax[i]['maxData']
          }
          if(minData > sensorMinMax[i]['minData']){
            minData = sensorMinMax[i]['minData']
          }
        }
      }
      // minMax = calMinMaxFinal(maxData, minData)
      setMinMax(calMinMaxFinal(maxData, minData))
    } 

    let localSeriesFinal = []                // 최종 series 리스트
    let localTooltipFinal = {max:[], min:[]} // 최종 tooltip 리스트
    
    // targetValues에 값이 있다면 최대 목표값 및 최소 목표값을 그래프로 추가해주기 위해서 series에 추가해주는 코드
    // =====================================================================================================
      if(targetValues.length>0 && isChanged[0]==1){
        let localSeriesList = []
        let localTooltipList = {max:[], min:[]}
        
        let newSeriesMax = {}
        let newSeriesMin = {}

        for(let targetValue of targetValues){
          if(seriesList.length > 0){
            newSeriesMax['name'] = targetValue.sensorNodeName+"-"+String(targetValue.sensorKindName)+" 최대 목표값"
            newSeriesMin['name'] = targetValue.sensorNodeName+"-"+String(targetValue.sensorKindName)+" 최소 목표값"
            
            let newSeriesMaxSensorValue = []
            let newSeriesMinSensorValue = []

            for(let i in seriesList[0].data){
              newSeriesMaxSensorValue.push(targetValue.targetValueMax)
              newSeriesMinSensorValue.push(targetValue.targetValueMin)
            }

            newSeriesMax['data'] = newSeriesMaxSensorValue
            newSeriesMin['data'] = newSeriesMinSensorValue
          }
          
          if(targetValue.targetValueMax != null){
            localSeriesList.push(newSeriesMax)
            localTooltipList['max'].push(newSeriesMax)
            localTooltipList['min'].push(newSeriesMax)
          }
          if(targetValue.targetValueMin != null){
            localSeriesList.push(newSeriesMin)
            localTooltipList['max'].push(newSeriesMin)
            localTooltipList['min'].push(newSeriesMin)
          }
          newSeriesMax = {}
          newSeriesMin = {}
        }

        // seriesListFinal = seriesList.concat(localSeriesList)
        // tooltipListFinal = {
        //                       max: tooltipList['max'].concat(localTooltipList['max']),
        //                       min: tooltipList['min'].concat(localTooltipList['min'])
        //                     }      
        
        if(seriesList.length > 0){
          localSeriesFinal = seriesList.concat(localSeriesList)
        } else {
          localSeriesFinal = seriesList
        }
        if(Object.keys(tooltipList).length > 0){
          localTooltipFinal  = {
            max: tooltipList['max'].concat(localTooltipList['max']),
            min: tooltipList['min'].concat(localTooltipList['min'])
          }   
        } else {
          localTooltipFinal = tooltipList
        }
      } else {
        // seriesListFinal = seriesList
        // tooltipListFinal = tooltipList
        // console.log("seriesListFinal >>>>>>>>>>>> ", seriesListFinal)
        localSeriesFinal = seriesList
        localTooltipFinal = tooltipList
      }

      setSeriesListFinal(localSeriesFinal)
      setTooltipListFinal(localTooltipFinal)
    // =====================================================================================================

    setValueUnit( //센서값 단위
                  sensorKind == 10150001 ? '℃' :
                  sensorKind == 10150002 ? '%RH' :
                  sensorKind == 10150004 ? 'ppm' :
                  sensorKind == 10150005 ? 'pH' :
                  sensorKind == 10150006 ? 'dS/m' :
                  sensorKind == 10150007 ? '' :
                  sensorKind == 10150008 ? '°' :
                  sensorKind == 10150009 ? 'm/s' :
                  sensorKind == 10150010 ? 'W/㎡' :
                  sensorKind == 10150011 ? '' :
                  sensorKind == 10150012 ? 'g/㎥' : ""
                )

    setSensorName( //센서명
                  sensorKind == 10150001 ? '온도':
                  sensorKind == 10150002 ? '습도':
                  sensorKind == 10150004 ? 'CO2':
                  sensorKind == 10150005 ? 'pH':
                  sensorKind == 10150006 ? 'EC':
                  sensorKind == 10150007 ? '유량':
                  sensorKind == 10150008 ? '풍향':
                  sensorKind == 10150009 ? '풍속':
                  sensorKind == 10150010 ? '일사':
                  sensorKind == 10150011 ? '감우':
                  sensorKind == 10150012 ? '수분부족량': ""
                 )


    function handleLegendItemClick(seriesIndex) {
    console.log("test"); // "test"를 콘솔에 출력
  
    // 추가적인 동작 수행
    }

    // 차트 옵션 설정
    const options = {
      chart: {
        type: 'line', // 차트 유형 설정: 선 그래프
        zoom: {
          enabled: true, // 확대/축소 활성화
          type: 'x', // x축 방향으로만 확대/축소 활성화
          autoScaleYaxis: false // 확대/축소 시 자동으로 y축 범위 조절 활성화
        },
        animations: {
          enabled: false, // 차트 애니메이션 비활성화
        },
      },
      series: localSeriesFinal, // 시리즈 데이터
      xaxis: { //x축 카테고리 데이터
        categories: categoryList,
      },
      // y축 설정
      yaxis: {
        min: minMax[0], // y축 최소값 설정
        max: minMax[1], // y축 최대값 설정
        tickAmount: minMax[2], // y축 눈금 갯수 설정
        labels: {
          formatter: (value) => {
            // y축 눈금 레이블 형식 설정
            return value + valueUnit; // 값 뒤에 단위를 붙여서 표시
          }
        }
      },
      tooltip: {
        zIndex: 10000, // 툴팁의 Z-index 설정
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          // custom 함수는 툴팁의 내용을 직접 제어하기 위해 사용되는 함수입니다.
          
          // 필요한 변수들을 초기화합니다.
          let result = ""; // 툴팁 내용을 저장할 변수
          // const generateResult = (series, dataPointIndex, categoryList, sensorName, valueUnit, tooltipListFinal, seriesList) => {
          const generateResult = (series, dataPointIndex, categoryList, sensorName, valueUnit, tooltipList, seriesList) => {
            // 툴팁 내용을 생성하는 함수 정의
            
            // 센서명 길이에 따라 다른 스타일의 박스를 생성합니다.
            const arrowBox = sensorName.length > 4 ? '<div class="arrow_boxLong">' : '<div class="arrow_box">';
            const avgTitle = sensorName.length > 4 ? "<div class='tooltipLabelAvgDivLong'>" : "<div class='tooltipLabelAvgDiv'>"
            const maxTitle = sensorName.length > 4 ? "<div class='tooltipLabelHighDivLong'>최고" : "<div class='tooltipLabelHighDiv'>최고"
            const minTitle = sensorName.length > 4 ? "<div class='tooltipLabelLowDivLong'>최저" : "<div class='tooltipLabelLowDiv'>최저"
            
            // 디버깅을 위해 로그 출력
            // console.log("tooltip >> result first:", result)
            // console.log("avg max min :", avgTitle, maxTitle, minTitle)
      
            // 툴팁의 날짜 부분을 추가합니다.
            result += arrowBox + "<div class='tooltipDivDate'>" + categoryList[dataPointIndex] + "</div>";
            
            // 시리즈 리스트를 순회하며 툴팁 데이터를 생성합니다.
            for (let i in seriesList){
              // const maxDataValue = tooltipListFinal['max'][i]['data'][dataPointIndex]
              // const minDataValue = tooltipListFinal['min'][i]['data'][dataPointIndex]
              const maxDataValue = tooltipList['max'][i]['data'][dataPointIndex]
              const minDataValue = tooltipList['min'][i]['data'][dataPointIndex]

              result += "<div class='tooltipTitleDivWrapper'><div class='tooltipTitleDiv'>" + seriesListFinal[i]['name'] +"</div>"+
                "</div>" +
                "<div class='tooltipDataWrapper'>" +
                "<div class='tooltipDataRowWrapper'>" +
                "<div class='tooltipDataRow'>" +
                avgTitle + sensorName + "</div>" +
                "<div class='tooltipValueDiv'>" + series[i][dataPointIndex] + valueUnit + "</div>" +
                "</div>" +
                "</div>" +
                "<div class='tooltipDataRowWrapper'>" +
                  "<div class='tooltipDataRow'>" +
                    maxTitle+sensorName+"</div>"+
                    // "<div class='tooltipValueDiv'>" + tooltipListFinal['max'][i]['data'][dataPointIndex] + valueUnit + "</div>" +
                    // "<div class='tooltipValueDiv'>" + tooltipList['max'][i]['data'][dataPointIndex] + valueUnit + "</div>" +
                    "<div class='tooltipValueDiv'>"+(maxDataValue == "none" ? "--" : maxDataValue+valueUnit)+"</div>"+
                  "</div>" +
                "</div>" +
                "<div class='tooltipDataRowWrapper'>" +
                  "<div class='tooltipDataRow'>" +
                    minTitle+sensorName+"</div>"+
                    // "<div class='tooltipValueDiv'>" + tooltipListFinal['min'][i]['data'][dataPointIndex] + valueUnit + "</div>" +
                    // "<div class='tooltipValueDiv'>" + tooltipList['min'][i]['data'][dataPointIndex] + valueUnit + "</div>" +
                    "<div class='tooltipValueDiv'>"+(minDataValue == "none" ? "--" : minDataValue+valueUnit)+"</div>"+
                  "</div>" +
                "</div>" +
                "</div>";
            }
            result = result + "</div>";
      
            return result;
          };
          
          // 위에서 정의한 함수를 호출하여 툴팁 내용을 반환합니다.
          // return generateResult(series, dataPointIndex, categoryList, sensorName, valueUnit, tooltipListFinal, seriesList);
          return generateResult(series, dataPointIndex, categoryList, sensorName, valueUnit, tooltipList, seriesList);
        },
      },
      stroke: {
        curve: 'smooth', // set the line style to smooth curve
        width: 2, // set the line thickness
      },
      colors:
        // 최대 목표값 및 최소 목표값의 색을 지정하기 위한 코드       
        [function({ seriesIndex, dataPointIndex, w }) {
          //시리즈의 name에 따라 색상을 지정
          if (String(w.config.series[seriesIndex].name).slice(-1) === '값') {
            return '#F08080'; // 코랄
          } else if (seriesIndex===0){
            // 나머지 시리즈에 대한 색상 지정
            return '#008FFB'; // 회색, 노랑, 보라
          } else if (seriesIndex===1){
            return '#339900';
          } else if (seriesIndex===2){
            return '#00E396';
          } else if (seriesIndex===3){
            return '#FEB019';
          } else if (seriesIndex===4){
            return '#808080';
          } else if (seriesIndex===5){
            return '#FFFF00';
          } else if (seriesIndex===6){
            return '#00E396';
          } else if (seriesIndex===7){
            return '#FEB019';
          } else if (seriesIndex===8){
            return '#A0522D';
          } else {
            return '#708080';
          }
        }],
    };

    const chartInstance = new ApexCharts(chartRef.current, options);
    if (chartInstance) {
      if (typeof window !== "undefined") {
        chartInstance.render();
      }
      setChart(chartInstance);
    }

    // clean up function
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [chartDataList]);
  //}, [chartDataList]);

  let hiddenCheckList = {} // 범례에서 각 그래프가 클릭되었을 때 그래프의 노출 상태를 담는 변수
  useEffect(() => {
    console.log("useEffect > chart:", chart)
    if (chart != null) {
      // 범례가 클릭되었을 때 콜백함수 실행 => chartContext가 클릭된 그래프의 index를 담고 있고 이를 활용한다.
      chart.w.config.chart.events.legendClick = function (event, chartContext, seriesIndex) { 
        // console.log("event:", event)
        // console.log("chartContext:", chartContext)
        // console.log("seriesIndex:", seriesIndex)
        // console.log('활성화된 그래프 개수:', activeSeriesCount);
        // const activeSeriesCount = chart.w.globals.series.filter((series) => !series.isDisabled).length;

        if (Object.keys(hiddenCheckList).length == 0) { // 페이지 렌더링 후 최초로 범례 클릭 시 클릭된 그래프의 노출상태를 true(숨겨짐)으로 hiddenCheckList 변수에 데이터 저장
          hiddenCheckList[chartContext] = {};
          hiddenCheckList[chartContext]['isHidden'] = true;
        } else { // 범례가 최초 클릭이 아닌 경우
          if (hiddenCheckList[chartContext] == null || hiddenCheckList[chartContext] == undefined) { // 범례에서 클릭된 그래프가 최초인 경우
            hiddenCheckList[chartContext] = {}
            hiddenCheckList[chartContext]['isHidden'] = true;
          } else { // 범례에서 클릭된 그래프가 최초가 아닌 경우 false인 경우 true로, true인 경우 false로 데이터 변경
            if (hiddenCheckList[chartContext]['isHidden'] == false) { 
              hiddenCheckList[chartContext]['isHidden'] = true;
            } else if (hiddenCheckList[chartContext]['isHidden'] == true) {
              hiddenCheckList[chartContext]['isHidden'] = false;
            }
          }
        }  

        console.log("hiddenCheckList:", hiddenCheckList)

        const newOptions = { // chart의 options를 업데이트 하기 위한 새로운 options 데이터
          chart: {
            type: 'line',
            zoom: {
              enabled: true,
              type: 'x', // enable zooming only on the x-axis
              autoScaleYaxis: false // auto-scale y-axis on zoom
            },
            animations:{
              enabled: false,
            },
          },
          series: seriesListFinal,
          xaxis: {
            categories: categoryList,
          },
          yaxis: {
            min: minMax[0],
            max: minMax[1],
            tickAmount: minMax[2],
            labels: {
              formatter: (value) => {
                //return value.toFixed(0) + '℃';
                return value + valueUnit;
              }
            }
          },
          tooltip: {
            zIndex:10000,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) { // 세로인 경우 세로로 tooltip 구성
              
              const activeSeries = w.globals.series.filter(series => !series.isDisabled)
              const activeSeriesCount = w.globals.series.filter(series => !series.isDisabled).length;

              let result = ""
              // const generateResult = (series, dataPointIndex, categoryList, sensorName, valueUnit, tooltipListFinal) => {
              const generateResult = (series, dataPointIndex, categoryList, sensorName, valueUnit, tooltipList) => {
                
                const arrowBox = sensorName.length > 4 ? '<div class="arrow_boxLong">' : '<div class="arrow_box">';
                const avgTitle = sensorName.length > 4 ? "<div class='tooltipLabelAvgDivLong'>" : "<div class='tooltipLabelAvgDiv'>"
                const maxTitle = sensorName.length > 4 ? "<div class='tooltipLabelHighDivLong'>최고" : "<div class='tooltipLabelHighDiv'>최고"
                const minTitle = sensorName.length > 4 ? "<div class='tooltipLabelLowDivLong'>최저" : "<div class='tooltipLabelLowDiv'>최저"

                result += arrowBox +"<div class='tooltipDivDate'>"+categoryList[dataPointIndex]+"</div>"
                for (let i in seriesList) {
                  if (hiddenCheckList[i] == null || hiddenCheckList[i] == undefined) {
                    hiddenCheckList[i] = { isHidden: false }
                  }

                  if (hiddenCheckList[i]['isHidden'] == false) {
                    // const maxDataValue = tooltipListFinal['max'][i]['data'][dataPointIndex]
                    // const minDataValue = tooltipListFinal['min'][i]['data'][dataPointIndex]
                    const maxDataValue = tooltipList['max'][i]['data'][dataPointIndex]
                    const minDataValue = tooltipList['min'][i]['data'][dataPointIndex]

                    result += "<div class='tooltipTitleDivWrapper'><div class='tooltipTitleDiv'>" + seriesListFinal[i]['name'] +"</div>"+
                    "</div>" +
                    "<div class='tooltipDataWrapper'>" +
                      "<div class='tooltipDataRowWrapper'>" +
                        "<div class='tooltipDataRow'>" +
                          avgTitle+sensorName+"</div>"+
                          "<div class='tooltipValueDiv'>"+series[i][dataPointIndex]+valueUnit+"</div>"+
                        "</div>" +
                      "</div>" +
                      "<div class='tooltipDataRowWrapper'>" +
                        "<div class='tooltipDataRow'>" +
                          maxTitle+sensorName+"</div>"+
                          // "<div class='tooltipValueDiv'>"+tooltipListFinal['max'][i]['data'][dataPointIndex]+valueUnit+"</div>"+
                          // "<div class='tooltipValueDiv'>"+tooltipList['max'][i]['data'][dataPointIndex]+valueUnit+"</div>"+
                          "<div class='tooltipValueDiv'>"+(maxDataValue == "none" ? "--" : maxDataValue+valueUnit)+"</div>"+
                        "</div>" +
                      "</div>" +
                      "<div class='tooltipDataRowWrapper'>" +
                        "<div class='tooltipDataRow'>" +
                          minTitle+sensorName+"</div>"+
                          // "<div class='tooltipValueDiv'>"+tooltipListFinal['min'][i]['data'][dataPointIndex]+valueUnit+"</div>"+
                          // "<div class='tooltipValueDiv'>"+tooltipList['min'][i]['data'][dataPointIndex]+valueUnit+"</div>"+
                          "<div class='tooltipValueDiv'>"+(minDataValue == "none" ? "--" : minDataValue+valueUnit)+"</div>"+
                        "</div>" +
                      "</div>" +
                    "</div>"
                    //if(i == (series.length-1)){}else{result += "<br>"}
                  }
                }
                result = result + "</div>"

                return result
              }

              return (
                // generateResult(series, dataPointIndex, categoryList, sensorName, valueUnit, tooltipListFinal, seriesList)
                generateResult(series, dataPointIndex, categoryList, sensorName, valueUnit, tooltipList, seriesList)
              );
            }
          }
          ,
          legend: {

          },
          stroke: {
            curve: 'smooth', // set the line style to smooth curve
            width: 2, // set the line thickness
          },
          colors:
            // 최대 목표값 및 최소 목표값의 색을 지정하기 위한 코드
            [function ({ seriesIndex, dataPointIndex, w }) {
              //시리즈의 name에 따라 색상을 지정
              if (String(w.config.series[seriesIndex].name).slice(-1) === '값') {
                return '#F08080'; // 코랄
              } else if (seriesIndex === 0) {
                // 나머지 시리즈에 대한 색상 지정
                return '#008FFB'; // 회색, 노랑, 보라
              } else if (seriesIndex === 1) {
                return '#339900';
              } else if (seriesIndex === 2) {
                return '#00E396';
              } else if (seriesIndex === 3) {
                return '#FEB019';
              } else if (seriesIndex === 4) {
                return '#808080';
              } else if (seriesIndex === 5) {
                return '#FFFF00';
              } else if (seriesIndex === 6) {
                return '#00E396';
              } else if (seriesIndex === 7) {
                return '#FEB019';
              } else if (seriesIndex === 8) {
                return '#A0522D';
              } else {
                return '#708080';
              }
            }],
        };

        chart.updateOptions(newOptions); // chart의 options 업데이트
      };

    }
  }, [chart])

  return <div ref={chartRef} className="custom-chart" style={{animation:"none"}}/>;
};

  export default ChartContainer(MyChart);