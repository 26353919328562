import i18n from "i18next";
import { initReactI18next } from "react-i18next";
// import fetch from "node-fetch";
import axios from 'axios';

import translationen from './translation.en';
import translationko from './translation.ko';
import translationvi from './translation.vi';
import translationzh from './translation.zh';
import translationtw from './translation.tw';
import translationja from './translation.ja';
// import translationetc from './translation.etc';

const resources = {

    // 한국어 |English |日本語 |简体中文 |繁體中文 |tiếng Việt

    en: {
        translation: translationen
    },
    ko: {
        translation: translationko
    },
    vi: {
        translation: translationvi
    },
    zh: {
        translation: translationzh
    },
    tw: {
        translation: translationtw
    },
    ja: {
        translation: translationja
    },
    // etc: {
    //     translation: translationetc
    // },
    
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
.init({
    resources,
    lng: "ko",
    fallbackLng: 'ko',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
        escapeValue: false // react already safes from xss
    }
});

const getJson = async ( url ) => {

    const res = await fetch( url )

    return await res.json();    
}

const setResources = async () => {

    // const translationen = await getJson( 'https://elefarm.s3.ap-northeast-2.amazonaws.com/translation.en.json' ) // 영어
    // const translationko = await getJson( 'https://elefarm.s3.ap-northeast-2.amazonaws.com/translation.ko.json' ) // 한국어
    // const translationvi = await getJson( 'https://elefarm.s3.ap-northeast-2.amazonaws.com/translation.vi.json' ) // 베트남어
    // const translationzh = await getJson( 'https://elefarm.s3.ap-northeast-2.amazonaws.com/translation.zh.json' ) // 중국어간체
    // const translationtw = await getJson( 'https://elefarm.s3.ap-northeast-2.amazonaws.com/translation.tw.json' ) // 중국어본체
    // const translationja = await getJson( 'https://elefarm.s3.ap-northeast-2.amazonaws.com/translation.ja.json' ) // 일본어
    
    const translationen = await axios({ method: 'GET', url: 'https://elefarm.s3.ap-northeast-2.amazonaws.com/translation.en.json', }) // 영어
    const translationko = await axios({ method: 'GET', url: 'https://elefarm.s3.ap-northeast-2.amazonaws.com/translation.ko.json', }) // 한국어
    const translationvi = await axios({ method: 'GET', url: 'https://elefarm.s3.ap-northeast-2.amazonaws.com/translation.vi.json', }) // 베트남어
    const translationzh = await axios({ method: 'GET', url: 'https://elefarm.s3.ap-northeast-2.amazonaws.com/translation.zh.json', }) // 중국어간체
    const translationtw = await axios({ method: 'GET', url: 'https://elefarm.s3.ap-northeast-2.amazonaws.com/translation.tw.json', }) // 중국어본체
    const translationja = await axios({ method: 'GET', url: 'https://elefarm.s3.ap-northeast-2.amazonaws.com/translation.ja.json', }) // 일본어
    // const translationetc = await axios({ method: 'GET', url: 'https://elefarm.s3.ap-northeast-2.amazonaws.com/translation.etc.json', })

    // https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
    const resources = {

        // 한국어 |English |日本語 |简体中文 |繁體中文 |tiếng Việt

        en: {
            translation: translationen.status == 200 ? translationen.data : {}
        },
        ko: {
            translation: translationko.status == 200 ? translationko.data : {}
        },
        vi: {
            translation: translationvi.status == 200 ? translationvi.data : {}
        },
        zh: {
            translation: translationzh.status == 200 ? translationzh.data : {}
        },
        tw: {
            translation: translationtw.status == 200 ? translationtw.data : {}
        },
        ja: {
            translation: translationja.status == 200 ? translationja.data : {}
        },
        // etc: {
        //     translation: translationetc.status == 200 ? translationetc.data : {}
        // },
        
    };

    i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "ko",
        fallbackLng: 'ko',
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

}

setResources();

export default i18n;
