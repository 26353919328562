// 현황정보 > 온습도/배지온도통계
import React from "react"
import { withTranslation } from "react-i18next"
import IncomeInquiryContainer from "../../../containers/IncomeInquiry/IncomeInquiryContainer"

const TemperatureStatistics = () => {
  return <div></div>
}

export default IncomeInquiryContainer(withTranslation()(TemperatureStatistics))
