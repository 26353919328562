// 작기관리 > 작기마감
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import { withTranslation } from "react-i18next"
import { downExcel } from "../../../utils/util"
import CropCloseContainer from "../../../containers/cropManage/CropCloseContainer"
import PageName from "../../component/common/PageName"
import InputBox from "../../component/common/InputBox"
import { YellowButton, GrayButton } from "../../component/common/Button"
import { TableComponent } from "../../component/common/Table"
import TableCheckBox from "../../component/common/TableCheckBox"
import BasicPopup from "../../popup/BasicPopup"

import CommonContainer from "../../../containers/CommonContainer"

const CropClose = (props) => {
  const { t, _cropCloseList, initCropCloseList, getCropCloseList, editCropClose, downloadCropCloseListExcel, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [searchText, setSearchText] = useState(moment().format("YYYY"))
  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {
    getTranslationWords()
    return async () => await initCropCloseList()
  }, [])

  const getData = async () => {
    await getCropCloseList({ startYear: searchText })
  }

  const onClickExcelDownload = async () => {
    const result = await downloadCropCloseListExcel()
    downExcel({ result: result, fileName: dict['cropCloseList'] })
  }

  const onClickSearch = async () => {
    if (searchText != "" && searchText.length != 4) return
    await getCropCloseList({ startYear: searchText })
  }

  const onClickCheckBox = async (cropSeq, isClose, isUse) => {
    const result = await editCropClose({ cropSeq, isClose, isUse })
    if (result) getData()
  }

  return (
    <Container>
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: popup.text })} content={popup.text} selectedValue={[dict['confirm']]} />
      <FlexBox justifyContent="space-between">
        <PageName name={dict['cropClose']} state={false} setState={() => {}} />
        <GrayButton onClick={onClickExcelDownload}>{dict['downloadExcelFile']}</GrayButton>
      </FlexBox>
      <Box>
        <WhiteBox>
          <FlexBox justifyContent="space-between">
            <FlexBox>
              <Text>{dict['cropYearFr']}</Text>
              <InputBox type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} maxLength={4} />
            </FlexBox>
            <div>
              <YellowButton onClick={onClickSearch}>{dict['search']}</YellowButton>
            </div>
          </FlexBox>

          <TableComponent
            headList={[
              { title: dict['ranking'] },
              { title: dict['stdCropName'], type: "text" },
              { title: dict['cropDateFr'], type: "date" },
              { title: dict['cropDateTo'], type: "date" },
              { title: dict['itemName'], type: "text" },
              { title: dict['cropClose'] },
              { title: dict['isUse'] },
              { title: dict['regDate'], type: "date" },
            ]}
            bodyList={_cropCloseList.map((i, idx) => ({
              seq: i.cropSeq,
              // no: _cropCloseList.length - idx,
              no: 1 + idx,
              cropName: i.cropName,
              cropDateFr: i.cropDateFr,
              cropDateTo: i.cropDateTo,
              itemName: i.itemName,
              isClose: <TableCheckBox check={i.isClose == 1} setCheck={(bool) => onClickCheckBox(i.cropSeq, bool ? 1 : 0, i.isUse)} />, // 작기마감
              isUse: <TableCheckBox check={i.isUse == 1} setCheck={(bool) => onClickCheckBox(i.cropSeq, i.isClose, bool ? 1 : 0)} />,   // 사용여부
              regDate: i.regDate,
            }))}
            dict={dict}
          />
        </WhiteBox>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
  width: 100%;
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
  margin-top: 30px;
  padding: 28px 32px 31px;
`
const Text = styled.p`
  width: 98px;
  color: #555555;
  font-size: 14px;
`

export default CropCloseContainer(CommonContainer(withTranslation()(CropClose)))
