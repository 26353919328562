// 인력관리 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import imageApis from "../../../apis/ImageApis"

import CommonContainer from "../../../containers/CommonContainer"
import StaffManagementContainer from "../../../containers/production/StaffManagementContainer"

import BasicPopup from "../../popup/BasicPopup"
import RequiredMark from "../../component/RequiredMark"
import InputBox from "../../component/common/InputBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import AttchImageBox from "../../component/common/AttchImageBox"
import ComboBox from "../../component/common/ComboBox"

import CircularIndeterminate from "../../component/common/CircularIndeterminate" //로딩화면

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        margin: "0 auto",
        "& .MuiDialog-paperFullWidth": {
            width: "100%",
        },
        "& .MuiPaper-root": {
            backgroundColor: "#fff",
            maxWidth: "960px",
        },
        "& .MuiDialog-paper": {
            margin: "0px",
        },
    },
}))

const StaffManagementAddPopup = (props) => {
    const classes = useStyles()

    const { t, initTranslationWords, getTranslationWords, _translationWords, replaceWord } = props

    const { addStaff, editStaff, infoItem } = props

    const { onClose, open } = props

    const [staffUniqueNumber, setStaffUniqueNumber] = useState("") //사번
    const [staffName, setStaffName] = useState("") //성명
    const [staffNumber, setStaffNumber] = useState("") //전화번호
    const [staffAddress, setStaffAddress] = useState("") //주소
    const [gender, setGender] = useState("") //성별
    const [remark, setRemark] = useState("") //비고
    const [attachImages, setAttachImages] = useState([]) //신분중이미지
    const [popup, setPopup] = useState({ open: false, text: "" })
    const dict = _translationWords[0] // 변수에 사전 담기

    const [loading, setLoading] = useState(false) //사진업로드 중에 로딩화면

    useEffect(() => {
        getTranslationWords()
    }, [open])

    const onClickSave = async () => {
        // console.log('>>>>>>>>>>>>>>') //김한중
        // console.log(staffName)        //김한중

        if (checkRequiredField()) {
            let images = []
            if (attachImages.length > 0) {
                //기존 이미지 데이터 있을떄 다시 배열
                const formData = new FormData()

                for (let i = 0; i < attachImages.length; i++) {
                    if (attachImages[i].file) {
                        formData.append("images", attachImages[i].file)

                        // console.log("@@@@@@@@@@@@@attachImages::", attachImages) //사이즈 체크 가능
                        // console.log("@@@@@@@@@@@@@attachImages[i].file::", attachImages[i].file) //사이즈 체크 가능
                    } else {
                        images.push({ fileUrl: attachImages[i].imageUrl, fileName: attachImages[i].fileName, filekey: attachImages[i].key })
                    }
                } // end for
                const uploadsResult = await imageApis.uploadImages(formData)
                images.push(...uploadsResult.data.images)

                // console.log('images') //김한중
                // console.log(images)   //김한중
            }

            let data = {
                // staffSeq:0,
                staffUniqueNumber: staffUniqueNumber,
                staffName: staffName,
                staffNumber: staffNumber,
                staffAddress: staffAddress,
                gender: gender,
                remark: remark,
                imageUris: images.length > 0 ? images.map((i) => ({ fileUri: i.fileUrl, fileName: i.fileName, filekey: i.filekey })) : [], //이미지 값 보낼떄
            }

            let result = false
            if (infoItem.staffSeq != 0) {
                data.staffSeq = infoItem.staffSeq
                result = await editStaff(data)
            } else {
                result = await addStaff(data)
            }
            if (result) {
                setLoading(false), onClose()
            }
            // 업로드중 로딩화면 종료
        } // end if
    }

    // verify popup
    const checkRequiredField = () => {
        let msg = ""

        if (staffName <= 0 || staffName == undefined) {
            msg = replaceWord(dict["n.Please select a product."], dict["name"])
        } else if (staffNumber <= 0 || staffNumber == undefined) {
            msg = replaceWord(dict["n.Please select a product."], dict["cellNo"])
        }

        if (msg > "") {
            setPopup({ open: true, text: msg })
        } else {
            setLoading(true) // 업로드중 로딩화면 시작
            return true
        }
    }

    const getNewData = async () => {
        const itemLength = Object.keys(infoItem).length

        setStaffUniqueNumber(itemLength > 0 ? infoItem.staffUniqueNumber : "")
        setStaffName(itemLength > 0 ? infoItem.staffName : "")
        setStaffNumber(itemLength > 0 ? infoItem.staffNumber : "")
        setStaffAddress(itemLength > 0 ? infoItem.staffAddress : "")
        setGender(itemLength > 0 ? infoItem.gender : "")
        setRemark(itemLength > 0 ? infoItem.remark : "")
        setAttachImages(
            itemLength > 0 && infoItem.imageUri.length > 0
                ? infoItem.imageUri.map((i) => ({ imageUrl: i.fileUri, fileName: i.fileName, key: i.filekey }))
                : [] //기존 이미지정보 다시set
        )
    }

    useEffect(() => {
        if (open) getNewData()

        return async () => {}
    }, [open])

    return (
        <Dialog
            className={classes.container}
            style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
            onClose={() => onClose(null)}
            open={open}
            fullWidth={true}
        >
            <BasicPopup
                open={popup.open}
                onClose={() => setPopup({ open: false, text: popup.text })}
                content={popup.text}
                selectedValue={[dict["confirm"]]}
            />
            <div
                style={{
                    backgroundColor: "#fff",
                    overflowY: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    padding: "23px 51px 44px 40px",
                }}
            >
                <CircularIndeterminate open={loading} />
                <Title>{dict["staffManagement"]}</Title>

                <FlexBox>
                    <Label>
                        <RequiredMark /> {dict["employeeNumber"]}
                    </Label>
                    <InputBox
                        style={{}}
                        type="string"
                        disabled={1 == 1}
                        value={staffUniqueNumber}
                        onChange={(e) => setStaffUniqueNumber(e.target.value)}
                    />
                </FlexBox>

                <FlexBox>
                    <Label>
                        <RequiredMark /> {dict["name"]}
                    </Label>
                    <InputBox style={{}} type="string" disabled={false} value={staffName} onChange={(e) => setStaffName(e.target.value)} />
                </FlexBox>
                <FlexBox>
                    <Label>
                        <RequiredMark /> {dict["cellNo"]}
                    </Label>
                    <InputBox style={{}} type="string" disabled={false} value={staffNumber} onChange={(e) => setStaffNumber(e.target.value)} />
                </FlexBox>

                <FlexBox>
                    <Label>{dict["address"]}</Label>
                    <InputBox
                        style={{ width: "400px" }}
                        type="string"
                        disabled={false}
                        value={staffAddress}
                        maxLength={100}
                        onChange={(e) => setStaffAddress(e.target.value)}
                    />
                </FlexBox>

                <FlexBox>
                    <Label>{dict["gender"]}</Label>
                    <ComboBox
                        style={{ width: "130px", marginRight: 0 }}
                        defaultValue={dict["select"]}
                        hasDefault={true}
                        items={[
                            { value: "1", label: dict["man"] },
                            { value: "2", label: dict["woman"] },
                        ]}
                        value={gender}
                        setValue={setGender}
                    />
                </FlexBox>

                <FlexBox alignItems="flex-start">
                    <Label>{dict["remark"]}</Label>
                    <TextAreaBox width={0} height={120} value={remark} setValue={setRemark} />
                </FlexBox>

                <FlexView alignItems="flex-start">
                    <Label>{dict["uplaodImage"]}</Label>
                    <ImgArea>
                        <AttchImageBox
                            style={{ width: "100%", overflowX: "auto" }}
                            images={attachImages}
                            setImages={setAttachImages}
                            maxCnt={50}
                            infoItem={infoItem} // AttachImageBox 컴포넌트에 infoItem 전달 - 차건담 2023.04.13
                        />
                    </ImgArea>
                </FlexView>

                <ButtonBox>
                    <Button
                        bgColor={"#fff"}
                        hover={"rgba(174, 174, 174, 0.22)"}
                        border={"1px solid #c6c6c6"}
                        color={"#555555cc"}
                        onClick={() => onClose(dict["cancel"], null)}
                    >
                        {dict["cancel"]}
                    </Button>
                    <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
                        {dict["save"]}
                    </Button>
                </ButtonBox>
            </div>
        </Dialog>
    )
}

const Title = styled.p`
    opacity: 80%;
    margin-bottom: 23px;
    color: #555555;
    font-size: 15px;
    font-weight: 700;
    line-height: 32px;
`

const FlexBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: ${(props) => props.alignItems || "center"};
    margin-bottom: 14px;
`

const FlexView = styled.div`
    display: flex;
    /* overflow-x: hidden; */
    justify-content: ${(props) => props.justifyContent || "flex-start"};
    align-items: ${(props) => props.alignItems || "center"};
    margin-bottom: 14px;
`
const Label = styled.p`
    opacity: 80%;
    width: 102px;
    color: #555555;
    font-size: 14px;
    line-height: 34px;
`
const ImgArea = styled.div`
    flex: 1;
    overflow-x: auto;
`
const ButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
const Button = styled.button`
    background-color: ${(props) => props.bgColor};
    width: 147px;
    height: 40px;
    border: ${(props) => props.border};
    border-radius: 3px;
    color: ${(props) => props.color};
    font-size: 14px;

    &:last-child {
        margin-left: 14px;
    }

    &:hover {
        background-color: ${(props) => props.hover};
    }
`

StaffManagementAddPopup.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

export default CommonContainer(StaffManagementContainer(withTranslation()(StaffManagementAddPopup)))
