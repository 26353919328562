import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  initCropList,
  getCropList,
  addCrop,
  initCrop,
  getCrop,
  editCrop,
  delCrop,

  initCropGrowthList,
  getCropGrowthList,
  addCropGrowth,
  initCropGrowth,
  getCropGrowth,
  editCropGrowth,
  delCropGrowth,

  initCropPestList,
  getCropPestList,
  addCropPest,
  initCropPest,
  getCropPest,
  editCropPest,
  delCropPest,

  initCropEnvList,
  getCropEnvList,
  addCropEnv,
  initCropEnv,
  getCropEnv,
  editCropEnv,
  delCropEnv,

  initCropHRMList,
  getCropHRMList,
  addCropHRM,
  initCropHRM,
  getCropHRM,
  editCropHRM,
  delCropHRM,
} = actions.CropManageAction
const { getCodeHelpItem, getComboList } = actions.SystemSettingAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _cropList: state.CropManageReducer._cropList,
  _crop: state.CropManageReducer._crop,
  _cropGrowthList: state.CropManageReducer._cropGrowthList,
  _cropGrowth: state.CropManageReducer._cropGrowth,
  _cropPestList: state.CropManageReducer._cropPestList,
  _cropPest: state.CropManageReducer._cropPest,
  _cropEnvList: state.CropManageReducer._cropEnvList,
  _cropEnv: state.CropManageReducer._cropEnv,
  _cropHRMList: state.CropManageReducer._cropHRMList,
  _cropHRM: state.CropManageReducer._cropHRM,

  _codeList: state.SystemSettingReducer._codeList,
  _codeHelpItem: state.SystemSettingReducer._codeHelpItem,
  _comboWorkCenterList: state.SystemSettingReducer._comboWorkCenterList,
  _comboStdCropList: state.SystemSettingReducer._comboStdCropList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  initCropList: (param) => dispatch(initCropList(param)),
  getCropList: (param) => dispatch(getCropList(param)),
  addCrop: (param) => dispatch(addCrop(param)),
  initCrop: (param) => dispatch(initCrop(param)),
  getCrop: (param) => dispatch(getCrop(param)),
  editCrop: (param) => dispatch(editCrop(param)),
  delCrop: (param) => dispatch(delCrop(param)),

  initCropGrowthList: (param) => dispatch(initCropGrowthList(param)),
  getCropGrowthList: (param) => dispatch(getCropGrowthList(param)),
  addCropGrowth: (param) => dispatch(addCropGrowth(param)),
  initCropGrowth: (param) => dispatch(initCropGrowth(param)),
  getCropGrowth: (param) => dispatch(getCropGrowth(param)),
  editCropGrowth: (param) => dispatch(editCropGrowth(param)),
  delCropGrowth: (param) => dispatch(delCropGrowth(param)),

  initCropPestList: (param) => dispatch(initCropPestList(param)),
  getCropPestList: (param) => dispatch(getCropPestList(param)),
  addCropPest: (param) => dispatch(addCropPest(param)),
  initCropPest: (param) => dispatch(initCropPest(param)),
  getCropPest: (param) => dispatch(getCropPest(param)),
  editCropPest: (param) => dispatch(editCropPest(param)),
  delCropPest: (param) => dispatch(delCropPest(param)),

  initCropEnvList: (param) => dispatch(initCropEnvList(param)),
  getCropEnvList: (param) => dispatch(getCropEnvList(param)),
  addCropEnv: (param) => dispatch(addCropEnv(param)),
  initCropEnv: (param) => dispatch(initCropEnv(param)),
  getCropEnv: (param) => dispatch(getCropEnv(param)),
  editCropEnv: (param) => dispatch(editCropEnv(param)),
  delCropEnv: (param) => dispatch(delCropEnv(param)),

  initCropHRMList: (param) => dispatch(initCropHRMList(param)),
  getCropHRMList: (param) => dispatch(getCropHRMList(param)),
  addCropHRM: (param) => dispatch(addCropHRM(param)),
  initCropHRM: (param) => dispatch(initCropHRM(param)),
  getCropHRM: (param) => dispatch(getCropHRM(param)),
  editCropHRM: (param) => dispatch(editCropHRM(param)),
  delCropHRM: (param) => dispatch(delCropHRM(param)),

  getCodeHelpItem: (param) => dispatch(getCodeHelpItem(param)),
  getComboList: (param) => dispatch(getComboList(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
