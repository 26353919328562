// 생산관리 > 작기별생산계획
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import { withTranslation } from "react-i18next"
import ProductionPlanContainer from "../../../containers/production/ProductionPlanContainer"
import PageName from "../../component/common/PageName"
import InputBox from "../../component/common/InputBox"
import { YellowButton } from "../../component/common/Button"
import { TableComponent } from "../../component/common/Table"

import CommonContainer from "../../../containers/CommonContainer"

import CodeHelpBox from "../../component/common/CodeHelpBox"

const ProductionPlan = (props) => {
  const {
    t,
    _cropPlanList,
    _cropWorkCenterPlanList,
    setCropPlanList,
    getCropPlanList,
    setCropWorkCenterPlanList,
    getCropWorkCenterPlanList,
    editCropWorkCenterPlan,
    delCropWorkCenterPlan,

    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,
  } = props
  const [startYear, setStartYear] = useState(moment().format("YYYY"))
  const [cropName, setCropName] = useState("")
  const [currentCropPlan, setCurrentCropPlan] = useState(null)
  const [workCenterPlanList, setWorkCenterPlanList] = useState([])
  const dict = _translationWords[0] // 변수에 사전 담기

  const [cropNameCode, setCropNameCode] = useState('')

  useEffect(() => {
    getTranslationWords()
    return async () => {
      await setCropPlanList([])
      await setCropWorkCenterPlanList([])
    }
  }, [])

  useEffect(() => {
    setWorkCenterPlanList(_cropWorkCenterPlanList)
  }, [_cropWorkCenterPlanList])

  // useEffect(() => {
  //   if (typeof cropName != String && cropName != ""){
  //     setCropName(cropName == "" ? "" : cropName['label'])
  //   }
  // }, [cropName])

  useEffect(() => {
    if(cropNameCode != ''){
      setCropName(cropNameCode['label'])
    }
  }, [cropNameCode])

  const getData = async () => {
    await getCropPlanList({ startYear, cropName: typeof cropName == 'object' ? cropName['label'] : cropName }) // codeHelp 사용으로 JSON 객체의 label값 사용 - 차건담 2023.08.02
  }

  const onClickSearch = async () => {
    if (startYear.length == 4) await getCropPlanList({ startYear, cropName })
    else await getCropPlanList({ startYear: "", cropName })
  }
  const onClickCrop = async (item) => {
    await getCropWorkCenterPlanList({ cropSeq: item.seq })
    setCurrentCropPlan(item)
  }
  const onClickEdit = async (cropWorkCenterSeq) => {
    const result = await editCropWorkCenterPlan({
      cropSeq: currentCropPlan.seq,
      cropWorkCenterSeq,
      cropPlanQty: document.getElementById(`input${cropWorkCenterSeq}`).value.replaceAll(",", ""),
    })
    if (result) getData()
  }
  const onClickDelete = async (cropWorkCenterSeq) => {
    const result = await delCropWorkCenterPlan({ cropSeq: currentCropPlan.seq, cropWorkCenterSeq })
    if (result) {
      getData()
      await getCropWorkCenterPlanList({ cropSeq: currentCropPlan.seq })
    }
  }

  return (
    <Container>
      <PageName name={dict['productionPlan']} state={false} setState={() => {}} />
      <TopWhiteBox>
        <FlexBox justifyContent="space-between">
          <FlexBox>
            <Text>{dict['cropYearFr']}</Text>
            <InputBox
              style={{ width: "100px", marginLeft: "20px" }}
              type="text"
              value={startYear}
              onChange={(e) => setStartYear(e.target.value)}
              maxLength={4}
            />
            <Text>{dict['cropName']}</Text>
            {/* <InputBox style={{ width: "200px", marginLeft: "20px" }} type="text" value={cropName} onChange={(e) => setCropName(e.target.value)} /> */}
            <CodeHelpBox // inputBox에서 CodeHelpBox로 변경 - 차건담 2023.07.13
              style={{width: "200px", marginLeft: "20px"}}
              placeholder={dict['cropName']}
              codeHelpSeq={3}
              // codeHelpParam={{}}
              value={cropNameCode}
              setValue={setCropNameCode}
            />
          </FlexBox>
          <YellowButton onClick={onClickSearch}>{dict['search']}</YellowButton>
        </FlexBox>

        <TableComponent
          headList={[
            { title: dict['ranking']},
            { title: dict['cropName'], type: "text" },
            { title: dict['itemName'], type: "text" },
            { title: dict['productionUnit'], type: "text" },
            { title: dict['productionPlanQuantity'], type: "comma" },
            { title: dict['cropDateFr'], type: "date" },
            { title: dict['cropDateTo'], type: "date" },
          ]}
          bodyList={_cropPlanList.map((i, idx) => ({
            seq: i.cropSeq,
            no: _cropPlanList.length - idx,
            cropName: i.cropName,
            itemName: i.itemName,
            cropUnitName: i.cropUnitName,
            cropPlanQty: i.cropPlanQty,
            cropDateFr: i.cropDateFr,
            cropDateTo: i.cropDateTo,
          }))}
          dict={dict}
          onClick={onClickCrop}
        />
      </TopWhiteBox>

      <BottomWhiteBox>
        <FlexBox>
          <Text>{dict['cropName']}</Text>
          <InputBox
            style={{ width: "200px", marginLeft: "20px" }}
            type="text"
            value={currentCropPlan ? currentCropPlan.cropName : ""}
            onChange={() => {}}
            disabled
          />
        </FlexBox>
        <TableComponent
          headList={[{ title: dict['workCenterName'], type: "text" }, { title: dict['productionPlanQuantity'] }, { title: dict["actions"] }]} //{ title: "Actions" }수정 2024.03.18 by 강동우 
          bodyList={workCenterPlanList.map((i, idx) => ({
            seq: i.cropWorkCenterSeq,
            workCenterName: i.workCenterName,
            cropPlanQty: (
              <InputBox
                id={`input${i.cropWorkCenterSeq}`}
                style={{ width: "150px", marginRight: 0, textAlign: "right" }}
                type="number"
                value={i.cropPlanQty}
                onChange={(e) => {
                  const temp = [...workCenterPlanList]
                  temp[idx].cropPlanQty = e.target.value
                  setWorkCenterPlanList(temp)
                }}
              />
            ),
            Actions: true,
          }))}
          dict={dict}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
        />
      </BottomWhiteBox>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  &:after {
    content: "";
    display: block;
    height: 42px;
    width: 100%;
  }
`
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const TopWhiteBox = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 350px;
  margin-top: 30px;
  padding: 28px 35px 31px;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const BottomWhiteBox = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 551px;
  height: 322px;
  margin-top: 30px;
  padding: 28px 35px 31px;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const Text = styled.p`
  color: #555555;
  font-size: 14px;
`

export default ProductionPlanContainer(CommonContainer(withTranslation()(ProductionPlan)))
