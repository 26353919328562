import React from "react"
import { Router } from "@reach/router"
import { useDispatch, useSelector } from "react-redux"
import { setServerErrorPopup } from "../actions/ConfigAction"
import PrivateRoute from "../components/privateRoute"
import { withTranslation } from "react-i18next"
import "../components/layout.css"
import Page404 from "./404"
import RouterPage from "../components/page/Router"
import Sample from "../components/page/Sample"
import Root from "../components/page/Root"
import Login from "../components/page/Login"
import SignUp from "../components/page/SignUp"
import ChangePw from "../components/page/ChangePw"
import Main from "../components/page/Main"

import BasicPopup from "../components/popup/BasicPopup"

const App = (props) => {
  const { t } = props
  const dispatch = useDispatch()
  const serverErrorPopup = useSelector((state) => state.ConfigReducer.serverErrorPopup)

  return (
    <>
      <BasicPopup
        open={serverErrorPopup.open}
        onClose={() => dispatch(setServerErrorPopup({ ...serverErrorPopup, open: false }))}
        content={serverErrorPopup.text}
        selectedValue={[t("confirm")]}
      />
      <Router basepath="/app" style={{ width: "100%", height: "100%" }}>
        {/* 샘플 페이지 */}
        <Sample path="/sample" />
        {/* 로그인/회원가입 */}
        <Root path="/" />
        <Login path="/login" />
        <SignUp path="/signup" />
        <ChangePw path="/changePw" />
        {/* 메뉴 */}
        <PrivateRoute component={Main} path="/main" />
        <PrivateRoute component={RouterPage} path="/systemSetting" />
        <PrivateRoute component={RouterPage} path="/defaultInfo" />
        <PrivateRoute component={RouterPage} path="/envControl" />
        <PrivateRoute component={RouterPage} path="/cropManage" />
        <PrivateRoute component={RouterPage} path="/production" />
        <PrivateRoute component={RouterPage} path="/salesManage" />
        <PrivateRoute component={RouterPage} path="/purchaseSpend" />
        <PrivateRoute component={RouterPage} path="/statusInfo" />
        <PrivateRoute component={RouterPage} path="/addDev" />
        {/* 설정 이외의 경로로 들어갈 경우 404 페이지 띄움 */}
        <Page404 path="/*" />
      </Router>
    </>
  )
}

export default withTranslation()(App)
