export const timeKindList = [
  { value: 1, label: "designatedTime" },
  { value: 2, label: "sunrise/sunset(sensor)" },
]

export const AutoEnvTimeKindList = [
  { value: 1, label: "beforeSunrise" },
  { value: 2, label: "afterSunrise" },
  { value: 3, label: "beforeSunset" },
  { value: 4, label: "aftereSunset" },
]

export const AutoEnvKindList = [
  { value: 1, label: "between" },
  { value: 2, label: "below" },
  { value: 3, label: "moreThan" },
  { value: 4, label: "maintenance" },
]

export const InterLockOpenList = [
  {
    value: 1,
    label: "open100percent",
  },
  { value: 2, label: "close" },
]

export const InterLockBoolList = [
  {
    value: 1,
    label: "yes",
  },
  { value: 2, label: "nope" },
]

export const InterLockOpenOnOffList = [
  {
    value: 1,
    label: "On",
  },
  { value: 2, label: "Off" },
]
