// 자동환경설정 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import { AutoEnvKindList } from "../../../utils/data"

import AutoEnvSettingConfContainer from "../../../containers/envControl/AutoEnvSettingConfContainer"
import CommonContainer from "../../../containers/CommonContainer"

import RequiredMark from "../../component/RequiredMark"
import ComboBox from "../../component/common/ComboBox"
import InputBox from "../../component/common/InputBox"
import AlertPopup from "../AlertPopup"

import InputTimeBox from "../../component/common/InputTimeBox"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "600px",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const AutoEnvConfAddPopup = (props) => {
  const classes = useStyles()
  
  const { t, initDataList, getDataList, saveDataList, setDataList, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  
  const { 
    confInfo: dataInfo,
    GET_CONF_INFO: actionType,
    ADD_CONF_INFO,
    MOD_CONF_INFO,
    envConfEquipConfSeq,
    equipSeq,
  } = props

  const { onClose, open } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const dict = _translationWords[0] // 변수에 사전 담기

  const setDataInfo = (data) => {
    setDataList({ confInfo: data })
  }

  const setTimeFr = (value) => { setDataInfo({ ...dataInfo, timeFr: value }) }
  const setTimeTo = (value) => { setDataInfo({ ...dataInfo, timeTo: value }) }

  const setTempKind = (value) => { setDataInfo({ ...dataInfo, tempKind: value }) }

  const setTempFr = (value) => { setDataInfo({ ...dataInfo, tempFr: value }) }
  const setTempTo = (value) => { setDataInfo({ ...dataInfo, tempTo: value }) }

  const setActionTime = (value) => { setDataInfo({ ...dataInfo, actionTime: value }) }
  const setDelayTime = (value) => { setDataInfo({ ...dataInfo, delayTime: value }) }

  useEffect(() => {
    
    if (open) getData(),getTranslationWords()

    return async () => {
      await initDataList(actionType)
    }

  }, [open])

  const getData = async () => {
    const data = { envConfEquipConfSeq }

    await getDataList(actionType, data)

    if ( envConfEquipConfSeq === 0 ) setDataList({ confInfo: {
      tempKind : 0,
      actionTime : 0,
      delayTime : 0,
    } })
    
  }

  const onSelectTempKind = async (value) => {

    let data = { ...dataInfo, tempKind : value }

    if ( value == 0 || value == 4 ) data = { ...data, tempFr : undefined, tempTo : undefined } 
    else if ( value == 2 ) data = { ...data, tempFr : undefined } 
    else if ( value == 3 ) data = { ...data, tempTo : undefined } 

    setDataInfo( data )

  }

  const onClickSave = async () => {
    
    if (dataInfo.timeFr === undefined || dataInfo.timeFr.length < 4) return setAlertPopup({ open: true, text: replaceWord(dict['n.Invalid value. Please try again.'], dict['time']) })
    else if (dataInfo.timeTo === undefined || dataInfo.timeTo.length < 4) return setAlertPopup({ open: true, text: replaceWord(dict['n.Invalid value. Please try again.'], dict['time']) })
    
    else if (dataInfo.tempKind === undefined || dataInfo.tempKind === 0) return setAlertPopup({ open: true, text: replaceWord(dict['n.Invalid value. Please try again.'], dict['temperature']) })
    else if ((dataInfo.tempKind == 1 || dataInfo.tempKind == 3) && (dataInfo.tempFr === undefined || dataInfo.tempFr === '')) return setAlertPopup({ open: true, text: replaceWord(dict['n.Invalid value. Please try again.'], dict['temperature']) })
    else if ((dataInfo.tempKind == 1 || dataInfo.tempKind == 2) && (dataInfo.tempTo === undefined || dataInfo.tempTo === '')) return setAlertPopup({ open: true, text: replaceWord(dict['n.Invalid value. Please try again.'], dict['temperature']) })
    
    else if (dataInfo.actionTime === undefined || dataInfo.actionTime === '') return setAlertPopup({ open: true, text: replaceWord(dict['n.Invalid value. Please try again.'], dict['repeatOperation']+'/'+dict['delayTime']) })
    else if (dataInfo.delayTime === undefined || dataInfo.delayTime === '') return setAlertPopup({ open: true, text: replaceWord(dict['n.Invalid value. Please try again.'], dict['repeatOperation']+'/'+dict['delayTime']) })

    let data = {
      ...dataInfo,
      envConfEquipConfSeq,
      equipSeq,
      tempFr : dataInfo.tempFr,
      tempTo : dataInfo.tempTo,
    }

    if (data.envConfEquipConfSeq > 0) { // 수정

      if (await saveDataList(MOD_CONF_INFO, data)) onClose()

    } 
    else { // 추가

      if (await saveDataList(ADD_CONF_INFO, data)) onClose()

    } // end if

  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose(null)}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <Container>
        <Title>{dict['conditionControl']}</Title>
        <div style={{ flex: 1 }}>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['time']}
            </Label>
            <InputTimeBox style={{ width: "80px", marginRight: 0 }} disabled={false} value={dataInfo.timeFr} setValue={setTimeFr} />
            &nbsp;&nbsp;<Label2>~</Label2>&nbsp;&nbsp;
            <InputTimeBox style={{ width: "80px", marginRight: 0 }} disabled={false} value={dataInfo.timeTo} setValue={setTimeTo} />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['temperature']}
            </Label>
            <ComboBox
              style={{ width: "160px", marginRight: 0 }}
              defaultValue={t("select")}
              hasDefault={true}
              items={[
                { value: 1, label: dict['between'], },
                { value: 2, label: dict['moreThan'], },
                { value: 3, label: dict['below'], },
                { value: 4, label: dict['unused'], },
              ]}
              value={dataInfo.tempKind}
              setValue={onSelectTempKind}
            />

            &nbsp;&nbsp;&nbsp;&nbsp;

            <InputBox
              style={{ width: "60px", marginRight: 0 }}
              type="number"
              value={dataInfo.tempFr}
              onChange={(e) => setTempFr(e.target.value)}
              disabled={dataInfo.tempKind === undefined || dataInfo.tempKind == 0 || dataInfo.tempKind == 2 || dataInfo.tempKind == 4}
            />
            &nbsp;<Label2>(℃)</Label2>

            &nbsp;&nbsp;<Label2>~</Label2>&nbsp;&nbsp;
            
            <InputBox
              style={{ width: "60px", marginRight: 0 }}
              type="number"
              value={dataInfo.tempTo}
              onChange={(e) => setTempTo(e.target.value)}
              disabled={dataInfo.tempKind === undefined || dataInfo.tempKind == 0 || dataInfo.tempKind == 3 || dataInfo.tempKind == 4}
            />
            &nbsp;<Label2>(℃)</Label2>

          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['repeatOperation']+'/'+dict['delayTime']}
            </Label>
            <InputBox
              style={{ width: "60px", marginRight: 0 }}
              type="number"
              value={dataInfo.actionTime}
              onChange={(e) => setActionTime(e.target.value)}
              // disabled={kind == 2}
            />
            &nbsp;<Label2>{'('+dict['sec']+')'}</Label2>
            ,
            &nbsp;
            <InputBox
              style={{ width: "60px", marginRight: 0 }}
              type="number"
              value={dataInfo.delayTime}
              onChange={(e) => setDelayTime(e.target.value)}
              // disabled={kind == 2}
            />
            &nbsp;<Label2>{'('+dict['sec']+')'}</Label2>
          </FlexBox>
        </div>
        <ButtonBox>
          <Button bgColor={"#fff"} hover={"rgba(174, 174, 174, 0.22)"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={() => onClose()}>
            {dict['cancel']}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {dict['save']}
          </Button>
        </ButtonBox>
      </Container>
    </Dialog>
  )
}

const Container = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 23px 51px 44px 40px;
`
const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 40px;
  }
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`

const Label2 = styled.p`
  opacity: 80%;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  flex: 1;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

AutoEnvConfAddPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default AutoEnvSettingConfContainer(CommonContainer(withTranslation()(AutoEnvConfAddPopup)))
