// 환경제어 > 인터락설정
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import InterLockSettingContainer from "../../../containers/envControl/InterLockSettingContainer"
import PageName from "../../component/common/PageName"
import { TableComponent } from "../../component/common/Table"
import TableCheckBox from "../../component/common/TableCheckBox"
import InterLockEditPopup from "../../popup/EnvControl/InterLockEditPopup"
import CommonContainer from "../../../containers/CommonContainer"

import { SkyButton } from "../../component/common/Button"
import EmergencyAlarmAddPopup from "../../popup/EnvControl/EmergencyAlarmAddPopup"

import Tab from "../../component/common/Tab"


const InterlockSetting = (props) => {
  const { t, _interLockList, initInterLockList, getInterLockList, editInterLockIsUse, initInterLock, getInterLock, editInterLock, getComboList } = props
  const {initEmergencyAlarmList, initEmergencyAlarm ,getEmergencyAlarmList, getEmergencyAlarm, addEmergencyAlarm, editEmergencyAlarm, delEmergencyAlarm, _emergencyAlarmList} = props //긴급경보알림
  const [interLockEditPopup, setInterLockEditPopup] = useState({ open: false, type: 0, workType: -1 })

  const [emergencyAlarmAddPopup, setEmergencyAlarmAddPopup] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)

  const {initTranslationWords, getTranslationWords, _translationWords} = props
  //   equipSeq
  // interLockKind
  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {
    getTranslationWords()
    getData()
    return async () => await initInterLockList()
  }, [])

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  // console.log('김한중짜@')
  // console.log(_emergencyAlarmList)

  const getData = async () => {
    await getEmergencyAlarmList() //긴급알람 리스트
    
    await getInterLockList()
    await getComboList({ queryKind: "Sensor" }) //센서명 가져오는거
  }

  const onClickCheckBox = async (seq, bool) => {
    const result = await editInterLockIsUse({ equipSeq: seq, isUse: bool ? 1 : 0 })
    if (result) getData()
  }

  const interLockEditPopupHandler = async (type, value, workType) => {
    if (type == dictCk("save")) {
      const result = await editInterLock(value)
      if (result) getData()
    }
    setInterLockEditPopup({ open: false, type: interLockEditPopup.type, workType: workType == "On/Off" ? 0 : 1 })
  }

  const onClickInterLockEditPopupOpen = async (seq, type, workType) => {
    const result = await getInterLock({ equipSeq: seq, interLockKind: type })

    if (result) {
      setInterLockEditPopup({ open: true, type: type, workType: workType == "On/Off" ? 0 : 1 })
    }
  }

  /////////////////////긴급경보
  // 긴급경보알람 신규추가
  const onClickAdd = async () => {
    await initEmergencyAlarm()
    await getEmergencyAlarm({ emergencyAlarmSeq: 0 })
    setEmergencyAlarmAddPopup(true)
  }
  
  // 긴급경보알람 edit method 수정
  const onClickEdit = async (seq) => {
    const result = await getEmergencyAlarm({ emergencyAlarmSeq: seq })
    if (result) {
      setEmergencyAlarmAddPopup(true)
    }
  }

  //긴급경보알람 삭제
  const onClickDelete = async (seq) => { // 정보 (삭제)
    const result = await delEmergencyAlarm({ emergencyAlarmSeq: seq })
    if (result) getData()
  }

  const emergencyAlarmAddPopupHandler = async (type, value) => {
    if (type == dictCk("save")) {
      let result = false
      if (value.emergencyAlarmSeq == undefined) {
        result = await addEmergencyAlarm(value)
      } else {
        result = await editEmergencyAlarm(value)
      }
      if (result) getData()
    } else if (type == dictCk("delete")) {
      const result = await delEmergencyAlarm(value)
      if (result) getData()
    }
    setEmergencyAlarmAddPopup(false)
  }

  return (
    <Container>
      <InterLockEditPopup
        open={interLockEditPopup.open}
        onClose={interLockEditPopupHandler}
        type={interLockEditPopup.type}
        workType={interLockEditPopup.workType}
        dict={dict}
      />

      <PageName name={dictCk("interLockSetting")} state={false} setState={() => {}} />

      <Tab
            style={{ marginTop: "23px" }}
            list={[
              { seq: 0, name: dictCk('interLockSetting') },
              { seq: 1, name: dictCk('warningAlarm') },
            ]}
            tabIndex={tabIndex}
            onClick={(i) => {
              setTabIndex(i.seq)
            }}
          />
      <Box>

        {tabIndex == 0 && (
        <WhiteBox>
          <TableComponent
            style={{ marginTop: 0 }}
            headList={[
              { title: dictCk("equipName") },
              { title: dictCk("workKind") },
              { title: dictCk("isInterLock") },
              { title: dictCk("temperatureMax")}, //`${t("tempMax")}` },
              { title: dictCk("temperatureMin") }, //`${t("tempMin")}` },
              { title: dictCk("rainyWeather")}, //`${t("rainFall")}` },
              { title: dictCk("windSpeedVolume") },
            ]}
            bodyList={(_interLockList || []).map((i) => ({
              seq: i.equipSeq,
              equipName: i.equipName,
              workKind: i.workKindName,
              isUse: <TableCheckBox check={i.isUse == 1} setCheck={(bool) => onClickCheckBox(i.equipSeq, bool)} />,
              tempMax: <InputButton onClick={() => onClickInterLockEditPopupOpen(i.equipSeq, 1, i.workKindName)}>{i.tempMax}</InputButton>,
              tempMin: <InputButton onClick={() => onClickInterLockEditPopupOpen(i.equipSeq, 2, i.workKindName)}>{i.tempMin}</InputButton>,
              detectKindName: (
                <InputButton onClick={() => onClickInterLockEditPopupOpen(i.equipSeq, 3, i.workKindName)}>{i.detectKindName}</InputButton>
              ),
              windSpeed: (
                <InputButton onClick={() => onClickInterLockEditPopupOpen(i.equipSeq, 4, i.workKindName)}>
                  {i.windSpeed}/{i.windVolume}
                </InputButton>
              ),
            }))}
            dict={dict}
          />
             
        </WhiteBox>
        )}

        {tabIndex == 1 && (
        <WhiteBox>
        <EmergencyAlarmAddPopup open={emergencyAlarmAddPopup} onClose={emergencyAlarmAddPopupHandler} />
            <div style={{ marginTop: "0px" }}>
            {/* {dictCk("warningAlarm") } */}
              <SkyButton style={{ float: 'right ' }}  onClick={onClickAdd}>{dict['add']}</SkyButton>
            </div>
          
          <TableComponent
            style={{ marginTop: 16 }}
            headList={[
              { title: dictCk("sensorKind")},
              { title: dictCk("maxValue")},
              { title: dictCk("minValue")}, 
              { title: dictCk("notice")+dictCk("isUse") , type: "check" }, 
              { title: dictCk("alarmRepeatTime")}, 
              { title: dictCk("remark"), type: "text" },
              { title: dictCk("sendManager"), type: "check" },
              { title: dictCk("actions") }, //{ title: `${t("actions")}` } 수정 24.03.13by 강동우
            ]}
            bodyList={(_emergencyAlarmList || []).map((i) => ({
              seq: i.emergencyAlarmSeq,
              sensorKind: i.sensorKindName,
              // tempMax: i.sensorKind == 10150001 ? i.tempMax + dictCk('cel') : i.sensorKind == 10150014 ? i.tempMax + dictCk('mm') : "", // 온도, 수위만 값 표시
              // tempMin: i.sensorKind == 10150001 ? i.tempMin + dictCk('cel') : i.sensorKind == 10150014 ? i.tempMin + dictCk('mm') : "", // 온도, 수위만 값 표시
              tempMax: i.sensorKind == 10150011 || i.sensorKind == 10150013 ? "" : i.tempMax + i.sensorUnitName, // 온도, 수위만 값 표시
              tempMin: i.sensorKind == 10150011 || i.sensorKind == 10150013 ? "" : i.tempMin + i.sensorUnitName, // 온도, 수위만 값 표시
              isUse: i.isUse,
              alarmCycleTime: i.alarmCycleTime + dictCk('time'),
              remark: i.remark,
              isManager: i.isManager,

              Actions: true,
            }))}
            dict={dict}
            onClickEdit={(seq)   => onClickEdit(seq)}         // 수정
            onClickDelete={(seq) => onClickDelete(seq)}       // 삭제
          />
        </WhiteBox>
        )}

      </Box>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  margin-top: 28px;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 31px 20px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const InputButton = styled.button`
  background-color: #fff;
  width: 100%;
  height: 35px;
  border: 1px solid #dedede;
  border-radius: 3px;
`

export default InterLockSettingContainer(CommonContainer(withTranslation()(InterlockSetting)))
