import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import icon_selector from "images/selector.png"

const ComboBox = (props) => {
  const { t, style = {}, defaultValue, hasDefault, items, value, setValue, disabled } = props

  const [open, setOpen] = useState(false)
  const [blur, setBlur] = useState(true)
  const [dataList, setDataList] = useState([])

  useEffect(() => {
    const dataList = (hasDefault ? [{ value: 0, label: defaultValue }] : []).concat(items)

    setDataList(dataList)
  }, [items])

  return (
    <StyledDropdown style={{ ...style, background: disabled == true ? "#edf4eb": "#FFFFFF" }} onBlur={() => (blur ? setOpen(false) : null)}>
      {/* <SelectButton type="button" color={value <= 0 && hasDefault ? "#55555566" : "#555555"} onClick={() => setOpen(!open)} disabled={disabled}> */}
      <SelectButton type="button" color={value <= 0 && hasDefault ? "#55555566" : "#555555"} onClick={() => setOpen(!open)} disabled={disabled}>
        <p>{dataList.filter((row) => row.value == value).length > 0 ? t(dataList.filter((row) => row.value == value)[0].label) : ""}</p>
        <img src={icon_selector} alt={""} />
      </SelectButton>
      {open ? (
        <Options onMouseOver={() => setBlur(false)} onMouseOut={() => setBlur(true)} >
          {dataList.map((data) => (
            <button
              key={data.value}
              onClick={() => {
                setValue(data.value, data.label)
                setOpen(false)
              }}
            >
              <p>&nbsp;&nbsp;&nbsp;{t(data.label)}</p>
            </button>
          ))}
        </Options>
      ) : null}
    </StyledDropdown>
  )
}

const StyledDropdown = styled.div`
  position: relative;
  width: 220px;
  margin-right: 30px;
`
const SelectButton = styled.button`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  border: 1px solid #dedede;
  border-radius: 3px;
  & > p {
    padding: 0 10px;
    color: ${(props) => props.color};
    font-size: 14px;
  }
  & > img {
    position: absolute;
    right: 8px;
    width: 24px;
    height: 24px;
  }
  &:focus {
    border: 1px solid #52935d;
  }
`
const Options = styled.div`
  overflow-y: auto;
  background-color: #ffffff;
  position: absolute;
  width: 100%;
  height: auto;
  max-height: 163px;
  z-index: 1000;
  border: 1px solid #dedede;
  border-radius: 3px;
  & > button {
    background-color: #ffffff00;
    width: 100%;
    height: 40px;
    text-align: left;
    border-bottom: 1px solid #dedede;
    &:last-child {
      border-bottom: none;
    }
  }
`

export default withTranslation()(ComboBox)
