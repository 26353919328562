// 작기정보 작기 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import moment from "moment"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import CropInfoContainer from "../../../containers/cropManage/CropInfoContainer"
import RequiredMark from "../../component/RequiredMark"
import CodeHelpBox from "../../component/common/CodeHelpBox"
import ComboBox from "../../component/common/ComboBox"
import DateBox from "../../component/common/DateBox"
import MultiComboBox from "../../component/common/MultiComboBox"
import InputBox from "../../component/common/InputBox"
import TableCheckBox from "../../component/common/TableCheckBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import AlertPopup from "../AlertPopup"

import CommonContainer from "../../../containers/CommonContainer"

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        maxWidth: "421px",
        margin: "0 auto",
        "& .MuiDialog-paperFullWidth": {
            width: "100%",
        },
        "& .MuiPaper-root": {
            backgroundColor: "#fff",
        },
        "& .MuiDialog-paper": {
            margin: "0px",
        },
    },
}))

const CropAddPopup = (props) => {
    const classes = useStyles()
    const {
        t,
        _codeList,
        _codeHelpItem,
        _comboWorkCenterList,
        _comboStdCropList,
        _crop,
        initTranslationWords,
        getTranslationWords,
        _translationWords,
        replaceWord,
    } = props
    const { open, onClose } = props
    const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
    const [stdCropName, setStdCropName] = useState(0)
    const [cropName, setCropName] = useState("")
    const [itemName, setItemName] = useState({ value: 0, label: "" })
    const [cropDateFr, setCropDateFr] = useState("")
    const [cropDateTo, setCropDateTo] = useState("")
    const [cropUnitSeq, setCropUnitSeq] = useState(0)
    const [salesUnitSeq, setSalesUnitSeq] = useState(0)
    const [workCenterSeqList, setWorkCenterSeqList] = useState(0)
    const [isUse, setIsUse] = useState(true)
    const [remark, setRemark] = useState("")
    const [value3, setValue3] = useState({ value: 0, label: "" }) // 코드도움 (품목)
    const dict = _translationWords[0] // 변수에 사전 담기

    useEffect(() => {
        getTranslationWords()
        // console.log("crop : ", _crop)
        // console.log("_comboWorkCenterList.length : ", _comboWorkCenterList.length)
        // console.log("Object.keys(_crop).length : ", Object.keys(_crop).length)

        if (_crop && _comboWorkCenterList.length > 0 && Object.keys(_crop).length != 0) {
            // console.log('_comboWorkCenterList', _comboWorkCenterList)
            // console.log('_crop', _crop)
            // setCropName({ value: _crop.cropSeq, label: _crop.cropName })
            setCropName(_crop.cropName)
            setItemName({ value: _crop.itemSeq, label: _crop.itemName })
            setCropDateFr(_crop.cropDateFr && moment(_crop.cropDateFr, "YYYYMMDD").format("YYYY-MM-DD"))
            setCropDateTo(_crop.cropDateTo && moment(_crop.cropDateTo, "YYYYMMDD").format("YYYY-MM-DD"))
            setCropUnitSeq(_crop.cropUnitSeq)
            setSalesUnitSeq(_crop.salesUnitSeq)
            /*
        기존 코드에서 _comboWorkCenterList.find((j) => j.workCenterSeq == i && j.workCenterName != undefined)에서 조건에 부합하는 행이 없을 때의 상황에서
        workCenterName을 찾으면서 오류가 발생하여 수정 - 차건담 2023.08.03

        기존코드
          _crop.workCenterSeqList.map((i) => ({ value: i, label: _comboWorkCenterList.find((j) => j.workCenterSeq == i && j.workCenterName != undefined).workCenterName }))
      */
            const workCenterSeqAndNameList = _crop.workCenterSeqList.map((i) => ({
                value:
                    _comboWorkCenterList.find((j) => j.workCenterSeq == i && j.workCenterName != undefined) != null ||
                    _comboWorkCenterList.find((j) => j.workCenterSeq == i && j.workCenterName != undefined) != undefined
                        ? _comboWorkCenterList.find((j) => j.workCenterSeq == i && j.workCenterName != undefined).workCenterSeq
                        : null,
                label:
                    _comboWorkCenterList.find((j) => j.workCenterSeq == i && j.workCenterName != undefined) != null ||
                    _comboWorkCenterList.find((j) => j.workCenterSeq == i && j.workCenterName != undefined) != undefined
                        ? _comboWorkCenterList.find((j) => j.workCenterSeq == i && j.workCenterName != undefined).workCenterName
                        : null,
            }))

            const workCenterSeqAndNameListNoNull = workCenterSeqAndNameList.filter((i) => i.value != null || i.label != null)

            console.log("workCenterSeqAndNameList:", workCenterSeqAndNameList)
            console.log("workCenterSeqAndNameListNoNull:", workCenterSeqAndNameListNoNull)
            setWorkCenterSeqList(workCenterSeqAndNameListNoNull)
            setIsUse(_crop.isUse == 1)
            setRemark(_crop.remark)
        }

        return () => {
            setStdCropName(0)
            setCropName("")
            setItemName(0)
            setCropDateFr("")
            setCropDateTo("")
            setCropUnitSeq(0)
            setSalesUnitSeq(0)
            setWorkCenterSeqList([])
            setIsUse(true)
            setRemark("")
        }
    }, [open])

    useEffect(() => {
        if (_comboStdCropList && _comboStdCropList.length > 0 && stdCropName != 0) {
            const selected = _comboStdCropList.find((i) => i.stdCropSeq == stdCropName)
            // console.log(selected)

            setCropDateFr(moment(selected.cropDateFr, "MMDD").format("YYYY-MM-DD"))
            setCropDateTo(
                selected.cropDateTo > selected.cropDateFr
                    ? moment(selected.cropDateTo, "MMDD").format("YYYY-MM-DD")
                    : selected.cropDateTo < selected.cropDateFr
                    ? moment(moment(selected.cropDateTo, "MMDD").format("YYYY-MM-DD")).add(1, "years")
                    : ""
            )

            // console.log('cropDateTo')
            // console.log(cropDateTo)

            setItemName({ value: selected.itemSeq, label: selected.itemName })
            // 2024.10.30 by 윤여광
            // 1. 기존에 표준작기 콤보박스 선택시 자동으로 작기명에 표준작기명으로 자동입력되는 기능 중 변수명 픽스
            // 2. 작기명이 표준작기명과 같을 필요가 없으므로 수정함
            // setCropName({ value: selected.cropSeq, label: selected.cropName })
            // setCropName(selected.stdCropName)
            setCropName(cropName)
            setRemark(selected.remark)
        }
    }, [stdCropName])

    const onClickSave = () => {
        if (cropName == "") return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["stdCropName"]) })
        else if (itemName.value == 0) return setAlertPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict["itemName"]) })
        else if (cropDateFr == "")
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["cropDateFr"]) })
        // else if (cropDateTo == "") return setAlertPopup({ open: true, text: t("n.Invalid value. Please try again.", { n: t("cropDateTo") }) })
        else if (cropUnitSeq == "")
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict["productionUnit"]) })
        else if (salesUnitSeq == "") return setAlertPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict["salesUnit"]) })
        else if (workCenterSeqList.length == 0)
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict["workCenter"]) })

        let param = {
            stdCropSeq: stdCropName,
            cropName: cropName,
            itemSeq: itemName.value || 0,
            cropDateFr: cropDateFr && moment(cropDateFr).format("YYYYMMDD"),
            // cropDateTo: cropDateTo && moment(cropDateTo).format("YYYYMMDD"),
            cropUnitSeq: cropUnitSeq || 0,
            salesUnitSeq: salesUnitSeq || 0,
            workCenterSeqList: workCenterSeqList.map((i) => i.value),
            isUse: isUse ? 1 : 0,
            remark,
        }

        if (Object.keys(_crop).length != 0) {
            param.cropSeq = _crop.cropSeq
        }
        onClose(dict["save"], param)
    }

    const onClickCancel = () => {
        onClose(dict["cancel"], null)
    }

    return (
        <Dialog
            className={classes.container}
            style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
            onClose={() => onClose(null)}
            open={open}
            fullWidth={true}
        >
            <AlertPopup
                bg={false}
                open={alertPopup.open}
                onClose={() => setAlertPopup({ open: false, text: alertPopup.text })}
                content={alertPopup.text}
            />
            <Container>
                <Title>{dict["cropInfo"]}</Title>
                <div style={{ flex: 1 }}>
                    {Object.keys(_crop).length == 0 && (
                        <FlexBox>
                            <Label>{dict["standardCropName"]}</Label>
                            <ComboBox
                                style={{ width: "220px" }}
                                defaultValue={dict["select"]}
                                hasDefault={true}
                                items={_comboStdCropList && _comboStdCropList.map((i) => ({ value: i.stdCropSeq, label: i.stdCropName }))}
                                value={stdCropName}
                                setValue={setStdCropName}
                            />
                        </FlexBox>
                    )}
                    <FlexBox>
                        <Label>
                            <RequiredMark /> {dict["cropName"]}
                        </Label>
                        {/* <CodeHelpBox
                            style={{}}
                            placeholder={dict['cropName']}
                            codeHelpSeq={3}
                            // codeHelpParam={{}}
                            value={cropName}
                            setValue={setCropName}
                        /> */}
                        <InputBox type="text" value={cropName} onChange={(e) => setCropName(e.target.value)} />
                    </FlexBox>
                    <FlexBox>
                        <Label>
                            <RequiredMark /> {dict["itemName"]}
                        </Label>
                        <CodeHelpBox
                            style={{}}
                            placeholder={dict["itemName"]}
                            codeHelpSeq={1}
                            // codeHelpParam={{}}
                            value={itemName}
                            setValue={setItemName}
                        />
                    </FlexBox>
                    <FlexBox>
                        <Label>
                            <RequiredMark /> {dict["cropDateFr"]}
                        </Label>
                        <DateBox style={{ zIndex: 101 }} stdDate={cropDateFr} setStdDate={setCropDateFr} />
                    </FlexBox>
                    {/* <FlexBox>
                            <Label>
                            <RequiredMark /> {t("cropDateTo")}
                            </Label>
                            <DateBox style={{}} stdDate={cropDateTo} setStdDate={setCropDateTo} />
                        </FlexBox> */}
                    <FlexBox>
                        <Label>
                            <RequiredMark /> {dict["productionUnit"]}
                        </Label>
                        <ComboBox
                            style={{ width: "220px" }}
                            defaultValue={dict["select"]}
                            hasDefault={true}
                            items={
                                _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1003).length > 0
                                    ? _codeList.filter((i) => i.majorSeq == 1003)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                                    : []
                            }
                            value={cropUnitSeq}
                            setValue={setCropUnitSeq}
                        />
                    </FlexBox>
                    <FlexBox>
                        <Label>
                            <RequiredMark /> {dict["salesUnit"]}
                        </Label>
                        <ComboBox
                            style={{ width: "220px" }}
                            defaultValue={dict["select"]}
                            hasDefault={true}
                            items={
                                _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1003).length > 0
                                    ? _codeList.filter((i) => i.majorSeq == 1003)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                                    : []
                            }
                            value={salesUnitSeq}
                            setValue={setSalesUnitSeq}
                        />
                    </FlexBox>
                    <FlexBox>
                        <Label>
                            <RequiredMark /> {dict["workCenter"]}
                        </Label>
                        <MultiComboBox
                            style={{ width: "220px" }}
                            placeholder={dict["multiSelect"]}
                            items={_comboWorkCenterList && _comboWorkCenterList.map((i) => ({ value: i.workCenterSeq, label: i.workCenterName }))}
                            value={workCenterSeqList}
                            setValue={setWorkCenterSeqList}
                        />
                    </FlexBox>
                    <FlexBox>
                        <Label>{dict["isUse"]}</Label>
                        <TableCheckBox check={isUse} setCheck={setIsUse} />
                    </FlexBox>
                    <FlexBox alignItems="flex-start">
                        <Label>{dict["remark"]}</Label>
                        <TextAreaBox value={remark} setValue={setRemark}></TextAreaBox>
                    </FlexBox>
                </div>
                <ButtonBox>
                    <Button
                        bgColor={"#fff"}
                        hover={"rgba(174, 174, 174, 0.22)"}
                        border={"1px solid #c6c6c6"}
                        color={"#555555cc"}
                        onClick={onClickCancel}
                    >
                        {dict["cancel"]}
                    </Button>
                    <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
                        {dict["save"]}
                    </Button>
                </ButtonBox>
            </Container>
        </Dialog>
    )
}

const Container = styled.div`
    background-color: #fff;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 23px 26px 40px;
`
const Title = styled.p`
    opacity: 80%;
    margin-bottom: 23px;
    color: #555555;
    font-size: 15px;
    font-weight: 700;
    line-height: 32px;
`
const FlexBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: ${(props) => props.alignItems || "center"};
    margin-bottom: 14px;
    &:last-child {
        margin-bottom: 34px;
    }
`
const Label = styled.p`
    opacity: 80%;
    width: 102px;
    color: #555555;
    font-size: 14px;
    line-height: 34px;
`
const ButtonBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`
const Button = styled.button`
    background-color: ${(props) => props.bgColor};
    flex: 1;
    height: 40px;
    border: ${(props) => props.border};
    border-radius: 3px;
    color: ${(props) => props.color};
    font-size: 14px;

    &:last-child {
        margin-left: 14px;
    }

    &:hover {
        background-color: ${(props) => props.hover};
    }
`

CropAddPopup.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default CommonContainer(CropInfoContainer(withTranslation()(CropAddPopup)))
