import React from "react"
import styled from "styled-components"

const Switch = ({ bool, setBool }) => {
  return (
    <Container active={bool} onClick={() => setBool(!bool)}>
      <Label color={"#3e7347"} opacity={"90%"} visible={bool}>
        On
      </Label>
      <Label color={"#555555"} opacity={"50%"} visible={!bool}>
        Off
      </Label>
      <Ball active={bool} />
    </Container>
  )
}

const Container = styled.button`
  background-color: ${(props) => (props.active ? "rgba(82, 147, 93, 0.42)" : "#fff")};
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80px;
  height: 40px;
  padding: 0 15px;
  border: ${(props) => (props.active ? "1px solid #52935d" : "1px solid #dedede")};
  border-radius: 20px;
`
const Label = styled.p`
  opacity: ${(props) => (props.visible ? props.opacity : "0%")};
  color: ${(props) => props.color};
  font-size: 12px;
  line-height: 32px;
`
const Ball = styled.div`
  background-color: ${(props) => (props.active ? "rgba(82, 147, 93, 0.87)" : "#d0d0d0")};
  position: absolute;
  left: ${(props) => (props.active ? "45px" : "5px")};
  width: 30px;
  height: 30px;
  border: ${(props) => (props.active ? "1px solid #52935d" : "1px solid rgba(255, 255, 255, 0.72)")};
  border-radius: 20px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
`

export default Switch
