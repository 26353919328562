import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  addCropReport,
  getCropReportList,
  getCropReportInfo,
  editCropReport,
  delCropReport,
  getCropReportExcelDown,
  setCropReportList,
  setCropReportInfo,
} = actions.CropManageAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  
  _cropReportList: state.CropManageReducer._cropReportList,
  _cropReportInfo: state.CropManageReducer._cropReportInfo,

})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  addCropReport: (param) => dispatch(addCropReport(param)),
  getCropReportList: (param) => dispatch(getCropReportList(param)),
  getCropReportInfo: (param) => dispatch(getCropReportInfo(param)),
  editCropReport: (param) => dispatch(editCropReport(param)),
  delCropReport: (param) => dispatch(delCropReport(param)),

  getCropReportExcelDown: (param) => dispatch(getCropReportExcelDown(param)),

  setCropReportList: (param) => dispatch(setCropReportList(param)),
  setCropReportInfo: (param) => dispatch(setCropReportInfo(param)),

})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
