// 판매관리 > 작기별판매계획
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import SalesPlanContainer from "../../../containers/salesManage/SalesPlanContainer"
import { withTranslation } from "react-i18next"
import PageName from "../../component/common/PageName"
import InputBox from "../../component/common/InputBox"
import { YellowButton, SkyButton } from "../../component/common/Button"
import { TableComponent } from "../../component/common/Table"
import BasicPopup from "../../popup/BasicPopup"
import SalesPlanAddPopup from "../../popup/SalesManage/SalesPlanAddPopup"

import CommonContainer from "../../../containers/CommonContainer"

import CodeHelpBox from "../../component/common/CodeHelpBox"

const SalesPlan = (props) => {
  const {
    t,
    _salesCropList,
    _salesCropPlanList,
    setSalesCropList,
    setSalesCropPlanList,
    setSalesCropPlan,
    getSalesCropList,
    getSalesCropPlanList,
    getSalesCropPlan,
    addSalesCropPlan,
    editSalesCropPlan,
    delSalesCropPlan,

    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,

  } = props
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [salesPlanAddPopup, setSalesPlanAddPopup] = useState(false)
  const [cropDateFr, setCropDateFr] = useState(moment().format("YYYY"))
  const [cropName, setCropName] = useState("")
  const [currentCrop, setCurrentCrop] = useState(null)

  const [cropNameCode, setCropNameCode] = useState('')

  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {
    getTranslationWords()
    return async () => {
      await setSalesCropList([])
      await setSalesCropPlanList([])
    }
  }, [])

  useEffect(() => {
    if(cropNameCode != ''){
      setCropName(cropNameCode['label'])
    }
  }, [cropNameCode])

  const getData = async () => {
    await getSalesCropList({ cropDateFr, cropName })
  }

  const onClickSearch = async () => {
    if (cropDateFr.length == 4) await getSalesCropList({ cropDateFr, cropName })
    else await getSalesCropList({ cropDateFr, cropName })
  }
  const onClickCrop = async (item) => {
    await getSalesCropPlanList({ cropSeq: item.seq })
    setCurrentCrop(item)
  }
  const onClickAdd = async () => {
    if (currentCrop == null) return setPopup({ open: true, text: replaceWord(dict['n.Please select a product.'], dict['crop']) })
    await setSalesCropPlan({})
    setSalesPlanAddPopup(true)
  }
  const onClickEdit = async (salesPlanSeq) => {
    if (currentCrop == null) return setPopup({ open: true, text: replaceWord(dict['n.Please select a product.'], dict['crop']) })
    const result = await getSalesCropPlan({ salesPlanSeq })
    if (result) setSalesPlanAddPopup(true)
  }
  const onClickDelete = async (salesPlanSeq) => {
    const result = await delSalesCropPlan({ salesPlanSeq })
    if (result) {
      getData()
      await getSalesCropPlanList({ cropSeq: currentCrop.seq })
    }
  }
  const salesPlanAddPopupHandler = async (type, value) => {
    if (type == dict['save']) {
      let result = false
      if (value.salesPlanSeq == undefined) {
        result = await addSalesCropPlan({ cropSeq: currentCrop.seq, ...value })
      } else {
        result = await editSalesCropPlan({ cropSeq: currentCrop.seq, ...value })
      }
      if (result) {
        getData()
        await getSalesCropPlanList({ cropSeq: currentCrop.seq })
      }
    }
    setSalesPlanAddPopup(false)
  }

  return (
    <Container>
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: popup.text })} content={popup.text} selectedValue={[dict['confirm']]} />
      <SalesPlanAddPopup open={salesPlanAddPopup} onClose={salesPlanAddPopupHandler} />
      <PageName name={dict['salesPlanByCrop']} />
      <TopWhiteBox>
        <FlexBox justifyContent="space-between">
          <FlexBox>
            <Text>{dict['cropYearFr']}</Text>
            <InputBox style={{ width: "100px" }} type="text" value={cropDateFr} onChange={(e) => setCropDateFr(e.target.value)} maxLength={4} />
            <Text>{dict['cropName']}</Text>
            {/* <InputBox style={{ width: "200px" }} type="text" value={cropName} onChange={(e) => setCropName(e.target.value)} /> */}
            <CodeHelpBox // inputBox에서 CodeHelpBox로 변경 - 차건담 2023.07.13
              style={{width: "200px", marginLeft: "20px"}}
              placeholder={dict['cropName']}
              codeHelpSeq={3}
              // codeHelpParam={{}}
              value={cropNameCode}
              setValue={setCropNameCode}
            />
          </FlexBox>
          <YellowButton onClick={onClickSearch}>{dict['search']}</YellowButton>
        </FlexBox>

        <TableComponent
          headList={[
            { title: dict['ranking'] },
            { title: dict['stdCropName'] , type: "text" },
            { title: dict['item'] , type: "text" },
            { title: dict['salesUnit'] , type: "text" },
            { title: dict['salesPlanQuantity'] , type: "comma" },
            { title: dict['salesPlanAmount'] , type: "comma" },
            { title: dict['cropDateFr'] , type: "date" },
            { title: dict['cropDateTo'] , type: "date" },
          ]}
          bodyList={_salesCropList.map((i, idx) => ({
            seq: i.cropSeq,
            no: _salesCropList.length - idx,
            cropName: i.cropName,
            itemName: i.itemName,
            minorName: i.minorName,
            salesPlanQty: i.salesPlanQty || 0,
            salesPlanAmt: i.salesPlanAmt || 0,
            cropDateFr: i.cropDateFr ? i.cropDateFr : "",
            cropDateTo: i.cropDateTo ? i.cropDateTo : "",
          }))}
          dict={dict}
          onClick={onClickCrop}
        />
      </TopWhiteBox>

      <BottomWhiteBox>
        <FlexBox justifyContent="space-between">
          <FlexBox>
            <Text>{dict['cropName']}</Text>
            <InputBox style={{ width: "200px" }} type="text" value={currentCrop ? currentCrop.cropName : ""} onChange={() => {}} disabled />
          </FlexBox>
          <SkyButton onClick={onClickAdd}>{dict['add']}</SkyButton>
        </FlexBox>
        <TableComponent
          headList={[
            { title: dict['planYearMonth'] },
            { title: dict['salesPlanQuantity'] , type: "comma" },
            { title: dict['salesPlanPrice'] , type: "comma" },
            { title: dict['salesPlanAmount'] , type: "comma" },
            { title: dict['remark'] , type: "text" },
            { title: "Actions" },
          ]}
          bodyList={_salesCropPlanList.map((i, idx) => ({
            seq: i.salesPlanSeq,
            planYM: i.planYM,
            qty: i.qty || 0,
            price: i.price || 0,
            amt: i.amt || 0,
            remark: i.remark,
            Actions: true,
          }))}
          dict={dict}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
        />
      </BottomWhiteBox>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  &:after {
    content: "";
    display: block;
    height: 42px;
    width: 100%;
  }
`
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const TopWhiteBox = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 350px;
  margin-top: 30px;
  padding: 28px 35px 31px;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const BottomWhiteBox = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 850px;
  height: 322px;
  margin-top: 30px;
  padding: 28px 35px 31px;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`

export default SalesPlanContainer(CommonContainer(withTranslation()(SalesPlan)))
