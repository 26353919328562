// 생산>일정관리 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment"
import { withTranslation } from "react-i18next"
import { Dialog } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import ScheduleManageContainer from "../../../containers/production/ScheduleManageContainer"
import RequiredMark from "../../component/RequiredMark"
import DateBox from "../../component/common/DateBox"
import TableCheckBox from "../../component/common/TableCheckBox"
import ComboBox from "../../component/common/ComboBox"
import InputBox from "../../component/common/InputBox"
import InputTimeBox from "../../component/common/InputTimeBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import AlertPopup from "../AlertPopup"

import CommonContainer from "../../../containers/CommonContainer"

const useStyles = makeStyles((theme) => ({
  container: {
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
      margin: "25px",
      borderRadius: "9px",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
      maxWidth: "960px",
    },
  },
}))

const ScheduleAddPopup = (props) => {
  const classes = useStyles()
  const { t, _comboWorkCenterList, _comboCropList, _productionPlan, getComboList, setComboWorkCenterList, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  const { open, onClose } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const [dateFr, setDateFr] = useState(moment().format("YYYYMMDD"))
  const [dateTo, setDateTo] = useState(moment().format("YYYYMMDD"))
  const [timeFr, setTimeFr] = useState("")
  const [timeTo, setTimeTo] = useState("")
  const [isAlarm, setIsAlarm] = useState(false)
  const [cropSeq, setCropSeq] = useState(0)
  const [workCenterSeq, setWorkCenterseq] = useState(0)
  const [harvPlanQty, setHarvPlanQty] = useState("")
  const [content, setContent] = useState("")

  const [minDate, setMinDate] = useState(new Date()) //날짜 최저일
  const [maxDate, setMaxDate] = useState(new Date()) //날짜 최대일

  const [cropAlarm, setCropAlarm] = useState(false)
  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {
    getTranslationWords()
    setCropAlarm(false)
    if (Object.keys(_productionPlan).length != 0) {
      setDateFr(moment(_productionPlan.dateFr, "YYYYMMDD").isValid() ? moment(_productionPlan.dateFr, "YYYYMMDD") : "")
      setDateTo(moment(_productionPlan.dateTo, "YYYYMMDD").isValid() ? moment(_productionPlan.dateTo, "YYYYMMDD") : "")
      setTimeFr(_productionPlan.timeFr || "")
      setTimeTo(_productionPlan.timeTo || "")
      setIsAlarm(_productionPlan.isAlarm == 1)
      setCropSeq(_productionPlan.cropSeq || 0)
      setWorkCenterseq(_productionPlan.workCenterSeq || 0)
      setHarvPlanQty(_productionPlan.harvPlanQty)
      setContent(_productionPlan.content)
      setCropAlarm(_productionPlan.cropSeq? 1 : 0 )
    }
    
    // console.log('_comboCropList>>>>>>>>>>>>>>>>>>>') 
    // console.log(_comboCropList) 
    
    return () => {
      setDateFr(moment().format("YYYYMMDD"))
      setDateTo(moment().format("YYYYMMDD"))
      setTimeFr("")
      setTimeTo("")
      setIsAlarm(false)
      setCropSeq(0)
      setWorkCenterseq(0)
      setHarvPlanQty("")
      setContent("")
    }
  }, [open])

  useEffect(() => {
    const getData = async () => {
      await getComboList({ queryKind: "WorkCenter", cropSeq: cropSeq })
    }

    if (cropSeq > 0) {
      if (_productionPlan.workCenterSeq) setWorkCenterseq(_productionPlan.workCenterSeq)
      else setWorkCenterseq(0)
      getData()
    } else setComboWorkCenterList([])
  }, [cropSeq])

  // 달력 날짜 제한 관련
  useEffect(() => {
    // console.log("@@@@@@@@@@@@@@@typeof dateFr::",typeof dateFr); 
    if(typeof dateFr =="string" && dateFr != undefined && dateFr != "" && dateFr != null){

    // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
    const originalDate = new Date(dateFr.substr(0, 4) + '-' + dateFr.substr(4, 2) + '-' + dateFr.substr(6, 2));
    const modifiedDate = originalDate.setDate(originalDate.getDate() + 10000);

    setMinDate(new Date(dateFr.substr(0,4)+'-'+dateFr.substr(4,2)+'-'+dateFr.substr(6,2)));
    setMaxDate(modifiedDate);
    // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
    // console.log('dateFr(1) : ', dateFr)
    // console.log('dateTo(1) : ', dateTo)
  }else if(typeof dateFr == "object" && dateFr._i != undefined && dateFr._i != "" && dateFr._i != null){

    // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
    const originalDate = new Date(dateFr._i.substr(0, 4) + '-' + dateFr._i.substr(4, 2) + '-' + dateFr._i.substr(6, 2));
    const modifiedDate = originalDate.setDate(originalDate.getDate() + 10000);

    setMinDate(new Date(dateFr._i.substr(0,4)+'-'+dateFr._i.substr(4,2)+'-'+dateFr._i.substr(6,2)));
    setMaxDate(modifiedDate);
    // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
    // console.log('dateFr(1) : ', dateFr)
    // console.log('dateTo(1) : ', dateTo)

  }

  }, [dateFr])

  const onSelectcropAlarm = async (value) => {
    setCropAlarm(value)
    if ( value == 0){ setCropSeq(0), setWorkCenterseq(0), setHarvPlanQty("") }
  
  }
  

  const onClickSave = () => {
    // console.log(_comboCropList.filter((i) =>i.cropSeq == cropSeq).map((i) =>i.cropDateFr)[0])
    // console.log('_comboCropList.filter((i) =>i.cropSeq == cropSeq).map((i) =>i.cropDateFr)[0]')
    // console.log(typeof dateFr == 'string' ? 1:0)
    // console.log(typeof dateFr == 'object' ? 2:0)
    
    // console.log('dateFr', typeof parseInt(dateFrT), parseInt(dateFrT)) //건들이면 string 바로저장시 object
    // console.log('dateTo', typeof parseInt(dateToT), parseInt(dateToT))

    let dateFrT = typeof dateFr == 'string' ?  dateFr : dateFr.format('YYYYMMDD')
    let dateToT = typeof dateTo == 'string' ?  dateTo : dateTo.format('YYYYMMDD')

    if (dateFr == "" || dateTo == "") return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict['date']) })
    else if (_comboCropList.filter((i) =>i.cropSeq == cropSeq).map((i) =>i.cropDateFr)[0] > (typeof dateFr == 'string' ? dateFr: dateFr.format('YYYYMMDD')) || (typeof dateFr == 'string' ? dateFr: dateFr.format('YYYYMMDD')) > _comboCropList.filter((i) =>i.cropSeq == cropSeq).map((i) =>i.cropDateTo)[0]) 
    return setAlertPopup({ open: true, text: replaceWord(dict["n.Please check the contents."], dict['crop']+dict['days']) })
    else if (_comboCropList.filter((i) =>i.cropSeq == cropSeq).map((i) =>i.cropDateFr)[0] > (typeof dateTo == 'string' ? dateTo: dateTo.format('YYYYMMDD')) || (typeof dateTo == 'string' ? dateTo: dateTo.format('YYYYMMDD')) > _comboCropList.filter((i) =>i.cropSeq == cropSeq).map((i) =>i.cropDateTo)[0]) 
    return setAlertPopup({ open: true, text: replaceWord(dict["n.Please check the contents."], dict['crop']+dict['days']) })
    
    else if (parseInt(dateFrT) > parseInt(dateToT)) return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid format. Please try again."], dict['date']) })

    else if (cropAlarm == 1 && cropSeq == 0) return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict['cropName']) })
    else if (cropAlarm == 1 && workCenterSeq == 0) return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict['workCenterName']) })
    else if (cropAlarm == 1 && harvPlanQty === '') return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict['harvestPlanQuantity']) })
    else if (content == "") return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict['content']) })
    
    let param = {
      cropSeq,
      workCenterSeq,
      dateFr: moment(dateFr).format("YYYYMMDD"),
      dateTo: moment(dateTo).format("YYYYMMDD"),
      timeFr: timeFr || "",
      timeTo: timeTo || "",
      isAlarm: isAlarm ? 1 : 0,
      harvPlanQty: harvPlanQty || 0,
      content,
    }
    if (_productionPlan.planSeq) param.planSeq = _productionPlan.planSeq
    onClose(dict['save'], param)
  }

  const onClickDelete = () => {
    if (_productionPlan.planSeq) onClose(dict['delete'], { planSeq: _productionPlan.planSeq })
  }

  return (
    <Dialog className={classes.container} open={open} onClose={onClose} fullWidth={true}>
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <Container>
        <Title>{dict['schedule']}</Title>
        <div style={{ flex: 1 }}>
        <FlexBox>
            <Label>
              <RequiredMark /> {dict['harvestPlan']}
            </Label>
            <TableCheckBox check={cropAlarm} setCheck={onSelectcropAlarm} />
        </FlexBox>

          <FlexBox>
            <Label>
              <RequiredMark /> {dict['date']}
            </Label>
            <DateBox style={{ marginRight: "15px" }} stdDate={dateFr} setStdDate={setDateFr} />
            <p style={{ marginRight: "15px", color: "#555555cc", fontSize: "14px" }}>~</p>
            <DateBox stdDate={dateTo} setStdDate={setDateTo} minDate={minDate} maxDate={maxDate} />
          </FlexBox>
          <FlexBox>
            <Label>{dict['time(24h)']}</Label>
            <InputTimeBox style={{ marginRight: 0 }} disabled={false} placeholder={"00 : 00"} value={timeFr} setValue={setTimeFr} />
            <p style={{ margin: "0 15px", color: "#555555cc", fontSize: "14px" }}>~</p>
            <InputTimeBox style={{ marginRight: 0 }} disabled={false} placeholder={"00 : 00"} value={timeTo} setValue={setTimeTo} />
            <TableCheckBox style={{ marginLeft: "15px" }} check={isAlarm} setCheck={setIsAlarm} />
            <p style={{ margin: "0 7px", color: "#555555cc", fontSize: "14px" }}>{dict['notice']}</p>
          </FlexBox>
          
          <FlexBox style={{display: cropAlarm == 0 ? 'none' : 'flex'}}>
            <Label><RequiredMark />{dict['cropName']}</Label>
            <ComboBox
              style={{ width: "300px" }}
              defaultValue={dict['select']}
              hasDefault={true}
              items={(_comboCropList || []).map((i) => ({ value: i.cropSeq, label: i.cropName }))}
              value={cropSeq}
              setValue={setCropSeq}
            />
          </FlexBox>
          <FlexBox style={{display: cropAlarm == 0 ? 'none' : 'flex'}}>
            <Label><RequiredMark />{dict['workCenterName']}</Label>
            <ComboBox
              style={{ width: "300px" }}
              defaultValue={dict['select']}
              hasDefault={true}
              items={(_comboWorkCenterList || []).map((i) => ({ value: i.workCenterSeq, label: i.workCenterName }))}
              value={workCenterSeq}
              setValue={setWorkCenterseq}
            />
          </FlexBox>
          <FlexBox style={{display: cropAlarm == 0 ? 'none' : 'flex'}}>
            <Label><RequiredMark />{dict['harvestPlanQuantity']}</Label>
            <InputBox style={{ width: "300px" }} type="number" value={harvPlanQty} onChange={(e) => setHarvPlanQty(e.target.value)} />
          </FlexBox>
          
          <FlexBox alignItems="flex-start">
            <Label>
              <RequiredMark /> {dict['content']}
            </Label>
            <TextAreaBox width={0} value={content} setValue={setContent}></TextAreaBox>
          </FlexBox>
        </div>

        <ButtonBox>
          <Button
            bgColor={"#fff"}
            hover={"rgba(174, 174, 174, 0.22)"}
            border={"1px solid #c6c6c6"}
            color={"#555555cc"}
            onClick={() => onClose(dict['cancel'], null)}
          >
            {dict['cancel']}
          </Button>
          {_productionPlan.planSeq && (
            <Button bgColor={"#fa5b59"} hover={"rgba(250, 91, 89, 0.8)"} border={"none"} color={"#fff"} onClick={onClickDelete}>
              {dict['delete']}
            </Button>
          )}
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {dict['save']}
          </Button>
        </ButtonBox>
      </Container>
    </Dialog>
  )
}

ScheduleAddPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

const Container = styled.div`
  padding: 23px 40px 27px;
`
const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  width: 147px;
  height: 40px;
  margin-left: 14px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

export default CommonContainer(ScheduleManageContainer(withTranslation()(ScheduleAddPopup)))
