import React from "react"
import PropTypes from "prop-types"
import DaumPostcode from "react-daum-postcode"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "500px",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#00000000",
      maxWidth: "500px",
    },
    "& .MuiDialog-paper": {
      margin: "20px",
    },
  },
}))

const DaumPostCodePopup = (props) => {
  const classes = useStyles()
  const { onClose, open, onComplete } = props

  return (
    <Dialog className={classes.container} style={{ width: "100%", height: "100%", zIndex: 10000 }} onClose={onClose} open={open} fullWidth={true}>
      <div style={{ height: "466px", margin: 0, padding: 0 }}>
        <DaumPostcode width="100%" height={466} onComplete={onComplete} submitMode={false}></DaumPostcode>
      </div>
    </Dialog>
  )
}

DaumPostCodePopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  onComplete: PropTypes.func.isRequired,
}

export default DaumPostCodePopup
