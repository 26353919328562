// 품목 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import ItemContainer from "../../../containers/defaultInfo/ItemContainer"
import RequiredMark from "../../component/RequiredMark"
import TableCheckBox from "../../component/common/TableCheckBox"
import ComboBox from "../../component/common/ComboBox"
import InputBox from "../../component/common/InputBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import AlertPopup from "../AlertPopup"
import CommonContainer from "../../../containers/CommonContainer" // 다국어 사전 가져오기 위해 import - 차건담 2023.03.02

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
      maxWidth: "615px",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const ItemAddPopup = (props) => {
  const classes = useStyles()
  const { t, _codeList, _item } = props
  const { onClose, open } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const [itemName, setItemName] = useState("")
  const [itemNo, setItemNo] = useState("")
  const [itemKind, setItemKind] = useState(0)
  const [unit, setUnit] = useState(0)
  const [itemClass1, setItemClass1] = useState(0)
  const [itemClass2, setItemClass2] = useState(0)
  const [itemClass3, setItemClass3] = useState(0)
  const [weight, setWeight] = useState("")
  const [sugarContent, setSugarContent] = useState("")
  const [isUse, setIsUse] = useState(true)
  const [etc, setEtc] = useState("")

// 사전 관련 객체 가져오기 - 차건담 2023.03.02
  const { initTranslationWords, getTranslationWords, _translationWords, replaceWord } = props 

  useEffect(() => {
    getTranslationWords() // 사전 가져오는 함수 - 차건담 2023.03.02
    if (open && Object.keys(_item).length != 0) {
      setItemName(_item.itemName || "")
      setItemNo(_item.itemNo || "")
      setItemKind(_item.itemKind || 0)
      setUnit(_item.unitSeq || 0)
      setItemClass1(_item.itemClass1 || 0)
      setItemClass2(_item.itemClass2 || 0)
      setItemClass3(_item.itemClass3 || 0)
      setWeight(_item.stdWeight || "")
      setSugarContent(_item.stdSugar || "")
      setIsUse(_item.isUse == 1)
      setEtc(_item.remark || "")
    }

    return () => {
      setItemName("")
      setItemNo("")
      setItemKind(0)
      setUnit(0)
      setItemClass1(0)
      setItemClass2(0)
      setItemClass3(0)
      setWeight("")
      setSugarContent("")
      setIsUse(true)
      setEtc("")
    }
  }, [open])

  let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.02.28

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  const onClickSave = async () => {
    if (itemName == "") return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("itemName")) })
    else if (itemNo == "") return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("itemNo")) })

    const param = {
      itemName,
      itemNo,
      itemKind: itemKind,
      unitSeq: unit,
      itemClass1: itemClass1,
      itemClass2: itemClass2,
      itemClass3: itemClass3,
      stdWeight: weight || 0,
      stdSugar: sugarContent || 0,
      isUse: isUse ? 1 : 0,
      remark: etc,
    }
    if (_item) param.itemSeq = _item.itemSeq

    onClose(dictCk("save"), param)
  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose(null)}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <div
        style={{
          backgroundColor: "#fff",
          //overflowY: "hidden", 핫픽스 - 차건담 2023.04.21
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "800px",
          padding: "23px 51px 44px 40px",
        }}
      >
        <Title>{dictCk("itemInfo")}</Title>
        <div style={{ flex: 1 }}>
          <FlexBox>
            <Label>
              <RequiredMark /> {dictCk("itemName")}
            </Label>
            <InputBox style={{ width: "300px", marginRight: 0 }} type="text" value={itemName} onChange={(e) => setItemName(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dictCk("itemNo")}
            </Label>
            <InputBox style={{ width: "300px", marginRight: 0 }} type="text" value={itemNo} onChange={(e) => setItemNo(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("itemKind")}</Label>
            <ComboBox
              defaultValue={dictCk("select")}
              hasDefault={true}
              items={
                _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1002).length > 0
                  ? _codeList.filter((i) => i.majorSeq == 1002)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                  : []
              }
              value={itemKind}
              setValue={setItemKind}
            />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("unit")}</Label>
            <ComboBox
              defaultValue={dictCk("select")}
              hasDefault={true}
              items={
                _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1003).length > 0
                  ? _codeList.filter((i) => i.majorSeq == 1003)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                  : []
              }
              value={unit}
              setValue={setUnit}
            />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("itemClass1")}</Label>
            <ComboBox
              defaultValue={dictCk("select")}
              hasDefault={true}
              items={
                _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1004).length > 0
                  ? _codeList.filter((i) => i.majorSeq == 1004)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                  : []
              }
              value={itemClass1}
              setValue={setItemClass1}
            />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("itemClass2")}</Label>
            <ComboBox
              defaultValue={dictCk("select")}
              hasDefault={true}
              items={
                _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1005).length > 0
                  ? _codeList.filter((i) => i.majorSeq == 1005)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                  : []
              }
              value={itemClass2}
              setValue={setItemClass2}
            />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("itemClass3")}</Label>
            <ComboBox
              defaultValue={dictCk("select")}
              hasDefault={true}
              items={
                _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1006).length > 0
                  ? _codeList.filter((i) => i.majorSeq == 1006)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                  : []
              }
              value={itemClass3}
              setValue={setItemClass3}
            />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("stdWeight")}</Label>
            <InputBox style={{ width: "144px", marginRight: 0 }} type="number" value={weight} onChange={(e) => setWeight(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("stdSugar")}</Label>
            <InputBox
              style={{ width: "144px", marginRight: 0 }}
              type="number"
              value={sugarContent}
              onChange={(e) => setSugarContent(e.target.value)}
            />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("isUse")}</Label>
            <TableCheckBox check={isUse} setCheck={setIsUse} />
          </FlexBox>
          <FlexBox alignItems={"flex-start"}>
            <Label>{dictCk("remark")}</Label>
            <TextAreaBox width={0} value={etc} setValue={setEtc} />
          </FlexBox>
        </div>
        <ButtonBox>
          <Button
            bgColor={"#fff"}
            hover={"rgba(174, 174, 174, 0.22)"}
            border={"1px solid #c6c6c6"}
            color={"#555555cc"}
            onClick={() => onClose(dictCk("cancel"), null)}
          >
            {dictCk("cancel")}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {dictCk("save")}
          </Button>
        </ButtonBox>
      </div>
    </Dialog>
  )
}

const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  width: 147px;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

ItemAddPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default CommonContainer(ItemContainer(withTranslation()(ItemAddPopup)))
