// 메인
import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { withTranslation } from "react-i18next"

import CommonContainer from "../../containers/CommonContainer"
import MainContainer from "../../containers/MainContainer"

import Layout from "../component/Layout"
import SEO from "../seo"

import ComboBox from "../component/common/ComboBox"

import ElefarmIcon from "../../images/elefarm.png"

import Temperature from "../../images/temperature.svg"
import Pour_rain from "../../images/pour-rain.svg"
import Ico_breeze from "../../images/ico-breeze.svg"
import OpenWithIcon from "@material-ui/icons/OpenWith"
import Icon_partialy_cloudy from "../../images/icon-partialy-cloudy.svg"
import Icon_rainy_day from "../../images/icon-rainy-day.svg"
import { Fragment } from "react"

const Main = (props) => {
    const { t, initDataList, getDataList, saveDataList, setDataList, getComboList } = props

    const { _moduleList, getModuleList, getBookmarkList, MAIN_DATA_LIST: actionType, mainDataList: dataList } = props

    const { workCenterComboList, postSignOut } = props

    // 다국어 TWord 관련 - 차건담 2023.02.14
    const { initTranslationWords, getTranslationWords, _translationWords, replaceWord } = props

    const [workCenterSeq, setWorkCenterSeq] = useState(0)

    const [gatewayStatusN, setGatewayStatusN] = useState(0) //게이트웨이 연결상태

    let instCycle = null

    useEffect(() => {
        getTranslationWords() // 다국어 TWord 가져오기 - 차건담 2023.02.14
        getData()

        if (instCycle === null) instCycle = setInterval(getMainDataListCycle, 30 * 1000) // 30s

        return async () => {
            clearInterval(instCycle)
            await initDataList(actionType)
        }
    }, [])

    useEffect(() => {
        if (instCycle === null) instCycle = setInterval(getMainDataListCycle, 30 * 1000) // 30s

        return async () => {
            clearInterval(instCycle)

            await initDataList(actionType)
        }
    }, [workCenterSeq])

    //게이트웨이 연결상태
    useEffect(() => {
        let gatewaynum = dataList.gatewayStatus && dataList.gatewayStatus.map((i) => i.connectionStatus)[0]
        setGatewayStatusN(gatewaynum)
    }, [dataList.gatewayStatus && dataList.gatewayStatus.map((i) => i.connectionStatus)[0]])

    let dict = _translationWords[0]

    // useEffect(() => {

    //   if ( workCenterComboList.length > 0 ) {

    //     setWorkCenterSeq(workCenterComboList[0].workCenterSeq)

    //     getMainDataList(workCenterComboList[0].workCenterSeq)

    //   } // end if

    // }, [workCenterComboList])

    const getData = async () => {
        await getModuleList()
        await getBookmarkList()

        await getComboList({ queryKind: "WorkCenter" })

        await getMainDataList(workCenterSeq)
    }

    const getMainDataListCycle = () => {
        console.log("[main] data loading...", workCenterSeq)

        // if( workCenterSeq > 0 )
        getMainDataList(workCenterSeq)
    }

    const getMainDataList = async (workCenterSeq) => {
        const data = {
            workCenterSeq,
        }

        await getDataList(actionType, data)
    }

    const onSelectWorkCenter = (value) => {
        setWorkCenterSeq(value)
        getMainDataList(value)
    }

    const dictCk = (word) => {
        if (dict == undefined || dict == null || dict == {} || dict == "") {
            return word
        } else {
            const result = dict[word] != undefined && dict[word] != null && dict[word] != "" ? dict[word] : word
            return result
        }
    }

    return (
        <Layout>
            <SEO title={dictCk("main")} />
            <Container>
                <div style={{ display: "flex" }}>
                    {/* 왼쪽 */}
                    <div style={{ width: "586px", height: "100%", border: "0px solid red" }}>
                        {/* <div style={{display: "flex", margin: "14px 0px 0px 14px"}}>
              <SensorType1 marginRight='14px' >
                <ComboBox
                  style={{ width: "220px", marginRight: "28px" }}
                  defaultValue={t("select")}
                  hasDefault={false}
                  items={workCenterComboList.map((row) => ({ value: row.workCenterSeq, label: row.workCenterName }))}
                  value={workCenterSeq}
                  setValue={onSelectWorkCenter}
                />
              </SensorType1>

              <div onDoubleClick={async () => {
                const result = await postSignOut()
                if (result) {
                  navigate("/app/login")
                }
              }}>&nbsp;</div>

            </div> */}
                        {/* 외부기상대 */}
                        <div style={{ display: "flex", margin: "14px 0px 0px 14px" }}>
                            <SensorType1
                                width="100px"
                                height="194px"
                                marginRight="14px"
                                style={{ backgroundColor: dataList.sensorData4Info.dateTimeTo1 == 0 ? "#7F8487" : "#52935d" }}
                            >
                                <div style={{ width: "100%", height: "100%", padding: "8px" }}>
                                    <SensorTypeView style={{ fontSize: dictCk("temperature").length >= 11 ? "12px" : "16px" }}>
                                        {dictCk("temperature")}
                                    </SensorTypeView>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80%" }}>
                                        <img style={{ height: `30%` }} src={Temperature} />
                                    </div>
                                    <div style={{ height: "10%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                        {dataList.sensorData4Info.sensorData1}&nbsp;{dataList.sensorData4Info.sensorUnitName1}
                                    </div>
                                </div>
                            </SensorType1>
                            {/* 온도 */}
                            <SensorType1
                                width="100px"
                                height="194px"
                                marginRight="14px"
                                style={{ backgroundColor: dataList.sensorData4Info.dateTimeTo2 == 0 ? "#7F8487" : "#c94b4b" }}
                            >
                                <div style={{ width: "100%", height: "100%", padding: "8px" }}>
                                    <SensorTypeView style={{ fontSize: dictCk("rain").length >= 11 ? "12px" : "16px" }}>
                                        {dictCk("rain")}
                                    </SensorTypeView>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80%" }}>
                                        <img style={{ height: `30%` }} src={Pour_rain} />
                                    </div>
                                    <div style={{ height: "10%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                        {dataList.sensorData4Info.sensorData2}&nbsp;{dataList.sensorData4Info.sensorUnitName2}
                                    </div>
                                </div>
                            </SensorType1>
                            {/* 감우 */}
                            <SensorType1
                                width="100px"
                                height="194px"
                                marginRight="14px"
                                style={{ backgroundColor: dataList.sensorData4Info.dateTimeTo3 == 0 ? "#7F8487" : "#4b8ac9" }}
                            >
                                <div style={{ width: "100%", height: "100%", padding: "8px" }}>
                                    <SensorTypeView style={{ fontSize: dictCk("windDirection").length >= 11 ? "12px" : "16px" }}>
                                        {dictCk("windDirection")}
                                    </SensorTypeView>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80%" }}>
                                        <OpenWithIcon style={{ opacity: "0.5", fontSize: 40 }} />
                                    </div>
                                    <div
                                        style={{ height: "10%", display: "flex", alignItems: "center", justifyContent: "flex-end", fontSize: "14px" }}
                                    >
                                        {dataList.sensorData4Info.sensorData3 == ""
                                            ? ""
                                            : dataList.sensorData4Info.sensorData3 < 22.5 || dataList.sensorData4Info.sensorData3 >= 337.5
                                            ? "(" + dictCk("north") + ")"
                                            : dataList.sensorData4Info.sensorData3 >= 22.5 && dataList.sensorData4Info.sensorData3 < 67.5
                                            ? "(" + dictCk("northeast") + ")"
                                            : dataList.sensorData4Info.sensorData3 >= 67.5 && dataList.sensorData4Info.sensorData3 < 112.5
                                            ? "(" + dictCk("east") + ")"
                                            : dataList.sensorData4Info.sensorData3 >= 112.5 && dataList.sensorData4Info.sensorData3 < 157.5
                                            ? "(" + dictCk("southeast") + ")"
                                            : dataList.sensorData4Info.sensorData3 >= 157.5 && dataList.sensorData4Info.sensorData3 < 202.5
                                            ? "(" + dictCk("south") + ")"
                                            : dataList.sensorData4Info.sensorData3 >= 202.5 && dataList.sensorData4Info.sensorData3 < 247.5
                                            ? "(" + dictCk("southwest") + ")"
                                            : dataList.sensorData4Info.sensorData3 >= 247.5 && dataList.sensorData4Info.sensorData3 < 292.5
                                            ? "(" + dictCk("west") + ")"
                                            : dataList.sensorData4Info.sensorData3 >= 292.5 && dataList.sensorData4Info.sensorData3 < 337.5
                                            ? "(" + dictCk("northwest") + ")"
                                            : ""}
                                        &nbsp;
                                        {dataList.sensorData4Info.sensorData3}&nbsp;{dataList.sensorData4Info.sensorUnitName3}
                                    </div>
                                </div>
                            </SensorType1>
                            {/* 풍향 */}
                            <SensorType1
                                width="100px"
                                height="194px"
                                marginRight="14px"
                                style={{ backgroundColor: dataList.sensorData4Info.dateTimeTo4 == 0 ? "#7F8487" : "#254ca3" }}
                            >
                                <div style={{ width: "100%", height: "100%", padding: "8px" }}>
                                    <SensorTypeView style={{ fontSize: dictCk("weedSpeed").length >= 11 ? "12px" : "16px" }}>
                                        {dictCk("weedSpeed")}
                                    </SensorTypeView>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80%" }}>
                                        <img style={{ height: `30%`, opacity: "0.5" }} src={Ico_breeze} />
                                    </div>
                                    <div style={{ height: "10%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                        {dataList.sensorData4Info.sensorData4}&nbsp;{dataList.sensorData4Info.sensorUnitName4}
                                    </div>
                                </div>
                            </SensorType1>
                            {/* 풍속 */}
                            <SensorType1
                                width="100px"
                                height="194px"
                                marginRight="14px"
                                style={{ backgroundColor: dataList.sensorData4Info.dateTimeTo5 == 0 ? "#7F8487" : "#c82dc1" }}
                            >
                                <div style={{ width: "100%", height: "100%", padding: "8px" }}>
                                    <SensorTypeView style={{ fontSize: dictCk("stdLight").length >= 11 ? "12px" : "16px" }}>
                                        {dictCk("stdLight")}
                                    </SensorTypeView>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "80%" }}>
                                        <img style={{ height: `30%`, opacity: "0.5" }} src={Icon_partialy_cloudy} />
                                    </div>
                                    <div style={{ height: "10%", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                                        {dataList.sensorData4Info.sensorData5}&nbsp;{dataList.sensorData4Info.sensorUnitName5}
                                    </div>
                                </div>
                            </SensorType1>
                            {/* 일사량 */}
                        </div>

                        <div style={{ display: "flex", margin: "14px 0px 0px 14px" }}>
                            {/* 실제온도 */}
                            <SensorDataItem
                                fontSize="25"
                                kind={dictCk("actualTemperature")}
                                value={dataList.sensorData1Info.sensorData1}
                                unit={dataList.sensorData1Info.sensorUnitName1}
                                style={{ marginRight: "14px" }}
                            />
                            {/* 목표온도 */}
                            <SensorDataItem
                                fontSize="25"
                                kind={dictCk("targetTemperature")}
                                value={dataList.sensorData1Info.sensorData2}
                                unit={dataList.sensorData1Info.sensorUnitName2}
                                style={{ marginRight: "14px" }}
                            />
                        </div>
                        {/* 40 200 45 4*14=56 => 285 + 56 = 341 */}
                        <ScrollContainer
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flexWrap: "wrap",
                                width: "570px",
                                height: `calc(100vh - 300px - ${_moduleList.length > 0 ? 96 : 0}px)`,
                                overflow: "auto",
                                margin: "14px 0px 0px 14px",
                            }}
                        >
                            {/* 온도,습도,CO2,EC,PH,Rain,유량 - 왼쪽 흰색 센서칸 */}
                            {/* <SensorNodeItems datas={dataList.sensorDataList} />  */}
                            {dataList.sensorDataList.map((row, idx) => (
                                <SensorNodeItems2 key={idx} data={row} />
                            ))}
                        </ScrollContainer>
                    </div>
                    {/* 오른쪽 */}
                    <div style={{ width: `424px`, height: "100%", display: "flex", flexDirection: "column", border: "0px solid blue" }}>
                        <div style={{ display: "flex", margin: "14px 14px 0px 0px", justifyContent: "flex-end", color: "#555555" }}>
                            <ComboBox
                                style={{ height: "40px", width: "220px", marginRight: "20px" }}
                                defaultValue={dictCk("total")}
                                hasDefault={true}
                                items={workCenterComboList.map((row) => ({ value: row.workCenterSeq, label: row.workCenterName }))}
                                value={workCenterSeq}
                                setValue={onSelectWorkCenter}
                            />
                            {/* ELE-FARM&nbsp;&nbsp;|&nbsp;&nbsp; */}
                            {/* 게이트웨이 표시상태 */}
                            <div
                                style={{
                                    backgroundColor: 1 == gatewayStatusN ? "#FF4A4A" : "#BCE29E",
                                    marginRight: "15px",
                                    width: "70px",
                                    height: "60px",
                                    borderRadius: "20%",
                                    border: "5px solid #FDFFF0",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <span style={{ fontSize: "15px", color: "#000000" }}>
                                    {1 == gatewayStatusN ? dictCk("commFail") : dictCk("commNormal")}
                                </span>
                            </div>

                            <img
                                src={ElefarmIcon}
                                onClick={async () => {
                                    const result = await postSignOut()
                                    if (result) {
                                        navigate("/app/login")
                                    }
                                }}
                            />
                        </div>

                        <div style={{ display: "flex", margin: "14px 0px 0px 0px", justifyContent: "center" }}>
                            <EquipItemView style={{ fontSize: dictCk("auto").length >= 8 ? "11px" : "15px" }} bgColor="#52935d">
                                {dictCk("auto")}
                            </EquipItemView>

                            <EquipItemView style={{ fontSize: dictCk("manual").length >= 8 ? "11px" : "15px" }} bgColor="#fadb56">
                                {dictCk("manual")}
                            </EquipItemView>

                            <EquipItemView style={{ fontSize: dictCk("unused").length >= 8 ? "11px" : "15px" }} bgColor="#aeaeae">
                                {dictCk("unused")}
                            </EquipItemView>

                            <EquipItemView style={{ fontSize: dictCk("open").length >= 8 ? "11px" : "15px" }} bgColor="#8cccfb">
                                {dictCk("open")}
                            </EquipItemView>

                            <EquipItemView style={{ fontSize: dictCk("close").length >= 8 ? "11px" : "15px" }} bgColor="#fa5b59">
                                {dictCk("close")}
                            </EquipItemView>
                        </div>

                        <ScrollContainer
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                height: `calc(100vh - 150px - ${_moduleList.length > 0 ? 96 : 0}px)`,
                                overflow: "auto",
                                margin: "14px 0px 0px 0px",
                            }}
                        >
                            <EquipItems datas={dataList.equipDataList} />
                        </ScrollContainer>
                    </div>
                </div>
            </Container>
        </Layout>
    )
}

const SensorNodeItems2 = (props) => {
    const { data } = props

    return (
        <div style={{ marginBottom: "14px" }}>
            <SensorDataItem2 fontSize="25" kind={data.sensorNodeName} style={{ backgroundColor: "#edfcf0" }} />
            {/* <SensorDataItem2 fontSize="25" kind={data.sensorNodeName} style={{ backgroundColor: data.dateTimeTo == 0 ? "#FD8A8A" : "#edfcf0" }} /> */}
            {/* <SensorDataItem2 fontSize="25" kind={data.dateTimeTo} style={{ backgroundColor: data.dateTimeTo == 0 ? "#FD8A8A" : "#edfcf0"  }} /> 김한중 센서 데이터 연결상태*/}

            {data.sensorDatas.map((row2, idx2) => {
                if (idx2 > 3) return

                return (
                    <SensorDataItem
                        fontSize="25"
                        key={idx2}
                        kind={row2.sensorKindName}
                        value={row2.sensorData}
                        unit={row2.sensorUnitName}
                        row={row2}
                        style={{
                            marginRight: "14px",
                            backgroundColor: row2.dateTimeTo == 0 && Object.keys(row2).length > 10 ? "#B2B2B2" : "#ffffff", //센서죽은거 표시 dateTimeTo 컬럼값이 0이 들어오면 망가진 센서
                        }}
                    />
                )
            })}
        </div>
    )
}

// const SensorNodeItems = (props) => {

//   const { datas = [] } = props

//   return (
//     <>
//       {datas.map( (row,idx) => (
//         <div style={{display: "flex", width: `${row.sensorDatas.length*124+60}px`} } key={idx} >
//           <SensorDataItem kind={row.sensorNodeNo > "" ? "SN"+row.sensorNodeNo : ""} value={''} unit={''} style={{ width: "60px", backgroundColor: "#edfcf0" }} />

//           {row.sensorDatas.map( (row2,idx2) => (

//             <SensorDataItem key={idx2} kind={row2.sensorKindName} value={row2.sensorData} unit={row2.sensorUnitName} style={{ width: "124px" }} />

//           ))}
//         </div>
//       ))}
//     </>
//   )
// }

// 설비 부분 표시 ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ
const EquipItems = (props) => {
    const { datas = [] } = props

    return (
        <>
            {datas.map((row, idx) => (
                <div style={{ display: "flex", marginBottom: "5px" }} key={idx}>
                    {/* <SensorDataItem kind={row.controlKindName} value={''} unit={''} style={{ width: "60px", backgroundColor: "#fadb56" }} /> */}

                    <SensorDataItem
                        fontSize="25"
                        kind={row.equipName}
                        value={""}
                        unit={""}
                        style={
                            row.isUse != "1" && row.controlKind > 0
                                ? { width: "210px", backgroundColor: "#aeaeae" }
                                : row.controlKind == 10200001
                                ? { width: "210px", backgroundColor: "#52935d" }
                                : row.controlKind == 10200002
                                ? { width: "210px", backgroundColor: "#fadb56" }
                                : { width: "210px" }
                        }
                    />
                    <SensorDataItem
                        fontSize="25"
                        kind={row.workKindName}
                        unitWidth="0"
                        value={""}
                        unit={""}
                        style={
                            row.isUse != "1" && row.controlKind > 0
                                ? { width: "100px", backgroundColor: "#aeaeae" }
                                : row.controlKind == 10200001
                                ? { width: "100px", backgroundColor: "#52935d" }
                                : row.controlKind == 10200002
                                ? { width: "100px", backgroundColor: "#fadb56" }
                                : { width: "100px" }
                        }
                    />
                    <SensorDataItem
                        fontSize="25"
                        kind={""}
                        unitWidth="10"
                        value={
                            row.workKind == 10180001
                                ? row.workStatus == 10190001
                                    ? "On"
                                    : "Off"
                                : row.isNotUseOpenPer == 1 && row.openPer == 10190001 // 전환설비(정)
                                ? "정(냉방)"
                                : row.isNotUseOpenPer == 1 && row.openPer == 10190002 // 전환설비(역)
                                ? "역(난방)"
                                : row.isNotUseOpenPer == 1 && row.openPer == 10190003 // 전환설비(정지)
                                ? "정지"
                                : row.isUseStControl == 1 // 정지 릴레이 사용 유무 체크
                                ? "--"
                                : row.openPer
                        }
                        unit={row.workKind == 10180001 ? "" : row.workKind == 10180002 && row.isNotUseOpenPer != 1 ? "%" : ""}
                        style={
                            row.workKind == 10180001 && row.workStatus == 10190001
                                ? { width: "100px", backgroundColor: "#8cccfb" } // On 파랑
                                : row.workKind == 10180001
                                ? { width: "100px", backgroundColor: "#fa5b59" } // Off 빨강
                                : row.workKind == 10180002 && row.openPer > 0 && row.isNotUseOpenPer != 1
                                ? { width: "100px", backgroundColor: "#8cccfb" } // 열림 파랑
                                : row.workKind == 10180002 && row.isNotUseOpenPer != 1
                                ? { width: "100px", backgroundColor: "#fa5b59" } // 닫힘 빨강
                                : row.workKind == 10180002 && row.isNotUseOpenPer == 1 && row.openPer == 10190001
                                ? { width: "100px", backgroundColor: "#8cccfb" } // 열림 파랑 정
                                : row.workKind == 10180002 && row.isNotUseOpenPer == 1 && row.openPer == 10190002
                                ? { width: "100px", backgroundColor: "#fa5b59" } // 닫힘 빨강 역
                                : row.workKind == 10180002 && row.isNotUseOpenPer == 1 && row.openPer == 10190003
                                ? { width: "100px", backgroundColor: "#9DB2BF" } // 정지 회색 정지
                                : { width: "100px" }
                        }
                    />
                </div>
            ))}

            {/* <div style={{display: "flex", flexDirection: "column", height: "100%", overflow: "scroll", margin: "14px 14px 0px 14px"}}> 

      <div style={{display: "flex"} } >
        <SensorDataItem kind={'CH1'} value={''} unit={''} style={{ width: "60px", backgroundColor: "#edfcf0" }} />

        <SensorDataItem kind={'측창'} value={''} unit={''} style={{ width: "180px" }} />
        <SensorDataItem kind={'정/역'} value={''} unit={''} style={{ width: "70px" }} />
        <SensorDataItem kind={'자동'} value={'85'} unit={'%'} style={{ width: "100px" }} />

      </div> */}
        </>
    )
}

const SensorDataItem2 = (props) => {
    const { kind = "", value = "", unit = "", style = {}, fontSize = "15" } = props

    let fontSizeControl = kind.length >= 17 ? 20 : 25 //센서명 길시 폰트크기 줄여줌

    return (
        <div
            style={{
                width: "271px",
                height: `45px`,
                display: "flex",
                alignItems: "center",
                border: `0.5px solid #e1e1e1`,
                padding: `0 12px`,
                backgroundColor: "white",
                ...style,
            }}
        >
            <div style={{ display: "flex", justifyContent: "center", width: `100%` }}>
                <section style={{ padding: `1px 0px 0px 0px`, fontSize: `${fontSizeControl}px`, color: "#555555", lineHeight: `normal` }}>
                    {kind}
                </section>
            </div>
        </div>
    )
}

const SensorDataItem = (props) => {
    const { kind = "", value = "", unit = "", style = {}, fontSize = "15", unitWidth = "30", row } = props

    let fontSizeControl = kind.length >= 9 ? 17 : 23 //센서명 길시 폰트크기 줄여줌

    return (
        <div
            style={{
                width: "271px",
                height: `51px`,
                display: "flex",
                alignItems: "center",
                border: `0.5px solid #e1e1e1`,
                padding: `0 12px`,
                backgroundColor: `${row && row.sensorKind == 10150011 && row.sensorData == "Y" ? "#c94b4b" : "white"}`,
                ...style,
            }}
        >
            <div style={{ display: "flex", justifyContent: "space-between", width: `100%` }}>
                <div style={{ display: "flex" }}>
                    <section style={{ padding: `1px 0px 0px 0px`, fontSize: `${fontSizeControl}px`, color: "#555555", lineHeight: `normal` }}>
                        {kind}
                    </section>
                    {row && row.sensorKind == 10150011 && row.sensorData == "Y" ? (
                        <section style={{ display: "flex", marginLeft: "10px", alignItems: "center" }}>
                            <img src={Icon_rainy_day} style={{ height: `23px` }} />
                        </section>
                    ) : (
                        ""
                    )}
                </div>
                <section style={{ display: "flex" }}>
                    <section style={{ fontSize: `${fontSize}px`, color: "#022808", fontWeight: "bold", lineHeight: `normal` }}>{value}</section>
                    <section
                        style={{
                            padding: `4px 0px 3px 3px`,
                            fontSize: `12px`,
                            color: "#555555",
                            fontWeight: "normal",
                            lineHeight: `normal`,
                            width: `${unitWidth}px`,
                        }}
                    >
                        {unit}
                    </section>
                </section>
            </div>
        </div>
    )
}

const Container = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
`

const SensorType1 = styled.div`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    background-color: ${(props) => props.bgColor};
    border-radius: 11px;
    color: #ffffff;

    padding: ${(props) => props.padding};
    margin: ${(props) => props.margin};
    margin-right: ${(props) => props.marginRight};

    display: flex;
    flex-direction: column;
`

const SensorTypeView = styled.div`
    height: 10%;
    display: flex;
    align-items: center;
    font-size: ${(props) => props.fontSize}; /* 기본 폰트 크기 설정 */
`

const EquipItemView = styled.div`
    background-color: ${(props) => props.bgColor};
    font-size: ${(props) => props.fontSize}; /* 기본 폰트 크기 설정 */
    margin-right: 14px;
    width: 70px;
    height: 35px;
    text-align: center;
    vertical-align: middle;
    color: black;
`

const ScrollContainer = styled.div`
    ::-webkit-scrollbar {
        display: none;
    }
`

export default CommonContainer(MainContainer(withTranslation()(Main)))
