// 현황정보 > 방문자관리
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import { withTranslation } from "react-i18next"
import VisitorManagementContainer from "../../../containers/IncomeInquiry/VisitorManagementContainer"
import PageName from "../../component/common/PageName"
import { SkyButton } from "../../component/common/Button"
import VisitorScheduleAddPopup from "../../popup/StatusInfo/VisitorScheduleAddPopup"
import { TableComponent } from "../../component/common/Table"

import CommonContainer from "../../../containers/CommonContainer"

const ScheduleManage = (props) => {
    const {
        t,
        //방문자관리
        _productionVisitorList,
        initProductionVisitorList,
        initProductionVisitor,
        getProductionVisitorMonthList,
        //getProductionVisitorDayList,
        addProductionVisitor,
        getProductionVisitor,
        editProductionVisitor,
        delProductionVisitor,

        initTranslationWords,
        getTranslationWords,
        _translationWords,
        replaceWord,
    } = props
    const [scheduleAddPopup, setScheduleAddPopup] = useState(false)
    const [calendarType, setCalendarType] = useState(0) // 0 달 1: 주 2: 일 3: agenda
    const [displayDate, setDisplayDate] = useState(moment())

    const dict = _translationWords[0] // 변수에 사전 담기

    const ctrlList = [dict["today"], dict["back"], dict["next"]]
    const typeList = [dict["month"], dict["week"], dict["day"], dict["agenda"]]
    const today = moment()

    useEffect(() => {
        getTranslationWords()
        initData()

        return async () => await initProductionVisitorList() //김한중
    }, [])

    const initData = async () => {
        const pro1 = await getProductionVisitorMonthList({ year: displayDate.format("YYYYMM"), month: displayDate.format("MM") }) //김한중
        Promise.all([pro1])
    }

    const getData = async () => {
        await getProductionVisitorMonthList({ year: displayDate.format("YYYYMM"), month: displayDate.format("MM") }) //김한중
    }

    //월별 목록/////////////////////////////
    const generateMonth = () => {
        const startWeek = (displayDate || today).clone().startOf("month").week()
        const endWeek = (displayDate || today).clone().endOf("month").week() === 1 ? 53 : (displayDate || today).clone().endOf("month").week()

        let calendar = []

        for (let week = startWeek; week <= endWeek; week++) {
            let num = 0
            let num2 = 0
            let positionStartDay = 0
            let positiondEndDay = 0
            let plans = []
            let numposition = []
            let numposition2 = []
            calendar.push(
                <DayContainer key={week}>
                    {Array(7)
                        .fill(0)
                        .map((n, i) => {
                            let current = (displayDate || today)
                                .clone()
                                .startOf("month")
                                .week(week)
                                .startOf("week")
                                .add(n + i, "day")
                            const disabled = (displayDate || today).get("month") !== current.get("month")

                            _productionVisitorList
                                .filter((planItem) =>
                                    moment(current).isBetween(
                                        moment(planItem.dateFr).add(-1, "days").format("YYYY-MM-DD"),
                                        moment(planItem.dateTo).add(1, "days").format("YYYY-MM-DD")
                                    )
                                )
                                .map((filteredItem) => {
                                    if (plans.findIndex((p) => p.visitorSeq == filteredItem.visitorSeq) === -1) {
                                        plans.push(filteredItem)
                                    }
                                })

                            return (
                                //월 숫자 표시
                                <DayBox key={i} isDisabled={disabled}>
                                    <p style={{ padding: "10px", color: disabled ? "#f6f6f6" : "#555555b3", fontSize: "15px" }}>
                                        {current.format("D")}{" "}
                                    </p>
                                </DayBox>
                            )
                        })}
                    {_productionVisitorList.map((planItem, planIndex) => {
                        const filterList = Array(7)
                            .fill(0)
                            .map((i, idx) => {
                                let current = (displayDate || today).clone().startOf("month").week(week).startOf("week").add(idx, "day")
                                const disabled = (displayDate || today).get("month") !== current.get("month")
                                if (
                                    !disabled &&
                                    moment(current).isBetween(
                                        moment(planItem.dateFr).add(-1, "days").format("YYYY-MM-DD"),
                                        moment(planItem.dateTo).add(1, "days").format("YYYY-MM-DD")
                                    )
                                )
                                    return { visible: true, position: idx, current }
                                else return { visible: false, position: idx, current }
                            })
                            .filter((i) => i.visible)
                        if (filterList.length == 0) {
                            return null
                        }

                        // 월 표시 조건 start
                        positionStartDay = filterList[0].position // 일0/월1/화2/수3/목4/금5/토6 시작날 날짜position 데이터 들어갈 값
                        positiondEndDay =
                            filterList[0].position == filterList[filterList.length - 1].position ? null : filterList[filterList.length - 1].position // 일0/월1/화2/수3/목4/금5/토6 마지막날 날짜position 데이터 들어갈 값

                        if (1 == 1) {
                            numposition.push(positionStartDay)
                        }
                        if (1 == 1) {
                            numposition.push(positiondEndDay)
                        }

                        console.log("numposition")
                        console.log(numposition)

                        for (let i = positionStartDay + 1; positionStartDay < i && i < positiondEndDay; i++) {
                            numposition.push(i) // 시작날부터 마지막날 사이 날짜position 값 넣어주는 포문
                        }
                        num2 = numposition.filter((i) => i == filterList[0].position).length //요일에 현재 있는 날짜position 갯수
                        ////////////////////////////////////////////////////////////////////////////////
                        // console.log(numposition2.filter((i) => i.a == positionStartDay  && i.b == num2).map((i) => i.b)[0] )

                        if (numposition2.filter((i) => i.a == positionStartDay && i.b == num2).length > 0) {
                            //기존 행에서 새로 들어올 날짜위치에 이미 값이 존재하면실행
                            console.log(numposition2.filter((i) => i.a == positionStartDay))
                            console.log(Math.max(...numposition2.filter((i) => i.a == positionStartDay).map((i) => i.b)) + 1)
                            if (numposition2.filter((i) => i.a == positionStartDay && i.b == num2).length > 0) {
                                num2 = Math.max(...numposition2.filter((i) => i.a == positionStartDay).map((i) => i.b)) + 1
                            } //시작날짜행의 최대세로값을 구한후 +1 을해줌
                        }

                        if (1 == 1) {
                            numposition2.push({ a: positionStartDay, b: num2 })
                        } //시작날 날짜position + 해당 날짜 위치 = 가로위치 + 세로위치
                        if (1 == 1) {
                            numposition2.push({ a: positiondEndDay, b: num2 })
                        } //마지막날 날짜position + 해당 날짜 위치 = 가로위치 + 세로위치

                        console.log("numposition2")
                        console.log(numposition2)

                        for (let i = positionStartDay + 1; positionStartDay < i && i < positiondEndDay; i++) {
                            numposition2.push({ a: i, b: num2 }) // 시작날부터 마지막날 사이 날짜position 값 + 해당 날짜 위치 = 가로위치 + 세로위치 넣어주는 포문
                        }

                        num = num < num2 ? num2 : num //늘어나는 행에따라 세로크기 늘여주는 변수 기존값보다 num2의 최대치만을 저장

                        // console.log(numposition2)
                        // console.log(filterList)
                        // console.log('lengthto : '+ lengthto)
                        // console.log('numposition')
                        // console.log(numposition)
                        // console.log(numposition2[0][0])
                        // console.log('numposition2')
                        // console.log(numposition2)
                        // console.log(numposition2.length)
                        // console.log(numposition2[0].filter((i) => i == numposition2[1]))
                        // 월 표시 조건 end

                        return (
                            <PlanLabel
                                key={planIndex}
                                num={num2}
                                position={filterList[0].position}
                                ratio={filterList.length}
                                left={planItem.dateFr == filterList[0].current.format("YYYYMMDD") ? "10px" : "0"}
                                right={planItem.dateTo == filterList[filterList.length - 1].current.format("YYYYMMDD") ? "10px" : "0"}
                                onClick={() => onClickEdit(planItem.visitorSeq)}
                            >
                                <PlanLabelInner
                                    hover={"#B7D3DF"}
                                    left={planItem.dateFr == filterList[0].current.format("YYYYMMDD") ? "3px" : "0"}
                                    right={planItem.dateTo == filterList[filterList.length - 1].current.format("YYYYMMDD") ? "3px" : "0"}
                                >
                                    <PlanLabelText>
                                        {dict["visitor"]} &nbsp;{":"}&nbsp;&nbsp;{planItem.visitorName}
                                    </PlanLabelText>
                                </PlanLabelInner>
                            </PlanLabel>
                        )
                    })}
                    {/* 행 숫자에 맞춰 크기가 늘어나는 부분 */}
                    <div
                        style={{
                            width: "1px",
                            height: 35 * (num + 1),
                        }}
                    ></div>
                </DayContainer>
            )
        }
        return calendar
    }

    //주별 목록/////////////////////////////
    const generateWeek = () => {
        const startWeek = (displayDate || today).clone().week()
        let num = 0
        let num2 = 0
        let positionStartDay = 0
        let positiondEndDay = 0
        let numposition = []
        let numposition2 = []
        return (
            <DayContainer>
                {Array(7)
                    .fill(0)
                    .map((n, i) => {
                        let current = (displayDate || today)
                            .clone()
                            .startOf("month")
                            .week(startWeek)
                            .startOf("week")
                            .add(n + i, "day")

                        return (
                            <DayBox key={i} style={{ height: "625px" }} onClick={() => {}}>
                                <p style={{ padding: "10px", color: "#555555b3", fontSize: "15px" }}>{current.format("D")}</p>
                            </DayBox>
                        )
                    })}
                {_productionVisitorList.map((planItem, planIndex) => {
                    const filterList = Array(7)
                        .fill(0)
                        .map((i, idx) => {
                            let current = (displayDate || today).clone().startOf("month").week(startWeek).startOf("week").add(idx, "day")
                            if (
                                moment(current).isBetween(
                                    moment(planItem.dateFr).add(-1, "days").format("YYYY-MM-DD"),
                                    moment(planItem.dateTo).add(1, "days").format("YYYY-MM-DD")
                                )
                            )
                                return { visible: true, position: idx, current }
                            else return { visible: false, position: idx, current }
                        })
                        .filter((i) => i.visible)

                    if (filterList.length == 0) return null

                    // 월 표시 조건 start
                    positionStartDay = filterList[0].position
                    positiondEndDay =
                        filterList[0].position == filterList[filterList.length - 1].position ? null : filterList[filterList.length - 1].position

                    if (1 == 1) {
                        numposition.push(positionStartDay)
                    }
                    if (1 == 1) {
                        numposition.push(positiondEndDay)
                    }

                    for (let i = positionStartDay + 1; positionStartDay < i && i < positiondEndDay; i++) {
                        numposition.push(i)
                    }
                    num2 = numposition.filter((i) => i == filterList[0].position).length
                    ////////////////////////////////////////////////////////////////////////////////
                    // console.log(numposition2.filter((i) => i.a == positionStartDay  && i.b == num2).map((i) => i.b)[0] )

                    if (numposition2.filter((i) => i.a == positionStartDay && i.b == num2).length > 0) {
                        console.log(numposition2.filter((i) => i.a == positionStartDay).map((i) => i.b))
                        console.log(Math.max(...numposition2.filter((i) => i.a == positionStartDay).map((i) => i.b)) + 1)
                        if (numposition2.filter((i) => i.a == positionStartDay && i.b == num2).length > 0) {
                            num2 = Math.max(...numposition2.filter((i) => i.a == positionStartDay).map((i) => i.b)) + 1
                        }
                    }

                    if (1 == 1) {
                        numposition2.push({ a: positionStartDay, b: num2 })
                    }
                    if (1 == 1) {
                        numposition2.push({ a: positiondEndDay, b: num2 })
                    }

                    for (let i = positionStartDay + 1; positionStartDay < i && i < positiondEndDay; i++) {
                        numposition2.push({ a: i, b: num2 })
                    }

                    num = num < num2 ? num2 : num

                    // console.log(numposition2)
                    // console.log(filterList)
                    // console.log('lengthto : '+ lengthto)
                    // console.log('numposition')
                    // console.log(numposition)
                    // console.log(numposition2[0][0])
                    // console.log('numposition2')
                    // console.log(numposition2)
                    // console.log(numposition2.length)
                    // console.log(numposition2[0].filter((i) => i == numposition2[1]))
                    // 월 표시 조건 end

                    return (
                        <PlanLabel
                            key={planIndex}
                            num={num2}
                            position={filterList[0].position}
                            ratio={filterList.length}
                            left={planItem.dateFr == filterList[0].current.format("YYYYMMDD") ? "10px" : "0"}
                            right={planItem.dateTo == filterList[filterList.length - 1].current.format("YYYYMMDD") ? "10px" : "0"}
                            onClick={() => onClickEdit(planItem.visitorSeq)}
                        >
                            <PlanLabelInner
                                hover={"#B7D3DF"}
                                left={planItem.dateFr == filterList[0].current.format("YYYYMMDD") ? "3px" : "0"}
                                right={planItem.dateTo == filterList[filterList.length - 1].current.format("YYYYMMDD") ? "3px" : "0"}
                            >
                                <PlanLabelText>
                                    {dict["visitor"]} &nbsp;{":"}&nbsp;&nbsp;{planItem.visitorName}
                                </PlanLabelText>
                            </PlanLabelInner>
                        </PlanLabel>
                    )
                })}
            </DayContainer>
        )
    }

    const onClickCtrl = (ctrl) => {
        switch (ctrl) {
            case dict["today"]:
                setDisplayDate(moment())
                break
            case dict["back"]:
                switch (calendarType) {
                    case 0:
                        setDisplayDate((prev) => moment(prev).add(-1, "month"))
                        break
                    case 1:
                        setDisplayDate((prev) => moment(prev).add(-1, "week"))
                        break
                    case 2:
                        setDisplayDate((prev) => moment(prev).add(-1, "day"))
                        break
                    case 3:
                        setDisplayDate((prev) => moment(prev).add(-1, "month"))
                        break
                }
                getData()
                break
            case dict["next"]:
                switch (calendarType) {
                    case 0:
                        setDisplayDate((prev) => moment(prev).add(1, "month"))
                        break
                    case 1:
                        setDisplayDate((prev) => moment(prev).add(1, "week"))
                        break
                    case 2:
                        setDisplayDate((prev) => moment(prev).add(1, "day"))
                        break
                    case 3:
                        setDisplayDate((prev) => moment(prev).add(1, "month"))
                        break
                }
                getData()
                break
            default:
                break
        }
    }

    const onClickType = async (type, index) => {
        setCalendarType(index)
        switch (type) {
            case dict["month"]:
                break
            case dict["week"]:
                break
            case dict["day"]:
                break
            case dict["agenda"]:
                break
        }
        getData()
    }

    //신규추가
    const onClickAdd = async () => {
        await initProductionVisitor() //김한중
        setScheduleAddPopup(true)
    }
    //수정
    const onClickEdit = async (seq) => {
        const result = await getProductionVisitor({ visitorSeq: seq })
        if (result) setScheduleAddPopup(true)
    }
    //삭제
    const onClickDelete = async (seq) => {
        // 정보 (삭제)

        const result = await delProductionVisitor({ visitorSeq: seq })
        if (result) getData()
    }

    //팝업창에서 신규추가, 수정, 삭제
    const scheduleAddPopupHandler = async (type, value) => {
        if (type == dict["save"]) {
            let result = false
            if (value.visitorSeq == undefined) {
                result = await addProductionVisitor(value)
            } else {
                result = await editProductionVisitor(value)
            }
            if (result) getData()
        } else if (type == dict["delete"]) {
            const result = await delProductionVisitor(value)
            if (result) getData()
        }
        setScheduleAddPopup(false)
    }

    return (
        <Container>
            <VisitorScheduleAddPopup open={scheduleAddPopup} onClose={scheduleAddPopupHandler} />
            <PageName name={dict["visitorManagement"]} state={false} setState={() => {}} />

            <WhiteBox>
                {/* 캘린더 상단 */}
                <FlexBox justifyContent="space-between" style={{ height: "104px", marginBottom: "14px" }}>
                    <FlexBox direction="column" justifyContent="space-between" alignItems="flex-start" style={{ flex: 1, height: "100%" }}>
                        <p style={{ color: "#555555", fontSize: "28px" }}>
                            {displayDate.get("year")}
                            {/* {displayDate.format("MMMM")} */}
                        </p>
                        <FlexBox>
                            {/* 오늘,이전,다음 */}
                            {ctrlList.map((i, idx) => (
                                <CalendarCtrlBtn key={idx} onClick={() => onClickCtrl(i)} hover={"rgba(174, 174, 174, 0.22)"}>
                                    {i}
                                </CalendarCtrlBtn>
                            ))}
                        </FlexBox>
                    </FlexBox>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "79px",
                            height: "79px",
                            border: "1px solid #d5d5d5",
                            borderRadius: "50%",
                        }}
                    >
                        <p style={{ color: "#52935d", fontSize: "50px", Height: "74px" }}>{displayDate.get("month") + 1}</p>
                    </div>
                    <FlexBox direction="column" justifyContent="space-between" alignItems="flex-end" style={{ flex: 1, height: "100%" }}>
                        <div>
                            <SkyButton onClick={onClickAdd}>{dict["visitorReservation"]}</SkyButton>
                        </div>
                        <FlexBox>
                            {/* 월,주,일,목록 */}
                            {typeList.map((i, idx) => (
                                <CalendarTypeBtn key={idx} active={calendarType == idx} onClick={() => onClickType(i, idx)}>
                                    {i}
                                </CalendarTypeBtn>
                            ))}
                        </FlexBox>
                    </FlexBox>
                </FlexBox>

                {/* 캘린더 하단 */}
                <div style={{ flex: 1 }}>
                    {/* 월 */}
                    {calendarType == 0 && (
                        <>
                            <WeekContainer>
                                {[dict["sun"], dict["mon"], dict["tue"], dict["wed"], dict["thu"], dict["fri"], dict["sat"]].map((i, idx) => (
                                    <WeekBox key={idx} style={{ fontSize: "15px" }}>
                                        {i}
                                    </WeekBox>
                                ))}
                            </WeekContainer>
                            {generateMonth()}
                        </>
                    )}
                    {/* 주 */}
                    {calendarType == 1 && (
                        <>
                            <WeekContainer>
                                {[dict["sun"], dict["mon"], dict["tue"], dict["wed"], dict["thu"], dict["fri"], dict["sat"]].map((i, idx) => (
                                    <WeekBox key={idx} style={{ fontSize: "15px" }}>
                                        {i}
                                    </WeekBox>
                                ))}
                            </WeekContainer>
                            {generateWeek()}
                        </>
                    )}
                    {/* 일 별 목록*/}
                    {calendarType == 2 && (
                        // <>
                        //   <WeekContainer>
                        //     <WeekBox style={{ paddingLeft: "10px", textAlign: "left", fontSize: "15px" }}>{t(displayDate.format("ddd").toLocaleLowerCase())}</WeekBox>
                        //   </WeekContainer>
                        //   <DayContainer>
                        //     <DayBox style={{ height: "625px" }}>
                        //       <p style={{ padding: "10px", color: "#555555b3", fontSize: "15px" }}>{displayDate.format("D")}</p>
                        //       {/* {_productionVisitorList
                        //         .filter((planItem) =>
                        //           moment(displayDate).isBetween(
                        //             moment(planItem.dateFr).format("YYYY-MM-DD"),
                        //             moment(planItem.dateTo).add(1, "days").format("YYYY-MM-DD")
                        //           )
                        //         )
                        //         .map((i, idx) => (
                        //           <DayPlanLabel key={idx} top={35 * (idx + 1)} left={"10px"} right={"10px"} onClick={() => onClickEdit(i.visitorSeq)}>
                        //             <DayPlanLabelInner left={"3px"} right={"3px"} hover={"rgba(102, 204, 102)"}>
                        //               <PlanLabelText2>
                        //               {t("방문자")} &nbsp;{':'}&nbsp;&nbsp;{i.visitorName}
                        //               </PlanLabelText2>
                        //               <PlanLabelText2>
                        //               {t("방문목적")} &nbsp;{':'}&nbsp;&nbsp;{i.visitPurpose}
                        //               </PlanLabelText2>

                        //               <PlanLabelText2>
                        //               </PlanLabelText2>
                        //               <PlanLabelText2>
                        //               </PlanLabelText2>
                        //               <PlanLabelText2>
                        //               </PlanLabelText2>

                        //             </DayPlanLabelInner>
                        //           </DayPlanLabel>
                        //         ))} */}
                        //     </DayBox>
                        //   </DayContainer>
                        // </>
                        <>
                            <WeekContainer>
                                <WeekBox style={{ paddingLeft: "10px", textAlign: "left", fontSize: "15px" }}>
                                    {t(moment(displayDate).format("YYYY-MM-DD")) + "  "}&&{"  " + t(displayDate.format("ddd").toLocaleLowerCase())}
                                </WeekBox>
                            </WeekContainer>
                            <TopWhiteBox>
                                <TableComponent
                                    headList={[
                                        { title: dict["days"] },
                                        { title: dict["time"] },
                                        { title: dict["visitPurpose"] },
                                        { title: dict["visitPersonnel"] },
                                        { title: dict["visitorName"] },
                                        { title: dict["visitorNumber"] },
                                        { title: dict["requesterName"] },
                                        { title: dict["requestorNumber"] },
                                        { title: dict["companyName"], type: "text" },
                                        { title: dict["remark"], type: "text" },
                                        { title: dict["actions"] }, // 수정 2024.03.18 by 강동우
                                        //{ title: `${t("actions")}` }, 
                                    ]}
                                    bodyList={_productionVisitorList
                                        .filter((planItem) =>
                                            moment(displayDate).isBetween(
                                                moment(planItem.dateFr).format("YYYY-MM-DD"),
                                                moment(planItem.dateTo).add(1, "days").format("YYYY-MM-DD")
                                            )
                                        )
                                        .map((i, idx) => ({
                                            seq: i.visitorSeq,
                                            Date:
                                                i.dateFr == 0 ||
                                                i.dateFr == null ||
                                                i.dateFr == undefined ||
                                                i.dateTo == 0 ||
                                                i.dateTo == null ||
                                                i.dateTo == undefined
                                                    ? ""
                                                    : i.dateFr.substr(0, 4) +
                                                      "." +
                                                      i.dateFr.substr(4, 2) +
                                                      "." +
                                                      i.dateFr.substr(6, 2) +
                                                      " ~ " +
                                                      i.dateTo.substr(0, 4) +
                                                      "." +
                                                      i.dateTo.substr(4, 2) +
                                                      "." +
                                                      i.dateTo.substr(6, 2),
                                            time:
                                                i.timeFr == 0 ||
                                                i.timeFr == null ||
                                                i.timeFr == undefined ||
                                                i.timeTo == 0 ||
                                                i.timeTo == null ||
                                                i.timeTo == undefined
                                                    ? ""
                                                    : i.timeFr.substr(0, 2) +
                                                      ":" +
                                                      i.timeFr.substr(-2) +
                                                      " ~ " +
                                                      i.timeTo.substr(0, 2) +
                                                      ":" +
                                                      i.timeTo.substr(-2),
                                            visitPurpose:
                                                i.visitPurpose == 0 || i.visitPurpose == null || i.visitPurpose == undefined ? "" : i.visitPurpose,
                                            visitPersonnel:
                                                i.visitPersonnel == 0 || i.visitPersonnel == null || i.visitPersonnel == undefined
                                                    ? 0
                                                    : i.visitPersonnel,
                                            visitorName: i.visitorName,
                                            visitorNumber:
                                                i.visitorNumber == 0 || i.visitorNumber == null || i.visitorNumber == undefined
                                                    ? ""
                                                    : i.visitorNumber,
                                            requesterName:
                                                i.requesterName == 0 || i.requesterName == null || i.requesterName == undefined
                                                    ? ""
                                                    : i.requesterName,
                                            requestorNumber:
                                                i.requestorNumber == 0 || i.requestorNumber == null || i.requestorNumber == undefined
                                                    ? ""
                                                    : i.requestorNumber,
                                            companyName:
                                                i.companyName == 0 || i.companyName == null || i.companyName == undefined ? "" : i.companyName,
                                            content: i.content == 0 || i.content == null || i.content == undefined ? "" : i.content,
                                            Actions: true,
                                        }))}
                                    dict={dict}
                                    onClickEdit={(seq) => onClickEdit(seq)} // 수정
                                    onClickDelete={(seq) => onClickDelete(seq)} // 삭제
                                />
                            </TopWhiteBox>
                        </>
                    )}
                    {/* 목록 */}
                    {calendarType == 3 && (
                        <>
                            <WeekContainer>
                                <WeekBox style={{ paddingLeft: "10px", textAlign: "left", fontSize: "15px" }}>
                                    {t(moment(displayDate).format("YYYY-MM")) + "  "}
                                </WeekBox>
                            </WeekContainer>
                            <TopWhiteBox>
                                <TableComponent
                                    headList={[
                                        { title: dict["days"] },
                                        { title: dict["time"] },
                                        { title: dict["visitPurpose"] },
                                        { title: dict["visitPersonnel"] },
                                        { title: dict["visitorName"] },
                                        { title: dict["visitorNumber"] },
                                        { title: dict["requesterName"] },
                                        { title: dict["requestorNumber"] },
                                        { title: dict["companyName"], type: "text" },
                                        { title: dict["remark"], type: "text" },
                                        { title: dict["actions"] }, // 수정 2024.03.18 by 강동우 
                                        //{ title: `${t("actions")}` },
                                    ]}
                                    bodyList={_productionVisitorList
                                        .filter((planItem) =>
                                            moment(displayDate).isBetween(
                                                moment(planItem.dateFr).startOf("month"),
                                                moment(planItem.dateTo).endOf("month")
                                            )
                                        )
                                        .map((i, idx) => ({
                                            seq: i.visitorSeq,
                                            Date:
                                                i.dateFr == 0 ||
                                                i.dateFr == null ||
                                                i.dateFr == undefined ||
                                                i.dateTo == 0 ||
                                                i.dateTo == null ||
                                                i.dateTo == undefined
                                                    ? ""
                                                    : i.dateFr.substr(0, 4) +
                                                      "." +
                                                      i.dateFr.substr(4, 2) +
                                                      "." +
                                                      i.dateFr.substr(6, 2) +
                                                      " ~ " +
                                                      i.dateTo.substr(0, 4) +
                                                      "." +
                                                      i.dateTo.substr(4, 2) +
                                                      "." +
                                                      i.dateTo.substr(6, 2),
                                            time:
                                                i.timeFr == 0 ||
                                                i.timeFr == null ||
                                                i.timeFr == undefined ||
                                                i.timeTo == 0 ||
                                                i.timeTo == null ||
                                                i.timeTo == undefined
                                                    ? ""
                                                    : i.timeFr.substr(0, 2) +
                                                      ":" +
                                                      i.timeFr.substr(-2) +
                                                      " ~ " +
                                                      i.timeTo.substr(0, 2) +
                                                      ":" +
                                                      i.timeTo.substr(-2),
                                            visitPurpose:
                                                i.visitPurpose == 0 || i.visitPurpose == null || i.visitPurpose == undefined ? "" : i.visitPurpose,
                                            visitPersonnel:
                                                i.visitPersonnel == 0 || i.visitPersonnel == null || i.visitPersonnel == undefined
                                                    ? 0
                                                    : i.visitPersonnel,
                                            visitorName: i.visitorName,
                                            visitorNumber:
                                                i.visitorNumber == 0 || i.visitorNumber == null || i.visitorNumber == undefined
                                                    ? ""
                                                    : i.visitorNumber,
                                            requesterName:
                                                i.requesterName == 0 || i.requesterName == null || i.requesterName == undefined
                                                    ? ""
                                                    : i.requesterName,
                                            requestorNumber:
                                                i.requestorNumber == 0 || i.requestorNumber == null || i.requestorNumber == undefined
                                                    ? ""
                                                    : i.requestorNumber,
                                            companyName:
                                                i.companyName == 0 || i.companyName == null || i.companyName == undefined ? "" : i.companyName,
                                            content: i.content == 0 || i.content == null || i.content == undefined ? "" : i.content,
                                            Actions: true,
                                        }))}
                                    dict={dict}
                                    onClickEdit={(seq) => onClickEdit(seq)} // 수정
                                    onClickDelete={(seq) => onClickDelete(seq)} // 삭제
                                />
                            </TopWhiteBox>
                        </>
                    )}
                </div>
            </WhiteBox>
        </Container>
    )
}

const Label = styled.p`
    opacity: 80%;
    width: 102px;
    color: #555555;
    font-size: 14px;
    line-height: 34px;
`

const Container = styled.div`
    width: 100%;
    height: 100%;

    &:after {
        content: "";
        display: block;
        height: 42px;
        width: 100%;
    }
`
const FlexBox = styled.div`
    display: flex;
    flex-direction: ${(props) => props.direction || "row"};
    justify-content: ${(props) => props.justifyContent || "flex-start"};
    align-items: ${(props) => props.alignItems || "center"};
`

const TopWhiteBox = styled.div`
    display: flex;
    overflow: hidden;
    flex-direction: column;
    height: 500px;
    border-radius: 9px;
`

const WhiteBox = styled.div`
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 28px 35px 34px;
    border-radius: 9px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
    margin-top: 30px;
    padding: 28px 32px 31px;
`
const CalendarCtrlBtn = styled.button`
    background-color: #fff;
    width: 65px;
    height: 30px;
    margin-left: -1px;
    border: 1px solid #dedede;
    border-radius: 1px;
    color: #55555580;
    font-size: 13px;

    &:hover {
        background-color: ${(props) => props.hover};
    }
`
const CalendarTypeBtn = styled.button`
    background-color: ${(props) => (props.active ? "#6d6d6d" : "#fff")};
    width: 85px;
    height: 34px;
    margin-left: 4px;
    border: 1px solid ${(props) => (props.active ? "#6e746b" : "#dedede")};
    border-radius: 20px;
    color: ${(props) => (props.active ? "#fff" : "#55555580")};
    font-size: 14px;
    font-weight: ${(props) => (props.active ? 700 : 400)};
`
//
const WeekContainer = styled.div`
    display: flex;
`
const WeekBox = styled.button`
    background-color: #f6f6f6;
    flex: 1;
    height: 40px;
    margin-left: -1px;
    border: 1px solid #e1e1e1;
    color: #555555b3;
    font-size: 12px;
    font-weight: 500;
`
const DayContainer = styled.div`
    position: relative;
    display: flex;
`
const DayBox = styled.div`
    background-color: ${(props) => (props.isDisabled ? "#f6f6f6" : "#fff")};
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1;
    /* height: fit-content; */
    min-height: 124px;
    margin-top: -1px;
    margin-left: -1px;
    border: 1px solid #e1e1e1;
`
const PlanLabel = styled.button`
  position: absolute;
  top: calc(35px * (${(props) => props.num}));
  left: calc(100% / 7 * (${(props) => props.position}));
  width: calc(100% / 7 * (${(props) => props.ratio}));
  height: 24px;
  padding-left: ${(props) => props.left};
  padding-right: ${(props) => props.right};
  &:hover {
    background-color: ${(props) => props.hover};
`
const PlanLabelInner = styled.div`
  background-color: #CDF0EA;
  width: 100%;
  height: 24px;
  border-top-left-radius: ${(props) => props.left};
  border-bottom-left-radius: ${(props) => props.left};
  border-top-right-radius: ${(props) => props.right};
  border-bottom-right-radius: ${(props) => props.right};
  &:hover {
    background-color: ${(props) => props.hover};
`
const PlanLabelText = styled.p`
  overflow-x: hidden;
  flex: 1;
  padding: 0 10px;
  color: #434242;
  font-size: 12px;
  line-height: 22px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  
  &:hover {
    background-color: ${(props) => props.hover};

`
const PlanLabelText2 = styled.p`
  overflow-x: hidden;
  flex: 2;
  padding: 0 15px;
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  
  &:hover {
    background-color: ${(props) => props.hover};

`

const DayPlanLabel = styled.div`
    position: absolute;
    width: calc(100%);
    top: ${(props) => props.top}px;
    left: 0px;
    padding-left: ${(props) => props.left};
    padding-right: ${(props) => props.right};
`
const DayPlanLabelInner = styled.div`
  background-color: #52935d;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
  height: 24px;
  border-top-left-radius: ${(props) => props.left};
  border-bottom-left-radius: ${(props) => props.left};
  border-top-right-radius: ${(props) => props.right};
  border-bottom-right-radius: ${(props) => props.right};
  &:hover {
    background-color: ${(props) => props.hover};
`

export default VisitorManagementContainer(CommonContainer(withTranslation()(ScheduleManage)))
