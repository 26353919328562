import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const { setFarmInfo, getFarmInfo, editFarmInfo } = actions.DefaultInfoAction
const { getCodeList } = actions.SystemSettingAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _farmInfo: state.DefaultInfoReducer._farmInfo,
  _codeList: state.SystemSettingReducer._codeList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  setFarmInfo: (param) => dispatch(setFarmInfo(param)),
  getFarmInfo: (param) => dispatch(getFarmInfo(param)),
  editFarmInfo: (param) => dispatch(editFarmInfo(param)),
  getCodeList: (param) => dispatch(getCodeList(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
