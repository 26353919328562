// 현황정보 > 센서데이터조회
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import { withTranslation } from "react-i18next"

import CommonContainer from "../../../containers/CommonContainer"
import IncomeInquiryContainer from "../../../containers/IncomeInquiry/IncomeInquiryContainer"

import AlertPopup from "../../popup/AlertPopup"

import PageName from "../../component/common/PageName"
import ChartLineBox from "../../component/common/ChartLineBox"

import RequiredMark from "../../component/RequiredMark"
import MultiComboBox from "../../component/common/MultiComboBox"
import ComboBox from "../../component/common/ComboBox"
import { YellowButton } from "../../component/common/Button"

import {Oval} from "react-loader-spinner";

const SensorDataInquiry = (props) => {

  const { t, initDataList, getDataList, saveDataList, setDataList, getComboList, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props

  const { workCenterComboList, sensorNodeComboList, sensorComboList } = props
  
  const { 
    
    sensorDataList: dataList, 
    SENSOR_DATA_LIST: actionType, 

  } = props
  
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const [hd, setHD] = useState(1)

  const [ workCenterSeq, setWorkCenterSeq ] = useState(0)
  const [ sensorNodeSeq, setSensorNodeSeq ] = useState(0)
  const [ sensorOptions, setSensorOptions ] = useState([])

  const [loading, setloading] = useState(true) // 로딩중

  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {

    getTranslationWords()
    getData()

    return async () => await initDataList(actionType)

  }, [])

  const getData = async () => {
    
    await getComboList({ queryKind: "WorkCenter" })
    await getComboList({ queryKind: "SensorNode" })
    await getComboList({ queryKind: "Sensor" })

  }

  const onClickSearch = async ( hd ) => {

    if(sensorOptions.length !== 0){setloading(false)}
    //setloading(false) // 조회시 스피너로 바꿔줌

    const data = {
      sensorSeqs : sensorOptions.map( row => row.value ),
      hd : hd
    }

    if ( data.sensorSeqs.length === 0 ) return setAlertPopup({ open: true, text: replaceWord(dict['n.Invalid value. Please try again.'], dict['sensorName']) })

    await getDataList(actionType, data)

    if(sensorOptions.length !== 0){setloading(true)}
    //setloading(true) // 조회끝난후 다시 조회버튼으로 바꿔줌

  }

  const onSelectWorkCenter = async ( value ) => {
    
    setWorkCenterSeq( value )

    setSensorNodeSeq( 0 )
    setSensorOptions( [] )

    await getComboList({ queryKind : "SensorNode", workCenterSeq : value })
    await getComboList({ queryKind : "Sensor", workCenterSeq : value })

  }

  const onSelectSensorNode = async ( value ) => {
        
    setSensorNodeSeq( value )

    setSensorOptions( [] )

  }

  const onSelectHD = (hd) => {

    setHD(hd)

    onClickSearch(hd)

  }

  return (
    <Container>
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <FlexBox justifyContent={"space-between"}>
        <PageName name={dict['sensorDataInquiry']} state={false} setState={() => {}} />
      </FlexBox>
      <Box>
        <WhiteBox>
          <SearchBox>
            <FlexBox>
              <Text>{dict['workCenterName']}</Text>
              <ComboBox
                style={{ width: "220px", marginRight: "28px" }}
                defaultValue={dict['select']}
                hasDefault={true}
                items={workCenterComboList.map((row) => ({ value: row.workCenterSeq, label: row.workCenterName }))}
                value={workCenterSeq}
                setValue={onSelectWorkCenter}
              />
              <Text>{dict['sensorNodeName']}</Text>
              <ComboBox
                style={{ width: "220px", marginRight: "28px" }}
                defaultValue={dict['select']}
                hasDefault={true}
                items={sensorNodeComboList.map((row) => ({ value: row.sensorNodeSeq, label: row.sensorNodeName }))}
                value={sensorNodeSeq}
                setValue={onSelectSensorNode}
              />
            </FlexBox>
            {loading ? <YellowButton onClick={()=>onClickSearch(hd)}>{dict['search']}</YellowButton> : <Oval height={50} width={50}/>}
            {/* <YellowButton >{'긴급점검중 사용불가'}</YellowButton> */}
          </SearchBox>
          <ClassesBox>
            <div>
              <Text>
                <RequiredMark /> {dict['sensorName']}
              </Text>
              <MultiComboBox style={{width:'548px'}}
                placeholder={dict['multiSelect']}
                items={sensorComboList.filter( row => sensorNodeSeq == 0 || row.sensorNodeSeq == sensorNodeSeq ).map((row) => ({ value: row.sensorSeq, label: row.sensorNodeName +' - '+ row.sensorName }))}
                value={sensorOptions}
                setValue={setSensorOptions}
              />
            </div>
            <div>
              <span>{hd == 1 ? dict['time(24h)'] : '30'+dict['day']}</span>&nbsp;&nbsp;&nbsp;&nbsp;
              <span>
                <a href="#" style={
                  hd == 1 ? { fontSize: `20px`, fontWeight: "bold", textDecoration: "underline", color: "black" } : 
                            { fontSize: `18px`, color: "black", textDecoration: "none" }
                } onClick={() => onSelectHD(1)} >
                  H
                </a>
              </span>
              &nbsp;|&nbsp;
              <span>
                <a href="#" style={
                  hd == 2 ? { fontSize: `20px`, fontWeight: "bold", textDecoration: "underline", color: "black" } : 
                            { fontSize: `18px`, color: "black", textDecoration: "none" }
                } onClick={() => onSelectHD(2)} >
                  D
                </a>
              </span>
            </div>
          </ClassesBox>
          
          {dataList.length > 0 && <ChartLineBox labels={[]} datas={dataList} />}

        </WhiteBox>
      </Box>
    </Container>
  )
}

export default IncomeInquiryContainer(CommonContainer(withTranslation()(SensorDataInquiry)))

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  margin-top: 19px;
  padding: 28px 32px 20px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`

const ClassesBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`