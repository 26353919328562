import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const { 
  
  getInfoProfitAndLoss, setInfoProfitAndLoss, getSalesCropList, setSalesCropList,

  SENSOR_DATA_LIST,
  PEST_DATA_LIST,

} = actions.StatusInfoAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  
  _infoProfitAndLoss: state.StatusInfoReducer._infoProfitAndLoss,
  _salesCropList: state.StatusInfoReducer._salesCropList,
  
  sensorDataList: state.CommonReducer.sensorDataList || SENSOR_DATA_LIST.initData, SENSOR_DATA_LIST,
  pestDataList: state.CommonReducer.pestDataList || PEST_DATA_LIST.initData, PEST_DATA_LIST,

})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getInfoProfitAndLoss: (param) => dispatch(getInfoProfitAndLoss(param)),
  setInfoProfitAndLoss: (param) => dispatch(setInfoProfitAndLoss(param)),
  getSalesCropList: (param) => dispatch(getSalesCropList(param)),
  setSalesCropList: (param) => dispatch(setSalesCropList(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
