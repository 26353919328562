// 품목 추가/수정 팝업
import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import styled from "styled-components"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"

import { dateFormat } from "../../../utils/util"

import AlertPopup from "../AlertPopup"

import CommonContainer from "../../../containers/CommonContainer"
import SalesContainer from "../../../containers/SalesContainer"

import SalesItemInfoPopup from "./SalesItemInfoPopup"

import RequiredMark from "../../component/RequiredMark"
import { TableComponent } from "../../component/common/Table"
import { SkyButton, YellowButton, GrayButton } from "../../component/common/Button"
import DateBox from "../../component/common/DateBox"
import InputBox from "../../component/common/InputBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import CodeHelpBox from "../../component/common/CodeHelpBox"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
      maxWidth: "1124px",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const SalesInfoPopup = (props) => {

  const classes = useStyles()

  const { t, initDataList, getDataList, saveDataList, setDataList, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  
  const { 
    salesInfo: dataInfo,
    SALES_INFO: actionType,
    ADD_SALES_INFO,
    MOD_SALES_INFO,
    DEL_SALES_ITEM,
    salesSeq,
  } = props

  const { onClose, open } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })

  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [salesItemIndex, setSalesItemIndex] = useState(0)

  const dict = _translationWords[0] // 변수에 사전 담기

  const setDataInfo = (data) => {
    setDataList({ salesInfo: data })
  }

  const temp1 = dateFormat(new Date(), "YYYYMMDD")

  const setDate = (date) => {
    setDataInfo({ ...dataInfo, salesDate: date })
  }
  const setSalesNo = (salesNo) => {
    setDataInfo({ ...dataInfo, salesNo })
  }
  const setCustSeq = (cust) => {
    setDataInfo({ ...dataInfo, custName: cust.label, custSeq: cust.value })
  }
  const initCust = { value: 0, label: "" }
  const [cust, setCust] = useState(initCust) // 코드도움 (판매거래처)
  const setRemark = (remark) => {
    setDataInfo({ ...dataInfo, remark })
  }

  useEffect(() => {
    getTranslationWords()
    if (open) getData()

    return async () => {
      await initDataList(actionType)
      setCust(initCust)
    }
  }, [open])

  useEffect(() => {
    if (open) setCust({ value: dataInfo.custSeq, label: dataInfo.custName })
  }, [dataInfo])

  const getData = async () => {
    const data = { salesSeq }

    await getDataList(actionType, data)
  }

  const closePopup = async () => {
    setIsOpenPopup(false)

    // getData()
  }

  const onClickInfo = async (index) => {
    // 정보 (추가/수정)

    // setSalesItemSeq( salesItemSeq )
    setSalesItemIndex(index)

    setIsOpenPopup(true)
  }

  const onClickDelete = async (index) => {
    // 정보 (삭제)

    const item = dataInfo.items.filter((row, idx) => idx == index)

    if (item.length > 0) {
      const salesItemSeq = item[0].salesItemSeq || 0

      if (salesItemSeq == 0 || (await saveDataList(DEL_SALES_ITEM, { salesItemSeq }))) {
        const data = {
          ...dataInfo,
          items: dataInfo.items.filter((row, idx) => idx != index),
        }

        setDataInfo(data)
      } // end if
    } // end if
  }

  const onClickSave = async () => {
    const salesDate = dataInfo.salesDate === undefined ? temp1 : dataInfo.salesDate
    const custSeq = cust.value || 0
    const items = dataInfo.items || []

    if (salesDate == "") return setAlertPopup({ open: true, text:replaceWord(dict['n.Invalid value. Please try again.'], dict['SalesDate']) })
    else if (custSeq <= 0) return setAlertPopup({ open: true, text:replaceWord(dict['n.Invalid value. Please try again.'], dict['salesCustName']) })
    else if (items.length === 0) return setAlertPopup({ open: true, text:replaceWord(dict['n.Invalid value. Please try again.'], dict['itemInfo']) })

    let data = {
      ...dataInfo,
      custSeq,
      custName: cust.label,
      salesDate,
      items: items.map((row) => ({ ...row, salesQty: row.qty })),
    }

    // console.log(777,data)
    if (!data.remark) data = { ...data, remark: "" }
    if (data.salesSeq > 0) {
      // 수정

      if (await saveDataList(MOD_SALES_INFO, data)) onClose()
    } else {
      // 추가
      if (await saveDataList(ADD_SALES_INFO, data)) onClose()
    } // end if
  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose()}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <SalesItemInfoPopup open={isOpenPopup} onClose={closePopup} salesItemIndex={salesItemIndex} />
      <div
        style={{
          backgroundColor: "#fff",
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "800px",
          padding: "23px 51px 44px 40px",
        }}
      >
        <Title>{dict['salesRegistration']}</Title>
        <div style={{ flex: 1 }}>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['saleDate']}
            </Label>
            <DateBox stdDate={dataInfo.salesDate} setStdDate={setDate} style={{ marginRight: "5px", width: "120px" }} />
            <Label>{dict['saleNo']}</Label>
            <InputBox type="text" value={dataInfo.salesNo} onChange={(e) => setSalesNo(e.target.value)} disabled={true} />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['salesCustomer']}
            </Label>
            {/* <InputBox type="text" value={dataInfo.custSeq || custSeq} onChange={(e) => setCustSeq(e.target.value)} /> */}
            <CodeHelpBox
              style={{ width: "260px" }}
              placeholder={dict['custName']}
              codeHelpSeq={2}
              codeHelpParam={{ custKind: 10060001 }} // 판매거래처
              value={cust}
              // value={{value: dataInfo.cusSeq, label: dataInfo.custName}}
              setValue={setCustSeq}
            />
          </FlexBox>
          <FlexBox alignItems="flex-start">
            <Label>{dict['remark']}</Label>
            <TextAreaBox width={0} value={dataInfo.remark || ""} setValue={setRemark}></TextAreaBox>
            <FlexBox alignItems="flex-end" style={{ height: "80px", marginLeft: "14px", marginBottom: "0px" }}>
              <SkyButton onClick={() => onClickInfo(-1)}>{dict['add']}</SkyButton>
            </FlexBox>
          </FlexBox>
          <TableComponent
            style={{ marginTop: "0px" }}
            headList={[
              { title: dict['cropName'] , type: "text" },
              { title: dict['itemName'] , type: "text" },
              { title: dict['unit'] , type: "text" },
              { title: dict['quantity'] , type: "comma" },
              { title: dict['price'] , type: "comma" },
              { title: dict['amt'] , type: "comma" },
              { title: dict['remark'] , type: "text" },
              { title: "Actions" },
            ]}
            bodyList={
              dataInfo.items
                ? dataInfo.items.map((i, idx) => ({
                    seq: idx,
                    cropName: i.cropName,
                    itemName: i.itemName,
                    unitName: i.unitName,
                    qty: i.qty,
                    price: i.price,
                    amt: i.amt,
                    remark: i.remark,
                    Actions: true,
                  }))
                : []
            }
            dict={dict}
            onClickEdit={(seq) => onClickInfo(seq)}
            onClickDelete={(seq) => onClickDelete(seq)}
          />
        </div>
        <ButtonBox>
          <Button bgColor={"#fff"} hover={"rgba(174, 174, 174, 0.22)"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={() => onClose()}>
            {dict['cancel']}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {dict['save']}
          </Button>
        </ButtonBox>
      </div>
    </Dialog>
  )
}

const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
// const InputBox = styled.div`
//   flex: 1;
// `
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  width: 147px;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

SalesInfoPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default CommonContainer(SalesContainer(withTranslation()(SalesInfoPopup)))
