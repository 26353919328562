import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

// import { Pie, defaults } from "react-chartjs-2"
// import ChartDataLabels from 'chartjs-plugin-datalabels';

import { ResponsivePie } from '@nivo/pie' // https://nivo.rocks/line
import { WidthToDP, isTablet } from "../../../utils/util"

// Disable animating charts by default.
// defaults.global.animation = false;

const pastelToneColor = [ // 파스텔톤
  '#ffafb0', '#caa6fe', '#dfd4e4', '#bee9b4', '#c4f4fe',
  '#aee4ff', '#b5c7ed', '#fcffb0', '#f2cfa5', '#fdfa87',
  '#fcc6f7', '#e2ffaf', '#eeb7b4', '#afffba', '#ffafd8',
  '#fcffb0', '#f2cfa5', '#ffe4af', '#83a7a3', '#acb890',
]

const data = [
  {
    "id": "rust",
    "label": "rust",
    "value": 61,
    "color": "hsl(287, 70%, 50%)"
  },
  {
    "id": "stylus",
    "label": "stylus",
    "value": 578,
    "color": "hsl(79, 70%, 50%)"
  },
  {
    "id": "java",
    "label": "java",
    "value": 70,
    "color": "hsl(85, 70%, 50%)"
  },
  {
    "id": "javascript",
    "label": "javascript",
    "value": 82,
    "color": "hsl(160, 70%, 50%)"
  },
  {
    "id": "python",
    "label": "python",
    "value": 461,
    "color": "hsl(266, 70%, 50%)"
  }
]

const ChartPieBox = (props) => {
  const { style = {}, labels = [], datas = [] } = props

  return (

    <StyledChartBox style={{ ...style }} >
      <ResponsivePie
        data={datas}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        sortByValue={true}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        colors={pastelToneColor}//{ scheme: 'nivo' }} // line 색상 
        borderWidth={1}
        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: 'rgba(255, 255, 255, 0.3)',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    id: 'ruby'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'c'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'go'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'python'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'scala'
                },
                id: 'lines'
            },
            {
                match: {
                    id: 'lisp'
                },
                id: 'lines'
            },
            {
                match: {
                    id: 'elixir'
                },
                id: 'lines'
            },
            {
                match: {
                    id: 'javascript'
                },
                id: 'lines'
            }
        ]}
        legends={[]}
    />
    </StyledChartBox>

  )
}

const checkRender = (prevProps, nextProps) => {

  return false;
  
}

const StyledChartBox = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  margin-top: ${WidthToDP(46)}px;
  background-color: #ffffff;
`

ChartPieBox.propTypes = {
  style: PropTypes.object,
  dataColor: PropTypes.string,
  label: PropTypes.string,
  labels: PropTypes.array.isRequired,
  datas: PropTypes.array.isRequired,
}

export default React.memo( ChartPieBox, checkRender )
