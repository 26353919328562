import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  initProductionPlanList,
  getProductionPlanMonthList,
  getProductionPlanDayList,
  addProductionPlan,
  initProductionPlan,
  getProductionPlan,
  editProductionPlan,
  delProductionPlan,
} = actions.ProductionAction
const { getComboList, setComboWorkCenterList } = actions.SystemSettingAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _productionPlanList: state.ProductionReducer._productionPlanList,
  _productionPlan: state.ProductionReducer._productionPlan,
  _comboWorkCenterList: state.SystemSettingReducer._comboWorkCenterList,
  _comboCropList: state.SystemSettingReducer._comboCropList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  initProductionPlanList: (param) => dispatch(initProductionPlanList(param)),
  getProductionPlanMonthList: (param) => dispatch(getProductionPlanMonthList(param)),
  getProductionPlanDayList: (param) => dispatch(getProductionPlanDayList(param)),
  addProductionPlan: (param) => dispatch(addProductionPlan(param)),
  initProductionPlan: (param) => dispatch(initProductionPlan(param)),
  getProductionPlan: (param) => dispatch(getProductionPlan(param)),
  editProductionPlan: (param) => dispatch(editProductionPlan(param)),
  delProductionPlan: (param) => dispatch(delProductionPlan(param)),
  getComboList: (param) => dispatch(getComboList(param)),
  setComboWorkCenterList: (param) => dispatch(setComboWorkCenterList(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
