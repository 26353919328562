import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  initWorkCenterList,
  getWorkCenterList,
  addWorkCenter,
  initWorkCenter,
  getWorkCenter,
  editWorkCenter,
  delWorkCenter,
  downloadWorkCenterListExcel,
} = actions.DefaultInfoAction
const { getCodeHelpItem } = actions.SystemSettingAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _workCenterList: state.DefaultInfoReducer._workCenterList,
  _workCenter: state.DefaultInfoReducer._workCenter,
  _codeList: state.SystemSettingReducer._codeList,
  _codeHelpItem: state.SystemSettingReducer._codeHelpItem,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  initWorkCenterList: (param) => dispatch(initWorkCenterList(param)),
  getWorkCenterList: (param) => dispatch(getWorkCenterList(param)),
  addWorkCenter: (param) => dispatch(addWorkCenter(param)),
  initWorkCenter: (param) => dispatch(initWorkCenter(param)),
  getWorkCenter: (param) => dispatch(getWorkCenter(param)),
  editWorkCenter: (param) => dispatch(editWorkCenter(param)),
  delWorkCenter: (param) => dispatch(delWorkCenter(param)),
  downloadWorkCenterListExcel: (param) => dispatch(downloadWorkCenterListExcel(param)),

  getCodeHelpItem: (param) => dispatch(getCodeHelpItem(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
