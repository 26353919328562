// 로그인
import React, { useState, useEffect } from "react"
import { navigate, Link } from "gatsby"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { isApp } from "../../utils/util"
import i18n from "../../lang/i18n"

import SignContainer from "../../containers/SignContainer"

import SEO from "../seo"
import InputBox from "../component/common/InputBox"
import ComboBox from "../component/common/ComboBox"

import CircleCheckBox from "../component/common/CircleCheckBox"
import { GreenButton } from "../component/common/Button"
import BasicPopup from "../popup/BasicPopup"
import icon_logo from "images/elefarm.png"

const Login = (props) => {
  const { t, postSignIn } = props

  const [popup, setPopup] = useState({ open: false, text: "" })
  const [id, setId] = useState("")
  const [password, setPassword] = useState("")
  const [check, setCheck] = useState(false)

  const [lang, setLang] = useState(i18n.language || "ko")
  const [languageSeq, setLanguageSeq] = useState(10010002)

  const onSetLang = (languageCode) => {
    setLang(languageCode)
    i18n.changeLanguage(languageCode)
    if (languageCode == "ko") {
      setLanguageSeq(10010002)
    } else if (languageCode == "en") {
      setLanguageSeq(10010001)
    } else if (languageCode == "vi") {
      setLanguageSeq(10010003)
    } else if (languageCode == "zh") {
      setLanguageSeq(10010004)
    } else if (languageCode == "tw") {
      setLanguageSeq(10010005)
    } else if (languageCode == "ja") {
      setLanguageSeq(10010006)
    } 
  }

  useEffect(() => {
    window.NativeLoginDataReceive = async (_data) => {
      loginFunction({
        signType: _data.LoginData.SignType,
        userId: _data.LoginData.ID,
        userPw: _data.LoginData.Password,
        isAutoLogin: _data.LoginData.IsAutoLogin,

        snsToken: "",
        pushToken: _data.LoginData.FCMToken,
        deviceNo: _data.LoginData.DeviceNo,
      })

      localStorage.setItem("deviceNo", _data.LoginData.DeviceNo)

      return "success"
    }

    if (localStorage.getItem("userId")) {
      setId(localStorage.getItem("userId"))
      setCheck(true)
    }
  }, [])

  const loginFunction = async (data) => {
    const result = await postSignIn(data)

    if (result) {
      if (check) localStorage.setItem("userId", id)
      else localStorage.removeItem("userId")

      navigate("/app/main")
    }
  }

  const onClickLogin = async () => {
    if (id === "" || password === "") return setPopup({ open: true, text: t("n.Please check the id or password.") })

    const data = {
      signType: 0,
      userId: id,
      userPw: password,
      isAutoLogin: "1",
      snsToken: "",
      pushToken: "",
      deviceNo: "WEB",
      languageSeq: languageSeq,
      
    }

    if (isApp) {
      window.callNative(
        JSON.stringify({
          Type: "GetFCMToken",
          FunctionName: "NativeLoginDataReceive",
          data: {
            LoginData: {
              ID: data.userId,
              Password: data.userPw,
              SignType: data.signType,
              IsAutoLogin: data.isAutoLogin,
            },
          },
        })
      )
    } else {
      loginFunction(data)
    } // end if
  }

  return (
    <Container>
      <SEO title={t("login")} />
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: "" })} content={popup.text} selectedValue={[t("confirm")]} />

      <LogoSection>
        <img style={{ width: "49px", height: "49px", marginRight: "16px" }} src={icon_logo} />
        <Title>{t("smartFarm")}</Title>
      </LogoSection>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onClickLogin()
        }}
      >
        <InputSection>
          <InputView>
            <InputLabel>{t("id")}</InputLabel>
            <InputBox
              style={{ width: "300px", marginRight: 0 }}
              type={"text"}
              placeholder={t("n.Invalid value. Please try again.", { n: t("id") })}
              value={id}
              onChange={(e) => setId(e.target.value)}
            />
          </InputView>

          <InputView>
            <InputLabel>{t("password")}</InputLabel>
            <InputBox
              style={{ width: "300px", marginRight: 0 }}
              type={"password"}
              placeholder={t("n.Invalid value. Please try again.", { n: t("password") })}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputView>

          <InputView>
            <InputLabel>{t("language")}</InputLabel>
            <ComboBox
              style={{ width: "300px", marginRight: 0 }}
              defaultValue={""}
              hasDefault={false}
              items={[
                { value: "en", label: "English" },
                { value: "ko", label: "한국어" },
                { value: "vi", label: "Tiếng Việt" },
                { value: "zh", label: "简体中文" },
                { value: "tw", label: "繁體中文" },
                { value: "ja", label: "日本語" },
              ]}
              value={lang}
              setValue={onSetLang}
            />
          </InputView>
        </InputSection>

        <BottomSection>
          <CircleCheckBox text={t("rememberId")} check={check} setCheck={setCheck} />

          <div style={{ width: "200px" }}>
            <GreenButton type="submit" style={{ width: "200px", height: "56px" }} onClick={() => onClickLogin()}>
              {t("login")}
            </GreenButton>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "26px" }}>
              <StyledLink to="/app/signup">{t("signUp")}</StyledLink>
              <div style={{ backgroundColor: "#dbdbdb", width: "1px", height: "21px" }} />
              <StyledLink to="/app/changePw">{t("resetPassword")}</StyledLink>
            </div>
          </div>
        </BottomSection>
      </form>
    </Container>
  )
}

const Container = styled.div`
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
const LogoSection = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 528px;
`
const Title = styled.p`
  color: #555555;
  font-size: 20px;
  font-weight: 500;
  line-height: -0.45px;
`
const InputSection = styled.section`
  background-color: #fff;
  margin-top: 16px;
  padding: 55px 60px 55px 56px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const InputView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
`
const InputLabel = styled.p`
  opacity: 80%;
  width: 90px;
  margin-bottom: 5px;
  margin-right: 22px;
  color: #555555;
  font-size: 16px;
  line-height: -0.45px;
`
const BottomSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 528px;
  margin-top: 26px;
`
const StyledLink = styled(Link)`
  cursor: pointer;
  opacity: 80%;
  color: #555555;
  font-size: 14px;
  text-decoration: none;
`

export default SignContainer(withTranslation()(Login))
