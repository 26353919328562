import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const { initItemList, getItemList, addItem, initItem, getItem, editItem, delItem, downloadItemListExcel } = actions.DefaultInfoAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _itemList: state.DefaultInfoReducer._itemList,
  _item: state.DefaultInfoReducer._item,
  _codeList: state.SystemSettingReducer._codeList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  initItemList: (param) => dispatch(initItemList(param)),
  getItemList: (param) => dispatch(getItemList(param)),
  addItem: (param) => dispatch(addItem(param)),
  initItem: (param) => dispatch(initItem(param)),
  getItem: (param) => dispatch(getItem(param)),
  editItem: (param) => dispatch(editItem(param)),
  delItem: (param) => dispatch(delItem(param)),
  downloadItemListExcel: (param) => dispatch(downloadItemListExcel(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
