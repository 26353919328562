import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const { setCropPlanList, getCropPlanList, setCostPlanList, getCostPlanList, addCostPlan, editCostPlan, delCostPlan } = actions.PurchaseSpendAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _cropPlanList: state.PurchaseSpendReducer._cropPlanList,
  _costPlanList: state.PurchaseSpendReducer._costPlanList,
  _codeList: state.SystemSettingReducer._codeList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  setCropPlanList: (param) => dispatch(setCropPlanList(param)),
  getCropPlanList: (param) => dispatch(getCropPlanList(param)),
  setCostPlanList: (param) => dispatch(setCostPlanList(param)),
  getCostPlanList: (param) => dispatch(getCostPlanList(param)),
  addCostPlan: (param) => dispatch(addCostPlan(param)),
  editCostPlan: (param) => dispatch(editCostPlan(param)),
  delCostPlan: (param) => dispatch(delCostPlan(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
