// 재배일지 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes, { object } from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import moment from "moment"
import imageApis from "../../../apis/ImageApis"

import CommonContainer from "../../../containers/CommonContainer"
import CultivationJournalContainer from "../../../containers/cropManage/CultivationJournalContainer"

import BasicPopup from "../../popup/BasicPopup"
import RequiredMark from "../../component/RequiredMark"
import InputBox from "../../component/common/InputBox"
import DateBox from "../../component/common/DateBox"
import ComboBox from "../../component/common/ComboBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import AttchImageBox from "../../component/common/AttchImageBox"
import MultiComboBox from "../../component/common/MultiComboBox"
import CodeHelpBox from "../../component/common/CodeHelpBox"

import CircularIndeterminate from "../../component/common/CircularIndeterminate" //로딩화면

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
      maxWidth: "960px",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const CultivationJournalAddPopup = (props) => {
  const classes = useStyles()

  const {
    t,
    setDataList,
    getComboList,

    sminorComboList,
    uminorComboList,
    workCenterComboList,
    cropComboList,
    cropGrowthComboList,

    staffComboList,

    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,

    isRowClick,
  } = props

  const { addCropReport, editCropReport, infoItem } = props

  const { onClose, open } = props

  const TODAY = moment()
  const [regDate, setRegDate] = useState(moment(TODAY).format("YYYYMMDD"))
  const [cropName, setCropName] = useState("")
  const [cropCenter, setCropCenter] = useState(0)
  const [cropGrow, setCropGrow] = useState(0)

  const [cropAct, setCropAct] = useState(0)
  const [cropEvent, setCropEvent] = useState(0)

  const [cropEventMajorSeq, setCropEventMajorSeq] = useState(0)

  const [importWork, setImportWork] = useState("")
  const [cropQty, setCropQty] = useState("")
  const [cropRemark, setCropRemark] = useState("")
  const [attachImages, setAttachImages] = useState([])
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [staffList, setStaffList] = useState([])
  const [useTime, setUseTime] = useState(0)
  const dict = _translationWords[0] // 변수에 사전 담기

  const [loading, setLoading] = useState(false) //사진업로드 중에 로딩화면

  useEffect(() => {
    
    let hiddenTarget = cropGrowthComboList.filter((i) => (i.cropGrowthSeq == cropGrow))
    let isHarv = '0'
    
    if (hiddenTarget.length > 0) {
      const obj = JSON.stringify(hiddenTarget[0])
      const jsonObj = JSON.parse(obj)
      isHarv = jsonObj['isHarv']

      if (isHarv == '0') {
        document.getElementById("cropQty").style.display = 'none'
        setCropQty(0)
       
      } else {
        document.getElementById("cropQty").style.display = 'flex'
    
      }
    }
  }, [cropGrow])

  useEffect(() => {
    //setStaffList([]) - 주석처리 차건담 2023.08.07
    setUseTime(0)
  }, [onClose])

  const onClickSave = async () => {
    if (checkRequiredField()) {
      let images = []
      if (attachImages.length > 0) {
        const formData = new FormData()

        for (let i = 0; i < attachImages.length; i++) {
          if (attachImages[i].file) {
            formData.append("images", attachImages[i].file)
          } else {
            images.push({ fileUrl: attachImages[i].imageUrl, fileName: attachImages[i].fileName })
          }
        } // end for

        const uploadsResult = await imageApis.uploadImages(formData)
        images.push(...uploadsResult.data.images)
      }
      let _staffList = staffList.map( (i) => i.value )
      
      let data = {
        cropReportSeq: 0,
        cropReportDate: moment(regDate).format("YYYYMMDD"),
        cropSeq: cropName['value'],
        workCenterSeq: cropCenter,
        cropGrowthSeq: cropGrow,
        cropActionSeq: cropAct,
        cropEventSeq: cropEvent,
        cropReportQty: cropQty == "" ? 0 : cropQty,
        work: importWork,
        imageUris: images.length > 0 ? images.map((i) => ({ fileUri: i.fileUrl, fileName: i.fileName })) : [],
        remark: cropRemark,
        staffList: _staffList,
        useTime: useTime
      }

      let result = false
      if (infoItem) {
        data.cropReportSeq = infoItem.cropReportSeq
        result = await editCropReport(data)
        // if (result) return setPopup({ open: true, text: t("the requested modifications have been completed.") })
      } else {
        result = await addCropReport(data)
        // if (result) return setPopup({ open: true, text: t("saved successfully.") })
      }
      if (result) {setLoading(false),onClose()}
      // 업로드중 로딩화면 종료
    } // end if
  }

  // verify popup - 필수입력 조건 확인
  const checkRequiredField = () => {
    let msg = ""
 
    if (cropName['value'] <= 0) {
      msg = replaceWord(dict['n.Please select a product.'], dict['cropName']) // 작기명
    } else if (workCenterComboList.length > 0 && cropCenter <= 0) { //작업장
      msg = replaceWord(dict['n.Please select a product.'], dict['workCenter'])
    } else if (cropGrowthComboList.length > 0 && cropGrow <= 0) { //생육단계
      msg = replaceWord(dict['n.Please select a product.'], dict['growthStep'])
    } else if (cropAct <= 0) { //재배활동
      msg = replaceWord(dict['n.Please select a product.'], dict['cropAction'])
    } else if (cropEvent <= 0) { //재배이벤트
      msg = replaceWord(dict['n.Please select a product.'], dict['cropEvent'])
    } else if (importWork <= 0) { //주요작업
      msg = replaceWord(dict["n.Invalid value. Please try again."], dict['importWork'])
    } else if (cropComboList.filter((i) =>i.cropSeq == cropName['value']).map((i) =>i.cropDateFr)[0] > regDate || regDate > cropComboList.filter((i) =>i.cropSeq == cropName['value']).map((i) =>i.cropDateTo)[0]) { // 등록일
      
      let cropDateFr = cropComboList.filter((i) =>i.cropSeq == cropName['value']).map((i) =>i.cropDateFr)[0]; //작기 시작일
      let cropDateTo = cropComboList.filter((i) =>i.cropSeq == cropName['value']).map((i) =>i.cropDateTo)[0]; //작기 종료일
    
      msg = replaceWord(dict["n.Please check the contents."], dict['crop']+dict['days']) + "   " + cropDateFr + "~" + cropDateTo // 작기시작일 작기종료일 사이날짜에만 등록가능     

    }
    
    if (msg > "") {
      setPopup({ open: true, text: msg })
    } else {
      setLoading(true) // 로딩화면 시작
      return true
    }
  }

  const getNewData = async () => {
    if (infoItem.cropSeq == 0) {
      await setDataList({ workCenterComboList: [] })
      await setDataList({ cropGrowthComboList: [] })
      await setDataList({})

      //setStaffList([])
      setUseTime(0)
    } else {
      await getComboList({ queryKind: "WorkCenter", cropSeq: infoItem.cropSeq })
      await getComboList({ queryKind: "CropGrowth", cropSeq: infoItem.cropSeq })
    } // end if

    await getComboList({ queryKind: "SMinor" })
    await getComboList({ queryKind: "UMinor" })
    await getComboList({ queryKind: "Staff" })

    const itemLength = Object.keys(infoItem).length

    setRegDate(itemLength > 0 ? infoItem.cropReportDate : moment(TODAY).format("YYYYMMDD"))
    setCropName(itemLength > 0 ? {label:infoItem.cropName, value:infoItem.cropSeq} : 0)
    setCropCenter(itemLength > 0 ? infoItem.workCenterSeq : 0)
    setCropGrow(itemLength > 0 ? infoItem.cropGrowthSeq : 0)

    setCropAct(itemLength > 0 ? infoItem.cropActionSeq : 0)

    onSetCropEventMajorSeq(infoItem.cropActionSeq)

    setCropEvent(itemLength > 0 ? infoItem.cropEventSeq : 0)
    setCropQty(itemLength > 0 ? (infoItem.cropReportQty > 0 ? infoItem.cropReportQty : "") : "")
    setImportWork(itemLength > 0 ? infoItem.work : "")
    setCropRemark(itemLength > 0 ? infoItem.remark : "")

    setAttachImages(
      itemLength > 0 && infoItem.imageUri.length > 0 ? infoItem.imageUri.map((i) => ({ imageUrl: i.fileUri, fileName: i.fileName })) : []
    )
    
    const staffListData = itemLength > 0 && infoItem.staffList.length > 0 ? infoItem.staffList.map((i) => ({ value: i.staffSeq, label: i.staffName })) : []
    setStaffList(staffListData)
    
    setUseTime(itemLength > 0 ? infoItem.useTime : 0)
  }

  useEffect(() => {
    getTranslationWords()
    if (open) 
    setCropGrow(0)
    getNewData()

    // console.log('infoItem>>>>>>>>>>>>>>>>>>>')
    // console.log(infoItem)
    // console.log('cropComboList>>>>>>>>>>>>>>>')
    // console.log(cropComboList.filter((i) =>i.cropSeq == infoItem.cropSeq).map((i) =>i.cropDateFr)[0])
    // console.log(cropComboList.filter((i) =>i.cropSeq == infoItem.cropSeq).map((i) =>i.cropDateTo)[0])
    
    return async () => {
      setCropName("")
    }
  }, [open])

  // const selected = cropComboList.find((i) => i.)

  useEffect(() => {
    console.log("useEffect [cropName] 진입 > cropName", cropName)
    onSelectCropName(cropName)
  }, [cropName])

  const onSelectCropName = async (param) => {
    console.log("onSelectCropName 함수 진입 => param:", param)
    const value = param == "" ? 0 : param['value']

    setCropCenter(0)
    setCropGrow(0)

    if (value == 0) {
      await setDataList({ workCenterComboList: [] })
      await setDataList({ cropGrowthComboList: [] })
    } else {
      await getComboList({ queryKind: "WorkCenter", cropSeq: value })
      console.log("workCenterComboList : ", workCenterComboList)
      console.log("infoItem.workCenterSeq : ", infoItem.workCenterSeq)
      setCropCenter(
        workCenterComboList.find((i)=>i.workCenterSeq == infoItem.workCenterSeq) != null ||
        workCenterComboList.find((i)=>i.workCenterSeq == infoItem.workCenterSeq) != undefined
        ? workCenterComboList.find((i)=>i.workCenterSeq == infoItem.workCenterSeq).workCenterSeq : 0
      )
      await getComboList({ queryKind: "CropGrowth", cropSeq: value })
    } // end if
  }

  const onSelectCropAction = (value) => {
    setCropAct(value)
    setCropEvent(0)

    onSetCropEventMajorSeq(value)
  }

  const onSetCropEventMajorSeq = (cropAct) => {
    setCropEventMajorSeq(cropAct == 10120001 ? 1011 : cropAct == 10120002 ? 1012 : cropAct == 10120003 ? 1010 : 0)
  }

  return ( // 행 클릭했을 경우 정보만 보여주고 수정이 안되게 하기 위해 isRowClick 변수를 받아 display 및 disabled 속성 설정 - 차건담 2023.04.12
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose(null)}
      open={open}
      fullWidth={true}
    >
      <CircularIndeterminate open={loading} /> 
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: popup.text })} content={popup.text} selectedValue={[dict['confirm']]} />
      <div
        style={{
          backgroundColor: "#fff",
          // overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "23px 51px 44px 40px",
        }}
      >
        <Title>{dict['cropReportReg']}</Title>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
          <InputBoxDiv>
            <FlexBox>
              <Label>
                <RequiredMark /> {dict['regDate']}
              </Label>
              <DateBox style={{zIndex: 99}} stdDate={regDate} setStdDate={setRegDate} disabled={isRowClick==1}/>
            </FlexBox>
            <FlexBox>
              <Label>
                <RequiredMark /> {dict['cropName']}
              </Label>
              <CodeHelpBox
                style={{}}
                placeholder={dict['cropName']}
                codeHelpSeq={3}
                // codeHelpParam={{}}
                value={cropName}
                setValue={setCropName}
              />
              {/* <ComboBox
                style={{}}
                defaultValue={dict['select']}
                hasDefault={true}
                items={cropComboList.map((i) => ({ value: i.cropSeq, label: i.cropName }))}
                value={cropName}
                setValue={onSelectCropName}
                disabled={isRowClick==1}
              /> */}
            </FlexBox>
            <FlexBox>
              <Label>
                <RequiredMark /> {dict['workCenter']}
              </Label>
              <ComboBox
                style={{}}
                defaultValue={dict['select']}
                hasDefault={true}
                items={workCenterComboList.map((i) => ({ value: i.workCenterSeq, label: i.workCenterName }))}
                value={cropCenter}
                setValue={setCropCenter}
                disabled={isRowClick==1}
              />
            </FlexBox>
            
            <FlexBox>
              <Label>
                {dict['cultivationManpower']}
              </Label>
              <MultiComboBox
                style={{width : '220px', zIndex: 90 }}
                placeholder={dict['multiSelect']}
                items={staffComboList.map((i) => ({ value: i.staffSeq, label: i.staffName }))}
                value={staffList}
                setValue={setStaffList}
                disabled={isRowClick==1}
              />
            </FlexBox>
            <FlexBox>
              <Label>
                {dict['manpower']+dict['usageTime']}
              </Label>
              <InputBox type="float" value={useTime} onChange={(e) => setUseTime(e.target.value)} disabled={isRowClick==1}/>
            </FlexBox>
          </InputBoxDiv>

          <InputBoxDiv style={{ flex: "none", marginTop: "54px" }}>
            <FlexBox>
              <Label>
                <RequiredMark /> {dict['growthStep']}
              </Label>
              <ComboBox
                style={{}}
                defaultValue={dict['select']}
                hasDefault={true}
                items={cropGrowthComboList.map((i) => ({ value: i.cropGrowthSeq, label: i.work }))}
                value={cropGrow}
                setValue={setCropGrow}
                disabled={isRowClick==1}
              />
            </FlexBox>
            <FlexBox>
              <Label>
                <RequiredMark /> {dict['cropAction']}
              </Label>
              <ComboBox
                style={{}}
                defaultValue={dict['select']}
                hasDefault={true}
                items={sminorComboList.filter((row) => row.majorSeq == "1012").map((i) => ({ value: i.minorSeq, label: i.minorName }))}
                value={cropAct}
                setValue={onSelectCropAction}
                disabled={isRowClick==1}
              />
            </FlexBox>
            <FlexBox>
              <Label>
                <RequiredMark /> {dict['cropEvent']}
              </Label>
              <ComboBox
                style={{}}
                defaultValue={dict['select']}
                hasDefault={true}
                items={uminorComboList.filter((row) => row.majorSeq == cropEventMajorSeq).map((i) => ({ value: i.minorSeq, label: i.minorName }))}
                value={cropEvent}
                setValue={setCropEvent}
                disabled={isRowClick==1}
              />
            </FlexBox>
            <FlexBox id="cropQty" style={{display:'none'}}>
              <Label>{dict['quantity']}</Label>
              <InputBox style={{}} type="number" disabled={isRowClick==1} value={cropQty} onChange={(e) => setCropQty(e.target.value)} />
            </FlexBox>
          </InputBoxDiv>
        </div>
        <FlexBox alignItems="flex-start">
          <Label>
            <RequiredMark /> {dict['importWork']}
          </Label>
          <TextAreaBox width={0} height={120} value={importWork} setValue={setImportWork} disabled={isRowClick==1}/>
        </FlexBox>
        <FlexBox alignItems="flex-start">
          <Label>{dict['remark']}</Label>
          <TextAreaBox width={0} height={120} value={cropRemark} setValue={setCropRemark} disabled={isRowClick==1}/>
        </FlexBox>
        <FlexView alignItems="flex-start">
          <Label>{dict['uplaodImage']}</Label>
          <ImgArea>
            <AttchImageBox style={{ width: "100%", overflowX: "auto" }}
              images={attachImages}
              setImages={setAttachImages}
              maxCnt={50} disabled={isRowClick}
              infoItem={infoItem} // AttachImageBox 컴포넌트에 infoItem 전달 - 차건담 2023.04.13
            />
          </ImgArea>
        </FlexView>

        <ButtonBox>
          <Button
            bgColor={"#fff"}
            hover={"rgba(174, 174, 174, 0.22)"}
            border={"1px solid #c6c6c6"}
            color={"#555555cc"}
            onClick={() => onClose(dict['cancel'], null)}
            style={{display:isRowClick==1?"none":"block"}}
          >
            {dict['cancel']}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave} style={{display:isRowClick==1?"none":"block"}}>
            {dict['save']}
          </Button>
        </ButtonBox>
      </div>
    </Dialog>
  )
}

const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const InputBoxDiv = styled.div`
  flex: 1;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
`

const FlexView = styled.div`
  display: flex;
  /* overflow-x: hidden; */
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ImgArea = styled.div`
  flex: 1;
  overflow-x: auto;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  width: 147px;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

CultivationJournalAddPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default CommonContainer(CultivationJournalContainer(withTranslation()(CultivationJournalAddPopup)))
