// 알림 팝업
import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Dialog } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import alert from "images/alert.png"

const useStyles = makeStyles((theme) => ({
    container: {
        "& .MuiDialog-paperFullWidth": {
            width: "400px",
            margin: "25px",
            borderRadius: "9px",
        },

        "&.backdrop": {
            "& .MuiBackdrop-root": {
                backgroundColor: "#00000000",
            },
        },
    },
}))

const AlertPopup = (props) => {
    const classes = useStyles()
    const { bg = true, open, onClose, content } = props

    return (
        <Dialog className={`${classes.container} ${!bg ? "backdrop" : ""}`} open={open} onClose={onClose} fullWidth={true}>
            <Container>
                <img style={{ width: "77px", height: "69px" }} src={alert} alt="느낌표" />
                <ContentView>
                    <Content>{content}</Content>
                </ContentView>

                <ButtonView>
                    <Button onClick={onClose}>OK</Button>
                </ButtonView>
            </Container>
        </Dialog>
    )
}

AlertPopup.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    content: PropTypes.string.isRequired,
}

export default AlertPopup

const Container = styled.div`
    background-color: #ffe9e9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 37px 80px 32px;
    border: 1px solid #ffd1d1;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
    z-index: 100;
`

const ContentView = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 9px;
    margin-bottom: 21px;
`

const Content = styled.p`
    color: #555555;
    font-size: 15px;
    text-align: center;
    white-space: pre-line;
`

const ButtonView = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
`
const Button = styled.button`
    background-color: inherit;
    width: 240px;
    height: 40px;
    border: 1px solid #555555;
    border-radius: 3px;
    color: #555555;
    font-size: 14px;

    &:nth-child(2) {
        margin-left: 14px;
    }
`
