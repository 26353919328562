// 코드도움 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Dialog } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import CommonContainer from "../../../containers/CommonContainer"

import { TableComponent } from "./Table"
import { YellowButton, GrayButton } from "./Button"

const useStyles = makeStyles((theme) => ({
  container: {
    "& .MuiDialog-paperFullWidth": {
      width: "500px",
      margin: "25px",
      borderRadius: "9px",
    },
    "&.backdrop": {
      "& .MuiBackdrop-root": {
        backgroundColor: "#00000000",
      },
    },
  },
}))

const CodeHelpCropPopup = (props) => {

  const classes = useStyles()

  const { 
    
    setDataList,
    getCropCodeHelpList : getDataList, cropCodeHelpList : dataList,
    codeHelpParam, placeholder, 
    value, setValue, 
    queryWord, setQueryWord,
    open, onClose 
    
  } = props

  const { bg = false } = props

  const { initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props

  useEffect(() => {
    getTranslationWords()

    if ( open && queryWord > "" ) onClickSearch();

    return () => { setDataList( { cropCodeHelpList : [] } ); }

  }, [open])

  const dict = _translationWords[0]

  const onClickSearch = async () => {

    // console.log(dict)

    await getDataList({ queryWord, ...codeHelpParam })
    
  }

  const onKeyDownSearch = ( e ) => { // 코드도움 input enter 키 클릭시 호출 

    if( e.keyCode == 13 ){ // enter key 
      
      onClickSearch()

    } // end if 

  }

  const onClickItem = async ( row ) => {

    setValue( { value : row.seq, label : row.dataName } )

    onClose()
    
  }

  return (
    <Dialog className={`${classes.container} ${!bg ? "backdrop" : ""}`} open={open} onClose={onClose} fullWidth={true}>
      <Container>
        <SearchBox>
          <input style={{ flex: 1, height: "40px" }} 
            placeholder={placeholder}
            value={queryWord} 
            
            onChange={(e) => setQueryWord(e.target.value)} 
            onKeyDown={(e) => onKeyDownSearch( e )}
          
          />
          <div>
            <YellowButton style={{ width: "90px", marginLeft: "13px" }} onClick={()=>onClickSearch()}>{dict['select']}</YellowButton>
            <GrayButton style={{ width: "90px", marginLeft: "13px" }} onClick={()=>onClose()}>{dict['cancel']}</GrayButton>
          </div>
        </SearchBox>
        <TableComponent style={{marginTop: '14px'}}
          headList={[
            { title: dict['cropName'], type: "text" },
          ]}
          bodyList={dataList.map((i) => ({
            seq: i.cropSeq,
            dataName: i.cropName,
          }))}
          dict={dict}
          onClick={(row) => onClickItem(row)}
        />
      </Container>
    </Dialog>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 500px;
  padding: 25px 33px;
`

const SearchBox = styled.div`
  display: flex;
  width: 100%;
`

CodeHelpCropPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CommonContainer(CodeHelpCropPopup)
