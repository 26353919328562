// 기본정보 > 설정정보 > 생산설비 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import { dateFormat } from "../../../utils/util"
import ProductionLineContainer from "../../../containers/defaultInfo/ProductionLineContainer"
import RequiredMark from "../../component/RequiredMark"
import TableCheckBox from "../../component/common/TableCheckBox"
import MultiComboBox from "../../component/common/MultiComboBox"
import ComboBox from "../../component/common/ComboBox"
import DateBox from "../../component/common/DateBox"
import InputBox from "../../component/common/InputBox"
import InputTelBox from "../../component/common/InputTelBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import AlertPopup from "../AlertPopup"

import CommonContainer from "../../../containers/CommonContainer" // 다국어 사전 가져오기 위해 import - 차건담 2023.03.02

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        margin: "0 auto",
        "& .MuiDialog-paperFullWidth": {
            width: "100%",
        },
        "& .MuiPaper-root": {
            backgroundColor: "#fff",
            maxWidth: "960px",
        },
        "& .MuiDialog-paper": {
            // margin: "0px",
        },
    },
}))

const ProductionLineAddPopup = (props) => {
    const classes = useStyles()
    const { t, _codeList, _comboWorkCenterList, _comboControlNodeList, _productionLine } = props
    const { onClose, open, verData1 } = props
    const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
    const [equipName, setEquipName] = useState("")
    const [equipNo, setEquipNo] = useState("")
    const [workKind, setWorkKind] = useState(0)
    const [controlKind, setControlKind] = useState(0)
    const [equipVolt, setEquipVolt] = useState(0)
    const [costPover, setCostPover] = useState("")
    const [workTime, setWorkTime] = useState("")
    const [workTimeR, setWorkTimeR] = useState("")
    // const [isControl, setIsControl] = useState("")
    const [controlNodeSeq, setControlNodeSeq] = useState(0)
    const [relayNoFw, setRelayNoFw] = useState(0)
    const [relayNoBk, setRelayNoBk] = useState(0)
    const [relayNoSt, setRelayNoSt] = useState(0)
    const [workCenterSeq, setWorkCenterSeq] = useState(0)
    const [areaNos, setAreaNos] = useState([])
    const [isUse, setIsUse] = useState(true)
    const [remark, setRemark] = useState("")

    const [isNotUseManual, setIsNotUseManual] = useState(false)
    const [isNotUseOpenPer, setIsNotUseOpenPer] = useState(false)
    const [isUseStControl, setIsUseStControl] = useState(false)

    const [provider, setProvider] = useState("")
    const [maker, setMaker] = useState("")
    const [asTeleNos, setAsTeleNos] = useState("")
    const [settingDate, setSettingDate] = useState("")
    const [useLife, setUseLife] = useState("")

    // 사전 관련 객체 가져오기 - 차건담 2023.03.02
    const { initTranslationWords, getTranslationWords, _translationWords, replaceWord } = props

    useEffect(() => {
        getTranslationWords() // 사전 가져오는 함수 - 차건담 2023.03.02
        if (open && Object.keys(_productionLine).length != 0) {
            setEquipName(_productionLine.equipName)
            setEquipNo(_productionLine.equipNo)
            setWorkKind(_productionLine.workKind || 0)
            setControlKind(_productionLine.controlKind || 0)
            setEquipVolt(_productionLine.equipVolt || 0)
            setCostPover(_productionLine.costPover)
            setWorkTime(_productionLine.workTime || "")
            setWorkTimeR(_productionLine.workTimeR || "")
            // setIsControl(_productionLine.isControl == 1)
            if (_comboControlNodeList.find((i) => i.controlNodeSeq == _productionLine.controlNodeSeq) != undefined) {
                setControlNodeSeq(_productionLine.controlNodeSeq || 0)
                setRelayNoFw(_productionLine.relayNoFw || 0)
                setRelayNoBk(_productionLine.relayNoBk || 0)
                setRelayNoSt(_productionLine.relayNoSt || 0)
            }
            if (_comboWorkCenterList.find((i) => i.workCenterSeq == _productionLine.workCenterSeq) != undefined) {
                setWorkCenterSeq(_productionLine.workCenterSeq || 0)
                setAreaNos(_productionLine.areaNos ? _productionLine.areaNos.split(",").map((i) => ({ value: Number(i), label: Number(i) })) : [])
            }
            setIsUse(_productionLine.isUse == 1)
            setRemark(_productionLine.remark)

            setProvider(_productionLine.provider)
            setMaker(_productionLine.maker)
            setAsTeleNos(_productionLine.asTeleNos)
            setSettingDate(_productionLine.settingDate ? dateFormat(_productionLine.settingDate, "YYYY-MM-DD") : "")
            setUseLife(_productionLine.useLife)

            setIsNotUseManual(_productionLine.isNotUseManual == 1)
            setIsNotUseOpenPer(_productionLine.isNotUseOpenPer == 1)
            setIsUseStControl(_productionLine.isUseStControl == 1)
        }

        return () => {
            setEquipName("")
            setEquipNo("")
            setWorkKind(0)
            setControlKind(0)
            setEquipVolt(0)
            setCostPover("")
            setWorkTime("")
            setWorkTimeR("")
            // setIsControl(false)
            setControlNodeSeq(0)
            setRelayNoFw(0)
            setRelayNoBk(0)
            setRelayNoSt(0)
            setWorkCenterSeq(0)
            setAreaNos([])
            setIsUse(true)
            setRemark("")

            setProvider("")
            setMaker("")
            setAsTeleNos("")
            setSettingDate("")
            setUseLife("")

            setIsNotUseManual(false)
            setIsNotUseOpenPer(false)
            setIsUseStControl(false)
        }
    }, [open])

    let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.03.02

    const dictCk = (word) => {
        if (dict == undefined || dict == null || dict == {} || dict == "") {
            return word
        } else {
            const result = dict[word] != undefined && dict[word] != null && dict[word] != "" ? dict[word] : word
            return result
        }
    }

    //릴레이 기사용값 제거 후 콤보리스트
    const verDataCombo = (data) => {
        const verData1Filter = verData1.filter(
            (i) =>
                i.equipNo != equipNo &&
                i.controlNodeSeq == controlNodeSeq &&
                (i.relayNoBk != 0 || i.relayNoBk != "" || i.relayNoFw != 0 || i.relayNoFw != "" || i.relayNoSt != 0 || i.relayNoSt != "")
        )
        let verArr = []
        for (let i = 0; i < verData1Filter.length; i++) {
            if (verData1Filter[i].relayNoFw > 0) {
                verArr.push(verData1Filter[i].relayNoFw)
            }
            if (verData1Filter[i].relayNoBk > 0) {
                verArr.push(verData1Filter[i].relayNoBk)
            }
            if (verData1Filter[i].relayNoSt > 0) {
                verArr.push(verData1Filter[i].relayNoSt)
            }
        }
        // console.log("verArrFW", verArr)

        let comboData = data.filter((i) => !verArr.includes(i.value))
        return comboData
    }

    // 정지모드 체크시 제어구분값 수동으로 set
    const setIsUseStControlCheck = async (value) => {
        setControlKind(10200002) // 제어구분 수동
        setIsUseStControl(value) // 정지모드 체크여부
    }

    // console.log("@@@", verDataCombo())

    const onClickSave = async () => {
        if (equipName == "")
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("equipName")) })
        else if (equipNo == "")
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("equipNo")) })
        else if (workKind == 0) return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Please select a product."), dictCk("workKind")) })
        else if (controlKind == 0)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Please select a product."), dictCk("controlKind")) })
        else if (workTime == 0 && isNotUseOpenPer == false)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("workTime")) })
        else if (workKind == 10180002 && workTimeR == 0 && isNotUseOpenPer == false)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("workTimeR")) })
        else if (controlNodeSeq == 0)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("controlNodeName")) })
        else if (workKind == 10180002 && relayNoFw == 0)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Please select a product."), dictCk("relayFor")) })
        else if (workKind == 10180002 && relayNoBk == 0)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Please select a product."), dictCk("relayRev")) })
        else if (workKind == 10180002 && isUseStControl == true && relayNoSt == 0)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Please select a product."), dictCk("Relay번호(역)")) })
        // 릴레이(정/역/정지)중복확인
        else if (workKind == 10180002 && (relayNoFw == relayNoBk || relayNoFw == relayNoSt || relayNoBk == relayNoSt))
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Values cannot be duplicated"), dictCk("relayNo")) })
        else if (workKind == 10180001 && relayNoFw == 0)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Please select a product."), dictCk("relayNo")) })
        else if (workCenterSeq == 0)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Please select a product."), dictCk("workCenter")) })
        else if (areaNos.length == 0)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Please select a product."), dictCk("workCenterArea")) })

        const param = {
            equipName,
            equipNo,
            workKind: workKind == 0 ? 0 : workKind,
            controlKind: controlKind == 0 ? 0 : controlKind,
            equipVolt: equipVolt == 0 ? 0 : equipVolt,
            costPover: costPover || 0,
            workTime: isNotUseOpenPer ? 0 : workTime || 0,
            workTimeR: workKind == 10180001 ? 0 : isNotUseOpenPer ? 0 : workTimeR,
            // isControl: isControl ? 1 : 0,
            controlNodeSeq: controlNodeSeq == 0 ? "" : controlNodeSeq,
            relayNoFw,
            relayNoBk: workKind == 10180001 ? relayNoFw : relayNoBk,
            relayNoSt: workKind == 10180002 && isUseStControl ? relayNoSt : 0,
            workCenterSeq: workCenterSeq == 0 ? 0 : workCenterSeq,
            areaNos: areaNos.map((i) => i.value),
            isUse: isUse ? 1 : 0,
            remark,
            provider,
            maker,
            asTeleNos,
            settingDate: settingDate.replaceAll("-", ""),
            useLife: useLife || 0,

            isNotUseManual: isNotUseManual ? 1 : 0,
            isNotUseOpenPer: isNotUseOpenPer ? 1 : 0,
            isUseStControl: isUseStControl ? 1 : 0,
        }
        if (_productionLine) param.equipSeq = _productionLine.equipSeq

        onClose(dictCk("save"), param)
    }

    return (
        <Dialog
            className={classes.container}
            style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
            onClose={() => onClose(null)}
            open={open}
            fullWidth={true}
        >
            <AlertPopup
                bg={false}
                open={alertPopup.open}
                onClose={() => setAlertPopup({ open: false, text: alertPopup.text })}
                content={alertPopup.text}
            />

            <div
                style={{
                    backgroundColor: "#fff",
                    overflowY: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    padding: "23px 51px 44px 40px",
                }}
            >
                <Title>{dictCk("equip")}</Title>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                    <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                        <FlexBox>
                            <Label>
                                <RequiredMark /> {dictCk("equipName")}
                            </Label>
                            <InputBox type="text" value={equipName} onChange={(e) => setEquipName(e.target.value)} />
                        </FlexBox>
                        <FlexBox>
                            <Label>
                                <RequiredMark /> {dictCk("equipNo")}
                            </Label>
                            <InputBox type="text" value={equipNo} onChange={(e) => setEquipNo(e.target.value)} />
                        </FlexBox>
                        <FlexBox>
                            <Label>
                                <RequiredMark /> {dictCk("workKind")}
                            </Label>
                            <ComboBox
                                style={{ width: "220px" }}
                                defaultValue={dictCk("select")}
                                hasDefault={true}
                                items={
                                    _codeList.length > 0 &&
                                    _codeList.filter((i) => i.majorKind == 1 && i.majorSeq == 1018).length > 0 &&
                                    _codeList
                                        .filter((i) => i.majorKind == 1 && i.majorSeq == 1018)[0]
                                        .minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                                }
                                value={workKind}
                                setValue={(value) => {
                                    setWorkKind(value)
                                    setRelayNoBk(0)
                                    setRelayNoSt(0)
                                }}
                            />
                        </FlexBox>
                        <FlexBox>
                            <Label>
                                <RequiredMark /> {dictCk("controlKind")}
                            </Label>
                            <ComboBox
                                style={{ width: "220px" }}
                                defaultValue={dictCk("select")}
                                hasDefault={true}
                                items={
                                    _codeList.length > 0 &&
                                    _codeList.filter((i) => i.majorKind == 1 && i.majorSeq == 1020).length > 0 &&
                                    _codeList
                                        .filter((i) => i.majorKind == 1 && i.majorSeq == 1020)[0]
                                        .minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                                }
                                disabled={isUseStControl} // 정지모드 체크시 dis처리
                                value={controlKind}
                                setValue={setControlKind}
                            />
                        </FlexBox>
                        <FlexBox>
                            <Label>{dictCk("equipVolt")}</Label>
                            <ComboBox
                                style={{ width: "220px" }}
                                defaultValue={dictCk("select")}
                                hasDefault={true}
                                items={
                                    _codeList.length > 0 &&
                                    _codeList.filter((i) => i.majorKind == 1 && i.majorSeq == 1017).length > 0 &&
                                    _codeList
                                        .filter((i) => i.majorKind == 1 && i.majorSeq == 1017)[0]
                                        .minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                                }
                                value={equipVolt}
                                setValue={setEquipVolt}
                            />
                        </FlexBox>
                        <FlexBox>
                            <Label>{dictCk("costPover")}</Label>
                            <InputBox
                                style={{ width: "220px", marginRight: 0 }}
                                type="number"
                                value={costPover}
                                onChange={(e) => setCostPover(e.target.value)}
                            />
                        </FlexBox>
                        {/* <FlexBox>
              <Label>스마트팜제어대상</Label>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                <TableCheckBox check={isControl} setCheck={setIsControl} />
              </div>
            </FlexBox> */}

                        {/* 작업장,구역 시작 */}

                        <FlexBox>
                            <Label>
                                <RequiredMark /> {dictCk("workCenter")}
                            </Label>
                            <ComboBox
                                style={{ width: "300px" }}
                                defaultValue={dictCk("select")}
                                hasDefault={true}
                                items={_comboWorkCenterList.map((i) => ({ value: i.workCenterSeq, label: i.workCenterName }))}
                                value={workCenterSeq}
                                setValue={(seq) => {
                                    setWorkCenterSeq(seq)
                                    setAreaNos([])
                                }}
                            />
                        </FlexBox>

                        <FlexBox>
                            <Label>
                                <RequiredMark /> {dictCk("workCenterArea")}
                            </Label>
                            <MultiComboBox
                                style={{ width: "300px" }}
                                placeholder={dictCk("multiSelect")}
                                items={
                                    workCenterSeq == 0
                                        ? []
                                        : Array(_comboWorkCenterList.find((i) => i.workCenterSeq == workCenterSeq).areaCnt)
                                              .fill()
                                              .map((i, idx) => ({ value: idx + 1, label: idx + 1 }))
                                }
                                value={areaNos}
                                setValue={setAreaNos}
                            />
                        </FlexBox>

                        {/* 작업장,구역 끝 */}

                        {/* 가동시간 */}
                        <FlexBox style={{ display: isNotUseOpenPer == 1 ? "none" : "flex" }}>
                            <Label>
                                <RequiredMark /> {dictCk("workTime")}
                            </Label>
                            <InputBox type="comma" value={workTime} onChange={(e) => setWorkTime(e.target.value)} />
                        </FlexBox>

                        <FlexBox
                            style={{
                                display: workKind == 10180001 ? "none" : isNotUseOpenPer == 1 ? "none" : "flex",
                                visibility: workKind == 10180001 ? "hidden" : "visible",
                            }}
                        >
                            <Label>
                                <RequiredMark /> {dictCk("workTimeR")}
                            </Label>
                            <InputBox type="comma" value={workTimeR} onChange={(e) => setWorkTimeR(e.target.value)} />
                        </FlexBox>
                        {/* 가동시간 끝 */}

                        <FlexBox>
                            <Label>{dictCk("isUse")}</Label>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                <TableCheckBox check={isUse} setCheck={setIsUse} />
                            </div>
                        </FlexBox>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column", flex: 1, justifyContent: "flex-end", height: "100%" }}>
                        <FlexBox>
                            <Label>{dictCk("provider")}</Label>
                            <InputBox type="text" value={provider} onChange={(e) => setProvider(e.target.value)} />
                        </FlexBox>
                        <FlexBox>
                            <Label>{dictCk("maker")}</Label>
                            <InputBox type="text" value={maker} onChange={(e) => setMaker(e.target.value)} />
                        </FlexBox>
                        <FlexBox>
                            <Label>{dictCk("asTeleNos")}</Label>
                            <InputTelBox style={{}} value={asTeleNos} setValue={setAsTeleNos} />
                        </FlexBox>
                        <FlexBox>
                            <Label>{dictCk("settingDate")}</Label>
                            <DateBox stdDate={settingDate} setStdDate={setSettingDate} />
                        </FlexBox>
                        <FlexBox>
                            <Label>{dictCk("useLife")}</Label>
                            <InputBox type="number" value={useLife} onChange={(e) => setUseLife(e.target.value)} />
                        </FlexBox>

                        {/* 제어노드,릴레이 시작 */}
                        <FlexBox>
                            <RequiredMark />
                            <Label>{dictCk("controlNodeName")}</Label>
                            <ComboBox
                                style={{ width: "300px" }}
                                defaultValue={dictCk("select")}
                                hasDefault={true}
                                items={_comboControlNodeList.map((i) => ({ value: i.controlNodeSeq, label: i.controlNodeName }))}
                                value={controlNodeSeq}
                                setValue={(seq) => {
                                    setControlNodeSeq(seq)
                                    setRelayNoFw(0)
                                    setRelayNoBk(0)
                                    setRelayNoSt(0)
                                }}
                            />
                        </FlexBox>
                        <FlexBox>
                            <Label>
                                <RequiredMark /> {workKind == 10180001 ? dictCk("relayNo") : dictCk("relayFor")}
                            </Label>
                            <ComboBox
                                style={{ width: "300px" }}
                                defaultValue={dictCk("select")}
                                hasDefault={true}
                                items={verDataCombo(
                                    controlNodeSeq == 0
                                        ? []
                                        : Array(_comboControlNodeList.find((i) => i.controlNodeSeq == controlNodeSeq).relayCnt)
                                              .fill()
                                              .map((i, idx) => ({ value: idx + 1, label: idx + 1 }))
                                )}
                                value={relayNoFw}
                                setValue={setRelayNoFw}
                            />
                        </FlexBox>

                        <FlexBox style={{ display: workKind == 10180001 ? "none" : "flex", visibility: workKind == 10180001 ? "hidden" : "visible" }}>
                            <Label>
                                <RequiredMark />
                                {dictCk("relayRev")}
                            </Label>
                            <ComboBox
                                style={{ width: "300px" }}
                                defaultValue={dictCk("select")}
                                hasDefault={true}
                                items={verDataCombo(
                                    controlNodeSeq == 0
                                        ? []
                                        : Array(_comboControlNodeList.find((i) => i.controlNodeSeq == controlNodeSeq).relayCnt)
                                              .fill()
                                              .map((i, idx) => ({ value: idx + 1, label: idx + 1 }))
                                )}
                                value={relayNoBk}
                                setValue={setRelayNoBk}
                            />
                        </FlexBox>

                        <FlexBox
                            style={{
                                display: workKind == 10180002 && isUseStControl == true ? "flex" : "none",
                                visibility: workKind == 10180002 && isUseStControl == true ? "visible" : "hidden",
                            }}
                        >
                            <Label>
                                <RequiredMark />
                                {dictCk(" Relay번호(정지)")}
                            </Label>
                            <ComboBox
                                style={{ width: "300px" }}
                                defaultValue={dictCk("select")}
                                hasDefault={true}
                                items={verDataCombo(
                                    controlNodeSeq == 0
                                        ? []
                                        : Array(_comboControlNodeList.find((i) => i.controlNodeSeq == controlNodeSeq).relayCnt)
                                              .fill()
                                              .map((i, idx) => ({ value: idx + 1, label: idx + 1 }))
                                )}
                                value={relayNoSt}
                                setValue={setRelayNoSt}
                            />
                        </FlexBox>

                        <FlexBox>
                            <Label>{dictCk("noManualControl")}</Label>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                <TableCheckBox check={isNotUseManual} setCheck={setIsNotUseManual} />
                            </div>
                        </FlexBox>
                        <FlexBox style={{ display: workKind == 10180001 ? "none" : "flex" }}>
                            <Label>{dictCk("switchingEquip")}</Label>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                <TableCheckBox check={isNotUseOpenPer} setCheck={setIsNotUseOpenPer} />
                            </div>
                        </FlexBox>
                        <FlexBox style={{ display: workKind == 10180001 ? "none" : "flex" }}>
                            <Label>{dictCk("정지모드")}</Label>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "40px" }}>
                                {/* <TableCheckBox check={isUseStControl} setCheck={setIsUseStControl} /> */}
                                <TableCheckBox check={isUseStControl} setCheck={setIsUseStControlCheck} />
                            </div>
                        </FlexBox>

                        {/* 제어노드,릴레이 끝 */}
                    </div>
                </div>
                <FlexBox alignItems="flex-start">
                    <Label>{dictCk("remark")}</Label>
                    <TextAreaBox width={0} value={remark} setValue={setRemark} />
                </FlexBox>
                <ButtonBox>
                    <Button
                        bgColor={"#fff"}
                        hover={"rgba(174, 174, 174, 0.22)"}
                        border={"1px solid #c6c6c6"}
                        color={"#555555cc"}
                        onClick={() => onClose(dictCk("cancel"), null)}
                    >
                        {dictCk("cancel")}
                    </Button>
                    <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
                        {dictCk("save")}
                    </Button>
                </ButtonBox>
            </div>
        </Dialog>
    )
}

const Title = styled.p`
    opacity: 80%;
    margin-bottom: 23px;
    color: #555555;
    font-size: 15px;
    font-weight: 700;
    line-height: 32px;
`
const FlexBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: ${(props) => props.alignItems || "center"};
    margin-bottom: 14px;
`
const Label = styled.p`
    opacity: 80%;
    width: 110px;
    color: #555555;
    font-size: 14px;
    line-height: 34px;
`
const ButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
const Button = styled.button`
    background-color: ${(props) => props.bgColor};
    width: 147px;
    height: 40px;
    border: ${(props) => props.border};
    border-radius: 3px;
    color: ${(props) => props.color};
    font-size: 14px;

    &:last-child {
        margin-left: 14px;
    }

    &:hover {
        background-color: ${(props) => props.hover};
    }
`

ProductionLineAddPopup.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

export default CommonContainer(ProductionLineContainer(withTranslation()(ProductionLineAddPopup)))
