// 환경제어 > 자동환경설정(복합제어)
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"

import AutoEnvSettingOnOffContainer from "../../../containers/envControl/AutoEnvSettingOnOffContainer"
import AutoEnvSettingConfContainerComp from "../../../containers/envControl/AutoEnvSettingConfContainerComp"
import CommonContainer from "../../../containers/CommonContainer"

import PageName from "../../component/common/PageName"
import { SkyButton, YellowButton } from "../../component/common/Button"
import { TableComponent } from "../../component/common/Table"

import AutoEnvConfAddPopupComp from "../../popup/EnvControl/AutoEnvConfAddPopupComp"
import AutoConfReSettingPopup from "../../popup/EnvControl/AutoConfReSettingPopup"
import AutoEnvOnOffExAddPopup from "../../popup/EnvControl/AutoEnvOnOffExAddPopup"
import TableCheckBox from "../../component/common/TableCheckBox"
import Tab from "../../component/common/Tab"

const AutoEnvSettingConf = (props) => {
    const {
        _autoEnvWorkCenterList,
        _autoEnvEquipList,
        _autoEnvEquip,

        initAutoEnvWorkCenterList,
        getAutoEnvWorkCenterList,
        initAutoEnvEquipList,
        getAutoEnvEquipList,
        initAutoEnvEquip,
        getAutoEnvEquip,
    } = props

    const {
        t,
        initDataList,
        getDataList,
        saveDataList,
        setDataList,
        getComboList,
        initTranslationWords,
        getTranslationWords,
        _translationWords,
        getConfReSetting,
        _confReSettingInfo,
    } = props

    const { confListComp: dataList, GET_CONF_LISTComp: actionType, MOD_CONF_INFOComp, MOD_CONF_INFOCompuseYN, DEL_CONF_INFOComp } = props

    const [tabIndex, setTabIndex] = useState(0)
    const [equipSeq, setEquipSeq] = useState(0)

    const [isOpenPopup, setIsOpenPopup] = useState(false)
    const [equipConfCompSeq, setEquipConfCompSeq] = useState(0)
    const [autoEnvOnOffExAddPopup, setAutoEnvOnOffExAddPopup] = useState(false)
    const [workCenterSeq, setWorkCenterSeq] = useState(0)
    const [autoConfReSettingPopup, setAutoConfReSettingPopup] = useState(false)
    // const [equipReSettingSeq, setEquipReSettingSeq] = useState(-1) //createWrite or Edit
    const dict = _translationWords[0] // 변수에 사전 담기

    // 다국어 관련 ==================================================
    const dictCk = (word) => {
        if (dict == undefined || dict == null || dict == {} || dict == "") {
            return word
        } else {
            const result = dict[word] != undefined && dict[word] != null && dict[word] != "" ? dict[word] : word
            return result
        }
    }
    // ==============================================================

    useEffect(() => {
        getTranslationWords()
        getData()

        return async () => {
            await initAutoEnvWorkCenterList()
            await initAutoEnvEquipList()
            await initAutoEnvEquip()
        }
    }, [])

    const getData = async () => {
        await getAutoEnvWorkCenterList()
    }

    useEffect(() => {
        if (_autoEnvWorkCenterList.length > 0) {
            setTabIndex(_autoEnvWorkCenterList[0].workCenterSeq)
            getEquipList(_autoEnvWorkCenterList[0].workCenterSeq)
            setWorkCenterSeq(_autoEnvWorkCenterList[0].workCenterSeq)
        }
    }, [_autoEnvWorkCenterList])

    useEffect(() => {
        if (_autoEnvEquipList.length > 0) {
            setEquipSeq(_autoEnvEquipList[0].equipSeq)
            getEquipInfo(_autoEnvEquipList[0].equipSeq)
        }
    }, [_autoEnvEquipList])

    const getEquipList = async (workCenterSeq) => {
        await getAutoEnvEquipList({ workCenterSeq, type: 0 })
    }

    const getEquipInfo = async (equipSeq) => {
        await getAutoEnvEquip({ equipSeq })

        const data = {
            equipSeq,
        }

        await getDataList(actionType, data)
    }

    const closePopup = async () => {
        setIsOpenPopup(false)

        // getData()
        getEquipInfo(equipSeq)
    }

    const onClickInfo = async (equipConfCompSeq) => {
        // 정보 (추가/수정)

        setEquipConfCompSeq(equipConfCompSeq)

        setIsOpenPopup(true)
    }

    const onClickInfo_Comp = async (equipConfCompSeq) => {
        // 정보 (추가) 보조조건 선택시

        setEquipConfCompSeq("-" + equipConfCompSeq)

        setIsOpenPopup(true)
        // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>'+equipConfCompSeq);
    }

    const onClickDelete = async (equipConfCompSeq) => {
        // 정보 (삭제)

        let data = { equipConfCompSeq }

        if (await saveDataList(DEL_CONF_INFOComp, data)) getEquipInfo(equipSeq)
    }

    const onClickCheckBox = async (datato) => {
        // 정보 (사용여부)

        let data = {
            useYN: datato.useYN == 1 ? 0 : 1,
            equipConfCompSeq: datato.equipConfCompSeq,
        }
        // console.log('>>>>>>>>>>>>>>>>>>>>>>>>>'+datato.useYN+'222'+datato.equipConfCompSeq);
        // console.log('>'+data.useYN+'444'+data.equipConfCompSeq);

        if (await saveDataList(MOD_CONF_INFOCompuseYN, data)) getEquipInfo(equipSeq)
    }

    // 목표값 조건변경 - 신규추가
    const onClickAllReSettingAdd = async () => {
        const result = await getConfReSetting({ workCenterSeq: workCenterSeq, standardKind: 10150001 })
        if (result) {
            setAutoConfReSettingPopup(true)
        }
    }
    // // 목표값 조건변경 - edit method 수정
    // const onClickAllReSettingEdit = async (seq) => {
    //     const result = await getConfReSetting({ workCenterSeq: workCenterSeq, standardKind: 10150001 })
    //     // setStaffSeq(seq)
    //     if (result) {
    //         setAutoConfReSettingPopup(true)
    //     }
    // }

    // // 목표값 조건변경 - del method 삭제
    // const onClickAllReSettingDelete = async (seq) => {
    //     const result = await delStaff({ equipReSettingSeq: seq })
    //     if (result) getData()
    // }

    // moveDetail method
    const autoEnvOnOffExAddPopupHandler = async () => {
        setAutoConfReSettingPopup(false)
        getEquipInfo(equipSeq)
    }

    return (
        <Container>
            <AutoEnvOnOffExAddPopup open={autoEnvOnOffExAddPopup} onClose={closePopup} />
            <AutoEnvConfAddPopupComp open={isOpenPopup} onClose={closePopup} equipConfCompSeq={equipConfCompSeq} equipSeq={equipSeq} />
            <AutoConfReSettingPopup
                open={autoConfReSettingPopup}
                onClose={autoEnvOnOffExAddPopupHandler}
                workCenterSeqs={workCenterSeq}
                standardKinds={10150001}
                infoItem={_confReSettingInfo}
            />

            <PageName name={dict["autoConfig(complex)"]} state={false} setState={() => {}} />
            <Tab
                list={_autoEnvWorkCenterList.map((i) => ({ seq: i.workCenterSeq, name: i.workCenterName }))}
                tabIndex={tabIndex}
                onClick={(i) => {
                    getEquipList(i.seq)
                    setTabIndex(i.seq)
                    setEquipSeq(0)
                    setWorkCenterSeq(i.seq)
                }}
            />
            <Box>
                {_autoEnvEquipList.length > 0 && tabIndex > 0 && (
                    <Left>
                        <LeftTop>
                            <TableComponent
                                headList={[{ title: dict["facilities"] }]}
                                bodyList={_autoEnvEquipList.map((i, idx) => ({
                                    seq: i.equipSeq,
                                    equipName: i.equipName,
                                }))}
                                dict={dict}
                                onClick={(row) => {
                                    getEquipInfo(row.seq)
                                    setEquipSeq(row.seq)
                                }}
                            />
                        </LeftTop>
                    </Left>
                )}
                {_autoEnvEquipList.length > 0 && equipSeq > 0 && (
                    <Right>
                        <RightTop>
                            <FlexBox justifyContent={"flex-end"}>
                                <SkyButton onClick={() => onClickInfo(0)}>{dict["add"]}</SkyButton>
                                <YellowButton style={{ marginLeft: "14px" }} onClick={() => onClickAllReSettingAdd()}>
                                    {dictCk("changeTargetValueCondition")}
                                </YellowButton>
                            </FlexBox>
                            <TableComponent
                                headList={[
                                    //{ title: `${t("no")}` },
                                    { title: dict["ranking"] }, //순위
                                    { title: dict["standardKind"] }, //조건기준
                                    { title: "(" + dict["sensor"] + "&" + dict["facilities"] + ")" + dict["type"] }, //(센서&설비)종류
                                    { title: dict["days"] }, // 날짜
                                    { title: dict["time"] }, // 시간
                                    { title: dict["operatingConditions"] + "(" + dict["sensor"] + ")" }, // 작동조건(센서)
                                    { title: dict["operatingConditions"] + "(" + dict["facilities"] + ")" }, // 작동조건(설비)
                                    { title: dict["workKind"] }, // 작동방식
                                    { title: dict["delayTime"] }, // 대기시간
                                    { title: dict["repeatOperation"] }, // 반복작동
                                    { title: dict["remark"], type: "text" }, // 비고
                                    { title: dict["isUse"] }, // 사용여부
                                    { title: dict["actions"] }, // 수정 2024.03.18 by 강동우
                                    //{ title: `${t("actions")}` }, 
                                ]}
                                bodyList={dataList.map((i, idx) => ({
                                    seq: i.equipConfCompSeq,
                                    seq1: i.prioritySubSerl == 0 ? i.equipConfCompSeq : "-",
                                    // no: dataList.length - idx,
                                    prioritySerl: i.prioritySubSerl == 0 ? i.prioritySerl : i.prioritySerl + "-" + i.prioritySubSerl,

                                    standardKindname: i.standardKindname,
                                    sensorTypename:
                                        i.sensorTypename == 0 || i.sensorTypename == null || i.sensorTypename == undefined
                                            ? i.conditionEquipSeqname
                                            : i.sensorTypename,
                                    // conditionEquipSeqname: i.conditionEquipSeqname == 0 || i.conditionEquipSeqname == null || i.conditionEquipSeqname == undefined ? '' : i.conditionEquipSeqname,
                                    //날짜
                                    Date:
                                        i.dateFr == 0 ||
                                        i.dateFr == null ||
                                        i.dateFr == undefined ||
                                        i.dateTo == 0 ||
                                        i.dateTo == null ||
                                        i.dateTo == undefined
                                            ? ""
                                            : i.dateFr.substr(0, 4) +
                                              "." +
                                              i.dateFr.substr(4, 2) +
                                              "." +
                                              i.dateFr.substr(6, 2) +
                                              " ~ " +
                                              i.dateTo.substr(0, 4) +
                                              "." +
                                              i.dateTo.substr(4, 2) +
                                              "." +
                                              i.dateTo.substr(6, 2),
                                    //시간
                                    time:
                                        i.timeFr == undefined && i.timeTo == undefined
                                            ? ""
                                            : i.timeFr == undefined && i.timeTo == 10 && i.standardKind == 10250002
                                            ? dict["lightFr"] + "~" + dict["lightTo"]
                                            : i.timeFr == undefined && i.timeTo == 10 && i.standardKind == 10250003
                                            ? dict["lightTo"] + "~" + dict["lightFr"]
                                            : i.timeFr == undefined && i.timeTo != 10
                                            ? i.timeTo + dict["time"]
                                            : i.timeFr.substr(0, 2) +
                                              ":" +
                                              i.timeFr.substr(-2) +
                                              " ~ " +
                                              i.timeTo.substr(0, 2) +
                                              ":" +
                                              i.timeTo.substr(-2),
                                    // 작동조건(센서)
                                    actionKindSensor:
                                        i.actionKindSensor == 1
                                            ? i.valueFr + " ~ " + i.valueTo + "(" + dict["between"] + ")"
                                            : i.actionKindSensor == 2
                                            ? i.valueTo + "(" + dict["below"] + ")"
                                            : i.actionKindSensor == 3
                                            ? i.valueFr + "(" + dict["moreThan"] + ")"
                                            : i.actionKindSensor == 4
                                            ? i.valueFr + " ~ " + i.valueTo + "(" + dict["maintenance"] + ")"
                                            : i.actionKindSensor == 4
                                            ? i.valueFr + " ~ " + i.valueTo + "(" + dict["maintenance"] + ")"
                                            : (i.standardKind == 10150011 || i.standardKind == 10150013) && i.valueFr == 1
                                            ? "N"
                                            : (i.standardKind == 10150011 || i.standardKind == 10150013) && i.valueFr == 2
                                            ? "Y"
                                            : "",
                                    // 작동조건(설비)
                                    actionKindEquip:
                                        i.actionKindEquip == 0 || i.actionKindEquip == null || i.actionKindEquip == undefined
                                            ? ""
                                            : i.actionKindEquip == 1 && i.actionStatus == 1
                                            ? dict["operationalStatus"] + " - " + dict["operation"]
                                            : i.actionKindEquip == 1 && i.actionStatus == 2
                                            ? dict["operationalStatus"] + " - " + dict["nonOperating"]
                                            : i.actionKindEquip == 2 && i.actionKindOpenPer == 2
                                            ? dict["openPer"] + " - " + i.dEquipOpenPer + " %(" + dict["below"] + ")"
                                            : dict["openPer"] + " - " + i.dEquipOpenPer + " %(" + dict["moreThan"] + ")",

                                    // 작동방식
                                    onOff:
                                        i.onOffname === undefined && i.openPerSet === undefined
                                            ? ""
                                            : i.openPerSet == null || i.openPerSet == undefined
                                            ? i.onOffname
                                            : i.openPerSet == 10190001
                                            ? "정(냉방)"
                                            : i.openPerSet == 10190002
                                            ? "역(난방)"
                                            : i.openPerSet == 10190003
                                            ? "정지"
                                            : i.openPerSet + " %",

                                    delayTime: i.delayTime == 0 || i.delayTime == null || i.delayTime == undefined ? "" : i.delayTime + dict["sec"],
                                    delayCycle:
                                        i.delayCycle == 0 || i.delayCycle == null || i.delayCycle == undefined ? "" : i.delayCycle + dict["sec"],

                                    remark: i.remark == 0 || i.remark == null || i.remark == undefined ? "" : i.remark,
                                    useYN: <TableCheckBox check={i.useYN == 1} setCheck={() => onClickCheckBox(i)} />,
                                    Actions_Comp: true,
                                }))}
                                dict={dict}
                                onClickEdit={(seq) => onClickInfo(seq)} // 수정
                                onClickDelete={(seq) => onClickDelete(seq)} // 삭제
                                onClickEdit_Comp={(seq1) => onClickInfo_Comp(seq1)} // 보조조건
                            />
                        </RightTop>
                    </Right>
                )}
            </Box>
        </Container>
    )
}

const Container = styled.div`
    width: 100%;
    height: 100%;
    &:after {
        content: "";
        display: block;
        height: 42px;
        width: 100%;
    }
`
const FlexBox = styled.div`
    display: flex;
    justify-content: ${(props) => props.justifyContent || "flex-start"};
    align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
    overflow: hidden;
    display: flex;
    margin-top: 28px;
    height: 100%;
`
const Left = styled.div`
    width: 260px;
    height: 100%;
`

const Right = styled.div`
    flex: 1;
    height: 100%;
`

const RightTop = styled.div`
    background-color: #fff;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 80%;
    margin: 0 23px;
    padding: 28px 35px;
    border: 1px solid #fff;
    border-radius: 9px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`

const LeftTop = styled.div`
    background-color: #fff;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 80%;
    margin: 0px;
    padding: 0px 20px 20px 20px;
    border: 1px solid #fff;
    border-radius: 9px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`

// const RightBottom = styled.div`
//   background-color: #fff;
//   width: 100%;
//   height: 350px;
//   margin: 30px 23px 0;
//   padding: 28px 35px;
//   border: 1px solid #fff;
//   border-radius: 9px;
//   box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
// `

export default AutoEnvSettingOnOffContainer(AutoEnvSettingConfContainerComp(CommonContainer(withTranslation()(AutoEnvSettingConf))))
