// 작기정보 환경정보 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import { withTranslation } from "react-i18next"
import CropInfoContainer from "../../../containers/cropManage/CropInfoContainer"
import RequiredMark from "../../component/RequiredMark"
import InputBox from "../../component/common/InputBox"
import ComboBox from "../../component/common/ComboBox"
import AlertPopup from "../AlertPopup"
import TextAreaBox from "../../component/common/TextAreaBox"

import CommonContainer from "../../../containers/CommonContainer"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "421px",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const CropEnvAddPopup = (props) => {
  const classes = useStyles()
  const { t, _codeList, _cropEnv, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  const { open, onClose } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const [dayNightSeq, setDayNightSeq] = useState(0)
  const [envKind, setEnvKind] = useState(0)
  const [sensorUnitSeq, setSensorUnitSeq] = useState(0)
  const [maxValue, setMaxValue] = useState("")
  const [minValue, setMinValue] = useState("")
  const [remark, setRemark] = useState("")
  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {
    getTranslationWords()
    if (_cropEnv && Object.keys(_cropEnv).length != 0) {
      setDayNightSeq(_cropEnv.dayNightSeq)
      setEnvKind(_cropEnv.envKind)
      setSensorUnitSeq(_cropEnv.sensorUnitSeq)
      setMaxValue(_cropEnv.maxValue)
      setMinValue(_cropEnv.minValue)
      setRemark(_cropEnv.remark)
    }

    return () => {
      setDayNightSeq(0)
      setEnvKind(0)
      setSensorUnitSeq(0)
      setMaxValue("")
      setMinValue("")
      setRemark("")
    }
  }, [open])

  const onClickSave = () => {
    if (dayNightSeq == 0) return setAlertPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['dayNightKind']) })
    else if (envKind == 0) return setAlertPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['env']) })
    else if (sensorUnitSeq == 0) return setAlertPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['sensorUnit']) })
    // else if (minValue == 0) return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict['minValue']) })
    // else if (maxValue == 0) return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict['maxValue']) })

    const param = {
      dayNightSeq: dayNightSeq || 0,
      envKind: envKind || 0,
      sensorUnitSeq: sensorUnitSeq || 0,
      minValue: minValue || null,
      maxValue: maxValue || null,
      remark,
    }
    if (Object.keys(_cropEnv).length != 0) {
      param.cropEnvSeq = _cropEnv.cropEnvSeq
    }
    onClose(dict['save'], param)
  }

  const onClickCancel = () => {
    onClose(dict['cancel'], null)
  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose(null)}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <Container>
        <Title>{dict['env']}</Title>
        <div style={{ flex: 1 }}>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['dayNightKind']}
            </Label>
            <ComboBox
              style={{ width: "220px" }}
              defaultValue={dict['select']}
              hasDefault={true}
              items={
                _codeList.length > 0 && _codeList.filter((i) => i.majorKind == 1 && i.majorSeq == 1008).length > 0
                  ? _codeList
                      .filter((i) => i.majorKind == 1 && i.majorSeq == 1008)[0]
                      .minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                  : []
              }
              value={dayNightSeq}
              setValue={setDayNightSeq}
            />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['envKind']}
            </Label>
            <ComboBox
              style={{ width: "220px" }}
              defaultValue={dict['select']}
              hasDefault={true}
              items={
                _codeList.length > 0 && _codeList.filter((i) => i.majorKind == 1 && i.majorSeq == 1009).length > 0
                  ? _codeList
                      .filter((i) => i.majorKind == 1 && i.majorSeq == 1009)[0]
                      .minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                  : []
              }
              value={envKind}
              setValue={setEnvKind}
            />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['sensorUnit']}
            </Label>
            <ComboBox
              style={{ width: "220px" }}
              defaultValue={dict['select']}
              hasDefault={true}
              items={
                _codeList.length > 0 &&
                _codeList.filter((i) => i.majorSeq == 1010).length > 0 &&
                _codeList.filter((i) => i.majorSeq == 1010)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
              }
              value={sensorUnitSeq}
              setValue={setSensorUnitSeq}
            />
          </FlexBox>
          <FlexBox>
            <Label> {dict['minValue']}</Label>
            <InputBox type="number" value={minValue} onChange={(e) => setMinValue(e.target.value)} useNegativeNum={true} />
          </FlexBox>
          <FlexBox>
            <Label> {dict['maxValue']}</Label>
            <InputBox type="number" value={maxValue} onChange={(e) => setMaxValue(e.target.value)} useNegativeNum={true} />
          </FlexBox>
          <FlexBox alignItems="flex-start">
            <Label>{dict['remark']}</Label>
            <TextAreaBox value={remark} setValue={setRemark}></TextAreaBox>
          </FlexBox>
        </div>
        <ButtonBox>
          <Button bgColor={"#fff"} hover={"rgba(174, 174, 174, 0.22)"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={onClickCancel}>
            {dict['cancel']}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {dict['save']}
          </Button>
        </ButtonBox>
      </Container>
    </Dialog>
  )
}

const Container = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 23px 26px 40px;
`
const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 34px;
  }
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  flex: 1;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

CropEnvAddPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CropInfoContainer(CommonContainer(withTranslation()(CropEnvAddPopup)))
