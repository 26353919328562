import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const { 
  
  SALES_LIST, SALES_INFO, 
  ADD_SALES_INFO, MOD_SALES_INFO, DEL_SALES_INFO, DEL_SALES_ITEM,
  SALES_OUT_LIST,
  ADD_SALES_OUT_INFO,
  MOD_SALES_OUT_INFO,
  DEL_SALES_OUT_INFO,
  SALES_OUT_EXCEL,

  OUT_BARCODE_SALES_LIST,
  OUT_BARCODE_LIST,
  ADD_OUT_BARCODE_INFO,
  DEL_OUT_BARCODE_INFO,

} = actions.SalesManageAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  
  salesList: state.CommonReducer.salesList || SALES_LIST.initData, SALES_LIST,
  salesInfo: state.CommonReducer.salesInfo || SALES_INFO.initData, SALES_INFO,
  ADD_SALES_INFO, MOD_SALES_INFO, DEL_SALES_INFO, DEL_SALES_ITEM,

  salesOutList: state.CommonReducer.salesOutList || SALES_OUT_LIST.initData, SALES_OUT_LIST,
  ADD_SALES_OUT_INFO,
  MOD_SALES_OUT_INFO,
  DEL_SALES_OUT_INFO,
  SALES_OUT_EXCEL,

  outBarcodeSalesList: state.CommonReducer.outBarcodeSalesList || OUT_BARCODE_SALES_LIST.initData, OUT_BARCODE_SALES_LIST,
  outBarcodeList: state.CommonReducer.outBarcodeList || OUT_BARCODE_LIST.initData, OUT_BARCODE_LIST,
  ADD_OUT_BARCODE_INFO,
  DEL_OUT_BARCODE_INFO,

})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({

})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
