import React, { useState } from "react"
import styled from "styled-components"
import cookie from "react-cookies"
import { withTranslation } from "react-i18next"
import MainContainer from "../../../containers/MainContainer"

import icon_left from "images/sidebar/left.png"
import icon_left_hover from "images/sidebar/left_hover.png"
import icon_right from "images/sidebar/right.png"
import icon_right_hover from "images/sidebar/right_hover.png"

import icon_menu_up from "images/sidebar/menuUp.png"
import icon_menu_up_hover from "images/sidebar/menuUp_hover.png"
import icon_menu_down from "images/sidebar/menuDown.png"
import icon_menu_down_hover from "images/sidebar/menuDown_hover.png"
import icon_hamberger from "images/sidebar/hamberger.png"
import icon_star from "images/sidebar/star.png"

const SideBar = (props) => {
  const { t, title = "타이틀", menuList = [], page = "", setPage = () => {}, getCodeList, _bookmarkList} = props
  const [open, setOpen] = useState(true)
  const [menuIndex, setMenuIndex] = useState(0)
  const [type, setType] = useState(0) // 0: 메뉴 1: 즐겨찾기
  const { initTranslationWords, getTranslationWords, _translationWords} = props // 추가 2024.03.18 by 강동우

  let dict = _translationWords[0] // 추가 2024.03.18 by 강동우

  // 추가 2024.03.18 by 강동우
  const dictCk = (word) => {
    if (dict == undefined || dict == null || dict == {} || dict == "") {
        return word
    } else {
        const result = dict[word] != undefined && dict[word] != null && dict[word] != "" ? dict[word] : word
        return result
    }
  }
  
  // 매뉴 아이콘 출력
  const returnIMG = (menuId) => {
    const imgg = require(`../../../images/sidebar/${menuId}.png`)
    return <img style={{ width: "24px", height: "24px", marginRight: "12px" }} src={imgg} alt={"메뉴 아이콘"} />
  }

  // 프로그램 클릭 이벤트 리스너
  const onClickMenuItem = async (subItem) => {
    cookie.save("moduleId", subItem.moduleId, { path: "/" })
    cookie.save("pgmSeq", subItem.pgmSeq, { path: "/" })
    cookie.save("pgmId", subItem.pgmId, { path: "/" })
    setPage(subItem.pgmId)
    await getCodeList({ pgmSeq: subItem.pgmSeq })
  }

  return (
    <Container>
      <Inner open={open}>
        <BarButton onClick={() => setOpen(!open)}>
          <BarIcon open={open} />
        </BarButton>
        {open && (
          <>
            <TitleView>
              <Title>{type == 0 ? title : dictCk("bookmark")}</Title> 
            </TitleView>

            {type == 0 && (
              <MenuBox>
                {menuList.map((item, index) => (
                  <MenuView key={index}>
                    <Menu open={menuIndex === index} onClick={() => setMenuIndex(menuIndex === index ? -1 : index)}>
                      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {returnIMG(item.menuId)}
                        <p style={{ opacity: "70%", color: "#fff", fontSize: "15px", lineHeight: "32px" }}>{item.menuName}</p>
                      </div>
                      <div className="drop" />
                    </Menu>
                    <MenuItemView open={menuIndex === index}>
                      {item.pgm.map((subItem, subIndex) => (
                        <MenuItem key={subIndex} select={page === subItem.pgmId} onClick={() => onClickMenuItem(subItem)}>
                          {subItem.pgmName}
                        </MenuItem>
                      ))}
                    </MenuItemView>
                  </MenuView>
                ))}
              </MenuBox>
            )}
            {type == 1 && (
              <MenuBox>
                <MenuItemView open={true}>
                  {_bookmarkList.map((subItem, subIndex) => (
                    <MenuItem key={subIndex} select={page === subItem.pgmId} onClick={() => onClickMenuItem(subItem)}>
                      {subItem.pgmName}
                    </MenuItem>
                  ))}
                </MenuItemView>
              </MenuBox>
            )}

            <BottomBox>
              <BottomButton onClick={() => setType(0)}>
                <img style={{ width: "15px", height: "13px" }} src={icon_hamberger} alt="햄버거" />
                <p>{dictCk("menu")}</p>
              </BottomButton>

              <BottomButton onClick={() => setType(1)}>
                <img style={{ width: "16px", height: "16px" }} src={icon_star} alt="별" />
                <p>{dictCk("bookmark")}</p>
              </BottomButton>
            </BottomBox>
          </>
        )}
      </Inner>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  padding: 18px 0px 47px 20px;
`
const Inner = styled.div`
  background-color: #3e7347;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.open ? 300 : 16)}px;
  height: 100%;
  box-sizing: content-box;
  border-radius: 16px;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.15);
`
const TitleView = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 80px;
`
const Title = styled.p`
  margin-left: 40px;
  color: #fff;
  font-size: 14px;
`
const BarButton = styled.button`
  position: absolute;
  background-color: #00000000;
  top: 40px;
  right: -19px;
`
const BarIcon = styled.div`
  background-image: url(${(props) => (props.open ? icon_left : icon_right)});
  background-repeat: no-repeat;
  background-size: 38px 38px;
  width: 38px;
  height: 38px;
  &:hover {
    background-image: url(${(props) => (props.open ? icon_left_hover : icon_right_hover)});
  }
`
const MenuBox = styled.div`
  flex: 1;
`
const MenuView = styled.div`
  width: 100%;
`
const Menu = styled.button`
  background-color: ${(props) => (props.open ? "rgba(255, 255, 255, 0.08)" : "inherit")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 80px;
  padding-left: 40px;
  padding-right: 29px;

  & > div.drop {
    background-image: url(${(props) => (props.open ? icon_menu_up : icon_menu_down)});
    background-size: 11px 7px;
    width: 11px;
    height: 7px;
  }
  :hover {
    & > div.drop {
      background-image: url(${(props) => (props.open ? icon_menu_up_hover : icon_menu_down_hover)});
    }
  }
`
const MenuItemView = styled.div`
  background-color: ${(props) => (props.open ? "rgba(0, 0, 0, 0.08)" : "inherit")};
  display: ${(props) => (props.open ? "block" : "none")};
  padding: 26px 40px;
`
const MenuItem = styled.p`
  cursor: pointer;
  opacity: ${(props) => (props.select ? "100%" : "40%")};
  width: 100%;
  margin-bottom: 13px;
  color: #fff;
  font-size: 15px;
  line-height: 32px;
  &:last-child {
    margin-bottom: 0px;
  }
`
const BottomBox = styled.div`
  display: flex;
  height: 80px;
  border-top: 1px solid rgba(0, 0, 0, 0.16);
`
const BottomButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  & > p {
    opacity: 70%;
    margin-left: 10px;
    color: #fff;
    font-size: 15px;
    line-height: 32px;
  }
  &:last-child {
    border-left: 1px solid rgba(0, 0, 0, 0.16);
  }
`

export default MainContainer(withTranslation()(SideBar))
