import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const { setUserList, getUserList, addUser, initUser, getUser, editUser, delUser, downloadUserListExcel } = actions.DefaultInfoAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _userList: state.DefaultInfoReducer._userList,
  _user: state.DefaultInfoReducer._user,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  setUserList: (param) => dispatch(setUserList(param)),
  getUserList: (param) => dispatch(getUserList(param)),
  addUser: (param) => dispatch(addUser(param)),
  initUser: (param) => dispatch(initUser(param)),
  getUser: (param) => dispatch(getUser(param)),
  editUser: (param) => dispatch(editUser(param)),
  delUser: (param) => dispatch(delUser(param)),
  downloadUserListExcel: (param) => dispatch(downloadUserListExcel(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
