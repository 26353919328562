import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const { initSensorDataListExcel, getSensorDataListExcel } = actions.StatusInfoAction

// const {} = actions.CommonAction
export const actionWithdrawalList = { uri: "/info/withdrawal/list", id: "withdrawalList", initData: [] }
export const actionWithdrawalExcelDownload = { uri: "/info/withdrawal/excel/download", id: "", initData: {} }

export const actionSensorDataGridList = { uri: "/info/sensorData/grid/list", id: "sensorDataGridList", initData: [] }
export const actionSensorDataGridExcelDownload = { uri: "/info/sensorData/Grid/excel/download", id: "", initData: {} }

// redux state > component mapping
const mapStateToProps = (state) => ({
    withdrawalList: state.CommonReducer.withdrawalList || actionWithdrawalList.initData,
    actionWithdrawalList,
    actionWithdrawalExcelDownload,
    sensorDataGridList: state.CommonReducer.sensorDataGridList || actionSensorDataGridList.initData,
    actionSensorDataGridList,
    actionSensorDataGridExcelDownload,
    sensorDataListExcel: state.StatusInfoReducer._sensorDataListExcel,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
    initSensorDataListExcel: (param) => dispatch(initSensorDataListExcel(param)),
    getSensorDataListExcel: (param) => dispatch(getSensorDataListExcel(param)),
})

const WithHoc = (ChildComponent) => (props) => {
    return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
