import styled from "styled-components"

export const YellowButton = styled.button`
  background-color: #fadb56;
  width: 147px;
  height: 40px;
  border: 1px solid #e9c11a;
  border-radius: 3px;
  color: #555555;
  font-size: 14px;
  &:hover {
    background-color: #f9cd08;
    border: 1px solid #e0b80a;
  }
`
export const SkyButton = styled.button`
  background-color: #aed9fa;
  width: flex;
  min-width: 147px;
  height: 40px;
  padding: 0 15px 0 15px; 
  border: 1px solid #84beeb;
  border-radius: 3px;
  color: #555555;
  font-size: 14px;
  &:hover {
    background-color: #8cccfb;
    border: 1px solid #63ade6;
  }
`
export const GreenButton = styled.button`
  background-color: #52935d;
  width: 147px;
  height: 40px;
  border-radius: 4px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  &:hover {
    background-color: #3e7347;
  }
`
export const GrayButton = styled.button`
  background-color: #f2f2f2;
  width: 147px;
  height: 40px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  color: #55555599;
  font-size: 14px;
  &:hover {
    background-color: #e4e4e4;
    border: 1px solid #bebdbd;
  }
`

export const RedButton = styled.button`
  background-color: rgba(250, 91, 89, 0.19);
  width: 147px;
  height: 40px;
  border: 1px solid #fa5b59;
  border-radius: 4px;
  color: #fa5b59;
  font-size: 14px;
  &:hover {
    background-color: #fff;
    border: 1px solid #fa5b59;
  }
`

