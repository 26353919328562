import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const {
    initDataList,
    getDataList,
    saveDataList,
    setDataList,
    getComboList,
    getItemCodeHelpList,
    getCustCodeHelpList,
    getCropCodeHelpList,

    // 다국어 관련 - 차건담 2023.02.14
    initTranslationWords,
    getTranslationWords,
} = actions.CommonAction

// redux state > component mapping
const mapStateToProps = (state) => ({
    sminorComboList: state.CommonReducer.sminorComboList || [],
    uminorComboList: state.CommonReducer.uminorComboList || [],
    workCenterComboList: state.CommonReducer.workCenterComboList || [],
    cropComboList: state.CommonReducer.cropComboList || [],
    cropGrowthComboList: state.CommonReducer.cropGrowthComboList || [],
    staffComboList: state.CommonReducer.staffComboList || [],

    farmComboList: state.CommonReducer.farmComboList || [],
    sensorNodeComboList: state.CommonReducer.sensorNodeComboList || [],
    sensorComboList: state.CommonReducer.sensorComboList || [],

    itemCodeHelpList: state.CommonReducer.itemCodeHelpList || [],
    custCodeHelpList: state.CommonReducer.custCodeHelpList || [],
    cropCodeHelpList: state.CommonReducer.cropCodeHelpList || [],

    _comboEquipList: state.SystemSettingReducer._comboEquipList || [],
    _codeList: state.SystemSettingReducer._codeList || [],

    _translationWords: state.CommonReducer._translationWords || [],
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
    initDataList: (actionType) => dispatch(initDataList(actionType)),
    getDataList: (actionType, param, options = {}) => dispatch(getDataList(actionType, param, options)),
    saveDataList: (actionType, param) => dispatch(saveDataList(actionType, param)),
    setDataList: (param) => dispatch(setDataList(param)),

    getComboList: (param) => dispatch(getComboList(param)),
    getItemCodeHelpList: (param) => dispatch(getItemCodeHelpList(param)),
    getCustCodeHelpList: (param) => dispatch(getCustCodeHelpList(param)),
    getCropCodeHelpList: (param) => dispatch(getCropCodeHelpList(param)),

    // 다국어 관련 - 차건담 2023.02.14
    initTranslationWords: (param) => dispatch(initTranslationWords(param)),
    getTranslationWords: (param) => dispatch(getTranslationWords(param)),

    // {{n}}에 다른 단어로 대체하는 함수 - 차건담 2023.02.15
    replaceWord: (wordWithN, word) => {
        const result = wordWithN.replace("{{n}}", word)
        return result
    },
})

const WithHoc = (ChildComponent) => (props) => {
    return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
