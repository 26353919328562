// 자동환경설정 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import { InterLockOpenList, InterLockBoolList, InterLockOpenOnOffList } from "../../../utils/data"
import InterLockSettingContainer from "../../../containers/envControl/InterLockSettingContainer"
import ComboBox from "../../component/common/ComboBox"
import InputBox from "../../component/common/InputBox"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "388px",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))



const InterLockEditPopup = (props) => {
  const classes = useStyles()
  const { t, _interLock } = props
  const { open, onClose, type, workType, dict} = props // type 1: 온도(상) 2: 온도(하) 3: 강우 4: 풍속/풍향
  const [temp, setTemp] = useState("") // 온도
  const [detectKind, setDetectKind] = useState(0) // 감우감지 yes no
  const [statusKind, setStatusKind] = useState(0) // 열림/닫힘 0: 닫힘 1: 열림
  const [section, setSection] = useState("") // 구간설정
  const [delayTime, setDelayTime] = useState("") // 대기시간

  const [windSpeed, setWindSpeed] = useState("")
  const [windVolume, setWindVolume] = useState("")

  useEffect(() => {
    if (Object.keys(_interLock).length == 0) return
    switch (type) {
      case 1:
        setTemp(_interLock.tempMax)
        setStatusKind(_interLock.statusKind)
        setSection(_interLock.section)
        setDelayTime(_interLock.delayTime)

        break
      case 2:
        setTemp(_interLock.tempMin)
        setStatusKind(_interLock.statusKind)
        setSection(_interLock.section)
        setDelayTime(_interLock.delayTime)

        break
      case 3:
        setDetectKind(_interLock.detectKind)
        setStatusKind(_interLock.statusKind)
        setSection(_interLock.section)
        setDelayTime(_interLock.delayTime)

        break
      case 4:
        setStatusKind(_interLock.statusKind)
        setWindSpeed(_interLock.windSpeed)
        setWindVolume(_interLock.windVolume)

        break
      default:
        break
    }
    return () => {
      setTemp("")
      setDetectKind(0)
      setStatusKind(0)
      setSection("")
      setDelayTime("")
      setWindSpeed("")
      setWindVolume("")
    }
  }, [open])

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }


  const renderTitle = () => {
    switch (type) {
      case 1:
        return dictCk("InterlockCon") //t("temperature (max) interlock condition") 번역 수정 24.03.13 by 강동우
      case 2:
        return dictCk("InterlockCon")  //t("temperature (min) interlock condition") 번역 수정 24.03.13 by 강동우
      case 3:
        return dictCk("InterlockCon")  //t("rain Interlock Conditions") 번역 수정 24.03.13 by 강동우
      case 4:
        return dictCk("InterlockCon")  //t("wind speed/wind direction interlock condition") 번역 수정 24.03.13 by 강동우
      default:
        return ""
    }
  }

  const onClickSave = () => {
    let param = {
      equipSeq: _interLock.equipSeq,
      interLockKind: type,
    }

    switch (type) {
      case 1:
        param = { ...param, tempMax: temp || 0, statusKind: statusKind || 0, section: section || 0, delayTime: delayTime || 0 }
        break
      case 2:
        param = { ...param, tempMin: temp || 0, statusKind: statusKind || 0, section: section || 0, delayTime: delayTime || 0 }
        break
      case 3:
        param = { ...param, detectKind: detectKind || 0, statusKind: statusKind || 0, section: section || 0, delayTime: delayTime || 0 }
        break
      case 4:
        param = { ...param, statusKind: statusKind || 0, windSpeed: windSpeed || 0, windVolume: windVolume || 0 }
        break
      default:
        break
    }
    onClose(dictCk("save"), param)
  }

  const onClickCancel = () => {
    onClose(dictCk("cancel"), null)
  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose(null)}
      open={open}
      fullWidth={true}
    >
      <Container>
        <Title>{renderTitle()}</Title>
        <div style={{ flex: 1 }}>
          {(type == 1 || type == 2) && (
            <FlexBox>
              {/* <Label>{type == 1 ? t("temperatureMax") : t("temperatureMin")}</Label> */}
              <Label>{type == 1 ? dictCk("MaxTemp") : dictCk("MinTemp")}</Label> {/* 번역 수정 24.03.13 by 강동우 */}
              <InputBox
                style={{ width: "160px", marginRight: 0 }}
                type="number"
                useComma={false}
                value={temp}
                useNegativeNum={true}
                onChange={(e) => setTemp(e.target.value)}
              />
              <p style={{ marginLeft: "10px", color: "#555555e6", fontSize: "14px" }}>{dictCk("cel")}</p>
            </FlexBox>
          )}

          {type == 3 && (
            <FlexBox>
              <Label>{dictCk("rainDetection")}</Label>
              <ComboBox
                style={{ width: "160px" }}
                defaultValue={dictCk("select")}
                hasDefault={true}
                items={InterLockBoolList.map(item => ({
                  label: dictCk(item.label),
                  value: item.value
                }))}// 수정 24.03.13 by 강동우
                value={detectKind}
                setValue={setDetectKind}
              />
            </FlexBox>
          )}

          <FlexBox>
            <Label>{workType == 0 ? dictCk("onOff") : dictCk("openClose")}</Label>
            <ComboBox
              style={{ width: "160px" }}
              defaultValue={dictCk("select")}
              hasDefault={true}
              items={(workType === 0 ? InterLockOpenOnOffList : InterLockOpenList).map(item => ({
                label: dictCk(item.label),
                value: item.value 
              }))}// 수정 24.03.13 by 강동우
              value={statusKind}
              setValue={setStatusKind}
            />
          </FlexBox>

          {/* {workType != 0 && type != 4 && (
            <>
              <FlexBox>
                <Label>{t("sectionSetting")}</Label>
                <InputBox
                  style={{ width: "160px", marginRight: 0 }}
                  type="number"
                  useComma={false}
                  value={section}
                  onChange={(e) => setSection(e.target.value)}
                />
                <p style={{ marginLeft: "10px", color: "#555555e6", fontSize: "14px" }}>{t("times")}</p>
              </FlexBox>

              <FlexBox>
                <Label>{t("delayTime")}</Label>
                <InputBox
                  style={{ width: "160px", marginRight: 0 }}
                  type="number"
                  useComma={false}
                  value={delayTime}
                  onChange={(e) => setDelayTime(e.target.value)}
                />
                <p style={{ marginLeft: "10px", color: "#555555e6", fontSize: "14px" }}>{t("sec")}</p>
              </FlexBox>
            </>
          )} */}

          {type == 4 && (
            <>
              <FlexBox>
                <Label>{dictCk("weedSpeed")}</Label>
                <InputBox
                  style={{ width: "160px", marginRight: 0 }}
                  type="number"
                  useComma={false}
                  value={windSpeed}
                  onChange={(e) => setWindSpeed(e.target.value)}
                />
                <p style={{ marginLeft: "10px", color: "#555555e6", fontSize: "14px" }}>{dictCk("millisecond")}</p>
              </FlexBox>

              <FlexBox>
                <Label>{dictCk("windDirection")}</Label>
                <InputBox
                  style={{ width: "160px", marginRight: 0 }}
                  type="number"
                  useComma={false}
                  value={windVolume}
                  onChange={(e) => setWindVolume(e.target.value)}
                />
                <p style={{ marginLeft: "10px", color: "#555555e6", fontSize: "14px" }}>{dictCk("py")}</p>
              </FlexBox>
            </>
          )}
        </div>
        <ButtonBox>
          <Button bgColor={"#fff"} hover={"rgba(174, 174, 174, 0.22)"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={onClickCancel}>
            {dictCk("cancel")}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {dictCk("save")}
          </Button>
        </ButtonBox>
      </Container>
    </Dialog>
  )
}

const Container = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 23px 26px 40px 40px;
`
const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 40px;
  }
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  flex: 1;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

InterLockEditPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default InterLockSettingContainer(withTranslation()(InterLockEditPopup))
