import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  setPurchaseCostList,
  getPurchaseCostList,
  setPurchaseCost,
  getPurchaseCost,
  addPurchaseCost,
  editPurchaseCost,
  delPurchaseCost,
  setPurchaseCostDetail,
  getPurchaseCostDetail,
  delPurchaseCostDetail,
  downloadPurchaseExpendListExcel,
} = actions.PurchaseSpendAction
const { getComboList } = actions.SystemSettingAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _purchaseCostList: state.PurchaseSpendReducer._purchaseCostList,
  _purchaseCost: state.PurchaseSpendReducer._purchaseCost,
  _purchaseCostDetail: state.PurchaseSpendReducer._purchaseCostDetail,

  _codeList: state.SystemSettingReducer._codeList,
  _comboCropList: state.SystemSettingReducer._comboCropList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  setPurchaseCostList: (param) => dispatch(setPurchaseCostList(param)),
  getPurchaseCostList: (param) => dispatch(getPurchaseCostList(param)),
  setPurchaseCost: (param) => dispatch(setPurchaseCost(param)),
  getPurchaseCost: (param) => dispatch(getPurchaseCost(param)),
  addPurchaseCost: (param) => dispatch(addPurchaseCost(param)),
  editPurchaseCost: (param) => dispatch(editPurchaseCost(param)),
  delPurchaseCost: (param) => dispatch(delPurchaseCost(param)),
  setPurchaseCostDetail: (param) => dispatch(setPurchaseCostDetail(param)),
  getPurchaseCostDetail: (param) => dispatch(getPurchaseCostDetail(param)),
  delPurchaseCostDetail: (param) => dispatch(delPurchaseCostDetail(param)),
  downloadPurchaseExpendListExcel: (param) => dispatch(downloadPurchaseExpendListExcel(param)),

  getComboList: (param) => dispatch(getComboList(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
