import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  getAutoEnvWorkCenterList,
  getEquipControlList,
  initEquipControlForRevList,
  initEquipControlOnOffList,
  editEquipControlAuto,
  editEquipControlSetting,
  ctrlEquipControlSwitchMod,
  getEquipControlStatus,
  initEquipControlStatus,
} = actions.EnvControlAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _equipControlForRevList: state.EnvControlReducer._equipControlForRevList,
  _equipControlOnOffList: state.EnvControlReducer._equipControlOnOffList,
  _workCenterList: state.EnvControlReducer._autoEnvWorkCenterList,

  _equipControlStatus: state.EnvControlReducer._equipControlStatus,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getAutoEnvWorkCenterList: (param) => dispatch(getAutoEnvWorkCenterList(param)),
  getEquipControlList: (param) => dispatch(getEquipControlList(param)),
  initEquipControlForRevList: (param) => dispatch(initEquipControlForRevList(param)),
  initEquipControlOnOffList: (param) => dispatch(initEquipControlOnOffList(param)),
  editEquipControlAuto: (param) => dispatch(editEquipControlAuto(param)),
  editEquipControlSetting: (param) => dispatch(editEquipControlSetting(param)),
  ctrlEquipControlSwitchMod: (param) => dispatch(ctrlEquipControlSwitchMod(param)),
  getEquipControlStatus: (param) => dispatch(getEquipControlStatus(param)),
  initEquipControlStatus: (param) => dispatch(initEquipControlStatus(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
