// 시스템설정 > 코드정보
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import SystemSettingContainer from "../../../containers/SystemSettingContainer"
import PageName from "../../component/common/PageName"
import InputBox from "../../component/common/InputBox"
import { YellowButton, SkyButton } from "../../component/common/Button"
import { TableComponent } from "../../component/common/Table"

import CommonContainer from "../../../containers/CommonContainer" // 사전 가져오기 위해 import - 차건담 2023.02.15
//import Main from "../Main.jsx"
//import {dict} from "../../TranslationWords.js"

// 차건담 - 2023.01.30
import TransLang from "../../popup/SystemSetting/TransLang"

const CodeInfo = (props) => {
  const {
    _translationWords, // 사전 담기는 변수
    replaceWord, // {{n}}에 다른 단어로 대체하는 함수
    initTranslationWords,
    getTranslationWords,
    t,
    _userMajorCode,
    _userMinorCode,
    initUserMajorCode,
    getUserMajorCode,
    initUserMinorCode,
    getUserMinorCode,
    addUserMinorCode,
    editUserMinorCode,
    delUserMinorCode,

    // 다국어 대분류
    initUMajorSMajorSMinor,
    getUMajorSMajorSMinor, // 다국어 대분류 값 가져오는 함수 - 차건담 2023.01.30
    _userUMajorSMajorSMinor, // _userUDLanguageCodeMajor
    
    // 다국어 소분류
    _userUDLanguageCode,
    initUserUDLanguageCode,

    getUserDefLanguageCode,
    editUserDefLanguageCode,
    delUserDefLanguageCode,

    // 다국어 공통 - 차건담 2023.01.27
    initLanguageKind,
    getLanguageKind,
    _languageKind    
  } = props

  const [currentMajor, setCurrentMajor] = useState(null)
  const [majorName, setMajorName] = useState("")
  const [minorName, setMinorName] = useState("")
  const [minorList, setMinorList] = useState([])

  //유용준
  const [currentMinor, setCurrentMinor] = useState(null)
  const [UDLanguageList, setUDLanguageList] = useState([])
  
  // 차건담 - 2023.01.30
  const [isOpenPopupMajor, setisOpenPopupMajor] = useState(false)
  const [isOpenPopupMinor, setisOpenPopupMinor] = useState(false)

  const [checkSysUser, setCheckSysUser] = useState(2)       // 사용자/시스템 구분코드 - 차건담
  const [checkMajorMinor, setCheckMajorMinor] = useState([]) // 대분류/소분류 구분코드 - 차건담

  // 사용자코드 다국어 대분류 번역기능을 위한 useState - 차건담 2023.01.31
    const [currentMajorForTrans, setCurrentMajorForTrans] = useState(null)
    const [majorListForTran, setMajorListForTran] = useState([])

  // 사용자코드 다국어 소분류 번역기능을 위한 useState - 차건담 2023.02.07
  const [currentMinorForTrans, setCurrentMinorForTrans] = useState(null)
  const [minorListForTran, setMinorListForTran] = useState([])    

  const [finalTransWords, setFinalTransWords] = useState([])

  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {
    getTranslationWords()
    return async () => {
      await initUserMajorCode()
      await initUserMinorCode()
      await initUserUDLanguageCode()
      await initUMajorSMajorSMinor()
      setisOpenPopupMinor(false)
      setisOpenPopupMajor(false)
    }
  }, [])

  useEffect(() => {
    if (currentMajor == null || currentMajor == [] || currentMajor.length == 0) return
    getMinorData()
    setisOpenPopupMinor(false)
    setisOpenPopupMajor(false)
  }, [currentMajor])

  useEffect(() => {
    setMinorList(_userMinorCode)
    setisOpenPopupMinor(false)
    setisOpenPopupMajor(false)
  }, [_userMinorCode])



  // 사용자코드 다국어 대분류 번역 - 차건담 2023.01.31 ============================================================================
    useEffect(() => {
      if (currentMajorForTrans == null || currentMajorForTrans == [] || currentMajorForTrans.length == 0) return
      else {
        //console.log("dict", dict)
        //console.log("Main dict", Main)
        console.log("_translationWords: ", _translationWords)
        setCheckMajorMinor([1, currentMajorForTrans.seq])
      }
    }, [currentMajorForTrans])

    useEffect(() => {
      if (checkMajorMinor[0] != 1 || checkMajorMinor[1] == null || checkMajorMinor[1] == [] || checkMajorMinor[1].length == 0) return
      else {
        getWordsForTranslate()
        
      }
    }, [checkMajorMinor[1]])

    useEffect(() => {
      if (_userUMajorSMajorSMinor == null || _userUMajorSMajorSMinor == [] || _userUMajorSMajorSMinor.length == 0) {return}
      else {
        setMajorListForTran(_userUMajorSMajorSMinor)
        setFinalTransWords([])
      }
    }, [_userUMajorSMajorSMinor])

    useEffect(() => {
      if ( majorListForTran == null || majorListForTran == [] || majorListForTran.length == 0) return
      else {
        if (checkMajorMinor[0] == 1) {     
          onClickTransLang_major()
        }
      }
    }, [majorListForTran])

    useEffect(() => {
      if (finalTransWords == null || finalTransWords == [] || finalTransWords.length == 0) return
      else {
        console.log("CodeInfo > 대분류 > finalTransWords:", finalTransWords)
      }
    }, [finalTransWords])
  //============================================================================================================================




  // 사용자코드 다국어 소분류 번역 - 차건담 2023.02.07 ============================================================================
    useEffect(() => {
      if (currentMinorForTrans == null || currentMinorForTrans == [] || currentMinorForTrans.length == 0) return
      else {
        setCheckMajorMinor([2, currentMinorForTrans.seq])
      }
    }, [currentMinorForTrans])

    useEffect(() => {
      if (checkMajorMinor[0] != 2 || checkMajorMinor[1] == null || checkMajorMinor[1] == [] || checkMajorMinor[1].length == 0) return
      else {
        getWordsForTranslate()
      }
    }, [checkMajorMinor[1]])

    useEffect(() => {
      if (_userUDLanguageCode == null || _userUDLanguageCode == [] || _userUDLanguageCode.length == 0) {return}
      else {
        setMinorListForTran(_userUDLanguageCode)
      }
    }, [_userUDLanguageCode])

    useEffect(() => {
      if ( minorListForTran == null || minorListForTran == [] || minorListForTran.length == 0) return
      else {      
        onClickTransLang_minor()
      }
    }, [minorListForTran])

    useEffect(() => {
      if (finalTransWords == null || finalTransWords == [] || finalTransWords.length == 0) return
      else {
        if (checkMajorMinor[0] == 2) {setisOpenPopupMinor(true)}
        console.log("CodeInfo > 소분류 > finalTransWords:", finalTransWords)
      }
    }, [finalTransWords])
  //==============================================================================================================================




  // 추가(유용준)
    useEffect(() => {
      if (currentMinor == null) return
      getUserDefLanguageDataForEdit()
    }, [currentMinor])

    useEffect(() => {
      setUDLanguageList(_userUDLanguageCode)
    }, [_userUDLanguageCode]) 

  // 다국어 대분류 값 가져오기
  const getWordsForTranslate = async () => {
    if ( checkMajorMinor[0] == 1 ) {
      await getUMajorSMajorSMinor({ majorSeq: currentMajorForTrans.seq,
                                    minorSeq: '',
                                    checkSysUser: checkSysUser,
                                  })
    }
    else if (checkMajorMinor[0] == 2) {
      getUserDefLanguageData()
    }
  }

  const getUserDefLanguageData = async () => {
    await getUserDefLanguageCode({ minorSeq: currentMinorForTrans.seq})
  }

  const getUserDefLanguageDataForEdit = async () => {
    await getUserDefLanguageCode({ minorSeq: currentMinor.seq})
  }  
  //
  const getMinorData = async () => {
    await getUserMinorCode({ majorSeq: currentMajor.seq })
  }

  const onClickMajorSearch = async () => {
    await setCurrentMajor(null)
    await initUserMajorCode()
    await initUserMinorCode()
    const result = await getUserMajorCode({ majorName: majorName })
    if (result) setMajorName("")
  }

  const onClickMinorAdd = async () => {
    const result = await addUserMinorCode({
      majorSeq: currentMajor.majorSeq,
      minorName: minorName,
    })
    if (result) {
      setMinorName("")
      getMinorData()
    }
  }

  const onClickMinorEdit = async (seq, name) => {
    const result = await editUserMinorCode({
      minorSeq: seq,
      minorName: name,
    })
    if (result) getMinorData()
  }

  const onClickMinorDel = async (seq) => {
    const result = await delUserMinorCode({
      minorSeq: seq,
    })
    if (result) getMinorData()
  }

  // 차건담 - 2023.01.30
  const closePopupMajor = async () => {
    setisOpenPopupMajor(false)
    await getUserMajorCode({ majorName: majorName })
  }

  const closePopupMinor = async () => {
    setisOpenPopupMinor(false);
  }
  
  const onClickTransLang_major = async () => {
    setFinalTransWords(await getTransLangList(majorListForTran))
    setisOpenPopupMajor(true)
  }
  const onClickTransLang_minor = async () => {
    setFinalTransWords(await getTransLangList(minorListForTran))
  }

  // 언어종류 배열과 언어종류별 데이터 배열 합치는 함수 - 차건담 2023.01.30
  /*
     언어종류 배열: 번역 가능한 언어들(languageSeq)을 가지고 있다. 
     언어종류별 데이터 배열: 해당되는 단어의 언어별 단어를 가지고 있다. 번역되어있는 데이터만 존재
  */
  const getTransLangList = async (transLangList) => {
    let listFinal = []

    if (checkMajorMinor[0] == 1) {
      for(let i=0; i<_languageKind.length; i++){
        let isTranslated = 0;
        for(let k=0; k<transLangList.length; k++){
            if(_languageKind[i].languageSeq == transLangList[k].languageSeq) {
                isTranslated = 1;
                listFinal.push(Object.assign(_languageKind[i], transLangList[k]))
            }
        }
        if (isTranslated == 0) {
            listFinal.push(_languageKind[i])
        }
      }
    }
    else if (checkMajorMinor[0] == 2) {
      for(let i=0; i<_languageKind.length; i++){
        let isTranslated = 0;
        for(let k=0; k<transLangList.length; k++){
            if (_languageKind[i].languageSeq == transLangList[k].languageSeq) {
                isTranslated = 1;
                listFinal.push(Object.assign(_languageKind[i], transLangList[k]))
            }
        }

        if (isTranslated == 0) {
            listFinal.push(Object.assign(_languageKind[i], {minorSeq: currentMinorForTrans.minorSeq, checkTrans:1}))
        }
      }
    }
    return listFinal
  }

  // 값 초기화 함수 - 차건담 2023.02.01
  const initForMajor = async () => {
    setCurrentMajorForTrans(1)
    setMajorListForTran([])
    await initLanguageKind()
  }

  const initForMinor = async () => {
    getMinorData()
    setCurrentMinorForTrans(1)
    setMinorListForTran([])
    await initLanguageKind()
  }

  // 사용자 소분류 번역 수정 함수 - 차건담 2023.02.08
  const onClickUDLanguageEdit = async (minorSeq, languageSeq, word) => {
    console.log("minorSeq:",minorSeq,"   languageSeq: ",languageSeq,"    word: ",word)
      await editUserDefLanguageCode({ 
        minorSeq: minorSeq,
        languageSeq: languageSeq,
        word: word, 
      })
  }


  return (
    <Container>
      {/* 다국어 기능 팝업 화면 - 차건담 */}
      <TransLang open={isOpenPopupMajor} checkSysUser={checkSysUser}
                                         checkMajorMinor={checkMajorMinor[0]}
                                         finalTransWords={finalTransWords}
                                         setFinalTransWords={setFinalTransWords}
                                         currentMajorForTrans={currentMajorForTrans}
                                         onClose={() => {
                                                         closePopupMajor()
                                                         initForMajor()
                                                        }}/>
      <TransLang open={isOpenPopupMinor} checkSysUser={checkSysUser}
                                         checkMajorMinor={checkMajorMinor[0]}
                                         finalTransWords={finalTransWords}
                                         setFinalTransWords={setFinalTransWords}
                                         currentMinorForTrans={currentMinorForTrans}                                         
                                         onClose={() => {
                                                         closePopupMinor()
                                                         initForMinor()
                                                        }}/>

      <PageName name={dict['codeInfo']} state={false} setState={() => {}} />
      <FlexBox>
        <WhiteBox width={520}>
          <InputView>
            <Title>{dict['mainCategory']}</Title>
            <InputBox style={{ flex: 1 }} type="text" value={majorName} onChange={(e) => setMajorName(e.target.value)} />
            <YellowButton onClick={onClickMajorSearch}>{dict["search"]}</YellowButton>
            {/* <YellowButton onClick={onClickMajorSearch}>{dict["search"]}</YellowButton> */}
            {/* t("n.Invalid value. Please try again.", { n: t("regDate") }) */}
            {/* <YellowButton onClick={onClickMajorSearch}>{t("search")}</YellowButton> */}
          </InputView>
          <TableComponent
            headList={[
              { title: dict['codeNo'] , type: "number" },
              { title: dict['mainCategoryName'] , type: "text" },
              { title: dict['function'] },
            ]}
            bodyList={_userMajorCode.map((i, idx) => ({                 
              seq: i.majorSeq,
              majorSeq: i.majorSeq,
              majorName: i.majorName,
              Actions_transLang_userMajor: true,
            }))}
            dict={dict}
            onClick={(i) => setCurrentMajor(i)}
            onClickTransLang={(i) => {setCurrentMajorForTrans(i)
                                      , getLanguageKind() // 언어종류 가져오기 - 차건담 2023.02.09
                                    }}
          />
        </WhiteBox>
        <Box>
          <WhiteBox width={520} marginLeft={10}>
            <InputView>
              <Title>{dict['subCategory']}</Title>
              <InputBox style={{ flex: 1 }} type="text" value={minorName} onChange={(e) => setMinorName(e.target.value)} />
              <SkyButton onClick={()=>{if(currentMajor != null && currentMajor != undefined){onClickMinorAdd()}}}>{dict['add']}</SkyButton>
            </InputView>
            <TableComponent
              headList={[{ title: dict['subCategoryCode'], type: "number" }, { title: dict['subCategoryName'], type: "text" }, { title: dict['function'] }]}
              bodyList={minorList.map((i, idx) => ({
                seq: i.minorSeq,
                minorSeq: i.minorSeq,
                minorName: (
                  <TableInput
                    id={`minor${i.minorSeq}`}
                    type="text"
                    value={i.minorName}
                    onChange={(e) => {
                      const temp = [...minorList]
                      temp[idx].minorName = e.target.value
                      setMinorList(temp)
                    }}
                    />
                    ),
                Actions_transLang_userMinor: true,
                }))}
              dict={dict}
              onClick={(i) => {setCurrentMinor(i)}}
              onClickEdit  ={(seq) => {
                                       onClickMinorEdit(seq, document.getElementById(`minor${seq}`).value)
                                       onClickUDLanguageEdit(seq, "", document.getElementById(`minor${seq}`).value)
                                      }}
              onClickDelete={(seq) => {
                                        delUserDefLanguageCode({
                                          minorSeq: seq,
                                          languageSeq: "",  
                                        })
                                        onClickMinorDel(seq)
                                      }}
              onClickTransLang={(i) => {setCurrentMinorForTrans(i), getLanguageKind() // 언어종류 가져오기 - 차건담 2023.02.09
                                       }}
            />
          </WhiteBox>
        </Box>
      </FlexBox>
      {console.log("userMinorCode=",props._userMinorCode)}
      {console.log("minorList=",minorList)}
      {console.log("currentMinorForTrans=",currentMinorForTrans)}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  overflow-y: hidden;
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 28px;
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width}px;
  height: 100%;
  margin-left: ${(props) => props.marginLeft}px;
  padding: 28px 35px 20px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const InputView = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Title = styled.p`
  width: 72px;
  color: #555555;
  font-size: 14px;
`
const TableInput = styled.input`
  width: 100%;
  height: 35px;
  padding: 0px 15px;
`

export default SystemSettingContainer(CommonContainer(withTranslation()(CodeInfo)))