// 현황정보 > 함수율데이터조회
import React from "react"
import { withTranslation } from "react-i18next"
import IncomeInquiryContainer from "../../../containers/IncomeInquiry/IncomeInquiryContainer"

const MoistureDataInquiry = () => {
  return <div></div>
}

export default IncomeInquiryContainer(withTranslation()(MoistureDataInquiry))
