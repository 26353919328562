import api from "../utils/api"

// 이미지 업로드
const uploadImage = async (data) => {
  const result = await api.post(`/upload/image`, { body: data })
  return result.data
}
// 이미지 업로드 멀티
const uploadImages = async (data) => {
  const result = await api.post(`/upload/images`, { body: data })
  return result.data
}

export default { uploadImage, uploadImages }
