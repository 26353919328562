import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const { initCropCloseList, getCropCloseList, editCropClose, downloadCropCloseListExcel } = actions.CropManageAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _cropCloseList: state.CropManageReducer._cropCloseList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  initCropCloseList: (param) => dispatch(initCropCloseList(param)),
  getCropCloseList: (param) => dispatch(getCropCloseList(param)),
  editCropClose: (param) => dispatch(editCropClose(param)),
  downloadCropCloseListExcel: (param) => dispatch(downloadCropCloseListExcel(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
