import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
    //조건제어[복합]-김한중
    GET_CONF_LISTComp,
    GET_CONF_INFOComp,
    GET_CONF_INFOCompDetail,
    ADD_CONF_INFOComp,
    MOD_CONF_INFOComp,
    MOD_CONF_INFOCompuseYN,
    DEL_CONF_INFOComp,
    //조건제어[복합]-김한중

    //조건제어[복합]-목표값 조건변경
    getConfReSetting,
    addConfReSetting,
    editConfReSetting,
} = actions.EnvControlAction

// redux state > component mapping
const mapStateToProps = (state) => ({
    //조건제어[복합]-김한중
    //  _comboEquipList: state.SystemSettingReducer._comboEquipList,
    //  _codeList: state.SystemSettingReducer._codeList,

    confListComp: state.CommonReducer.confListComp || GET_CONF_LISTComp.initData,
    GET_CONF_LISTComp,
    confInfoComp: state.CommonReducer.confInfoComp || GET_CONF_INFOComp.initData,
    GET_CONF_INFOComp,
    confInfoCompDetail: state.CommonReducer.confInfoCompDetail || GET_CONF_INFOCompDetail.initData,
    GET_CONF_INFOCompDetail,
    ADD_CONF_INFOComp,
    MOD_CONF_INFOComp,
    MOD_CONF_INFOCompuseYN,
    DEL_CONF_INFOComp,
    //조건제어[복합]-김한중

    //조건제어[복합]-목표값 조건변경
    _confReSettingInfo: state.EnvControlReducer._confReSettingInfo,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
    //조건제어[복합]-목표값 조건변경
    getConfReSetting: (param) => dispatch(getConfReSetting(param)),
    addConfReSetting: (param) => dispatch(addConfReSetting(param)),
    editConfReSetting: (param) => dispatch(editConfReSetting(param)),
})

const WithHoc = (ChildComponent) => (props) => {
    return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
