// 환경제어 > 자동환경설정 정/역
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import AutoEnvSettingOnOffContainer from "../../../containers/envControl/AutoEnvSettingOnOffContainer"
import PageName from "../../component/common/PageName"
import { SkyButton } from "../../component/common/Button"
import { TableComponent } from "../../component/common/Table"
import AutoEnvOnOffAddPopup from "../../popup/EnvControl/AutoEnvOnOffAddPopup"
import AutoEnvOnOffExAddPopup from "../../popup/EnvControl/AutoEnvOnOffExAddPopup"
import TableCheckBox from "../../component/common/TableCheckBox"
import Tab from "../../component/common/Tab"

const AutoEnvSettingOnOff = (props) => {
  const {
    _autoEnvWorkCenterList,
    _autoEnvEquipList,
    _autoEnvEquip,
    initAutoEnvWorkCenterList,
    getAutoEnvWorkCenterList,
    initAutoEnvEquipList,
    getAutoEnvEquipList,
    initAutoEnvEquip,
    getAutoEnvEquip,
  } = props
  const {
    t,
    addAutoEnvConfEquipOnOff,
    initAutoEnvConfEquipOnOff,
    getAutoEnvConfEquipOnOff,
    editAutoEnvConfEquipOnOff,
    delAutoEnvConfEquipOnOff,
    addAutoEnvConfEquipOnOffEx,
    initAutoEnvConfEquipOnOffEx,
    getAutoEnvConfEquipOnOffEx,
    editAutoEnvConfEquipOnOffEx,
    delAutoEnvConfEquipOnOffEx,
  } = props
  const [autoEnvOnOffAddPopup, setAutoEnvOnOffAddPopup] = useState(false)
  const [autoEnvOnOffExAddPopup, setAutoEnvOnOffExAddPopup] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [equipIndex, setEquipIndex] = useState(0)

  useEffect(() => {
    getWorkCenterList()

    return async () => {
      await initAutoEnvWorkCenterList()
      await initAutoEnvEquipList()
      await initAutoEnvEquip()
    }
  }, [])

  useEffect(() => {
    if (_autoEnvWorkCenterList.length > 0) {
      setTabIndex(_autoEnvWorkCenterList[0].workCenterSeq)
      getEquipList(_autoEnvWorkCenterList[0].workCenterSeq)
    }
  }, [_autoEnvWorkCenterList])

  useEffect(() => {
    if (_autoEnvEquipList.length > 0) {
      setEquipIndex(_autoEnvEquipList[0].equipSeq)
      getEquipInfo(_autoEnvEquipList[0].equipSeq)
    }
  }, [_autoEnvEquipList])

  const getWorkCenterList = async () => {
    await getAutoEnvWorkCenterList()
  }

  const getEquipList = async (workCenterSeq) => {
    await getAutoEnvEquipList({ workCenterSeq, type: 2 })
  }

  const getEquipInfo = async (equipSeq) => {
    await getAutoEnvEquip({ equipSeq })
  }

  // 자동환경 추가 버튼
  const onClickAdd = async () => {
    await initAutoEnvConfEquipOnOff()
    setAutoEnvOnOffAddPopup(true)
  }
  // 자동환경 수정 버튼
  const onClickEdit = async (seq) => {
    const result = await getAutoEnvConfEquipOnOff({ envConfEquipOnOffSeq: seq })
    if (result) setAutoEnvOnOffAddPopup(true)
  }
  // 자동환경 삭제 버튼
  const onClickDel = async (seq) => {
    const result = await delAutoEnvConfEquipOnOff({ envConfEquipOnOffSeq: seq })
    if (result) getEquipInfo(equipIndex)
  }

  // 자동환경예외 추가 버튼
  const onClickExAdd = async () => {
    await initAutoEnvConfEquipOnOffEx()
    setAutoEnvOnOffExAddPopup(true)
  }
  // 자동환경예외 수정 버튼
  const onClickExEdit = async (seq) => {
    const result = await getAutoEnvConfEquipOnOffEx({ envConfEquipOnOffExSeq: seq })
    if (result) setAutoEnvOnOffExAddPopup(true)
  }
  // 자동환경예외 삭제 버튼
  const onClickExDel = async (seq) => {
    const result = await delAutoEnvConfEquipOnOffEx({ envConfEquipOnOffExSeq: seq })
    if (result) getEquipInfo(equipIndex)
  }

  const autoEnvOnOffAddPopupHandler = async (type, value) => {
    if (type == t("save")) {
      let result = false
      if (value.envConfEquipOnOffSeq === undefined) {
        result = await addAutoEnvConfEquipOnOff({
          equipSeq: equipIndex,
          sensorKind: value.sensorKind,
          valueKind: value.kind,
          tgValueFr: value.from,
          tgValueTo: value.to,
        })
      } else {
        result = await editAutoEnvConfEquipOnOff({
          envConfEquipOnOffSeq: value.envConfEquipOnOffSeq,
          sensorKind: value.sensorKind,
          valueKind: value.kind,
          tgValueFr: value.from,
          tgValueTo: value.to,
        })
      }

      if (result) getEquipInfo(equipIndex)
    }
    setAutoEnvOnOffAddPopup(false)
  }

  const autoEnvOnOffExPopupHandler = async (type, value) => {
    if (type == t("save")) {
      let result = false
      if (value.envConfEquipOnOffExSeq == undefined) {
        result = await addAutoEnvConfEquipOnOffEx({
          equipSeq: equipIndex,
          equipSeqEx: value.equipKind,
          workStatus: value.status,
          valueKind: value.kind,
          tgValueFr: value.from,
          tgValueTo: value.to,
          isAlarm: value.isAlarm,
        })
      } else {
        result = await editAutoEnvConfEquipOnOffEx({
          envConfEquipOnOffExSeq: value.envConfEquipOnOffExSeq,
          equipSeq: equipIndex,
          equipSeqEx: value.equipKind,
          workStatus: value.status,
          valueKind: value.kind,
          tgValueFr: value.from,
          tgValueTo: value.to,
          isAlarm: value.isAlarm,
        })
      }

      if (result) getEquipInfo(equipIndex)
    }
    setAutoEnvOnOffExAddPopup(false)
  }

  return (
    <Container>
      <AutoEnvOnOffAddPopup open={autoEnvOnOffAddPopup} onClose={autoEnvOnOffAddPopupHandler} />
      <AutoEnvOnOffExAddPopup open={autoEnvOnOffExAddPopup} onClose={autoEnvOnOffExPopupHandler} />
      <PageName name={t("autoEnvSettingOnOff")} state={false} setState={() => {}} />
      <Tab
        list={_autoEnvWorkCenterList.map((i) => ({ seq: i.workCenterSeq, name: i.workCenterName }))}
        tabIndex={tabIndex}
        onClick={(i) => {
          getEquipList(i.seq)
          setTabIndex(i.seq)
          setEquipIndex(0)
        }}
      />
      <Box>
        {_autoEnvEquipList.length > 0 && tabIndex > 0 && (
          <Left>
            {_autoEnvEquipList.map((i, idx) => (
              <button
                key={idx}
                style={{
                  backgroundColor: equipIndex == i.equipSeq ? "#fff" : "#d8d8d81a",
                  display: "block",
                  width: "100%",
                  height: "37px",
                  paddingLeft: "20px",
                  border: equipIndex == i.equipSeq ? "1px solid #52935d" : "none",
                  color: "#555555",
                  fontSize: "13px",
                  fontWeight: equipIndex == i.equipSeq ? 700 : 500,
                  textAlign: "left",
                }}
                onClick={() => {
                  getEquipInfo(i.equipSeq)
                  setEquipIndex(i.equipSeq)
                }}
              >
                {i.equipName}
              </button>
            ))}
          </Left>
        )}
        {_autoEnvEquipList.length > 0 && equipIndex > 0 && (
          <Right>
            <RightTop>
              <FlexBox justifyContent={"flex-end"}>
                <SkyButton onClick={onClickAdd}>{t("add")}</SkyButton>
              </FlexBox>
              <TableComponent
                headList={[
                  { title: `${t("no")}` },
                  { title: `${t("sensorKind")}`, type: "text" },
                  { title: `${t("kind")}`, type: "text" },
                  { title: `${t("from")}`, type: "number" },
                  { title: `${t("to")}`, type: "number" },
                  { title: `${t("actions")}` },
                ]}
                bodyList={(_autoEnvEquip.envConfOnOffList || []).map((i, idx) => ({
                  seq: i.envConfEquipOnOffSeq,
                  no: _autoEnvEquip.envConfOnOffList.length - idx,
                  sensorKindName: i.sensorKindName,
                  valueKindName: i.valueKindName,
                  tgValueFr: i.tgValueFr,
                  tgValueTo: i.tgValueTo,
                  Actions: true,
                }))}
                onClickEdit={(seq) => onClickEdit(seq)}
                onClickDelete={(seq) => onClickDel(seq)}
              />
            </RightTop>
            <RightBottom>
              <FlexBox justifyContent={"space-between"}>
                <p style={{ color: "#555555b3", fontSize: "14px" }}>
                  {t("Control exception condition (No control if the following conditions are applicable)")}
                </p>
                <SkyButton onClick={onClickExAdd}>{t("add")}</SkyButton>
              </FlexBox>
              <TableComponent
                headList={[
                  { title: `${t("no")}` },
                  { title: `${t("equipKind")}`, type: "text" },
                  { title: `${t("status")}`, type: "text" },
                  { title: `${t("kind")}`, type: "text" },
                  { title: `${t("from")}`, type: "number" },
                  { title: `${t("to")}`, type: "number" },
                  { title: `${t("alarmSetting")}` },
                  { title: `${t("actions")}` },
                ]}
                bodyList={(_autoEnvEquip.envConfOnOffExList || []).map((i, idx) => ({
                  seq: i.envConfEquipOnOffExSeq,
                  no: _autoEnvEquip.envConfOnOffExList.length - idx,
                  equipName: i.equipName,
                  workStatusName: i.workStatusName,
                  valueKindName: i.valueKindName,
                  tgValueFr: i.tgValueFr,
                  tgValueTo: i.tgValueTo,
                  isAlarm: <TableCheckBox check={i.isAlarm == 1} setCheck={() => {}} />,
                  Actions: true,
                }))}
                onClickEdit={(seq) => onClickExEdit(seq)}
                onClickDelete={(seq) => onClickExDel(seq)}
              />
            </RightBottom>
          </Right>
        )}
      </Box>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  &:after {
    content: "";
    display: block;
    height: 42px;
    width: 100%;
  }
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  display: flex;
  margin-top: 30px;
`
const Left = styled.div`
  background-color: #fff;
  width: 260px;
  height: fit-content;
  padding: 14px 12px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const Right = styled.div`
  flex: 1;
`
const RightTop = styled.div`
  background-color: #fff;
  width: 754px;
  height: 350px;
  margin: 0 23px;
  padding: 28px 35px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const RightBottom = styled.div`
  background-color: #fff;
  width: 100%;
  height: 350px;
  margin: 30px 23px 0;
  padding: 28px 35px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`

export default AutoEnvSettingOnOffContainer(withTranslation()(AutoEnvSettingOnOff))
