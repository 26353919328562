// 센서노드 > 센서 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import SensorNodeContainer from "../../../containers/defaultInfo/SensorNodeContainer"

import RequiredMark from "../../component/RequiredMark"
import TableCheckBox from "../../component/common/TableCheckBox"
import ComboBox from "../../component/common/ComboBox"
import InputBox from "../../component/common/InputBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import AlertPopup from "../AlertPopup"

import CommonContainer from "../../../containers/CommonContainer" // 다국어 사전 가져오기 위해 import - 차건담 2023.03.02

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        margin: "0 auto",
        "& .MuiDialog-paperFullWidth": {
            width: "100%",
        },
        "& .MuiPaper-root": {
            backgroundColor: "#fff",
            maxWidth: "615px",
        },
        "& .MuiDialog-paper": {
            margin: "0px",
        },
    },
}))

const SensorAddPopup = (props) => {
    const classes = useStyles()
    const { t, _codeList, _sensor } = props
    const { onClose, open, currentSensorNode } = props
    const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
    const [sensorKind, setSensorKind] = useState(0)
    const [sensorName, setSensorName] = useState("")
    // const [sensorNo, setSensorNo] = useState("")
    const [sensorVolt, setSensorVolt] = useState(0)
    const [sensorUnit, setSensorUnit] = useState(0)
    const [measureRange, setMeasureRange] = useState("")
    const [errorRange, setErrorRange] = useState("")
    const [modelName, setModelName] = useState("")
    const [maker, setMaker] = useState("")
    const [isUse, setIsUse] = useState(true)
    const [etc, setEtc] = useState("")
    const [sensorType, setSensorType] = useState("")

    // 사전 관련 객체 가져오기 - 차건담 2023.03.02
    const { initTranslationWords, getTranslationWords, _translationWords, replaceWord } = props

    useEffect(() => {
        getTranslationWords() // 사전 가져오는 함수 - 차건담 2023.03.02
        if (open && _sensor && Object.keys(_sensor).length != 0) {
            setSensorKind(_sensor.sensorKind || 0)
            setSensorName(_sensor.sensorName)
            // setSensorNo(_sensor.sensorNo)
            setSensorVolt(_sensor.sensorVolt || 0)
            setSensorUnit(_sensor.sensorUnitSeq || 0)
            setMeasureRange(_sensor.measureRange)
            setErrorRange(_sensor.errorRange)
            setModelName(_sensor.modelName)
            setMaker(_sensor.maker)
            setIsUse(_sensor.isUse == 1)
            setEtc(_sensor.remark)
            setSensorType(_sensor.sensorType)
        }

        return () => {
            setSensorKind(0)
            setSensorName("")
            // setSensorNo("")
            setSensorVolt(0)
            setSensorUnit(0)
            setMeasureRange("")
            setErrorRange("")
            setModelName("")
            setMaker("")
            setIsUse(true)
            setEtc("")
            setSensorType("")
        }
    }, [open])

    let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.02.28

    const dictCk = (word) => {
        if (dict == undefined || dict == null || dict == {} || dict == "") {
            return word
        } else {
            const result = dict[word] != undefined && dict[word] != null && dict[word] != "" ? dict[word] : word
            return result
        }
    }

    const onClickSave = async () => {
        if (sensorType == 0) return setAlertPopup({ open: true, text: rplaceWord(dictCk("n.Please select a product."), dictCk("sensorType")) })
        else if (sensorKind == 0) return setAlertPopup({ open: true, text: rplaceWord(dictCk("n.Please select a product."), dictCk("sensorKind")) })
        else if (sensorName == "") return setAlertPopup({ open: true, text: rplaceWord(dictCk("n.Please select a product."), dictCk("sensorName")) })
        else if (sensorUnit == 0) return setAlertPopup({ open: true, text: rplaceWord(dictCk("n.Please select a product."), dictCk("sensorUnit")) })
        else if (Math.sign(errorRange) != 1 && Math.sign(errorRange) != -1 && Math.sign(errorRange) != 0)
            return setAlertPopup({ open: true, text: replaceWord(dictCk("제대로 된 숫자를 눌러주세요."), dictCk("errorRange")) })

        // console.log(typeof Math.sign(errorRange))

        const param = {
            sensorNodeSeq: currentSensorNode.seq,
            sensorKind: sensorKind,
            sensorName,
            // sensorSeq,
            sensorVolt: sensorVolt,
            sensorUnit: sensorUnit,
            measureRange: measureRange || 0,
            errorRange: errorRange || 0,
            modelName,
            maker,
            isUse: isUse ? 1 : 0,
            remark: etc,
            sensorType,
        }
        if (_sensor) param.sensorSeq = _sensor.sensorSeq

        onClose(t("save"), param)
    }

    return (
        <Dialog
            className={classes.container}
            style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
            onClose={() => onClose(null)}
            open={open}
            fullWidth={true}
        >
            <AlertPopup
                bg={false}
                open={alertPopup.open}
                onClose={() => setAlertPopup({ open: false, text: alertPopup.text })}
                content={alertPopup.text}
            />

            <div
                style={{
                    backgroundColor: "#fff",
                    overflowY: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    padding: "23px 51px 44px 40px",
                }}
            >
                <Title>{dictCk("sensorInfo")}</Title>
                <div style={{ flex: 1 }}>
                    <FlexBox>
                        <Label>
                            <RequiredMark /> {dictCk("sensorType")}
                        </Label>
                        <ComboBox
                            style={{ width: "300px" }}
                            defaultValue={dictCk("select")}
                            hasDefault={true}
                            items={
                                _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1023).length > 0
                                    ? _codeList.filter((i) => i.majorSeq == 1023)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                                    : []
                            }
                            value={sensorType}
                            setValue={setSensorType}
                        />
                    </FlexBox>
                    <FlexBox>
                        <Label>
                            <RequiredMark /> {dictCk("sensorKind")}
                        </Label>
                        <ComboBox
                            style={{ width: "300px" }}
                            defaultValue={dictCk("select")}
                            hasDefault={true}
                            items={
                                _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1015).length > 0
                                    ? _codeList.filter((i) => i.majorSeq == 1015)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                                    : []
                            }
                            value={sensorKind}
                            setValue={setSensorKind}
                        />
                    </FlexBox>
                    <FlexBox>
                        <Label>
                            <RequiredMark /> {dictCk("sensorName")}
                        </Label>
                        <InputBox
                            style={{ width: "300px", marginRight: 0 }}
                            type="text"
                            value={sensorName}
                            onChange={(e) => setSensorName(e.target.value)}
                        />
                    </FlexBox>
                    {/* <FlexBox>
            <Label>
              <RequiredMark /> {dictCk("sensorNo")}
            </Label>
            <InputBox style={{ width: "300px", marginRight: 0 }} type="text" value={sensorNo} onChange={(e) => setSensorNo(e.target.value)} />
          </FlexBox> */}

                    <FlexBox>
                        <Label>{dictCk("equipVolt")}</Label>
                        <ComboBox
                            style={{ width: "300px" }}
                            defaultValue={dictCk("select")}
                            hasDefault={true}
                            items={
                                _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1016).length > 0
                                    ? _codeList.filter((i) => i.majorSeq == 1016)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                                    : []
                            }
                            value={sensorVolt}
                            setValue={setSensorVolt}
                        />
                    </FlexBox>
                    <FlexBox>
                        <Label>
                            <RequiredMark /> {dictCk("sensorUnit")}
                        </Label>
                        <ComboBox
                            style={{ width: "300px" }}
                            defaultValue={dictCk("select")}
                            hasDefault={true}
                            items={
                                _codeList.length > 0 && _codeList.filter((i) => i.majorKind == 1 && i.majorSeq == 1010).length > 0
                                    ? _codeList
                                          .filter((i) => i.majorKind == 1 && i.majorSeq == 1010)[0]
                                          .minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                                    : []
                            }
                            value={sensorUnit}
                            setValue={setSensorUnit}
                        />
                    </FlexBox>
                    <FlexBox>
                        <Label>{dictCk("measureRange")}</Label>
                        <InputBox
                            style={{ width: "110px", marginRight: 0 }}
                            type="number"
                            value={measureRange}
                            onChange={(e) => setMeasureRange(e.target.value)}
                        />
                    </FlexBox>
                    <FlexBox>
                        <Label>{dictCk("errorRange")}</Label>
                        <InputBox
                            style={{ width: "110px", marginRight: 0 }}
                            type="text"
                            value={errorRange}
                            onChange={(e) => setErrorRange(e.target.value)}
                        />
                    </FlexBox>
                    <FlexBox>
                        <Label>{dictCk("modelName")}</Label>
                        <InputBox
                            style={{ width: "200px", marginRight: 0 }}
                            type="text"
                            value={modelName}
                            onChange={(e) => setModelName(e.target.value)}
                        />
                    </FlexBox>
                    <FlexBox>
                        <Label>{dictCk("maker")}</Label>
                        <InputBox style={{ width: "200px", marginRight: 0 }} type="text" value={maker} onChange={(e) => setMaker(e.target.value)} />
                    </FlexBox>
                    <FlexBox>
                        <Label>{dictCk("isUse")}</Label>
                        <TableCheckBox check={isUse} setCheck={setIsUse} />
                    </FlexBox>
                    <FlexBox alignItems="flex-start">
                        <Label>{dictCk("remark")}</Label>
                        <TextAreaBox width={0} value={etc} setValue={setEtc} />
                    </FlexBox>
                </div>

                <ButtonBox>
                    <Button
                        bgColor={"#fff"}
                        hover={"rgba(174, 174, 174, 0.22)"}
                        border={"1px solid #c6c6c6"}
                        color={"#555555cc"}
                        onClick={() => onClose(t("cancel"), null)}
                    >
                        {dictCk("cancel")}
                    </Button>
                    <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
                        {dictCk("save")}
                    </Button>
                </ButtonBox>
            </div>
        </Dialog>
    )
}

const Title = styled.p`
    opacity: 80%;
    margin-bottom: 23px;
    color: #555555;
    font-size: 15px;
    font-weight: 700;
    line-height: 32px;
`
const FlexBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: ${(props) => props.alignItems || "center"};
    margin-bottom: 14px;
`
const Label = styled.p`
    opacity: 80%;
    width: 122px;
    color: #555555;
    font-size: 14px;
    line-height: 34px;
`
const ButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
const Button = styled.button`
    background-color: ${(props) => props.bgColor};
    width: 147px;
    height: 40px;
    border: ${(props) => props.border};
    border-radius: 3px;
    color: ${(props) => props.color};
    font-size: 14px;

    &:last-child {
        margin-left: 14px;
    }

    &:hover {
        background-color: ${(props) => props.hover};
    }
`

SensorAddPopup.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

export default CommonContainer(SensorNodeContainer(withTranslation()(SensorAddPopup)))
