import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  getProductionHarvList,
  getProductionHarvWorkCenterList,
  getProductionDayList,
  setProductionHarvList,
  setProductionHarvWorkCenterList,
  setProductionDayList,
} = actions.ProductionAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _productionHarvList: state.ProductionReducer._productionHarvList,
  _productionHarvWorkCenterList: state.ProductionReducer._productionHarvWorkCenterList,
  _productionDayList: state.ProductionReducer._productionDayList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getProductionHarvList: (param) => dispatch(getProductionHarvList(param)),
  getProductionHarvWorkCenterList: (param) => dispatch(getProductionHarvWorkCenterList(param)),
  getProductionDayList: (param) => dispatch(getProductionDayList(param)),
  setProductionHarvList: (param) => dispatch(setProductionHarvList(param)),
  setProductionHarvWorkCenterList: (param) => dispatch(setProductionHarvWorkCenterList(param)),
  setProductionDayList: (param) => dispatch(setProductionDayList(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
