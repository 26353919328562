// 현황정보 > 병해충발행분석조회
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import { withTranslation } from "react-i18next"

import CommonContainer from "../../../containers/CommonContainer"
import IncomeInquiryContainer from "../../../containers/IncomeInquiry/IncomeInquiryContainer"

import AlertPopup from "../../popup/AlertPopup"

import PageName from "../../component/common/PageName"
import ChartLineBox from "../../component/common/ChartLineBox"
import ChartPieBox from "../../component/common/ChartPieBox"

import RequiredMark from "../../component/RequiredMark"
import { TableComponent } from "../../component/common/Table"
import MultiComboBox from "../../component/common/MultiComboBox"
import ComboBox from "../../component/common/ComboBox"
import DateBox from "../../component/common/DateBox"
import InputBox from "../../component/common/InputBox"
import { YellowButton } from "../../component/common/Button"
import BasicPopup from "../../popup/BasicPopup"

const PestAnalysisInquiry = (props) => {

  const { t, initDataList, getDataList, saveDataList, setDataList, getComboList, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props

  // const { workCenterComboList, sensorNodeComboList, sensorComboList } = props

  const {

    pestDataList: dataList,
    PEST_DATA_LIST: actionType,

  } = props

  const [stdYear, setStdYear] = useState(moment().format("YYYY"))
  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {

    getTranslationWords()
    getData()

    return async () => await initDataList(actionType)

  }, [])

  const getData = async () => {

    await getComboList({ queryKind: "WorkCenter" })
    await getComboList({ queryKind: "SensorNode" })
    await getComboList({ queryKind: "Sensor" })

  }

  const onClickSearch = async () => {

    const data = {
      stdYear
    }

    await getDataList(actionType, data)

  }

  return (
    <Container>

      <FlexBox justifyContent={"space-between"}>
        <PageName name={dict['pestIssuanceAnalysisInquiry']} state={false} setState={() => {}} />
      </FlexBox>
      <Box>
        <WhiteBox>
          <SearchBox>
            <FlexBox>
              <Text>{dict['yearOfInquiry']}</Text>
              <InputBox useComma={false} style={{ width: "100px", marginRight: "5px" }} type="number" value={stdYear} onChange={(e) => setStdYear(e.target.value)} mask={'####'} />
            </FlexBox>
            <YellowButton onClick={()=>onClickSearch()}>{dict['search']}</YellowButton>
          </SearchBox>


          <div style={{ display: 'flex', justifyContent: 'space-between', height: '90%' }} >

            {dataList.lineData && <ChartLineBox labels={[]} datas={dataList.lineData} />}
            {dataList.pieData && <ChartPieBox labels={[]} datas={dataList.pieData} />}

            {/* <ChartLineBox labels={[1,2,3,4,5,6,7,8,9,10,11,12]} datas={[
              {label : 'a1', datas : [1,1,1,1,1]},
              {label : 'a2', datas : [1,2,3,4,5]},
              {label : 'a3', datas : [1,3,3,2,1]}
            ]} />
            
            <ChartPieBox labels={['a1','a2','a3']} datas={[10,20,30]} /> */}

          </div>

        </WhiteBox>
      </Box>
    </Container>
  )
}

export default IncomeInquiryContainer(CommonContainer(withTranslation()(PestAnalysisInquiry)))

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  margin-top: 19px;
  padding: 28px 32px 20px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`

const ClassesBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`