// 작기관리 > 표준작기정보
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import moment from "moment"
import StandardCropInfoContainer from "../../../containers/cropManage/StandardCropInfoContainer"
import PageName from "../../component/common/PageName"
import {YellowButton, SkyButton } from "../../component/common/Button"
import InputBox from "../../component/common/InputBox"
import { TableComponent } from "../../component/common/Table"
import Tab from "../../component/common/Tab"
import BasicPopup from "../../popup/BasicPopup"
import StdCropAddPopup from "../../popup/CropManage/StdCropAddPopup"
import StdCropGrowthAddPopup from "../../popup/CropManage/StdCropGrowthAddPopup"
import StdCropPestAddPopup from "../../popup/CropManage/StdCropPestAddPopup"
import StdCropEnvAddPopup from "../../popup/CropManage/StdCropEnvAddPopup"
import StdCropHRMAddPopup from "../../popup/CropManage/StdCropHRMAddPopup"

import CommonContainer from "../../../containers/CommonContainer"

import CodeHelpBox from "../../component/common/CodeHelpBox"

const StandardCropInfo = (props) => {
  const { t, getCodeHelpItem, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  const { _stdCropList, initStdCropList, getStdCropList, addStdCrop, initStdCrop, getStdCrop, editStdCrop, delStdCrop } = props
  const {
    _stdCropGrowthList,
    initStdCropGrowthList,
    getStdCropGrowthList,
    addStdCropGrowth,
    initStdCropGrowth,
    getStdCropGrowth,
    editStdCropGrowth,
    delStdCropGrowth,
  } = props
  const {
    _stdCropPestList,
    initStdCropPestList,
    getStdCropPestList,
    addStdCropPest,
    initStdCropPest,
    getStdCropPest,
    editStdCropPest,
    delStdCropPest,
  } = props
  const {
    _stdCropEnvList,
    initStdCropEnvList,
    getStdCropEnvList,
    addStdCropEnv,
    initStdCropEnv,
    getStdCropEnv,
    editStdCropEnv,
    delStdCropEnv,
  } = props
  const {
    _stdCropHRMList,
    initStdCropHRMList,
    getStdCropHRMList,
    addStdCropHRM,
    initStdCropHRM,
    getStdCropHRM,
    editStdCropHRM,
    delStdCropHRM,
  } = props
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [stdCropAddPopup, setStdCropAddPopup] = useState(false)
  const [stdCropGrowthAddPopup, setStdCropGrowthAddPopup] = useState(false)
  const [stdCropPestAddPopup, setStdCropPestAddPopup] = useState(false)
  const [stdCropEnvAddPopup, setStdCropEnvAddPopup] = useState(false)
  const [stdCropHRMAddPopup, setStdCropHRMAddPopup] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [currentStdCrop, setCurrentStdCrop] = useState(null)
  const [currentStdCropGrowth, setCurrentStdCropGrowth] = useState(null)

  const [cropName, setCropName] = useState("")
  const [itemName, setItemName] = useState("")

  const dict = _translationWords[0] // 변수에 사전 담기
  //
  useEffect(() => {
    getTranslationWords()
    getData()

    return async () => {
      const pro1 = await initStdCropList()
      const pro2 = await initStdCropGrowthList()
      const pro3 = await initStdCropPestList()
      const pro4 = await initStdCropEnvList()
      const pro5 = await initStdCropHRMList()
      Promise.all([pro1, pro2, pro3, pro4, pro5])
    }
  }, [])

  const getData = async () => {
    const pro1 = await getStdCropList({cropName, itemName: typeof itemName == 'object' ? itemName['label'] : itemName}) // codeHelp 사용으로 JSON 객체의 label값 사용 - 차건담 2023.08.02
    const pro2 = await getCodeHelpItem({ queryWord: "" })
    Promise.all([pro1, pro2])
  }

  const getGrowthList = async (stdCropSeq) => {
    const pro1 = await getStdCropGrowthList({ stdCropSeq })
    const pro2 = await getStdCropPestList({ stdCropGrowthSeq: 0 })
    const pro3 = await getStdCropEnvList({ stdCropGrowthSeq: 0 })
    const pro4 = await getStdCropHRMList({ stdCropGrowthSeq: 0 })
    Promise.all([pro2, pro3, pro4, pro1])
  }

  const getPestList = async (stdCropGrowthSeq) => {
    await getStdCropPestList({ stdCropGrowthSeq })
  }

  const getEnvList = async (stdCropGrowthSeq) => {
    await getStdCropEnvList({ stdCropGrowthSeq })
  }
  
  const getHRMList = async (stdCropGrowthSeq) => {
    await getStdCropHRMList({ stdCropGrowthSeq })
  }

  const onClickGrowth = async (item) => {
    setCurrentStdCropGrowth(item)
    const pro1 = await getStdCropPestList({ stdCropGrowthSeq: item.seq })
    const pro2 = await getStdCropEnvList({ stdCropGrowthSeq: item.seq })
    const pro3 = await getStdCropHRMList({ stdCropGrowthSeq: item.seq })
    Promise.all([pro1, pro2, pro3])
  }

  const onClickSearch = async () => {
    // const a = async () => {
    //   setItemName(itemName['label'])
    // }
    // a().then
    getData()    
  }

  // 작기정보
  const onClickAdd = async () => {
    await initStdCrop()
    setStdCropAddPopup(true)
  }
  const onClickEdit = async (seq) => {
    const result = await getStdCrop({ stdCropSeq: seq })
    if (result) setStdCropAddPopup(true)
  }
  const onClickDel = async (seq) => {
    const result = await delStdCrop({ stdCropSeq: seq })
    if (result) {getData()
      if(currentStdCrop != null)getGrowthList(currentStdCrop.seq)
    }
  }
  const stdCropAddPopupHandler = async (type, value) => {
    if (type == dict['save']) {
      let result = false
      if (value.stdCropSeq == undefined) {
        result = await addStdCrop(value)
      } else {
        result = await editStdCrop(value)
      }
      if (result) {
        let result2 = getData()
        if(currentStdCrop != null)getGrowthList(currentStdCrop.seq)
        if (result2) setStdCropAddPopup(false)
      }
    } else if (type == dict['cancel']) {
      setStdCropAddPopup(false)
    }
  }

  // 생육정보
  const onClickAddGrowth = async () => {
    if (!currentStdCrop) return setPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['stdCropInfo'])})
    await initStdCropGrowth()
    setStdCropGrowthAddPopup(true)
  }
  const onClickEditGrowth = async (seq) => {
    const result = await getStdCropGrowth({ stdCropGrowthSeq: seq })
    if (result) setStdCropGrowthAddPopup(true)
  }
  const onClickDelGrowth = async (seq) => {
    const result = await delStdCropGrowth({ stdCropSeq: currentStdCrop.seq, stdCropGrowthSeq: seq })
    if (result) getGrowthList(currentStdCrop.seq)
    getData()
  }
  const stdCropGrowthAddPopupHandler = async (type, value) => {
    if (type == dict['save']) {
      let result = false
      if (value.stdCropGrowthSeq == undefined) {
        result = await addStdCropGrowth({ stdCropSeq: currentStdCrop.seq, ...value })
      } else {
        result = await editStdCropGrowth({ stdCropSeq: currentStdCrop.seq, ...value })
      }
      if (result) {
        getGrowthList(currentStdCrop.seq)
        getData()
      }
    }
    setStdCropGrowthAddPopup(false)
  }

  // 병해충정보
  const onClickAddPest = async () => {
    if (!currentStdCropGrowth) return setPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['stdCropGrowth'])})
    await initStdCropPest()
    setStdCropPestAddPopup(true)
  }
  const onClickEditPest = async (seq) => {
    if (!currentStdCropGrowth) return setPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['stdCropGrowth'])})
    const result = await getStdCropPest({ stdCropPestSeq: seq })
    if (result) setStdCropPestAddPopup(true)
  }
  const onClickDelPest = async (seq) => {
    const result = await delStdCropPest({ stdCropPestSeq: seq })
    if (result) getPestList(currentStdCropGrowth.seq)
  }
  const stdCropPestAddPopupHandler = async (type, value) => {
    if (type == dict['save']) {
      let result = false
      if (value.stdCropPestSeq == undefined) {
        result = await addStdCropPest({ stdCropGrowthSeq: currentStdCropGrowth.seq, ...value })
      } else {
        result = await editStdCropPest(value)
      }
      if (result) getPestList(currentStdCropGrowth.seq)
    }
    setStdCropPestAddPopup(false)
  }

  // 환경정보
  const onClickAddEnv = async () => {
    if (!currentStdCropGrowth) return setPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['stdCropGrowth']) })
    await initStdCropEnv()
    setStdCropEnvAddPopup(true)
  }
  const onClickEditEnv = async (seq) => {
    if (!currentStdCropGrowth) return setPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['stdCropGrowth']) })
    const result = await getStdCropEnv({ stdCropEnvSeq: seq })
    if (result) setStdCropEnvAddPopup(true)
  }
  const onClickDelEnv = async (seq) => {
    const result = await delStdCropEnv({ stdCropEnvSeq: seq })
    if (result) getEnvList(currentStdCropGrowth.seq)
  }
  const stdCropEnvAddPopupHandler = async (type, value) => {
    if (type == dict['save']) {
      let result = false
      if (value.stdCropEnvSeq == undefined) {
        result = await addStdCropEnv({ stdCropGrowthSeq: currentStdCropGrowth.seq, ...value })
      } else {
        result = await editStdCropEnv(value)
      }
      if (result) getEnvList(currentStdCropGrowth.seq)
    }
    setStdCropEnvAddPopup(false)
  }

  // 인력관리정보
  const onClickAddHRM = async () => {
    if (!currentStdCropGrowth) return setPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['stdCropGrowth']) })
    await initStdCropHRM()
    setStdCropHRMAddPopup(true)
  }
  const onClickEditHRM = async (seq) => {
    console.log('currentStdCropGrowth : ', currentStdCropGrowth)
    if (!currentStdCropGrowth) return setPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['stdCropGrowth']) })
    const result = await getStdCropHRM({ stdCropHRMSeq: seq })
    if (result) setStdCropHRMAddPopup(true)
  }
  const onClickDelHRM = async (seq) => {
    const result = await delStdCropHRM({ stdCropHRMSeq: seq })
    if (result) getHRMList(currentStdCropGrowth.seq)
  }
  const stdCropHRMAddPopupHandler = async (type, value) => {
    if (type == dict['save']) {
      let result = false
      if (value.stdCropHRMSeq == undefined) {
        result = await addStdCropHRM({ stdCropGrowthSeq: currentStdCropGrowth.seq, ...value })
      } else {
        result = await editStdCropHRM(value)
      }
      if (result) getHRMList(currentStdCropGrowth.seq)
    }
    setStdCropHRMAddPopup(false)
  }

  return (
    <Container>
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: popup.text })} content={popup.text} selectedValue={[dict['confirm']]} />
      <StdCropAddPopup open={stdCropAddPopup} onClose={stdCropAddPopupHandler} />
      <StdCropGrowthAddPopup open={stdCropGrowthAddPopup} onClose={stdCropGrowthAddPopupHandler} currentStdCrop={currentStdCrop} />
      <StdCropPestAddPopup open={stdCropPestAddPopup} onClose={stdCropPestAddPopupHandler} />
      <StdCropEnvAddPopup open={stdCropEnvAddPopup} onClose={stdCropEnvAddPopupHandler} />
      <StdCropHRMAddPopup open={stdCropHRMAddPopup} onClose={stdCropHRMAddPopupHandler} />
      <PageName name={dict['stdCropInfo']} state={false} setState={() => {}} />

      <Box>
        <WhiteBox padding="0px 34px 32px;" height={350}>

            <div style={{ display: "flex", height: "22px", alignItems: "center", marginTop: "16px"}}>
                <TableName>{dict['defaultInfo']}</TableName>
            </div>
          <FlexBox justifyContent={"space-between"}>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <FlexBox style={{ marginTop: "16px", marginLeft: "25px" }}>
               {/* 작기명 */}
               <Text>{dict['cropName']}</Text>
               <InputBox style={{ width: "280px" }} type="text" value={cropName} onChange={(e) => setCropName(e.target.value)} />
               {/* <CodeHelpBox // inputBox에서 CodeHelpBox로 변경 - 차건담 2023.07.13
                style={{}}
                placeholder={dict['cropName']}
                codeHelpSeq={3}
                // codeHelpParam={{}}
                value={cropName}
                setValue={setCropName}
                setUseState={setCodeHelpCropData}
               /> */}
               {/* 품명 */}
               <Text>{dict['itemName']}</Text>
               {/* <InputBox style={{ width: "280px" }} type="text" value={itemName} onChange={(e) => setItemName(e.target.value)} /> */}
               <CodeHelpBox // inputBox에서 CodeHelpBox로 변경 - 차건담 2023.07.13
                style={{}}
                placeholder={dict['itemName']}
                codeHelpSeq={1}
                // codeHelpParam={{}}
                value={itemName}
                setValue={setItemName}
               />
               </FlexBox>
            </div>
            <div style={{ marginTop: "16px" }}>
              <YellowButton onClick={onClickSearch}>{dict['search']}</YellowButton>
              <SkyButton style={{ marginLeft: "14px" }} onClick={onClickAdd}>{dict['add']}</SkyButton>
            </div>
          </FlexBox>


          <TableComponent
            headList={[
              { title: dict['ranking'] },
              { title: dict['stdCropName'], type: "text" },
              { title: dict['itemName'], type: "text" },
              { title: dict['cropDateFr'] },
              { title: dict['cropDateTo'] },
              { title: dict['remark'], type: "text" },
              { title: dict["actions"] }, // 수정 2024.03.18 by 강동우 
              //{ title: `${t("actions")}` },
            ]}
            bodyList={_stdCropList.map((i, idx) => ({
              seq: i.stdCropSeq,
              // no: _stdCropList.length - idx,
              no: 1 + idx,
              stdCropName: i.stdCropName,
              itemName: i.itemName,
              cropDateFr: i.cropDateFr && i.cropDateTo != 0 ? moment(i.cropDateFr, "MMDD").format("MM-DD") : "",
              cropDateTo: i.cropDateTo && i.cropDateTo != 0 ? moment(i.cropDateTo, "MMDD").format("MM-DD") : "",
              remark: i.remark,
              Actions: true,
            }))}
            dict={dict}
            onClick={(item) => {
              setCurrentStdCropGrowth(null)
              setCurrentStdCrop(item)
              getGrowthList(item.seq)
            }}
            onClickEdit={(seq) => onClickEdit(seq)}
            onClickDelete={(seq) => onClickDel(seq)}
          />
        </WhiteBox>
      </Box>

      <Box>
        <WhiteBox height={740}>
          <FlexBox justifyContent={"space-between"}>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ display: "flex", height: "32px", alignItems: "center" }}>
                <TableName>{dict['stdCropGrowth']}</TableName>
              </div>
              {/* 생육정보 작기명 */}
              <LabelComponent
                style={{ marginTop: "16px", marginLeft: "30px" }}
                title={dict['stdCropName']}
                value={(currentStdCrop && currentStdCrop.stdCropName) || ""}
              />
              {/* 생육정보 품명 */}
              <LabelComponent
                style={{ marginTop: "16px", marginLeft: "30px" }}
                title={dict['itemName']}
                value={(currentStdCrop && currentStdCrop.itemName) || ""}
              />
            </div>

            <div style={{ marginTop: "16px" }}>
              <SkyButton onClick={onClickAddGrowth}>{dict['add']}</SkyButton>
            </div>
          </FlexBox>

          <TableContainer>
            <TableComponent
              headList={[
                { title: dict['ranking'] },
                { title: dict['growthStep'] },
                { title: dict['dateFr'] },
                { title: dict['elapsedDays'], type: "number" },
                { title: dict['importWork'], type: "text" },
                { title: dict['isHarv'], type: "check" },
                { title: dict['isPlant'], type: "check" },
                { title: dict['checkPoint'], type: "text" },
                { title: dict['remark'], type: "text" },
                { title: dict["actions"] }, // 수정 2024.03.18 by 강동우 
                //{ title: `${t("actions")}` },
              ]}
              bodyList={_stdCropGrowthList.map((i, idx) => ({
                seq: i.stdCropGrowthSeq,
                // no: _stdCropGrowthList.length - idx,
                no: 1 + idx,
                growthStepName: i.growthStepName,
                dateFr: i.dateFr && moment(i.dateFr, "MMDD").format("MM-DD"),
                date: i.date,
                work: i.work,
                isHarv: i.isHarv,
                isPlant: i.isPlant,
                checkPoint: i.checkPoint,
                remark: i.remark,
                Actions: true,
              }))}
              dict={dict}
              onClick={onClickGrowth}
              onClickEdit={(seq) => onClickEditGrowth(seq)}
              onClickDelete={(seq) => onClickDelGrowth(seq)}
            />
          </TableContainer>

          <FlexBox justifyContent="space-between" style={{ marginTop: "14px", marginLeft: "82px" }}>
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              {/* 생육정보 생육단계 */}
              <LabelComponent 
              title={dict['growthStep']} 
              value={(currentStdCropGrowth && currentStdCropGrowth.growthStepName) || ""} 
              />
              {/* 생육정보 주요작업 */}
              <LabelComponent2
                style={{ marginLeft: "30px" }}
                title={dict['importWork']}
                value={(currentStdCropGrowth && currentStdCropGrowth.work) || ""}
              />
            </div>

            {/* <LabelComponent title={t("growthStep")} value={(currentStdCropGrowth && currentStdCropGrowth.growthStepName) || ""} /> */}
            <SkyButton
              onClick={() => {
                if (tabIndex == 0) onClickAddPest()
                else if (tabIndex == 1) onClickAddEnv()
                else if (tabIndex == 2) onClickAddHRM()
              }}
            >
              {dict['add']}
            </SkyButton>
          </FlexBox>

          <Tab
            style={{ marginTop: "23px" }}
            list={[
              { seq: 0, name: dict['cropPest'] },
              { seq: 1, name: dict['env'] },
              { seq: 2, name: dict['staffManagement'] },
            ]}
            tabIndex={tabIndex}
            onClick={(i) => {
              setTabIndex(i.seq)
            }}
          />
          <TableContainer>
            {tabIndex == 0 && (
              <TableComponent
                style={{ marginTop: 0 }}
                headList={[
                  { title: dict['ranking'] },
                  { title: dict['diseaseKind'] },
                  { title: dict['diseaseClass'] },
                  { title: dict['content'], type: "text" },
                  { title: dict['remark'], type: "text" },
                  { title: dict["actions"] }, // 수정 2024.03.18 by 강동우 
                  //{ title: `${t("actions")}` },
                ]}
                bodyList={_stdCropPestList.map((i, idx) => ({
                  seq: i.stdCropPestSeq,
                  // no: _stdCropPestList.length - idx,
                  no: 1 + idx,
                  diseaseKindName: i.diseaseKindName,
                  diseaseClassName: i.diseaseClassName,
                  content: i.content,
                  remark: i.remark,
                  Actions: true,
                }))}
                dict={dict}
                onClickEdit={(seq) => onClickEditPest(seq)}
                onClickDelete={(seq) => onClickDelPest(seq)}
              />
            )}
            {tabIndex == 1 && (
              <TableComponent
                style={{ marginTop: 0 }}
                headList={[
                  { title: dict['ranking'] },
                  { title: dict['dayNightInfo'] },
                  { title: dict['envKind'] },
                  { title: dict['sensorUnit'] },
                  { title: dict['minValue'] },
                  { title: dict['maxValue'] },
                  { title: dict['remark'], type: "text" },
                  { title: dict["actions"] }, // 수정 2024.03.18 by 강동우 
                  //{ title: `${t("actions")}` },
                ]}
                bodyList={_stdCropEnvList.map((i, idx) => ({
                  seq: i.stdCropEnvSeq,
                  // no: _stdCropEnvList.length - idx,
                  no: 1 + idx,
                  dayNightName: i.dayNightName,
                  envKindName: i.envKindName,
                  sensorUnitName: i.sensorUnitName,
                  minValue: i.minValue,
                  maxValue: i.maxValue,
                  remark: i.remark,
                  Actions: true,
                }))}
                dict={dict}
                onClickEdit={(seq) => onClickEditEnv(seq)}
                onClickDelete={(seq) => onClickDelEnv(seq)}
              />
            )}
            {tabIndex == 2 && (
              <TableComponent
                style={{ marginTop: 0 }}
                headList={[
                  { title: dict['ranking'] },
                  { title: dict['necessaryPersonnel'] },
                  { title: dict['necessaryDays'] },
                  { title: dict['remark'], type: "text" },
                  { title: dict["actions"] }, // 수정 2024.03.18 by 강동우 
                  //{ title: `${t("actions")}` },
                ]}
                bodyList={_stdCropHRMList.map((i, idx) => ({
                  seq: i.stdCropHRMSeq,
                  // no: _stdCropHRMList.length - idx,
                  no: 1 + idx,
                  reqNumPeople: i.reqNumPeople,
                  reqNumDays: i.reqNumDays,
                  remark: i.remark,
                  Actions: true,
                }))}
                dict={dict}
                onClickEdit={(seq) => onClickEditHRM(seq)}
                onClickDelete={(seq) => onClickDelHRM(seq)}
              />
            )}
          </TableContainer>
        </WhiteBox>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  &:after {
    content: "";
    display: block;
    height: 42px;
    width: 100%;
  }
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  height: ${(props) => props.height}px;
  margin-top: 12px;
  padding: ${(props) => (props.padding ? props.padding : "12px 34px 32px")};
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`

const TableNameDiv = styled.p`
  display: flex;
  justify-content: flex-start;
`
const TableName = styled.p`
  color: #555555b3;
  font-size: 14px;
  font-weight: 700;
`
const TableContainer = styled.div`
  overflow: hidden;
  display: flex;
  max-height: 227px;
`
const Text = styled.p`
  width: 60px;
  color: #555555;
  font-size: 14px;
`

const LabelComponent = ({ style = {}, title, value }) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", ...style }}>
      <p style={{ color: "#555555e6", fontSize: "14px" }}>{title}</p>
      <div
        style={{
          backgroundColor: "#edf4eb",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "280px",
          height: "40px",
          marginLeft: "20px",
          padding: "0 15px",
          border: "1px solid #dedede",
          borderRadius: "3px",
        }}
      >
        {value}
      </div>
    </div>
  )
}

const LabelComponent2 = ({ style = {}, title, value }) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", ...style }}>
      <p style={{ color: "#555555e6", fontSize: "14px" }}>{title}</p>
      <div
        style={{
          backgroundColor: "#edf4eb",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "820px",
          height: "40px",
          marginLeft: "20px",
          padding: "0 15px",
          border: "1px solid #dedede",
          borderRadius: "3px",
        }}
      >
        {value}
      </div>
    </div>
  )
}

export default StandardCropInfoContainer(CommonContainer(withTranslation()(StandardCropInfo)))
