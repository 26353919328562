// 센서 목표값 설정 팝업 - 차건담 || 최종 수정 날짜 - 2023.06.16
import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import { AutoEnvTimeKindList } from "../../../utils/data"
import ChartContainer from "../../../containers/statusInfo/ChartContainer"
import CommonContainer from "../../../containers/CommonContainer"
import RequiredMark from "../../component/RequiredMark"
import ComboBox from "../../component/common/ComboBox"
import InputBox from "../../component/common/InputBox"
import InputTimeBox from "../../component/common/InputTimeBox"
import AlertPopup from "../AlertPopup"
import moment from "moment"

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        maxWidth: "1000px",
        margin: "0 auto",
        "& .MuiDialog-paperFullWidth": {
            width: "100%",
        },
        "& .MuiPaper-root": {
            backgroundColor: "#fff",
        },
        "& .MuiDialog-paper": {
            margin: "0px",
        },
    },
}))

const SensorTargetValuePopup = (props) => {
    const classes = useStyles()
    const { t, sensorNodeList, sensorKindComboList, targetValueSensorKind, setTargetValueSensorKind } = props
    const { open, onClose, type } = props // 1: specified time, 2: sunrise/sunset
    const { insertTargetValueList, initTargetValueList, getTargetValueList, _targetValueList, isPopup } = props

    const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
    const [tgValue, setTgValue] = useState("")

    const [targetSensorKindDropIndex, setTargetSensorKindDropIndex] = useState("")
    const [recievedSensorKind, setRecievedSensorKind] = useState("")

    const [targetValueListFinal, setTargetValueListFinal] = useState({})

    const [checkInputBoxChanged, setCheckInpuBoxChanged] = useState(0) // 값 입력하는 박스에 값이 입력되었는 지 확인하는 변수

    const [checkTargetValueListFinalChanged, setCheckTargetValueListFinalChanged] =
        useState(0) /* targetValueListFinal 의 값 구성이 바뀌었는 지 확인하는 변수
                                                                                                   위 변수는 선택된 sensorKind에 따라 각 노드의 목표값들이 담기는데
                                                                                                   유저가 입력 박스에 값을 입력할 때 마다 수정된 전체 값 리스트가 변수에 저장된다*/

    // 저장하는 함수 >> TSensorTargetValue 테이블에 저장
    /*
            동작 방식
            1. 전달 받은 sensorNodeList(내부1, 내부2, 외부,...)의 길이만큼 반복문을 실행한다.
            2. targetValueListFinal에서 각 센서노드의 sensorNodeSeq와 일치하는 데이터를 추출한다.
            3. targetValueListFinal에서 sensorNodeSeq, targetValueMax(목표 최대값), targetValueMin(목표 최소값)을 추출하여 최종적으로 Back에 전달할 데이터를 구성한다.
            4. 3번의 데이터를 targetValueDataList 배열에 push 한다.
            5. targetValueDataList를 JSON 형태로 insertTargetValueList 함수에 인자로 전달한다.
    */
    const onClickSave = async () => {
        let targetValueDataList = []
        for (let i in sensorNodeList) {
            let targetValueData = targetValueListFinal[sensorNodeList[i]["sensorNodeSeq"]]
            // console.log("targetValueData:", targetValueData)
            const data = {
                sensorNodeSeq: targetValueData.sensorNodeSeq,
                sensorKind: targetSensorKindDropIndex == "" ? "" : targetSensorKindDropIndex,
                targetValueMax:
                    targetValueData.targetValueMax == "" || targetValueData.targetValueMax == null ? null : Number(targetValueData.targetValueMax),
                targetValueMin:
                    targetValueData.targetValueMin == "" || targetValueData.targetValueMax == null ? null : Number(targetValueData.targetValueMin),
            }

            targetValueDataList.push(data)
        }
        insertTargetValueList({ targetValueDataList: targetValueDataList })
        onClose()
    }

    const onClickCancel = () => {
        onClose(t("cancel"), null)
    }

    // 각 센서노드의 최대 목표값, 최소 목표값 입력 상자에 값을 할당하는 함수
    /*
            함수 생성 이유 : inputBox 컴포넌트 사용 시 sensorKind에 따라 값을 가져온 뒤 입력 상자에
                            값을 입력하면 가져온 값이 모두 지워지는 오류 발생.
                            
                             그래서 상자에 값이 입력될 때 마다 inputBox의 onChange 속성의 내부 함수에
                            상자 값들을 데이터로 가지고 있는 변수인 targetValueListFinal을 업데이트
                            하면서 해당 변수의 값을 다시 각 입력상자에 부여하여 화면에서 수정될 때 마다
                            업데이트 된 targetValueListFinal의 값이 보여지도록 하였음
    */
    const setInputBoxes = async () => {
        // console.log("get in setInputBoxes()")
        // console.log("line 97 targetValueListFinal:", targetValueListFinal)
        for (let i in sensorNodeList) {
            let inputBox1 = document.querySelector(".targetValue01" + String(sensorNodeList[i]["sensorNodeSeq"]))
            let inputBox2 = document.querySelector(".targetValue02" + String(sensorNodeList[i]["sensorNodeSeq"]))
            const targetValueData = targetValueListFinal[sensorNodeList[i]["sensorNodeSeq"]]
            // console.log("sensorNodeList:", sensorNodeList)
            // console.log("inputBox1, inputBox2", inputBox1, inputBox2)
            if (inputBox1 != null && inputBox2 != null) {
                inputBox1.innerHTML = targetValueData["targetValueMax"]
                inputBox1.value = targetValueData["targetValueMax"]
                inputBox2.innerHTML = targetValueData["targetValueMin"]
                inputBox2.value = targetValueData["targetValueMin"]
                inputBox1.setAttribute("value", targetValueData["targetValueMax"])
                inputBox2.setAttribute("value", targetValueData["targetValueMin"])
            }
        }
    }

    // 각 센서노드의 Label 및 입력 상자 태그를 생성하는 함수
    /*
        InputBox의 onChange
        1. 새로 입력된 값을 그 즉시 targetValueListFinal에 업데이트 해주면서 checkInputBoxChanged 변수의 값을 변경
            > 위와 같이 하는 이유는 setInputBoxes의 함수 생성 이유 참조 - line 87~
    */
    function generateTargetValueTags(sensorNodeList) {
        console.log("get in generateTargetValueTags()")
        const rows = []
        for (let i = 0; i < sensorNodeList.length; i++) {
            const data = sensorNodeList[i]

            const row = (
                <div id="inputBoxList" key={i} style={{ display: "flex", marginBottom: "10px" }}>
                    <Label style={{ flex: 1, width: "100px", marginRight: "50px", fontSize: "17px" }}>{data.sensorNodeName}</Label>
                    <Label style={{ width: "60px", fontSize: "15px", marginRight: "10px" }}>최대값:</Label>
                    <InputBox
                        className={"targetValue01" + String(data.sensorNodeSeq)}
                        style={{ flex: 1, width: "100px" }}
                        type="number"
                        onChange={(e) => {
                            setTgValue(e.target.value)
                            targetValueListFinal[data.sensorNodeSeq]["targetValueMax"] = e.target.value
                            setTargetValueListFinal(targetValueListFinal)
                            setCheckInpuBoxChanged(checkInputBoxChanged + 1)
                        }}
                    />
                    <Label style={{ width: "60px", fontSize: "15px", marginRight: "10px" }}>최소값:</Label>
                    <InputBox
                        className={"targetValue02" + String(data.sensorNodeSeq)}
                        style={{ flex: 1, width: "100px" }}
                        type="number"
                        onChange={(e) => {
                            setTgValue(e.target.value)
                            targetValueListFinal[data.sensorNodeSeq]["targetValueMin"] = e.target.value
                            setTargetValueListFinal(targetValueListFinal)
                            setCheckInpuBoxChanged(checkInputBoxChanged == 1 ? checkInputBoxChanged + 1 : checkInputBoxChanged - 1)
                        }}
                    />
                </div>
            )
            rows.push(row)
        }
        return <div style={{ display: "flex", flexDirection: "column" }}>{rows}</div>
    }

    useEffect(() => {
        // console.log("targetValueSensorKind ???? :", targetValueSensorKind)
        if (targetValueSensorKind != "" || targetValueSensorKind.length > 0) {
            // console.log("targetValueSensorKind >>>>>>> ", targetValueSensorKind)
            setTargetSensorKindDropIndex(targetValueSensorKind)
        }
    }, [targetValueSensorKind])

    // 센서노드리스트 길이만큼 targetValueList 변수의 껍데기 데이터를 생성
    useEffect(() => {
        console.log("get in useEffect[sensorNodeList]")
        if (sensorNodeList.length > 0 && sensorNodeList != []) {
            let targetValueList = {}

            for (let i in sensorNodeList) {
                targetValueList[sensorNodeList[i]["sensorNodeSeq"]] = {
                    sensorNodeSeq: sensorNodeList[i]["sensorNodeSeq"],
                    targetValueMax: "",
                    targetValueMin: "",
                }
            }

            setTargetValueListFinal(targetValueList)
        }
    }, [sensorNodeList])

    // 화면에서 센서 종류 선택할 때 마다 센서에 맞는 데이터 가져오는 함수 실행
    useEffect(() => {
        console.log("get in useEffect[targetSensorKindDropIndex]")
        if (targetSensorKindDropIndex != "" && targetSensorKindDropIndex != null && isPopup == 1) {
            getTargetValueList({ sensorKind: targetSensorKindDropIndex })
            setTargetSensorKindDropIndex(targetSensorKindDropIndex)
        }
    }, [targetSensorKindDropIndex])

    // getTargetValueList 함수가 실행되고 나면 _targetValueList의 값이 변경되며 아래의 useEffect 실행
    /*
            _targetValueList에 데이터가 존재하면 해당하는 값을 각 입력상자에 보여지도록 할당하고,
            데이터가 존재하지 않으면 모든 입력 상자에 null 값을 할당함
    */
    useEffect(() => {
        console.log("get in useEffect[_targetValueList]")
        if (isPopup == 1) {
            if (targetValueSensorKind > 10000) {
                setRecievedSensorKind(targetValueSensorKind)
                if (targetSensorKindDropIndex == "") {
                    setTargetSensorKindDropIndex(targetValueSensorKind)
                }
            }
            if (
                _targetValueList != [] &&
                _targetValueList != null &&
                _targetValueList != undefined &&
                _targetValueList.length > 0
                // _targetValueList[0].targetValueMax != null &&
                // _targetValueList[0].targetValueMin != null
            ) {
                // console.log("@@@@@@@@@@@@@@@@_targetValueList::", _targetValueList)
                for (let i in _targetValueList) {
                    if (sensorNodeList.length > 0 && sensorNodeList != [] && targetValueListFinal != {}) {
                        targetValueListFinal[_targetValueList[i]["sensorNodeSeq"]]["targetValueMax"] =
                            _targetValueList[i].targetValueMax == null ? null : _targetValueList[i].targetValueMax
                        targetValueListFinal[_targetValueList[i]["sensorNodeSeq"]]["targetValueMin"] =
                            _targetValueList[i].targetValueMin == null ? null : _targetValueList[i].targetValueMin
                    }
                    let inputBox1 = document.querySelector(".targetValue01" + String(_targetValueList[i]["sensorNodeSeq"]))
                    let inputBox2 = document.querySelector(".targetValue02" + String(_targetValueList[i]["sensorNodeSeq"]))

                    if (inputBox1 != null && inputBox2 != null) {
                        inputBox1.innerHTML = _targetValueList[i]["targetValueMax"]
                        inputBox1.value = _targetValueList[i]["targetValueMax"]
                        inputBox2.innerHTML = _targetValueList[i]["targetValueMin"]
                        inputBox2.value = _targetValueList[i]["targetValueMin"]
                        inputBox1.setAttribute("value", _targetValueList[i]["targetValueMax"])
                        inputBox2.setAttribute("value", _targetValueList[i]["targetValueMin"])
                    }
                }
            } else {
                for (let i in sensorNodeList) {
                    if (sensorNodeList.length > 0 && sensorNodeList != [] && targetValueListFinal != {}) {
                        targetValueListFinal[sensorNodeList[i]["sensorNodeSeq"]]["targetValueMax"] = null
                        targetValueListFinal[sensorNodeList[i]["sensorNodeSeq"]]["targetValueMin"] = null
                    }
                    let inputBox1 = document.querySelector(".targetValue01" + String(sensorNodeList[i]["sensorNodeSeq"]))
                    let inputBox2 = document.querySelector(".targetValue02" + String(sensorNodeList[i]["sensorNodeSeq"]))

                    if (inputBox1 != null && inputBox2 != null) {
                        inputBox1.innerHTML = null
                        inputBox1.value = null
                        inputBox2.innerHTML = null
                        inputBox2.value = null
                        inputBox1.setAttribute("value", null)
                        inputBox2.setAttribute("value", null)
                    }
                }
            }
            setTargetValueListFinal(targetValueListFinal)
            setCheckTargetValueListFinalChanged(1)
        }
    }, [_targetValueList])

    // checkTargetValueListFinalChanged 변수의 값이 변경되면 입력 setInputBoxes() 함수를 실행
    useEffect(() => {
        console.log("get in useEffect[checkTargetValueListFinalChanged]")
        setInputBoxes()
        setCheckTargetValueListFinalChanged(0)
    }, [checkTargetValueListFinalChanged])

    // 유저가 입력상자에 값을 입력하면 setInputBoxes() 함수를 실행
    useEffect(() => {
        console.log("get in useEffect[checkInputBoxChanged]")
        if (checkInputBoxChanged != 0) {
            setInputBoxes()
        }
    }, [checkInputBoxChanged])

    // 팝업창이 열리고 닫힐 때 마다 targetSensorKindDropIndex 초기화
    useEffect(() => {
        if (isPopup != 1) {
            setTargetSensorKindDropIndex("")
        }
    }, [isPopup])

    return (
        <Dialog
            className={classes.container}
            style={{ position: "absolute", width: "100%", height: "100%", zIndex: 101 }}
            onClose={() => {
                onClose(null), setTargetValueSensorKind("")
            }}
            open={open}
            fullWidth={true}
        >
            <AlertPopup
                bg={false}
                open={alertPopup.open}
                onClose={() => setAlertPopup({ open: false, text: alertPopup.text })}
                content={alertPopup.text}
            />
            <Container>
                <Title style={{}}>센서 목표값 설정</Title>
                <ComboBox
                    style={{ width: "100%", marginBottom: "20px" }}
                    defaultValue={t("select")}
                    hasDefault={true}
                    items={sensorKindComboList.map((i) => ({ value: i.sensorKind, label: i.sensorName }))}
                    value={targetSensorKindDropIndex}
                    setValue={setTargetSensorKindDropIndex}
                />
                <div style={{ flex: 1 }}>
                    <FlexBox>{generateTargetValueTags(sensorNodeList)}</FlexBox>
                </div>
                <ButtonBox>
                    <Button
                        bgColor={"#fff"}
                        hover={"rgba(174, 174, 174, 0.22)"}
                        border={"1px solid #c6c6c6"}
                        color={"#555555cc"}
                        onClick={onClickCancel}
                    >
                        {t("cancel")}
                    </Button>
                    <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
                        {t("save")}
                    </Button>
                </ButtonBox>
            </Container>
        </Dialog>
    )
}

const Container = styled.div`
    background-color: #fff;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 23px 51px 44px 40px;
`
const Title = styled.p`
    opacity: 80%;
    margin-bottom: 23px;
    color: #555555;
    font-size: 15px;
    font-weight: 700;
    line-height: 32px;
`
const FlexBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 14px;
    &:last-child {
        margin-bottom: 40px;
    }
`
const Label = styled.p`
    opacity: 80%;
    width: 102px;
    color: #555555;
    font-size: 14px;
    line-height: 34px;
`
const ButtonBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
`
const Button = styled.button`
    background-color: ${(props) => props.bgColor};
    flex: 1;
    height: 40px;
    border: ${(props) => props.border};
    border-radius: 3px;
    color: ${(props) => props.color};
    font-size: 14px;

    &:last-child {
        margin-left: 14px;
    }

    &:hover {
        background-color: ${(props) => props.hover};
    }
`

SensorTargetValuePopup.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

export default CommonContainer(ChartContainer(withTranslation()(SensorTargetValuePopup)))
