// 게이트웨이 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import GatewayInfoContainer from "../../../containers/defaultInfo/GatewayInfoContainer"
import RequiredMark from "../../component/RequiredMark"
import TableCheckBox from "../../component/common/TableCheckBox"
import InputBox from "../../component/common/InputBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import AlertPopup from "../AlertPopup"

import CommonContainer from "../../../containers/CommonContainer" // 다국어 사전 가져오기 위해 import - 차건담 2023.03.02

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
      maxWidth: "615px",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const GatewayAddPopup = (props) => {
  const classes = useStyles()
  const { t, _gateWay } = props
  const { onClose, open } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const [gatewayName, setGatewayName] = useState("")
  const [gatewayNo, setGatewayNo] = useState("")
  const [apiUrl, setApiUrl] = useState("")
  const [socketUrl, setSocketUrl] = useState("")
  const [ip, setIp] = useState("")
  const [port, setPort] = useState("")
  const [macAddress, setMacAddress] = useState("")
  const [dataSendCycle, setDataSendCycle] = useState("")
  const [isUse, setIsUse] = useState(true)
  const [etc, setEtc] = useState("")

  // 사전 관련 객체 가져오기 - 차건담 2023.03.02
  const { initTranslationWords, getTranslationWords, _translationWords, replaceWord } = props  

  useEffect(() => {
    getTranslationWords() // 사전 가져오는 함수 - 차건담 2023.03.02
    if (open && _gateWay && Object.keys(_gateWay).length != 0) {
      setGatewayName(_gateWay.gatewayName)
      setGatewayNo(_gateWay.gatewayNo)
      setApiUrl(_gateWay.apiUrl)
      setSocketUrl(_gateWay.socketUrl)
      setIp(_gateWay.ip)
      setPort(_gateWay.port)
      setMacAddress(_gateWay.mac)
      setDataSendCycle(_gateWay.dataSendCycle)
      setIsUse(_gateWay.isUse == 1)
      setEtc(_gateWay.remark)
    }

    return () => {
      setGatewayName("")
      setGatewayNo("")
      setApiUrl("")
      setSocketUrl("")
      setIp("")
      setPort("")
      setMacAddress("")
      setDataSendCycle("")
      setIsUse(true)
      setEtc("")
    }
  }, [open])

  let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.02.28

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  const onClickSave = async () => {
    if (gatewayName == "") return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("gatewayName")) })
    else if (gatewayNo == "") return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("gatewayNo")) })
    else if (dataSendCycle == "" || dataSendCycle == 0) return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("dataSendCycle")) })

    const param = {
      gatewayName,
      gatewayNo,
      apiUrl,
      socketUrl,
      ip,
      port,
      mac: macAddress,
      dataSendCycle: dataSendCycle || 0,
      isUse: isUse ? 1 : 0,
      remark: etc,
    }
    if (_gateWay) param.gatewaySeq = _gateWay.gatewaySeq

    onClose(dictCk("save"), param)
  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose(null)}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />

      <div
        style={{
          backgroundColor: "#fff",
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: "23px 51px 44px 40px",
        }}
      >
        <Title>{dictCk("gatewayInfo")}</Title>
        <div style={{ flex: 1 }}>
          <FlexBox>
            <Label>
              <RequiredMark /> {dictCk("gatewayName")}
            </Label>
            <InputBox style={{ width: "300px", marginRight: 0 }} type="text" value={gatewayName} onChange={(e) => setGatewayName(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dictCk("gatewayNo")}
            </Label>
            <InputBox style={{ width: "300px", marginRight: 0 }} type="text" value={gatewayNo} onChange={(e) => setGatewayNo(e.target.value)} />
          </FlexBox>
          {/* <FlexBox>
            <Label>API URL</Label>
            <InputBox style={{ width: "200px", marginRight: 0 }} type="text" value={apiUrl} onChange={(e) => setApiUrl(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>Socket URL</Label>
            <InputBox style={{ width: "200px", marginRight: 0 }} type="text" value={socketUrl} onChange={(e) => setSocketUrl(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>IP</Label>
            <InputBox style={{ width: "200px", marginRight: 0 }} type="text" value={ip} onChange={(e) => setIp(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>Port</Label>
            <InputBox style={{ width: "200px", marginRight: 0 }} type="text" value={port} onChange={(e) => setPort(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>Mac주소</Label>
            <InputBox style={{ width: "200px", marginRight: 0 }} type="text" value={macAddress} onChange={(e) => setMacAddress(e.target.value)} />
          </FlexBox> */}
          <FlexBox>
          <RequiredMark /><Label>{dictCk("dataSendCycle")}</Label>
            <InputBox
              style={{ width: "144px", marginRight: 0 }}
              type="number"
              value={dataSendCycle}
              onChange={(e) => setDataSendCycle(e.target.value)}
            />
          </FlexBox>
          <FlexBox>
            <Label>{dictCk("isUse")}</Label>
            <TableCheckBox check={isUse} setCheck={setIsUse} />
          </FlexBox>
          <FlexBox alignItems="flex-start">
            <Label>{dictCk("remark")}</Label>
            <TextAreaBox width={0} value={etc} setValue={setEtc} />
          </FlexBox>
        </div>

        <ButtonBox>
          <Button
            bgColor={"#fff"}
            hover={"rgba(174, 174, 174, 0.22)"}
            border={"1px solid #c6c6c6"}
            color={"#555555cc"}
            onClick={() => onClose(dictCk("cancel"), null)}
          >
            {dictCk("cancel")}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {dictCk("save")}
          </Button>
        </ButtonBox>
      </div>
    </Dialog>
  )
}

const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
`
const Label = styled.p`
  opacity: 80%;
  width: 122px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  width: 147px;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

GatewayAddPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default CommonContainer(GatewayInfoContainer(withTranslation()(GatewayAddPopup)))
