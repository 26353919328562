import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const { 
  
  ITEM_LIST, 

} = actions.AddDevItemAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  
  itemList: state.CommonReducer.itemList || ITEM_LIST.initData, ITEM_LIST,

})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({

})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
