import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { downExcel } from "../../../utils/util"
import SensorNodeContainer from "../../../containers/defaultInfo/SensorNodeContainer"
import PageName from "../../component/common/PageName"
import InputBox from "../../component/common/InputBox"
import { SkyButton, YellowButton, GrayButton } from "../../component/common/Button"
import { TableComponent } from "../../component/common/Table"
import BasicPopup from "../../popup/BasicPopup"
import SensorNodeAddPopup from "../../popup/DefaultInfo/SensorNodeAddPopup"
import SensorAddPopup from "../../popup/DefaultInfo/SensorAddPopup"
import ComboBox from "../../component/common/ComboBox"

import CommonContainer from "../../../containers/CommonContainer" // 다국어 사전 가져오기 위해 import - 차건담 2023.03.02

const SensorNode = (props) => {
  const {
    t,
    _codeList,
    _comboWorkCenterList,
    _comboGatewayList,
    getComboList,

    _sensorNodeList,
    initSensorNodeList,
    getSensorNodeList,
    addSensorNode,
    initSensorNode,
    getSensorNode,
    editSensorNode,
    delSensorNode,
    downloadSensorNodeListExcel,

    _sensorList,
    initSensorList,
    getSensorList,
    addSensor,
    initSensor,
    getSensor,
    editSensor,
    delSensor,
  } = props
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [sensorNodeAddPopup, setSensorNodeAddPopup] = useState(false)
  const [sensorAddPopup, setSensorAddPopup] = useState(false)
  const [index1, setIndex1] = useState(0)
  const [index2, setIndex2] = useState(0)
  const [searchText, setSearchText] = useState("")
  const [currentSensorNode, setCurrentSensorNode] = useState(null)

// 사전 관련 객체 가져오기 - 차건담 2023.03.02
  const { initTranslationWords, getTranslationWords, _translationWords, replaceWord } = props  

  useEffect(() => {
    initPage()
    getTranslationWords() // 사전 가져오는 함수 - 차건담 2023.03.02

    return async () => {
      await initSensorList()
      await initSensorNodeList()
    }
  }, [])

  let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.02.28

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  useEffect(() => {
    if (currentSensorNode == null) return
    getSensorData()
  }, [currentSensorNode])

  const initPage = async () => {
    const pro1 = await getComboList({ queryKind: "WorkCenter" })
    const pro2 = await getComboList({ queryKind: "Gateway" })
    Promise.all([pro1, pro2])
  }

  // sensor list GET method
  const getData = async () => {
    await getSensorNodeList({ gatewaySeq: 0, workCenterSeq: 0, sensorNodeName: "" })
  }
  // sensorNode list Excel method
  const onClickExcelDownload = async () => {
    const result = await downloadSensorNodeListExcel()
    downExcel({ result: result, fileName: dictCk("sensorNodeList") })
  }
  // search button listener
  const onClickSearch = async () => {
    const result = await getSensorNodeList({ gatewaySeq: index1, workCenterSeq: index2, sensorNodeName: searchText })
    if (result) {
      setSearchText("")
    }
  }
  // sensor node add listener
  const onClickAdd = async () => {
    await initSensorNode()
    setSensorNodeAddPopup(true)
  }
  // sensor node edit listener
  const onClickEdit = async (seq) => {
    const result = await getSensorNode({ sensorNodeSeq: seq })
    if (result) setSensorNodeAddPopup(true)
  }
  // sensor node del button listener
  const onClickDelete = async (seq) => {
    const result = await delSensorNode({ sensorNodeSeq: seq })
    if (result) getData()
  }
  // sensor node add/edit popup handler
  const sensorNodeAddPopupHandler = async (type, value) => {
    let finalResult = false
    if (type == dictCk("save") && value.sensorNodeSeq == undefined) {
      const result = await addSensorNode(value)
      if (result && !result.code != 0) {
        getData()
        finalResult = true
      }
    } else if (type == dictCk("save") && value.sensorNodeSeq) {
      const result = await editSensorNode(value)
      if (result && !result.code != 0) {
        getData()
        finalResult = true
      }
    }
    if ((type == dictCk("save") && finalResult) || type == dictCk("cancel")) {
      setSensorNodeAddPopup(false)
    }
  }

  // sensor list get method
  const getSensorData = async () => {
    if (currentSensorNode == null) return initSensorList()
    await getSensorList({ sensorNodeSeq: currentSensorNode.seq })
  }
  // sensor add button method
  const onClickSensorAdd = async () => {
    if (currentSensorNode == null) return setPopup({ open: true, text: replaceWord(dictCk("n.Please select a product."), dictCk("sensorNode")) })
    await initSensor()
    setSensorAddPopup(true)
  }
  // sensor edit button listener
  const onClickSensorEdit = async (seq) => {
    const result = await getSensor({ sensorSeq: seq })
    if (result) setSensorAddPopup(true)
  }
  // sensor del button listener
  const onClickSensorDelete = async (seq) => {
    const result = await delSensor({ sensorSeq: seq })
    if (result) getSensorData()
  }
  // sensor add/edit popup handler
  const sensorAddPopupHandler = async (type, value) => {
    let finalResult = false
    if (type == dictCk("save") && value.sensorSeq == undefined) {
      const result = await addSensor(value)
      if (result && !result.code != 0) {
        getSensorData()
        finalResult = true
      }
    } else if (type == dictCk("save") && value.sensorSeq) {
      const result = await editSensor(value)
      if (result && !result.code != 0) {
        getSensorData()
        finalResult = true
      }
    }

    if ((type == dictCk("save") && finalResult) || type == dictCk("cancel")) {
      setSensorAddPopup(false)
    }
  }

  const sensorTypeReturn = (code) => {
    let returnName = ""
    if (code == 10230001) returnName = dictCk("inside")
    //내부
    else if (code == 10230002) returnName = dictCk("soil")
    //지중
    else if (code == 10230003) returnName = dictCk("water")
    //배액
    else if (code == 10230004) returnName = dictCk("outside")
    //외부
    return returnName
  }

  return (
    <Container>
      <BasicPopup open={popup.open} onClose={() => setPopup({ ...popup, open: false })} content={popup.text} selectedValue={[t("confirm")]} />
      <SensorNodeAddPopup open={sensorNodeAddPopup} onClose={sensorNodeAddPopupHandler} />
      <SensorAddPopup open={sensorAddPopup} onClose={sensorAddPopupHandler} currentSensorNode={currentSensorNode} />
      <FlexBox justifyContent="space-between">
        <PageName name={dictCk("sensorNode")} state={false} setState={() => {}} />
        <GrayButton onClick={onClickExcelDownload}>{dictCk("downloadExcelFile")}</GrayButton>
      </FlexBox>

      <Box>
        <WhiteBox height={350}>
          <FlexBox justifyContent="space-between">
            <FlexBox>
              <Text>{dictCk("gatewayName")}</Text>
              <ComboBox
                style={{ width: "220px", marginRight: "28px" }}
                defaultValue={dictCk("select")}
                hasDefault={true}
                items={_comboGatewayList.map((i) => ({ value: i.gatewaySeq, label: i.gatewayName }))}
                value={index1}
                setValue={setIndex1}
              />
              <Text>{dictCk("workCenterName")}</Text>
              <ComboBox
                style={{ width: "220px", marginRight: "28px" }}
                defaultValue={dictCk("select")}
                hasDefault={true}
                items={_comboWorkCenterList.map((i) => ({ value: i.workCenterSeq, label: i.workCenterName }))}
                value={index2}
                setValue={setIndex2}
              />
            </FlexBox>
            <FlexBox>
              <YellowButton onClick={onClickSearch}>{dictCk("search")}</YellowButton>
              <SkyButton style={{ marginLeft: "14px" }} onClick={onClickAdd}>
                {dictCk("add")}
              </SkyButton>
            </FlexBox>
          </FlexBox>
          <FlexBox2>
            <Text>{dictCk("sensorNodeName")}</Text>
            <InputBox style={{ marginRight: "33px" }} type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
          </FlexBox2>

          <TableComponent
            headList={[
              { title: `${dictCk("no")}` },
              { title: `${dictCk("sensorNodeName")}`, type: "text" },
              { title: `${dictCk("sensorNodeNo")}`, type: "text" },
              { title: `${dictCk("gatewayName")}`, type: "text" },
              { title: `${dictCk("workCenterName")}`, type: "text" },
              { title: `${dictCk("workCenterArea")}`, type: "text" },
              // { title: "IP", type: "text" },
              // { title: "Port", type: "text" },
              // { title: "Mac주소", type: "text" },
              { title: `${dictCk("dataSendCycle")}`, type: "number" },
              { title: `${dictCk("isUse")}`, type: "check" },
              { title: `${dictCk("actions")}` },
            ]}
            bodyList={_sensorNodeList.map((i, idx) => ({
              seq: i.sensorNodeSeq,
              no: _sensorNodeList.length - idx,
              sensorNodeName: i.sensorNodeName,
              sensorNodeNo: i.sensorNodeNo,
              gatewayName: i.gatewayName,
              workCenterName: i.workCenterName,
              areaNos: i.areaNos ? i.areaNos.replaceAll(",", ", ") : "",
              // ip: i.ip,
              // port: i.port,
              // mac: i.mac,
              dataSendCycle: i.dataSendCycle,
              isUse: i.isUse,
              Actions: true,
            }))}
            dict={dict}
            onClick={(data) => setCurrentSensorNode(data)}
            onClickEdit={(seq) => onClickEdit(seq)}
            onClickDelete={(seq) => onClickDelete(seq)}
          />
        </WhiteBox>
      </Box>
      <Box>
        <WhiteBox>
          <FlexBox justifyContent="space-between">
            <FlexBox>
              <Text>{dictCk("sensorNodeName")}</Text>
              <InputBox
                style={{ backgroundColor: "#edf4eb", marginRight: "33px" }}
                type="text"
                value={(currentSensorNode && currentSensorNode.sensorNodeName) || ""}
                onChange={() => {}}
                readOnly
              />
            </FlexBox>
            <FlexBox>
              <SkyButton onClick={onClickSensorAdd}>{dictCk("add")}</SkyButton>
            </FlexBox>
          </FlexBox>
          <TableComponent
            headList={[
              { title: `${dictCk("no")}` },
              { title: `${dictCk("sensorKind")}`, type: "text" },
              { title: `${dictCk("sensorType")}`, type: "text" },
              { title: `${dictCk("sensorName")}`, type: "text" },
              // { title: `${dictCk("sensorNo")}`, type: "text" },
              { title: `${dictCk("sensorVolt")}`, type: "number" },
              { title: `${dictCk("sensorUnit")}`, type: "text" },
              { title: `${dictCk("measureRange")}`, type: "number" },
              { title: `${dictCk("errorRange")}`, type: "number" },
              { title: `${dictCk("modelName")}`, type: "text" },
              { title: `${dictCk("maker")}`, type: "text" },
              { title: `${dictCk("isUse")}`, type: "check" },
              { title: `${dictCk("remark")}`, type: "text" },
              { title: `${dictCk("actions")}` },
            ]}
            bodyList={_sensorList.map((i, idx) => ({
              seq: i.sensorSeq,
              no: _sensorList.length - idx,
              sensorKindName: i.sensorKindName,
              sensorType: sensorTypeReturn(i.sensorType),
              sensorName: i.sensorName,
              // sensorNo: i.sensorNo,
              sensorVolt: i.sensorVoltName,
              sensorUnitName: i.sensorUnitName,
              measureRange: i.measureRange,
              errorRange: i.errorRange,
              modelName: i.modelName,
              maker: i.maker,
              isUse: i.isUse,
              remark: i.remark,
              Actions: true,
            }))}
            dict={dict}
            onClickEdit={(seq) => onClickSensorEdit(seq)}
            onClickDelete={(seq) => onClickSensorDelete(seq)}
          />
        </WhiteBox>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const FlexBox2 = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
  margin-top: 17px;
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  border-radius: 9px;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  height: ${(props) => props.height || 275}px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
  margin-top: 30px;
  padding: 28px 32px 31px;
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`

export default CommonContainer(SensorNodeContainer(withTranslation()(SensorNode)))
