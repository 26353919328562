// 비교 차트 컴포넌트 || 최종 수정일자 - 차건담 2023.06.16
import React, { useRef, useEffect, useState, Component } from 'react';
import ApexCharts from 'apexcharts';
import ChartContainer from '../../../containers/statusInfo/ChartContainer';
import PropTypes from "prop-types"
import './chart.css'

const CompareChart = (props) => {
  const { chartDataList, // 차트 데이터 리스트
          seriesList,    // 시리즈 데이터 리스트 (그래프의 값)
          categoryList,  // x축 값 리스트
          tooltipList,   // tooltip 데이터 리스트 (tooltip은 그래프에 커서가 올라가면 나타나는 박스)
          sensorKind,    // 센서종류 seq 번호
          sensorMinMax,  // 센서 데이터의 최소값과 최대값
          yaxisList      // y축 리스트
        } = props

  const chartRef = useRef(null);
  const [chart, setChart] = useState(null);

  useEffect(() => {
    const options = {
      chart: {
        type: 'line',
        zoom: {
          enabled: true,
          type: 'x', // enable zooming only on the x-axis
          autoScaleYaxis: true // auto-scale y-axis on zoom
        },
        animations:{
          enabled: false,
        }
      },
      series: seriesList,
      xaxis: {
        categories: categoryList,
      },
      yaxis: seriesList.length<=0 ? 
                  {
        min: -20,
        max: 100,
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: '#008FFB'
        },
        labels: {
          style: {
            colors: '#008FFB',
          }
        },
        title: {
          text: "Income (thousand crores)",
          style: {
            color: '#008FFB',
          }
        }
        }
        :
        yaxisList,

    tooltip: {
        enabled: true,
        shared: true,
    },
      legend: {
        show: true, // Set show property to true
        // other legend options...
        showForSingleSeries: true,
      },
      stroke: {
        curve: 'smooth', // set the line style to smooth curve
        width: 2, // set the line thickness
      },
      //colors: ['#008FFB', '#00E396', '#FEB019'], // set the line colors
      colors: ['#F08080', '#008FFB', '#00E396', '#FEB019', '#BA55D3', '#008080']
    };

    const chartInstance = new ApexCharts(chartRef.current, options);
    chartInstance.render();
    setChart(chartInstance);

    // clean up function
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [chartDataList]);

  return <div ref={chartRef} className="custom-chart" style={{animation:"none"}}/>;
};

export default ChartContainer(CompareChart);