import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  initControlNodeList,
  getControlNodeList,
  addControlNode,
  initControlNode,
  getControlNode,
  editControlNode,
  delControlNode,
  downloadControlNodeListExcel,
} = actions.DefaultInfoAction
const { getComboList } = actions.SystemSettingAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _controlNodeList: state.DefaultInfoReducer._controlNodeList,
  _controlNode: state.DefaultInfoReducer._controlNode,
  _codeList: state.SystemSettingReducer._codeList,
  _comboWorkCenterList: state.SystemSettingReducer._comboWorkCenterList,
  _comboGatewayList: state.SystemSettingReducer._comboGatewayList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  initControlNodeList: (param) => dispatch(initControlNodeList(param)),
  getControlNodeList: (param) => dispatch(getControlNodeList(param)),
  addControlNode: (param) => dispatch(addControlNode(param)),
  initControlNode: (param) => dispatch(initControlNode(param)),
  getControlNode: (param) => dispatch(getControlNode(param)),
  editControlNode: (param) => dispatch(editControlNode(param)),
  delControlNode: (param) => dispatch(delControlNode(param)),
  downloadControlNodeListExcel: (param) => dispatch(downloadControlNodeListExcel(param)),
  getComboList: (param) => dispatch(getComboList(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
