// 구매지출관리 > 작기별구매지출계획
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import { withTranslation } from "react-i18next"
import PurchaseSpendPlanContainer from "../../../containers/purchaseSpend/PurchaseSpendPlanContainer"
import PageName from "../../component/common/PageName"
import InputBox from "../../component/common/InputBox"
import { YellowButton, SkyButton } from "../../component/common/Button"
import { TableComponent } from "../../component/common/Table"
import Tab from "../../component/common/Tab"
import BasicPopup from "../../popup/BasicPopup"
import PurchaseSpendPlanAddPopup from "../../popup/PurchaseSpend/PurchaseSpendPlanAddPopup"

import CommonContainer from "../../../containers/CommonContainer"

import CodeHelpBox from "../../component/common/CodeHelpBox"

const ProductionPlan = (props) => {
  const {
    t,
    _codeList,
    _cropPlanList,
    _costPlanList,
    setCropPlanList,
    getCropPlanList,
    setCostPlanList,
    getCostPlanList,
    addCostPlan,
    editCostPlan,
    delCostPlan,

    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,
  } = props
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [purchaseSpendPlanAddPopup, setPurchaseSpendPlanAddPopup] = useState({ open: false, data: {} })
  const [cropDateFr, setCropDateFr] = useState(moment().format("YYYY"))
  const [cropName, setCropName] = useState("")
  const [cropNameCode, setCropNameCode] = useState('')
  const [currentCrop, setCurrentCrop] = useState(null)
  const [tabIndex, setTabIndex] = useState(0)

  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {
    getTranslationWords()
    return async () => {
      await setCropPlanList([])
      await setCostPlanList([])
    }
  }, [])

  useEffect(() => {
    if(cropNameCode != ''){
      setCropName(cropNameCode['label'])
    }
  }, [cropNameCode])

  const getData = async () => {
    await getCropPlanList({ cropDateFr, cropName })
  }

  const onClickSearch = async () => {
    if (cropDateFr.length == 4) await getCropPlanList({ cropDateFr, cropName })
    else await getCropPlanList({ cropDateFr, cropName })
  }
  const onClickCrop = async (item) => {
    await getCostPlanList({ cropSeq: item.seq, cropKind: _codeList.filter((i) => i.majorSeq == 1014)[0].minorList[tabIndex].minorSeq })
    setCurrentCrop(item)
  }
  const onClickTab = async (item) => {
    if (currentCrop) {
      await getCostPlanList({ cropSeq: currentCrop.seq, cropKind: item.minorSeq })
    }
    setTabIndex(item.seq)
  }
  const onClickAdd = async () => {
    if (currentCrop) setPurchaseSpendPlanAddPopup({ open: true, data: {} })
    else setPopup({ open: true, text:replaceWord(dict['n.Please select a product.'], dict['crop']) })
  }
  const onClickEdit = async (costPlanSeq) => {
    if (currentCrop) setPurchaseSpendPlanAddPopup({ open: true, data: _costPlanList.find((i) => i.costPlanSeq == costPlanSeq) })
    else setPopup({ open: true, text:replaceWord(dict['n.Please select a product.'], dict['crop']) })
  }
  const onClickDelete = async (costPlanSeq) => {
    const result = await delCostPlan({ costPlanSeq })
    
    await getCostPlanList({ cropSeq: currentCrop.seq, cropKind: _codeList.filter((i) => i.majorSeq == 1014)[0].minorList[tabIndex].minorSeq })//

    if (result) {
      getData()
    }
  }

  const purchaseSpendPlanAddPopupHandler = async (type, value) => {
    if (type == dict['save']) {
      let result = false
      if (value.costPlanSeq == undefined) {
        result = await addCostPlan({ cropSeq: currentCrop.seq, ...value })
        if (result) {
          getData()
        }
      } else {
        result = await editCostPlan({ cropSeq: currentCrop.seq, ...value })
        if (result) {
          getData()
        }
      }
      if (result)
        await getCostPlanList({
          cropSeq: currentCrop.seq,
          cropKind: _codeList.filter((i) => i.majorSeq == 1014)[0].minorList[tabIndex].minorSeq,
        })
    }
    setPurchaseSpendPlanAddPopup({ open: false, data: purchaseSpendPlanAddPopup.data })
  }

  return (
    <Container>
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: popup.text })} content={popup.text} selectedValue={[dict['confirm']]} />
      <PurchaseSpendPlanAddPopup
        open={purchaseSpendPlanAddPopup.open}
        onClose={purchaseSpendPlanAddPopupHandler}
        data={purchaseSpendPlanAddPopup.data}
        tabIndex={tabIndex}
      />
      <PageName name={dict['purchaseSpendingPlan']} state={false} setState={() => {}} />
      <TopWhiteBox>
        <FlexBox justifyContent="space-between">
          <FlexBox>
            <Text>{dict['cropYearFr']}</Text>
            <InputBox style={{ width: "100px" }} type="text" value={cropDateFr} onChange={(e) => setCropDateFr(e.target.value)} maxLength={4} />
            <Text>{dict['cropName']}</Text>
            {/* <InputBox style={{ width: "200px" }} type="text" value={cropName} onChage={(e) => setCropName(e.target.value)} /> */}
            <CodeHelpBox // inputBox에서 CodeHelpBox로 변경 - 차건담 2023.07.13
              style={{width: "200px", marginLeft: "20px"}}
              placeholder={dict['cropName']}
              codeHelpSeq={3}
              // codeHelpParam={{}}
              value={cropNameCode}
              setValue={setCropNameCode}
            />
          </FlexBox>
          <YellowButton onClick={onClickSearch}>{dict['search']}</YellowButton>
        </FlexBox>

        <TableComponent
          headList={[
            { title: dict['ranking'] },
            { title: dict['cropName'], type: "text" },
            { title: dict['itemName'] , type: "text" },
            { title: dict['purchasePlanAmount'] , type: "comma" },
            { title: dict['spendPlanAmount'] , type: "comma" },
            { title: dict['cropDateFr'] , type: "date" },
            { title: dict['cropDateTo'] , type: "date" },
          ]}
          bodyList={_cropPlanList.map((i, idx) => ({
            seq: i.cropSeq,
            no: _cropPlanList.length - idx,
            cropName: i.cropName,
            itemName: i.itemName,
            purchasePlanAmt: i.purchasePlanAmt || 0,
            expenditurePlanAmt: i.expenditurePlanAmt || 0,
            cropDateFr: i.cropDateFr,
            cropDateTo: i.cropDateTo,
          }))}
          dict={dict}
          onClick={onClickCrop}
        />
      </TopWhiteBox>

      <BottomWhiteBox>
        <FlexBox justifyContent="space-between">
          <FlexBox>
            <Text>{dict['cropName']}</Text>
            <InputBox style={{ width: "200px" }} type="text" value={currentCrop ? currentCrop.cropName : ""} onChange={() => {}} disabled />
          </FlexBox>
          <SkyButton onClick={onClickAdd}>{dict['add']}</SkyButton>
        </FlexBox>

        <Tab
          list={
            _codeList.length > 0 && _codeList.filter((i) => i.majorSeq == 1014).length > 0
              ? _codeList.filter((i) => i.majorSeq == 1014)[0].minorList.map((i, idx) => ({ seq: idx, name: i.minorName, minorSeq: i.minorSeq }))
              : []
          }
          tabIndex={tabIndex}
          onClick={onClickTab}
        />
        <TableComponent
          style={{ marginTop: 0 }}
          headList={[
            { title:dict['planYearMonth'] },
            { title: tabIndex == 0 ? dict['purchaseItems'] : dict['spendItems'], type: "text" },
            { title: tabIndex == 0 ? dict['purchasePlanQuantity'] : dict['spendPlanQuantity'], type: "comma" },
            { title: tabIndex == 0 ? dict['purchasePlanPrice'] : dict['spendPlanPrice'], type: "comma" },
            { title: tabIndex == 0 ? dict['purchasePlanAmount']: dict['spendPlanAmount'], type: "comma" },
            { title:dict['remark'] , type: "text" },
            { title: "Actions" },
          ]}
          bodyList={_costPlanList.map((i) => ({
            seq: i.costPlanSeq,
            planYM: i.planYM,
            minorName: i.minorName,
            qty: i.qty || 0,
            price: i.price || 0,
            amt: i.amt || 0,
            remark: i.remark,
            Actions: true,
          }))}
          dict={dict}
          onClickEdit={onClickEdit}
          onClickDelete={onClickDelete}
        />
      </BottomWhiteBox>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  &:after {
    content: "";
    display: block;
    height: 42px;
    width: 100%;
  }
`
const FlexBox = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction || "row"};
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const TopWhiteBox = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 350px;
  margin-top: 30px;
  padding: 28px 35px 31px;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const BottomWhiteBox = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 350px;
  margin-top: 30px;
  padding: 28px 35px 31px;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`

export default PurchaseSpendPlanContainer(CommonContainer(withTranslation()(ProductionPlan)))
