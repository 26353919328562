import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import Header from "./Header"

const Layout = (props) => {
  const { children } = props

  return (
    <Container>
      <Header />
      <ChildContainer>{children}</ChildContainer>
    </Container>
  )
}

const Container = styled.div`
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const ChildContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
`

Layout.propTypes = {}

export default Layout
