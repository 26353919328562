import React from "react"
import styled from "styled-components"
import icon_check from "images/check.png"
import icon_check_select from "images/check_select.png"

const CheckBox = ({ text, check, setCheck, style = {} }) => {
  return (
    <StyledCheckBox style={{ ...style }} onClick={() => setCheck(!check)}>
      <CheckIcon src={check ? icon_check_select : icon_check} alt="체크아이콘" />
      <Label>{text}</Label>
    </StyledCheckBox>
  )
}

const StyledCheckBox = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 35px;
`
const CheckIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 9px;
`
const Label = styled.p`
  opacity: 80%;
  margin-bottom: 2px;
  color: #555555;
  font-size: 12px;
`

export default CheckBox
