import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  initProductionLineList,
  getProductionLineList,
  addProductionLine,
  initProductionLine,
  getProductionLine,
  editProductionLine,
  delProductionLine,
  downloadProductionLineListExcel,

  initProductionLineListDiv,
  getProductionLineListDiv,
} = actions.DefaultInfoAction

const { getComboList } = actions.SystemSettingAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _productionLineList: state.DefaultInfoReducer._productionLineList,
  _productionLine: state.DefaultInfoReducer._productionLine,
  _codeList: state.SystemSettingReducer._codeList,
  _comboWorkCenterList: state.SystemSettingReducer._comboWorkCenterList,
  _comboControlNodeList: state.SystemSettingReducer._comboControlNodeList,

  _productionLineListDiv: state.DefaultInfoReducer._productionLineListDiv,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  initProductionLineList: (param) => dispatch(initProductionLineList(param)),
  getProductionLineList: (param) => dispatch(getProductionLineList(param)),
  addProductionLine: (param) => dispatch(addProductionLine(param)),
  initProductionLine: (param) => dispatch(initProductionLine(param)),
  getProductionLine: (param) => dispatch(getProductionLine(param)),
  editProductionLine: (param) => dispatch(editProductionLine(param)),
  delProductionLine: (param) => dispatch(delProductionLine(param)),
  downloadProductionLineListExcel: (param) => dispatch(downloadProductionLineListExcel(param)),
  getComboList: (param) => dispatch(getComboList(param)),

  initProductionLineListDiv: (param) => dispatch(initProductionLineListDiv(param)),
  getProductionLineListDiv: (param) => dispatch(getProductionLineListDiv(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
