// 현황정보 > 퇴수조회
import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { downExcel, dateFormat } from "../../../utils/util"

import CommonContainer from "../../../containers/CommonContainer"
import InfoContainer from "../../../containers/InfoContainer"

import PageName from "../../component/common/PageName"
import { TableComponent } from "../../component/common/TableNew"

import DateBox from "../../component/common/DateBox"
import ComboBox from "../../component/common/ComboBox"

import { YellowButton, GrayButton } from "../../component/common/Button"

const WithdrawalListInquiry = (props) => {
  
  const { t, initDataList, getDataList, saveDataList, setDataList, getComboList, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  
  const { workCenterComboList, sensorNodeComboList, sensorComboList } = props
  
  const { 
    withdrawalList : dataList, 
    actionWithdrawalList : actionType, 
    actionWithdrawalExcelDownload : actionTypeExcelDownload,
  } = props

  const temp1 = dateFormat(new Date(), "YYYYMM01")
  const temp2 = dateFormat(new Date(), "YYYYMMDD")

  const [dateFr, setDateFr] = useState(temp1)
  const [dateTo, setDateTo] = useState(temp2)
  const [ sensorSeq, setSensorSeq ] = useState(0)
  const dict = _translationWords[0] // 변수에 사전 담기

  const [minDate, setMinDate] = useState(new Date()) //날짜 최저일
  const [maxDate, setMaxDate] = useState(new Date()) //날짜 최대일

  useEffect(() => {
    getTranslationWords()
    getData()

    return async () => await initDataList(actionType)

  }, [])

  useEffect(() => {
    
    const _ = sensorComboList.filter( row => row.sensorKind == 10150007 )

    if ( _.length > 0 ) {
      
      setSensorSeq( _[0].sensorSeq );

    }

  }, [sensorComboList])

  // 달력 날짜 제한 관련
  useEffect(() => {

    // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
    const originalDate = new Date(dateFr.substr(0, 4) + '-' + dateFr.substr(4, 2) + '-' + dateFr.substr(6, 2));
    const modifiedDate = originalDate.setDate(originalDate.getDate() + 10000);

    setMinDate(new Date(dateFr.substr(0,4)+'-'+dateFr.substr(4,2)+'-'+dateFr.substr(6,2)));
    setMaxDate(modifiedDate);
    // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
    // console.log('dateFr(1) : ', dateFr)
    // console.log('dateTo(1) : ', dateTo)

  }, [dateFr])

  const getData = async () => {

    await getComboList({ queryKind: "Sensor" })

  }

  const onClickSearch = async () => {

    const data = {
      dateFr,
      dateTo,
      sensorSeq 
    }

    await getDataList(actionType, data);

  }

  const onClickExcelDownload = async () => {

    const data = {
      dateFr,
      dateTo,
      sensorSeq
    }

    const result = await getDataList( actionTypeExcelDownload, data, { responseType : "blob" } );

    downExcel({ result: result, fileName: dict['decontaminationWater'] });

  }

  return (
    <Container>
      
      <FlexBox justifyContent={"space-between"}>
        <PageName name={dict['']+dict['search']} state={false} setState={() => {}} />
        <GrayButton onClick={onClickExcelDownload}>{dict['downloadExcelFile']}</GrayButton>
      </FlexBox>
      
      <Box>
        <WhiteBox>
          <SearchBox>
            <FlexBox>
              <Text>{dict['inquiryPeriod']}</Text>
              <DateBox
                style={{
                  width: "120px",
                  height: "40px",
                  marginRight: "5px",
                }}
                stdDate={dateFr}
                setStdDate={setDateFr}
              />
              <p style={{ width: "15px" }}>~</p>
              <DateBox
                style={{
                  width: "120px",
                  height: "40px",
                  marginRight: "30px",
                }}
                stdDate={dateTo}
                setStdDate={setDateTo}
                minDate={minDate} // 날짜 최저일
                maxDate={maxDate} // 날짜 최대일
              />

              <Text>{dict['sensorName']}</Text>
              <ComboBox //style={{width:'548px'}}
                defaultValue={dict['select']}
                hasDefault={false}
                items={sensorComboList.filter( row => row.sensorKind == 10150007 ).map((row) => ({ value: row.sensorSeq, label: row.sensorName }))}
                value={sensorSeq}
                setValue={setSensorSeq}
              />

            </FlexBox>
            <YellowButton onClick={onClickSearch}>{dict['search']}</YellowButton>
          </SearchBox>
          <TableComponent
            headList={[
              { title: dict['date'], type: "date", width: "20%" },
              { title: dict['sum'], type: "comma", width: "10%" },
              
              { title: '05', type: "comma", width: "4%" },
              { title: '06', type: "comma", width: "4%" },
              { title: '07', type: "comma", width: "4%" },
              { title: '08', type: "comma", width: "4%" },
              { title: '09', type: "comma", width: "4%" },
              
              { title: '10', type: "comma", width: "4%" },
              { title: '11', type: "comma", width: "4%" },
              { title: '12', type: "comma", width: "4%" },
              { title: '13', type: "comma", width: "4%" },
              { title: '14', type: "comma", width: "4%" },
              
              { title: '15', type: "comma", width: "4%" },
              { title: '16', type: "comma", width: "4%" },
              { title: '17', type: "comma", width: "4%" },
              { title: '18', type: "comma", width: "4%" },
              { title: '19', type: "comma", width: "4%" },
              
              { title: '20', type: "comma", width: "4%" },
              { title: '21', type: "comma", width: "4%" },
              { title: '22', type: "comma", width: "4%" },
              { title: '23', type: "comma", width: "4%" },
              { title: '00', type: "comma", width: "4%" },
              
              { title: '01', type: "comma", width: "4%" },
              { title: '02', type: "comma", width: "4%" },
              { title: '03', type: "comma", width: "4%" },
              { title: '04', type: "comma", width: "4%" },
            ]}
            bodyList={dataList.map((i) => ({
              stdDate: i.stdDate,
              qtyTot: i.qtyTot,

              qty05: i.qty05,
              qty06: i.qty06,
              qty07: i.qty07,
              qty08: i.qty08,
              qty09: i.qty09,

              qty10: i.qty10,
              qty11: i.qty11,
              qty12: i.qty12,
              qty13: i.qty13,
              qty14: i.qty14,
              
              qty15: i.qty15,
              qty16: i.qty16,
              qty17: i.qty17,
              qty18: i.qty18,
              qty19: i.qty19,
              
              qty20: i.qty20,
              qty21: i.qty21,
              qty22: i.qty22,
              qty23: i.qty23,
              qty00: i.qty00,
              
              qty01: i.qty01,
              qty02: i.qty02,
              qty03: i.qty03,
              qty04: i.qty04,
              
            }))}
            dict={dict}
          />
        </WhiteBox>
      </Box>
    </Container>
  )
}

export default InfoContainer(CommonContainer(withTranslation()(WithdrawalListInquiry)))

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  margin-top: 19px;
  padding: 28px 32px 20px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`
