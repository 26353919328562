// 작기정보 작기 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import SalesPlanContainer from "../../../containers/salesManage/SalesPlanContainer"
import RequiredMark from "../../component/RequiredMark"
import DateBox from "../../component/common/DateBox"
import InputBox from "../../component/common/InputBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import AlertPopup from "../AlertPopup"
import moment from "moment"

import CommonContainer from "../../../containers/CommonContainer"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "521px",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const SalesPlanAddPopup = (props) => {
  const classes = useStyles()
  const { t, _salesCropPlan, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  const { open, onClose } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const [planYM, setPlanYM] = useState("")
  const [qty, setQty] = useState("")
  const [price, setPrice] = useState("")
  const [remark, setRemark] = useState("")

  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {
    getTranslationWords()
    if (_salesCropPlan && Object.keys(_salesCropPlan).length != 0) {
      setPlanYM(_salesCropPlan.planYM)
      setQty(_salesCropPlan.qty)
      setPrice(_salesCropPlan.price)
      setRemark(_salesCropPlan.remark)
    }

    return () => {
      setPlanYM("")
      setQty("")
      setPrice("")
      setRemark("")
    }
  }, [open])

  const onClickSave = () => {
    if (planYM == "") return setAlertPopup({ open: true, text:replaceWord(dict['n.Invalid value. Please try again.'], dict['planYearMonth']) })
    // else if (qty == "") return setAlertPopup({ open: true, text:replaceWord(dict['n.Invalid value. Please try again.'], dict['salesPlanQuantity']) })
    // else if (price == "") return setAlertPopup({ open: true, text:replaceWord(dict['n.Invalid value. Please try again.'], dict['salesPlanPrice']) })

    let param = {
      planYM: moment(planYM).format("YYYYMM"),
      qty: qty || 0,
      price: price || 0,
      amt: qty > 0 && price > 0 ? qty * price : 0,
      remark,
    }

    if (Object.keys(_salesCropPlan).length != 0) {
      param.salesPlanSeq = _salesCropPlan.salesPlanSeq
    }
    onClose(dict['save'], param)
  }

  const onClickCancel = () => {
    onClose(dict['cancel'], null)
  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose(null)}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <Container>
        <Title>{dict['monthlySalesPlan']}</Title>
        <div style={{ flex: 1 }}>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['planYearMonth']}
            </Label>
            <InputBox
              style={{ width: "200px", marginRight: 0 }}
              placeholder={"0000-00"}
              type="text"
              mask="####-##"
              value={planYM}
              onChange={(e) => setPlanYM(e.target.value)}
            />
          </FlexBox>
          <FlexBox>
            <Label>{dict['salesPlanQuantity']}</Label>
            <InputBox style={{ width: "200px", marginRight: 0 }} type="number" value={qty} onChange={(e) => setQty(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>{dict['salesPlanPrice']}</Label>
            <InputBox style={{ width: "200px", marginRight: 0 }} type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>{dict['salesPlanAmount']}</Label>
            <InputBox
              style={{ width: "200px", marginRight: 0 }}
              type="number"
              value={qty > 0 && price > 0 ? qty * price : ""}
              onChange={() => {}}
              disabled
            />
          </FlexBox>
          <FlexBox alignItems="flex-start">
            <Label>{dict['remark']}</Label>
            <TextAreaBox width={300} value={remark} setValue={setRemark}></TextAreaBox>
          </FlexBox>
        </div>
        <ButtonBox>
          <Button bgColor={"#fff"} hover={"rgba(174, 174, 174, 0.22)"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={onClickCancel}>
            {dict['cancel']}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {dict['save']}
          </Button>
        </ButtonBox>
      </Container>
    </Dialog>
  )
}

const Container = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 23px 59px 40px 40px;
`
const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 34px;
  }
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  flex: 1;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

SalesPlanAddPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default SalesPlanContainer(CommonContainer(withTranslation()(SalesPlanAddPopup)))
