// 공용 라우터 페이지
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import cookie from "react-cookies"
import MainContainer from "../../containers/MainContainer"
import Layout from "../component/Layout"
import SEO from "../seo"
import SideBar from "../component/common/SideBar"

const Router = (props) => {
  const { _moduleList, getMenuList, getCodeList } = props
  const [pgmList, setPgmList] = useState([])
  const [page, setPage] = useState(null)
  const [Component, setComponent] = useState(null)

  // 페이지 초기셋팅 & 코드리스트 호출
  useEffect(() => {
    const getData = async () => {
      cookie.save("pgmSeq", pgmList[0].pgm[0].pgmSeq, { path: "/" })
      cookie.save("pgmId", pgmList[0].pgm[0].pgmId, { path: "/" })
      setPage(pgmList[0].pgm[0].pgmId)
      await getCodeList({ pgmSeq: pgmList[0].pgm[0].pgmSeq })
    }

    if (pgmList.length > 0) {
      getData()
    }
  }, [pgmList])

  // 메뉴 리스트 호출
  useEffect(() => {
    const getData = async () => {
      const menuList = await getMenuList({
        moduleSeq: _moduleList.find((i) => i.moduleId.toLocaleLowerCase() == props.location.pathname.split("/")[2].toLocaleLowerCase()).moduleSeq,
      })

      setPgmList(menuList)
    }

    getData()
  }, [])

  // 페이지 컴포넌트 셋팅
  useEffect(() => {
    if (page != null) {
      try {
        setComponent(require(`./${cookie.load("moduleId", { path: "/" })}/${page}`).default)
      } catch (e) {
        console.log(e)
      }
    }
  }, [page])

  return (
    <Layout>
      <SEO title={_moduleList.find((i) => i.moduleId.toLocaleLowerCase() == props.location.pathname.split("/")[2].toLocaleLowerCase()).moduleName} />
      <Container>
        <SideBar
          title={_moduleList.find((i) => i.moduleId.toLocaleLowerCase() == props.location.pathname.split("/")[2].toLocaleLowerCase()).moduleName}
          menuList={pgmList}
          page={page}
          setPage={setPage}
        />
        <ChildrenContainer>{Component != null && <Component />}</ChildrenContainer>
      </Container>
    </Layout>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`
const ChildrenContainer = styled.div`
  flex: 1;
  padding: 42px 70px;
`

export default MainContainer(Router)
