// 기본정보 > 제어노드
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { downExcel } from "../../../utils/util"
import ControlNodeContainer from "../../../containers/defaultInfo/ControlNodeContainer"
import PageName from "../../component/common/PageName"
import { GrayButton, SkyButton, YellowButton } from "../../component/common/Button"
import ComboBox from "../../component/common/ComboBox"
import InputBox from "../../component/common/InputBox"
import { TableComponent } from "../../component/common/Table"
import ControlNodeAddPopup from "../../popup/DefaultInfo/ControlNodeAddPopup"

import CommonContainer from "../../../containers/CommonContainer" // 다국어 사전 가져오기 위해 import - 차건담 2023.03.02

const ControlNode = (props) => {
  const {
    t,
    _comboWorkCenterList,
    _comboGatewayList,
    getComboList,

    _controlNodeList,
    initControlNodeList,
    getControlNodeList,
    initControlNode,
    getControlNode,
    addControlNode,
    editControlNode,
    delControlNode,
    downloadControlNodeListExcel,
  } = props
  
  // 사전 관련 객체 가져오기 - 차건담 2023.03.02
  const { initTranslationWords, getTranslationWords, _translationWords, replaceWord } = props  

  const [controlNodeAddPopup, setControlNodeAddPopup] = useState(false)
  const [index1, setIndex1] = useState(0)
  const [index2, setIndex2] = useState(0)
  const [searchText, setSearchText] = useState("")

  useEffect(() => {
    initData()
    getTranslationWords() // 사전 가져오는 함수 - 차건담 2023.03.02
    return async () => await initControlNodeList()
  }, [])


  let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.03.02

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }


  const initData = async () => {
    await getComboList({ queryKind: "Gateway" })
    // const pro1 = await getControlNodeList(`gatewaySeq=${0}&workCenterSeq=${0}&controlNodeName=${""}`)
    // const pro2 = await getComboList({ queryKind: "WorkCenter" })
    // const pro3 = await getComboList({ queryKind: "Gateway" })

    // Promise.all([pro1, pro2, pro3])
  }

  const getData = async () => {
    await getControlNodeList(`gatewaySeq=${0}&workCenterSeq=${0}&controlNodeName=${""}`)
  }

  const onClickExcelDownload = async () => {
    const result = await downloadControlNodeListExcel()
    downExcel({ result: result, fileName: dictCk("controlNodeList") })
  }

  const onClickSearch = async () => {
    const result = await getControlNodeList(
      `gatewaySeq=${index1 == 0 ? 0 : index1}&workCenterSeq=${index2 == 0 ? 0 : index2}&controlNodeName=${searchText}`
    )
    if (result) {
      setSearchText("")
    }
  }

  const onClickAdd = async () => {
    await initControlNode()
    setControlNodeAddPopup(true)
  }

  const onClickEdit = async (seq) => {
    const result = await getControlNode(`controlNodeSeq=${seq}`)
    if (result) setControlNodeAddPopup(true)
  }

  const onClickDelete = async (seq) => {
    const result = await delControlNode({ controlNodeSeq: seq })
    if (result) getData()
  }

  const controlNodeAddPopupHandler = async (type, value) => {
    if (type == "save" && value.controlNodeSeq == undefined) {
      const result = await addControlNode(value)
      if (result) getData()
    } else if (type == "save" && value.controlNodeSeq) {
      const result = await editControlNode(value)
      if (result) getData()
    }

    setControlNodeAddPopup(false)
  }
  
  return (
    <Container>
      <ControlNodeAddPopup open={controlNodeAddPopup} onClose={controlNodeAddPopupHandler} />
      <FlexBox justifyContent="space-between">
        <PageName name={dictCk("controlNode")} state={false} setState={() => {}} />
        <GrayButton onClick={onClickExcelDownload}>{dictCk("downloadExcelFile")}</GrayButton>
      </FlexBox>
      <Box>
        <WhiteBox>
          <FlexBox justifyContent="space-between">
            <FlexBox>
              <Text>{dictCk("gatewayName")}</Text>
              <ComboBox
                style={{ width: "220px", marginRight: "28px" }}
                defaultValue={dictCk("select")}
                hasDefault={true}
                items={_comboGatewayList.map((i) => ({ value: i.gatewaySeq, label: i.gatewayName }))}
                value={index1}
                setValue={setIndex1}
              />
              {/* <Text>작업장명</Text>
              <ComboBox
                style={{ width: "220px", marginRight: "28px" }}
                defaultValue={t("select")}
                hasDefault={true}
                items={_comboWorkCenterList.map((i) => ({ value: i.workCenterSeq, label: i.workCenterName }))}
                value={index2}
                setValue={setIndex2}
              /> */}
              <Text>{dictCk("controlNodeName")}</Text>
              <InputBox style={{ marginRight: "33px" }} type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            </FlexBox>
            <FlexBox>
              <YellowButton onClick={onClickSearch}>{dictCk("search")}</YellowButton>
              <SkyButton style={{ marginLeft: "14px" }} onClick={onClickAdd}>
                {dictCk("add")}
              </SkyButton>
            </FlexBox>
          </FlexBox>

          <TableComponent
            headList={[
              { title: `${dictCk("no")}` },
              { title: `${dictCk("controlNodeName")}`, type: "text" },
              { title: `${dictCk("controlNodeNo")}`, type: "text" },
              { title: `${dictCk("relayCnt")}`, type: "number" },
              { title: `${dictCk("gatewayName")}`, type: "text" },
              // { title: "작업장명", type: "text" },
              // { title: "IP", type: "text" },
              // { title: "Port", type: "text" },
              // { title: "Mac주소", type: "text" },
              { title: `${dictCk("dataSendCycle")}`, type: "number" },
              { title: `${dictCk("isUse")}`, type: "check" },
              { title: `${dictCk("remark")}`, type: "text" },
              { title: `${dictCk("actions")}` },
            ]}
            bodyList={_controlNodeList.map((i, idx) => ({
              seq: i.controlNodeSeq,
              no: _controlNodeList.length - idx,
              controlNodeName: i.controlNodeName,
              controlNodeNo: i.controlNodeNo,
              relayCnt: i.relayCnt,
              gatewayName: i.gatewayName,
              // workCenterName: i.workCenterName,
              // ip: i.ip,
              // port: i.port,
              // mac: i.mac,
              dataSendCycle: i.dataSendCycle,
              isUse: i.isUse,
              remark: i.remark,
              Actions: true,
            }))}
            dict={dict}
            onClickEdit={(seq) => onClickEdit(seq)}
            onClickDelete={(seq) => onClickDelete(seq)}
          />
        </WhiteBox>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
  margin-top: 30px;
  padding: 28px 32px 31px;
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`

export default CommonContainer(ControlNodeContainer(withTranslation()(ControlNode)))
