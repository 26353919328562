import React from "react"
import styled from "styled-components"
import icon_check from "images/checkBasic.png"
import icon_check_select from "images/checkBasic_select.png"

const TableCheckBox = ({ style = {}, check, setCheck, disabled = false }) => {
  return (
    <StyledCheckBox style={{ ...style }}>
      <CheckButton
        onClick={(e) => {
          e.stopPropagation()
          if (disabled) return
          setCheck(!check)
        }}
      >
        <CheckIcon src={check ? icon_check_select : icon_check} alt="체크아이콘" />
      </CheckButton>
    </StyledCheckBox>
  )
}

const StyledCheckBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const CheckButton = styled.button``
const CheckIcon = styled.img`
  width: 22px;
  height: 22px;
`

export default TableCheckBox
