// 판매관리 > 판매관리
import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { downExcel, dateFormat } from "../../../utils/util"

import CommonContainer from "../../../containers/CommonContainer"
import AddDevItemContainer from "../../../containers/AddDevItemContainer"

import SalesInfoPopup from "../../popup/SalesManage/SalesInfoPopup"

import { SkyButton, YellowButton, GrayButton } from "../../component/common/Button"
import PageName from "../../component/common/PageName"
// import ComboBox from "../../component/common/ComboBox"
import { TableComponent } from "../../component/common/Table"
import DateBox from "../../component/common/DateBox"
import InputBox from "../../component/common/InputBox"
import CodeHelpBox from "../../component/common/CodeHelpBox"

// import icon_search from "images/search.png"
// import CodeHelpItemPopup from "../../popup/CodeHelpItemPopup"

const AddDevItem = (props) => {
  
  const { t, initDataList, getDataList, saveDataList, setDataList, getComboList } = props
  
  const { 
    itemList: dataList, 
    ITEM_LIST: actionType, 
  } = props

  const [itemNo, setItemNo] = useState("")

  useEffect(() => {
    getData()

    return async () => await initDataList(actionType)
  }, [])

  const getData = async () => {}

  const onClickSearch = async () => {
    const data = {
      itemNo,
      // itemName=${data.itemName}&itemNo=${data.itemNo}&itemClass1=${data.itemClass1}&itemClass2=${data.itemClass2}&itemClass3
    }

    await getDataList(actionType, data)
  }

  return (
    <Container>
      <FlexBox justifyContent={"space-between"}>
        <PageName name={'품목관리'} state={false} setState={() => {}} />
        {/* <GrayButton onClick={onClickExcelDownload}>엑셀 다운로드</GrayButton> */}
      </FlexBox>
      <Box>
        <WhiteBox>
          <SearchBox>
            <div>
              <Text>{'품번'}</Text>
              <InputBox type="text" value={itemNo} onChange={(e) => setItemNo(e.target.value)} />
            </div>
            <FlexBox>
              <YellowButton onClick={onClickSearch}>{t("search")}</YellowButton>
            </FlexBox>
          </SearchBox>
          <TableComponent
            headList={[
              { title: '품목코드', type: "text" },
              { title: '품번', type: "text" },
              { title: '품명', type: "text" },
            ]}
            bodyList={dataList.map((i) => ({
              seq: i.itemSeq,
              itemSeq: i.itemSeq,
              itemNo: i.itemNo,
              itemName: i.itemName,
            }))}
          />
        </WhiteBox>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  margin-top: 30px;
  padding: 28px 32px 32px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`
const ClassesBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

export default CommonContainer(AddDevItemContainer(withTranslation()(AddDevItem)))
