import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const { initInterLockList, getInterLockList, editInterLockIsUse, initInterLock, getInterLock, editInterLock,  
  initEmergencyAlarmList,  getEmergencyAlarmList, addEmergencyAlarm,  initEmergencyAlarm,  getEmergencyAlarm,  editEmergencyAlarm,  delEmergencyAlarm,  } = actions.EnvControlAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _interLockList: state.EnvControlReducer._interLockList,
  _interLock: state.EnvControlReducer._interLock,
  
  _emergencyAlarmList: state.EnvControlReducer._emergencyAlarmList,
  _emergencyAlarm: state.EnvControlReducer._emergencyAlarm,

})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  initInterLockList: (param) => dispatch(initInterLockList(param)),
  getInterLockList: (param) => dispatch(getInterLockList(param)),
  editInterLockIsUse: (param) => dispatch(editInterLockIsUse(param)),
  initInterLock: (param) => dispatch(initInterLock(param)),
  getInterLock: (param) => dispatch(getInterLock(param)),
  editInterLock: (param) => dispatch(editInterLock(param)),

  initEmergencyAlarmList: (param) => dispatch(initEmergencyAlarmList(param)),
  getEmergencyAlarmList: (param) => dispatch(getEmergencyAlarmList(param)),
  addEmergencyAlarm: (param) => dispatch(addEmergencyAlarm(param)),
  initEmergencyAlarm: (param) => dispatch(initEmergencyAlarm(param)),
  getEmergencyAlarm: (param) => dispatch(getEmergencyAlarm(param)),
  editEmergencyAlarm: (param) => dispatch(editEmergencyAlarm(param)),
  delEmergencyAlarm: (param) => dispatch(delEmergencyAlarm(param)),


})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
