// 작기관리 > 재배일지
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import { withTranslation } from "react-i18next"
import { downExcel } from "../../../utils/util"
import { SkyButton, YellowButton, GrayButton } from "../../component/common/Button"
import PageName from "../../component/common/PageName"

import CommonContainer from "../../../containers/CommonContainer"
import CultivationJournalContainer from "../../../containers/cropManage/CultivationJournalContainer"

import ComboBox from "../../component/common/ComboBox"
import DateBox from "../../component/common/DateBox"
import MultiComboBox from "../../component/common/MultiComboBox"
import { TableComponent } from "../../component/common/Table"

import CultivationJournalAddPopup from "../../popup/CropManage/CultivationJournalAddPopup"
import ImgListPopup from "../../popup/CropManage/imgListPopup"

import CodeHelpBox from "../../component/common/CodeHelpBox"

const CultivationJournal = (props) => {
  const {
    t,
    getComboList,

    sminorComboList,
    cropComboList,

    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,
  } = props

  const {
    getCropReportList,
    setCropReportList,
    _cropReportList,
    getCropReportInfo,
    setCropReportInfo,
    _cropReportInfo,
    delCropReport,
    getCropReportExcelDown,
  } = props

  const [journalAddPopup, setJournalAddPopup] = useState(false)
  const [imgListPopup, setImgListPopup] = useState(false)
  const [reportSeq, setReportSeq] = useState(-1) //createWrite or Edit
  const [stdDate, setStdDate] = useState(moment().format("YYYY0101"))
  const [endDate, setEndDate] = useState(moment().format("YYYYMMDD"))
  const [dropIndex, setDropIndex] = useState("")
  const [value, setValue] = useState([]) //multiComboBox
  const dict = _translationWords[0] // 변수에 사전 담기
  const [isRow, setIsRow] = useState(0) // 행 클릭시 1로 변경할 변수 - 차건담 2023.04.12

  const [cropName, setCropName] = useState('')

  const [minDate, setMinDate] = useState(new Date()) //날짜 최저일
  const [maxDate, setMaxDate] = useState(new Date()) //날짜 최대일

  useEffect(() => {
    getTranslationWords()
  }, [])

  // 추가 2024.03.18 by 강동우
  const dictCk = (word) => {
    if (dict == undefined || dict == null || dict == {} || dict == "") {
        return word
    } else {
        const result = dict[word] != undefined && dict[word] != null && dict[word] != "" ? dict[word] : word
        return result
    }
  }

  // downloadExcelFile
  const onClickExcelDownload = async () => {
    const result = await getCropReportExcelDown()
    downExcel({ result: result, fileName: dictCk("cropReportList") }) // t("cropReportList")수정 2024.03.18 by 강동우
  }
  // search method
  const onClickSearch = async () => {
    getTranslationWords()
    getData()
  }
  const onClickAdd = async () => {
    setJournalAddPopup(true)
  }

  // edit method
  const onClickEdit = async (seq) => {
    const result = await getCropReportInfo({ cropReportSeq: seq })    
    setReportSeq(seq)
    if (result) {
      setJournalAddPopup(true)
    }
  }

  // row click >> show detail
  const onClickRow = async (seq) => {
    setIsRow(1)
    const result = await getCropReportInfo({ cropReportSeq: seq })
    setReportSeq(seq)
    if (result) {
      setJournalAddPopup(true)
    }
  }  
  
  // del method
  const onClickDelete = async (seq) => {
    const result = await delCropReport({ cropReportSeq: seq })
    if (result) getData()
  }

  const onClickImg = async (seq) => {
    const result = await getCropReportInfo({ cropReportSeq: seq })
    setReportSeq(seq)
    if (result) {
      setImgListPopup(true)
    }
  }
  
  // moveDetail method
  const journalAddPopupHandler = async () => {
    setReportSeq(-1)
    setIsRow(0)
    setJournalAddPopup(false)
    getData()
  }

  const imgListPopupHandler = async () => {
    setReportSeq(-1)
    setImgListPopup(false)
    // alert('close')
    getData()
  }

  // lookUp method
  const getData = async () => {
    await getCropReportList({
      startDate: stdDate,
      endDate: endDate,
      // cropSeq: dropIndex == "" ? "" : dropIndex,
      cropSeq: typeof cropName == 'object' ? cropName['value'] : cropName, // codeHelp 사용으로 JSON 객체의 label값 사용 - 차건담 2023.08.02
      cropActionSeq: value.length > 0 ? value.map((i) => i.value) : value,
    })
  }
  // category method
  const categoryData = async () => {
    await getComboList({ queryKind: "SMinor" })
    await getComboList({ queryKind: "Crop" })
  }

  useEffect(() => {
    categoryData()
    return async () => {
      await setCropReportList([])
      await setCropReportInfo({})
    }
  }, [])
  
  useEffect(() => {
    console.log("CultivationJournal > cropName:", cropName)
  }, [cropName])

  // 달력 날짜 제한 관련
  useEffect(() => {

    // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
    const originalDate = new Date(stdDate.substr(0, 4) + '-' + stdDate.substr(4, 2) + '-' + stdDate.substr(6, 2));
    const modifiedDate = originalDate.setDate(originalDate.getDate() + 10000);

    setMinDate(new Date(stdDate.substr(0,4)+'-'+stdDate.substr(4,2)+'-'+stdDate.substr(6,2)));
    setMaxDate(modifiedDate);
    // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
    // console.log('dateFr(1) : ', dateFr)
    // console.log('dateTo(1) : ', dateTo)

  }, [stdDate])

  return (
    <Container>
      <ImgListPopup
        open={reportSeq >= 0 && imgListPopup && _cropReportInfo.imageUri.length > 0 ? imgListPopup : false}
        onClose={imgListPopupHandler}
        infoItem={reportSeq >= 0 && imgListPopup ? _cropReportInfo : false}
      />
      <CultivationJournalAddPopup
        open={journalAddPopup}
        onClose={journalAddPopupHandler}
        infoItem={reportSeq >= 0 && journalAddPopup ? _cropReportInfo : false}
        isRowClick={isRow}
      />
      <FlexBox justifyContent={"space-between"}>
        <PageName name={dict['cropReport']} state={false} setState={() => {}} />
        <GrayButton onClick={onClickExcelDownload}>{dict['downloadExcelFile']}</GrayButton>
      </FlexBox>
      <Box>
        <WhiteBox>
          <SearchBox>
            <div style={{ display: "flex" }}>
              <Text>{dict['regDate']}</Text>
              <DateBox
                style={{
                  width: "120px",
                  height: "40px",
                  marginRight: "5px",
                  zIndex: 99 ,
                }}
                stdDate={stdDate}
                setStdDate={setStdDate}
              />
              <p style={{ width: "15px" }}>~</p>
              <DateBox
                style={{
                  width: "120px",
                  height: "40px",
                  zIndex: 99 ,
                }}
                stdDate={endDate}
                setStdDate={setEndDate}
                minDate={minDate} // 날짜 최저일
                maxDate={maxDate} // 날짜 최대일
              />
              <Text>{dict['cropName']}</Text>
              {/* <ComboBox
                style={{}}
                defaultValue={dict['select']}
                hasDefault={true}
                items={cropComboList.map((i) => ({ value: i.cropSeq, label: i.cropName }))}
                value={dropIndex}
                setValue={setDropIndex}
              /> */}
              <CodeHelpBox // ComboBox에서 CodeHelpBox로 변경 - 차건담 2023.07.13
                style={{}}
                placeholder={dict['cropName']}
                codeHelpSeq={3}
                // codeHelpParam={{}}
                value={cropName}
                setValue={setCropName}
              />
            </div>
            <FlexBox>
              <YellowButton onClick={onClickSearch}>{dict['search']}</YellowButton>
              <SkyButton style={{ marginLeft: "14px" }} onClick={onClickAdd}>
                {dict['add']}
              </SkyButton>
            </FlexBox>
          </SearchBox>
          <ClassesBox>
            <div>
              <Text>{dict['cropAction']}</Text>

              <MultiComboBox
                style={{ width: "590px", zIndex: 90 }}
                placeholder={""}
                items={sminorComboList.filter((i) => i.majorSeq == 1012).map((i) => ({ value: i.minorSeq, label: i.minorName }))}
                value={value}
                setValue={setValue}
              />
            </div>
          </ClassesBox>

          <TableComponent
            headList={[
              { title: dict['ranking'] },
              { title: dict['regDate'] , type: "date" },
              { title: dict['stdCropName'] , type: "text" },
              { title: dict['workCenterName'] , type: "text" },
              { title: dict['growthStep'] , type: "text" },
              { title: dict['cropAction'] , type: "text" },
              { title: dict['cropEvent'] , type: "text" },
              { title: dict['quantity'] , type: "comma" },
              { title: dict['image'] , type: "img" },
              { title: dict['importWork'] , type: "text" },
              { title: dict['remark'] , type: "text" },
              { title: dict["actions"] }, // 수정 2024.03.18 by 강동우 
              //{ title: `${t("actions")}` },
            ]}
            bodyList={((_cropReportList && _cropReportList) || []).map((i, idx) => ({
              seq: i.cropReportSeq,
              no: _cropReportList.length - idx,
              cropReportDate: i.cropReportDate,
              cropName: i.cropName,
              workCenterName: i.workCenterName,
              growthStepName: i.growthStepName,
              cropActionName: i.cropActionName,
              cropEventName: i.cropEventName,
              cropReportQty: i.cropReportQty == 0 ? "" : i.cropReportQty,
              imageUri: i.imageUri.length > 0 ? i.imageUri[0].fileUri : "",
              work: i.work,
              remark: i.remark,
              Actions: true,
            }))}
            dict={dict}
            onClickEdit={(seq) => onClickEdit(seq)}
            onClickDelete={(seq) => onClickDelete(seq)}
            onClickImg={(seq) => onClickImg(seq)}
            onClick={(seq) => onClickRow(seq['seq'])} // 행 클릭시 상세 정보 창 띄어주는 함수 - 차건담 2023.04.12
          />
        </WhiteBox>
      </Box>
    </Container>
  )
}

export default CommonContainer(CultivationJournalContainer(withTranslation()(CultivationJournal)))

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  margin-top: 30px;
  padding: 28px 32px 32px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`
const ClassesBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
