// 생산관리 > 인력관리
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import moment from "moment"
import { withTranslation } from "react-i18next"
import { downExcel } from "../../../utils/util"
import { SkyButton, YellowButton, GrayButton } from "../../component/common/Button"
import PageName from "../../component/common/PageName"

import CommonContainer from "../../../containers/CommonContainer"
import StaffManagementContainer from "../../../containers/production/StaffManagementContainer"

import ComboBox from "../../component/common/ComboBox"
import DateBox from "../../component/common/DateBox"
import { TableComponent } from "../../component/common/Table"

import StaffManagementAddPopup from "../../popup/Production/StaffManagementAddPopup"
import ImgListPopup from "../../popup/Production/imgListPopup"

const CultivationJournal = (props) => {
    const {
        t,
        getComboList,

        sminorComboList,
        cropComboList,
        initTranslationWords,
        getTranslationWords,
        _translationWords,
        replaceWord,
    } = props

    const { getStaffList, getStaffInfo, _staffList, _staffInfo, delStaff, getStaffExcelDown, setStaffList, setStaffInfo } = props

    const [staffManagementAddPopup, setStaffManagementAddPopup] = useState(false)
    const [staffSeq, setStaffSeq] = useState(-1) //createWrite or Edit
    const [stdDate, setStdDate] = useState(moment().format("YYYY0101"))
    const [endDate, setEndDate] = useState(moment().format("YYYYMMDD"))
    const [dropIndex, setDropIndex] = useState("")
    // const [value, setValue] = useState([]) //multiComboBox
    const [imgListPopup, setImgListPopup] = useState(false) //이미지팝업
    const dict = _translationWords[0] // 변수에 사전 담기

    const [minDate, setMinDate] = useState(new Date()) //날짜 최저일
    const [maxDate, setMaxDate] = useState(new Date()) //날짜 최대일

    useEffect(() => {
        getTranslationWords()
    }, [open])

    // downloadExcelFile
    const onClickExcelDownload = async () => {
        const result = await getStaffExcelDown()
        downExcel({ result: result, fileName: dict["cropReportList"] })
    }
    // search method 조회
    const onClickSearch = async () => {
        getData()
    }
    // // 신규추가
    // const onClickAdd = async () => {
    //   setStaffManagementAddPopup(true)
    // }

    // 신규추가
    const onClickAdd = async (seq) => {
        const result = await getStaffInfo({ staffSeq: seq })
        setStaffSeq(seq)
        if (result) {
            setStaffManagementAddPopup(true)
        }
    }
    // edit method 수정
    const onClickEdit = async (seq) => {
        const result = await getStaffInfo({ staffSeq: seq })
        setStaffSeq(seq)
        if (result) {
            setStaffManagementAddPopup(true)
        }
    }

    // del method 삭제
    const onClickDelete = async (seq) => {
        const result = await delStaff({ staffSeq: seq })
        if (result) getData()
    }

    const onClickImg = async (seq) => {
        const result = await getStaffInfo({ staffSeq: seq })
        setStaffSeq(seq)
        if (result) {
            setImgListPopup(true)
        }
    }

    // moveDetail method
    const staffManagementAddPopupHandler = async () => {
        setStaffSeq(-1)
        setStaffManagementAddPopup(false)
        getData()
    }

    const imgListPopupHandler = async () => {
        setStaffSeq(-1)
        setImgListPopup(false)
        // alert('close')
        getData()
    }

    // lookUp method
    const getData = async () => {
        await getStaffList({
            startDate: stdDate,
            endDate: endDate,
            // cropSeq: dropIndex == "" ? dropIndex : dropIndex,
            // cropActionSeq: value.length > 0 ? value.map((i) => i.value) : value,
        })
    }
    // category method
    const categoryData = async () => {
        await getComboList({ queryKind: "SMinor" })
        await getComboList({ queryKind: "Crop" })
    }
    useEffect(() => {
        categoryData()
        return async () => {
            await setStaffList([])
            await setStaffInfo({})
        }
    }, [])

    // 달력 날짜 제한 관련
    useEffect(() => {
        // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
        const originalDate = new Date(stdDate.substr(0, 4) + "-" + stdDate.substr(4, 2) + "-" + stdDate.substr(6, 2))
        const modifiedDate = originalDate.setDate(originalDate.getDate() + 10000)

        setMinDate(new Date(stdDate.substr(0, 4) + "-" + stdDate.substr(4, 2) + "-" + stdDate.substr(6, 2)))
        setMaxDate(modifiedDate)
        // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
        // console.log('dateFr(1) : ', dateFr)
        // console.log('dateTo(1) : ', dateTo)
    }, [stdDate])

    return (
        <Container>
            <ImgListPopup
                open={staffSeq >= 0 && imgListPopup && _staffInfo.imageUri.length > 0 ? imgListPopup : false}
                onClose={imgListPopupHandler}
                infoItem={staffSeq >= 0 && imgListPopup ? _staffInfo : false}
            />

            <StaffManagementAddPopup
                open={staffManagementAddPopup}
                onClose={staffManagementAddPopupHandler}
                infoItem={staffSeq >= 0 && staffManagementAddPopup ? _staffInfo : false}
                // infoItem={staffManagementAddPopup ? _staffInfo : false}
            />
            <FlexBox justifyContent={"space-between"}>
                <PageName name={dict["staffManagement"]} state={false} setState={() => {}} />
                {/* <GrayButton onClick={onClickExcelDownload}>{t("downloadExcelFile")}</GrayButton> */}
            </FlexBox>
            <Box>
                <WhiteBox>
                    <SearchBox>
                        <div style={{ display: "flex" }}>
                            <Text>{dict["regDate"]}</Text>
                            <DateBox
                                style={{
                                    width: "120px",
                                    height: "40px",
                                    marginRight: "5px",
                                }}
                                stdDate={stdDate}
                                setStdDate={setStdDate}
                            />
                            <p style={{ width: "15px" }}>~</p>
                            <DateBox
                                style={{
                                    width: "120px",
                                    height: "40px",
                                }}
                                stdDate={endDate}
                                setStdDate={setEndDate}
                                minDate={minDate} // 날짜 최저일
                                maxDate={maxDate} // 날짜 최대일
                            />
                            {/* 작기명 */}
                            {/* <Text>{t("cropName")}</Text>
              <ComboBox
                style={{}}
                defaultValue={t("select")}
                hasDefault={true}
                items={cropComboList.map((i) => ({ value: i.cropSeq, label: i.cropName }))}
                value={dropIndex}
                setValue={setDropIndex}
              /> */}
                        </div>
                        {/* onClick={(seq) => onClickAdd(0)} */}
                        <FlexBox>
                            <YellowButton onClick={onClickSearch}>{dict["search"]}</YellowButton>
                            <SkyButton style={{ marginLeft: "14px" }} onClick={(seq) => onClickAdd(0)}>
                                {dict["add"]}
                            </SkyButton>
                        </FlexBox>
                    </SearchBox>
                    <ClassesBox>
                        {/* 재배활동 */}
                        {/* <div>
              <Text>{t("cropAction")}</Text>

              <MultiComboBox
                style={{ width: "590px" }}
                placeholder={""}
                items={sminorComboList.filter((i) => i.majorSeq == 1012).map((i) => ({ value: i.minorSeq, label: i.minorName }))}
                value={value}
                setValue={setValue}
              />
            </div> */}
                    </ClassesBox>

                    <TableComponent
                        headList={[
                            { title: dict["ranking"] },
                            { title: dict["image"], type: "img" },
                            { title: dict["employeeNumber"] },
                            { title: dict["userName"] },
                            { title: dict["cellNo"] },
                            { title: dict["address"], type: "text" },
                            { title: dict["gender"] },
                            { title: dict["remark"], type: "text" },
                            { title: dict["actions"] }, // 수정 2024.03.18 by 강동우
                            //{ title: `${t("actions")}` },
                        ]}
                        bodyList={((_staffList && _staffList) || []).map((i, idx) => ({
                            seq: i.staffSeq,
                            // no: _staffList.length - idx,
                            no: 1 + idx,
                            imageUri: i.imageUri.length > 0 ? i.imageUri[0].fileUri : "",
                            staffUniqueNumber: i.staffUniqueNumber,
                            staffName: i.staffName,
                            staffNumber: i.staffNumber,
                            staffAddress: i.staffAddress,
                            gender: i.gender == 1 ? dict["man"] : i.gender == 2 ? dict["woman"] : "",
                            remark: i.remark,
                            Actions: true,
                        }))}
                        dict={dict}
                        onClickEdit={(seq) => onClickEdit(seq)}
                        onClickDelete={(seq) => onClickDelete(seq)}
                        onClickImg={(seq) => onClickImg(seq)}
                    />
                </WhiteBox>
            </Box>
        </Container>
    )
}

export default CommonContainer(StaffManagementContainer(withTranslation()(CultivationJournal)))

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`
const FlexBox = styled.div`
    display: flex;
    justify-content: ${(props) => props.justifyContent || "flex-start"};
    align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
    overflow: hidden;
    display: flex;
    height: 100%;
`
const WhiteBox = styled.div`
    background-color: #fff;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 0px;
    margin-top: 30px;
    padding: 28px 32px 32px;
    border: 1px solid #fff;
    border-radius: 9px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
`
const Text = styled.p`
    width: 80px;
    color: #555555;
    font-size: 14px;
`
const ClassesBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 17px;
    & > div {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }
`
