// 작기정보 생육정보 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import moment from "moment"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import CropInfoContainer from "../../../containers/cropManage/CropInfoContainer"
import RequiredMark from "../../component/RequiredMark"
import ComboBox from "../../component/common/ComboBox"
import InputBox from "../../component/common/InputBox"
import TableCheckBox from "../../component/common/TableCheckBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import AlertPopup from "../AlertPopup"

import CommonContainer from "../../../containers/CommonContainer"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "600px",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const CropGrowthAddPopupPlan = (props) => {
  const classes = useStyles()
  const { t, _codeList, _cropGrowthList, _cropGrowth, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  const { open, onClose, currentCrop } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const [growthStepName, setGrowthStepName] = useState(0)
  const [dateFr, setDateFr] = useState("")
  const [date, setDate] = useState("")
  const [work, setWork] = useState("")
  const [isHarv, setIsHarv] = useState(false)
  const [isPlant, setIsPlant] = useState(false)
  const [checkPoint, setCheckPoint] = useState("")
  const [remark, setRemark] = useState("")
  const [cropPlanQty, setCropPlanQty] = useState("")
  const dict = _translationWords[0] // 변수에 사전 담기
  

  useEffect(() => {
    getTranslationWords()
    if (_cropGrowth && Object.keys(_cropGrowth).length != 0) {
      setGrowthStepName(_cropGrowth.growthStepSeq)
      setDateFr(_cropGrowth.dateFr)
      setDate(_cropGrowth.date)
      setWork(_cropGrowth.work)
      setIsHarv(_cropGrowth.isHarv == 1)
      setIsPlant(_cropGrowth.isPlant == 1)
      setCheckPoint(_cropGrowth.checkPoint)
      setRemark(_cropGrowth.remark)
      setCropPlanQty(_cropGrowth.cropPlanQty)
    }

    console.log('currentCrop')
    console.log(currentCrop)

    return () => {
      setGrowthStepName(0)
      setDateFr("")
      setDate("")
      setWork("")
      setIsHarv(false)
      setIsPlant(false)
      setCheckPoint("")
      setRemark("")
      setCropPlanQty("")
    }
  }, [open])

  // const onClickSave = () => {
  //   if (growthStepName == 0) return setAlertPopup({ open: true, text: t("n.Please select a product.", { n: t("growthStep") }) })
  //   else if (date == "") return setAlertPopup({ open: true, text: t("n.Invalid value. Please try again.", { n: t("elapsedDays") }) })

  //   let param = {
  //     growthStepSeq: growthStepName,
  //     dateFr: dateFr || moment().format("YYYYMMDD"),
  //     date: date || 0,
  //     work,
  //     checkPoint,
  //     isHarv: isHarv ? 1 : 0,
  //     isPlant: isPlant ? 1 : 0,
  //     remark,
  //   }
  //   if (Object.keys(_cropGrowth).length != 0) {
  //     param.cropGrowthSeq = _cropGrowth.cropGrowthSeq
  //   }
  //   onClose(t("save"), param)
  // }

  const onClickCancel = () => {
    onClose(dict['cancel'], null)
  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose(null)}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <Container>
        <Title>{dict['stdCropGrowth']}</Title>
        <div style={{ flex: 1 }}>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['growthStep']}
            </Label>
            <ComboBox
              style={{ width: "400px" }}
              defaultValue={dict['select']}
              hasDefault={true}
              items={
                _codeList.length > 0 &&
                _codeList.filter((i) => i.majorKind == 2 && i.majorSeq == 1009).length > 0 &&
                _codeList.filter((i) => i.majorKind == 2 && i.majorSeq == 1009)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
              }
              disabled={true}
              value={growthStepName}
              setValue={setGrowthStepName}
            />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['dateFr']}
            </Label>
            <InputBox style={{ width: "400px" }} type="text" value={dateFr} onChange={(e) => setDateFr(e.target.value)} disabled />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['elapsedDays']}
            </Label>
            <InputBox style={{ width: "400px" }} type="text" value={date} onChange={(e) => setDate(e.target.value)} disabled/>
          </FlexBox>
          <FlexBox>
            <Label>{dict['importWork']}</Label>
            {/* <InputBox type="text" value={work} onChange={(e) => setWork(e.target.value)} disabled/> */}
            <TextAreaBox width={400} value={work} setValue={setWork} disabled></TextAreaBox>
          </FlexBox>
          <FlexBox>
            <Label>{dict['productionPlanQuantity']}</Label>
            <InputBox style={{ width: "400px" }} type="text" value={cropPlanQty} onChange={(e) => setCropPlanQty(e.target.value)} disabled/>
          </FlexBox>
          <FlexBox>
            <Label>{dict['isHarv']}</Label>
            <TableCheckBox check={isHarv} setCheck={setIsHarv} disabled/>
          </FlexBox>
          <FlexBox>
            <Label>{dict['isPlant']}</Label>
            <TableCheckBox check={isPlant} setCheck={setIsPlant} disabled/>
          </FlexBox>
          <FlexBox alignItems="flex-start">
            <Label>{dict['checkPoint']}</Label>
            <TextAreaBox width={400} height={200} value={checkPoint} setValue={setCheckPoint} disabled></TextAreaBox>
          </FlexBox>
          <FlexBox alignItems="flex-start">
            <Label>{dict['remark']}</Label>
            <TextAreaBox width={400} height={200} value={remark} setValue={setRemark} disabled></TextAreaBox>
          </FlexBox>
        </div>
        <ButtonBox>
          <Button bgColor={"#fff"} hover={"rgba(174, 174, 174, 0.22)"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={onClickCancel}>
            {dict['cancel']}
          </Button>
          {/* <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {t("save")}
          </Button> */}
        </ButtonBox>
      </Container>
    </Dialog>
  )
}
// overflow-y: hidden; //팝업 스크롤없애는 css

const Container = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 23px 26px 40px;
`
const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 34px;
  }
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  flex: 1;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

CropGrowthAddPopupPlan.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CropInfoContainer(CommonContainer(withTranslation()(CropGrowthAddPopupPlan)))
