import React, { useState, forwardRef } from "react"
import styled, { css, createGlobalStyle } from "styled-components"

import { WidthToDP } from "../../../utils/util"

import icon_defaultImg from "images/defaultImg.png"
import ImgListPopup from "../../popup/CropManage/imgListPopup"
import imgListPopup from "../../popup/CropManage/imgListPopup"

const AttchImageBox = (props) => {
  const { style = {}, images, setImages, maxCnt, disabled, infoItem } = props

  //이미지 미리보기용 base64변경
  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const [isPopup, setIsPopup] = useState(false);
  const closeImgPopup = () => {
    setIsPopup(false)
  }
  return (
    <StyledAttchImageBox alignItems={"flex-start"} style={{}}>
      <ImgListPopup
        // open={reportSeq >= 0 && imgListPopup && _cropReportInfo.imageUri.length > 0 ? imgListPopup : false}
        // onClose={imgListPopupHandler}
        // infoItem={reportSeq >= 0 && imgListPopup ? _cropReportInfo : false}
        open={isPopup}
        onClose={closeImgPopup}
        infoItem={infoItem} // ImgListPopup 컴포넌트에 infoItem 전달 - 차건담 2023.04.13
      />
      <input
        id="farmImgaeInputRef"
        style={{ display: "none" }}
        type="file"
        accept={"image/*"}
        value={""}
        onChange={async (e) => {
          if (e.target.files[0]) {
            const file = e.target.files[0]
            // console.log('@@@@@@@@@@@@@@파일',file)
            // console.log('@@@@@@@@@@@@@@파일사이즈file.size::',file.size)
            if (file.size > 20 * 1024 * 1024) { // 파일용량이 20MB 넘을시 반환
              alert("파일 용량이 20MB를 넘어 저장이 불가합니다.");
              return;
            }

            const base64 = await toBase64(file)
            const temp = [...images]
            temp.push({ base64, file, imageUrl: null })
            setImages(temp)
          }
        }}
        disabled={disabled==1}
      />
      <button
        style={{ width: "", height: "" }}
        onClick={() => {
          if (images.length < maxCnt && disabled != 1) {
            document.getElementById("farmImgaeInputRef").click()
          }
        }}
      >
        <img style={{ width: "80px", height: "80px", borderRadius: `3px` }} src={icon_defaultImg} alt={"logo"} disabled={disabled==1}/>
      </button>

      {images.map((image, imageIndex) => (
        <div
          key={imageIndex}
          style={{
            position: "relative",
            width: `112px`,
            height: `80px`,
            marginLeft: `12px`,
            border: `1px solid #dddddd`,
          }}
        >
          <img style={{ width: `112px`, height: `80px` }} src={image.base64 || image.imageUrl} alt={""} 
                onClick={() => {setIsPopup(true)}}
          />
          <button
            onClick={() => {
              if(disabled != 1){
                const temp = [...images]
                temp.splice(imageIndex, 1)
                setImages(temp)
              }
            }}
            style={{
              backgroundColor: "#abbfae",
              position: "absolute",
              top: `2px`,
              right: `2px`,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: `10px`,
              height: `10px`,
              paddingBottom: `2px`,
              border: `1px solid #fff`,
              borderRadius: "50%",
              color: "#000",
              fontSize: `9px`,
              fontWeight: 700,
            }}
          >
            X
          </button>
        </div>
      ))}
    </StyledAttchImageBox>
  )
}

const StyledAttchImageBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`

export default AttchImageBox
