// 환경제어 > 설비제어
import React, { useEffect, useState } from "react"
import PageName from "../../component/common/PageName"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { createTheme, makeStyles, ThemeProvider, withStyles } from "@material-ui/core/styles"
import { Dialog, Button } from "@material-ui/core"
import { ArrowBackIos, Stop, ArrowForwardIos, Timer } from "@material-ui/icons"

import Tab from "../../component/common/Tab"
import FacilityControlContainer from "../../../containers/envControl/FacilityControlContainer"
import BasicPopup from "../../popup/BasicPopup"

import Radio from "../../component/common/Radio"
import InputBox from "../../component/common/InputBox"
import Switch from "../../component/common/Switch"

import CircularIndeterminate from "../../component/common/CircularIndeterminate" //로딩화면
import CommonContainer from "../../../containers/CommonContainer"
import { SkyButton } from "../../component/common/Button"
import { BallTriangle } from "react-loader-spinner"

const FacilityControl = (props) => {
    const {
        t,
        getEquipControlList,
        _equipControlForRevList: equipControlList,
        _equipControlOnOffList: equipOnOffControlList,
        editEquipControlAuto,
        editEquipControlSetting,
        getAutoEnvWorkCenterList,
        _workCenterList: workCenterList,
        ctrlEquipControlSwitchMod,

        _equipControlStatus,
        getEquipControlStatus,
        initEquipControlStatus,
    } = props

    const { initTranslationWords, getTranslationWords, _translationWords } = props

    const [tabIndex, setTabIndex] = useState(0) //workcenterseq
    // const [popup, setPopup] = useState({ open: false, text: "" })
    const [settingChange, setSettingChange] = useState(false) //변경클릭시 화면전환을 위한 useState
    const [loading, setLoading] = useState(false)

    const [allChangeModeLoading, setAllChangeModeLoading] = useState(true) // 자동수동-로딩중

    const [farmSeqGG, setFarmSeqGG] = useState(0) //임시 곤충농장 ALL자동 수동버튼 보이게하는 변수

    let instCycle = null

    let dict = _translationWords[0] // 변수에 사전 담기

    useEffect(() => {
        getTranslationWords()
        getData()

        if (instCycle === null) instCycle = setInterval(getMainDataListCycle, 5000)

        return async () => {
            clearInterval(instCycle)
        }
    }, [])

    useEffect(() => {
        if (instCycle === null) instCycle = setInterval(getMainDataListCycle, 5000)

        return async () => {
            clearInterval(instCycle)
        }
    }, [tabIndex])

    useEffect(() => {
        if (workCenterList.length > 0) {
            setTabIndex(workCenterList[0].workCenterSeq)
            getMainDataList(workCenterList[0].workCenterSeq)

            setFarmSeqGG(workCenterList[0].farmSeq)
        }
    }, [workCenterList])

    const dictCk = (word) => {
        if (dict == undefined || dict == null || dict == {} || dict == "") {
            return word
        } else {
            const result = dict[word] != undefined && dict[word] != null && dict[word] != "" ? dict[word] : word
            return result
        }
    }

    const getData = async () => {
        await getAutoEnvWorkCenterList()
    }

    const getMainDataListCycle = () => {
        console.log("[main] data loading...", tabIndex)

        //if( tabIndex > 0 )
        getMainDataList(tabIndex)
    }

    const getMainDataList = async (workCenterSeq) => {
        await getEquipControlList({ workCenterSeq })
    }

    const onClickWorkCenter = (value) => {
        getMainDataList(value)
        setTabIndex(value)
    }

    // // 자동 일괄 전환버튼 (프론트에서 2초마다 설비 for문)
    // const allpostAutoSett = async (kind) => {
    //   let i = 0;

    //   async function runLoop(kind) {
    //     if (i >= kind.length) {
    //       setAllChangeModeLoading(true)
    //       return;
    //     }

    //     setAllChangeModeLoading(false)
    //     await editEquipControlAuto({
    //       equipSeq: kind[i].equipSeq,
    //       controlKind: 10200001,
    //     });

    //     // console.log('변수값 길이', kind.length);
    //     // console.log('변수값', kind[i].equipSeq);
    //     i++; // 인덱스 증가

    //     setTimeout(() => {runLoop(kind); // 재귀 호출
    //     }, 2000); // 2초 후에 실행

    //   }

    //   await runLoop(kind); // 초기 호출

    // }

    // // 수동 일괄 전환버튼 (프론트에서 2초마다 설비 for문)
    // const allpostManualSett = async (kind) => {
    //   let v = 0;

    //   async function runLoop(kind) {
    //     if (v >= kind.length) {
    //       setAllChangeModeLoading(true)
    //       v = 0;
    //       return;
    //     }

    //     setAllChangeModeLoading(false)
    //     await editEquipControlAuto({
    //       equipSeq: kind[v].equipSeq,
    //       controlKind: 10200002,
    //     });

    //     // console.log('변수값 길이', kind.length);
    //     // console.log('변수값', kind[v].equipSeq);
    //     v++; // 인덱스 증가
    //     // console.log("@@@@@@@@@@@@vvvv@@@@@@@@", v)

    //     setTimeout(() => {runLoop(kind); // 재귀 호출
    //     }, 2000); // 2초 후에 실행
    //   }

    //   await runLoop(kind); // 초기 호출
    //   // await runLoop(kind); // 두번쨰 호출
    // }
    ////////////////////////////////////////////////////////////////

    // 자동 일괄 전환버튼
    const allpostAutoSett = async (kind) => {
        await editEquipControlAuto({
            equipSeq: -1, //설비 전체 명령 정지값
            controlKind: 10200001, // 자동
        })

        let equipLength = kind.length // 사용중인 설비 갯수

        // 전환시 화면 로딩 퍼즈
        if (!loading) {
            //&& result) {
            setLoading(true) // 로딩중
            setTimeout(() => {
                setLoading(false) // 2초후 로딩 해제
                setSettingChange(true)
            }, 2000 * equipLength)
        }
    }

    // 수동 일괄 전환버튼
    const allpostManualSett = async (kind) => {
        await editEquipControlAuto({
            equipSeq: -1, //설비 전체 명령 정지값
            controlKind: 10200002, // 수동
        })

        let equipLength = kind.length // 사용중인 설비 갯수

        // 전환시 화면 로딩 퍼즈
        if (!loading) {
            //&& result) {
            setLoading(true) // 로딩중
            setTimeout(() => {
                setLoading(false) // 2초후 로딩 해제
                setSettingChange(true)
            }, 2000 * equipLength)
        }
    }

    return (
        <TopContainer>
            <CircularIndeterminate open={loading} />
            {/* <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: popup.text })} content={popup.text} selectedValue={[t("confirm")]} /> */}
            <PageName name={dictCk("equipControl")} state={false} setState={() => {}} />
            <Tab
                list={workCenterList.map((i) => ({ seq: i.workCenterSeq, name: i.workCenterName }))}
                tabIndex={tabIndex}
                onClick={(i) => {
                    // setTabIndex(i.seq)
                    onClickWorkCenter(i.seq)
                }}
            />
            <Box>
                {/* 서우엠에스(곤충농장) && 라라랜드 ALL 자동 수동 버튼보이게 start -------------------------------------------------*/}
                <FlexBox style={{ display: farmSeqGG == 506 || farmSeqGG == 520 ? "flex" : "none" }}>
                    <SkyButton
                        style={{ marginBottom: "10px", marginRight: "10px", pointerEvents: allChangeModeLoading ? "auto" : "none" }}
                        onClick={() => allpostAutoSett(equipControlList.concat(equipOnOffControlList))}
                    >
                        {dictCk("AllAuto")}
                    </SkyButton>
                    <SkyButton
                        style={{ marginBottom: "10px", marginRight: "10px", pointerEvents: allChangeModeLoading ? "auto" : "none" }}
                        onClick={() => allpostManualSett(equipControlList.concat(equipOnOffControlList))}
                    >
                        {dictCk("AllManual")}
                    </SkyButton>

                    <div style={{ display: allChangeModeLoading ? "none" : "flex", marginBottom: "10px" }}>
                        {" "}
                        <BallTriangle height={40} width={50} />{" "}
                    </div>
                </FlexBox>
                {/* 서우엠에스(곤충농장) && 라라랜드 ALL 자동 수동 버튼보이게 end --------------------------------------------------*/}

                {/* 정역파트 start */}
                {equipControlList.length > 0 && (
                    <SettBox>
                        <div style={{ display: "flex", marginBottom: "19px" }}>
                            <DefaltFont opacity="1" fs="16px" color="#3e7347">
                                {dict["forRev"]}
                            </DefaltFont>
                        </div>
                        {equipControlList &&
                            equipControlList.map((i, idx) => (
                                <div key={idx}>
                                    <PreRevComponent
                                        data={i}
                                        postData={editEquipControlAuto}
                                        postSettData={editEquipControlSetting}
                                        // popup={popup}
                                        // setPopup={setPopup}
                                        t={t}
                                        dict={_translationWords[0]}
                                        setSettingChange={setSettingChange}
                                        ctrlEquitBtn={ctrlEquipControlSwitchMod}
                                        // tabIndex={tabIndex}
                                        loading={loading}
                                        setLoading={setLoading}
                                        getStatus={getEquipControlStatus}
                                        dataStatus={_equipControlStatus}
                                        initStatus={initEquipControlStatus}
                                        allChangeModeLoading={allChangeModeLoading}
                                    />
                                </div>
                            ))}
                    </SettBox>
                )}
                {/* 정역파트 end */}

                {/* on/dff파트 start */}
                {equipOnOffControlList.length > 0 && (
                    <SettBox>
                        {/* onoff */}
                        <div style={{ display: "flex", marginBottom: "19px" }}>
                            <DefaltFont opacity="1" fs="16px" color="#3e7347">
                                {dict["onOff"]}
                            </DefaltFont>
                        </div>

                        {equipOnOffControlList &&
                            equipOnOffControlList.map((item, index) => (
                                <div key={index}>
                                    <TurnOnOffComponent
                                        onOffdata={item}
                                        postData={editEquipControlAuto}
                                        postSett={editEquipControlSetting}
                                        t={t}
                                        dict={_translationWords[0]}
                                        setSettingChange={setSettingChange}
                                        allChangeModeLoading={allChangeModeLoading}
                                        loading={loading}
                                        setLoading={setLoading}
                                    />
                                </div>
                            ))}
                    </SettBox>
                )}
                {/* on/dff파트 end */}
            </Box>
        </TopContainer>
    )
}

export default FacilityControlContainer(CommonContainer(withTranslation()(FacilityControl)))

const TopContainer = styled.div`
    width: 100%;
    height: 100%;
    &:after {
        content: "";
        display: block;
        height: 42px;
        width: 100%;
    }
`
const Box = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 20px;
`
const SettBox = styled.div`
    display: "flex";
    width: 970px;
    /* 712px; */
    min-height: 220px;
    padding: 30px 42px 25px 35px;
    border-radius: 9px;
    box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.04);
    border: solid 1px #c6c6c6;
    background-color: #f1f1f1;
    margin-bottom: 30px;
`

const DefaltFont = styled.p`
    opacity: ${(props) => (props.opacity ? props.opacity : "0.8")};
    font-size: ${(props) => (props.fs ? props.fs : "14px")};
    font-weight: ${(props) => (props.fw ? props.fw : "")};
    color: ${(props) => (props.color ? props.color : "#555555")};
`
const CustomBtn = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 100px;
    border-radius: 3px;
    font-family: NotoSansCJKkr;
    font-size: 14px;
    background-color: ${(props) => (props.emty ? "" : "#52935d")};
    border: ${(props) => (props.emty ? "1px solid #3e7347" : "none")};
    color: ${(props) => (props.emty ? "#3e7347" : "#ffffff")};
`

const CustomMUIBtn = styled(Button)`
    background: ${(props) =>
        props.radioIndex == 0 && props.isNotUseOpenPer == 0
            ? "none"
            : props.clickBtn == props.btnType && props.manualUnconditionalStop == 0
            ? `linear-gradient(45deg, ${props.color} 30%, ${props.color} 90%)`
            : `none`};
    border: ${(props) =>
        props.radioIndex == 0 && props.isNotUseOpenPer == 0
            ? `1px solid rgba(0, 0, 0, 0.26)`
            : props.clickBtn == props.btnType && props.manualUnconditionalStop == 0
            ? `1px solid ${props.color}`
            : `1px solid #52935d`};
    color: ${(props) => (props.clickBtn == props.btnType ? `#FFFFFF` : `#52935d`)};
    height: 48;
`

const FlexBox = styled.div`
    display: flex;
    justify-content: ${(props) => props.justifyContent || "flex-start"};
    align-items: ${(props) => props.alignItems || "center"};
`

const useStyles = makeStyles((theme) => ({
    root: {
        marginRight: "10px",
    },
}))

// PreRev
// 정역파트 start ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ
const PreRevComponent = (props) => {
    const classes = useStyles()
    const {
        t,
        data,
        postData,
        postSettData,
        setSettingChange,
        // popup, setPopup,
        ctrlEquitBtn,
        loading,
        setLoading,
        getStatus,
        dataStatus,
        initStatus,
        dict,
        allChangeModeLoading,
    } = props

    const [inputValue, setInputValue] = useState(0)
    const [inputCurrValue, setInputCurrValue] = useState(0)
    const [radioIndex, setRadioIndex] = useState(0)
    const [clickBtn, setClickBtn] = useState(10190003) //  1:for, 2:rev, 3:stop
    const [onbtn, setOnBtn] = useState(false) //interval 제어

    const [manualUnconditionalStop, setManualUnconditionalStop] = useState(0) // 수동전환시 무조건 정지 후 가동

    let instCycle = null
    let worktimeCalc = null
    let timer = null
    let dupClickLoading = null

    const stillWorkingEquit = async () => {
        // let result = await getStatus({ equipSeq: data.equipSeq })
        // if (result) setOnBtn(true)
    }

    useEffect(() => {
        if (data) {
            setInputCurrValue(data.openPer)
            setRadioIndex(data.controlKind == 10200001 ? 0 : 1)
            setClickBtn(data.workStatus)
            //화면이 갱신되고 아직 작동중인 설비가 있을 경우
            if (data.workStatus == 10190003 && data.isUpdated == 0) {
                stillWorkingEquit()
            }

            if (data.controlKind == 10200002) {
                setManualUnconditionalStop(0) //수동전환시 dis처리 해제
            }
        }
    }, [data])

    useEffect(() => {
        if (onbtn && dataStatus && dataStatus.isUpdated == 1) {
            setOnBtn(false)
        }
    }, [dataStatus])

    useEffect(() => {
        // if (onbtn && dataStatus && dataStatus.isUpdated == 0 && clickBtn == 10190001) {
        //   //열기
        //   worktimeCalc = (data.workTime * (1 - data.openPer) + 10) * 1000
        //   instCycle = setInterval(async () => {
        //     await getStatus({ equipSeq: data.equipSeq })
        //     console.log("status:open, wait stop, request 2second====")
        //   }, 2000)
        // } else if (onbtn && dataStatus && dataStatus.isUpdated == 0 && clickBtn == 10190002) {
        //   //닫기
        //   worktimeCalc = (data.workTime * data.openPer + 10) * 1000
        //   instCycle = setInterval(async () => {
        //     await getStatus({ equipSeq: data.equipSeq })
        //     console.log("status:close, wait stop, request 2seconde====")
        //   }, 2000)
        // } else if (onbtn && dataStatus && dataStatus.isUpdated == 0 && clickBtn == 10190003) {
        //   // 정지
        //   instCycle = setInterval(async () => {
        //     await getStatus({ equipSeq: data.equipSeq })
        //     console.log("status:wait stop, request 0.5seconde========")
        //   }, 500)
        // }
        // return async () => {
        //   console.log("clear======")
        //   clearInterval(instCycle)
        //   await initStatus()
        //   clearTimeout(timer)
        //   clearTimeout(dupClickLoading)
        // }
    }, [onbtn])

    //설정버튼은 따로 함수로 빼지 않음
    //수동,자동
    const postAutoSett = async (kind) => {
        let result = await postData({
            equipSeq: data.equipSeq,
            controlKind: kind, // 10200001 = 자동, 10200002 = 수동
        })
        if (result) setRadioIndex(radioIndex == 0 ? 1 : 0)

        if (kind == 10200002) {
            setManualUnconditionalStop(1)
        } // 여기서 수동전환 할때 dis처리

        // 전환시 화면 로딩 퍼즈
        if (!loading) {
            //&& result) {
            setLoading(true) // 로딩중
            setTimeout(() => {
                setLoading(false) // 2초후 로딩 해제
                setSettingChange(true)
            }, 2000)
        }
    }

    //정,역,정지
    const postCtrlEquit = async (type) => {
        // //중복클릭방지
        // if (onbtn && dataStatus && dataStatus.isUpdated == 0 && data.workStatus == clickBtn) {
        //   setLoading(true)
        //   dupClickLoading = setTimeout(() => {
        //     setLoading(false)
        //   }, 1200)
        // } else {

        let result = false

        // 전환시 화면 로딩 퍼즈
        if (!loading) {
            //&& result) {
            setLoading(true) // 로딩중
            setTimeout(() => {
                setLoading(false) // 2초후 로딩 해제
                setSettingChange(true)
            }, 2000)
        }

        result = await ctrlEquitBtn({ equipSeq: data.equipSeq, workStatus: type })

        if (result) {
            // const result2 = await getStatus({ equipSeq: data.equipSeq })
            // if (result2) {
            setClickBtn(type)
            setOnBtn(true)
            // }
        }
        // }

        // let result2 = await getStatus({ equipSeq: data.equipSeq })
        // if (result1) {
        //   setLoading(false)
        // }
    }

    // const handleSettingBtnClick = () => {
    //   if (!loading) {
    //     setLoading(true)
    //     timer.current = window.setTimeout(() => {
    //       setLoading(false)
    //     }, 5000)
    //   }
    // }

    return (
        <div style={{ display: "flex", marginBottom: "22px", pointerEvents: allChangeModeLoading ? "auto" : "none" }}>
            {/* 정지릴레이 모드 체크시 자동 수정 전환금지 */}
            <section style={{ display: "flex", alignItems: "center", height: "40px", pointerEvents: data.isUseStControl == 1 ? "none" : "auto" }}>
                <section style={{ width: "130px" }}>
                    <DefaltFont>{data.equipName}</DefaltFont>
                </section>
                <Radio
                    style={{ marginRight: "30px" }}
                    text={dict["auto"]} //자동
                    idx={0}
                    check={radioIndex}
                    setCheck={() => {
                        postAutoSett(10200001)
                    }}
                />
                <Radio
                    style={{ marginRight: "30px" }}
                    text={dict["manual"]} //수동
                    idx={1}
                    check={radioIndex}
                    setCheck={() => {
                        postAutoSett(10200002)
                    }}
                />
            </section>

            {/* 수동조작모드 이벤트 작동안하게  */}
            <div
                style={{
                    display: "flex",
                    pointerEvents:
                        data.isNotUseManual == 1
                            ? "none"
                            : data.isNotUseOpenPer == 1 && radioIndex == 0
                            ? "none"
                            : allChangeModeLoading
                            ? "auto"
                            : "none",
                }}
            >
                {/* 닫힘 정지 열림 START */}
                <div style={{ display: "flex", width: "220px", justifyContent: "space-between", marginLeft: "30px", marginRight: "10px" }}>
                    <CustomMUIBtn
                        clickBtn={clickBtn}
                        radioIndex={radioIndex}
                        isNotUseOpenPer={data.isNotUseOpenPer}
                        btnType={10190002}
                        onClick={() => postCtrlEquit(10190002)}
                        color="#fa5b59"
                        // disabled={radioIndex == 1 ? false : true}
                        disabled={radioIndex == 0 && data.isNotUseOpenPer != 1 ? true : manualUnconditionalStop == 1 ? true : false}
                        manualUnconditionalStop={manualUnconditionalStop}
                        className={classes.root}
                    >
                        {data.isNotUseOpenPer == 1 ? "난방" : dict["close"]}
                    </CustomMUIBtn>
                    <CustomMUIBtn
                        clickBtn={clickBtn}
                        radioIndex={radioIndex}
                        isNotUseOpenPer={data.isNotUseOpenPer}
                        btnType={10190003}
                        onClick={() => postCtrlEquit(10190003)}
                        color="#52935d"
                        // disabled={radioIndex == 1 ? false : true}
                        disabled={radioIndex == 0 && data.isNotUseOpenPer != 1 ? true : manualUnconditionalStop == 1 ? true : false}
                        manualUnconditionalStop={manualUnconditionalStop}
                        className={classes.root}
                    >
                        {dict["stop"]}
                    </CustomMUIBtn>
                    <CustomMUIBtn
                        clickBtn={clickBtn}
                        radioIndex={radioIndex}
                        isNotUseOpenPer={data.isNotUseOpenPer}
                        btnType={10190001}
                        lo
                        onClick={() => postCtrlEquit(10190001)}
                        color="#8cccfb"
                        // disabled={radioIndex == 1 ? false : true}
                        disabled={radioIndex == 0 && data.isNotUseOpenPer != 1 ? true : manualUnconditionalStop == 1 ? true : false}
                        manualUnconditionalStop={manualUnconditionalStop}
                        className={classes.root}
                    >
                        {data.isNotUseOpenPer == 1 ? "냉방" : dict["open"]}
                    </CustomMUIBtn>
                </div>
                {/* 닫힘 정지 열림 END */}

                {/* 오픈퍼조작모드 안보이게 - 생산설비 전환설비 OR 정지릴레이모드 체크시  */}
                <div
                    style={{
                        display: data.isNotUseOpenPer == 1 || data.isUseStControl == 1 ? "none" : "flex",
                        pointerEvents: data.isNotUseManual == 1 ? "none" : allChangeModeLoading ? "auto" : "none",
                    }}
                >
                    {/* 오픈퍼 2개 START */}
                    <div style={{ display: "flex" }}>
                        <section style={{ display: "flex", alignItems: "center", width: "105px" }}>
                            <InputBox
                                style={{ width: "70px", marginRight: "7px", opacity: "0.8" }}
                                type="number"
                                disabled={true}
                                value={inputCurrValue}
                            />
                            <DefaltFont>{t("per")}</DefaltFont>
                        </section>
                        <section style={{ display: "flex", alignItems: "center", width: "125px" }}>
                            <InputBox
                                style={{ width: "70px", marginRight: "7px", opacity: "0.8" }}
                                type="number"
                                maxLength="3"
                                disabled={radioIndex == 0 ? true : false}
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                            />
                            <DefaltFont>{dict["per"]}</DefaltFont>
                        </section>
                    </div>
                    {/* 오픈퍼 2개 END */}

                    {/* 설정 START */}
                    <div style={{ display: "flex", height: "40px", alignItems: "center" }}>
                        <CustomBtn
                            emty={radioIndex == 0 ? true : false}
                            onClick={async () => {
                                let result = false

                                if (radioIndex == 1) {
                                    // result = await
                                    postSettData({
                                        equipSeq: data.equipSeq,
                                        workStatus: clickBtn,
                                        openPer: inputValue,
                                    })
                                }

                                //여러번 클릭금지
                                if (!loading) {
                                    //&& result) {
                                    setLoading(true)
                                    timer = setTimeout(() => {
                                        setLoading(false)
                                        // setPopup({ open: true, text: t("saved successfully.") })
                                        setSettingChange(true)
                                        setInputValue(0)
                                    }, 2000)
                                }
                            }}
                        >
                            {dict["settings"]}
                        </CustomBtn>
                    </div>
                    {/* 설정 END */}
                </div>
                {/* 오픈퍼조작막는 div */}
            </div>
            {/* 수동조작막는 div */}
        </div>
    )
}
// 정역파트 end ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ

// on/off파트 start ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ
const TurnOnOffComponent = (props) => {
    const { t, onOffdata, postData, postSett, dict, allChangeModeLoading, setSettingChange, loading, setLoading } = props
    const [OnOffRadio, setOnOffRadio] = useState(0)
    const [OnOffSwitch, SetOnOffSwitch] = useState(false)

    //수동,자동
    const postOnOffSett = async () => {
        let result = false
        if (OnOffRadio == 1) {
            result = await postData({
                equipSeq: onOffdata.equipSeq,
                controlKind: 10200001, //자동
            })
        } else {
            result = await postData({
                equipSeq: onOffdata.equipSeq,
                controlKind: 10200002, //수동
            })
        }
        if (result) setOnOffRadio(OnOffRadio == 1 ? 0 : 1)

        // 전환시 화면 로딩 퍼즈
        if (!loading) {
            //&& result) {
            setLoading(true) // 로딩중
            setTimeout(() => {
                setLoading(false) // 2초후 로딩 해제
                setSettingChange(true)
            }, 2000)
        }
    }
    const postSetting = async () => {
        // true => off, false => on => set
        await postSett({
            equipSeq: onOffdata.equipSeq,
            workStatus: OnOffSwitch == true ? 10190002 : 10190001,
            openPer: 0,
        })
        SetOnOffSwitch(!OnOffSwitch)

        // 전환시 화면 로딩 퍼즈
        if (!loading) {
            //&& result) {
            setLoading(true) // 로딩중
            setTimeout(() => {
                setLoading(false) // 2초후 로딩 해제
                setSettingChange(true)
            }, 1000)
        }
    }

    useEffect(() => {
        if (onOffdata) {
            setOnOffRadio(onOffdata.controlKind == 10200001 ? 0 : 1)
            SetOnOffSwitch(onOffdata.workStatus == 10190001 ? true : false)
        }
    }, [onOffdata])

    // console.log('김한중짱@@@@@@@@@@@@@@@@')
    // console.log('onOffdata', onOffdata)

    return (
        <>
            <section
                style={{
                    display: "flex",
                    alignItems: "center",
                    height: "40px",
                    marginBottom: "22px",
                    pointerEvents: allChangeModeLoading ? "auto" : "none",
                }}
            >
                <section style={{ overflowX: "hidden", width: "180px", marginRight: "40px", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
                    <DefaltFont>{onOffdata.equipName}</DefaltFont>
                </section>
                <section style={{ display: "flex", width: "195px" }}>
                    <Radio style={{ marginRight: "30px" }} text={dict["auto"]} idx={0} check={OnOffRadio} setCheck={postOnOffSett} />
                    <Radio style={{}} text={dict["manual"]} idx={1} check={OnOffRadio} setCheck={postOnOffSett} />
                </section>

                {/* on/off 버튼 급수밸브,배수펌프는 이벤트 안되게 막음  */}
                <div
                    style={{
                        display: "flex",
                        pointerEvents:
                            onOffdata.controlKind == 10200001
                                ? "none"
                                : onOffdata.isNotUseManual == 1
                                ? "none"
                                : allChangeModeLoading
                                ? "auto"
                                : "none",
                    }}
                >
                    <Switch
                        bool={OnOffRadio == 0 ? (onOffdata.workStatus == 10190001 ? true : false) : OnOffSwitch}
                        setBool={() => {
                            OnOffRadio == 0 ? (onOffdata.workStatus == 10190001 ? true : false) : postSetting()
                        }}
                    />
                </div>
            </section>
        </>
    )
}
// on/off파트 end ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ
