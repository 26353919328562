import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const { postSignIn, postSignOut, checkSignStatus, setIsLogin, findPw, postSignUp, setUserInfo } = actions.SignAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _isLogin: state.SignReducer._isLogin,
  _userInfo: state.SignReducer._userInfo,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  postSignIn: (param) => dispatch(postSignIn(param)),
  postSignOut: () => dispatch(postSignOut()),
  checkSignStatus: (param) => dispatch(checkSignStatus(param)),
  setIsLogin: (param) => dispatch(setIsLogin(param)),
  findPw: (param) => dispatch(findPw(param)),
  postSignUp: (param) => dispatch(postSignUp(param)),
  setUserInfo: (param) => dispatch(setUserInfo(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
