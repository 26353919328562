import React from "react"
import styled from "styled-components"
import icon_radio from "images/radio.png"
import icon_radio_select from "images/radio_select.png"

const Radio = ({ text, idx, check, setCheck, style = {} }) => {
  return (
    <StyledRadio style={{ ...style }} onClick={() => setCheck(idx)}>
      <RadioImg src={idx === check ? icon_radio_select : icon_radio} alt={"라디오"} />
      <RadioLabel>{text}</RadioLabel>
    </StyledRadio>
  )
}

const StyledRadio = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 35px;
`
const RadioImg = styled.img`
  width: 20px;
  height: 20px;
`
const RadioLabel = styled.p`
  opacity: 80%;
  margin-bottom: 2px;
  margin-left: 9px;
  color: #555555;
  font-size: 14px;
`

export default Radio
