import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  initSensorNodeList,
  getSensorNodeList,
  addSensorNode,
  initSensorNode,
  getSensorNode,
  editSensorNode,
  delSensorNode,
  downloadSensorNodeListExcel,
  initSensorList,
  getSensorList,
  addSensor,
  initSensor,
  getSensor,
  editSensor,
  delSensor,
} = actions.DefaultInfoAction
const { getComboList } = actions.SystemSettingAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _sensorNodeList: state.DefaultInfoReducer._sensorNodeList,
  _sensorNode: state.DefaultInfoReducer._sensorNode,
  _sensorList: state.DefaultInfoReducer._sensorList,
  _sensor: state.DefaultInfoReducer._sensor,
  _codeList: state.SystemSettingReducer._codeList,
  _comboWorkCenterList: state.SystemSettingReducer._comboWorkCenterList,
  _comboGatewayList: state.SystemSettingReducer._comboGatewayList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  initSensorNodeList: (param) => dispatch(initSensorNodeList(param)),
  getSensorNodeList: (param) => dispatch(getSensorNodeList(param)),
  addSensorNode: (param) => dispatch(addSensorNode(param)),
  initSensorNode: (param) => dispatch(initSensorNode(param)),
  getSensorNode: (param) => dispatch(getSensorNode(param)),
  editSensorNode: (param) => dispatch(editSensorNode(param)),
  delSensorNode: (param) => dispatch(delSensorNode(param)),
  downloadSensorNodeListExcel: (param) => dispatch(downloadSensorNodeListExcel(param)),
  initSensorList: (param) => dispatch(initSensorList(param)),
  getSensorList: (param) => dispatch(getSensorList(param)),
  addSensor: (param) => dispatch(addSensor(param)),
  initSensor: (param) => dispatch(initSensor(param)),
  getSensor: (param) => dispatch(getSensor(param)),
  editSensor: (param) => dispatch(editSensor(param)),
  delSensor: (param) => dispatch(delSensor(param)),

  getComboList: (param) => dispatch(getComboList(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
