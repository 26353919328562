import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  initStdCropList,
  getStdCropList,
  addStdCrop,
  initStdCrop,
  getStdCrop,
  editStdCrop,
  delStdCrop,

  initStdCropGrowthList,
  getStdCropGrowthList,
  addStdCropGrowth,
  initStdCropGrowth,
  getStdCropGrowth,
  editStdCropGrowth,
  delStdCropGrowth,

  initStdCropPestList,
  getStdCropPestList,
  addStdCropPest,
  initStdCropPest,
  getStdCropPest,
  editStdCropPest,
  delStdCropPest,

  initStdCropEnvList,
  getStdCropEnvList,
  addStdCropEnv,
  initStdCropEnv,
  getStdCropEnv,
  editStdCropEnv,
  delStdCropEnv,

  initStdCropHRMList,
  getStdCropHRMList,
  addStdCropHRM,
  initStdCropHRM,
  getStdCropHRM,
  editStdCropHRM,
  delStdCropHRM,
} = actions.CropManageAction
const { getCodeHelpItem } = actions.SystemSettingAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _stdCropList: state.CropManageReducer._stdCropList,
  _stdCrop: state.CropManageReducer._stdCrop,
  _stdCropGrowthList: state.CropManageReducer._stdCropGrowthList,
  _stdCropGrowth: state.CropManageReducer._stdCropGrowth,
  _stdCropPestList: state.CropManageReducer._stdCropPestList,
  _stdCropPest: state.CropManageReducer._stdCropPest,
  _stdCropEnvList: state.CropManageReducer._stdCropEnvList,
  _stdCropEnv: state.CropManageReducer._stdCropEnv,
  _stdCropHRMList: state.CropManageReducer._stdCropHRMList,
  _stdCropHRM: state.CropManageReducer._stdCropHRM,

  _codeList: state.SystemSettingReducer._codeList || [],
  _codeHelpItem: state.SystemSettingReducer._codeHelpItem,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  initStdCropList: (param) => dispatch(initStdCropList(param)),
  getStdCropList: (param) => dispatch(getStdCropList(param)),
  addStdCrop: (param) => dispatch(addStdCrop(param)),
  initStdCrop: (param) => dispatch(initStdCrop(param)),
  getStdCrop: (param) => dispatch(getStdCrop(param)),
  editStdCrop: (param) => dispatch(editStdCrop(param)),
  delStdCrop: (param) => dispatch(delStdCrop(param)),

  initStdCropGrowthList: (param) => dispatch(initStdCropGrowthList(param)),
  getStdCropGrowthList: (param) => dispatch(getStdCropGrowthList(param)),
  addStdCropGrowth: (param) => dispatch(addStdCropGrowth(param)),
  initStdCropGrowth: (param) => dispatch(initStdCropGrowth(param)),
  getStdCropGrowth: (param) => dispatch(getStdCropGrowth(param)),
  editStdCropGrowth: (param) => dispatch(editStdCropGrowth(param)),
  delStdCropGrowth: (param) => dispatch(delStdCropGrowth(param)),

  initStdCropPestList: (param) => dispatch(initStdCropPestList(param)),
  getStdCropPestList: (param) => dispatch(getStdCropPestList(param)),
  addStdCropPest: (param) => dispatch(addStdCropPest(param)),
  initStdCropPest: (param) => dispatch(initStdCropPest(param)),
  getStdCropPest: (param) => dispatch(getStdCropPest(param)),
  editStdCropPest: (param) => dispatch(editStdCropPest(param)),
  delStdCropPest: (param) => dispatch(delStdCropPest(param)),

  initStdCropEnvList: (param) => dispatch(initStdCropEnvList(param)),
  getStdCropEnvList: (param) => dispatch(getStdCropEnvList(param)),
  addStdCropEnv: (param) => dispatch(addStdCropEnv(param)),
  initStdCropEnv: (param) => dispatch(initStdCropEnv(param)),
  getStdCropEnv: (param) => dispatch(getStdCropEnv(param)),
  editStdCropEnv: (param) => dispatch(editStdCropEnv(param)),
  delStdCropEnv: (param) => dispatch(delStdCropEnv(param)),

  initStdCropHRMList: (param) => dispatch(initStdCropHRMList(param)),
  getStdCropHRMList: (param) => dispatch(getStdCropHRMList(param)),
  addStdCropHRM: (param) => dispatch(addStdCropHRM(param)),
  initStdCropHRM: (param) => dispatch(initStdCropHRM(param)),
  getStdCropHRM: (param) => dispatch(getStdCropHRM(param)),
  editStdCropHRM: (param) => dispatch(editStdCropHRM(param)),
  delStdCropHRM: (param) => dispatch(delStdCropHRM(param)),

  getCodeHelpItem: (param) => dispatch(getCodeHelpItem(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
