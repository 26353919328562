import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const { getStaffList, getStaffInfo, addStaff, editStaff, delStaff, getStaffExcelDown, setStaffList, setStaffInfo } = actions.ProductionAction

// redux state > component mapping
const mapStateToProps = (state) => ({
    _staffList: state.ProductionReducer._staffList,
    _staffInfo: state.ProductionReducer._staffInfo,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
    addStaff: (param) => dispatch(addStaff(param)),
    getStaffList: (param) => dispatch(getStaffList(param)),
    getStaffInfo: (param) => dispatch(getStaffInfo(param)),
    editStaff: (param) => dispatch(editStaff(param)),
    delStaff: (param) => dispatch(delStaff(param)),

    getStaffExcelDown: (param) => dispatch(getStaffExcelDown(param)),

    setStaffList: (param) => dispatch(setStaffList(param)),
    setStaffInfo: (param) => dispatch(setStaffInfo(param)),
})

const WithHoc = (ChildComponent) => (props) => {
    return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
