// 구글 주소검색 팝업창 - 차건담 2023.08.29
import React, { useRef, useState, useEffect } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import PlaceComponent from "./DefaultInfo/PlaceComponent"
import AlertPopup from "./AlertPopup"

import CommonContainer from "../../containers/CommonContainer"

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        maxWidth: "500px",
        margin: "0 auto",
        "& .MuiDialog-paperFullWidth": {
            width: "100%",
        },
        "& .MuiPaper-root": {
            backgroundColor: "#00000000",
            maxWidth: "500px",
        },
        "& .MuiDialog-paper": {
            margin: "20px",
        },
    },
}))

const GooglePostCodePopup = (props) => {
    const classes = useStyles()
    const { onClose, open, onComplete, typeAddress, getTranslationWords, _translationWords, _userInfo } = props

    const [mainAddr, setMainAddr] = useState("")
    const [xy, setXY] = useState({ x: 0, y: 0 }) // xy값 직접 처리를 위해 추가 - 윤여광 2024.03.12
    const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })

    useEffect(() => {
        getTranslationWords()
    }, [])

    let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.04.04

    const dictCk = (word) => {
        if (dict == undefined || dict == null || dict == {} || dict == "") {
            return word
        } else {
            const result = dict[word] != undefined && dict[word] != null && dict[word] != "" ? dict[word] : word
            return result
        }
    }

    const insertAddress = () => {
        // 선택된 주소와 입력한 상세주소를 농장 기본정보 화면의 주소칸과 상세주소칸에 입력
        //if(mainAddr == ""){return setAlertPopup({ open: true, text: dictCk('addressSelectAlert') })}
        if (mainAddr == "") {
            return setAlertPopup({ open: true, text: dictCk("addressSelectAlert") })
        }
        typeAddress(mainAddr, xy) // farmInfo에서 넘겨받은 함수
    }

    const mapRef = React.createRef()

    return (
        <Dialog
            className={classes.container}
            style={{ width: "100%", height: "100%", zIndex: 100, alignItems: "center" }}
            onClose={onClose}
            open={open}
            fullWidth={true}
        >
            <AlertPopup
                bg={false}
                open={alertPopup.open}
                onClose={() => setAlertPopup({ open: false, text: alertPopup.text })}
                content={alertPopup.text}
            />
            <div style={{ width: "100%", backgroundColor: "white" }}>
                <p
                    style={{
                        marginLeft: "35px",
                        marginTop: "30px",
                        opacity: "80%",
                        marginBottom: "23px",
                        color: "#555555",
                        fontSize: "18px",
                        fontWeight: "700",
                        lineHeight: "32px",
                        //  }}>address</p>
                    }}
                >
                    {String(dictCk("address")).charAt(0).toUpperCase() + String(dictCk("address")).slice(1) + " " + dictCk("search2")}
                </p>
            </div>
            <div style={{ width: "100%", height: "265px", textAlign: "center", backgroundColor: "white", zIndex: 101, justifyContent: "center" }}>
                {/* <span style={{ display: "block", marginTop: "30px", fontSize: "20px", fontWeight: "bold", letterSpacing: "3px", textAlign: "center" }}>주소검색</span> */}

                <PlaceComponent mainAddr={mainAddr} setMainAddr={setMainAddr} xy={xy} setXY={setXY} _userInfo={_userInfo}></PlaceComponent>
                <div style={{ justifyContent: "center", width: "100%", marginTop: "20px" }}>
                    <div style={{ width: "400px" }}>
                        <span
                            style={{
                                display: "block",
                                width: "400px",
                                textAlign: "left",
                                marginLeft: "32px",
                                opacity: "80%",
                                color: "#555555",
                                fontSize: "14px",
                                fontWeight: "bold",
                                marginBottom: "5px",
                            }}
                        >
                            {dictCk("addrDetail")}
                        </span>
                        {/* color: "#555555", fontSize:"14px", fontWeight:"bold", marginBottom:"5px"}}>addrDetail</span> */}
                    </div>
                    <textarea
                        id={"detailAddr"}
                        style={{
                            boxSizing: "border-box",
                            border: "1px solid transparent",
                            width: "400px",
                            height: "70px",
                            marginTop: "5px",
                            padding: "0 12px",
                            borderRadius: "3px",
                            boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
                            fontSize: "14px",
                            outline: "none",
                            textOverflow: "ellipses",
                        }}
                    ></textarea>
                </div>
                <br></br>
                <div style={{ textAlign: "right", width: "380px", marginLeft: "50px" }}>
                    <button
                        style={{
                            width: "100px",
                            height: "40px",
                            backgroundColor: "lightgray",
                            borderRadius: "10px",
                            color: "white",
                            // backgroundColor: "#188a31",
                        }}
                        onClick={() => insertAddress()}
                        // >complete</button>
                    >
                        {dictCk("complete")}
                    </button>
                </div>
            </div>
        </Dialog>
    )
}

GooglePostCodePopup.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    onComplete: PropTypes.func.isRequired,
}

export default CommonContainer(GooglePostCodePopup)
