// 작기관리 > 작기정보
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import moment from "moment"
import CropInfoContainer from "../../../containers/cropManage/CropInfoContainer"
import PageName from "../../component/common/PageName"
import {YellowButton, SkyButton } from "../../component/common/Button"
import InputBox from "../../component/common/InputBox"
import { TableComponent } from "../../component/common/Table"
import Tab from "../../component/common/Tab"
import BasicPopup from "../../popup/BasicPopup"
import CropAddPopup from "../../popup/CropManage/CropAddPopup"
import CropGrowthAddPopup from "../../popup/CropManage/CropGrowthAddPopup"
import CropPestAddPopup from "../../popup/CropManage/CropPestAddPopup"
import CropEnvAddPopup from "../../popup/CropManage/CropEnvAddPopup"
import CropHRMAddPopup from "../../popup/CropManage/CropHRMAddPopup"

import CommonContainer from "../../../containers/CommonContainer"

import CodeHelpBox from "../../component/common/CodeHelpBox"

const CropInfo = (props) => {
  const { t, getCodeHelpItem, getComboList, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  const { _cropList, initCropList, getCropList, addCrop, initCrop, getCrop, editCrop, delCrop } = props
  const {
    _cropGrowthList,
    initCropGrowthList,
    getCropGrowthList,
    addCropGrowth,
    initCropGrowth,
    getCropGrowth,
    editCropGrowth,
    delCropGrowth,
  } = props
  const { _cropPestList, initCropPestList, getCropPestList, addCropPest, initCropPest, getCropPest, editCropPest, delCropPest } = props
  const { _cropEnvList, initCropEnvList, getCropEnvList, addCropEnv, initCropEnv, getCropEnv, editCropEnv, delCropEnv } = props
  const { _cropHRMList, initCropHRMList, getCropHRMList, addCropHRM, initCropHRM, getCropHRM, editCropHRM, delCropHRM } = props
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [cropAddPopup, setCropAddPopup] = useState(false)
  const [cropGrowthAddPopup, setCropGrowthAddPopup] = useState(false)
  const [cropPestAddPopup, setCropPestAddPopup] = useState(false)
  const [cropEnvAddPopup, setCropEnvAddPopup] = useState(false)
  const [cropHRMAddPopup, setCropHRMAddPopup] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [currentCrop, setCurrentCrop] = useState(null)
  const [currentCropGrowth, setCurrentCropGrowth] = useState(null)
  const dict = _translationWords[0] // 변수에 사전 담기

  const [cropName, setCropName] = useState("")
  const [itemName, setItemName] = useState("")

  useEffect(() => {
    getTranslationWords()
    getData()

    return async () => {
      const pro1 = await initCropList()
      const pro2 = await initCropGrowthList()
      const pro3 = await initCropPestList()
      const pro4 = await initCropEnvList()
      const pro5 = await initCropHRMList()
      Promise.all([pro1, pro2, pro3, pro4, pro5])
    }
  }, [])

  const getData = async () => {
    const pro1 = await getCropList({cropName: typeof cropName == 'object' ? cropName['label'] : cropName , // codeHelp 사용으로 JSON 객체의 label값 사용 - 차건담 2023.08.02
                                    itemName: typeof itemName == 'object' ? itemName['label'] : itemName}) // codeHelp 사용으로 JSON 객체의 label값 사용 - 차건담 2023.08.02
    const pro2 = await getCodeHelpItem({ queryWord: "" })
    const pro3 = await getComboList({ queryKind: "WorkCenter" })
    const pro4 = await getComboList({ queryKind: "StdCrop" })
    Promise.all([pro1, pro2, pro3, pro4])
  }

  const getGrowthList = async (cropSeq) => {
    const pro1 = await getCropGrowthList({ cropSeq })
    const pro2 = await getCropPestList({ cropGrowthSeq: 0 })
    const pro3 = await getCropEnvList({ cropGrowthSeq: 0 })
    const pro4 = await getCropHRMList({ cropGrowthSeq: 0 })
    Promise.all([pro1, pro2, pro3, pro4])
  }

  const onClickSearch = async () => {
    getData()
  }

  const getPestList = async (cropGrowthSeq) => {
    await getCropPestList({ cropGrowthSeq })
  }

  const getEnvList = async (cropGrowthSeq) => {
    await getCropEnvList({ cropGrowthSeq })
  }

  const getHRMList = async (cropGrowthSeq) => {
    await getCropHRMList({ cropGrowthSeq })
  }

  const onClickGrowth = async (item) => {
    setCurrentCropGrowth(item)
    const pro1 = await getCropPestList({ cropGrowthSeq: item.seq })
    const pro2 = await getCropEnvList({ cropGrowthSeq: item.seq })
    const pro3 = await getCropHRMList({ cropGrowthSeq: item.seq })
    Promise.all([pro1, pro2, pro3])
  }

  // 작기정보
  const onClickAdd = async () => {
    await initCrop()
    setCropAddPopup(true)
  }
  const onClickEdit = async (seq) => {
    const result = await getCrop({ cropSeq: seq })
    if (result) setCropAddPopup(true)
  }
  const onClickDel = async (seq) => {
    const result = await delCrop({ cropSeq: seq })
    if (result) {getData()
      if(currentCrop != null)getGrowthList(currentCrop.seq)
    }
  }
  const cropAddPopupHandler = async (type, value) => {
    if (type == dict['save']) {
      let result = false
      if (value.cropSeq == undefined) {
        result = await addCrop(value)
      } else {
        result = await editCrop(value)
      }
      if (result) {getData()
        if(currentCrop != null)getGrowthList(currentCrop.seq)
      }
    }
    setCropAddPopup(false)
  }

  // 생육정보
  const onClickAddGrowth = async () => {
    if (!currentCrop) return setPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['crop']) })
    await initCropGrowth()
    setCropGrowthAddPopup(true)
  }
  const onClickEditGrowth = async (seq) => {
    const result = await getCropGrowth({ cropGrowthSeq: seq })
    if (result) setCropGrowthAddPopup(true)
  }
  const onClickDelGrowth = async (seq) => {
    const result = await delCropGrowth({ cropSeq: currentCrop.seq, cropGrowthSeq: seq })
    if (result) getGrowthList(currentCrop.seq)
    getData()
  }
  const cropGrowthAddPopupHandler = async (type, value) => {
    if (type == dict['save']) {
      let result = false
      if (value.cropGrowthSeq == undefined) {
        result = await addCropGrowth({ cropSeq: currentCrop.seq, ...value })
      } else {
        result = await editCropGrowth({ cropSeq: currentCrop.seq, ...value })
      }
      if (result) {
        getGrowthList(currentCrop.seq)
        getData()
      }
    }
    setCropGrowthAddPopup(false)
  }

  // 병해충정보
  const onClickAddPest = async () => {
    if (!currentCropGrowth) return setPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['stdCropGrowth']) })
    await initCropPest()
    setCropPestAddPopup(true)
  }
  const onClickEditPest = async (seq) => {
    if (!currentCropGrowth) return setPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['stdCropGrowth']) })
    const result = await getCropPest({ cropPestSeq: seq })
    if (result) setCropPestAddPopup(true)
  }
  const onClickDelPest = async (seq) => {
    const result = await delCropPest({ cropPestSeq: seq })
    if (result) getPestList(currentCropGrowth.seq)
  }
  const cropPestAddPopupHandler = async (type, value) => {
    if (type == dict['save']) {
      let result = false
      if (value.cropPestSeq == undefined) {
        result = await addCropPest({ cropGrowthSeq: currentCropGrowth.seq, ...value })
      } else {
        result = await editCropPest(value)
      }
      if (result) getPestList(currentCropGrowth.seq)
    }
    setCropPestAddPopup(false)
  }

  // 환경정보
  const onClickAddEnv = async () => {
    if (!currentCropGrowth) return setPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['stdCropGrowth']) })
    await initCropEnv()
    setCropEnvAddPopup(true)
  }
  const onClickEditEnv = async (seq) => {
    if (!currentCropGrowth) return setPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['stdCropGrowth']) })
    const result = await getCropEnv({ cropEnvSeq: seq })
    if (result) setCropEnvAddPopup(true)
  }
  const onClickDelEnv = async (seq) => {
    const result = await delCropEnv({ cropEnvSeq: seq })
    if (result) getEnvList(currentCropGrowth.seq)
  }
  const cropEnvAddPopupHandler = async (type, value) => {
    if (type == dict['save']) {
      let result = false
      if (value.cropEnvSeq == undefined) {
        result = await addCropEnv({ cropGrowthSeq: currentCropGrowth.seq, ...value })
      } else {
        result = await editCropEnv(value)
      }
      if (result) getEnvList(currentCropGrowth.seq)
    }
    setCropEnvAddPopup(false)
  }

  // 인력정보
  const onClickAddHRM = async () => {
    if (!currentCropGrowth) return setPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['stdCropGrowth']) })
    await initCropHRM()
    setCropHRMAddPopup(true)
  }
  const onClickEditHRM = async (seq) => {
    if (!currentCropGrowth) return setPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['stdCropGrowth']) })
    const result = await getCropHRM({ cropHRMSeq: seq })
    if (result) setCropHRMAddPopup(true)
  }
  const onClickDelHRM = async (seq) => {
    const result = await delCropHRM({ cropHRMSeq: seq })
    if (result) getHRMList(currentCropGrowth.seq)
  }
  const cropHRMAddPopupHandler = async (type, value) => {
    if (type == dict['save']) {
      let result = false
      if (value.cropHRMSeq == undefined) {
        result = await addCropHRM({ cropGrowthSeq: currentCropGrowth.seq, ...value })
      } else {
        result = await editCropHRM(value)
      }
      if (result) getHRMList(currentCropGrowth.seq)
    }
    setCropHRMAddPopup(false)
  }

  return (
    <Container>
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: popup.text })} content={popup.text} selectedValue={[dict['confirm']]} />
      <CropAddPopup open={cropAddPopup} onClose={cropAddPopupHandler} />
      <CropGrowthAddPopup open={cropGrowthAddPopup} onClose={cropGrowthAddPopupHandler} currentCrop={currentCrop} />
      <CropPestAddPopup open={cropPestAddPopup} onClose={cropPestAddPopupHandler} />
      <CropEnvAddPopup open={cropEnvAddPopup} onClose={cropEnvAddPopupHandler} />
      <CropHRMAddPopup open={cropHRMAddPopup} onClose={cropHRMAddPopupHandler} />
      <PageName name={dict['cropInfo']} state={false} setState={() => {}} />

      <Box>
        <WhiteBox padding="0px 34px 32px;" height={350}>
          {/* <div style={{ display: "flex", marginTop: "12px", justifyContent: "space-between", alignItems: "flex-start" }}>
            <TableName>{dict['defaultInfo']}</TableName>
            <div style={{ marginTop: "16px" }}>
              <SkyButton onClick={onClickAdd}>{dict['add']}</SkyButton>
            </div>
          </div> */}

            <div style={{ display: "flex", height: "22px", alignItems: "center", marginTop: "16px"}}>
                <TableName>{dict['defaultInfo']}</TableName>
            </div>
          <FlexBox justifyContent={"space-between"}>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <FlexBox style={{ marginTop: "16px", marginLeft: "25px" }}>
               {/* 작기명 */}
               <Text>{dict['cropName']}</Text>
               {/* <InputBox style={{ width: "280px" }} type="text" value={cropName} onChange={(e) => setCropName(e.target.value)} /> */}
               <CodeHelpBox // inputBox에서 CodeHelpBox로 변경 - 차건담 2023.07.13
                style={{}}
                placeholder={dict['cropName']}
                codeHelpSeq={3}
                // codeHelpParam={{}}
                value={cropName}
                setValue={setCropName}
               />

               {/* 품명 */}
               <Text>{dict['itemName']}</Text>
               {/* <InputBox style={{ width: "280px" }} type="text" value={itemName} onChange={(e) => setItemName(e.target.value)} /> */}
               <CodeHelpBox // inputBox에서 CodeHelpBox로 변경 - 차건담 2023.07.13
                style={{}}
                placeholder={dict['itemName']}
                codeHelpSeq={1}
                // codeHelpParam={{}}
                value={itemName}
                setValue={setItemName}
               />
               </FlexBox>
            </div>
            <div style={{ marginTop: "16px" }}>
              <YellowButton onClick={onClickSearch}>{dict['search']}</YellowButton>
              <SkyButton style={{ marginLeft: "14px" }} onClick={onClickAdd}>{dict['add']}</SkyButton>
            </div>
          </FlexBox>



          <TableComponent
            headList={[
              { title: dict['ranking'] },
              { title: dict['stdCropName'], type: "text" },
              { title: dict['itemName'], type: "text" },
              { title: dict['cropDateFr'] },
              { title: dict['cropDateTo'] },
              { title: dict['remark'], type: "text" },
              { title: dict["actions"] }, // 수정 2024.03.18 by 강동우 
              //{ title: `${t("actions")}` },
            ]}
            bodyList={(_cropList || []).map((i, idx) => ({
              seq: i.cropSeq,
              // no: _cropList.length - idx,
              no: 1 + idx,
              cropName: i.cropName,
              itemName: i.itemName,
              cropDateFr: i.cropDateFr && moment(i.cropDateFr, "YYYYMMDD").format("YYYY-MM-DD"),
              cropDateTo: i.cropDateTo && moment(i.cropDateTo, "YYYYMMDD").format("YYYY-MM-DD"),
              remark: i.remark,
              Actions: true,
            }))}
            dict={dict}
            onClick={(item) => {
              setCurrentCropGrowth(null)
              setCurrentCrop(item)
              getGrowthList(item.seq)
            }}
            onClickEdit={(seq) => onClickEdit(seq)}
            onClickDelete={(seq) => onClickDel(seq)}
          />
        </WhiteBox>
      </Box>

      <Box>
        <WhiteBox height={740}>
          <FlexBox justifyContent={"space-between"}>
            <div style={{ display: "flex", justifyContent: "flex-start" }}>
              <div style={{ display: "flex", height: "32px", alignItems: "center" }}>
                <TableName>{dict['stdCropGrowth']}</TableName>
              </div>
              <LabelComponent
                style={{ marginTop: "16px", marginLeft: "30px" }}
                title={dict['stdCropName']}
                value={(currentCrop && currentCrop.cropName) || ""}
              />
              <LabelComponent
                style={{ marginTop: "16px", marginLeft: "30px" }}
                title={dict['itemName']}
                value={(currentCrop && currentCrop.itemName) || ""}
              />
            </div>

            <div style={{ marginTop: "16px" }}>
              <SkyButton onClick={onClickAddGrowth}>{dict['add']}</SkyButton>
            </div>
          </FlexBox>

          <TableContainer>
            <TableComponent
              headList={[
                { title: dict['ranking'] },
                { title: dict['growthStep'] },
                { title: dict['dateFr'] },
                { title: dict['elapsedDays'], type: "number" },
                { title: dict['importWork'], type: "text" },
                { title: dict['isHarv'], type: "check" },
                { title: dict['isPlant'], type: "check" },
                { title: dict['checkPoint'], type: "text" },
                { title: dict['remark'], type: "text" },
                { title: dict["actions"] }, // 수정 2024.03.18 by 강동우 
                //{ title: `${t("actions")}` },
              ]}
              bodyList={(_cropGrowthList || []).map((i, idx) => ({
                seq: i.cropGrowthSeq,
                // no: _cropGrowthList.length - idx,
                no: 1 + idx,
                growthStepName: i.growthStepName,
                dateFr: i.dateFr && moment(i.dateFr, "YYYYMMDD").format("YYYY-MM-DD"),
                date: i.date,
                work: i.work,
                isHarv: i.isHarv,
                isPlant: i.isPlant,
                checkPoint: i.checkPoint,
                remark: i.remark,
                Actions: true,
              }))}
              dict={dict}
              onClick={onClickGrowth}
              onClickEdit={(seq) => onClickEditGrowth(seq)}
              onClickDelete={(seq) => onClickDelGrowth(seq)}
            />
          </TableContainer>

          <FlexBox justifyContent="space-between" style={{ marginTop: "14px", marginLeft: "82px" }}>
            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <LabelComponent title={dict['growthStep']} value={(currentCropGrowth && currentCropGrowth.growthStepName) || ""} />
              <LabelComponent2 style={{ marginLeft: "30px" }} title={dict['importWork']} value={(currentCropGrowth && currentCropGrowth.work) || ""} />
              {/* <LabelComponent title={t("growthStep")} value={(currentCropGrowth && currentCropGrowth.growthStepName) || ""} /> */}
            </div>
            <SkyButton
              onClick={() => {
                if (tabIndex == 0) onClickAddPest()
                if (tabIndex == 1) onClickAddEnv()
                if (tabIndex == 2) onClickAddHRM()
              }}
            >
              {dict['add']}
            </SkyButton>
          </FlexBox>

          <Tab
            style={{ marginTop: "23px" }}
            list={[
              { seq: 0, name: dict['cropPest'] },
              { seq: 1, name: dict['env'] },
              { seq: 2, name: dict['staffManagement'] },
            ]}
            tabIndex={tabIndex}
            onClick={(i) => {
              setTabIndex(i.seq)
            }}
          />
          <TableContainer>
            {tabIndex == 0 && (
              <TableComponent
                style={{ marginTop: 0 }}
                headList={[
                  { title: dict['ranking'] },
                  { title: dict['diseaseKind'] },
                  { title: dict['diseaseClass'] },
                  { title: dict['content'], type: "text" },
                  { title: dict['remark'], type: "text" },
                  { title: dict["actions"] }, // 수정 2024.03.18 by 강동우 
                  //{ title: `${t("actions")}` },
                ]}
                bodyList={(_cropPestList || []).map((i, idx) => ({
                  seq: i.cropPestSeq,
                  // no: _cropPestList.length - idx,
                  no: 1 + idx,
                  diseaseKindName: i.diseaseKindName,
                  diseaseClassName: i.diseaseClassName,
                  content: i.content,
                  remark: i.remark,
                  Actions: true,
                }))}
                dict={dict}
                onClickEdit={(seq) => onClickEditPest(seq)}
                onClickDelete={(seq) => onClickDelPest(seq)}
              />
            )}
            {tabIndex == 1 && (
              <TableComponent
                style={{ marginTop: 0 }}
                headList={[
                  { title: dict['ranking'] },
                  { title: dict['dayNightInfo'] },
                  { title: dict['envKind'] },
                  { title: dict['sensorUnit'] },
                  { title: dict['minValue'] },
                  { title: dict['maxValue'] },
                  { title: dict['remark'], type: "text" },
                  { title: dict["actions"] }, // 수정 2024.03.18 by 강동우 
                  //{ title: `${t("actions")}` },
                ]}
                bodyList={(_cropEnvList || []).map((i, idx) => ({
                  seq: i.cropEnvSeq,
                  // no: _cropEnvList.length - idx,
                  no: 1 + idx,
                  dayNightName: i.dayNightName,
                  envKindName: i.envKindName,
                  sensorUnitName: i.sensorUnitName,
                  minValue: i.minValue,
                  maxValue: i.maxValue,
                  remark: i.remark,
                  Actions: true,
                }))}
                dict={dict}
                onClickEdit={(seq) => onClickEditEnv(seq)}
                onClickDelete={(seq) => onClickDelEnv(seq)}
              />
            )}
            {tabIndex == 2 && (
              <TableComponent
                style={{ marginTop: 0 }}
                headList={[
                  { title: dict['ranking']},
                  { title: dict['necessaryPersonnel'] },
                  { title: dict['necessaryDays'] },
                  { title: dict['remark'], type: "text" },
                  { title: dict["actions"] }, // 수정 2024.03.18 by 강동우 
                  //{ title: `${t("actions")}` },
                ]}
                bodyList={(_cropHRMList || []).map((i, idx) => ({
                  seq: i.cropHRMSeq,
                  // no: _cropHRMList.length - idx,
                  no: 1 + idx,
                  reqNumPeople: i.reqNumPeople,
                  reqNumDays: i.reqNumDays,
                  remark: i.remark,
                  Actions: true,
                }))}
                dict={dict}
                onClickEdit={(seq) => onClickEditHRM(seq)}
                onClickDelete={(seq) => onClickDelHRM(seq)}
              />
            )}
          </TableContainer>
        </WhiteBox>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  &:after {
    content: "";
    display: block;
    height: 42px;
    width: 100%;
  }
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
`

const WhiteBox = styled.div`
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  height: ${(props) => props.height}px;
  margin-top: 12px;
  padding: ${(props) => (props.padding ? props.padding : "12px 34px 32px")};
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const TableNameDiv = styled.p`
  display: flex;
  justify-content: flex-start;
`
const TableName = styled.p`
  color: #555555b3;
  font-size: 14px;
  font-weight: 700;
`
const TableContainer = styled.div`
  overflow: hidden;
  display: flex;
  max-height: 227px;
`
const Text = styled.p`
  width: 60px;
  color: #555555;
  font-size: 14px;
`

const LabelComponent = ({ style = {}, title, value }) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", ...style }}>
      <p style={{ color: "#555555e6", fontSize: "14px" }}>{title}</p>
      <div
        style={{
          backgroundColor: "#edf4eb",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "320px",
          height: "40px",
          marginLeft: "20px",
          padding: "0 15px",
          border: "1px solid #dedede",
          borderRadius: "3px",
        }}
      >
        {value}
      </div>
    </div>
  )
}

const LabelComponent2 = ({ style = {}, title, value }) => {
  return (
    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", ...style }}>
      <p style={{ color: "#555555e6", fontSize: "14px" }}>{title}</p>
      <div
        style={{
          backgroundColor: "#edf4eb",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "820px",
          height: "40px",
          marginLeft: "20px",
          padding: "0 15px",
          border: "1px solid #dedede",
          borderRadius: "3px",
        }}
      >
        {value}
      </div>
    </div>
  )
}

export default CommonContainer(CropInfoContainer(withTranslation()(CropInfo)))
