import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  getManpowerList,
  getManpowerInfo,
  setManpowerList,
  setManpowerInfo,
} = actions.ProductionAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  manpowerList: state.ProductionReducer.manpowerList,
  manpowerInfo: state.ProductionReducer.manpowerInfo,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  getManpowerList: (param) => dispatch(getManpowerList(param)),
  getManpowerInfo: (param) => dispatch(getManpowerInfo(param)),
  setManpowerList: (param) => dispatch(setManpowerList(param)),
  setManpowerInfo: (param) => dispatch(setManpowerInfo(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
