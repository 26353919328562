// 작기정보 환경정보 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import { withTranslation } from "react-i18next"
import CropInfoContainer from "../../../containers/cropManage/CropInfoContainer"
import RequiredMark from "../../component/RequiredMark"
import InputBox from "../../component/common/InputBox"
import ComboBox from "../../component/common/ComboBox"
import AlertPopup from "../AlertPopup"
import TextAreaBox from "../../component/common/TextAreaBox"

import CommonContainer from "../../../containers/CommonContainer"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "421px",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const CropHRMAddPopup = (props) => {
  const classes = useStyles()
  const { t, _codeList, _cropHRM, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  const { open, onClose } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const [reqNumPeople, setReqNumPeople] = useState("")
  const [reqNumDays, setReqNumDays] = useState("")
  const [remark, setRemark] = useState("")
  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {
    getTranslationWords()
    if (_cropHRM && Object.keys(_cropHRM).length != 0) {
      setReqNumPeople(_cropHRM.reqNumPeople)
      setReqNumDays(_cropHRM.reqNumDays)
      setRemark(_cropHRM.remark)
    }

    return () => {
      setReqNumPeople(0)
      setReqNumDays(0)
      setRemark("")
    }
  }, [open])

  const onClickSave = () => {
    if (reqNumPeople == 0) return setAlertPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['reqNumPeople'])  })
    else if (reqNumDays == 0) return setAlertPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['reqNumDays']) })

    const param = {
      reqNumPeople: reqNumPeople || 0,
      reqNumDays: reqNumDays || 0,
      remark,
    }
    console.log('_cropHRM : ', _cropHRM)
    if (Object.keys(_cropHRM).length != 0) {
      param.cropHRMSeq = _cropHRM.cropHRMSeq
    }
    onClose(dict['save'], param)
  }

  const onClickCancel = () => {
    onClose(dict['cancel'], null)
  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose(null)}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <Container>
        <Title>{dict['staffManagement']}</Title>
        <div style={{ flex: 1 }}>
          <FlexBox>
            <Label><RequiredMark /> {dict['necessaryPersonnel']}</Label>
            <InputBox type="number" value={reqNumPeople} onChange={(e) => setReqNumPeople(e.target.value)} useNegativeNum={true} />
          </FlexBox>
          <FlexBox>
            <Label><RequiredMark /> {dict['necessaryDays']}</Label>
            <InputBox type="number" value={reqNumDays} onChange={(e) => setReqNumDays(e.target.value)} useNegativeNum={true} />
          </FlexBox>
          <FlexBox alignItems="flex-start">
            <Label>{dict['remark']}</Label>
            <TextAreaBox value={remark} setValue={setRemark}></TextAreaBox>
          </FlexBox>
        </div>
        <ButtonBox>
          <Button bgColor={"#fff"} hover={"rgba(174, 174, 174, 0.22)"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={onClickCancel}>
            {dict['cancel']}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {dict['save']}
          </Button>
        </ButtonBox>
      </Container>
    </Dialog>
  )
}

const Container = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 23px 26px 40px;
`
const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 34px;
  }
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  flex: 1;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

CropHRMAddPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default CropInfoContainer(CommonContainer(withTranslation()(CropHRMAddPopup)))
