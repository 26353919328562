// 판매관리 > 포장바코드 발행
import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import styled from "styled-components"
import { downExcel, dateFormat } from "../../../utils/util"

import AlertPopup from "../../popup/AlertPopup"
import CommonContainer from "../../../containers/CommonContainer"
import SalesContainer from "../../../containers/SalesContainer"
import OutBarcodeInfoPopup from "../../popup/SalesManage/OutBarcodeInfoPopup"

import { SkyButton, YellowButton, GrayButton, RedButton } from "../../component/common/Button"
import PageName from "../../component/common/PageName"
import DateBox from "../../component/common/DateBox"
import { TableComponent } from "../../component/common/Table"
import ComboBox from "../../component/common/ComboBox"
import InputBox from "../../component/common/InputBox"
import CodeHelpBox from "../../component/common/CodeHelpBox"
import TableCheckBox from "../../component/common/TableCheckBox"

// import icon_search from "images/search.png"
// import CodeHelpItemPopup from "../../popup/CodeHelpItemPopup"

const PackingBarcode = (props) => {
  const {
    t,

    initDataList,
    getDataList,
    saveDataList,
    setDataList,

    outBarcodeSalesList: dataList,
    OUT_BARCODE_SALES_LIST: actionType,
    outBarcodeList: dataSubList,
    OUT_BARCODE_LIST: actionSubType,
    DEL_OUT_BARCODE_INFO,

    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,

  } = props

  const dict = _translationWords[0] // 변수에 사전 담기

  const barcodeStatusList = [
    { value: 1, label: dict['incomplete'] },
    { value: 2, label: dict['complete'] },
  ]

  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })

  const [isOpenPopup, setIsOpenPopup] = useState(false)

  const [salesOutSeq, setSalesOutSeq] = useState(0)

  const temp1 = dateFormat(new Date(), "YYYYMM01")
  const temp2 = dateFormat(new Date(), "YYYYMMDD")

  const [dateFr, setDateFr] = useState(temp1)
  const [dateTo, setDateTo] = useState(temp2)

  const [salesOutNo, setSalesOutNo] = useState("")
  const [barcode, setBarcode] = useState("")
  const [barcodeStatus, setBarcodeStatus] = useState(1)

  // row2
  const [salesOutNoDis, setSalesOutNoDis] = useState("")
  // const [itemNameDis, setItemNameDis] = useState("")

  const [minDate, setMinDate] = useState(new Date()) //날짜 최저일
  const [maxDate, setMaxDate] = useState(new Date()) //날짜 최대일

  useEffect(() => {
    getData()

    return async () => {
      await initDataList(actionType)
      await initDataList(actionSubType)
    }
  }, [])

  // 달력 날짜 제한 관련
  useEffect(() => {

    // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
    const originalDate = new Date(dateFr.substr(0, 4) + '-' + dateFr.substr(4, 2) + '-' + dateFr.substr(6, 2));
    const modifiedDate = originalDate.setDate(originalDate.getDate() + 10000);

    setMinDate(new Date(dateFr.substr(0,4)+'-'+dateFr.substr(4,2)+'-'+dateFr.substr(6,2)));
    setMaxDate(modifiedDate);
    // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
    // console.log('dateFr(1) : ', dateFr)
    // console.log('dateTo(1) : ', dateTo)

  }, [dateFr])

  const getData = async () => {}

  const onClickSearch = async () => {
    const data = {
      dateFr,
      dateTo,
      salesOutNo,
      barcode,
      barcodeStatus,
    }

    await getDataList(actionType, data)

    if (salesOutSeq > 0) await getDataList(actionSubType, { salesOutSeq })
  }

  const closePopup = async () => {
    setIsOpenPopup(false)

    onClickSearch()
  }

  const onClickRow = async (row) => {
    setSalesOutNoDis(row.salesOutNo)
    setSalesOutSeq(row.seq)

    const data = {
      salesOutSeq: row.seq,
    }

    await getDataList(actionSubType, data)
  }

  // 추가/수정
  const onClickInfo = async (salesOutSeq) => {
    if (salesOutSeq > 0) {
      setSalesOutSeq(salesOutSeq)

      setIsOpenPopup(true)
    } // end if
  }

  // 삭제
  const onClickDelete = async (outBarcodeSeq) => {
    let data = {
      outBarcodeSeqs: [outBarcodeSeq],
    }

    if (await saveDataList(DEL_OUT_BARCODE_INFO, data)) onClickSearch()
  }

  // 일괄삭제
  const onClickBatchDelete = async () => {
    const outBarcodeSeqs = dataSubList.filter((row) => row.isCheck == "1")
    
    if (outBarcodeSeqs.length === 0) return setAlertPopup({ open: true, text: replaceWord(dict['n.Please select a product.'], dict['rowToDelete']) })
    
    let data = {
      outBarcodeSeqs: outBarcodeSeqs.map((row) => row.outBarcodeSeq),
    }

    if (await saveDataList(DEL_OUT_BARCODE_INFO, data)) onClickSearch()
  }

  // const onClickExcelDownload = async () => {

  //   const data = {
  //     dateFr,
  //     dateTo,
  //     salesNo,
  //     custSeq: cust.value,
  //     itemName,
  //     salesOutStatus,
  //   }

  //   const result = await getDataList( SALES_OUT_EXCEL, data, { responseType: "blob" } )

  //   downExcel({ result: result, fileName: "출하관리" })

  // }

  // const onClickAllCheckBox = async () => {
  //   const dataCnt = dataSubList.filter((row) => row.isCheck == 0 || row.isCheck == undefined).length
  //   const data = dataSubList.map((row) => ({ ...row, isCheck: dataCnt > 0 ? 1 : 0 }))

  //   await setDataList({ outBarcodeList: data })
  // }

  const onClickCheckBox = async (outBarcodeSeq, isCheck) => {
    let data = ""
    if (outBarcodeSeq == undefined) {
      const dataCnt = dataSubList.filter((row) => row.isCheck == 0 || row.isCheck == undefined).length
      data = dataSubList.map((row) => ({ ...row, isCheck: dataCnt > 0 ? 1 : 0 }))
    } else data = dataSubList.map((row) => (row.outBarcodeSeq == outBarcodeSeq ? { ...row, isCheck } : row))

    await setDataList({ outBarcodeList: data })
  }

  return (
    <Container>
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <OutBarcodeInfoPopup open={isOpenPopup} onClose={closePopup} salesOutSeq={salesOutSeq} />
      <FlexBox justifyContent={"space-between"}>
        <PageName name={dict['IssuePackingBarcode']} state={false} setState={() => {}} />
      </FlexBox>
      <Box>
        <WhiteBox>
          <SearchBox>
            
            {/* <div> */}
            <FlexBox style={{ minWidth: "400px" }}>
              <Text>{dict['salesOutDate']}</Text>
              <DateBox stdDate={dateFr} setStdDate={setDateFr} style={{ marginRight: "5px", width: "120px", minWidth: "100px" }} />
              <Text style={{ width: "15px" }}>~</Text>
              <DateBox stdDate={dateTo} setStdDate={setDateTo} style={{ width: "120px", minWidth: "100px" }} minDate={minDate} maxDate={maxDate} />

              <Text>{dict['shipmentNo']}</Text>
              <InputBox type="text" value={salesOutNo} onChange={(e) => setSalesOutNo(e.target.value)} />
            </FlexBox>
            {/* </div> */}
            <YellowButton onClick={onClickSearch} style={{ marginLeft: "14px" }}>{dict['search']}</YellowButton>
          
          </SearchBox>
          <ClassesBox>
            {/* <div> */}
            <FlexBox style={{ minWidth: "400px" }}>
              <Text>{dict['issueStatus']}</Text>
              <ComboBox
                defaultValue={dict['select']}
                hasDefault={true}
                items={barcodeStatusList}
                value={barcodeStatus}
                setValue={setBarcodeStatus}
                style={{ width: "260px", minWidth: "210px" }}
              />

              <Text>{dict['barCodeNo']}</Text>
              <InputBox type="text" value={barcode} onChange={(e) => setBarcode(e.target.value)} />
            </FlexBox>
            {/* </div> */}
            {/* <SkyButton onClick={() => onClickInfo(0)}>{t("new")}</SkyButton> */}
          </ClassesBox>
          <TableComponent
            headList={[
              { title: dict['shipmentNo'] , type: "text" },
              { title: dict['cropName'] , type: "text" },
              { title: dict['salesCustomer'] , type: "text" },
              { title: dict['salesOutDate'] , type: "date" },
              { title: dict['itemName'] , type: "text" },
              { title: dict['issueStatus'] , type: "" },
              { title: dict['unit'] , type: "text" },
              { title: dict['shipmentQty'] , type: "comma" },
              { title: dict['issueQty'] , type: "comma" },
              { title: dict['amtRemain'] , type: "comma" },
              { title: dict['issueBarcode'] , type: "" },
            ]}
            bodyList={dataList.map((i) => ({
              seq: i.salesOutSeq,
              salesOutNo: i.salesOutNo,
              cropName: i.cropName,
              custName: i.custName,
              salesOutDate: i.salesOutDate,
              itemName: i.itemName,
              barcodeStatus: i.barcodeStatus > 0 ? barcodeStatusList.filter((row) => row.value == i.barcodeStatus)[0].label : "",
              unitName: i.unitName,
              salesOutQty: i.salesOutQty,
              barcodeQty: i.barcodeQty,
              restQty: i.restQty,
              action: (
                <ActionButton color={"#038965cc"} onClick={() => onClickInfo(i.salesOutSeq)}>
                  {dict['issue']}
                </ActionButton>
              ),
            }))}
            dict={dict}
            onClick={(row) => onClickRow(row)}
          />
        </WhiteBox>
      </Box>
      <Box style={{ width: "60%" }}>
        <WhiteBox>
          <SearchBox>
            {/* <div> */}
            <FlexBox style={{ minWidth: "130px" }}>
              <Text> {dict['shipmentNo']}</Text>
              {/* <InputBox type="text" value={salesOutNoDis} disabled={true} /> */}
              <InputBox  type="text" value={salesOutNoDis} disabled={true} />
            </FlexBox>
            {/* </div> */}
            
            {/* <div> */}
            <FlexBox>
              <GrayButton onClick={() => onClickCheckBox()} style={{ marginLeft: "14px" }}>{dict['selectAll']}</GrayButton>
              <RedButton onClick={() => onClickBatchDelete()}  style={{ marginLeft: "14px" }} >{dict['del']}</RedButton>
              <SkyButton onClick={() => {}} style={{ marginLeft: "14px" }}>{dict['print']}</SkyButton>
            </FlexBox> 
            {/* </div> */}
          
          </SearchBox>
          <TableComponent
            headList={[
              { title: dict['select'] , type: "checkbox" },
              { title: dict['issueDate'] , type: "date" },
              { title: dict['packingQty'] , type: "comma" },
              { title: dict['barcode'] , type: "" },
              { title: "Actions" },
            ]}
            bodyList={dataSubList.map((i) => ({
              seq: i.outBarcodeSeq,
              select: <TableCheckBox check={i.isCheck == "1"} setCheck={() => onClickCheckBox(i.outBarcodeSeq, i.isCheck == "1" ? "0" : "1")} />,
              createDate: i.createDate,
              qty: i.qty,
              barcode: i.barcode,
              // Actions: true,

              action: (
                <>
                  <ActionButton color={"#fa5b59cc"} onClick={() => onClickDelete(i.outBarcodeSeq)}>
                    {dict['del'] }
                  </ActionButton>
                  <ActionButton color={"#55555599"} onClick={() => {}}>
                    {dict['issue'] }
                  </ActionButton>
                </>
              ),
            }))}
            dict={dict}
            // onClickEdit={(seq) => onClickInfo(seq)}
            // onClickDelete={(seq) => onClickDelete(seq)}
          />
        </WhiteBox>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
  min-width: 40px;
  `

const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  margin-top: 30px;
  padding: 28px 32px 32px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`
const ClassesBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 17px;
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
`

export const ActionButton = styled.button`
  width: 46px;
  height: 20px;
  border: 1px solid #aeaeae33;
  border-radius: 12px;
  color: ${(props) => props.color};
  font-size: 12px;
  &:last-child {
    margin-left: 16px;
  }
  &:hover {
    background-color: #d5d5d533;
  }
`

export default SalesContainer(CommonContainer(withTranslation()(PackingBarcode)))
