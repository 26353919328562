// 환경제어 > 자동환경설정(조건제어)
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"

import AutoEnvSettingOnOffContainer from "../../../containers/envControl/AutoEnvSettingOnOffContainer"
import AutoEnvSettingConfContainer from "../../../containers/envControl/AutoEnvSettingConfContainer"
import CommonContainer from "../../../containers/CommonContainer"

import PageName from "../../component/common/PageName"
import { SkyButton } from "../../component/common/Button"
import { TableComponent } from "../../component/common/Table"

import AutoEnvConfAddPopup from "../../popup/EnvControl/AutoEnvConfAddPopup"

// import AutoEnvOnOffExAddPopup from "../../popup/EnvControl/AutoEnvOnOffExAddPopup"
// import TableCheckBox from "../../component/common/TableCheckBox"
import Tab from "../../component/common/Tab"

const AutoEnvSettingConf = (props) => {

  const {

    _autoEnvWorkCenterList,
    _autoEnvEquipList,
    _autoEnvEquip,

    initAutoEnvWorkCenterList,
    getAutoEnvWorkCenterList,
    initAutoEnvEquipList,
    getAutoEnvEquipList,
    initAutoEnvEquip,
    getAutoEnvEquip,

  } = props

  const { t, initDataList, getDataList, saveDataList, setDataList, getComboList, initTranslationWords, getTranslationWords, _translationWords, } = props
  
  const { 
    confList: dataList, 
    GET_CONF_LIST: actionType, 
    MOD_CONF_INFO, 
    DEL_CONF_INFO, 
  } = props

  const [tabIndex, setTabIndex] = useState(0)
  const [equipSeq, setEquipSeq] = useState(0)

  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [envConfEquipConfSeq, setEnvConfEquipConfSeq] = useState(0)
  const dict = _translationWords[0] // 변수에 사전 담기
  
  useEffect(() => {

    getTranslationWords()
    getData() 

    return async () => {
      await initAutoEnvWorkCenterList()
      await initAutoEnvEquipList()
      await initAutoEnvEquip()
    }
  }, [])

  const getData = async () => {
    
    await getAutoEnvWorkCenterList()

  }

  useEffect(() => {
    if (_autoEnvWorkCenterList.length > 0) {
      setTabIndex(_autoEnvWorkCenterList[0].workCenterSeq)
      getEquipList(_autoEnvWorkCenterList[0].workCenterSeq)
    }
  }, [_autoEnvWorkCenterList])

  useEffect(() => {
    if (_autoEnvEquipList.length > 0) {
      
      setEquipSeq(_autoEnvEquipList[0].equipSeq)
      getEquipInfo(_autoEnvEquipList[0].equipSeq)

    }
  }, [_autoEnvEquipList]);

  const getEquipList = async (workCenterSeq) => {
    await getAutoEnvEquipList({ workCenterSeq, type: 0 })
  }

  const getEquipInfo = async (equipSeq) => {

    await getAutoEnvEquip({ equipSeq })

    const data = {
      equipSeq
    }

    await getDataList(actionType, data)

  }

  const closePopup = async () => {
    setIsOpenPopup(false)

    // getData()
    getEquipInfo( equipSeq )
  }

  const onClickInfo = async (envConfEquipConfSeq) => { // 정보 (추가/수정)

    setEnvConfEquipConfSeq(envConfEquipConfSeq)

    setIsOpenPopup(true)
  }

  const onClickDelete = async (envConfEquipConfSeq) => { // 정보 (삭제)

    let data = { envConfEquipConfSeq }

    if (await saveDataList(DEL_CONF_INFO, data)) getEquipInfo( equipSeq )
  }

  return (
    <Container>
      
      <AutoEnvConfAddPopup open={isOpenPopup} onClose={closePopup} envConfEquipConfSeq={envConfEquipConfSeq} equipSeq={equipSeq} />

      <PageName name={dict['autoEnvSetting']+'('+dict['conditionControl']+')'} state={false} setState={() => {}} />
      <Tab
        list={_autoEnvWorkCenterList.map((i) => ({ seq: i.workCenterSeq, name: i.workCenterName }))}
        tabIndex={tabIndex}
        onClick={(i) => {
          getEquipList(i.seq)
          setTabIndex(i.seq)
          setEquipSeq(0)
        }}
      />
      <Box>
        {_autoEnvEquipList.length > 0 && tabIndex > 0 && (
          <Left>
            <LeftTop>
              <TableComponent
                headList={[
                  { title: dict['facilities'] },
                ]}
                bodyList={_autoEnvEquipList.map((i, idx) => ({
                  seq: i.equipSeq,
                  equipName: i.equipName,
                }))}
                dict={dict}
                onClick={(row)=>{ 
                  getEquipInfo(row.seq)
                  setEquipSeq(row.seq)
                }}
              />
            </LeftTop>
          </Left>
        )}
        {_autoEnvEquipList.length > 0 && equipSeq > 0 && (
          <Right>
            <RightTop>
              <FlexBox justifyContent={"flex-end"}>
                <SkyButton onClick={() => onClickInfo(0)}>{dict['add']}</SkyButton>
              </FlexBox>
              <TableComponent
                headList={[
                  { title: `${t("no")}` },
                  { title: dict['time'], type: "text" },
                  { title: dict['temperature'], type: "text" },
                  { title: dict['repeatOperation']+'/'+dict['delayTime']+'('+dict['sec']+')', type: "text" },
                  // { title: `작동` },
                  { title: `${t("actions")}` },
                ]}
                bodyList={dataList.map((i, idx) => ({
                  seq: i.envConfEquipConfSeq,
                  no: dataList.length - idx,
                  time: i.timeFr.substr(0,2)+':'+i.timeFr.substr(-2) 
                       +' ~ '
                       +i.timeTo.substr(0,2)+':'+i.timeTo.substr(-2),
                  temp: i.tempKind == 1 ? i.tempFr + ' ~ ' + i.tempTo :
                        i.tempKind == 2 ? i.tempTo + '('+dict['moreThan']+')' :
                        i.tempKind == 3 ? i.tempFr + '('+dict['below']+')' :
                        i.tempKind == 4 ? '('+dict['unused']+')' :
                        '',
                        
                  
                  //i.tempFr + ' ~ ' + i.tempTo + (' ('+(i.tempKind == 2 ? '이상' : i.tempKind == 3 ? '이하' : '사이')+')'),
                  delay: i.actionTime+', '+i.delayTime,
                  // action: i.actionType == 1 ? '열림' :
                  //         i.actionType == 2 ? '닫힘' :
                  //         '',

                  Actions: true,
                }))}
                dict={dict}
                onClickEdit={(seq) => onClickInfo(seq)}
                onClickDelete={(seq) => onClickDelete(seq)}
              />
            </RightTop>
          </Right>
        )}
      </Box>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  &:after {
    content: "";
    display: block;
    height: 42px;
    width: 100%;
  }
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  margin-top: 28px;
  height: 100%;
`
const Left = styled.div`
  width: 260px;
  height: 100%;
`

const Right = styled.div`
  flex: 1;
  height: 100%;
`

const RightTop = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 80%;
  margin: 0 23px;
  padding: 28px 35px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`

const LeftTop = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 80%;
  margin: 0px;
  padding: 0px 20px 20px 20px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`

// const RightBottom = styled.div`
//   background-color: #fff;
//   width: 100%;
//   height: 350px;
//   margin: 30px 23px 0;
//   padding: 28px 35px;
//   border: 1px solid #fff;
//   border-radius: 9px;
//   box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
// `

export default AutoEnvSettingOnOffContainer(AutoEnvSettingConfContainer(CommonContainer(withTranslation()(AutoEnvSettingConf))))
