// 파일 생성 - 차건담 | 마지막 수정 날짜 - 2023.06.13
import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const { 
    getWorkCenterComboList, getSensorNodeComboList, getSensorKindComboList, 
    initChartDataList, getChartDataList,
    initCompareChartDataList, getCompareChartDataList,
    initSensorMinMax, getSensorMinMax,
    initTargetValueList, getTargetValueList,
    insertTargetValueList,
  } = actions.ChartAction

// redux state > component mapping
const mapStateToProps = (state) => ({
    _workCenterComboList: state.ChartReducer._workCenterComboList || [], // 작업장 리스트
    _sensorNodeComboList: state.ChartReducer._sensorNodeComboList || [], // 센서노드 리스트
    _sensorKindComboList: state.ChartReducer._sensorKindComboList || [], // 센서종류 리스트

    _chartDataList: state.ChartReducer._chartDataList || [], // 일반차트 데이터 리스트

    _compareChartDataList: state.ChartReducer._compareChartDataList || [], // 비교차트 데이터 리스트

    _sensorMinMax: state.ChartReducer._sensorMinMax || [], // 센서 최소값 및 최대값

    _targetValueList: state.ChartReducer._targetValueList || [], // 센서 최대 목표값 및 최소 목표값
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
    getWorkCenterComboList: (param) => dispatch(getWorkCenterComboList(param)),
    getSensorNodeComboList: (param) => dispatch(getSensorNodeComboList(param)),
    getSensorKindComboList: (param) => dispatch(getSensorKindComboList(param)),

    initChartDataList: (param) => dispatch(initChartDataList(param)),
    getChartDataList: (param) => dispatch(getChartDataList(param)),

    initCompareChartDataList: (param) => dispatch(initCompareChartDataList(param)),
    getCompareChartDataList: (param) => dispatch(getCompareChartDataList(param)),

    initSensorMinMax: (param) => dispatch(initSensorMinMax(param)),
    getSensorMinMax: (param) => dispatch(getSensorMinMax(param)),

    initTargetValueList: (param) => dispatch(initTargetValueList(param)),
    getTargetValueList: (param) => dispatch(getTargetValueList(param)),

    insertTargetValueList: ( param ) => dispatch(insertTargetValueList( param )),
})

const WithHoc = (ChildComponent) => (props) => {
    return <ChildComponent {...props} />
  }
  
  const hoc = connect(mapStateToProps, mapDispatchToProps)
  
  export default compose(hoc, WithHoc)
  