import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  initAutoEnvWorkCenterList,
  getAutoEnvWorkCenterList,
  initAutoEnvEquipList,
  getAutoEnvEquipList,
  initAutoEnvEquip,
  getAutoEnvEquip,
  addAutoEnvConfEquipOnOff,
  initAutoEnvConfEquipOnOff,
  getAutoEnvConfEquipOnOff,
  editAutoEnvConfEquipOnOff,
  delAutoEnvConfEquipOnOff,
  addAutoEnvConfEquipOnOffEx,
  initAutoEnvConfEquipOnOffEx,
  getAutoEnvConfEquipOnOffEx,
  editAutoEnvConfEquipOnOffEx,
  delAutoEnvConfEquipOnOffEx,
} = actions.EnvControlAction
const { getComboList } = actions.SystemSettingAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _autoEnvWorkCenterList: state.EnvControlReducer._autoEnvWorkCenterList,
  _autoEnvEquipList: state.EnvControlReducer._autoEnvEquipList,
  _autoEnvEquip: state.EnvControlReducer._autoEnvEquip,

  _autoEnvConfEquipOnOff: state.EnvControlReducer._autoEnvConfEquipOnOff,
  _autoEnvConfEquipOnOffEx: state.EnvControlReducer._autoEnvConfEquipOnOffEx,

  _comboEquipList: state.SystemSettingReducer._comboEquipList,
  
  _codeList: state.SystemSettingReducer._codeList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  initAutoEnvWorkCenterList: (param) => dispatch(initAutoEnvWorkCenterList(param)),
  getAutoEnvWorkCenterList: (param) => dispatch(getAutoEnvWorkCenterList(param)),
  initAutoEnvEquipList: (param) => dispatch(initAutoEnvEquipList(param)),
  getAutoEnvEquipList: (param) => dispatch(getAutoEnvEquipList(param)),
  initAutoEnvEquip: (param) => dispatch(initAutoEnvEquip(param)),
  getAutoEnvEquip: (param) => dispatch(getAutoEnvEquip(param)),

  addAutoEnvConfEquipOnOff: (param) => dispatch(addAutoEnvConfEquipOnOff(param)),
  initAutoEnvConfEquipOnOff: (param) => dispatch(initAutoEnvConfEquipOnOff(param)),
  getAutoEnvConfEquipOnOff: (param) => dispatch(getAutoEnvConfEquipOnOff(param)),
  editAutoEnvConfEquipOnOff: (param) => dispatch(editAutoEnvConfEquipOnOff(param)),
  delAutoEnvConfEquipOnOff: (param) => dispatch(delAutoEnvConfEquipOnOff(param)),
  addAutoEnvConfEquipOnOffEx: (param) => dispatch(addAutoEnvConfEquipOnOffEx(param)),
  initAutoEnvConfEquipOnOffEx: (param) => dispatch(initAutoEnvConfEquipOnOffEx(param)),
  getAutoEnvConfEquipOnOffEx: (param) => dispatch(getAutoEnvConfEquipOnOffEx(param)),
  editAutoEnvConfEquipOnOffEx: (param) => dispatch(editAutoEnvConfEquipOnOffEx(param)),
  delAutoEnvConfEquipOnOffEx: (param) => dispatch(delAutoEnvConfEquipOnOffEx(param)),

  getComboList: (param) => dispatch(getComboList(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
