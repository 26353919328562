import React from "react"
import styled from "styled-components"

const RequiredMark = () => {
  return <Red>* </Red>
}

const Red = styled.span`
  color: red;
`

export default RequiredMark
