import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { downExcel } from "../../../utils/util"
import { timeKindList } from "../../../utils/data"
import WorkPlaceInfoContainer from "../../../containers/defaultInfo/WorkPlaceInfoContainer"
import PageName from "../../component/common/PageName"
import InputBox from "../../component/common/InputBox"
import { SkyButton, YellowButton, GrayButton } from "../../component/common/Button"
import { TableComponent } from "../../component/common/Table"
import WorkCenterAddPopup from "../../popup/DefaultInfo/WorkCenterAddPopup"

import CommonContainer from "../../../containers/CommonContainer" // 다국어 사전 가져오기 위해 import - 차건담 2023.03.02

const WorkplaceInfo = (props) => {
  const {
    t,
    _workCenterList,
    initWorkCenterList,
    getWorkCenterList,
    addWorkCenter,
    initWorkCenter,
    getWorkCenter,
    editWorkCenter,
    delWorkCenter,
    downloadWorkCenterListExcel,
  } = props
  const [workCenterAddPopup, setWorkCenterAddPopup] = useState(false)
  const [searchText, setSearchText] = useState("")
  
// 사전 관련 객체 가져오기 - 차건담 2023.03.02
  const { initTranslationWords, getTranslationWords, _translationWords, replaceWord } = props

  useEffect(() => {
    getTranslationWords() // 사전 가져오는 함수 - 차건담 2023.03.02
    return async () => await initWorkCenterList()
  }, [])

  let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.02.28

  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  const getData = async () => {
    await getWorkCenterList({ workCenterName: "" })
  }

  const onClickExcelDownload = async () => {
    const result = await downloadWorkCenterListExcel()
    downExcel({ result: result, fileName: dictCk("workCenterList") })
  }

  const onClickSearch = async () => {
    const result = await getWorkCenterList({ workCenterName: searchText })
    if (result) {
      setSearchText("")
    }
  }

  const onClickAdd = async () => {
    await initWorkCenter()
    setWorkCenterAddPopup(true)
  }

  const onClickEdit = async (seq) => {
    const result = await getWorkCenter({ workCenterSeq: seq })
    if (result) setWorkCenterAddPopup(true)
  }

  const onClickDelete = async (seq) => {
    const result = await delWorkCenter({ workCenterSeq: seq })
    if (result) getData()
  }

  const workCenterAddPopupHandler = async (type, value) => {
    let finalResult = false
    if (type == dictCk("save") && value.workCenterSeq == undefined) {
      const result = await addWorkCenter(value)
      if (result && !result.code != 0) {
        getData()
        finalResult = true
      }
    } else if (type == dictCk("save") && value.workCenterSeq) {
      const result = await editWorkCenter(value)
      if (result && !result.code != 0) {
        getData()
        finalResult = true
      }
    }
    if ((type == dictCk("save") && finalResult) || type == dictCk("cancel")) {
    setWorkCenterAddPopup(false)
  }
  }

  return (
    <Container>
      <WorkCenterAddPopup open={workCenterAddPopup} onClose={workCenterAddPopupHandler} />
      <FlexBox justifyContent="space-between">
        <PageName name={dictCk("workCenterInfo")} state={false} setState={() => {}} />
        <GrayButton onClick={onClickExcelDownload}>{dictCk("downloadExcelFile")}</GrayButton>
      </FlexBox>
      <Box>
        <WhiteBox>
          <FlexBox justifyContent="space-between">
            <FlexBox>
              <Text>{dictCk("workCenterName")}</Text>
              <InputBox style={{ marginRight: "14px" }} type="text" value={searchText} onChange={(e) => setSearchText(e.target.value)} />
            </FlexBox>
            <FlexBox justifyContent="flex-end">
              <YellowButton onClick={onClickSearch}>{dictCk("search")}</YellowButton>
              <SkyButton style={{ marginLeft: "14px" }} onClick={onClickAdd}>
                {dictCk("add")}
              </SkyButton>
            </FlexBox>
          </FlexBox>

          <TableComponent
            headList={[
              { title: `${dictCk("workCenterName")}`, type: "text" },
              { title: `${dictCk("workCenterNo")}`, type: "text" },
              { title: `${dictCk("workCenterKind")}`, type: "text" },
              { title: `${dictCk("area")}`, type: "number" },
              { title: `${dictCk("cropItem")}`, type: "text" },
              { title: `${dictCk("cropMethod1")}`, type: "text" },
              { title: `${dictCk("cropMethod2")}`, type: "text" },
              { title: `${dictCk("areaCnt")}`, type: "number" },
              { title: `${dictCk("timeKind")}`, type: "number" },
              { title: `${dictCk("isUse")}`, type: "check" },
              { title: `${dictCk("remark")}`, type: "text" },
              { title: `${dictCk("actions")}` }, // 수정 2024.03.18 by 강동우
              //{ title: `${dictCk("action")}` },
            ]}
            bodyList={_workCenterList.map((i) => ({
              seq: i.workCenterSeq,
              workCenterName: i.workCenterName,
              workCenterNo: i.workCenterNo,
              workCenterKindName: i.workCenterKindName,
              area: i.area + (i.areaUnitSeqName ? `(${i.areaUnitSeqName})` : ""),
              itemName: i.itemName,
              cropMethod1Name: i.cropMethod1Name,
              cropMethod2Name: i.cropMethod2Name,
              areaCnt: i.areaCnt,
              timeKind: t(timeKindList.find((j) => j.value == i.timeKind).label),
              isUse: i.isUse,
              remark: i.remark,
              Actions: true,
            }))}
            dict={dict}
            onClickEdit={(seq) => onClickEdit(seq)}
            onClickDelete={(seq) => onClickDelete(seq)}
          />
        </WhiteBox>
      </Box>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  align-items: ${(props) => props.alignItems || "center"};
  width: 100%;
`
const Box = styled.div`
  overflow: hidden;
  display: flex;
  height: 100%;
`
const WhiteBox = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
  margin-top: 30px;
  padding: 28px 32px 31px;
`
const Text = styled.p`
  width: 80px;
  color: #555555;
  font-size: 14px;
`

export default CommonContainer(WorkPlaceInfoContainer(withTranslation()(WorkplaceInfo)))
