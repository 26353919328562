import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../../actions"

const {
  setSalesCropList,
  setSalesCropPlanList,
  setSalesCropPlan,
  getSalesCropList,
  getSalesCropPlanList,
  getSalesCropPlan,
  addSalesCropPlan,
  editSalesCropPlan,
  delSalesCropPlan,
} = actions.SalesManageAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _salesCropList: state.SalesManageReducer._salesCropList,
  _salesCropPlanList: state.SalesManageReducer._salesCropPlanList,
  _salesCropPlan: state.SalesManageReducer._salesCropPlan,
  _codeList: state.SystemSettingReducer._codeList,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({
  setSalesCropList: (param) => dispatch(setSalesCropList(param)),
  setSalesCropPlanList: (param) => dispatch(setSalesCropPlanList(param)),
  setSalesCropPlan: (param) => dispatch(setSalesCropPlan(param)),
  getSalesCropList: (param) => dispatch(getSalesCropList(param)),
  getSalesCropPlanList: (param) => dispatch(getSalesCropPlanList(param)),
  getSalesCropPlan: (param) => dispatch(getSalesCropPlan(param)),
  addSalesCropPlan: (param) => dispatch(addSalesCropPlan(param)),
  editSalesCropPlan: (param) => dispatch(editSalesCropPlan(param)),
  delSalesCropPlan: (param) => dispatch(delSalesCropPlan(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
