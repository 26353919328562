// 품목 추가/수정 팝업
import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import PropTypes from "prop-types"
import styled from "styled-components"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"

import { dateFormat } from "../../../utils/util"

import AlertPopup from "../AlertPopup"

import CommonContainer from "../../../containers/CommonContainer"
import SalesContainer from "../../../containers/SalesContainer"

// import TableComponent from "../component/common/TableComponent"
// import { SkyButton, YellowButton, GrayButton } from "../component/common/Button"
// import DateBox from "../component/common/DateBox"
import RequiredMark from "../../component/RequiredMark"
import InputBox from "../../component/common/InputBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import ComboBox from "../../component/common/ComboBox"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
      maxWidth: "521px",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const SalesItemInfoPopup = (props) => {
  const classes = useStyles()

  const { t, initDataList, getDataList, saveDataList, setDataList, getComboList, initTranslationWords, getTranslationWords, _translationWords, replaceWord,} = props

  const {

    cropComboList,
    uminorComboList,

    salesInfo: dataInfo,
    SALES_INFO: actionType,
    // salesItemSeq
    salesItemIndex,
  } = props

  const { onClose, open } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })

  const [dataItemInfo, setDataItemInfo] = useState({ remark: "" })

  const dict = _translationWords[0] // 변수에 사전 담기

  const setCropSeq = (cropSeq, cropName) => {
    setDataItemInfo({ ...dataItemInfo, cropSeq, cropName, itemName: cropComboList.filter(row => row.cropSeq == cropSeq)[0].itemName , })
  }
  const setUnitSeq = (unitSeq, unitName) => {
    setDataItemInfo({ ...dataItemInfo, unitSeq, unitName })
  }
  const setQty = (qty) => {
    setDataItemInfo({ ...dataItemInfo, qty: parseInt(qty, 10), amt: qty * dataItemInfo.price })
  }
  const setPrice = (price) => {
    setDataItemInfo({ ...dataItemInfo, price: parseInt(price, 10), amt: dataItemInfo.qty * price })
  }
  // const setAmt = ( amt ) => { setDataItemInfo( { ...dataItemInfo, amt } ) }
  const setRemark = (remark) => {
    setDataItemInfo({ ...dataItemInfo, remark })
  }

  useEffect(() => {
    if (open) getData()

    return () => setDataItemInfo({})
  }, [open])

  const getData = async () => {

    await getComboList( { queryKind: "Crop" } )
    await getComboList( { queryKind: "UMinor" } )

    let data = salesItemIndex >= 0 ? dataInfo.items[salesItemIndex] : {} //.filter( row => row.salesItemSeq == salesItemSeq );

    // data = data.length >= 0 ? data[0] : {}

    setDataItemInfo(data)
  }

  const onClickSave = async () => {
    if (dataItemInfo.cropSeq === undefined || dataItemInfo.cropSeq <= 0) return setAlertPopup({ open: true, text:replaceWord(dict['n.Please select a product.'], dict['stdCropName']) })
    else if (dataItemInfo.unitSeq === undefined || dataItemInfo.unitSeq <= 0) return setAlertPopup({ open: true, text:replaceWord(dict['n.Please select a product.'], dict['unit']) })
    else if (dataItemInfo.qty === undefined || dataItemInfo.qty == 0) return setAlertPopup({ open: true, text:replaceWord(dict['n.Invalid value. Please try again.'], dict['quantity']) })
    else if (dataItemInfo.price === undefined || dataItemInfo.price == 0) return setAlertPopup({ open: true, text:replaceWord(dict['n.Invalid value. Please try again.'], dict['price']) })

    let data = []

    if (salesItemIndex >= 0) {
      data = dataInfo.items.map((row, idx) => (idx == salesItemIndex ? dataItemInfo : row))
    } else {
      data = (dataInfo.items || []).concat(dataItemInfo)
    }

    await setDataList({ salesInfo: { ...dataInfo, items: data } })

    onClose()
  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose()}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <div
        style={{
          backgroundColor: "#fff",
          overflowY: "hidden",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "700px",
          padding: "23px 51px 44px 40px",
        }}
      >
        <Title>{dict['sale']+dict['item']}</Title>
        <div style={{ flex: 1 }}>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['stdCropName']}
            </Label>
            <ComboBox
              style={{}}
              defaultValue={dict['select']}
              hasDefault={true}
              items={cropComboList.map((row) => ({ value: row.cropSeq, label: row.cropName }))}
              value={dataItemInfo.cropSeq}
              setValue={setCropSeq}
            />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['unit']}
            </Label>
            {/* <InputBox type="text" value={dataItemInfo.unitSeq} onChange={(e) => setUnitSeq(e.target.value)} /> */}
            <ComboBox
              style={{}}
              defaultValue={dict['select']}
              hasDefault={true}
              items={uminorComboList.filter((row) => row.majorSeq == 1003).map((row) => ({ value: row.minorSeq, label: row.minorName }))}
              value={dataItemInfo.unitSeq}
              setValue={setUnitSeq}
            />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['qty']}
            </Label>
            <InputBox type="number" value={dataItemInfo.qty} onChange={(e) => setQty(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {dict['price']}
            </Label>
            <InputBox type="number" value={dataItemInfo.price} onChange={(e) => setPrice(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>{dict['amount']}</Label>
            <InputBox type="number" value={dataItemInfo.amt} disabled={true} />
          </FlexBox>
          <FlexBox alignItems="flex-start">
            <Label>{dict['remark']}</Label>
            <TextAreaBox width={0} value={dataItemInfo.remark || ""} setValue={setRemark}></TextAreaBox>
          </FlexBox>
        </div>
        <ButtonBox>
          <Button bgColor={"#fff"} hover={"rgba(174, 174, 174, 0.22)"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={() => onClose()}>
            {dict['cancel']}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {dict['tempSave']}
          </Button>
        </ButtonBox>
      </div>
    </Dialog>
  )
}

const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  width: 147px;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

SalesItemInfoPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default CommonContainer(SalesContainer(withTranslation()(SalesItemInfoPopup)))
