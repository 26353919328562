import React from "react"
import styled from "styled-components"

const TextAreaBox = ({ width = 220, height = 80, value, setValue, disabled }) => {
  return (
    <div style={{ flex: width == 0 ? "1" : "none" }}>
      <StyledTextArea 
        width={width}
        height={height}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        maxLength={3000}
        disabled={disabled} // 화면에서 행 클릭 시 수정 안되게 하기 위한 값 - 차건담 2023.04.13
      >          
      </StyledTextArea>
      {/* <p style={{ color: "#555555", fontSize: "13px" }}>3000자까지 입력 가능 합니다.</p> */}
    </div>
  )
}

const StyledTextArea = styled.textarea`
  width: ${(props) => (props.width == 0 ? "100%" : `${props.width}px`)};
  height: ${(props) => props.height}px;
  resize: none;
`

export default TextAreaBox
