import React from "react"
import { connect } from "react-redux"
import { compose } from "redux"
import actions from "../actions"

const {
  getModuleList,
  getMenuList,
  getCodeList,
  getEnvSettingInfo,
  editEnvSettingInfo,
  initUserMajorCode,
  getUserMajorCode,

  initUserMinorCode,
  getUserMinorCode,
  addUserMinorCode,
  editUserMinorCode,
  delUserMinorCode,

  setModuleAuthList,
  getModuleAuthList,
  editModuleAuth,
  initUserPgmAuthMenuList,
  getUserPgmAuthMenuList,
  initUserPgmAuthList,
  getUserPgmAuthList,
  editUserPgmAuth,

  setCodeHelpItem,
  getCodeHelpItem,
  setCodeHelpCustomer,
  getCodeHelpCustomer,

  // 사용자 다국어 소분류 유용준
    initUserUDLanguageCode,
    setUserUDLanguageCode,
    getUserDefLanguageCode,
    editUserDefLanguageCode, // 사용자 다국어 소분류 수정 - 차건담 2023.02.06
    insertUserDefLanguageCode, // 사용자 다국어 소분류 추가 - 차건담 2023.02.07
    delUserDefLanguageCode, // 사용자 다국어 소분류 삭제 - 차건담 2023.02.13
    
    initUMajorSMajorSMinor,   // 사용자 대분류, 시스템 대분류/소분류 다국어 초기화 함수 - 차건담 2023.01.30
    getUMajorSMajorSMinor,    // 사용자 대분류, 시스템 대분류/소분류 다국어 가져오기 - 차건담 2023.01.30
    editUMajorSMajorSMinor,   // 시스템 대분류, 시스템 대분류/소분류 다국어 수정 - 2023.02.06
    insertUMajorSMajorSMinor, // 시스템 대분류, 시스템 대분류/소분류 다국어 추가 - 2023.02.07

  // 차건담 2023.01.20
    initSysMajorCode,        // 시스템 대분류값 초기화
    getSysMajorCode,         // 시스템 대분류값 가져오기 | 검색된 대분류값이 조건

    initSysMinorCode,        // 시스템 소분류값 초기화
    getSysMinorCode,         // 선택된 대분류에 속하는 소분류값 가져오기

    initSDLanguageCode,      // 시스템 다국어코드 초기화
    //getSDLanguageCode,       // 선택된 소분류값에 속하는 시스템 다국어코드 가져오기

    initLanguageKind,
    getLanguageKind,         // 언어종류 가져오기 - 차건담 2023.01.30


} = actions.SystemSettingAction

// redux state > component mapping
const mapStateToProps = (state) => ({
  _menuList: state.SystemSettingReducer._menuList,
  _codeList: state.SystemSettingReducer._codeList,
  //
  _envSettingInfo: state.SystemSettingReducer._envSettingInfo,
  //
  _userMajorCode: state.SystemSettingReducer._userMajorCode,
  _userMinorCode: state.SystemSettingReducer._userMinorCode,
 
  // 언어종류
  _languageKind: state.SystemSettingReducer._languageKind, // 차건담 2023.01.30

  // 다국어
  _userUMajorSMajorSMinor: state.SystemSettingReducer._userUMajorSMajorSMinor,
  _userUDLanguageCode: state.SystemSettingReducer._userUDLanguageCode,  // 유용준
  _userSDLanguageCode: state.SystemSettingReducer._userSDLanguageCode,  // 차건담 2023.01.19
  
  // 시스템 코드 - 차건담 2023.01.20
  _sysMajorCode: state.SystemSettingReducer._sysMajorCode,
  _sysMinorCode: state.SystemSettingReducer._sysMinorCode,

  _moduleAuthList: state.SystemSettingReducer._moduleAuthList,
  //
  _userPgmAuthMenuList: state.SystemSettingReducer._userPgmAuthMenuList,
  _userPgmAuthList: state.SystemSettingReducer._userPgmAuthList,
  //
  _codeHelpItem: state.SystemSettingReducer._codeHelpItem,
  _codeHelpCustomer: state.SystemSettingReducer._codeHelpCustomer,
})

// redux action > component mapping
const mapDispatchToProps = (dispatch) => ({

  getModuleList: (param) => dispatch(getModuleList(param)),
  getMenuList: (param) => dispatch(getMenuList(param)),
  getCodeList: (param) => dispatch(getCodeList(param)),
  // 환경설정
  getEnvSettingInfo: (param) => dispatch(getEnvSettingInfo(param)),
  editEnvSettingInfo: (param) => dispatch(editEnvSettingInfo(param)),
  // 사용자코드
  initUserMajorCode: (param) => dispatch(initUserMajorCode(param)),
  getUserMajorCode : (param) => dispatch(getUserMajorCode(param)),
  initUserMinorCode: (param) => dispatch(initUserMinorCode(param)),
  getUserMinorCode : (param) => dispatch(getUserMinorCode(param)),
  addUserMinorCode : (param) => dispatch(addUserMinorCode(param)),
  editUserMinorCode: (param) => dispatch(editUserMinorCode(param)),
  delUserMinorCode : (param) => dispatch(delUserMinorCode(param)),

  // 시스템코드 - 차건담 2023.01.20
    initSysMajorCode: (param) => dispatch(initSysMajorCode(param)) ,
    getSysMajorCode : (param) => dispatch(getSysMajorCode(param))  ,
    initSysMinorCode: (param) => dispatch(initSysMinorCode(param)) ,
    getSysMinorCode : (param) => dispatch(getSysMinorCode(param))  ,

  // 언어종류 가져오기(차건담)
    initLanguageKind: (param) => dispatch(initLanguageKind(param)),
    getLanguageKind: (param) => dispatch(getLanguageKind(param)),

  // 다국어 공통 - 차건담 2023.01.30
    // 사용자 대분류, 시스템 대분류/소분류 - 2023.01.31
    initUMajorSMajorSMinor: (param) => dispatch(initUMajorSMajorSMinor(param)), 
    getUMajorSMajorSMinor: (param) => dispatch(getUMajorSMajorSMinor(param)),
    editUMajorSMajorSMinor: (param) => dispatch(editUMajorSMajorSMinor(param)),     
    insertUMajorSMajorSMinor: (param) => dispatch(insertUMajorSMajorSMinor(param)),     

  // 사용자 다국어언어(유용준) 
    // 소분류
    initUserUDLanguageCode: (param) => dispatch(initUserUDLanguageCode(param)),
    setUserUDLanguageCode: (param) => dispatch(setUserUDLanguageCode(param)),
    getUserDefLanguageCode: (param) => dispatch(getUserDefLanguageCode(param)),
    editUserDefLanguageCode: (param) => dispatch(editUserDefLanguageCode(param)),
    insertUserDefLanguageCode: (param) => dispatch(insertUserDefLanguageCode(param)),
    delUserDefLanguageCode: (param) => dispatch(delUserDefLanguageCode(param)),

  // 시스템 다국어언어 - 차건담 2023.01.20
    initUserSDLanguageCode: (param) => dispatch(initSDLanguageCode(param)),
    //getUserSDLanguageCode : (param) => dispatch(getSDLanguageCode(param)) ,

  // 모듈권한
    setModuleAuthList: (param) => dispatch(setModuleAuthList(param)),
    getModuleAuthList: (param) => dispatch(getModuleAuthList(param)),
    editModuleAuth   : (param) => dispatch(editModuleAuth(param)),

  // 프로그램권한
    initUserPgmAuthMenuList: (param) => dispatch(initUserPgmAuthMenuList(param)),
    getUserPgmAuthMenuList: (param) => dispatch(getUserPgmAuthMenuList(param)),
    initUserPgmAuthList: (param) => dispatch(initUserPgmAuthList(param)),
    getUserPgmAuthList: (param) => dispatch(getUserPgmAuthList(param)),
    editUserPgmAuth: (param) => dispatch(editUserPgmAuth(param)),
  //
    setCodeHelpItem: (param) => dispatch(setCodeHelpItem(param)),
    getCodeHelpItem: (param) => dispatch(getCodeHelpItem(param)),
    setCodeHelpCustomer: (param) => dispatch(setCodeHelpCustomer(param)),
    getCodeHelpCustomer: (param) => dispatch(getCodeHelpCustomer(param)),
})

const WithHoc = (ChildComponent) => (props) => {
  return <ChildComponent {...props} />
}

const hoc = connect(mapStateToProps, mapDispatchToProps)

export default compose(hoc, WithHoc)
