// 현황정보 > EC/PH통계조회
import React from "react"
import { withTranslation } from "react-i18next"
import IncomeInquiryContainer from "../../../containers/IncomeInquiry/IncomeInquiryContainer"

const ECPHInquiry = () => {
  return <div></div>
}

export default IncomeInquiryContainer(withTranslation()(ECPHInquiry))
