// 시스템설정 > 프로그램권한
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import SystemSettingContainer from "../../../containers/SystemSettingContainer"
import PageName from "../../component/common/PageName"
import { YellowButton } from "../../component/common/Button"
import TableCheckBox from "../../component/common/TableCheckBox"
import { TableComponent } from "../../component/common/Table"
import InputBox from "../../component/common/InputBox"
import BasicPopup from "../../popup/BasicPopup"
import icon_plus from "images/plus.png"
import icon_minus from "images/minus.png"
import { arrayOf } from "prop-types"

import CommonContainer from "../../../containers/CommonContainer"

const ProgramPermission = (props) => {
  const {
    t,
    _userPgmAuthMenuList,
    _userPgmAuthList,
    initUserPgmAuthMenuList,
    getUserPgmAuthMenuList,
    initUserPgmAuthList,
    getUserPgmAuthList,
    editUserPgmAuth,

    initTranslationWords, 
    getTranslationWords, 
    _translationWords, 
    replaceWord,

  } = props
  const [popup, setPopup] = useState({ open: false, text: "" })
  const [programListOpen, setProgramListOpen] = useState(true)
  const [currentSelect, setCurrentSelect] = useState({ module: null, menu: null })
  const [pgmText, setPgmText] = useState("")
  const [userText, setUserText] = useState("")
  // const [userList, setUserList] = useState([])

  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {
    getTranslationWords()
    getData()

    return async () => {
      await initUserPgmAuthMenuList()
      await initUserPgmAuthList()
    }
  }, [])

  useEffect(() => {
    if (currentSelect.module == null || currentSelect.menu == null) return
    getListData()
  }, [currentSelect])

  // useEffect(() => {
  //   if (_userPgmAuthList.length == 0) return
  //   // setUserList(_userPgmAuthList[0].userList)
  // }, [_userPgmAuthList])

  const getData = async () => {
    await getUserPgmAuthMenuList()
  }

  const getListData = async () => {
    await getUserPgmAuthList({ menuSeq: currentSelect.menu, userName: "", pgmName: "" })
  }

  const onClickSearch = async () => {
    if (currentSelect.menu == null) return setPopup({ open: true, text:replaceWord(dict['n.Please select a product.'], dict['programPermission']) })

    const result = await getUserPgmAuthList({ menuSeq: currentSelect.menu, userName: userText, pgmName: pgmText })
    if (result) {
      // setPgmText("")
      // setUserText("")
    }
  }

  const onClickCheck = async (data) => {
    const result = await editUserPgmAuth({ pgmSeq: data.pgmSeq, userSeq: data.userSeq, isAuth: data.isAuth })
    // if (result) getListData()
    if (result) onClickSearch()
  }

  const toObject = (arr, num) => {
    let obj = {}
    for (let i = 0; i < arr.length; ++i) {
      obj["check" + i] = (
        <TableCheckBox
          check={_userPgmAuthList[num].userList[i].isAuth == 1}
          setCheck={(bool) => {
            onClickCheck({
              pgmSeq: _userPgmAuthList[num].userList[i].pgmSeq,
              userSeq: _userPgmAuthList[num].userList[i].userSeq,
              isAuth: bool ? 1 : 0,
            })
          }}
        />
      )
    }
    return obj
  }

  return (
    <Container>
      <BasicPopup open={popup.open} onClose={() => setPopup({ open: false, text: "" })} content={popup.text} selectedValue={[dict['confirm']]} />
      <PageName name={dict['programPermission']} state={false} setState={() => {}} />
      <FlexBox>
        <ProgramListView>
          <button
            style={{
              backgroundColor: "#d8d8d899",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              width: "100%",
              height: "37px",
              padding: "0 12.5px",
            }}
            onClick={() => setProgramListOpen(!programListOpen)}
          >
            <img style={{ width: "11px", height: "11px" }} src={programListOpen ? icon_minus : icon_plus} />
            <p style={{ color: "#555555", fontSize: "13px", marginLeft: "6.5px" }}>{dict['total']}</p>
          </button>
          {programListOpen && (
            <ScrollView>
              {_userPgmAuthMenuList.map((i, idx) => (
                <ProgramListItem key={idx} item={i} currentSelect={currentSelect} setCurrentSelect={setCurrentSelect} />
              ))}
            </ScrollView>
          )}
        </ProgramListView>

        <ProgramView>
          <SearchBox>
            <Text>{dict['programName']}</Text>
            <InputBox value={pgmText} onChange={(e) => setPgmText(e.target.value)} />
            <Text>{dict['programUserName']}</Text>
            <InputBox value={userText} onChange={(e) => setUserText(e.target.value)} />
            <YellowButton onClick={onClickSearch}>{dict['search']}</YellowButton>
          </SearchBox>
          <TableComponent
            headList={[
              { title: dict['programId'], type: "text" },
              { title: dict['programName'], type: "text" },
              ...(_userPgmAuthList.length != 0 ? _userPgmAuthList[0].userList : []).map((i) => ({ title: i.userName })),
              // ...userList.map((i) => ({ title: i.userName })),
            ]}
            bodyList={_userPgmAuthList.length == 0 ? [] : _userPgmAuthList.map((i, idx) => ({
              pgmId: i.pgmId,
              pgmName: i.pgmName,
              ...toObject(_userPgmAuthList.length != 0 ? _userPgmAuthList[0].userList : [], idx),
              // ...toObject(userList, idx),
            }))}
            dict={dict}
          />
        </ProgramView>
      </FlexBox>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`
const FlexBox = styled.div`
  overflow-y: hidden;
  display: flex;
  flex: 1;
  margin-top: 30px;
`
const ProgramListView = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  width: 259px;
  height: 100%;
  padding: 14px 12px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const ScrollView = styled.div`
  overflow-y: auto;
  flex: 1;
  &::-webkit-scrollbar {
    display: block;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #d8d8d866;
    border: 1px solid rgba(151, 151, 151, 0.48) inset;
  }
`
const ProgramListItem = ({ item, currentSelect, setCurrentSelect }) => {
  const [open, setOpen] = useState(true)

  return (
    <div>
      <button
        style={{
          backgroundColor: "#d8d8d84d",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          height: "37px",
          padding: "0 12.5px",
        }}
        onClick={() => setOpen(!open)}
      >
        <img style={{ width: "11px", height: "11px" }} src={open ? icon_minus : icon_plus} />
        <p style={{ color: "#555555", fontSize: "13px", marginLeft: "6.5px" }}>{item.moduleName}</p>
      </button>
      {open && (
        <div>
          {item.menuList.map((i, idx) => (
            <button
              key={idx}
              style={{
                backgroundColor: "#d8d8d81a",
                width: "100%",
                height: "37px",
                padding: "0 40px",
                color: "#555555",
                fontSize: "13px",
                textAlign: "left",
              }}
              onClick={() => setCurrentSelect({ module: i.moduleSeq, menu: i.menuSeq })}
            >
              <p>{i.menuName}</p>
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

const ProgramView = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 0px;
  margin-left: 24px;
  padding: 28px 35px 20px 40px;
  border: 1px solid #fff;
  border-radius: 9px;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
`
const SearchBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const Text = styled.p`
  color: #555555;
  font-size: 14px;
`

export default SystemSettingContainer(CommonContainer(withTranslation()(ProgramPermission)))
