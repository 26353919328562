// 자동제어[복합제어] - 목표값 조건변경 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled, { ThemeConsumer } from "styled-components"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
// import imageApis from "../../../apis/ImageApis"

import CommonContainer from "../../../containers/CommonContainer"
import AutoEnvSettingConfContainerComp from "../../../containers/envControl/AutoEnvSettingConfContainerComp"

import BasicPopup from "../../popup/BasicPopup"
import RequiredMark from "../../component/RequiredMark"
import InputBox from "../../component/common/InputBox"
// import TextAreaBox from "../../component/common/TextAreaBox"
import ComboBox from "../../component/common/ComboBox"
import AlertPopup from "../AlertPopup"

import CircularIndeterminate from "../../component/common/CircularIndeterminate" //로딩화면

const useStyles = makeStyles((theme) => ({
    container: {
        width: "100%",
        margin: "0 auto",
        "& .MuiDialog-paperFullWidth": {
            width: "100%",
        },
        "& .MuiPaper-root": {
            backgroundColor: "#fff",
            maxWidth: "960px",
        },
        "& .MuiDialog-paper": {
            margin: "0px",
        },
    },
}))

const AutoConfReSettingPopup = (props) => {
    const classes = useStyles()

    const { t, initTranslationWords, getTranslationWords, _translationWords, replaceWord, _codeList, workCenterComboList } = props

    const { getConfReSetting, addConfReSetting, editConfReSetting, infoItem, workCenterSeqs, standardKinds, _confReSettingInfo } = props

    const { onClose, open } = props

    const [equipReSettingSeq, setEquipReSettingSeq] = useState(0) // ReSettingSeq 코드
    const [workCenterSeq, setWorkCenterSeq] = useState(0) // 작업장코드
    const [standardKind, setStandardKind] = useState(0) //조건기준
    const [standardKindValue, setStandardKindValue] = useState("") //조건기준 - 센서값
    const [targetValueFr, setTargetValueFr] = useState(0) // 목표 설정값Fr
    const [targetValueTo, setTargetValueTo] = useState(0) // 목표 설정값To
    const [changeValue, setChangeValue] = useState(0) // 변경할 값
    let [changeValueDefault, setChangeValueDefault] = useState(0) // 변경할 값 디폴트
    const [popup, setPopup] = useState({ open: false, text: "" })
    const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
    const dict = _translationWords[0] // 변수에 사전 담기
    // 다국어 관련 ==================================================
    const dictCk = (word) => {
        if (dict == undefined || dict == null || dict == {} || dict == "") {
            return word
        } else {
            const result = dict[word] != undefined && dict[word] != null && dict[word] != "" ? dict[word] : word
            return result
        }
    }
    // ==============================================================

    const [loading, setLoading] = useState(false) // 사진업로드 중에 로딩화면

    useEffect(() => {
        getTranslationWords()
    }, [open])

    const onClickSave = async () => {
        if (workCenterSeq === undefined || workCenterSeq === 0 || workCenterSeq == null)
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["workCenter"]) })
        // 작업장
        else if (standardKind === undefined || standardKind === 0 || standardKind == null)
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["standardKind"]) })
        //조건기준

        changeValueDefault =
            parseInt(changeValueDefault == "" || undefined || null ? 0 : changeValueDefault) * -1 +
            parseInt(changeValue == "" || undefined || null ? 0 : changeValue)

        let data = {
            // equipReSettingSeq:0,
            workCenterSeq: workCenterSeq,
            standardKind: standardKind,
            targetValueFr: targetValueFr,
            targetValueTo: targetValueTo,
            changeValue: changeValue == "" ? 0 : changeValue,
        }

        let result = false
        if (infoItem.equipReSettingSeq != 0 && infoItem.equipReSettingSeq != undefined) {
            data.equipReSettingSeq = infoItem.equipReSettingSeq
            data.changeValueDefault = changeValueDefault
            result = await editConfReSetting(data)
        } else {
            result = await addConfReSetting(data)
        }
        if (result) {
            onClose()
        }
    } // onClickSave end----------------------------------------------------

    // 작업장 OR 조건기준 변경시 값 다시 SET
    const onSelectChange = async () => {
        const itemLength = Object.keys(_confReSettingInfo).length
        setEquipReSettingSeq(itemLength > 0 ? _confReSettingInfo.equipReSettingSeq : 0)
        // setWorkCenterSeq(itemLength > 0 ? _confReSettingInfo.workCenterSeq : workCenterSeqs)
        // setStandardKind(itemLength > 0 ? _confReSettingInfo.standardKind : standardKinds)
        setStandardKindValue(itemLength > 0 ? _confReSettingInfo.standardKindValue : "")
        setTargetValueFr(itemLength > 0 ? _confReSettingInfo.targetValueFr : 0)
        setTargetValueTo(itemLength > 0 ? _confReSettingInfo.targetValueTo : 0)
        setChangeValue(itemLength > 0 ? _confReSettingInfo.changeValue : "")
        setChangeValueDefault(itemLength > 0 ? _confReSettingInfo.changeValue : "")
    }

    // 팝업 오픈 초기 SET
    const getNewData = async () => {
        const itemLength = Object.keys(infoItem).length

        setEquipReSettingSeq(itemLength > 0 ? infoItem.equipReSettingSeq : 0)
        setWorkCenterSeq(itemLength > 0 ? infoItem.workCenterSeq : workCenterSeqs)
        setStandardKind(itemLength > 0 ? infoItem.standardKind : standardKinds)
        setStandardKindValue(itemLength > 0 ? infoItem.standardKindValue : "")
        setTargetValueFr(itemLength > 0 ? infoItem.targetValueFr : 0)
        setTargetValueTo(itemLength > 0 ? infoItem.targetValueTo : 0)
        setChangeValue(itemLength > 0 ? infoItem.changeValue : "")
        setChangeValueDefault(itemLength > 0 ? infoItem.changeValue : "")
    }

    useEffect(() => {
        if (open) getNewData()

        return async () => {}
    }, [open])

    // 작업장 OR 조건기준 변경시 값 다시 SET
    useEffect(() => {
        const fetchData = async () => {
            try {
                const result = await getConfReSetting({ workCenterSeq, standardKind })
            } catch (error) {
                // 에러 처리
            }
        }
        fetchData()
    }, [workCenterSeq, standardKind])

    useEffect(() => {
        onSelectChange()
    }, [_confReSettingInfo])

    return (
        <Dialog
            className={classes.container}
            style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
            onClose={() => onClose(null)}
            open={open}
            // fullWidth={true}
        >
            <AlertPopup
                bg={false}
                open={alertPopup.open}
                onClose={() => setAlertPopup({ open: false, text: alertPopup.text })}
                content={alertPopup.text}
            />
            <BasicPopup
                open={popup.open}
                onClose={() => setPopup({ open: false, text: popup.text })}
                content={popup.text}
                selectedValue={[dict["confirm"]]}
            />
            <div
                style={{
                    backgroundColor: "#fff",
                    overflowY: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    padding: "23px 51px 44px 40px",
                }}
            >
                <CircularIndeterminate open={loading} />
                <Title>{dictCk("changeTargetValueCondition")}</Title>

                <FlexBox>
                    <Label>
                        <RequiredMark /> {dictCk("workCenter")}
                    </Label>
                    <ComboBox
                        style={{ width: "200px", marginRight: "28px" }}
                        defaultValue={dict["select"]}
                        hasDefault={true}
                        items={workCenterComboList.map((row) => ({ value: row.workCenterSeq, label: row.workCenterName }))}
                        value={workCenterSeq}
                        setValue={setWorkCenterSeq}
                    />
                </FlexBox>

                <FlexBox>
                    <Label>
                        <RequiredMark /> {dictCk("standardKind")}
                    </Label>
                    <ComboBox
                        style={{ width: "200px" }}
                        defaultValue={dictCk("select")}
                        hasDefault={true}
                        items={((_codeList || []).filter((i) => i.majorSeq == 1015)[0] || { minorList: [] }).minorList.map((i) => ({
                            value: i.minorSeq,
                            label: i.minorName,
                        }))}
                        value={standardKind}
                        setValue={setStandardKind}
                    />
                </FlexBox>

                <FlexBox>
                    <Label>{dictCk("sensorData")}</Label>
                    <InputBox
                        style={{ width: "100px" }}
                        type="string"
                        disabled={true}
                        value={standardKindValue}
                        maxLength={100}
                        onChange={(e) => setStandardKindValue(e.target.value)}
                    />
                </FlexBox>

                <FlexBox>
                    <Label>{dictCk("targetSettingValue")}</Label>
                    <InputBox
                        style={{ width: "100px" }}
                        type="number"
                        // disabled={true}
                        value={targetValueFr}
                        maxLength={100}
                        onChange={(e) => setTargetValueFr(e.target.value)}
                    />
                    ~ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <InputBox
                        style={{ width: "100px" }}
                        type="number"
                        // disabled={true}
                        value={targetValueTo}
                        maxLength={100}
                        onChange={(e) => setTargetValueTo(e.target.value)}
                    />
                </FlexBox>

                <FlexBox alignItems="flex-start">
                    <Label>{dictCk("correctionValue")}</Label>
                    <InputBox
                        style={{ width: "100px" }}
                        type="text"
                        disabled={false}
                        value={changeValue}
                        onChange={(e) => setChangeValue(e.target.value)}
                    />
                </FlexBox>

                <ButtonBox>
                    <Button
                        bgColor={"#fff"}
                        hover={"rgba(174, 174, 174, 0.22)"}
                        border={"1px solid #c6c6c6"}
                        color={"#555555cc"}
                        onClick={() => onClose(dict["cancel"], null)}
                    >
                        {dict["cancel"]}
                    </Button>
                    <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
                        {dict["save"]}
                    </Button>
                </ButtonBox>
            </div>
        </Dialog>
    )
}

const Title = styled.p`
    opacity: 80%;
    margin-bottom: 23px;
    color: #555555;
    font-size: 15px;
    font-weight: 700;
    line-height: 32px;
`

const FlexBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: ${(props) => props.alignItems || "center"};
    margin-bottom: 14px;
`

const FlexView = styled.div`
    display: flex;
    /* overflow-x: hidden; */
    justify-content: ${(props) => props.justifyContent || "flex-start"};
    align-items: ${(props) => props.alignItems || "center"};
    margin-bottom: 14px;
`
const Label = styled.p`
    opacity: 80%;
    width: 102px;
    color: #555555;
    font-size: 14px;
    line-height: 34px;
`
const ImgArea = styled.div`
    flex: 1;
    overflow-x: auto;
`
const ButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
const Button = styled.button`
    background-color: ${(props) => props.bgColor};
    width: 147px;
    height: 40px;
    border: ${(props) => props.border};
    border-radius: 3px;
    color: ${(props) => props.color};
    font-size: 14px;

    &:last-child {
        margin-left: 14px;
    }

    &:hover {
        background-color: ${(props) => props.hover};
    }
`

AutoConfReSettingPopup.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
}

export default CommonContainer(AutoEnvSettingConfContainerComp(withTranslation()(AutoConfReSettingPopup)))
