// 표준작기정보 작기 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import StandardCropInfoContainer from "../../../containers/cropManage/StandardCropInfoContainer"
import RequiredMark from "../../component/RequiredMark"
import InputBox from "../../component/common/InputBox"
import InputYMBox from "../../component/common/InputYMBox"
import ComboBox from "../../component/common/ComboBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import AlertPopup from "../AlertPopup"
import CodeHelpBox from "../../component/common/CodeHelpBox"

import CommonContainer from "../../../containers/CommonContainer"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "421px",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const StdCropAddPopup = (props) => {
  const classes = useStyles()
  const { t, _codeHelpItem, _stdCrop, initTranslationWords, getTranslationWords, _translationWords, replaceWord, } = props
  const { open, onClose } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const [cropName, setCropName] = useState("")
  const [itemName, setItemName] = useState(0)
  const [cropDateFr, setCropDateFr] = useState("")
  const [remark, setRemark] = useState("")
  const dict = _translationWords[0] // 변수에 사전 담기

  useEffect(() => {
    getTranslationWords()
    console.log("_stdCrop >>>>>>>>>>>>>>>>>>>", _stdCrop)
    if (Object.keys(_stdCrop).length != 0) {
      setCropName(_stdCrop.stdCropName)
      setItemName({label: _stdCrop.itemName, value: _stdCrop.itemSeq})
      setCropDateFr(_stdCrop.cropDateFr)
      setRemark(_stdCrop.remark)
    }

    return () => {
      setCropName("")
      setItemName(0)
      setCropDateFr("")
      setRemark("")
    }
  }, [open])

  const onClickSave = () => {
    if (cropName == "") return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict['stdCropName'])})
    else if (itemName == 0) return setAlertPopup({ open: true, text: replaceWord(dict["n.Please select a product."], dict['itemName'])})
    else if (cropDateFr == "") return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict['cropDateFr']) })

    let param = {
      stdCropName: cropName,
      itemSeq: typeof itemName == 'object'?itemName['value']:itemName || 0,
      itemName: itemName == 0 ? "" : _codeHelpItem.find((i) => i.itemSeq == (typeof itemName == 'object'?itemName['value']:itemName)).itemName, // codeHelp 사용으로 JSON 객체의 label값 사용 - 차건담 2023.08.02
      cropDateFr: cropDateFr || "0101",
      remark,
    }
    if (Object.keys(_stdCrop).length != 0) {
      param.stdCropSeq = _stdCrop.stdCropSeq
    }
    onClose(dict['save'], param)
  }

  const onClickCancel = () => {
    onClose(dict['cancel'], null)
  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose(null)}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <Container>
        <Title>{dict['stdCrop']}</Title>
        <div style={{ flex: 1 }}>
          <FlexBox>
            <Label>
              <RequiredMark /> { dict['cropName']}
            </Label>
            <InputBox type="text" value={cropName} onChange={(e) => setCropName(e.target.value)} />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> { dict['itemName']}
            </Label>
            {/* <ComboBox
              style={{ width: "220px" }}
              defaultValue={ dict['select']}
              hasDefault={true}
              items={_codeHelpItem.map((i) => ({ value: i.itemSeq, label: i.itemName }))}
              value={itemName}
              setValue={setItemName}
            /> */}
            <CodeHelpBox // inputBox에서 CodeHelpBox로 변경 - 차건담 2023.07.13
              style={{}}
              placeholder={dict['itemName']}
              codeHelpSeq={1}
              // codeHelpParam={{}}
              value={itemName}
              setValue={setItemName}
            />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> { dict['cropDateFr']}
            </Label>
            <InputYMBox style={{}} disabled={false} value={cropDateFr} setValue={setCropDateFr} />
          </FlexBox>
          <FlexBox alignItems="flex-start">
            <Label>{ dict['remark']}</Label>
            <TextAreaBox value={remark} setValue={setRemark}></TextAreaBox>
          </FlexBox>
        </div>
        <ButtonBox>
          <Button bgColor={"#fff"} hover={"rgba(174, 174, 174, 0.22)"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={onClickCancel}>
            { dict['cancel']}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            { dict['save']}
          </Button>
        </ButtonBox>
      </Container>
    </Dialog>
  )
}

const Container = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 23px 26px 40px;
`
const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 34px;
  }
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  flex: 1;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

StdCropAddPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default StandardCropInfoContainer(CommonContainer(withTranslation()(StdCropAddPopup)))
