import React, { useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment"

import TableCheckBox from "./TableCheckBox"

import icon_plus from "images/plus.png"
import icon_minus from "images/minus.png"

export const TableAccodionComponent = (props) => {
  const { style = {}, headList = [], bodyList = [], onClick = () => {}, onClickEdit = () => {}, onClickDelete = () => {} } = props
  const { bodyMenu = [] } = props
  const [active, setActive] = useState(-1)

  return (
    <TableBox style={{ ...style }}>
      <Table>
        <Thead>
          <Tr className="thead">
            {headList.map((i, idx) => (
              <Th key={"th" + idx}>{i.title}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {bodyMenu.map((i, idx) => (
            <TableData
              key={idx}
              index={idx}
              item={i}
              headList={headList}
              onClick={onClick}
              onClickEdit={onClickEdit}
              onClickDelete={onClickDelete}
              active={active}
              setActive={setActive}
              bodyList={bodyList}
            />
          ))}
        </Tbody>
      </Table>
    </TableBox>
  )
}

TableAccodionComponent.propTypes = {
  headList: PropTypes.array,
  bodyList: PropTypes.array,
  onClick: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickDelete: PropTypes.func,
}

const TableData = ({ item, index, headList, onClick, onClickEdit, onClickDelete, active, setActive, bodyList }) => {
  const keys = Object.keys(item)
  const [isOpen, setIsOpen] = useState()

  const switchType = (type) => {
    switch (type) {
      case "text":
        return "left"
      case "number":
        return "right"
      case "comma":
        return "right"
      case "check":
        return "center"
      default:
        return "center"
    }
  }

  const foldBtn = () => {
    return (
      <button style={{ width: "27px" }}>
        <img style={{ width: "11px", height: "11px" }} src={isOpen ? icon_minus : icon_plus} />
      </button>
    )
  }

  return (
    <>
      <Tr
        className={`${index == active ? "active" : ""}`}
        onClick={() => {
          onClick(item)
          setIsOpen(!isOpen)
        }}
      >
        {keys
          .filter((i) => i != "seq")
          .filter((i) => i != "menuType")
          .map((i, idx) => {
            if (i == "Actions")
              return (
                <Td key={"td" + idx}>
                  <ActionButton
                    color={"#038965cc"}
                    onClick={(e) => {
                      e.stopPropagation()
                      onClickEdit(item["seq"])
                    }}
                  >
                    수정
                  </ActionButton>
                  <ActionButton
                    color={"#fa5b59cc"}
                    onClick={(e) => {
                      e.stopPropagation()
                      onClickDelete(item["seq"])
                    }}
                  >
                    삭제
                  </ActionButton>
                </Td>
              )
            else if (headList[idx].type == "check")
              return (
                <Td key={"td" + idx} textAlign={switchType(headList[idx].type)}>
                  <TableCheckBox check={item[i] == 1} setCheck={() => {}} />
                </Td>
              )
            else if (headList[idx].type == "img")
              return (
                <Td key={"td" + idx} textAlign={switchType(headList[idx].type)}>
                  <div>{!item[i] || item[i] == "" ? "" : <img style={{ height: "64px", width: "74px" }} src={item[i]} />}</div>
                </Td>
              )
            else
              return (
                <Td key={"td" + idx} textAlign={switchType(headList[idx].type)}>
                  <div style={{ width: headList[idx].width || "100%", fontWeight: item.menuType == 0 ? "bold" : "normal" }}>
                    {item.menuType != 0 && item.menuType != 1 && idx == 0 && foldBtn()}
                    {headList[idx].type === "date"
                      ? item[i] && moment(item[i], "YYYYMMDD").format("YYYY-MM-DD")
                      : headList[idx].type === "comma"
                      ? item[i].toLocaleString()
                      : headList[idx].type === "text" && (item[i] + "").length > 20
                      ? item[i].substr(0, 20) + "..."
                      : item[i]}
                  </div>
                </Td>
              )
          })}
      </Tr>

      {bodyList.map(
        (i, idx) =>
          item.menuType != 0 &&
          item.menuType != 1 &&
          item.menuType == i.menuType && (
            <FoldableTableData
              key={idx}
              index={idx}
              item={i}
              headList={headList}
              onClick={onClick}
              onClickEdit={onClickEdit}
              onClickDelete={onClickDelete}
              active={active}
              setActive={setActive}
              bodyList={bodyList}
              isOpen={isOpen}
              menuType={item.menuType}
            />
          )
      )}
    </>
  )
}

const FoldableTableData = ({ item, index, headList, onClick, onClickEdit, onClickDelete, active, setActive, isOpen, menuType }) => {
  const keys = Object.keys(item)

  const switchType = (type) => {
    switch (type) {
      case "text":
        return "left"
      case "number":
        return "right"
      case "comma":
        return "right"
      case "check":
        return "center"
      default:
        return "center"
    }
  }

  return (
    <>
      {isOpen && (
        <Tr
          className={`${index == active ? "active" : ""}`}
          onClick={() => {
            onClick(item)
          }}
        >
          {keys
            .filter((i) => i != "seq")
            .filter((i) => i != "menuType")
            .map((i, idx) => {
              // if (i == "Actions")
              //   return (
              //     <Td key={"td" + idx}>
              //       <ActionButton
              //         color={"#038965cc"}
              //         onClick={(e) => {
              //           e.stopPropagation()
              //           onClickEdit(item["seq"])
              //         }}
              //       >
              //         수정
              //       </ActionButton>
              //       <ActionButton
              //         color={"#fa5b59cc"}
              //         onClick={(e) => {
              //           e.stopPropagation()
              //           onClickDelete(item["seq"])
              //         }}
              //       >
              //         삭제
              //       </ActionButton>
              //     </Td>
              //   )
              // else
              if (headList[idx].type == "check")
                return (
                  <Td key={"td" + idx} textAlign={switchType(headList[idx].type)}>
                    <TableCheckBox check={item[i] == 1} setCheck={() => {}} />
                  </Td>
                )
              else if (headList[idx].type == "img")
                return (
                  <Td key={"td" + idx} textAlign={switchType(headList[idx].type)}>
                    <div>{!item[i] || item[i] == "" ? "" : <img style={{ height: "64px", width: "74px" }} src={item[i]} />}</div>
                  </Td>
                )
              else
                return (
                  <Td key={"td" + idx} textAlign={switchType(headList[idx].type)}>
                    <div style={{ width: headList[idx].width || "100%", marginLeft: menuType != 0 && headList[idx].type === "text" ? "27px" : "" }}>
                      {headList[idx].type === "date"
                        ? item[i] && moment(item[i], "YYYYMMDD").format("YYYY-MM-DD")
                        : headList[idx].type === "comma"
                        ? item[i].toLocaleString()
                        : headList[idx].type === "text" && (item[i] + "").length > 20
                        ? item[i].substr(0, 20) + "..."
                        : item[i]}
                    </div>
                  </Td>
                )
            })}
        </Tr>
      )}
    </>
  )
}

export const TableBox = styled.div`
  overflow: auto;
  width: 100%;
  margin-top: 23px;
  border: 1px solid #dedede;
  border-radius: 3px;
`
export const Table = styled.table`
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
  white-space: nowrap;
`
export const Tr = styled.tr`
  background-color: #ffffff;

  &:nth-child(even) {
    background-color: #fafafa;
  }
  &.active {
    background-color: #edf4eb;
  }
`
export const Thead = styled.thead``
export const Th = styled.th`
  background-color: #f6f6f6;
  position: sticky;
  top: 0px;
  height: 40px;
  padding: 0 10px;
  border-right: 1px solid #dedede;
  color: #555555b3;
  font-size: 14px;
  text-align: center;
  z-index: 1;
  &:last-child {
    border-right: none;
  }
`
export const Tbody = styled.tbody`
  & > tr {
    cursor: pointer;
  }
`
export const Td = styled.td`
  height: 40px;
  padding: 0 10px;
  border-right: 1px solid #e1e1e1;
  color: #555555b3;
  font-size: 14px;
  text-align: ${(props) => props.textAlign || "center"};
  &:last-child {
    border-right: none;
  }
`
export const ActionButton = styled.button`
  width: 46px;
  height: 20px;
  border: 1px solid #aeaeae33;
  border-radius: 12px;
  color: ${(props) => props.color};
  font-size: 12px;
  &:last-child {
    margin-left: 16px;
  }
  &:hover {
    background-color: #d5d5d533;
  }
`
