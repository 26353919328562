// 기본정보 > 설정정보 > 생산설비
import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { downExcel } from "../../../utils/util"
import ProductionLineContainer from "../../../containers/defaultInfo/ProductionLineContainer"
import PageName from "../../component/common/PageName"
import { GrayButton, SkyButton, YellowButton } from "../../component/common/Button"
import ComboBox from "../../component/common/ComboBox"
import InputBox from "../../component/common/InputBox"
import { TableComponent } from "../../component/common/Table"
import ProductionLineAddPopup from "../../popup/DefaultInfo/ProductionLineAddPopup"

import CommonContainer from "../../../containers/CommonContainer" // 다국어 사전 가져오기 위해 import - 차건담 2023.03.02

const ProductionLine = (props) => {
    const {
        t,
        _comboControlNodeList,
        getComboList,

        _productionLineList,
        initProductionLineList,
        getProductionLineList,
        addProductionLine,
        initProductionLine,
        getProductionLine,
        editProductionLine,
        delProductionLine,
        downloadProductionLineListExcel,

        initProductionLineListDiv,
        getProductionLineListDiv,
        _productionLineListDiv,
    } = props
    const [producttionLineAddPopup, setProductionLineAddPopup] = useState(false)
    const [index, setIndex] = useState(0)
    const [searchText, setSearchText] = useState("")

    // 사전 관련 객체 가져오기 - 차건담 2023.03.02
    const { initTranslationWords, getTranslationWords, _translationWords, replaceWord } = props

    //추가,수정시 릴레이 중복값 확인api
    const getVerData = async () => {
        await getProductionLineListDiv({
            equipName: "",
            controlNodeSeq: 0,
        })
    }

    useEffect(() => {
        getTranslationWords() // 사전 가져오는 함수 - 차건담 2023.03.02
        getVerData()
        initData()

        return async () => {
            await initProductionLineList()
            await initProductionLineListDiv()
        }
    }, [])

    let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.03.02

    const dictCk = (word) => {
        if (dict == undefined || dict == null || dict == {} || dict == "") {
            return word
        } else {
            const result = dict[word] != undefined && dict[word] != null && dict[word] != "" ? dict[word] : word
            return result
        }
    }

    const initData = async () => {
        const pro1 = await getComboList({ queryKind: "WorkCenter" })
        const pro2 = await getComboList({ queryKind: "ControlNode" })

        Promise.all([pro1, pro2])
    }

    const getData = async () => {
        await getProductionLineList({
            equipName: searchText,
            controlNodeSeq: 0,
        })
    }

    const onClickExcelDownload = async () => {
        const result = await downloadProductionLineListExcel()
        downExcel({ result: result, fileName: dictCk("equipList") })
    }

    const onClickSearch = async () => {
        const result = await getProductionLineList({
            equipName: searchText,
            controlNodeSeq: index,
        })
        if (result) {
            setSearchText("")
        }
    }

    const onClickAdd = async () => {
        await initProductionLine()
        setProductionLineAddPopup(true)
    }

    const onClickEdit = async (seq) => {
        const result = await getProductionLine({ equipSeq: seq })
        if (result) setProductionLineAddPopup(true)
    }

    const onClickDelete = async (seq) => {
        const result = await delProductionLine({ equipSeq: seq })
        if (result) getData()
    }

    const controlNodeAddPopupHandler = async (type, value) => {
        let finalResult = false
        if (type == dictCk("save") && value.equipSeq == undefined) {
            const result = await addProductionLine(value)
            if (result && !result.code != 0) {
                getData()
                finalResult = true
            }
        } else if (type == dictCk("save") && value.equipSeq) {
            const result = await editProductionLine(value)
            if (result && !result.code != 0) {
                getData()
                finalResult = true
            }
        }
        if (type == dictCk("save")) getVerData()

        if ((type == dictCk("save") && finalResult) || type == dictCk("cancel")) {
            setProductionLineAddPopup(false)
        }
    }
    return (
        <Container>
            <ProductionLineAddPopup open={producttionLineAddPopup} onClose={controlNodeAddPopupHandler} verData1={_productionLineListDiv} />
            <FlexBox justifyContent="space-between">
                <PageName name={dictCk("equip")} state={false} setState={() => {}} />
                <GrayButton onClick={onClickExcelDownload}>{dictCk("downloadExcelFile")}</GrayButton>
            </FlexBox>
            <Box>
                <WhiteBox>
                    <FlexBox justifyContent="space-between">
                        <FlexBox>
                            <Text>{dictCk("equipName")}</Text>
                            <InputBox
                                style={{ marginRight: "33px" }}
                                type="text"
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                            />
                            <Text>{dictCk("controlNode")}</Text>
                            <ComboBox
                                style={{ width: "220px", marginRight: "28px" }}
                                defaultValue={dictCk("select")}
                                hasDefault={true}
                                items={_comboControlNodeList.map((i) => ({ value: i.controlNodeSeq, label: i.controlNodeName }))}
                                value={index}
                                setValue={setIndex}
                            />
                        </FlexBox>
                        <FlexBox>
                            <YellowButton onClick={onClickSearch}>{dictCk("search")}</YellowButton>
                            <SkyButton style={{ marginLeft: "14px" }} onClick={onClickAdd}>
                                {dictCk("add")}
                            </SkyButton>
                        </FlexBox>
                    </FlexBox>

                    <TableComponent
                        headList={[
                            { title: `${dictCk("workCenterName")}`, type: "text" },
                            { title: `${dictCk("equipName")}`, type: "text" },
                            { title: `${dictCk("equipNo")}`, type: "text" },
                            { title: `${dictCk("workKind")}`, type: "text" },
                            { title: `${dictCk("controlKind")}`, type: "text" },
                            { title: `${dictCk("equipVolt")}`, type: "text" },
                            { title: `${dictCk("costPover")}`, type: "number" },
                            // { title: "총가동시간(초)", type: "number" },
                            // { title: "스마트팜제어대상", type: "check" },
                            { title: `${dictCk("controlNode")}`, type: "text" },
                            { title: `${dictCk("relayFor")}`, type: "number" },
                            { title: `${dictCk("relayRev")}`, type: "number" },
                            { title: `${dictCk("isUse")}`, type: "check" },
                            { title: `${dictCk("remark")}`, type: "text" },
                            { title: `${dictCk("actions")}` },
                        ]}
                        bodyList={(_productionLineList || []).map((i, idx) => ({
                            seq: i.equipSeq,
                            workCenterName: i.workCenterName,
                            equipName: i.equipName,
                            equipNo: i.equipNo,
                            workKindName: i.workKindName,
                            controlKindName: i.controlKindName,
                            equipVoltName: i.equipVoltName,
                            costPover: i.costPover,
                            // workTime: i.workTime,
                            // isControl: i.isControl,
                            controlNodeName: i.controlNodeName,
                            relayNoFw: i.relayNoFw,
                            relayNoBk: i.relayNoBk,
                            isUse: i.isUse,
                            remark: i.remark,
                            Actions: true,
                        }))}
                        dict={dict}
                        onClickEdit={(seq) => onClickEdit(seq)}
                        onClickDelete={(seq) => onClickDelete(seq)}
                    />
                </WhiteBox>
            </Box>
        </Container>
    )
}

const Container = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`
const FlexBox = styled.div`
    display: flex;
    justify-content: ${(props) => props.justifyContent || "flex-start"};
    align-items: ${(props) => props.alignItems || "center"};
`
const Box = styled.div`
    overflow: hidden;
    display: flex;
    height: 100%;
`
const WhiteBox = styled.div`
    background-color: #fff;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 0px;
    border: 1px solid #fff;
    border-radius: 9px;
    box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.04);
    margin-top: 30px;
    padding: 28px 32px 31px;
`
const Text = styled.p`
    width: 80px;
    color: #555555;
    font-size: 14px;
`

export default CommonContainer(ProductionLineContainer(withTranslation()(ProductionLine)))
