// 거래처 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import CustomerContainer from "../../../containers/defaultInfo/CustomerContainer"
import RequiredMark from "../../component/RequiredMark"
import TableCheckBox from "../../component/common/TableCheckBox"
import ComboBox from "../../component/common/ComboBox"
import InputBox from "../../component/common/InputBox"
import InputTelBox from "../../component/common/InputTelBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import AlertPopup from "../AlertPopup"
import DaumPostCodePopup from "../DaumPostCodePopup"
import icon_search from "images/search.png"

import CommonContainer from "../../../containers/CommonContainer" // 다국어 사전 가져오기 위해 import - 차건담 2023.02.28

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
      maxWidth: "960px",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const CustAddPopup = (props) => {
  const classes = useStyles()
  const { t, _codeList, _customer } = props
  const { onClose, open } = props
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
  const [daumPostCodePopup, setDaumPostCodePopup] = useState(false)
  const [custName, setCustName] = useState("")
  const [custKind, setCustKind] = useState(0)
  const [custClass1, setCustClass1] = useState(0)
  const [custClass2, setCustClass2] = useState(0)
  const [custClass3, setCustClass3] = useState(0)
  const [zipCode, setZipCode] = useState("")
  const [addr, setAddr] = useState("")
  const [addrDetail, setAddrDetail] = useState("")
  const [blogUrl, setBlogUrl] = useState("")
  const [homepageUrl, setHomepageUrl] = useState("")
  const [isUse, setIsUse] = useState(true)
  const [etc, setEtc] = useState("")
  const [comKind, setComKind] = useState(0)
  const [bizKind, setBizKind] = useState(0)
  const [bizNo, setBizNo] = useState("")
  const [ceoName, setCeoName] = useState("")
  const [phoneNo, setPhoneNo] = useState("")
  const [teleNo, setTeleNo] = useState("")
  const [faxNo, setFaxNo] = useState("")
  const [email, setEmail] = useState("")

// 사전 관련 객체 가져오기 - 차건담 2023.02.28
  const { initTranslationWords, getTranslationWords, _translationWords, replaceWord } = props

  useEffect(() => {
    getTranslationWords() // 사전 가져오는 함수 - 차건담 2023.02.28

    if (open && Object.keys(_customer).length != 0) {
      setCustName(_customer.custName || "")
      setCustKind(_customer.custKind || 0)
      setCustClass1(_customer.custClass1 || 0)
      setCustClass2(_customer.custClass2 || 0)
      setCustClass3(_customer.custClass3 || 0)
      setZipCode(_customer.zipCode)
      setAddr(_customer.addr)
      setAddrDetail(_customer.addrDetail)
      setBlogUrl(_customer.blogUrl || "")
      setHomepageUrl(_customer.homepageUrl || "")
      setIsUse(_customer.isUse == 1)
      setEtc(_customer.remark)

      setComKind(_customer.corpKind || 0)
      setBizKind(_customer.bizKind || 0)
      setBizNo(_customer.bizNo)
      setCeoName(_customer.owner)
      setPhoneNo(_customer.cellNo)
      setTeleNo(_customer.teleNo)
      setFaxNo(_customer.faxNo)
      setEmail(_customer.email || "")
    }

    return () => {
      setCustName("")
      setCustKind(0)
      setCustClass1(0)
      setCustClass2(0)
      setCustClass3(0)
      setZipCode("")
      setAddr("")
      setAddrDetail("")
      setBlogUrl("")
      setHomepageUrl("")
      setIsUse(true)
      setEtc("")

      setComKind(0)
      setBizKind(0)
      setBizNo("")
      setCeoName("")
      setPhoneNo("")
      setTeleNo("")
      setFaxNo("")
      setEmail("")
    }
  }, [open])

  let dict = _translationWords[0] // 사전 데이터 변수에 담기 - 차건담 2023.02.28
  
  const dictCk = (word) => {
    if ( dict == undefined || dict == null || dict == {} || dict == "" ) {
      return word
    } else {
      const result = dict[word] != undefined && dict[word] != null && dict[word] != '' ? dict[word] : word
      return result
    }
  }

  const onClickSave = async () => {
    if (custName == "") return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid value. Please try again."), dictCk("custName")) })
    else if (custKind == 0) return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Please select a product."), dictCk("custKind")) })
    else if (blogUrl != "" && !/(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi.test(blogUrl))
      return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid format. Please try again."), dictCk("blogUrl")) })
    else if (homepageUrl != "" && !/(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi.test(homepageUrl))
      return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid format. Please try again."), dictCk("homepageUrl")) })
    else if (email != "" && !/^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i.test(email))
      return setAlertPopup({ open: true, text: replaceWord(dictCk("n.Invalid format. Please try again."), dictCk("email")) })

    const param = {
      custName,
      custKind: custKind,
      custClass1: custClass1,
      custClass2: custClass2,
      custClass3: custClass3,
      zipCode,
      addr,
      addrDetail,
      blogUrl,
      homepageUrl,
      isUse: isUse ? 1 : 0,
      remark: etc,
      corpKind: comKind,
      bizKind: bizKind,
      bizNo,
      owner: ceoName,
      cellNo: phoneNo,
      teleNo,
      faxNo,
      email,
    }
    if (_customer) param.custSeq = _customer.custSeq

    onClose(dictCk("save"), param)
  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose(null)}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <DaumPostCodePopup
        open={daumPostCodePopup}
        onClose={() => setDaumPostCodePopup(false)}
        onComplete={async (addr) => {
          setZipCode(addr.zonecode)
          setAddr(addr.userSelectedType == "R" ? addr.roadAddress : addr.jibunAddress)
          setAddrDetail("")
          setDaumPostCodePopup(false)
        }}
      />

      <div
        style={{
          backgroundColor: "#fff",
          //overflowY: "hidden", 핫픽스 - 차건담 2023.04.21
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "840px",
          padding: "23px 51px 44px 40px",
        }}
      >
        <Title>{dictCk("custInfo")}</Title>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
          <div style={{ flex: 1 }}>
            <FlexBox>
              <Label>
                <RequiredMark /> {dictCk("custName")}
              </Label>
              <InputBox style={{ width: "300px", marginRight: 0 }} type="text" value={custName} onChange={(e) => setCustName(e.target.value)} />
            </FlexBox>
            <FlexBox>
              <Label>
                <RequiredMark /> {dictCk("custKind")}
              </Label>
              <ComboBox
                style={{ width: "300px" }}
                defaultValue={dictCk("select")}
                hasDefault={true}
                items={
                  _codeList.length > 0 &&
                  _codeList.filter((i) => i.majorSeq == 1006).length > 0 &&
                  _codeList.filter((i) => i.majorSeq == 1006)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                }
                value={custKind}
                setValue={setCustKind}
              />
            </FlexBox>
            <FlexBox>
              <Label>{dictCk("custClass1")}</Label>
              <ComboBox
                style={{ width: "300px" }}
                defaultValue={dictCk("select")}
                hasDefault={true}
                items={
                  _codeList.length > 0 &&
                  _codeList.filter((i) => i.majorSeq == 1014).length > 0 &&
                  _codeList.filter((i) => i.majorSeq == 1014)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                }
                value={custClass1}
                setValue={setCustClass1}
              />
            </FlexBox>
            <FlexBox>
              <Label>{dictCk("custClass2")}</Label>
              <ComboBox
                style={{ width: "300px" }}
                defaultValue={dictCk("select")}
                hasDefault={true}
                items={
                  _codeList.length > 0 &&
                  _codeList.filter((i) => i.majorSeq == 1015).length > 0 &&
                  _codeList.filter((i) => i.majorSeq == 1015)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                }
                value={custClass2}
                setValue={setCustClass2}
              />
            </FlexBox>
            <FlexBox>
              <Label>{dictCk("custClass3")}</Label>
              <ComboBox
                style={{ width: "300px" }}
                defaultValue={dictCk("select")}
                hasDefault={true}
                items={
                  _codeList.length > 0 &&
                  _codeList.filter((i) => i.majorSeq == 1016).length > 0 &&
                  _codeList.filter((i) => i.majorSeq == 1016)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                }
                value={custClass3}
                setValue={setCustClass3}
              />
            </FlexBox>
            <FlexBox>
              <Label>{dictCk("zipCode")}</Label>
              <InputBox style={{ width: "200px", marginRight: 0 }} type="text" value={zipCode} onChange={() => {}} />
              <button style={{ width: "40px", height: "40px" }} onClick={() => setDaumPostCodePopup(true)}>
                <img style={{ width: "16px", height: "16px" }} src={icon_search} />
              </button>
            </FlexBox>
            <FlexBox>
              <Label>{dictCk("addr")}</Label>
              <InputBox
                style={{ width: "300px", marginRight: 0 }}
                disabled={true}
                type="text"
                value={addr}
                onChange={(e) => setAddr(e.target.value)}
              />
            </FlexBox>
            <FlexBox>
              <Label>{dictCk("addrDetail")}</Label>
              <InputBox style={{ width: "300px", marginRight: 0 }} type="text" value={addrDetail} onChange={(e) => setAddrDetail(e.target.value)} />
            </FlexBox>
            <FlexBox>
              <Label>{dictCk("blogUrl")}</Label>
              <InputBox
                style={{ width: "300px", marginRight: 0 }}
                type="text"
                value={blogUrl}
                onChange={(e) => setBlogUrl(e.target.value)}
                maxLength={200}
              />
            </FlexBox>
            <FlexBox>
              <Label>{dictCk("homepageUrl")}</Label>
              <InputBox
                style={{ width: "300px", marginRight: 0 }}
                type="text"
                value={homepageUrl}
                onChange={(e) => setHomepageUrl(e.target.value)}
                maxLength={200}
              />
            </FlexBox>
            <FlexBox>
              <Label>{dictCk("isUse")}</Label>
              <TableCheckBox check={isUse} setCheck={setIsUse} />
            </FlexBox>
          </div>

          <div style={{ marginTop: "54px" }}>
            <FlexBox>
              <Label>{dictCk("corpKind")}</Label>
              <ComboBox
                style={{ width: "300px" }}
                defaultValue={dictCk("select")}
                hasDefault={true}
                items={
                  _codeList.length > 0 &&
                  _codeList.filter((i) => i.majorSeq == 1004).length > 0 &&
                  _codeList.filter((i) => i.majorSeq == 1004)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                }
                value={comKind}
                setValue={setComKind}
              />
            </FlexBox>
            <FlexBox>
              <Label>{dictCk("bizKind")}</Label>
              <ComboBox
                style={{ width: "300px" }}
                defaultValue={dictCk("select")}
                hasDefault={true}
                items={
                  _codeList.length > 0 &&
                  _codeList.filter((i) => i.majorSeq == 1005).length > 0 &&
                  _codeList.filter((i) => i.majorSeq == 1005)[0].minorList.map((i) => ({ value: i.minorSeq, label: i.minorName }))
                }
                value={bizKind}
                setValue={setBizKind}
              />
            </FlexBox>
            <FlexBox>
              <Label>{dictCk("bizNo")}</Label>
              <InputBox
                style={{ width: "200px", marginRight: 0 }}
                placeholder={"000 - 00 - 00000"}
                type="text"
                mask="### - ## - #####"
                value={bizNo}
                onChange={(e) => setBizNo(e.target.value)}
              />
            </FlexBox>
            <FlexBox>
              <Label>{dictCk("ownerName")}</Label>
              <InputBox style={{ width: "200px", marginRight: 0 }} type="text" value={ceoName} onChange={(e) => setCeoName(e.target.value)} />
            </FlexBox>
            <FlexBox>
              <Label>{dictCk("cellNo")}</Label>
              <InputTelBox style={{ width: "200px", marginRight: 0 }} value={phoneNo} setValue={setPhoneNo} />
            </FlexBox>
            <FlexBox>
              <Label>{dictCk("RepTelNumber")}</Label>
              <InputTelBox style={{ width: "200px", marginRight: 0 }} value={teleNo} setValue={setTeleNo} />
            </FlexBox>
            <FlexBox>
              <Label>{dictCk("faxNo")}</Label>
              <InputTelBox style={{ width: "200px", marginRight: 0 }} value={faxNo} setValue={setFaxNo} />
            </FlexBox>
            <FlexBox>
              <Label>{dictCk("email")}</Label>
              <InputBox style={{ width: "300px", marginRight: 0 }} type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
            </FlexBox>
          </div>
        </div>
        <FlexBox alignItems="flex-start">
          <Label>{dictCk("remark")}</Label>
          <TextAreaBox width={0} value={etc} setValue={setEtc} />
        </FlexBox>
        <ButtonBox>
          <Button
            bgColor={"#fff"}
            hover={"rgba(174, 174, 174, 0.22)"}
            border={"1px solid #c6c6c6"}
            color={"#555555cc"}
            onClick={() => onClose(dictCk("cancel"), null)}
          >
            {dictCk("cancel")}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {dictCk("save")}
          </Button>
        </ButtonBox>
      </div>
    </Dialog>
  )
}

const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: ${(props) => props.alignItems || "center"};
  margin-bottom: 14px;
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  width: 147px;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

CustAddPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default CommonContainer(CustomerContainer(withTranslation()(CustAddPopup)))
