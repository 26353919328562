// 생산>방문자관리 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import moment from "moment"
import { withTranslation } from "react-i18next"
import { Dialog } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import VisitorManagementContainer from "../../../containers/IncomeInquiry/VisitorManagementContainer"
import RequiredMark from "../../component/RequiredMark"
import DateBox from "../../component/common/DateBox"
import InputBox from "../../component/common/InputBox"
import InputTimeBox from "../../component/common/InputTimeBox"
import TextAreaBox from "../../component/common/TextAreaBox"
import AlertPopup from "../AlertPopup"

import CommonContainer from "../../../containers/CommonContainer"

const useStyles = makeStyles((theme) => ({
    container: {
        "& .MuiDialog-paperFullWidth": {
            width: "100%",
            margin: "25px",
            borderRadius: "9px",
        },
        "& .MuiPaper-root": {
            backgroundColor: "#fff",
            maxWidth: "960px",
        },
    },
}))

const ScheduleAddPopup = (props) => {
    const classes = useStyles()
    const { t, _productionVisitor, initTranslationWords, getTranslationWords, _translationWords, replaceWord } = props
    const { open, onClose } = props
    const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })
    const [dateFr, setDateFr] = useState(moment().format("YYYYMMDD")) // 시작일
    const [dateTo, setDateTo] = useState(moment().format("YYYYMMDD")) // 종료일
    const [timeFr, setTimeFr] = useState("") // 시작시간
    const [timeTo, setTimeTo] = useState("") // 종료시간
    // const [isAlarm, setIsAlarm] = useState(false)                  // 알림여부
    const [requesterName, setRequesterName] = useState("") // 요청자이름
    const [requestorNumber, setRequestorNumber] = useState("") // 요청자전화번호
    const [visitorName, setVisitorName] = useState("") // 방문자이름
    const [visitorNumber, setVisitorNumber] = useState("") // 방문자전화번호
    const [companyName, setCompanyName] = useState("") // 업체명
    const [visitPurpose, setVisitPurpose] = useState("") // 방문목적
    const [visitPersonnel, setVisitPersonnel] = useState(0) // 방문인원
    const [content, setContent] = useState("") // 내용

    const [minDate, setMinDate] = useState(new Date()) //날짜 최저일
    const [maxDate, setMaxDate] = useState(new Date()) //날짜 최대일

    const dict = _translationWords[0] // 변수에 사전 담기

    // 예약방문되있는거 눌렀을경우 값 불러오기
    useEffect(() => {
        getTranslationWords()
        if (Object.keys(_productionVisitor).length != 0) {
            setDateFr(moment(_productionVisitor.dateFr, "YYYYMMDD").isValid() ? moment(_productionVisitor.dateFr, "YYYYMMDD") : "")
            setDateTo(moment(_productionVisitor.dateTo, "YYYYMMDD").isValid() ? moment(_productionVisitor.dateTo, "YYYYMMDD") : "")
            setTimeFr(_productionVisitor.timeFr || "")
            setTimeTo(_productionVisitor.timeTo || "")
            // setIsAlarm(_productionPlan.isAlarm == 1)
            setRequesterName(_productionVisitor.requesterName || "")
            setRequestorNumber(_productionVisitor.requestorNumber || "")
            setVisitorName(_productionVisitor.visitorName || "")
            setVisitorNumber(_productionVisitor.visitorNumber || "")
            setCompanyName(_productionVisitor.companyName || "")
            setVisitPurpose(_productionVisitor.visitPurpose || "")
            setVisitPersonnel(_productionVisitor.visitPersonnel || 0)
            setContent(_productionVisitor.content)
        }
        return () => {
            setDateFr(moment().format("YYYYMMDD"))
            setDateTo(moment().format("YYYYMMDD"))
            setTimeFr("")
            setTimeTo("")
            // setIsAlarm(false)
            setRequesterName("")
            setRequestorNumber("")
            setVisitorName("")
            setVisitorNumber("")
            setCompanyName("")
            setVisitPurpose("")
            setVisitPersonnel(0)
            setContent("")
        }
    }, [open])

    // 달력 날짜 제한 관련
    useEffect(() => {
        // console.log("@@@@@@@@@@@@@@@typeof dateFr::",typeof dateFr);
        if (typeof dateFr == "string" && dateFr != undefined && dateFr != "" && dateFr != null) {
            // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
            const originalDate = new Date(dateFr.substr(0, 4) + "-" + dateFr.substr(4, 2) + "-" + dateFr.substr(6, 2))
            const modifiedDate = originalDate.setDate(originalDate.getDate() + 10000)

            setMinDate(new Date(dateFr.substr(0, 4) + "-" + dateFr.substr(4, 2) + "-" + dateFr.substr(6, 2)))
            setMaxDate(modifiedDate)
            // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
            // console.log('dateFr(1) : ', dateFr)
            // console.log('dateTo(1) : ', dateTo)
        } else if (typeof dateFr == "object" && dateFr._i != undefined && dateFr._i != "" && dateFr._i != null) {
            // 날짜를 6일 빼기 위해 날짜 객체를 복사합니다
            const originalDate = new Date(dateFr._i.substr(0, 4) + "-" + dateFr._i.substr(4, 2) + "-" + dateFr._i.substr(6, 2))
            const modifiedDate = originalDate.setDate(originalDate.getDate() + 10000)

            setMinDate(new Date(dateFr._i.substr(0, 4) + "-" + dateFr._i.substr(4, 2) + "-" + dateFr._i.substr(6, 2)))
            setMaxDate(modifiedDate)
            // setMaxDate(new Date(calcDate(dateTo, 0).resDate));
            // console.log('dateFr(1) : ', dateFr)
            // console.log('dateTo(1) : ', dateTo)
        }
    }, [dateFr])

    // 저장하는 부분
    const onClickSave = () => {
        if (dateFr == "" || dateTo == "")
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["date"]) })
        else if (visitPurpose == "")
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["visitPurpose"]) })
        else if (visitorName == "")
            return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["visitorName"]) })
        else if (content == "") return setAlertPopup({ open: true, text: replaceWord(dict["n.Invalid value. Please try again."], dict["content"]) })

        let param = {
            dateFr: moment(dateFr).format("YYYYMMDD"),
            dateTo: moment(dateTo).format("YYYYMMDD"),
            timeFr: timeFr || "",
            timeTo: timeTo || "",
            // isAlarm: isAlarm ? 1 : 0,
            requesterName: requesterName || "",
            requestorNumber: requestorNumber || "",
            visitorName: visitorName || "",
            visitorNumber: visitorNumber || "",
            companyName: companyName || "",
            visitPurpose: visitPurpose || "",
            visitPersonnel: visitPersonnel || 0,
            content,
        }
        if (_productionVisitor.visitorSeq) param.visitorSeq = _productionVisitor.visitorSeq
        onClose(dict["save"], param)
    } //onClickSave END

    //삭제
    const onClickDelete = () => {
        if (_productionVisitor.visitorSeq) onClose(dict["delete"], { visitorSeq: _productionVisitor.visitorSeq })
    }

    return (
        <Dialog className={classes.container} open={open} onClose={onClose} fullWidth={true}>
            <AlertPopup
                bg={false}
                open={alertPopup.open}
                onClose={() => setAlertPopup({ open: false, text: alertPopup.text })}
                content={alertPopup.text}
            />
            <Container>
                <Title>{dict["visitor"] + dict["schedule"]}</Title>
                <div style={{ flex: 1 }}>
                    <FlexBox>
                        <Label>
                            <RequiredMark /> {dict["date"]}
                        </Label>
                        <DateBox style={{ marginRight: "15px" }} stdDate={dateFr} setStdDate={setDateFr} />
                        <p style={{ marginRight: "15px", color: "#555555cc", fontSize: "14px" }}>~</p>
                        <DateBox stdDate={dateTo} setStdDate={setDateTo} minDate={minDate} maxDate={maxDate} />
                    </FlexBox>

                    <FlexBox>
                        <Label>{dict["time(24h)"]}</Label>
                        <InputTimeBox
                            style={{ width: "120px", marginRight: 0 }}
                            disabled={false}
                            placeholder={"00 : 00"}
                            value={timeFr}
                            setValue={setTimeFr}
                        />
                        <p style={{ margin: "0 15px", color: "#555555cc", fontSize: "14px" }}>~</p>
                        <InputTimeBox
                            style={{ width: "120px", marginRight: 0 }}
                            disabled={false}
                            placeholder={"00 : 00"}
                            value={timeTo}
                            setValue={setTimeTo}
                        />
                        {/* <TableCheckBox style={{ marginLeft: "15px" }} check={isAlarm} setCheck={setIsAlarm} />
            <p style={{ margin: "0 7px", color: "#555555cc", fontSize: "14px" }}>{t("notice")}</p> */}
                    </FlexBox>

                    <FlexBox>
                        <Label>
                            <RequiredMark /> {dict["visitPurpose"]}
                        </Label>
                        <InputBox style={{ width: "200px" }} type="string" value={visitPurpose} onChange={(e) => setVisitPurpose(e.target.value)} />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Label2>{dict["visitPersonnel"]}</Label2>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <InputBox
                            style={{ width: "70px" }}
                            type="number"
                            value={visitPersonnel}
                            onChange={(e) => setVisitPersonnel(e.target.value)}
                        />
                    </FlexBox>

                    <FlexBox>
                        <Label>
                            <RequiredMark /> {dict["visitorName"]}
                        </Label>
                        <InputBox style={{ width: "200px" }} type="string" value={visitorName} onChange={(e) => setVisitorName(e.target.value)} />
                        <Label2>{dict["visitorNumber"]}</Label2>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <InputBox style={{ width: "200px" }} type="string" value={visitorNumber} onChange={(e) => setVisitorNumber(e.target.value)} />
                    </FlexBox>

                    <FlexBox>
                        <Label>{dict["requesterName"]}</Label>
                        <InputBox style={{ width: "200px" }} type="string" value={requesterName} onChange={(e) => setRequesterName(e.target.value)} />
                        <Label2>{dict["requestorNumber"]}</Label2>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <InputBox
                            style={{ width: "200px" }}
                            type="string"
                            value={requestorNumber}
                            onChange={(e) => setRequestorNumber(e.target.value)}
                        />
                    </FlexBox>

                    <FlexBox>
                        <Label>{dict["companyName"]}</Label>
                        <InputBox style={{ width: "200px" }} type="string" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                    </FlexBox>

                    <FlexBox alignItems="flex-start">
                        <Label>
                            <RequiredMark /> {dict["content"]}
                        </Label>
                        <TextAreaBox width={0} value={content} setValue={setContent}></TextAreaBox>
                    </FlexBox>

                    <FlexBox></FlexBox>
                    <FlexBox></FlexBox>
                </div>

                <ButtonBox>
                    <Button
                        bgColor={"#fff"}
                        hover={"rgba(174, 174, 174, 0.22)"}
                        border={"1px solid #c6c6c6"}
                        color={"#555555cc"}
                        onClick={() => onClose(dict["cancel"], null)}
                    >
                        {dict["cancel"]}
                    </Button>
                    {_productionVisitor.visitorSeq && (
                        <Button bgColor={"#fa5b59"} hover={"rgba(250, 91, 89, 0.8)"} border={"none"} color={"#fff"} onClick={onClickDelete}>
                            {dict["delete"]}
                        </Button>
                    )}
                    <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
                        {dict["save"]}
                    </Button>
                </ButtonBox>
            </Container>
        </Dialog>
    )
}

ScheduleAddPopup.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
}

const Container = styled.div`
    padding: 23px 40px 27px;
`
const Title = styled.p`
    opacity: 80%;
    margin-bottom: 23px;
    color: #555555;
    font-size: 15px;
    font-weight: 700;
    line-height: 32px;
`
const FlexBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: ${(props) => props.alignItems || "center"};
    margin-bottom: 14px;
`
const Label = styled.p`
    opacity: 80%;
    width: 102px;
    color: #555555;
    font-size: 14px;
    line-height: 34px;
`
const Label2 = styled.p`
    opacity: 80%;
    color: #555555;
    font-size: 14px;
    line-height: 34px;
`
const ButtonBox = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`
const Button = styled.button`
    background-color: ${(props) => props.bgColor};
    width: 147px;
    height: 40px;
    margin-left: 14px;
    border: ${(props) => props.border};
    border-radius: 3px;
    color: ${(props) => props.color};
    font-size: 14px;

    &:first-child {
        margin-left: 0;
    }

    &:hover {
        background-color: ${(props) => props.hover};
    }
`

export default VisitorManagementContainer(CommonContainer(withTranslation()(ScheduleAddPopup)))
