// 자동환경설정 추가/수정 팝업
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import { makeStyles } from "@material-ui/styles"
import { Dialog } from "@material-ui/core"
import { AutoEnvKindList } from "../../../utils/data"
import AutoEnvSettingOnOffContainer from "../../../containers/envControl/AutoEnvSettingOnOffContainer"
import RequiredMark from "../../component/RequiredMark"
import ComboBox from "../../component/common/ComboBox"
import InputBox from "../../component/common/InputBox"
import AlertPopup from "../AlertPopup"

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "388px",
    margin: "0 auto",
    "& .MuiDialog-paperFullWidth": {
      width: "100%",
    },
    "& .MuiPaper-root": {
      backgroundColor: "#fff",
    },
    "& .MuiDialog-paper": {
      margin: "0px",
    },
  },
}))

const AutoEnvOnOffAddPopup = (props) => {
  const classes = useStyles()
  const { t, _codeList, _autoEnvConfEquipOnOff } = props
  const { onClose, open } = props
  const [sensorKind, setSensorKind] = useState(0)
  const [kind, setKind] = useState(0)
  const [from, setFrom] = useState("")
  const [to, setTo] = useState("")
  const [alertPopup, setAlertPopup] = useState({ open: false, text: "" })

  useEffect(() => {
    if (Object.keys(_autoEnvConfEquipOnOff).length == 0) return
    setSensorKind(_autoEnvConfEquipOnOff.sensorKind || 0)
    setKind(_autoEnvConfEquipOnOff.valueKind)
    setFrom(_autoEnvConfEquipOnOff.tgValueFr)
    setTo(_autoEnvConfEquipOnOff.tgValueTo)

    return () => {
      setSensorKind(0)
      setKind(0)
      setFrom("")
      setTo("")
    }
  }, [open])

  const onClickSave = () => {
    let msg = ""
    if (sensorKind === 0) {
      msg = t("n.Please select a product.", { n: t("sensorKind") })
    } else if (kind == 0) {
      msg = t("n.Please select a product.", { n: t("division") })
    } else if (kind != 3 && from == "") {
      msg = t("n.Invalid value. Please try again.", { n: t("from") })
    } else if (kind != 2 && to == "") {
      msg = t("n.Invalid value. Please try again.", { n: t("to") })
    }
    if (msg != "") {
      return setAlertPopup({ open: true, text: msg })
    }

    // if (sensorKind === 0 || kind == 0) return
    // else if (kind != 3 && from == "") return
    // else if (kind != 2 && to == "") return

    let param = { sensorKind, kind, from: kind == 3 ? 0 : from || 0, to: kind == 2 ? 0 : to || 0 }

    if (_autoEnvConfEquipOnOff.envConfEquipOnOffSeq) {
      param.envConfEquipOnOffSeq = _autoEnvConfEquipOnOff.envConfEquipOnOffSeq
    }

    onClose(t("save"), param)
  }

  const onClickCancel = () => {
    onClose(t("cancel"), null)
  }

  return (
    <Dialog
      className={classes.container}
      style={{ position: "absolute", width: "100%", height: "100%", zIndex: 100 }}
      onClose={() => onClose(null)}
      open={open}
      fullWidth={true}
    >
      <AlertPopup bg={false} open={alertPopup.open} onClose={() => setAlertPopup({ open: false, text: alertPopup.text })} content={alertPopup.text} />
      <Container>
        <Title>{t("autoEnvSetting")}</Title>
        <div style={{ flex: 1 }}>
          <FlexBox>
            <Label>
              <RequiredMark /> {t("sensorKind")}
            </Label>
            <ComboBox
              style={{ width: "160px", marginRight: 0 }}
              defaultValue={t("select")}
              hasDefault={true}
              items={((_codeList || []).filter((i) => i.majorSeq == 1015)[0] || { minorList: [] }).minorList.map((i) => ({
                value: i.minorSeq,
                label: i.minorName,
              }))}
              value={sensorKind}
              setValue={setSensorKind}
            />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {t("kind")}
            </Label>
            <ComboBox
              style={{ width: "160px", marginRight: 0 }}
              defaultValue={t("select")}
              hasDefault={true}
              items={AutoEnvKindList}
              value={kind}
              setValue={setKind}
            />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {t("from")}
            </Label>
            <InputBox
              style={{ width: "160px", marginRight: 0 }}
              type="comma"
              value={from}
              onChange={(e) => setFrom(e.target.value)}
              disabled={kind == 3}
            />
          </FlexBox>
          <FlexBox>
            <Label>
              <RequiredMark /> {t("to")}
            </Label>
            <InputBox
              style={{ width: "160px", marginRight: 0 }}
              type="comma"
              value={to}
              onChange={(e) => setTo(e.target.value)}
              disabled={kind == 2}
            />
          </FlexBox>
        </div>
        <ButtonBox>
          <Button bgColor={"#fff"} hover={"rgba(174, 174, 174, 0.22)"} border={"1px solid #c6c6c6"} color={"#555555cc"} onClick={onClickCancel}>
            {t("cancel")}
          </Button>
          <Button bgColor={"#52935d"} hover={"#3e7347"} border={"none"} color={"#fff"} onClick={onClickSave}>
            {t("save")}
          </Button>
        </ButtonBox>
      </Container>
    </Dialog>
  )
}

const Container = styled.div`
  background-color: #fff;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 23px 51px 44px 40px;
`
const Title = styled.p`
  opacity: 80%;
  margin-bottom: 23px;
  color: #555555;
  font-size: 15px;
  font-weight: 700;
  line-height: 32px;
`
const FlexBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 14px;
  &:last-child {
    margin-bottom: 40px;
  }
`
const Label = styled.p`
  opacity: 80%;
  width: 102px;
  color: #555555;
  font-size: 14px;
  line-height: 34px;
`
const ButtonBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const Button = styled.button`
  background-color: ${(props) => props.bgColor};
  flex: 1;
  height: 40px;
  border: ${(props) => props.border};
  border-radius: 3px;
  color: ${(props) => props.color};
  font-size: 14px;

  &:last-child {
    margin-left: 14px;
  }

  &:hover {
    background-color: ${(props) => props.hover};
  }
`

AutoEnvOnOffAddPopup.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
}

export default AutoEnvSettingOnOffContainer(withTranslation()(AutoEnvOnOffAddPopup))
